import styled from 'styled-components';

export type CheckboxGroupProps = {
  reverse?: boolean;
  variant?: 'pill' | 'normal';
  onChange?: (e:React.ChangeEvent<HTMLInputElement>) => void
}
export const CheckboxGroup: React.FC<CheckboxGroupProps> = ({
  reverse,
  children,
  variant='normal',
  ...args}) =>

  <StyledGroup reverse={reverse||undefined} variant={variant} {...args}>{children}</StyledGroup>;

const StyledGroup = styled.div<{
  variant?: CheckboxGroupProps['variant']
  reverse?: CheckboxGroupProps['reverse']
}>`${({theme, variant, reverse}) => `
  
  display: flex;
  flex-direction: row;

  > *:not(.input-holder):not(input) {
    flex-grow: 1;
  }

  input, label {
    vertical-align: middle;
    line-height: ${theme.fontSize.fontMargins}px;
    margin-right: 0.5em;
  }

  > label:first-child {
    margin-right: 0.5em;
  }

  ${variant === 'pill'?`
    display: inline-block;
    
    > input {
      display: none !important;
    }

    label {
      display: inline-block;

      padding: ${theme.fontPadding.base/2}px ${theme.fontPadding.base}px;
      border-radius: ${theme.fontPadding.base*2}px;

      color: ${theme.colors.primary.base};

      cursor: pointer;
    }

    label {
      background-color: ${theme.colors.gray.lightest};
    }
    input:checked + label {
      background-color: ${theme.colors.primary.lightest};
    }
  `:''}

  cursor: pointer;
  > * {
    cursor: pointer;
  }
`}`;
