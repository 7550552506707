import React from 'react';
import {IconProps} from './_iconProps';

export const Frown: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M21.3333 21.3333C21.3333 21.3333 19.3333 18.6667 16 18.6667C12.6667 18.6667 10.6667 21.3333 10.6667 21.3333M12 12H12.0133M20 12H20.0133M29.3333 16C29.3333 23.3638 23.3638 29.3333 16 29.3333C8.63621 29.3333 2.66667 23.3638 2.66667 16C2.66667 8.63621 8.63621 2.66667 16 2.66667C23.3638 2.66667 29.3333 8.63621 29.3333 16Z" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</svg>
