import React from 'react';

import styled from 'styled-components';

type PopoverPropsType = {
  align?: 'center' | 'right' | 'left',
  valign?: 'top' | 'bottom',
  active: boolean;
};
export const Popover: React.FC<PopoverPropsType & React.HTMLAttributes<HTMLDivElement>> = ({
  align = 'center',
  valign = 'bottom',
  active,
  children,
  ...rest
}) => (<Marker {...rest}><PopoverContainer className={active?'active':undefined} align={align} valign={valign}>
  {children}
</PopoverContainer></Marker>);


const Marker = styled.div.attrs({
  className: 'popoverMarker'
} as React.HTMLAttributes<HTMLDivElement>)`${() => `
  width: 0;
  height: 0;
  position: relative;
  padding: 0 !important;
  display: inline-block;
  vertical-align: baseline;
`}`;

const PopoverContainer = styled.div.attrs({} as Pick<PopoverPropsType,'align'|'valign'|'active'>)`${({theme, align, valign}) => `
  position: absolute;

  box-shadow: 0 0 5px ${theme.colors.sky.base};
  border-radius: ${theme.boxPadding.base}px;
  overflow: hidden;

  ${align === 'left' ? 'left: 0;' : align === 'right' ? 'right:0;' : 'left:0; right:0;' }
  ${valign === 'top' ? 'bottom: 0;' : 'top:0;'}

  &:not(.active) {
    display: none
  }
`}`;
