import React from 'react';
import {IconProps} from './_iconProps';

export const BookOpen: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M16 9.33333C16 7.91885 15.4381 6.56229 14.4379 5.5621C13.4377 4.5619 12.0812 4 10.6667 4H2.66667V24H12C13.0609 24 14.0783 24.4214 14.8284 25.1716C15.5786 25.9217 16 26.9391 16 28M16 9.33333V28M16 9.33333C16 7.91885 16.5619 6.56229 17.5621 5.5621C18.5623 4.5619 19.9188 4 21.3333 4H29.3333V24H20C18.9391 24 17.9217 24.4214 17.1716 25.1716C16.4214 25.9217 16 26.9391 16 28" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</svg>
