import styled from 'styled-components';
import {
  WidthContainer
} from 'layout/Containers';

export const PageWrapper: React.FC = ({children, ...rest}) => (
  <PageContainer {...rest}>
    <WidthContainer>
      {children}
    </WidthContainer>
  </PageContainer>
);

export const PageContainer = styled.div`${({theme}) =>`
  flex-grow: 10;
  padding: ${theme.boxPadding.xl5}px 0;
  background-color: ${theme.newColors.WhippedCream};

  > .width-container {
    display: block;
  }
`}`;