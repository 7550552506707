import {
  getHomes as getHomesApi,
  createHome as createHomeApi,
  updateHome as updateHomeApi,
  logChangedHomeDetails
} from '@ubiety/fe-api';
import { createStore } from 'zustand/vanilla';

// import { persist } from 'zustand/middleware';
import { getAccountId } from './selectors';
import { ContextError } from '../classes';
import { fnSilentWrapper, withCatcher, processRawHomeData } from '../helpers';
import { DataStatus, Maybe } from '../types';
import { Home } from '../types/home';
import { removeUndefinedValuesFromObject } from '../utils';

interface IHomeState {
  home: Home | undefined;
  homeDataStatus: DataStatus;
}

type HomeAction = {
  reset: () => void;
  getHome: () => Promise<Home>;
  getHomeSilently: () => Promise<Maybe<boolean>>;
  createHome: (payload: Partial<Home>) => Promise<Maybe<Home>>;
  createHomeSilently: (payload: Partial<Home>) => Promise<Maybe<boolean>>;
  updateHome: (payload: Partial<Omit<Home, 'created_at' | 'timezone'>>) => Promise<Maybe<Home>>;
  updateHomeSilently: (payload: Partial<Omit<Home, 'created_at' | 'timezone'>>) => Promise<Maybe<boolean>>;
  createOrUpdateHome: (payload: Partial<Home>, uid?: number) => Promise<Maybe<Home>>;
};

export type HomeState = IHomeState & HomeAction;

const INITIAL_STATE: IHomeState = {
  home: undefined,
  homeDataStatus: undefined,
};

export const homeStore = createStore<HomeState>()(
  // persist(
    (set, get) => ({
      ...INITIAL_STATE,
      reset: () => set(INITIAL_STATE),
      getHome: withCatcher('homeStore.getHome', async () => {
        const accountId = getAccountId();
        if (!accountId) {
          throw new ContextError('there is no account to get home', {isLocal: true});
        }

        const response = await getHomesApi();
        const rawHome = response?.data?.data?.[0];
        const processedHome = processRawHomeData(rawHome);

        processedHome.accountId = accountId;

        set({ home: processedHome, homeDataStatus: 'success' });
        return processedHome;
      }, (e) => {
        if ((e as any)?.response?.status === 404) {
          set({ homeDataStatus: 'success' });
          return;
        } 
        if (e.isLocal) {return;}
        set({ homeDataStatus: 'error' });
      }),
      getHomeSilently: fnSilentWrapper(() => get().getHome()),
      createHome: withCatcher('homeStore.createHome', (payload) => get().createOrUpdateHome(payload)),
      createHomeSilently: fnSilentWrapper((payload) => get().createHome(payload)),
      updateHome: withCatcher('homeStore.updateHome', (payload) => get().createOrUpdateHome(payload)),
      updateHomeSilently: fnSilentWrapper((payload) => get().updateHome(payload)),
      createOrUpdateHome: withCatcher('homeStore.createOrUpdateHome', async (payload) => {
        const accountId = getAccountId();

        if (!accountId) {
          throw new ContextError(`${'there is no account to' + payload.uid ? 'update': 'create'} home`, {isLocal: true});
        }

        const rawPayload = {
          account_id: payload.accountId,
          name: payload.name,
          home_type: payload.homeType,
          sq_ft: payload.sqFt,
          address_street1: payload.addressStreet1,
          address_street2: payload.addressStreet2,
          address_city: payload.addressCity,
          address_state: payload.addressState,
          address_country: payload.addressCountry,
          address_zip: payload.addressZip
        };

        removeUndefinedValuesFromObject(rawPayload);

        const resp = payload?.uid
          ? await updateHomeApi(payload.uid, { ...rawPayload })
          : await createHomeApi(rawPayload);

        logChangedHomeDetails({
          homeUid: String(resp.data.uid)
        });

        const newHome = processRawHomeData(resp.data);
        set({ home: newHome, homeDataStatus: 'success' });

        return newHome;
      }, (e) => {
        if (e.isLocal) {return;}
        set({ homeDataStatus: 'error' });
      }),
    }),
  //   {
  //     name: 'home-storage',
  //   },
  // ),
);
