import React from 'react';
import {IconProps} from './_iconProps';

export const Calendar: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M21.3333 2.66669V8.00002M10.6667 2.66669V8.00002M4 13.3334H28M6.66667 5.00002H25.3333C26.8061 5.00002 28 6.19393 28 7.66669V26.3334C28 27.8061 26.8061 29 25.3333 29H6.66667C5.19391 29 4 27.8061 4 26.3334V7.66669C4 6.19393 5.19391 5.00002 6.66667 5.00002Z" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</svg>
