import React from 'react';
import {IconProps} from './_iconProps';

export const ZapOff: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_149_890)">
<path d="M16.5467 8.99998L17.3333 2.66665L14.0933 6.55998M24.76 17.2133L28 13.3333H20.88M10.6667 10.6666L4 18.6666H16L14.6667 29.3333L21.3333 21.3333M1.33334 1.33331L30.6667 30.6666" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</g>
<defs>
<clipPath id="clip0_149_890">
<rect width={width} height={height} fill="white"/>
</clipPath>
</defs>
</svg>
