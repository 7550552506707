import React from 'react';
import {IconProps} from './_iconProps';

export const Video: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M30.6667 9.33335L21.3333 16L30.6667 22.6667V9.33335Z" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
<path d="M18.6667 6.66669H4.00001C2.52725 6.66669 1.33334 7.86059 1.33334 9.33335V22.6667C1.33334 24.1394 2.52725 25.3334 4.00001 25.3334H18.6667C20.1394 25.3334 21.3333 24.1394 21.3333 22.6667V9.33335C21.3333 7.86059 20.1394 6.66669 18.6667 6.66669Z" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</svg>
