import React from 'react';
import {IconProps} from './_iconProps';

export const Delete: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M24 12L16 20M16 12L24 20M28 5.33334H10.6667L1.33334 16L10.6667 26.6667H28C28.7072 26.6667 29.3855 26.3857 29.8856 25.8856C30.3857 25.3855 30.6667 24.7073 30.6667 24V8.00001C30.6667 7.29277 30.3857 6.61449 29.8856 6.11439C29.3855 5.61429 28.7072 5.33334 28 5.33334Z" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</svg>
