import React from 'react';
import {IconProps} from './_iconProps';

export const LifeBuoy: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.57334 6.57333L12.2267 12.2267M19.7733 19.7733L25.4267 25.4267M25.4267 6.57333L19.7733 12.2267L24.48 7.52M6.57334 25.4267L12.2267 19.7733M29.3333 16C29.3333 23.3638 23.3638 29.3333 16 29.3333C8.63621 29.3333 2.66667 23.3638 2.66667 16C2.66667 8.6362 8.63621 2.66667 16 2.66667C23.3638 2.66667 29.3333 8.6362 29.3333 16ZM21.3333 16C21.3333 18.9455 18.9455 21.3333 16 21.3333C13.0545 21.3333 10.6667 18.9455 10.6667 16C10.6667 13.0545 13.0545 10.6667 16 10.6667C18.9455 10.6667 21.3333 13.0545 21.3333 16Z" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</svg>
