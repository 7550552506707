import React from 'react';
import {IconProps} from './_iconProps';

export const Repeat: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M22.6667 1.33334L28 6.66668M28 6.66668L22.6667 12M28 6.66668H9.33333C7.91885 6.66668 6.56229 7.22858 5.5621 8.22877C4.5619 9.22897 4 10.5855 4 12V14.6667M9.33333 30.6667L4 25.3333M4 25.3333L9.33333 20M4 25.3333H22.6667C24.0812 25.3333 25.4377 24.7714 26.4379 23.7712C27.4381 22.7711 28 21.4145 28 20V17.3333" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</svg>
