import React from 'react';
import {IconProps} from './_iconProps';

export const Gitlab: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M30.2 19.1867L16 29.5067L1.80001 19.1867C1.60964 19.0467 1.46843 18.85 1.39659 18.6249C1.32474 18.3998 1.32594 18.1577 1.40001 17.9333L3.02668 12.8933L6.28001 2.88001C6.31156 2.79842 6.3618 2.72536 6.42668 2.66668C6.53233 2.57017 6.67025 2.51666 6.81334 2.51666C6.95644 2.51666 7.09436 2.57017 7.20001 2.66668C7.26852 2.7329 7.319 2.8155 7.34668 2.90668L10.6 12.8933H21.4L24.6533 2.88001C24.6849 2.79842 24.7351 2.72536 24.8 2.66668C24.9057 2.57017 25.0436 2.51666 25.1867 2.51666C25.3298 2.51666 25.4677 2.57017 25.5733 2.66668C25.6419 2.7329 25.6923 2.8155 25.72 2.90668L28.9733 12.92L30.6667 17.9333C30.734 18.1647 30.725 18.4115 30.6409 18.6373C30.5569 18.8631 30.4023 19.0557 30.2 19.1867Z" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</svg>
