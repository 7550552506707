export class Listeners<T> {
  listeners: Array<(val: T)=>void>=[];

	addListener(fn: (val: T) => void): () => void {
    this.listeners.push(fn);
    return () => this.removeListener(fn);
	}

  removeListener(fn: (val: T) => void){
    const index = this.listeners.indexOf(fn);
    if(index > -1){
      this.listeners.splice(index,1);
    }
  }

  fire(val?: T){
    return Promise.all(this.listeners.map(listener => listener.apply(listener, [val as T])));
  }
}