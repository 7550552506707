import React from 'react';
import {IconProps} from './_iconProps';

export const FileText: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M18.6667 2.66666H7.99999C7.29275 2.66666 6.61447 2.94761 6.11438 3.4477C5.61428 3.9478 5.33333 4.62608 5.33333 5.33332V26.6667C5.33333 27.3739 5.61428 28.0522 6.11438 28.5523C6.61447 29.0524 7.29275 29.3333 7.99999 29.3333H24C24.7072 29.3333 25.3855 29.0524 25.8856 28.5523C26.3857 28.0522 26.6667 27.3739 26.6667 26.6667V10.6667M18.6667 2.66666L26.6667 10.6667M18.6667 2.66666V10.6667H26.6667M21.3333 17.3333H10.6667M21.3333 22.6667H10.6667M13.3333 12H10.6667" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</svg>
