import {
  NewSavedReportValue
} from 'context';
import {
  offsetHomeSpecificTimestamp
} from 'utils';

export type ProcessedReportItem = NewSavedReportValue['items'][0] & {
  localTimestamp: number;
}
export type ProcessedReport = Omit<NewSavedReportValue,'items'> & {
  localStartTime: number;
  localEndTime: number;
  localReportTime: number;
  items: Array<ProcessedReportItem>;
}
export const processReportItem = (it: NewSavedReportValue['items'][0]): ProcessedReportItem => ({
  ...it, 
  localTimestamp: offsetHomeSpecificTimestamp(it.timestamp)
});
export const processReport = (report:NewSavedReportValue): ProcessedReport => ({
  ...report,
  localStartTime:offsetHomeSpecificTimestamp(report.startTime),
  localEndTime:offsetHomeSpecificTimestamp(report.endTime),
  localReportTime:offsetHomeSpecificTimestamp(report.reportTime), 
  items: (report.items?.map(processReportItem))||[]
});