import styled from 'styled-components';

export const IconWrapper = styled.div.attrs(({type, theme}: {
  type: 'warn' | 'info' | 'success' | undefined,
  theme: any
}) => ({
  color: type === 'warn'
      ?
      theme.colors.red.base
      :
      type === 'info'
      ?
      theme.colors.blue.light
      :
      type === 'success'
      ?
      theme.colors.green.base
      :
      theme.colors.ink.base,
  type

}))`${({theme, color}) => `
  display: inline-block !important;

  padding: ${theme.boxPadding.xl6}px;
  margin: ${theme.boxMargins.xl3}px;
  border-radius: ${theme.boxPadding.xl6*2}px;
  border: 4px dotted ${color};

  > svg {
    font-size: ${theme.fontSize.xl8}px;

    color: ${color};
  }

`}`;

export const SuccessMessage = styled.b`
  display: inline-block;
  color: ${({theme}) => theme.colors.green.base};
  font-family: ${({theme}) => theme.defaultFontFamily};
  font-size: ${({theme}) => theme.fontSize.base}px;

  margin-top: 10px !important;
  margin-bottom: 25px;
`;

export const InfoMessage = styled.b.attrs({} as {
  large?: boolean
})`
  display: inline-block;
  color: ${({theme}) => theme.newColors.information};
  font-family: ${({theme}) => theme.defaultFontFamily};
  font-size: ${({theme}) => theme.fontSize.s}px;

  margin-top: 10px !important;
  margin-bottom: 25px;
`;

export const WarningMessage = styled.b.attrs({} as {
  large?: boolean
})`
  display: inline-block;
  color: ${({theme}) => theme.newColors.error};
  font-family: ${({theme}) => theme.defaultFontFamily};

  ${({theme, large}) => large ? `
    font-size: ${theme.fontSize.s}px;
    margin-top: 10px !important;
    margin-bottom: 25px;
  ` : `
    font-size: ${theme.fontSize.s}px;
    margin-top: 5px !important;
  `}
`;