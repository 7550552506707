import React from 'react';
import {IconProps} from './_iconProps';

export const Rss: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5.33333 14.6667C8.51593 14.6667 11.5682 15.931 13.8186 18.1814C16.069 20.4318 17.3333 23.4841 17.3333 26.6667M5.33333 5.33334C10.9913 5.33334 16.4175 7.58096 20.4183 11.5817C24.419 15.5825 26.6667 21.0087 26.6667 26.6667M7.99999 25.3333C7.99999 26.0697 7.40304 26.6667 6.66666 26.6667C5.93028 26.6667 5.33333 26.0697 5.33333 25.3333C5.33333 24.597 5.93028 24 6.66666 24C7.40304 24 7.99999 24.597 7.99999 25.3333Z" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</svg>
