import React from 'react';
import {IconProps} from './_iconProps';

export const ArrowLeftCircle: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M16 10.6667L10.6667 16M10.6667 16L16 21.3334M10.6667 16H21.3333M29.3333 16C29.3333 23.3638 23.3638 29.3334 16 29.3334C8.6362 29.3334 2.66666 23.3638 2.66666 16C2.66666 8.63622 8.6362 2.66669 16 2.66669C23.3638 2.66669 29.3333 8.63622 29.3333 16Z" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</svg>
