import React from 'react';
import {IconProps} from './_iconProps';

export const Divide: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.66666 16H25.3333M18.6667 8.00001C18.6667 9.47277 17.4727 10.6667 16 10.6667C14.5272 10.6667 13.3333 9.47277 13.3333 8.00001C13.3333 6.52725 14.5272 5.33334 16 5.33334C17.4727 5.33334 18.6667 6.52725 18.6667 8.00001ZM18.6667 24C18.6667 25.4728 17.4727 26.6667 16 26.6667C14.5272 26.6667 13.3333 25.4728 13.3333 24C13.3333 22.5272 14.5272 21.3333 16 21.3333C17.4727 21.3333 18.6667 22.5272 18.6667 24Z" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</svg>
