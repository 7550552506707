import React from 'react';
import {IconProps} from './_iconProps';

export const Tool: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M19.6 8.39999C19.3557 8.64923 19.2188 8.98432 19.2188 9.33333C19.2188 9.68233 19.3557 10.0174 19.6 10.2667L21.7333 12.4C21.9826 12.6443 22.3177 12.7811 22.6667 12.7811C23.0157 12.7811 23.3508 12.6443 23.6 12.4L28.6267 7.37333C29.2971 8.85491 29.5001 10.5056 29.2086 12.1055C28.9171 13.7054 28.1449 15.1785 26.995 16.3284C25.8451 17.4783 24.3721 18.2504 22.7722 18.5419C21.1723 18.8334 19.5216 18.6304 18.04 17.96L8.82666 27.1733C8.29623 27.7038 7.5768 28.0018 6.82666 28.0018C6.07651 28.0018 5.35709 27.7038 4.82666 27.1733C4.29623 26.6429 3.99823 25.9235 3.99823 25.1733C3.99823 24.4232 4.29623 23.7038 4.82666 23.1733L14.04 13.96C13.3695 12.4784 13.1665 10.8277 13.458 9.2278C13.7496 7.62792 14.5217 6.15487 15.6716 5.00496C16.8215 3.85504 18.2946 3.08289 19.8945 2.79138C21.4943 2.49988 23.1451 2.70288 24.6267 3.37333L19.6133 8.38666L19.6 8.39999Z" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</svg>
