import React from 'react';
import {IconProps} from './_iconProps';

export const Database: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M28 6.66666C28 8.8758 22.6274 10.6667 16 10.6667C9.37258 10.6667 4 8.8758 4 6.66666M28 6.66666C28 4.45752 22.6274 2.66666 16 2.66666C9.37258 2.66666 4 4.45752 4 6.66666M28 6.66666V25.3333C28 27.5467 22.6667 29.3333 16 29.3333C9.33333 29.3333 4 27.5467 4 25.3333V6.66666M28 16C28 18.2133 22.6667 20 16 20C9.33333 20 4 18.2133 4 16" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</svg>
