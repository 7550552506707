import React from 'react';
import {IconProps} from './_iconProps';

export const Moon: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M28 17.0533C27.7903 19.3229 26.9385 21.4859 25.5444 23.2891C24.1502 25.0922 22.2713 26.4611 20.1276 27.2354C17.9839 28.0097 15.664 28.1575 13.4394 27.6615C11.2147 27.1654 9.17735 26.0461 7.56566 24.4344C5.95396 22.8227 4.83461 20.7853 4.33856 18.5607C3.84252 16.336 3.99031 14.0161 4.76463 11.8724C5.53896 9.72868 6.9078 7.84982 8.71097 6.45567C10.5141 5.06152 12.6771 4.20974 14.9467 4C13.6179 5.79769 12.9785 8.0126 13.1447 10.2419C13.311 12.4712 14.2718 14.5667 15.8526 16.1475C17.4333 17.7282 19.5289 18.689 21.7581 18.8553C23.9874 19.0215 26.2023 18.3821 28 17.0533Z" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</svg>
