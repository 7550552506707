import React from 'react';
import {IconProps} from './_iconProps';

export const Mic: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M25.3333 13.3333V16C25.3333 18.4753 24.35 20.8493 22.5997 22.5997C20.8493 24.35 18.4753 25.3333 16 25.3333M16 25.3333C13.5246 25.3333 11.1507 24.35 9.40033 22.5997C7.64999 20.8493 6.66666 18.4753 6.66666 16V13.3333M16 25.3333V30.6667M10.6667 30.6667H21.3333M16 1.33333C14.9391 1.33333 13.9217 1.75476 13.1716 2.5049C12.4214 3.25505 12 4.27246 12 5.33333V16C12 17.0609 12.4214 18.0783 13.1716 18.8284C13.9217 19.5786 14.9391 20 16 20C17.0609 20 18.0783 19.5786 18.8284 18.8284C19.5786 18.0783 20 17.0609 20 16V5.33333C20 4.27246 19.5786 3.25505 18.8284 2.5049C18.0783 1.75476 17.0609 1.33333 16 1.33333Z" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</svg>
