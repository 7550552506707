import React from 'react';
import {IconProps} from './_iconProps';

export const Anchor: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M16 10.6667C18.2091 10.6667 20 8.87583 20 6.66669C20 4.45755 18.2091 2.66669 16 2.66669C13.7909 2.66669 12 4.45755 12 6.66669C12 8.87583 13.7909 10.6667 16 10.6667ZM16 10.6667V29.3334M16 29.3334C12.4638 29.3334 9.07238 27.9286 6.5719 25.4281C4.07141 22.9276 2.66666 19.5362 2.66666 16H6.66666M16 29.3334C19.5362 29.3334 22.9276 27.9286 25.4281 25.4281C27.9286 22.9276 29.3333 19.5362 29.3333 16H25.3333" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</svg>
