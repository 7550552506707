import React from 'react';
import {IconProps} from './_iconProps';

export const Cpu: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_149_329)">
<path d="M12 1.33334V5.33334M20 1.33334V5.33334M12 26.6667V30.6667M20 26.6667V30.6667M26.6667 12H30.6667M26.6667 18.6667H30.6667M1.33334 12H5.33334M1.33334 18.6667H5.33334M8.00001 5.33334H24C25.4728 5.33334 26.6667 6.52725 26.6667 8.00001V24C26.6667 25.4728 25.4728 26.6667 24 26.6667H8.00001C6.52725 26.6667 5.33334 25.4728 5.33334 24V8.00001C5.33334 6.52725 6.52725 5.33334 8.00001 5.33334ZM12 12H20V20H12V12Z" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</g>
<defs>
<clipPath id="clip0_149_329">
<rect width={width} height={height} fill="white"/>
</clipPath>
</defs>
</svg>
