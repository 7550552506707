import React from 'react';
import {IconProps} from './_iconProps';

export const Paperclip: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M28.5867 14.7333L16.3334 26.9867C14.8322 28.4878 12.7963 29.3311 10.6734 29.3311C8.55045 29.3311 6.51449 28.4878 5.01336 26.9867C3.51224 25.4855 2.66891 23.4496 2.66891 21.3267C2.66891 19.2038 3.51224 17.1678 5.01336 15.6667L17.2667 3.41334C18.2674 2.41259 19.6248 1.85037 21.04 1.85037C22.4553 1.85037 23.8126 2.41259 24.8134 3.41334C25.8141 4.41409 26.3763 5.7714 26.3763 7.18667C26.3763 8.60195 25.8141 9.95926 24.8134 10.96L12.5467 23.2133C12.0463 23.7137 11.3677 23.9948 10.66 23.9948C9.95239 23.9948 9.27374 23.7137 8.77336 23.2133C8.27299 22.713 7.99188 22.0343 7.99188 21.3267C7.99188 20.619 8.27299 19.9404 8.77336 19.44L20.0934 8.13334" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</svg>
