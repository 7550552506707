import React from 'react';
import {IconProps} from './_iconProps';

export const AlertTriangle: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M16 12V17.3334M16 22.6667H16.0133M13.72 5.1467L2.42667 24C2.19383 24.4033 2.07063 24.8604 2.06932 25.3261C2.06802 25.7917 2.18866 26.2495 2.41924 26.6541C2.64982 27.0586 2.98231 27.3957 3.38362 27.6318C3.78492 27.868 4.24107 27.9949 4.70667 28H27.2933C27.7589 27.9949 28.2151 27.868 28.6164 27.6318C29.0177 27.3957 29.3502 27.0586 29.5808 26.6541C29.8113 26.2495 29.932 25.7917 29.9307 25.3261C29.9294 24.8604 29.8062 24.4033 29.5733 24L18.28 5.1467C18.0423 4.75484 17.7076 4.43085 17.3083 4.20601C16.9089 3.98116 16.4583 3.86304 16 3.86304C15.5417 3.86304 15.0911 3.98116 14.6917 4.20601C14.2924 4.43085 13.9577 4.75484 13.72 5.1467Z" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</svg>
