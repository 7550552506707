import {
  AxiosResponse
} from 'axios';

import {
  apiRequest
} from '../apiRequest';

export type Feedback = {
  created_at: number
  home_id: number
  user_id: string
  alert_id: string
  feedback_type: string
  alert_correct: boolean
  additional_comments: string
  missed_alert_time: number
  estimated_number_people: number
  estimated_number_devices: number
  zendesk_details: {
    account_id: string,
    user_id: string,
    phone_uid: string,
    subject: string,
    description: string,
    router_details: string,
    issue_type: 'Problems'|'Questions'|'Incidents'|'Tasks'|'sensorSetup'|'deviceDetection'|'presence'|'hardwareIssue'|'featureRequest'|'other',
    details: 'timed_out'|'wont_connect_to_wifi'|'wont_connect_to_bluetooth'|'cannot_find_phone'|'unknown_device'|'home/not_home'|'fan_is_too_loud'|'device_wont_power_on'|'damaged'|'secondary_other'|'Timed out'|'Won\'t connect to WiFi'|'Won\'t connect to Bluetooth'|'Other'|'Cannot find phone'|'Unknown device'|'Home/Not Home'|'Fan is too loud'|'Device won\'t power on'|'Damaged',
    feedback_type: 'sensor_setup'|'device_detection'|'presence'|'hardware_issue'|'feature_request'|'other'|'Sensor Setup'|'Device Detection'|'Hardware Issue'|'Presence'|'Feature Request'|'Other'
  } | null
}

export const createAlertFeedback = async (payload: Omit<Feedback,'created_at'>):
  Promise<AxiosResponse<Feedback>> => {

  return apiRequest({
    service: 'pushServiceApiV2',
    path: '/feedback',
    method: 'POST',
    data: payload
  });
};

export const getAlertFeedback = async (alert_id: string):
  Promise<AxiosResponse<Array<Feedback>>> => {

  const params = {
    alert_id
  };

  return apiRequest({
    service: 'pushServiceApiV2',
    path: '/feedback',
    params,
    method: 'GET'
  });
};


export const addFeedback = (payload: Omit<Feedback,'created_at'>) =>
  apiRequest({
    service: 'dataAPI',
    path: '/api/feedback',
    method: 'POST',
    data: payload,
  });
