import React from 'react';
import {IconProps} from './_iconProps';

export const Film: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.33332 2.66667V29.3333M22.6667 2.66667V29.3333M2.66666 16H29.3333M2.66666 9.33334H9.33332M2.66666 22.6667H9.33332M22.6667 22.6667H29.3333M22.6667 9.33334H29.3333M5.57332 2.66667H26.4267C28.032 2.66667 29.3333 3.96803 29.3333 5.57334V26.4267C29.3333 28.032 28.032 29.3333 26.4267 29.3333H5.57332C3.96802 29.3333 2.66666 28.032 2.66666 26.4267V5.57334C2.66666 3.96803 3.96802 2.66667 5.57332 2.66667Z" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</svg>
