import React from 'react';
import {IconProps} from './_iconProps';

export const Wifi: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.66664 16.7334C9.30206 14.5383 12.6235 13.3362 16.0533 13.3362C19.4832 13.3362 22.8046 14.5383 25.44 16.7334M1.89331 12C5.78987 8.56531 10.8057 6.67023 16 6.67023C21.1942 6.67023 26.2101 8.56531 30.1066 12M11.3733 21.48C12.7269 20.5184 14.3462 20.0017 16.0066 20.0017C17.6671 20.0017 19.2864 20.5184 20.64 21.48M16 26.6667H16.0133" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</svg>
