import React from 'react';
import {IconProps} from './_iconProps';

export const Thermometer: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M18.6667 19.68V4.66665C18.6667 3.78259 18.3155 2.93474 17.6904 2.30962C17.0652 1.6845 16.2174 1.33331 15.3333 1.33331C14.4493 1.33331 13.6014 1.6845 12.9763 2.30962C12.3512 2.93474 12 3.78259 12 4.66665V19.68C10.9297 20.3951 10.1178 21.4355 9.68411 22.6474C9.25044 23.8594 9.21804 25.1787 9.59169 26.4105C9.96534 27.6423 10.7252 28.7213 11.7591 29.4881C12.793 30.2549 14.0461 30.6689 15.3333 30.6689C16.6206 30.6689 17.8737 30.2549 18.9076 29.4881C19.9415 28.7213 20.7014 27.6423 21.075 26.4105C21.4487 25.1787 21.4162 23.8594 20.9826 22.6474C20.5489 21.4355 19.737 20.3951 18.6667 19.68Z" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</svg>
