import axios from 'axios';

import {
  apiRequest
} from '../apiRequest';
import { 
  TellMeWhenAssosiationDuration, 
  TellMeWhenAssosiationInterval, 
  TellMeWhenScheduleApiItemInterval, 
  TellMeWhenScheduleApiItemDuration, 
  TellMeWhenScheduleApiItemUpdateDurationPayload, 
  TellMeWhenScheduleApiItemUpdateIntervalPayload,
} from './types';

// export type TellMeWhenScheduleApiItem = {
//   createdAt: Date,
//   id: number,
//   homeId: number,
//   profile: string, // profileUid
//   scheduleName: string,
//   daysActive: Array<0|1|2|3|4|5|6|7>, // where 0 is sat and 7 is sun
//   // "start" center marker
//   timeLeave: string, // in 24 h format (timestamp)
//   // "end" center marker
//   timeArrive: string, // in 24 h format (timestamp)
//   boundaryForward: string, // in 24 h format
//   boundaryBackward: string, // in 24 h format
//   options: Array<'Leaves early'|'Leaves late'|'Arrives late'|'Arrives early'>,
//   awsScheduleIds: Array<string>, // Array<string>
//   scheduleActive: boolean,
// }


// helpers
const mergeDataToItems = (data: any, itemType: 'duration'|'interval') => {
  const keys = itemType === 'duration'
    ?
    ['tmw_schedule_duration_associations', 'duration_schedule_id', 'tmw_schedule_durations'] 
    :
    ['tmw_schedule_interval_associations', 'interval_schedule_id', 'tmw_schedule_intervals'];

  const [
    assiciationsKey,
    scheduleIdentifierKey,
    schedulesKey
  ] = keys;

  const items: Array<TellMeWhenScheduleApiItemDuration | TellMeWhenScheduleApiItemInterval> = [];
  
  data[assiciationsKey]?.forEach?.( (association: any) => {
    const newItem = data[schedulesKey]?.[association[scheduleIdentifierKey]];

    if(!newItem){ // continue
      return;
    }

    newItem.enabled = association.enabled;
    newItem.itemType = itemType;
    newItem.profileUid = association.profile_id;

    items.push(newItem);
  });

  return items;
};
export const getHomeTmwSchedules = async (targetId: number | string) => {
  console.log('[getHomeTmwSchedules] getting tell me whens', {targetId, axios});

  const intervalSchedulesData = (await apiRequest<{ 
      Status: 'Success'|'Error';
      Data?: {
        tmw_schedule_interval_associations: Array<TellMeWhenAssosiationInterval>,
        tmw_schedule_intervals: {
          [key: string]: TellMeWhenScheduleApiItemInterval
        }
      }
    }>({
      service: 'dataAPI',
      path: `/api/tmw_schedule_interval/${targetId}`,
      method: 'GET'
    })).data?.Data;

  const intervalSchedules = intervalSchedulesData ? mergeDataToItems(intervalSchedulesData, 'interval') : []; 

  const durationSchedulesData = (await apiRequest<{ 
    Status: 'Success'|'Error';
    Data?: {
      tmw_schedule_duration_associations: Array<TellMeWhenAssosiationDuration>,
      tmw_schedule_durations: {
        [key: string]: TellMeWhenScheduleApiItemDuration
      }
    }
  }>({
      service: 'dataAPI',
      path: `/api/tmw_schedule_duration/${targetId}`,
      method: 'GET'
    })).data?.Data;

  const durationSchedules = durationSchedulesData ? mergeDataToItems(durationSchedulesData, 'duration') : [];

  console.log('[getHomeTmwSchedules] retrieved schedules', {
    intervalSchedulesData,
    intervalSchedules,
    durationSchedulesData,
    durationSchedules
  });

  return [...intervalSchedules, ...durationSchedules];
};
  

export const createTmwSchedule = async (data: TellMeWhenScheduleApiItemUpdateDurationPayload|TellMeWhenScheduleApiItemUpdateIntervalPayload, 
  passedItemType?: 'duration' | 'interval') => {

  const itemType = passedItemType || data.itemType || (data.duration ? 'duration' : 'interval');

  const resp = itemType === 'duration' ?
    await apiRequest<{Status: 'Success'|'Failure', Description?: string}>({
      service: 'dataAPI',
      path: '/api/tmw_schedule_duration',
      method: 'POST',
      data
    })
    :
    await apiRequest<{Status: 'Success'|'Error'|string}>({
      service: 'dataAPI',
      path: '/api/tmw_schedule_interval',
      method: 'POST',
      data
    });
  return resp;
};
  

export const updateTmwSchedule = (targetId: string, 
  data: TellMeWhenScheduleApiItemUpdateDurationPayload|TellMeWhenScheduleApiItemUpdateIntervalPayload,
  passedItemType?: 'duration' | 'interval') => {

  const itemType = passedItemType || data.itemType || (data.duration ? 'duration' : 'interval');

  return itemType === 'duration' ?
    apiRequest<{Status: 'Success'|'Failure', Description?: string}>({
      service: 'dataAPI',
      path: `/api/tmw_schedule_duration/${targetId}`,
      method: 'PUT',
      data
    })
    :
    apiRequest<{Status: 'Success'|'Error'|string}>({
      service: 'dataAPI',
      path: `/api/tmw_schedule_interval/${targetId}`,
      method: 'PUT',
      data
    });
};

export const updateTmwScheduleAssociation = (targetId: string, 
  data: TellMeWhenAssosiationInterval | TellMeWhenAssosiationDuration) => {

  const itemType = (data as TellMeWhenAssosiationDuration).duration_schedule_id ? 'duration' : 'interval';

  return itemType === 'duration' ?
    apiRequest<{Status: 'Success'|'Error', message?: string}>({
      service: 'dataAPI',
      path: '/api/tmw_schedule_duration_association',
      method: 'PUT',
      data
    })
    :
    apiRequest<{Status: 'Success'|'Error', message?: string}>({
      service: 'dataAPI',
      path: '/api/tmw_schedule_interval_association',
      method: 'PUT',
      data
    });
};

export const deleteTmwSchedule = (targetId: string|number,
  itemType?: 'duration' | 'interval') => itemType === 'duration' ?
    apiRequest<{message: string}>({
      service: 'dataAPI',
      path: `/api/tmw_schedule_duration/${targetId}`,
      method: 'DELETE'
    })
    :
    apiRequest<{message: string}>({
      service: 'dataAPI',
      path: `/api/tmw_schedule_interval/${targetId}`,
      method: 'DELETE'
    });
