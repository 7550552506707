import styled from 'styled-components';

import {UID} from 'utils';

export type CheckboxProps = {
  disabled?: boolean,
  checked?: boolean,
  defaultChecked?: boolean|undefined,
  id?: string,
  el?: React.MutableRefObject<HTMLInputElement>,
  name?: string,
  placeholder?: string,
  className?: string,
  value?: string,
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void,
  variant?:'custom'|'normal'
}

export const Switch: React.FC<CheckboxProps> = ({
  disabled=false,
  checked,
  defaultChecked=undefined,
  id=UID(),
  el=null,
  name,
  className,
  value,
  onChange,
  variant='normal'
}) => {

  const checkbox = <StyledInput
      id={id}
      ref={el}
      key={id}
      type="checkbox"
      name={name}
      checked={checked}
      defaultChecked={defaultChecked}
      onChange={onChange}
      className={className}
      value={value}
      disabled={disabled}
      />;

  return (
    <StyledLabel>{checkbox}<svg className="checkmark" width="37" height="21" viewBox="0 0 37 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_i_244_2502)">
        <rect width="37" height="21" rx="10.5" fill="#FEFAE3"/>
        </g>
        <rect x="0.5" y="0.5" width="36" height="20" rx="10" stroke="#F47B37"/>
        <rect className="dragger" x="1.5" y="1.5" width="18" height="18" rx="9" fill="#F47B37" stroke="#FFFEF8"/>
        <defs>
        <filter id="filter0_i_244_2502" x="0" y="0" width="37" height="23" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dy="2"/>
          <feGaussianBlur stdDeviation="2"/>
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0.407843 0 0 0 0 0.431373 0 0 0 0 0.376471 0 0 0 0.2 0"/>
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_244_2502"/>
        </filter>
        </defs>
        </svg>
    </StyledLabel>
    
  );
};

const StyledInput = styled.input`${({theme}) => `
  width: ${theme.fontSize.xl2}px;
  height: ${theme.fontSize.xl2}px;
`}`;

const StyledLabel = styled.label`${({theme}) => `
  cursor: pointer;

  input {
    display: none;
  }

  .checkmark .dragger{
    transition: transform 0.2s ease-in-out;
  }

  input:not(:checked) + .checkmark rect{
    
  }
  input:checked + .checkmark .dragger {
    transform: translateX(16px);
  }
`}`;
