// import { persist } from 'zustand/middleware';
import {
  markAlertAsRead as markAlertAsReadApi,
  getAlerts as getAlertsApi,
  Alert,
  markAllAlertsAsReadApi
} from '@ubiety/fe-api';
import { startOfDay, endOfDay, setDayOfYear, setYear } from 'date-fns';
import { createStore } from 'zustand/vanilla';

import { getHomeId } from './selectors';
import { ContextError } from '../classes';
import {
  processRawAlertEntryArray,
  acceptedAlerts,
  digestRawAlerts,
  digestSensorsResponse,
  fnSilentWrapper,
  withCatcher,
  eachForGetTimeframeAlerts,
  eachForGetSensorOnlineOfflineTimeframeAlerts,
  getDatesDataForTimeframe,
} from '../helpers';
import { DataStatus, Maybe, PromiseVoid } from '../types';
import { AlertMap, DailyIndexedAlerts, IndexedSensorAlertMap } from '../types/alerts';

type limiters = {
  timeframe: { timeStart: number, timeEnd: number },
  pagination: { pageSize: number, numberOfPages: number } | { newest: true }
}

export interface IAlertsState {
  alertsUpdated: number;
  paginatedAlerts: Array<Alert>;
  sensorAlertsUpdated: number;
  paginatedAlertsStatus: DataStatus;

  total: number | undefined;
  endCursor: undefined | number;

  unreadAlertCount?: number;
  unreadAlertArray: Array<Alert>;
  unreadAlertsStatus: DataStatus;
  unreadAlertArrayStatus: DataStatus;
  unreadAlerts: Record<string, Alert | undefined>;

  paginatedAlertsIndex: AlertMap;
  dailyIndexedAlerts: DailyIndexedAlerts;
  dailyIndexedAlertsIndex: AlertMap;
  sensorAlertsIndex: IndexedSensorAlertMap;
}

export type AlertsAction = {
  reset: () => void;
  getUnreadAlerts: (params?: {
    limit?: number,
    offset?: number
  }) => PromiseVoid;
  getUnreadAlertsSilently: () => Promise<Maybe<boolean>>;
  markAlertsAsRead: (uid: Array<string>) => PromiseVoid;
  markAlertsAsReadSilently: (uid: Array<string>) => Promise<Maybe<boolean>>;
  getNewerPaginatedAlerts: () => Promise<Maybe<Array<Alert>>>;
  getNewerPaginatedSilently: () => Promise<Maybe<boolean>>;
  markAllAlertsAsRead: () => PromiseVoid; // see comments on a function
  markAllAlertsAsReadSilently: () => Promise<Maybe<boolean>>;
  getTimeframeAlerts: (p: limiters['timeframe'], forceUpdate?: boolean) => Promise<Maybe<Array<Alert>>>;
  getTimeframeAlertsSilently: (p: limiters['timeframe'], forceUpdate?: boolean) => Promise<Maybe<boolean>>;
  getPaginationAlerts: (p: limiters['pagination'], forceUpdate?: boolean) => Promise<Maybe<Array<Alert>>>;
  getPaginationAlertsSilently: (p: limiters['pagination'], forceUpdate?: boolean) => Promise<Maybe<boolean>>;
  getSensorOnlineOfflineTimeframeAlerts: (p: limiters['timeframe'], forceUpdate?: boolean) => Promise<Maybe<Array<Alert>>>;
  getSensorOnlineOfflineTimeframeAlertsSilently: (p: limiters['timeframe'], forceUpdate?: boolean) =>Promise<Maybe<boolean>>;
};

const INITIAL_STATE: IAlertsState = {
  alertsUpdated: 0,
  paginatedAlerts: [],
  sensorAlertsUpdated: 0,
  paginatedAlertsStatus: undefined,

  total: undefined,
  endCursor: undefined,

  unreadAlerts: {},
  unreadAlertCount: 0,
  unreadAlertArray: [],
  unreadAlertsStatus: undefined,
  unreadAlertArrayStatus: undefined,

  paginatedAlertsIndex: {},
  dailyIndexedAlerts: {},
  dailyIndexedAlertsIndex: {},
  sensorAlertsIndex: {},
};

export type AlertsState = IAlertsState & AlertsAction;

export const alertsStore = createStore<AlertsState>()(
  // persist(
    (set, get) => ({
      ...INITIAL_STATE,
      reset: () => set(INITIAL_STATE),
      getUnreadAlerts: withCatcher('AlertsStore.getUnreadAlerts', async ({
        offset = 0,
        limit = 30
      } = {}) => {
        const homeId = getHomeId();
        if (!homeId) {
          throw new ContextError('no homeId', { isLocal: true });
        }

        const response = await getAlertsApi(homeId, {
          offset: offset,
          limit: limit,
          acknowledged: false,
        });

        const { alertArray, alerts } = processRawAlertEntryArray(response?.data?.data);
        set({
          unreadAlerts: alerts,
          unreadAlertArray: alertArray,
          unreadAlertsStatus: 'success',
          unreadAlertArrayStatus: 'success',
        });
      }, (e) => {
        if (e.isLocal) {return;}
        set({ unreadAlertsStatus: 'error', unreadAlertArrayStatus: 'error' });
      }),
      getUnreadAlertsSilently: fnSilentWrapper(() => get().getUnreadAlerts()),
      markAlertsAsRead: withCatcher('AlertsStore.markAlertsAsRead', async (uids) => {
        const homeId = getHomeId();
        if (!homeId) {
          throw new ContextError('no homeId', { isLocal: true });
        }

        const { paginatedAlertsIndex: paginatedIndex, dailyIndexedAlertsIndex: dailyIndex } = get();

        const indexes: Array<Alert> = [];
        const unreadAlertInstances: Array<Alert> = [];

        uids.forEach(uid => {
          if (paginatedIndex[uid]) {
            indexes.push(paginatedIndex[uid]);
          }
          if (dailyIndex[uid]) {
            indexes.push(dailyIndex[uid]);
          }
          const alertFromStateByUid = get().unreadAlerts?.[uid];
          if (alertFromStateByUid) {
            unreadAlertInstances.push(alertFromStateByUid);
          }
        });

        const resp = await Promise.all(uids.map(uid => markAlertAsReadApi(homeId, uid)));
        const acknowledged_time = resp?.[0]?.data.acknowledged_timestamp;

        // update pagination
        indexes.forEach(indexed => {
          indexed.acknowledgedTime = acknowledged_time || 0;
        });

        set((state) => {
          return { paginatedAlerts: state.paginatedAlerts, paginatedAlertsStatus: 'success' };
        });

        // update sensor indexed alerts
        if (unreadAlertInstances.length) {
          unreadAlertInstances.forEach(unreadAlertInstance => {
            unreadAlertInstance.acknowledgedTime = acknowledged_time || 0;
          });

          set((state) => {
            const newUnreadAlerts = { ...state.unreadAlerts };
            const newUnreadAlertArray = [...(state.unreadAlertArray || [])];
            uids.forEach(uid => {
              delete newUnreadAlerts[uid];
              const index = newUnreadAlertArray.findIndex(a => a.uid === uid);
              newUnreadAlertArray.splice(index, 1);
            });

            return {
              count: (state.unreadAlertCount ? state.unreadAlertCount - 1 : 0),
              alerts: newUnreadAlerts,
              alertsArray: newUnreadAlertArray,
              alertsUpdated: Date.now(),
            };
          });
        }
      }),
      markAlertsAsReadSilently: fnSilentWrapper((uids) => get().markAlertsAsRead(uids)),
      markAllAlertsAsRead: withCatcher('AlertsStore.markAllAlertsAsRead', async () => {
        const homeId = getHomeId();
        if (!homeId) { throw new ContextError('there is no home id to mark all alerts as read', {isLocal: true}); }

        await markAllAlertsAsReadApi(homeId);

        set((state) => {
          const updatedState: Partial<IAlertsState> = {
            alertsUpdated: Date.now(),
            paginatedAlerts: state.paginatedAlerts,
            paginatedAlertsStatus: state.paginatedAlertsStatus,
            unreadAlerts: {},
            unreadAlertCount: 0,
            unreadAlertArray: [],
            unreadAlertsStatus: state.unreadAlertArrayStatus,
          };

          if (state.paginatedAlerts.length) {
            updatedState.paginatedAlerts = state.paginatedAlerts?.map((alert) => {
              const updatedAlert = { ...alert, acknowledgedTime: alert.acknowledgedTime = Math.round(new Date().getTime() / 1000) };

              return updatedAlert;
            });
          }

          return updatedState;
        });
      }),
      markAllAlertsAsReadSilently: fnSilentWrapper(() => get().markAllAlertsAsRead()),
      getNewerPaginatedAlerts: withCatcher('AlertsStore.getNewerPaginatedAlerts', async () => get().getPaginationAlerts({ newest: true })),
      getNewerPaginatedSilently: fnSilentWrapper(() => get().getNewerPaginatedAlerts()),
      getPaginationAlerts: withCatcher('AlertsStore.getPaginationAlerts', async (payload, forceUpdate) => {

        let query = {};

        // set limit query
        // or return if satisfied
        const { paginatedAlerts, endCursor } = get();
        const paginatedAlertsLength = paginatedAlerts.length;

        if ('newest' in payload && paginatedAlertsLength) {
          query = {
            start_timestamp: paginatedAlerts[0].createdAt,
            end_timestamp: Math.round(Date.now())
          };
        } else if (!('newest' in payload)) {
          const { pageSize, numberOfPages } = payload;

          const totalAimNumber = pageSize * numberOfPages;
          const adjustedAimNumber = !forceUpdate ? totalAimNumber - paginatedAlertsLength : totalAimNumber;

          console.log('[AlertsStore] getPaginatedAlerts aim:', { totalAimNumber, adjustedAimNumber });

          if (adjustedAimNumber < 1) {
            return paginatedAlerts.slice(0, adjustedAimNumber);
          }

          // TODO: I think there is a better way to address hard limit of 100 items per request imposed by frontend api
          query = !forceUpdate ? {
            limit: adjustedAimNumber <= 100 ? adjustedAimNumber : 100,
            offset: endCursor ? endCursor : 0
          } : {
            offset: 0,
            limit: adjustedAimNumber <= 100 ? adjustedAimNumber : 100,
          };
        }

        // try obtaining alerts
        const homeUid = getHomeId();

        if (!homeUid) { throw new ContextError('no home to get paginated alerts by', { isLocal: true }); }
        const resp = await getAlertsApi(homeUid, {...query, type: [
          'PROFILE_EXIT',
          'PROFILE_ENTER',
          'UNKNOWN_DEVICE',
          'UNASSIGNED_DEVICE',
          'UNIT_ONLINE_OFFLINE',
        ]});

        // digest alerts
        const { data: alerts, pagination } = resp.data;

        const {next_offset: new_offset, total_records} = pagination;

        const { dailyIndexedAlerts, paginatedAlertsIndex: currentIndex, dailyIndexedAlertsIndex } = get();

        const { digestedData, indexedData } = digestRawAlerts(alerts, true, dailyIndexedAlerts, dailyIndexedAlertsIndex);
        // console.log('[AlertsStore] received pagination alerts response', { resp, digestedData, alerts });
        // update pagination and paginatedAlerts
        // set startCursor on very first request:
        // or if we got newer alerts
        set((state) => {
          const newDigestedData = digestedData.filter(a => !currentIndex[a.uid]);

          return {
            paginatedAlertsIndex: { ...currentIndex, ...indexedData },
            endCursor: new_offset || 0,
            paginatedAlerts: forceUpdate ?
              [...digestedData]
              : [...(state.paginatedAlerts || []), ...newDigestedData],
            paginatedAlertsStatus: 'success',
            total: total_records,
          };
        });

        return digestedData;
      }),
      getPaginationAlertsSilently: fnSilentWrapper((payload, forceUpdate) => get().getPaginationAlerts(payload, forceUpdate)),
      getTimeframeAlerts: withCatcher('AlertsStore.getTimeframeAlerts', async (payload, forceUpdate = false) => {
        const { epochEnd, epochStart, startMarker: markerOne, endMarker: markerTwo, ends } = getDatesDataForTimeframe(payload);

        console.log('[AlertStore] time start, time end', { timeStart: payload.timeStart, timeEnd: payload.timeEnd, markerOne, markerTwo, ends });
        let responseCollection: Array<Alert> = [];
        const { dailyIndexedAlerts } = get();

        const startsAtLesserEnd = !!dailyIndexedAlerts[ends[0]]?.complete;

        console.log('[AlertsStore] trying markers', { startsAtLesserEnd, ends, markerOne, markerTwo, dailyIndexedAlerts });
        // if there is a start and an end -> see if all the days are complete and return
        // go from start
        
        let isIncomplete;
        if (startsAtLesserEnd) {
          for (let dm = ends[0]; dm <= ends[1]; dm++) {
            isIncomplete = eachForGetTimeframeAlerts(dm, dailyIndexedAlerts, responseCollection, ends, { forceUpdate, startsAtLesserEnd });
            if (isIncomplete) { break; }
          }
        } else {
          for (let dm = ends[1]; dm >= ends[0]; dm--) {
            isIncomplete = eachForGetTimeframeAlerts(dm, dailyIndexedAlerts, responseCollection, ends, { forceUpdate, startsAtLesserEnd });
            if (isIncomplete) { break; }
          }
          responseCollection.reverse();
        }
        console.log('[AlertsStore] populated response:', { responseCollection, isIncomplete });
        const startEpochMarker = Math.floor(startOfDay(setDayOfYear(setYear(new Date(), Math.floor(ends[0] / 1000)), ends[0] % 1000)).getTime()); // time in milliseconds
        const endEpochMarker = Math.floor(endOfDay(setDayOfYear(setYear(new Date(), Math.floor(ends[1] / 1000)), ends[1] % 1000)).getTime()); // time is milliseconds

        //get missing days
        if (isIncomplete) {
          const homeId = getHomeId();

            if (!homeId) { throw new ContextError('No home id to query by', {isLocal: true}); }
            const resp = await getAlertsApi(homeId, {
              start_timestamp: startEpochMarker,
              end_timestamp: endEpochMarker,
              type: acceptedAlerts,
            });

            // digest respospose
            const { data: timeframeAlerts } = resp.data;
            const { dailyIndexedAlerts, dailyIndexedAlertsIndex } = get();

            const { digestedData } =
              digestRawAlerts(timeframeAlerts, undefined, dailyIndexedAlerts, dailyIndexedAlertsIndex);

            // console.log('[AlertsStore] digested main response:', { digestedData });
            // merge, filter and sort
            set({ alertsUpdated: Date.now() });
            responseCollection = startsAtLesserEnd ? ([...digestedData, ...responseCollection]) : ([...responseCollection, ...digestedData]);

        }

        // console.log('[AlertsStore] filtering responseCollection', { responseCollection, epochStart, epochEnd });
        return responseCollection.filter(a => a.createdAt > epochStart && a.createdAt < epochEnd);
      }),
      getTimeframeAlertsSilently: fnSilentWrapper((payload, forceUpdate) => get().getTimeframeAlerts(payload, forceUpdate)),
      getSensorOnlineOfflineTimeframeAlerts: withCatcher('AlertsStore.getSensorOnlineOfflineTimeframeAlerts', async (payload, forceUpdate) => {
        const { epochEnd, epochStart, startMarker, endMarker, ends } = getDatesDataForTimeframe(payload);
        console.log('[AlertsStore] time start, time end sensor alerts', { timeStart: payload.timeStart, timeEnd: payload.timeEnd, startMarker, endMarker, ends });
        let responseCollection: Array<Alert> = [];

        const { dailyIndexedAlerts, sensorAlertsIndex } = get();

        const startsAtLesserEnd = !!dailyIndexedAlerts[ends[0]]?.complete;

        console.log('[AlertsStore] trying markers', { startsAtLesserEnd, ends, startMarker, endMarker, dailyIndexedAlerts });
        // if there is a start and an end -> see if all the days are complete and return
        // go from start
        let isIncomplete;
        for (let dm = ends[0]; dm <= ends[1]; dm++) {
          isIncomplete = eachForGetSensorOnlineOfflineTimeframeAlerts(dm, sensorAlertsIndex, responseCollection, ends, { forceUpdate, startsAtLesserEnd });
          if (isIncomplete) { break; }
        }

        if (isIncomplete) {
          const homeId = getHomeId();
          if (!homeId) {
            throw new ContextError('getSensorOnlineOfflineTimeframeAlerts No home id found', { isLocal: true });
          }

          const resp = await getAlertsApi(homeId, {
            start_timestamp: epochStart,
            end_timestamp: epochEnd,
            type: 'UNIT_ONLINE_OFFLINE'
          });

          console.log('[AlertsStore] got data', { resp, epochStart, epochEnd });
          const rawAlerts = resp.data?.data;

          const { sensorAlertsIndex } = get();

          const digestedResponse = digestSensorsResponse({
            rawAlerts: [
              {
                created_at: epochEnd + 1000,
                uid: 'generic-after-alert',
                type: 'UNIT_ONLINE_OFFLINE',
                home_id: homeId,
                contributing_factors: {
                  status: (rawAlerts[0]?.contributing_factors as any)?.status === 'online' ? 'offline' : 'online', //'offline'
                  unit_id: 0,
                  unit_type: 'sensor',
                  deployment: false
                },
                push_notification: rawAlerts[0]?.push_notification ? rawAlerts[0]?.push_notification : [],
              },
              ...rawAlerts,
              {
                created_at: epochStart - 1.1,
                uid: 'generic-before-alert',
                type: 'UNIT_ONLINE_OFFLINE',
                home_id: homeId,
                contributing_factors: {
                  status: (rawAlerts[rawAlerts.length - 1]?.contributing_factors as any)?.status === 'online' ? 'offline' : 'online', //'offline' 
                  unit_id: 0,
                  unit_type: 'sensor',
                  deployment: false
                },
                push_notification: rawAlerts[rawAlerts.length - 1]?.push_notification ? rawAlerts[rawAlerts.length - 1]?.push_notification : [],
              }],
            startMarker,
            endMarker
          }, sensorAlertsIndex);

          console.log('[AlertsStore] digested sensor response', digestedResponse);
          responseCollection = digestedResponse;
          set({ sensorAlertsUpdated: Date.now() });

          return digestedResponse;

        }

        return responseCollection;
      }),
      getSensorOnlineOfflineTimeframeAlertsSilently: fnSilentWrapper((payload, forceUpdate) => get().getSensorOnlineOfflineTimeframeAlerts(payload, forceUpdate)),
    }),
  //   {
  //     name: 'alerts-storage',
  //   },
  // ),
);
