import {
  Alert,
  AlertSettingsAlertType,
  AlertSettingsSensitivityType,
  Maybe
} from '../types';
import { ENV_HOLDER } from '../env';
import { RawDeviceType } from '../devices';

// Many of these events will likely be migrated from inside the app to be backend.
// This will happen the same time we move away from Firebase and towards our own event logging system

const logAnalyticsEvent = ENV_HOLDER.settings.logAnalyticsEvent;

//
// App State
//
export const logAppStateChange = (appState: string) =>
  logAnalyticsEvent('app_state_change', { appState });

// 
// Account (user and home details) changes
//
type ChangedUserDetails = {
  userUid: string,
  accountUid?: string
};
export const logChangedUserDetails = (data: ChangedUserDetails) =>
  logAnalyticsEvent('changed_user_details', data);
type ChangedHomeDetails = {
  homeUid: string
};
export const logChangedHomeDetails = (data: ChangedHomeDetails) =>
  logAnalyticsEvent('changed_home_details', data);

//
// Profiles
//
type LogCreateProfileEventData = {
  profileUid: string;
};
export const logCreateProfileEvent = (data: LogCreateProfileEventData) =>
  logAnalyticsEvent('create_profile', data);

type LogDeleteProfileEventData = {
  profileUid: string;
};
export const logDeleteProfileEvent = (data: LogDeleteProfileEventData) =>
  logAnalyticsEvent('delete_profile', data);

type LogProfileNameChangeEventData = {
  profileUid: string;
  toName: string;
  fromName: string;
};
export const logProfileNameChangeEvent = (data: LogProfileNameChangeEventData) => 
  logAnalyticsEvent('profile_name_change', data);

type ProfileNotificationsChangeEventData = {
  profileUid: string;
  notificationsType: 'enter'|'exit';
  notificationsValue: boolean;
};
export const logProfileNotificationsChangeEvent = (data: ProfileNotificationsChangeEventData) =>
  logAnalyticsEvent('profile_notifications_change', data);

//
// Device
//
type LogDeviceTypeChangeEventData = {
  deviceUid: string;
  fromType?: RawDeviceType;
  toType?: RawDeviceType;
};
export const logDeviceTypeChangeEvent = (data: LogDeviceTypeChangeEventData) =>
  logAnalyticsEvent('device_type_change', data);

type LogDeviceNameChangeEventData = {
  deviceUid: string;
  fromName?: string;
  toName?: string;
};
export const logDeviceNameChangeEvent = (data: LogDeviceNameChangeEventData) =>
  logAnalyticsEvent('device_name_change', data);

type LogAssignedDeviceEventData = {
  deviceUid: string;
  fromProfileUid?: Maybe<string>;
  toProfileUid?: Maybe<string>;
};
export const logDeviceAssignedEvent = (data: LogAssignedDeviceEventData) =>
  logAnalyticsEvent('assign_device', data);

//
// Sensor Setup
//
export const logSensorSetupStarted = (setupRunUid: string) =>
  logAnalyticsEvent('sensor_setup_started', { setupRunUid });

type logSetupBluetoothStatusData = {
  bluetoothStatus: number;
  setupRunUid: string;
  sensorUid?: string;
};

export const logSensorSetupBluetoothStatus = (
  data: logSetupBluetoothStatusData,
) => {
  logAnalyticsEvent('setup_bluetooth_status', data);
};

type LogSensorSetupStepChangeData = {
  step: number;
  stepName: string;
  duration: number;
  setupRunUid: string;
  sensorUid?: string;
};
export const logSensorSetupStepChange = (data: LogSensorSetupStepChangeData) =>
  logAnalyticsEvent('setup_step', data);

type logSetupCompleteData = {
  duration: number;
  setupRunUid: string;
  sensorUid: string;
};
export const logSensorSetupComplete = (data: logSetupCompleteData) =>
  logAnalyticsEvent('setup_complete', data);

type logSetupErrorData = {
  duration: number;
  setupRunUid: string;
  sensorUid?: string;
  errorCode: number;
  userMessage: string;
  systemMessage: string;
};
export const logSensorSetupError = (data: logSetupErrorData) =>
  logAnalyticsEvent('setup_error', data);

//
// Sensor details
//
type LogSensorFactoryResetEvent = {
  sensorUid: string
};
export const logSensorFactoryReset = (data: LogSensorFactoryResetEvent) =>
  logAnalyticsEvent('sensor_factory_reset', data);

// Wifi Credential Change
export const logWifiCredChangeStarted = (credChangeUid: string) =>
  logAnalyticsEvent('wifi_cred_change_started', { credChangeUid });

type LogWifiCredChangeBluetoothStatusData = {
  bluetoothStatus: number;
  wifiCredChangeRunUid: string;
  sensorUid?: string;
};

export const logWifiCredChangeBluetoothStatus = (data: LogWifiCredChangeBluetoothStatusData) => 
  logAnalyticsEvent('wifi_change_bluetooth_status', data);

type LogWifiCredChangeStepChangeData = {
  step: number;
  stepName: string;
  duration: number;
  setupRunUid: string;
  sensorUid?: string;
};
export const logWifiCredStepChange = (data: LogWifiCredChangeStepChangeData) =>
  logAnalyticsEvent('wifi_change_step', data);

type LogWifiCredChangeCompleteData = {
  duration: number;
  setupRunUid: string;
  sensorUid: string;
};
export const logWifiCredChangeComplete = (data: LogWifiCredChangeCompleteData) => 
  logAnalyticsEvent('wifi_change_complete', data);

type LogWifiCredChangeErrorData = {
  duration: number;
  setupRunUid: string;
  sensorUid?: string;
  errorCode: number;
  userMessage: string;
  systemMessage: string;
};
export const logWifiCredChangeError = (data: LogWifiCredChangeErrorData) =>
  logAnalyticsEvent('wifi_change_error', data);

//
// Onboarding
//
export const logOnboardingStarted = () =>
  logAnalyticsEvent('onboarding_started');

export const logOnboardingGettingStartedComplete = () =>
  logAnalyticsEvent('onboarding_getting_started_complete');

type LogOnboardingProfilesCompleteData = {
  numberOfProfiles?: number;
};

export const logOnboardingProfilesComplete = (data: LogOnboardingProfilesCompleteData) => 
  logAnalyticsEvent('onboarding_profiles_complete', data);

export const logOnboardingComplete = () =>
  logAnalyticsEvent('onboarding_complete');

//
// QR Setup
//
type QrScreenOpened = {
  profileUid: string;
};
export const logQrScreenOpened = (data: QrScreenOpened) =>
  logAnalyticsEvent('qr_screen_opened', data);

type QrCodeSuccess = {
  profileUid: string;
};
export const logQrCodeSuccess = (data: QrCodeSuccess) =>
  logAnalyticsEvent('qr_code_success', data);

type QrCodeError = {
  profileUid: string;
};
export const logQrCodeError = (data: QrCodeError) =>
  logAnalyticsEvent('qr_code_error', data);

//
// Alerts
//
// alerts details alert view
type AlertDetailsPageViewedData = {
  alertType: Alert['alertType']
};
export const logAlertDetailsPageViewed = (data: AlertDetailsPageViewedData) =>
  logAnalyticsEvent('alert_details_view', data);

// wigle scale change
type WigleScaleMode = {
  mode: 'one'|'few'|'many'
};
export const logWigleScaleMode = (data: WigleScaleMode) =>
  logAnalyticsEvent('wigle_scale_mode', data);

// sensitivity
type AlertSensitivityChangeData = {
  alertKind: AlertSettingsAlertType
  targetValue: 'on'|'off'|AlertSettingsSensitivityType
};
export const logAlertSensitivityChange = (data: AlertSensitivityChangeData) =>
  logAnalyticsEvent('alert_sensitivity_change', data);

// feedback
type AlertFeedbackData = {
  alertType: Alert['alertType'],
  alertUid: string
};
export const logAlertFeedback = (data: AlertFeedbackData) =>
  logAnalyticsEvent('alert_feedback', data);

//
// Tell me when
//
type TellMeWhenCreatedData = {
  variationType?: string,
  profileUid?: string,
  type: 'interval'|'duration'
};
export const logTellMeWhenCreated = (data: TellMeWhenCreatedData) =>
  logAnalyticsEvent('create_tell_me_when', data);

//
// Time Machine
//
type TimeMachineActionData = {
  actionType: 'refDate'|'dateMode'|'dayChange'|'weekChange'|'sliderGesture',
  value?: string
};
export const logTimeMachineAction = (data: TimeMachineActionData) =>
  logAnalyticsEvent('time_machine_action', data);

//
// Support
//
type ContactSupportData = {
  targetType: 'call'|'email'|'help_pages'|'chat'|'feedback'|'button'
};
export const logContactSupport = (data: ContactSupportData) => 
  logAnalyticsEvent('contact_support', data);


//
// TO DEPRECATE
//
type ProfileDeviceActionEvent = {
  action: string,
  deviceUid?: string
};
export const logProfileDeviceActionEvent = (data: ProfileDeviceActionEvent) =>
  logAnalyticsEvent('profile_device_action', data);
