import React, {useMemo,useEffect} from 'react';
import {
  AmountOverDateGraph,
  Loading
} from 'components';
import {
  ActivityReportItem
} from '@ubiety/fe-api';
import {
  ProvidersColorsMap
} from 'config';
import {
  startOfHour,
  subHours
} from 'date-fns';
import {
  useRawCellBucket
} from 'hooks';
import { processRawCellBucketItem } from 'helpers';
import { useSensorsStore } from 'context';

export const RecentCellActivityGraph: React.FC<{
  tmsiOnly?: boolean
}> = ({
  tmsiOnly=false
}) => {
  const {sensorArray} = useSensorsStore(s => s);
  const sensor = useMemo(() => sensorArray[0], [sensorArray]);
  const {
    startTimestamp,
    endTimestamp
  } = useMemo(() => {
    const now = startOfHour(Date.now() + 60 * 60 * 1000);
    return {
      startTimestamp: subHours(now, 6).getTime(),
      endTimestamp: now.getTime()
    };
  },[]);

  // const {
  //   loading: cellScanLoading,
  //   filteredActivityReport
  // } = useActivityReport({
  //   sensorId: sensorUid,
  //   preserveByName: 'recentCellularActivity',
  //   dateMode: 'day',
  //   binSizeMin: 'hour'
  // });

  // const {
  //   loading: cellLoading,
  //   filteredTmsiReport
  // } = useTmsiActivity({
  //   sensorId: sensorUid,
  //   dateMode: 'day',
  //   preserveByName: 'recentCellularActivity'
  // });


  // useRawCellBucket to get cell data
  const {
    loading: cellLoading,
    rawItems: cellRawItems
  } = useRawCellBucket({
    source: 'cell',
    sensorId: sensor?.uid || '',
    startTimestamp,
    endTimestamp,
    binSize: 'hour'
  });
  // useRawCellBucket to get cell scan data
  const {
    loading: cellScanLoading,
    rawItems: cellScanRawItems,
  } = useRawCellBucket({
    source: 'cell-scan',
    sensorId: sensor?.uid || '',
    startTimestamp,
    endTimestamp,
    binSize: 'hour'
  }); 

  const mergedData = useMemo(function mergeData(){
    const mergedKeyed: {
      [key: string]: {
        label: string;
        items: Array<({
          amount: number;
          date: Date;
        })|ActivityReportItem>
      }
    } = {
      'att': {
        label: 'att',
        items: []
      },
      'tmobile': {
        label: 'tmobile',
        items: []
      },
      'verizon': {
        label: 'verizon',
        items: []
      },
      'other': {
        label: 'other',
        items: []
      }
    };

    const processedCellBuckets = cellRawItems?.map(item => processRawCellBucketItem(item));
    processedCellBuckets?.forEach(item => {
      mergedKeyed[item.providerLabel]?.['items']?.push?.({
        amount: item.count,
        date: new Date(item.timestamp)
      })
    });  

    if(!tmsiOnly){
      const processedCellScanBuckets = cellScanRawItems?.map(item => processRawCellBucketItem(item));
      processedCellScanBuckets?.forEach(item => {
        mergedKeyed[item.providerLabel]?.['items']?.push?.({
          amount: item.count,
          date: new Date(item.timestamp)
        })
      });
    }

    return Object.values(mergedKeyed);
  },[
    cellScanRawItems,
    cellRawItems,
    tmsiOnly
  ]);

  useEffect(function logChangedData(){
    console.log('[RecentCellActivityGraph] values', 
    {cellRawItems, cellScanRawItems, mergedData, startTimestamp, endTimestamp});
  },[
    cellRawItems,
    cellScanRawItems,
    startTimestamp,
    endTimestamp,
    mergedData
  ]);

  return <AmountOverDateGraph
    endLabel={'NOW'}
    dataGroups={mergedData}
    startDate={new Date(startTimestamp)}
    width={628}
    height={284}
    label={"Number of \nScans"}
    labelToColorMap={ProvidersColorsMap}
    dateTicks={'hours'}
    padding={{
      top: 5,
      right: 90,
      left: 90,
      bottom: 20
    }}
    axisSpacing={{
      top: 0,
      right: 50,
      left: 50,
      bottom: 6
    }}
    endDate={new Date(endTimestamp)}
    numberOfVerticalTicks={7}
    numberOfBins={7}
    gridCellHeight={0}
    smothing={false}
    >
    {cellScanLoading||(cellLoading) ?
      <Loading x="246" y="92" /> : null
    }

  </AmountOverDateGraph>
}

// const mockedReport = {
//     "unsortedActivityReport": [
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 160,
//             "epochTimestamp": 1693594230,
//             "date": new Date("2023-09-01T18:50:30.000Z"),
//             "timestamp": 1693594230000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 181,
//             "epochTimestamp": 1693112400,
//             "date": new Date("2023-08-27T05:00:00.000Z"),
//             "timestamp": 1693112400000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "unknown",
//             "amount": 1,
//             "epochTimestamp": 1693112400,
//             "date": new Date("2023-08-27T05:00:00.000Z"),
//             "timestamp": 1693112400000,
//             "label": "other"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "Verizon Wireless",
//             "amount": 12,
//             "epochTimestamp": 1693112400,
//             "date": new Date("2023-08-27T05:00:00.000Z"),
//             "timestamp": 1693112400000,
//             "label": "verizon"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "unknown",
//             "amount": 3,
//             "epochTimestamp": 1693116000,
//             "date": new Date("2023-08-27T06:00:00.000Z"),
//             "timestamp": 1693116000000,
//             "label": "other"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 93,
//             "epochTimestamp": 1693116000,
//             "date": new Date("2023-08-27T06:00:00.000Z"),
//             "timestamp": 1693116000000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "Verizon Wireless",
//             "amount": 6,
//             "epochTimestamp": 1693116000,
//             "date": new Date("2023-08-27T06:00:00.000Z"),
//             "timestamp": 1693116000000,
//             "label": "verizon"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "unknown",
//             "amount": 1,
//             "epochTimestamp": 1693119600,
//             "date": new Date("2023-08-27T07:00:00.000Z"),
//             "timestamp": 1693119600000,
//             "label": "other"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "Verizon Wireless",
//             "amount": 4,
//             "epochTimestamp": 1693119600,
//             "date": new Date("2023-08-27T07:00:00.000Z"),
//             "timestamp": 1693119600000,
//             "label": "verizon"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 80,
//             "epochTimestamp": 1693119600,
//             "date": new Date("2023-08-27T07:00:00.000Z"),
//             "timestamp": 1693119600000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "Verizon Wireless",
//             "amount": 2,
//             "epochTimestamp": 1693123200,
//             "date": new Date("2023-08-27T08:00:00.000Z"),
//             "timestamp": 1693123200000,
//             "label": "verizon"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "unknown",
//             "amount": 2,
//             "epochTimestamp": 1693123200,
//             "date": new Date("2023-08-27T08:00:00.000Z"),
//             "timestamp": 1693123200000,
//             "label": "other"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 65,
//             "epochTimestamp": 1693123200,
//             "date": new Date("2023-08-27T08:00:00.000Z"),
//             "timestamp": 1693123200000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 86,
//             "epochTimestamp": 1693126800,
//             "date": new Date("2023-08-27T09:00:00.000Z"),
//             "timestamp": 1693126800000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "Verizon Wireless",
//             "amount": 2,
//             "epochTimestamp": 1693126800,
//             "date": new Date("2023-08-27T09:00:00.000Z"),
//             "timestamp": 1693126800000,
//             "label": "verizon"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 120,
//             "epochTimestamp": 1693130400,
//             "date": new Date("2023-08-27T10:00:00.000Z"),
//             "timestamp": 1693130400000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 132,
//             "epochTimestamp": 1693134000,
//             "date": new Date("2023-08-27T11:00:00.000Z"),
//             "timestamp": 1693134000000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "unknown",
//             "amount": 14,
//             "epochTimestamp": 1693137600,
//             "date": new Date("2023-08-27T12:00:00.000Z"),
//             "timestamp": 1693137600000,
//             "label": "other"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 142,
//             "epochTimestamp": 1693137600,
//             "date": new Date("2023-08-27T12:00:00.000Z"),
//             "timestamp": 1693137600000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "unknown",
//             "amount": 14,
//             "epochTimestamp": 1693141200,
//             "date": new Date("2023-08-27T13:00:00.000Z"),
//             "timestamp": 1693141200000,
//             "label": "other"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 136,
//             "epochTimestamp": 1693141200,
//             "date": new Date("2023-08-27T13:00:00.000Z"),
//             "timestamp": 1693141200000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "Verizon Wireless",
//             "amount": 4,
//             "epochTimestamp": 1693141200,
//             "date": new Date("2023-08-27T13:00:00.000Z"),
//             "timestamp": 1693141200000,
//             "label": "verizon"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 262,
//             "epochTimestamp": 1693144800,
//             "date": new Date("2023-08-27T14:00:00.000Z"),
//             "timestamp": 1693144800000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "unknown",
//             "amount": 6,
//             "epochTimestamp": 1693144800,
//             "date": new Date("2023-08-27T14:00:00.000Z"),
//             "timestamp": 1693144800000,
//             "label": "other"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "Verizon Wireless",
//             "amount": 16,
//             "epochTimestamp": 1693144800,
//             "date": new Date("2023-08-27T14:00:00.000Z"),
//             "timestamp": 1693144800000,
//             "label": "verizon"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 92,
//             "epochTimestamp": 1693148400,
//             "date": new Date("2023-08-27T15:00:00.000Z"),
//             "timestamp": 1693148400000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "unknown",
//             "amount": 4,
//             "epochTimestamp": 1693148400,
//             "date": new Date("2023-08-27T15:00:00.000Z"),
//             "timestamp": 1693148400000,
//             "label": "other"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "Verizon Wireless",
//             "amount": 32,
//             "epochTimestamp": 1693148400,
//             "date": new Date("2023-08-27T15:00:00.000Z"),
//             "timestamp": 1693148400000,
//             "label": "verizon"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "unknown",
//             "amount": 2,
//             "epochTimestamp": 1693152000,
//             "date": new Date("2023-08-27T16:00:00.000Z"),
//             "timestamp": 1693152000000,
//             "label": "other"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 77,
//             "epochTimestamp": 1693152000,
//             "date": new Date("2023-08-27T16:00:00.000Z"),
//             "timestamp": 1693152000000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "Verizon Wireless",
//             "amount": 8,
//             "epochTimestamp": 1693152000,
//             "date": new Date("2023-08-27T16:00:00.000Z"),
//             "timestamp": 1693152000000,
//             "label": "verizon"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 240,
//             "epochTimestamp": 1693155600,
//             "date": new Date("2023-08-27T17:00:00.000Z"),
//             "timestamp": 1693155600000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "Verizon Wireless",
//             "amount": 15,
//             "epochTimestamp": 1693155600,
//             "date": new Date("2023-08-27T17:00:00.000Z"),
//             "timestamp": 1693155600000,
//             "label": "verizon"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 263,
//             "epochTimestamp": 1693159200,
//             "date": new Date("2023-08-27T18:00:00.000Z"),
//             "timestamp": 1693159200000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "unknown",
//             "amount": 3,
//             "epochTimestamp": 1693159200,
//             "date": new Date("2023-08-27T18:00:00.000Z"),
//             "timestamp": 1693159200000,
//             "label": "other"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "Verizon Wireless",
//             "amount": 4,
//             "epochTimestamp": 1693159200,
//             "date": new Date("2023-08-27T18:00:00.000Z"),
//             "timestamp": 1693159200000,
//             "label": "verizon"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "unknown",
//             "amount": 8,
//             "epochTimestamp": 1693162800,
//             "date": new Date("2023-08-27T19:00:00.000Z"),
//             "timestamp": 1693162800000,
//             "label": "other"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "Verizon Wireless",
//             "amount": 47,
//             "epochTimestamp": 1693162800,
//             "date": new Date("2023-08-27T19:00:00.000Z"),
//             "timestamp": 1693162800000,
//             "label": "verizon"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 204,
//             "epochTimestamp": 1693162800,
//             "date": new Date("2023-08-27T19:00:00.000Z"),
//             "timestamp": 1693162800000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "unknown",
//             "amount": 7,
//             "epochTimestamp": 1693166400,
//             "date": new Date("2023-08-27T20:00:00.000Z"),
//             "timestamp": 1693166400000,
//             "label": "other"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 215,
//             "epochTimestamp": 1693166400,
//             "date": new Date("2023-08-27T20:00:00.000Z"),
//             "timestamp": 1693166400000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "Verizon Wireless",
//             "amount": 28,
//             "epochTimestamp": 1693166400,
//             "date": new Date("2023-08-27T20:00:00.000Z"),
//             "timestamp": 1693166400000,
//             "label": "verizon"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 145,
//             "epochTimestamp": 1693170000,
//             "date": new Date("2023-08-27T21:00:00.000Z"),
//             "timestamp": 1693170000000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "unknown",
//             "amount": 8,
//             "epochTimestamp": 1693170000,
//             "date": new Date("2023-08-27T21:00:00.000Z"),
//             "timestamp": 1693170000000,
//             "label": "other"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "Verizon Wireless",
//             "amount": 45,
//             "epochTimestamp": 1693170000,
//             "date": new Date("2023-08-27T21:00:00.000Z"),
//             "timestamp": 1693170000000,
//             "label": "verizon"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "Verizon Wireless",
//             "amount": 158,
//             "epochTimestamp": 1693173600,
//             "date": new Date("2023-08-27T22:00:00.000Z"),
//             "timestamp": 1693173600000,
//             "label": "verizon"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 284,
//             "epochTimestamp": 1693173600,
//             "date": new Date("2023-08-27T22:00:00.000Z"),
//             "timestamp": 1693173600000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "unknown",
//             "amount": 7,
//             "epochTimestamp": 1693177200,
//             "date": new Date("2023-08-27T23:00:00.000Z"),
//             "timestamp": 1693177200000,
//             "label": "other"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "Verizon Wireless",
//             "amount": 14,
//             "epochTimestamp": 1693177200,
//             "date": new Date("2023-08-27T23:00:00.000Z"),
//             "timestamp": 1693177200000,
//             "label": "verizon"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 203,
//             "epochTimestamp": 1693177200,
//             "date": new Date("2023-08-27T23:00:00.000Z"),
//             "timestamp": 1693177200000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 427,
//             "epochTimestamp": 1693180800,
//             "date": new Date("2023-08-28T00:00:00.000Z"),
//             "timestamp": 1693180800000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "unknown",
//             "amount": 13,
//             "epochTimestamp": 1693180800,
//             "date": new Date("2023-08-28T00:00:00.000Z"),
//             "timestamp": 1693180800000,
//             "label": "other"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "Verizon Wireless",
//             "amount": 16,
//             "epochTimestamp": 1693180800,
//             "date": new Date("2023-08-28T00:00:00.000Z"),
//             "timestamp": 1693180800000,
//             "label": "verizon"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "Verizon Wireless",
//             "amount": 6,
//             "epochTimestamp": 1693184400,
//             "date": new Date("2023-08-28T01:00:00.000Z"),
//             "timestamp": 1693184400000,
//             "label": "verizon"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "unknown",
//             "amount": 39,
//             "epochTimestamp": 1693184400,
//             "date": new Date("2023-08-28T01:00:00.000Z"),
//             "timestamp": 1693184400000,
//             "label": "other"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 271,
//             "epochTimestamp": 1693184400,
//             "date": new Date("2023-08-28T01:00:00.000Z"),
//             "timestamp": 1693184400000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "Verizon Wireless",
//             "amount": 4,
//             "epochTimestamp": 1693188000,
//             "date": new Date("2023-08-28T02:00:00.000Z"),
//             "timestamp": 1693188000000,
//             "label": "verizon"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 87,
//             "epochTimestamp": 1693188000,
//             "date": new Date("2023-08-28T02:00:00.000Z"),
//             "timestamp": 1693188000000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "unknown",
//             "amount": 80,
//             "epochTimestamp": 1693188000,
//             "date": new Date("2023-08-28T02:00:00.000Z"),
//             "timestamp": 1693188000000,
//             "label": "other"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "Verizon Wireless",
//             "amount": 9,
//             "epochTimestamp": 1693191600,
//             "date": new Date("2023-08-28T03:00:00.000Z"),
//             "timestamp": 1693191600000,
//             "label": "verizon"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 201,
//             "epochTimestamp": 1693191600,
//             "date": new Date("2023-08-28T03:00:00.000Z"),
//             "timestamp": 1693191600000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "AT&T Mobility",
//             "amount": 4,
//             "epochTimestamp": 1693191600,
//             "date": new Date("2023-08-28T03:00:00.000Z"),
//             "timestamp": 1693191600000,
//             "label": "at&t"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "unknown",
//             "amount": 3,
//             "epochTimestamp": 1693191600,
//             "date": new Date("2023-08-28T03:00:00.000Z"),
//             "timestamp": 1693191600000,
//             "label": "other"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 134,
//             "epochTimestamp": 1693195200,
//             "date": new Date("2023-08-28T04:00:00.000Z"),
//             "timestamp": 1693195200000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "AT&T Mobility",
//             "amount": 8,
//             "epochTimestamp": 1693195200,
//             "date": new Date("2023-08-28T04:00:00.000Z"),
//             "timestamp": 1693195200000,
//             "label": "at&t"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "Verizon Wireless",
//             "amount": 26,
//             "epochTimestamp": 1693195200,
//             "date": new Date("2023-08-28T04:00:00.000Z"),
//             "timestamp": 1693195200000,
//             "label": "verizon"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "unknown",
//             "amount": 2,
//             "epochTimestamp": 1693198800,
//             "date": new Date("2023-08-28T05:00:00.000Z"),
//             "timestamp": 1693198800000,
//             "label": "other"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "AT&T Mobility",
//             "amount": 9,
//             "epochTimestamp": 1693198800,
//             "date": new Date("2023-08-28T05:00:00.000Z"),
//             "timestamp": 1693198800000,
//             "label": "at&t"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 182,
//             "epochTimestamp": 1693198800,
//             "date": new Date("2023-08-28T05:00:00.000Z"),
//             "timestamp": 1693198800000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "Verizon Wireless",
//             "amount": 23,
//             "epochTimestamp": 1693198800,
//             "date": new Date("2023-08-28T05:00:00.000Z"),
//             "timestamp": 1693198800000,
//             "label": "verizon"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 282,
//             "epochTimestamp": 1693202400,
//             "date": new Date("2023-08-28T06:00:00.000Z"),
//             "timestamp": 1693202400000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "unknown",
//             "amount": 2,
//             "epochTimestamp": 1693202400,
//             "date": new Date("2023-08-28T06:00:00.000Z"),
//             "timestamp": 1693202400000,
//             "label": "other"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "AT&T Mobility",
//             "amount": 10,
//             "epochTimestamp": 1693202400,
//             "date": new Date("2023-08-28T06:00:00.000Z"),
//             "timestamp": 1693202400000,
//             "label": "at&t"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "Verizon Wireless",
//             "amount": 36,
//             "epochTimestamp": 1693202400,
//             "date": new Date("2023-08-28T06:00:00.000Z"),
//             "timestamp": 1693202400000,
//             "label": "verizon"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "Verizon Wireless",
//             "amount": 7,
//             "epochTimestamp": 1693206000,
//             "date": new Date("2023-08-28T07:00:00.000Z"),
//             "timestamp": 1693206000000,
//             "label": "verizon"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "AT&T Mobility",
//             "amount": 9,
//             "epochTimestamp": 1693206000,
//             "date": new Date("2023-08-28T07:00:00.000Z"),
//             "timestamp": 1693206000000,
//             "label": "at&t"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 94,
//             "epochTimestamp": 1693206000,
//             "date": new Date("2023-08-28T07:00:00.000Z"),
//             "timestamp": 1693206000000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "AT&T Mobility",
//             "amount": 10,
//             "epochTimestamp": 1693209600,
//             "date": new Date("2023-08-28T08:00:00.000Z"),
//             "timestamp": 1693209600000,
//             "label": "at&t"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 72,
//             "epochTimestamp": 1693209600,
//             "date": new Date("2023-08-28T08:00:00.000Z"),
//             "timestamp": 1693209600000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "unknown",
//             "amount": 1,
//             "epochTimestamp": 1693209600,
//             "date": new Date("2023-08-28T08:00:00.000Z"),
//             "timestamp": 1693209600000,
//             "label": "other"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "Verizon Wireless",
//             "amount": 13,
//             "epochTimestamp": 1693209600,
//             "date": new Date("2023-08-28T08:00:00.000Z"),
//             "timestamp": 1693209600000,
//             "label": "verizon"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "AT&T Mobility",
//             "amount": 15,
//             "epochTimestamp": 1693213200,
//             "date": new Date("2023-08-28T09:00:00.000Z"),
//             "timestamp": 1693213200000,
//             "label": "at&t"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 84,
//             "epochTimestamp": 1693213200,
//             "date": new Date("2023-08-28T09:00:00.000Z"),
//             "timestamp": 1693213200000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "Verizon Wireless",
//             "amount": 39,
//             "epochTimestamp": 1693213200,
//             "date": new Date("2023-08-28T09:00:00.000Z"),
//             "timestamp": 1693213200000,
//             "label": "verizon"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "unknown",
//             "amount": 1,
//             "epochTimestamp": 1693213200,
//             "date": new Date("2023-08-28T09:00:00.000Z"),
//             "timestamp": 1693213200000,
//             "label": "other"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "Verizon Wireless",
//             "amount": 16,
//             "epochTimestamp": 1693216800,
//             "date": new Date("2023-08-28T10:00:00.000Z"),
//             "timestamp": 1693216800000,
//             "label": "verizon"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 211,
//             "epochTimestamp": 1693216800,
//             "date": new Date("2023-08-28T10:00:00.000Z"),
//             "timestamp": 1693216800000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "unknown",
//             "amount": 1,
//             "epochTimestamp": 1693216800,
//             "date": new Date("2023-08-28T10:00:00.000Z"),
//             "timestamp": 1693216800000,
//             "label": "other"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "AT&T Mobility",
//             "amount": 11,
//             "epochTimestamp": 1693216800,
//             "date": new Date("2023-08-28T10:00:00.000Z"),
//             "timestamp": 1693216800000,
//             "label": "at&t"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "Verizon Wireless",
//             "amount": 32,
//             "epochTimestamp": 1693220400,
//             "date": new Date("2023-08-28T11:00:00.000Z"),
//             "timestamp": 1693220400000,
//             "label": "verizon"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "unknown",
//             "amount": 5,
//             "epochTimestamp": 1693220400,
//             "date": new Date("2023-08-28T11:00:00.000Z"),
//             "timestamp": 1693220400000,
//             "label": "other"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 216,
//             "epochTimestamp": 1693220400,
//             "date": new Date("2023-08-28T11:00:00.000Z"),
//             "timestamp": 1693220400000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "AT&T Mobility",
//             "amount": 22,
//             "epochTimestamp": 1693220400,
//             "date": new Date("2023-08-28T11:00:00.000Z"),
//             "timestamp": 1693220400000,
//             "label": "at&t"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "AT&T Mobility",
//             "amount": 14,
//             "epochTimestamp": 1693224000,
//             "date": new Date("2023-08-28T12:00:00.000Z"),
//             "timestamp": 1693224000000,
//             "label": "at&t"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 141,
//             "epochTimestamp": 1693224000,
//             "date": new Date("2023-08-28T12:00:00.000Z"),
//             "timestamp": 1693224000000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "unknown",
//             "amount": 8,
//             "epochTimestamp": 1693224000,
//             "date": new Date("2023-08-28T12:00:00.000Z"),
//             "timestamp": 1693224000000,
//             "label": "other"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "Verizon Wireless",
//             "amount": 63,
//             "epochTimestamp": 1693224000,
//             "date": new Date("2023-08-28T12:00:00.000Z"),
//             "timestamp": 1693224000000,
//             "label": "verizon"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 117,
//             "epochTimestamp": 1693227600,
//             "date": new Date("2023-08-28T13:00:00.000Z"),
//             "timestamp": 1693227600000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "AT&T Mobility",
//             "amount": 2,
//             "epochTimestamp": 1693227600,
//             "date": new Date("2023-08-28T13:00:00.000Z"),
//             "timestamp": 1693227600000,
//             "label": "at&t"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "Verizon Wireless",
//             "amount": 29,
//             "epochTimestamp": 1693227600,
//             "date": new Date("2023-08-28T13:00:00.000Z"),
//             "timestamp": 1693227600000,
//             "label": "verizon"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "unknown",
//             "amount": 14,
//             "epochTimestamp": 1693227600,
//             "date": new Date("2023-08-28T13:00:00.000Z"),
//             "timestamp": 1693227600000,
//             "label": "other"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "Verizon Wireless",
//             "amount": 95,
//             "epochTimestamp": 1693231200,
//             "date": new Date("2023-08-28T14:00:00.000Z"),
//             "timestamp": 1693231200000,
//             "label": "verizon"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 645,
//             "epochTimestamp": 1693231200,
//             "date": new Date("2023-08-28T14:00:00.000Z"),
//             "timestamp": 1693231200000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "unknown",
//             "amount": 19,
//             "epochTimestamp": 1693231200,
//             "date": new Date("2023-08-28T14:00:00.000Z"),
//             "timestamp": 1693231200000,
//             "label": "other"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "unknown",
//             "amount": 13,
//             "epochTimestamp": 1693234800,
//             "date": new Date("2023-08-28T15:00:00.000Z"),
//             "timestamp": 1693234800000,
//             "label": "other"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 227,
//             "epochTimestamp": 1693234800,
//             "date": new Date("2023-08-28T15:00:00.000Z"),
//             "timestamp": 1693234800000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "Verizon Wireless",
//             "amount": 34,
//             "epochTimestamp": 1693234800,
//             "date": new Date("2023-08-28T15:00:00.000Z"),
//             "timestamp": 1693234800000,
//             "label": "verizon"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "Verizon Wireless",
//             "amount": 92,
//             "epochTimestamp": 1693238400,
//             "date": new Date("2023-08-28T16:00:00.000Z"),
//             "timestamp": 1693238400000,
//             "label": "verizon"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "unknown",
//             "amount": 15,
//             "epochTimestamp": 1693238400,
//             "date": new Date("2023-08-28T16:00:00.000Z"),
//             "timestamp": 1693238400000,
//             "label": "other"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 316,
//             "epochTimestamp": 1693238400,
//             "date": new Date("2023-08-28T16:00:00.000Z"),
//             "timestamp": 1693238400000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "unknown",
//             "amount": 8,
//             "epochTimestamp": 1693242000,
//             "date": new Date("2023-08-28T17:00:00.000Z"),
//             "timestamp": 1693242000000,
//             "label": "other"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "Verizon Wireless",
//             "amount": 82,
//             "epochTimestamp": 1693242000,
//             "date": new Date("2023-08-28T17:00:00.000Z"),
//             "timestamp": 1693242000000,
//             "label": "verizon"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 65,
//             "epochTimestamp": 1693242000,
//             "date": new Date("2023-08-28T17:00:00.000Z"),
//             "timestamp": 1693242000000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "AT&T Mobility",
//             "amount": 10,
//             "epochTimestamp": 1693242000,
//             "date": new Date("2023-08-28T17:00:00.000Z"),
//             "timestamp": 1693242000000,
//             "label": "at&t"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "Verizon Wireless",
//             "amount": 60,
//             "epochTimestamp": 1693245600,
//             "date": new Date("2023-08-28T18:00:00.000Z"),
//             "timestamp": 1693245600000,
//             "label": "verizon"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "unknown",
//             "amount": 34,
//             "epochTimestamp": 1693245600,
//             "date": new Date("2023-08-28T18:00:00.000Z"),
//             "timestamp": 1693245600000,
//             "label": "other"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 215,
//             "epochTimestamp": 1693245600,
//             "date": new Date("2023-08-28T18:00:00.000Z"),
//             "timestamp": 1693245600000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "Verizon Wireless",
//             "amount": 65,
//             "epochTimestamp": 1693249200,
//             "date": new Date("2023-08-28T19:00:00.000Z"),
//             "timestamp": 1693249200000,
//             "label": "verizon"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "unknown",
//             "amount": 49,
//             "epochTimestamp": 1693249200,
//             "date": new Date("2023-08-28T19:00:00.000Z"),
//             "timestamp": 1693249200000,
//             "label": "other"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "AT&T Mobility",
//             "amount": 24,
//             "epochTimestamp": 1693249200,
//             "date": new Date("2023-08-28T19:00:00.000Z"),
//             "timestamp": 1693249200000,
//             "label": "at&t"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 158,
//             "epochTimestamp": 1693249200,
//             "date": new Date("2023-08-28T19:00:00.000Z"),
//             "timestamp": 1693249200000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 226,
//             "epochTimestamp": 1693252800,
//             "date": new Date("2023-08-28T20:00:00.000Z"),
//             "timestamp": 1693252800000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "AT&T Mobility",
//             "amount": 32,
//             "epochTimestamp": 1693252800,
//             "date": new Date("2023-08-28T20:00:00.000Z"),
//             "timestamp": 1693252800000,
//             "label": "at&t"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "Verizon Wireless",
//             "amount": 80,
//             "epochTimestamp": 1693252800,
//             "date": new Date("2023-08-28T20:00:00.000Z"),
//             "timestamp": 1693252800000,
//             "label": "verizon"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "unknown",
//             "amount": 12,
//             "epochTimestamp": 1693252800,
//             "date": new Date("2023-08-28T20:00:00.000Z"),
//             "timestamp": 1693252800000,
//             "label": "other"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 424,
//             "epochTimestamp": 1693256400,
//             "date": new Date("2023-08-28T21:00:00.000Z"),
//             "timestamp": 1693256400000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "Verizon Wireless",
//             "amount": 36,
//             "epochTimestamp": 1693256400,
//             "date": new Date("2023-08-28T21:00:00.000Z"),
//             "timestamp": 1693256400000,
//             "label": "verizon"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "AT&T Mobility",
//             "amount": 32,
//             "epochTimestamp": 1693256400,
//             "date": new Date("2023-08-28T21:00:00.000Z"),
//             "timestamp": 1693256400000,
//             "label": "at&t"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "unknown",
//             "amount": 4,
//             "epochTimestamp": 1693256400,
//             "date": new Date("2023-08-28T21:00:00.000Z"),
//             "timestamp": 1693256400000,
//             "label": "other"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "Verizon Wireless",
//             "amount": 68,
//             "epochTimestamp": 1693260000,
//             "date": new Date("2023-08-28T22:00:00.000Z"),
//             "timestamp": 1693260000000,
//             "label": "verizon"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "AT&T Mobility",
//             "amount": 8,
//             "epochTimestamp": 1693260000,
//             "date": new Date("2023-08-28T22:00:00.000Z"),
//             "timestamp": 1693260000000,
//             "label": "at&t"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 322,
//             "epochTimestamp": 1693260000,
//             "date": new Date("2023-08-28T22:00:00.000Z"),
//             "timestamp": 1693260000000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "unknown",
//             "amount": 7,
//             "epochTimestamp": 1693260000,
//             "date": new Date("2023-08-28T22:00:00.000Z"),
//             "timestamp": 1693260000000,
//             "label": "other"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "unknown",
//             "amount": 10,
//             "epochTimestamp": 1693263600,
//             "date": new Date("2023-08-28T23:00:00.000Z"),
//             "timestamp": 1693263600000,
//             "label": "other"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 326,
//             "epochTimestamp": 1693263600,
//             "date": new Date("2023-08-28T23:00:00.000Z"),
//             "timestamp": 1693263600000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "Verizon Wireless",
//             "amount": 111,
//             "epochTimestamp": 1693263600,
//             "date": new Date("2023-08-28T23:00:00.000Z"),
//             "timestamp": 1693263600000,
//             "label": "verizon"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 255,
//             "epochTimestamp": 1693267200,
//             "date": new Date("2023-08-29T00:00:00.000Z"),
//             "timestamp": 1693267200000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "Verizon Wireless",
//             "amount": 30,
//             "epochTimestamp": 1693267200,
//             "date": new Date("2023-08-29T00:00:00.000Z"),
//             "timestamp": 1693267200000,
//             "label": "verizon"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "unknown",
//             "amount": 3,
//             "epochTimestamp": 1693267200,
//             "date": new Date("2023-08-29T00:00:00.000Z"),
//             "timestamp": 1693267200000,
//             "label": "other"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "AT&T Mobility",
//             "amount": 2,
//             "epochTimestamp": 1693267200,
//             "date": new Date("2023-08-29T00:00:00.000Z"),
//             "timestamp": 1693267200000,
//             "label": "at&t"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "Verizon Wireless",
//             "amount": 6,
//             "epochTimestamp": 1693270800,
//             "date": new Date("2023-08-29T01:00:00.000Z"),
//             "timestamp": 1693270800000,
//             "label": "verizon"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 154,
//             "epochTimestamp": 1693270800,
//             "date": new Date("2023-08-29T01:00:00.000Z"),
//             "timestamp": 1693270800000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "unknown",
//             "amount": 3,
//             "epochTimestamp": 1693270800,
//             "date": new Date("2023-08-29T01:00:00.000Z"),
//             "timestamp": 1693270800000,
//             "label": "other"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 137,
//             "epochTimestamp": 1693274400,
//             "date": new Date("2023-08-29T02:00:00.000Z"),
//             "timestamp": 1693274400000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "unknown",
//             "amount": 7,
//             "epochTimestamp": 1693274400,
//             "date": new Date("2023-08-29T02:00:00.000Z"),
//             "timestamp": 1693274400000,
//             "label": "other"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "Verizon Wireless",
//             "amount": 8,
//             "epochTimestamp": 1693274400,
//             "date": new Date("2023-08-29T02:00:00.000Z"),
//             "timestamp": 1693274400000,
//             "label": "verizon"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 304,
//             "epochTimestamp": 1693278000,
//             "date": new Date("2023-08-29T03:00:00.000Z"),
//             "timestamp": 1693278000000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "unknown",
//             "amount": 14,
//             "epochTimestamp": 1693278000,
//             "date": new Date("2023-08-29T03:00:00.000Z"),
//             "timestamp": 1693278000000,
//             "label": "other"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "Verizon Wireless",
//             "amount": 6,
//             "epochTimestamp": 1693278000,
//             "date": new Date("2023-08-29T03:00:00.000Z"),
//             "timestamp": 1693278000000,
//             "label": "verizon"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "unknown",
//             "amount": 7,
//             "epochTimestamp": 1693281600,
//             "date": new Date("2023-08-29T04:00:00.000Z"),
//             "timestamp": 1693281600000,
//             "label": "other"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 109,
//             "epochTimestamp": 1693281600,
//             "date": new Date("2023-08-29T04:00:00.000Z"),
//             "timestamp": 1693281600000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 226,
//             "epochTimestamp": 1693285200,
//             "date": new Date("2023-08-29T05:00:00.000Z"),
//             "timestamp": 1693285200000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "Verizon Wireless",
//             "amount": 16,
//             "epochTimestamp": 1693285200,
//             "date": new Date("2023-08-29T05:00:00.000Z"),
//             "timestamp": 1693285200000,
//             "label": "verizon"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "unknown",
//             "amount": 1,
//             "epochTimestamp": 1693285200,
//             "date": new Date("2023-08-29T05:00:00.000Z"),
//             "timestamp": 1693285200000,
//             "label": "other"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 156,
//             "epochTimestamp": 1693288800,
//             "date": new Date("2023-08-29T06:00:00.000Z"),
//             "timestamp": 1693288800000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "unknown",
//             "amount": 1,
//             "epochTimestamp": 1693288800,
//             "date": new Date("2023-08-29T06:00:00.000Z"),
//             "timestamp": 1693288800000,
//             "label": "other"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "Verizon Wireless",
//             "amount": 22,
//             "epochTimestamp": 1693288800,
//             "date": new Date("2023-08-29T06:00:00.000Z"),
//             "timestamp": 1693288800000,
//             "label": "verizon"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "Verizon Wireless",
//             "amount": 8,
//             "epochTimestamp": 1693292400,
//             "date": new Date("2023-08-29T07:00:00.000Z"),
//             "timestamp": 1693292400000,
//             "label": "verizon"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 113,
//             "epochTimestamp": 1693292400,
//             "date": new Date("2023-08-29T07:00:00.000Z"),
//             "timestamp": 1693292400000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 113,
//             "epochTimestamp": 1693296000,
//             "date": new Date("2023-08-29T08:00:00.000Z"),
//             "timestamp": 1693296000000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "AT&T Mobility",
//             "amount": 2,
//             "epochTimestamp": 1693296000,
//             "date": new Date("2023-08-29T08:00:00.000Z"),
//             "timestamp": 1693296000000,
//             "label": "at&t"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "Verizon Wireless",
//             "amount": 18,
//             "epochTimestamp": 1693296000,
//             "date": new Date("2023-08-29T08:00:00.000Z"),
//             "timestamp": 1693296000000,
//             "label": "verizon"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 149,
//             "epochTimestamp": 1693299600,
//             "date": new Date("2023-08-29T09:00:00.000Z"),
//             "timestamp": 1693299600000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "unknown",
//             "amount": 1,
//             "epochTimestamp": 1693303200,
//             "date": new Date("2023-08-29T10:00:00.000Z"),
//             "timestamp": 1693303200000,
//             "label": "other"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 26,
//             "epochTimestamp": 1693303200,
//             "date": new Date("2023-08-29T10:00:00.000Z"),
//             "timestamp": 1693303200000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "Verizon Wireless",
//             "amount": 3,
//             "epochTimestamp": 1693306800,
//             "date": new Date("2023-08-29T11:00:00.000Z"),
//             "timestamp": 1693306800000,
//             "label": "verizon"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "unknown",
//             "amount": 6,
//             "epochTimestamp": 1693306800,
//             "date": new Date("2023-08-29T11:00:00.000Z"),
//             "timestamp": 1693306800000,
//             "label": "other"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 11,
//             "epochTimestamp": 1693306800,
//             "date": new Date("2023-08-29T11:00:00.000Z"),
//             "timestamp": 1693306800000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "Verizon Wireless",
//             "amount": 373,
//             "epochTimestamp": 1693310400,
//             "date": new Date("2023-08-29T12:00:00.000Z"),
//             "timestamp": 1693310400000,
//             "label": "verizon"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "unknown",
//             "amount": 7,
//             "epochTimestamp": 1693310400,
//             "date": new Date("2023-08-29T12:00:00.000Z"),
//             "timestamp": 1693310400000,
//             "label": "other"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 31,
//             "epochTimestamp": 1693310400,
//             "date": new Date("2023-08-29T12:00:00.000Z"),
//             "timestamp": 1693310400000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "AT&T Mobility",
//             "amount": 4,
//             "epochTimestamp": 1693310400,
//             "date": new Date("2023-08-29T12:00:00.000Z"),
//             "timestamp": 1693310400000,
//             "label": "at&t"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "unknown",
//             "amount": 3,
//             "epochTimestamp": 1693314000,
//             "date": new Date("2023-08-29T13:00:00.000Z"),
//             "timestamp": 1693314000000,
//             "label": "other"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 80,
//             "epochTimestamp": 1693314000,
//             "date": new Date("2023-08-29T13:00:00.000Z"),
//             "timestamp": 1693314000000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "Verizon Wireless",
//             "amount": 66,
//             "epochTimestamp": 1693314000,
//             "date": new Date("2023-08-29T13:00:00.000Z"),
//             "timestamp": 1693314000000,
//             "label": "verizon"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "unknown",
//             "amount": 1,
//             "epochTimestamp": 1693317600,
//             "date": new Date("2023-08-29T14:00:00.000Z"),
//             "timestamp": 1693317600000,
//             "label": "other"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 134,
//             "epochTimestamp": 1693317600,
//             "date": new Date("2023-08-29T14:00:00.000Z"),
//             "timestamp": 1693317600000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "Verizon Wireless",
//             "amount": 119,
//             "epochTimestamp": 1693317600,
//             "date": new Date("2023-08-29T14:00:00.000Z"),
//             "timestamp": 1693317600000,
//             "label": "verizon"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "unknown",
//             "amount": 9,
//             "epochTimestamp": 1693321200,
//             "date": new Date("2023-08-29T15:00:00.000Z"),
//             "timestamp": 1693321200000,
//             "label": "other"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "AT&T Mobility",
//             "amount": 2,
//             "epochTimestamp": 1693321200,
//             "date": new Date("2023-08-29T15:00:00.000Z"),
//             "timestamp": 1693321200000,
//             "label": "at&t"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 347,
//             "epochTimestamp": 1693321200,
//             "date": new Date("2023-08-29T15:00:00.000Z"),
//             "timestamp": 1693321200000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "Verizon Wireless",
//             "amount": 176,
//             "epochTimestamp": 1693321200,
//             "date": new Date("2023-08-29T15:00:00.000Z"),
//             "timestamp": 1693321200000,
//             "label": "verizon"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "unknown",
//             "amount": 9,
//             "epochTimestamp": 1693324800,
//             "date": new Date("2023-08-29T16:00:00.000Z"),
//             "timestamp": 1693324800000,
//             "label": "other"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 176,
//             "epochTimestamp": 1693324800,
//             "date": new Date("2023-08-29T16:00:00.000Z"),
//             "timestamp": 1693324800000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "AT&T Mobility",
//             "amount": 8,
//             "epochTimestamp": 1693324800,
//             "date": new Date("2023-08-29T16:00:00.000Z"),
//             "timestamp": 1693324800000,
//             "label": "at&t"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "AT&T Mobility",
//             "amount": 77,
//             "epochTimestamp": 1693328400,
//             "date": new Date("2023-08-29T17:00:00.000Z"),
//             "timestamp": 1693328400000,
//             "label": "at&t"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 151,
//             "epochTimestamp": 1693328400,
//             "date": new Date("2023-08-29T17:00:00.000Z"),
//             "timestamp": 1693328400000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "unknown",
//             "amount": 6,
//             "epochTimestamp": 1693328400,
//             "date": new Date("2023-08-29T17:00:00.000Z"),
//             "timestamp": 1693328400000,
//             "label": "other"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "AT&T Mobility",
//             "amount": 22,
//             "epochTimestamp": 1693332000,
//             "date": new Date("2023-08-29T18:00:00.000Z"),
//             "timestamp": 1693332000000,
//             "label": "at&t"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 239,
//             "epochTimestamp": 1693332000,
//             "date": new Date("2023-08-29T18:00:00.000Z"),
//             "timestamp": 1693332000000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "Verizon Wireless",
//             "amount": 4,
//             "epochTimestamp": 1693332000,
//             "date": new Date("2023-08-29T18:00:00.000Z"),
//             "timestamp": 1693332000000,
//             "label": "verizon"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "unknown",
//             "amount": 4,
//             "epochTimestamp": 1693332000,
//             "date": new Date("2023-08-29T18:00:00.000Z"),
//             "timestamp": 1693332000000,
//             "label": "other"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "Verizon Wireless",
//             "amount": 6,
//             "epochTimestamp": 1693335600,
//             "date": new Date("2023-08-29T19:00:00.000Z"),
//             "timestamp": 1693335600000,
//             "label": "verizon"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "unknown",
//             "amount": 4,
//             "epochTimestamp": 1693335600,
//             "date": new Date("2023-08-29T19:00:00.000Z"),
//             "timestamp": 1693335600000,
//             "label": "other"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 169,
//             "epochTimestamp": 1693335600,
//             "date": new Date("2023-08-29T19:00:00.000Z"),
//             "timestamp": 1693335600000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "AT&T Mobility",
//             "amount": 18,
//             "epochTimestamp": 1693335600,
//             "date": new Date("2023-08-29T19:00:00.000Z"),
//             "timestamp": 1693335600000,
//             "label": "at&t"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "AT&T Mobility",
//             "amount": 4,
//             "epochTimestamp": 1693339200,
//             "date": new Date("2023-08-29T20:00:00.000Z"),
//             "timestamp": 1693339200000,
//             "label": "at&t"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 766,
//             "epochTimestamp": 1693339200,
//             "date": new Date("2023-08-29T20:00:00.000Z"),
//             "timestamp": 1693339200000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "unknown",
//             "amount": 18,
//             "epochTimestamp": 1693339200,
//             "date": new Date("2023-08-29T20:00:00.000Z"),
//             "timestamp": 1693339200000,
//             "label": "other"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "Verizon Wireless",
//             "amount": 108,
//             "epochTimestamp": 1693339200,
//             "date": new Date("2023-08-29T20:00:00.000Z"),
//             "timestamp": 1693339200000,
//             "label": "verizon"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "unknown",
//             "amount": 14,
//             "epochTimestamp": 1693342800,
//             "date": new Date("2023-08-29T21:00:00.000Z"),
//             "timestamp": 1693342800000,
//             "label": "other"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "Verizon Wireless",
//             "amount": 14,
//             "epochTimestamp": 1693342800,
//             "date": new Date("2023-08-29T21:00:00.000Z"),
//             "timestamp": 1693342800000,
//             "label": "verizon"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "AT&T Mobility",
//             "amount": 26,
//             "epochTimestamp": 1693342800,
//             "date": new Date("2023-08-29T21:00:00.000Z"),
//             "timestamp": 1693342800000,
//             "label": "at&t"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 373,
//             "epochTimestamp": 1693342800,
//             "date": new Date("2023-08-29T21:00:00.000Z"),
//             "timestamp": 1693342800000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 298,
//             "epochTimestamp": 1693346400,
//             "date": new Date("2023-08-29T22:00:00.000Z"),
//             "timestamp": 1693346400000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "unknown",
//             "amount": 3,
//             "epochTimestamp": 1693346400,
//             "date": new Date("2023-08-29T22:00:00.000Z"),
//             "timestamp": 1693346400000,
//             "label": "other"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "Verizon Wireless",
//             "amount": 82,
//             "epochTimestamp": 1693346400,
//             "date": new Date("2023-08-29T22:00:00.000Z"),
//             "timestamp": 1693346400000,
//             "label": "verizon"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "AT&T Mobility",
//             "amount": 50,
//             "epochTimestamp": 1693346400,
//             "date": new Date("2023-08-29T22:00:00.000Z"),
//             "timestamp": 1693346400000,
//             "label": "at&t"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "unknown",
//             "amount": 8,
//             "epochTimestamp": 1693350000,
//             "date": new Date("2023-08-29T23:00:00.000Z"),
//             "timestamp": 1693350000000,
//             "label": "other"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "Verizon Wireless",
//             "amount": 26,
//             "epochTimestamp": 1693350000,
//             "date": new Date("2023-08-29T23:00:00.000Z"),
//             "timestamp": 1693350000000,
//             "label": "verizon"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 280,
//             "epochTimestamp": 1693350000,
//             "date": new Date("2023-08-29T23:00:00.000Z"),
//             "timestamp": 1693350000000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "AT&T Mobility",
//             "amount": 12,
//             "epochTimestamp": 1693350000,
//             "date": new Date("2023-08-29T23:00:00.000Z"),
//             "timestamp": 1693350000000,
//             "label": "at&t"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "Verizon Wireless",
//             "amount": 10,
//             "epochTimestamp": 1693353600,
//             "date": new Date("2023-08-30T00:00:00.000Z"),
//             "timestamp": 1693353600000,
//             "label": "verizon"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "unknown",
//             "amount": 1,
//             "epochTimestamp": 1693353600,
//             "date": new Date("2023-08-30T00:00:00.000Z"),
//             "timestamp": 1693353600000,
//             "label": "other"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 314,
//             "epochTimestamp": 1693353600,
//             "date": new Date("2023-08-30T00:00:00.000Z"),
//             "timestamp": 1693353600000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "Verizon Wireless",
//             "amount": 30,
//             "epochTimestamp": 1693357200,
//             "date": new Date("2023-08-30T01:00:00.000Z"),
//             "timestamp": 1693357200000,
//             "label": "verizon"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "unknown",
//             "amount": 12,
//             "epochTimestamp": 1693357200,
//             "date": new Date("2023-08-30T01:00:00.000Z"),
//             "timestamp": 1693357200000,
//             "label": "other"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 412,
//             "epochTimestamp": 1693357200,
//             "date": new Date("2023-08-30T01:00:00.000Z"),
//             "timestamp": 1693357200000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "unknown",
//             "amount": 35,
//             "epochTimestamp": 1693360800,
//             "date": new Date("2023-08-30T02:00:00.000Z"),
//             "timestamp": 1693360800000,
//             "label": "other"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 186,
//             "epochTimestamp": 1693360800,
//             "date": new Date("2023-08-30T02:00:00.000Z"),
//             "timestamp": 1693360800000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 239,
//             "epochTimestamp": 1693364400,
//             "date": new Date("2023-08-30T03:00:00.000Z"),
//             "timestamp": 1693364400000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "unknown",
//             "amount": 31,
//             "epochTimestamp": 1693364400,
//             "date": new Date("2023-08-30T03:00:00.000Z"),
//             "timestamp": 1693364400000,
//             "label": "other"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "Verizon Wireless",
//             "amount": 3,
//             "epochTimestamp": 1693368000,
//             "date": new Date("2023-08-30T04:00:00.000Z"),
//             "timestamp": 1693368000000,
//             "label": "verizon"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 334,
//             "epochTimestamp": 1693368000,
//             "date": new Date("2023-08-30T04:00:00.000Z"),
//             "timestamp": 1693368000000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "unknown",
//             "amount": 3,
//             "epochTimestamp": 1693368000,
//             "date": new Date("2023-08-30T04:00:00.000Z"),
//             "timestamp": 1693368000000,
//             "label": "other"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "unknown",
//             "amount": 2,
//             "epochTimestamp": 1693371600,
//             "date": new Date("2023-08-30T05:00:00.000Z"),
//             "timestamp": 1693371600000,
//             "label": "other"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 347,
//             "epochTimestamp": 1693371600,
//             "date": new Date("2023-08-30T05:00:00.000Z"),
//             "timestamp": 1693371600000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "unknown",
//             "amount": 3,
//             "epochTimestamp": 1693375200,
//             "date": new Date("2023-08-30T06:00:00.000Z"),
//             "timestamp": 1693375200000,
//             "label": "other"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 387,
//             "epochTimestamp": 1693375200,
//             "date": new Date("2023-08-30T06:00:00.000Z"),
//             "timestamp": 1693375200000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 442,
//             "epochTimestamp": 1693378800,
//             "date": new Date("2023-08-30T07:00:00.000Z"),
//             "timestamp": 1693378800000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "Verizon Wireless",
//             "amount": 2,
//             "epochTimestamp": 1693378800,
//             "date": new Date("2023-08-30T07:00:00.000Z"),
//             "timestamp": 1693378800000,
//             "label": "verizon"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 409,
//             "epochTimestamp": 1693382400,
//             "date": new Date("2023-08-30T08:00:00.000Z"),
//             "timestamp": 1693382400000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "Verizon Wireless",
//             "amount": 9,
//             "epochTimestamp": 1693382400,
//             "date": new Date("2023-08-30T08:00:00.000Z"),
//             "timestamp": 1693382400000,
//             "label": "verizon"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "unknown",
//             "amount": 3,
//             "epochTimestamp": 1693382400,
//             "date": new Date("2023-08-30T08:00:00.000Z"),
//             "timestamp": 1693382400000,
//             "label": "other"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 384,
//             "epochTimestamp": 1693386000,
//             "date": new Date("2023-08-30T09:00:00.000Z"),
//             "timestamp": 1693386000000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "Verizon Wireless",
//             "amount": 5,
//             "epochTimestamp": 1693386000,
//             "date": new Date("2023-08-30T09:00:00.000Z"),
//             "timestamp": 1693386000000,
//             "label": "verizon"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "unknown",
//             "amount": 3,
//             "epochTimestamp": 1693389600,
//             "date": new Date("2023-08-30T10:00:00.000Z"),
//             "timestamp": 1693389600000,
//             "label": "other"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 322,
//             "epochTimestamp": 1693389600,
//             "date": new Date("2023-08-30T10:00:00.000Z"),
//             "timestamp": 1693389600000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "Verizon Wireless",
//             "amount": 2,
//             "epochTimestamp": 1693389600,
//             "date": new Date("2023-08-30T10:00:00.000Z"),
//             "timestamp": 1693389600000,
//             "label": "verizon"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 297,
//             "epochTimestamp": 1693393200,
//             "date": new Date("2023-08-30T11:00:00.000Z"),
//             "timestamp": 1693393200000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "unknown",
//             "amount": 4,
//             "epochTimestamp": 1693393200,
//             "date": new Date("2023-08-30T11:00:00.000Z"),
//             "timestamp": 1693393200000,
//             "label": "other"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "Verizon Wireless",
//             "amount": 7,
//             "epochTimestamp": 1693393200,
//             "date": new Date("2023-08-30T11:00:00.000Z"),
//             "timestamp": 1693393200000,
//             "label": "verizon"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "AT&T Mobility",
//             "amount": 2,
//             "epochTimestamp": 1693396800,
//             "date": new Date("2023-08-30T12:00:00.000Z"),
//             "timestamp": 1693396800000,
//             "label": "at&t"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "unknown",
//             "amount": 7,
//             "epochTimestamp": 1693396800,
//             "date": new Date("2023-08-30T12:00:00.000Z"),
//             "timestamp": 1693396800000,
//             "label": "other"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "Verizon Wireless",
//             "amount": 79,
//             "epochTimestamp": 1693396800,
//             "date": new Date("2023-08-30T12:00:00.000Z"),
//             "timestamp": 1693396800000,
//             "label": "verizon"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 570,
//             "epochTimestamp": 1693396800,
//             "date": new Date("2023-08-30T12:00:00.000Z"),
//             "timestamp": 1693396800000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 358,
//             "epochTimestamp": 1693400400,
//             "date": new Date("2023-08-30T13:00:00.000Z"),
//             "timestamp": 1693400400000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "Verizon Wireless",
//             "amount": 42,
//             "epochTimestamp": 1693400400,
//             "date": new Date("2023-08-30T13:00:00.000Z"),
//             "timestamp": 1693400400000,
//             "label": "verizon"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "unknown",
//             "amount": 7,
//             "epochTimestamp": 1693400400,
//             "date": new Date("2023-08-30T13:00:00.000Z"),
//             "timestamp": 1693400400000,
//             "label": "other"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "AT&T Mobility",
//             "amount": 1,
//             "epochTimestamp": 1693400400,
//             "date": new Date("2023-08-30T13:00:00.000Z"),
//             "timestamp": 1693400400000,
//             "label": "at&t"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "Verizon Wireless",
//             "amount": 43,
//             "epochTimestamp": 1693404000,
//             "date": new Date("2023-08-30T14:00:00.000Z"),
//             "timestamp": 1693404000000,
//             "label": "verizon"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 487,
//             "epochTimestamp": 1693404000,
//             "date": new Date("2023-08-30T14:00:00.000Z"),
//             "timestamp": 1693404000000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "unknown",
//             "amount": 7,
//             "epochTimestamp": 1693404000,
//             "date": new Date("2023-08-30T14:00:00.000Z"),
//             "timestamp": 1693404000000,
//             "label": "other"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "AT&T Mobility",
//             "amount": 1,
//             "epochTimestamp": 1693404000,
//             "date": new Date("2023-08-30T14:00:00.000Z"),
//             "timestamp": 1693404000000,
//             "label": "at&t"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "unknown",
//             "amount": 2,
//             "epochTimestamp": 1693407600,
//             "date": new Date("2023-08-30T15:00:00.000Z"),
//             "timestamp": 1693407600000,
//             "label": "other"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "AT&T Mobility",
//             "amount": 23,
//             "epochTimestamp": 1693407600,
//             "date": new Date("2023-08-30T15:00:00.000Z"),
//             "timestamp": 1693407600000,
//             "label": "at&t"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 578,
//             "epochTimestamp": 1693407600,
//             "date": new Date("2023-08-30T15:00:00.000Z"),
//             "timestamp": 1693407600000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "Verizon Wireless",
//             "amount": 101,
//             "epochTimestamp": 1693407600,
//             "date": new Date("2023-08-30T15:00:00.000Z"),
//             "timestamp": 1693407600000,
//             "label": "verizon"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 112,
//             "epochTimestamp": 1693411200,
//             "date": new Date("2023-08-30T16:00:00.000Z"),
//             "timestamp": 1693411200000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "unknown",
//             "amount": 31,
//             "epochTimestamp": 1693411200,
//             "date": new Date("2023-08-30T16:00:00.000Z"),
//             "timestamp": 1693411200000,
//             "label": "other"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "Verizon Wireless",
//             "amount": 9,
//             "epochTimestamp": 1693411200,
//             "date": new Date("2023-08-30T16:00:00.000Z"),
//             "timestamp": 1693411200000,
//             "label": "verizon"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "AT&T Mobility",
//             "amount": 122,
//             "epochTimestamp": 1693411200,
//             "date": new Date("2023-08-30T16:00:00.000Z"),
//             "timestamp": 1693411200000,
//             "label": "at&t"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "Verizon Wireless",
//             "amount": 61,
//             "epochTimestamp": 1693414800,
//             "date": new Date("2023-08-30T17:00:00.000Z"),
//             "timestamp": 1693414800000,
//             "label": "verizon"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 228,
//             "epochTimestamp": 1693414800,
//             "date": new Date("2023-08-30T17:00:00.000Z"),
//             "timestamp": 1693414800000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "AT&T Mobility",
//             "amount": 5,
//             "epochTimestamp": 1693414800,
//             "date": new Date("2023-08-30T17:00:00.000Z"),
//             "timestamp": 1693414800000,
//             "label": "at&t"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "unknown",
//             "amount": 7,
//             "epochTimestamp": 1693414800,
//             "date": new Date("2023-08-30T17:00:00.000Z"),
//             "timestamp": 1693414800000,
//             "label": "other"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "unknown",
//             "amount": 3,
//             "epochTimestamp": 1693418400,
//             "date": new Date("2023-08-30T18:00:00.000Z"),
//             "timestamp": 1693418400000,
//             "label": "other"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "Verizon Wireless",
//             "amount": 109,
//             "epochTimestamp": 1693418400,
//             "date": new Date("2023-08-30T18:00:00.000Z"),
//             "timestamp": 1693418400000,
//             "label": "verizon"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 511,
//             "epochTimestamp": 1693418400,
//             "date": new Date("2023-08-30T18:00:00.000Z"),
//             "timestamp": 1693418400000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "Verizon Wireless",
//             "amount": 4,
//             "epochTimestamp": 1693422000,
//             "date": new Date("2023-08-30T19:00:00.000Z"),
//             "timestamp": 1693422000000,
//             "label": "verizon"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "AT&T Mobility",
//             "amount": 22,
//             "epochTimestamp": 1693422000,
//             "date": new Date("2023-08-30T19:00:00.000Z"),
//             "timestamp": 1693422000000,
//             "label": "at&t"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 238,
//             "epochTimestamp": 1693422000,
//             "date": new Date("2023-08-30T19:00:00.000Z"),
//             "timestamp": 1693422000000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "unknown",
//             "amount": 31,
//             "epochTimestamp": 1693422000,
//             "date": new Date("2023-08-30T19:00:00.000Z"),
//             "timestamp": 1693422000000,
//             "label": "other"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "unknown",
//             "amount": 16,
//             "epochTimestamp": 1693425600,
//             "date": new Date("2023-08-30T20:00:00.000Z"),
//             "timestamp": 1693425600000,
//             "label": "other"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "Verizon Wireless",
//             "amount": 6,
//             "epochTimestamp": 1693425600,
//             "date": new Date("2023-08-30T20:00:00.000Z"),
//             "timestamp": 1693425600000,
//             "label": "verizon"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "AT&T Mobility",
//             "amount": 26,
//             "epochTimestamp": 1693425600,
//             "date": new Date("2023-08-30T20:00:00.000Z"),
//             "timestamp": 1693425600000,
//             "label": "at&t"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 524,
//             "epochTimestamp": 1693425600,
//             "date": new Date("2023-08-30T20:00:00.000Z"),
//             "timestamp": 1693425600000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 852,
//             "epochTimestamp": 1693429200,
//             "date": new Date("2023-08-30T21:00:00.000Z"),
//             "timestamp": 1693429200000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "unknown",
//             "amount": 21,
//             "epochTimestamp": 1693429200,
//             "date": new Date("2023-08-30T21:00:00.000Z"),
//             "timestamp": 1693429200000,
//             "label": "other"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "Verizon Wireless",
//             "amount": 212,
//             "epochTimestamp": 1693429200,
//             "date": new Date("2023-08-30T21:00:00.000Z"),
//             "timestamp": 1693429200000,
//             "label": "verizon"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 512,
//             "epochTimestamp": 1693432800,
//             "date": new Date("2023-08-30T22:00:00.000Z"),
//             "timestamp": 1693432800000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "unknown",
//             "amount": 11,
//             "epochTimestamp": 1693432800,
//             "date": new Date("2023-08-30T22:00:00.000Z"),
//             "timestamp": 1693432800000,
//             "label": "other"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "Verizon Wireless",
//             "amount": 187,
//             "epochTimestamp": 1693432800,
//             "date": new Date("2023-08-30T22:00:00.000Z"),
//             "timestamp": 1693432800000,
//             "label": "verizon"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "AT&T Mobility",
//             "amount": 2,
//             "epochTimestamp": 1693432800,
//             "date": new Date("2023-08-30T22:00:00.000Z"),
//             "timestamp": 1693432800000,
//             "label": "at&t"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 328,
//             "epochTimestamp": 1693436400,
//             "date": new Date("2023-08-30T23:00:00.000Z"),
//             "timestamp": 1693436400000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "unknown",
//             "amount": 9,
//             "epochTimestamp": 1693436400,
//             "date": new Date("2023-08-30T23:00:00.000Z"),
//             "timestamp": 1693436400000,
//             "label": "other"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "Verizon Wireless",
//             "amount": 77,
//             "epochTimestamp": 1693436400,
//             "date": new Date("2023-08-30T23:00:00.000Z"),
//             "timestamp": 1693436400000,
//             "label": "verizon"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "Verizon Wireless",
//             "amount": 38,
//             "epochTimestamp": 1693440000,
//             "date": new Date("2023-08-31T00:00:00.000Z"),
//             "timestamp": 1693440000000,
//             "label": "verizon"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "AT&T Mobility",
//             "amount": 2,
//             "epochTimestamp": 1693440000,
//             "date": new Date("2023-08-31T00:00:00.000Z"),
//             "timestamp": 1693440000000,
//             "label": "at&t"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "unknown",
//             "amount": 4,
//             "epochTimestamp": 1693440000,
//             "date": new Date("2023-08-31T00:00:00.000Z"),
//             "timestamp": 1693440000000,
//             "label": "other"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 268,
//             "epochTimestamp": 1693440000,
//             "date": new Date("2023-08-31T00:00:00.000Z"),
//             "timestamp": 1693440000000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "Verizon Wireless",
//             "amount": 43,
//             "epochTimestamp": 1693443600,
//             "date": new Date("2023-08-31T01:00:00.000Z"),
//             "timestamp": 1693443600000,
//             "label": "verizon"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 331,
//             "epochTimestamp": 1693443600,
//             "date": new Date("2023-08-31T01:00:00.000Z"),
//             "timestamp": 1693443600000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "unknown",
//             "amount": 4,
//             "epochTimestamp": 1693443600,
//             "date": new Date("2023-08-31T01:00:00.000Z"),
//             "timestamp": 1693443600000,
//             "label": "other"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 411,
//             "epochTimestamp": 1693447200,
//             "date": new Date("2023-08-31T02:00:00.000Z"),
//             "timestamp": 1693447200000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "Verizon Wireless",
//             "amount": 52,
//             "epochTimestamp": 1693447200,
//             "date": new Date("2023-08-31T02:00:00.000Z"),
//             "timestamp": 1693447200000,
//             "label": "verizon"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "unknown",
//             "amount": 11,
//             "epochTimestamp": 1693447200,
//             "date": new Date("2023-08-31T02:00:00.000Z"),
//             "timestamp": 1693447200000,
//             "label": "other"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 524,
//             "epochTimestamp": 1693450800,
//             "date": new Date("2023-08-31T03:00:00.000Z"),
//             "timestamp": 1693450800000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "Verizon Wireless",
//             "amount": 39,
//             "epochTimestamp": 1693450800,
//             "date": new Date("2023-08-31T03:00:00.000Z"),
//             "timestamp": 1693450800000,
//             "label": "verizon"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "unknown",
//             "amount": 35,
//             "epochTimestamp": 1693450800,
//             "date": new Date("2023-08-31T03:00:00.000Z"),
//             "timestamp": 1693450800000,
//             "label": "other"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 383,
//             "epochTimestamp": 1693454400,
//             "date": new Date("2023-08-31T04:00:00.000Z"),
//             "timestamp": 1693454400000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "unknown",
//             "amount": 15,
//             "epochTimestamp": 1693454400,
//             "date": new Date("2023-08-31T04:00:00.000Z"),
//             "timestamp": 1693454400000,
//             "label": "other"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "Verizon Wireless",
//             "amount": 41,
//             "epochTimestamp": 1693454400,
//             "date": new Date("2023-08-31T04:00:00.000Z"),
//             "timestamp": 1693454400000,
//             "label": "verizon"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "unknown",
//             "amount": 4,
//             "epochTimestamp": 1693458000,
//             "date": new Date("2023-08-31T05:00:00.000Z"),
//             "timestamp": 1693458000000,
//             "label": "other"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 551,
//             "epochTimestamp": 1693458000,
//             "date": new Date("2023-08-31T05:00:00.000Z"),
//             "timestamp": 1693458000000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "Verizon Wireless",
//             "amount": 21,
//             "epochTimestamp": 1693458000,
//             "date": new Date("2023-08-31T05:00:00.000Z"),
//             "timestamp": 1693458000000,
//             "label": "verizon"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "unknown",
//             "amount": 3,
//             "epochTimestamp": 1693461600,
//             "date": new Date("2023-08-31T06:00:00.000Z"),
//             "timestamp": 1693461600000,
//             "label": "other"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 497,
//             "epochTimestamp": 1693461600,
//             "date": new Date("2023-08-31T06:00:00.000Z"),
//             "timestamp": 1693461600000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "Verizon Wireless",
//             "amount": 23,
//             "epochTimestamp": 1693461600,
//             "date": new Date("2023-08-31T06:00:00.000Z"),
//             "timestamp": 1693461600000,
//             "label": "verizon"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "unknown",
//             "amount": 2,
//             "epochTimestamp": 1693465200,
//             "date": new Date("2023-08-31T07:00:00.000Z"),
//             "timestamp": 1693465200000,
//             "label": "other"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "Verizon Wireless",
//             "amount": 38,
//             "epochTimestamp": 1693465200,
//             "date": new Date("2023-08-31T07:00:00.000Z"),
//             "timestamp": 1693465200000,
//             "label": "verizon"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 347,
//             "epochTimestamp": 1693465200,
//             "date": new Date("2023-08-31T07:00:00.000Z"),
//             "timestamp": 1693465200000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "Verizon Wireless",
//             "amount": 38,
//             "epochTimestamp": 1693468800,
//             "date": new Date("2023-08-31T08:00:00.000Z"),
//             "timestamp": 1693468800000,
//             "label": "verizon"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "unknown",
//             "amount": 5,
//             "epochTimestamp": 1693468800,
//             "date": new Date("2023-08-31T08:00:00.000Z"),
//             "timestamp": 1693468800000,
//             "label": "other"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 347,
//             "epochTimestamp": 1693468800,
//             "date": new Date("2023-08-31T08:00:00.000Z"),
//             "timestamp": 1693468800000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 18,
//             "epochTimestamp": 1693472400,
//             "date": new Date("2023-08-31T09:00:00.000Z"),
//             "timestamp": 1693472400000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "Verizon Wireless",
//             "amount": 31,
//             "epochTimestamp": 1693472400,
//             "date": new Date("2023-08-31T09:00:00.000Z"),
//             "timestamp": 1693472400000,
//             "label": "verizon"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "unknown",
//             "amount": 5,
//             "epochTimestamp": 1693472400,
//             "date": new Date("2023-08-31T09:00:00.000Z"),
//             "timestamp": 1693472400000,
//             "label": "other"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "unknown",
//             "amount": 1,
//             "epochTimestamp": 1693476000,
//             "date": new Date("2023-08-31T10:00:00.000Z"),
//             "timestamp": 1693476000000,
//             "label": "other"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 64,
//             "epochTimestamp": 1693476000,
//             "date": new Date("2023-08-31T10:00:00.000Z"),
//             "timestamp": 1693476000000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "Verizon Wireless",
//             "amount": 45,
//             "epochTimestamp": 1693476000,
//             "date": new Date("2023-08-31T10:00:00.000Z"),
//             "timestamp": 1693476000000,
//             "label": "verizon"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 188,
//             "epochTimestamp": 1693479600,
//             "date": new Date("2023-08-31T11:00:00.000Z"),
//             "timestamp": 1693479600000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "Verizon Wireless",
//             "amount": 46,
//             "epochTimestamp": 1693479600,
//             "date": new Date("2023-08-31T11:00:00.000Z"),
//             "timestamp": 1693479600000,
//             "label": "verizon"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "Verizon Wireless",
//             "amount": 155,
//             "epochTimestamp": 1693483200,
//             "date": new Date("2023-08-31T12:00:00.000Z"),
//             "timestamp": 1693483200000,
//             "label": "verizon"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 160,
//             "epochTimestamp": 1693483200,
//             "date": new Date("2023-08-31T12:00:00.000Z"),
//             "timestamp": 1693483200000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "unknown",
//             "amount": 6,
//             "epochTimestamp": 1693483200,
//             "date": new Date("2023-08-31T12:00:00.000Z"),
//             "timestamp": 1693483200000,
//             "label": "other"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "Verizon Wireless",
//             "amount": 85,
//             "epochTimestamp": 1693486800,
//             "date": new Date("2023-08-31T13:00:00.000Z"),
//             "timestamp": 1693486800000,
//             "label": "verizon"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 278,
//             "epochTimestamp": 1693486800,
//             "date": new Date("2023-08-31T13:00:00.000Z"),
//             "timestamp": 1693486800000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "unknown",
//             "amount": 7,
//             "epochTimestamp": 1693486800,
//             "date": new Date("2023-08-31T13:00:00.000Z"),
//             "timestamp": 1693486800000,
//             "label": "other"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "Verizon Wireless",
//             "amount": 127,
//             "epochTimestamp": 1693490400,
//             "date": new Date("2023-08-31T14:00:00.000Z"),
//             "timestamp": 1693490400000,
//             "label": "verizon"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "unknown",
//             "amount": 4,
//             "epochTimestamp": 1693490400,
//             "date": new Date("2023-08-31T14:00:00.000Z"),
//             "timestamp": 1693490400000,
//             "label": "other"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 464,
//             "epochTimestamp": 1693490400,
//             "date": new Date("2023-08-31T14:00:00.000Z"),
//             "timestamp": 1693490400000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "Verizon Wireless",
//             "amount": 80,
//             "epochTimestamp": 1693494000,
//             "date": new Date("2023-08-31T15:00:00.000Z"),
//             "timestamp": 1693494000000,
//             "label": "verizon"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "unknown",
//             "amount": 9,
//             "epochTimestamp": 1693494000,
//             "date": new Date("2023-08-31T15:00:00.000Z"),
//             "timestamp": 1693494000000,
//             "label": "other"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 182,
//             "epochTimestamp": 1693494000,
//             "date": new Date("2023-08-31T15:00:00.000Z"),
//             "timestamp": 1693494000000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "unknown",
//             "amount": 16,
//             "epochTimestamp": 1693497600,
//             "date": new Date("2023-08-31T16:00:00.000Z"),
//             "timestamp": 1693497600000,
//             "label": "other"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "Verizon Wireless",
//             "amount": 71,
//             "epochTimestamp": 1693497600,
//             "date": new Date("2023-08-31T16:00:00.000Z"),
//             "timestamp": 1693497600000,
//             "label": "verizon"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 382,
//             "epochTimestamp": 1693497600,
//             "date": new Date("2023-08-31T16:00:00.000Z"),
//             "timestamp": 1693497600000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 341,
//             "epochTimestamp": 1693501200,
//             "date": new Date("2023-08-31T17:00:00.000Z"),
//             "timestamp": 1693501200000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "unknown",
//             "amount": 47,
//             "epochTimestamp": 1693501200,
//             "date": new Date("2023-08-31T17:00:00.000Z"),
//             "timestamp": 1693501200000,
//             "label": "other"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "Verizon Wireless",
//             "amount": 49,
//             "epochTimestamp": 1693501200,
//             "date": new Date("2023-08-31T17:00:00.000Z"),
//             "timestamp": 1693501200000,
//             "label": "verizon"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "unknown",
//             "amount": 40,
//             "epochTimestamp": 1693504800,
//             "date": new Date("2023-08-31T18:00:00.000Z"),
//             "timestamp": 1693504800000,
//             "label": "other"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 761,
//             "epochTimestamp": 1693504800,
//             "date": new Date("2023-08-31T18:00:00.000Z"),
//             "timestamp": 1693504800000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "Verizon Wireless",
//             "amount": 21,
//             "epochTimestamp": 1693504800,
//             "date": new Date("2023-08-31T18:00:00.000Z"),
//             "timestamp": 1693504800000,
//             "label": "verizon"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "unknown",
//             "amount": 7,
//             "epochTimestamp": 1693508400,
//             "date": new Date("2023-08-31T19:00:00.000Z"),
//             "timestamp": 1693508400000,
//             "label": "other"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 609,
//             "epochTimestamp": 1693508400,
//             "date": new Date("2023-08-31T19:00:00.000Z"),
//             "timestamp": 1693508400000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "Verizon Wireless",
//             "amount": 49,
//             "epochTimestamp": 1693508400,
//             "date": new Date("2023-08-31T19:00:00.000Z"),
//             "timestamp": 1693508400000,
//             "label": "verizon"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "unknown",
//             "amount": 20,
//             "epochTimestamp": 1693512000,
//             "date": new Date("2023-08-31T20:00:00.000Z"),
//             "timestamp": 1693512000000,
//             "label": "other"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "Verizon Wireless",
//             "amount": 109,
//             "epochTimestamp": 1693512000,
//             "date": new Date("2023-08-31T20:00:00.000Z"),
//             "timestamp": 1693512000000,
//             "label": "verizon"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 315,
//             "epochTimestamp": 1693512000,
//             "date": new Date("2023-08-31T20:00:00.000Z"),
//             "timestamp": 1693512000000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "unknown",
//             "amount": 9,
//             "epochTimestamp": 1693515600,
//             "date": new Date("2023-08-31T21:00:00.000Z"),
//             "timestamp": 1693515600000,
//             "label": "other"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 355,
//             "epochTimestamp": 1693515600,
//             "date": new Date("2023-08-31T21:00:00.000Z"),
//             "timestamp": 1693515600000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "Verizon Wireless",
//             "amount": 164,
//             "epochTimestamp": 1693515600,
//             "date": new Date("2023-08-31T21:00:00.000Z"),
//             "timestamp": 1693515600000,
//             "label": "verizon"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 613,
//             "epochTimestamp": 1693519200,
//             "date": new Date("2023-08-31T22:00:00.000Z"),
//             "timestamp": 1693519200000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "Verizon Wireless",
//             "amount": 68,
//             "epochTimestamp": 1693519200,
//             "date": new Date("2023-08-31T22:00:00.000Z"),
//             "timestamp": 1693519200000,
//             "label": "verizon"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "unknown",
//             "amount": 9,
//             "epochTimestamp": 1693519200,
//             "date": new Date("2023-08-31T22:00:00.000Z"),
//             "timestamp": 1693519200000,
//             "label": "other"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "Verizon Wireless",
//             "amount": 47,
//             "epochTimestamp": 1693522800,
//             "date": new Date("2023-08-31T23:00:00.000Z"),
//             "timestamp": 1693522800000,
//             "label": "verizon"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 487,
//             "epochTimestamp": 1693522800,
//             "date": new Date("2023-08-31T23:00:00.000Z"),
//             "timestamp": 1693522800000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "unknown",
//             "amount": 4,
//             "epochTimestamp": 1693522800,
//             "date": new Date("2023-08-31T23:00:00.000Z"),
//             "timestamp": 1693522800000,
//             "label": "other"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 562,
//             "epochTimestamp": 1693526400,
//             "date": new Date("2023-09-01T00:00:00.000Z"),
//             "timestamp": 1693526400000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "unknown",
//             "amount": 6,
//             "epochTimestamp": 1693526400,
//             "date": new Date("2023-09-01T00:00:00.000Z"),
//             "timestamp": 1693526400000,
//             "label": "other"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "Verizon Wireless",
//             "amount": 70,
//             "epochTimestamp": 1693526400,
//             "date": new Date("2023-09-01T00:00:00.000Z"),
//             "timestamp": 1693526400000,
//             "label": "verizon"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "unknown",
//             "amount": 1,
//             "epochTimestamp": 1693530000,
//             "date": new Date("2023-09-01T01:00:00.000Z"),
//             "timestamp": 1693530000000,
//             "label": "other"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "Verizon Wireless",
//             "amount": 104,
//             "epochTimestamp": 1693530000,
//             "date": new Date("2023-09-01T01:00:00.000Z"),
//             "timestamp": 1693530000000,
//             "label": "verizon"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 635,
//             "epochTimestamp": 1693530000,
//             "date": new Date("2023-09-01T01:00:00.000Z"),
//             "timestamp": 1693530000000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "Verizon Wireless",
//             "amount": 58,
//             "epochTimestamp": 1693533600,
//             "date": new Date("2023-09-01T02:00:00.000Z"),
//             "timestamp": 1693533600000,
//             "label": "verizon"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 674,
//             "epochTimestamp": 1693533600,
//             "date": new Date("2023-09-01T02:00:00.000Z"),
//             "timestamp": 1693533600000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "unknown",
//             "amount": 8,
//             "epochTimestamp": 1693533600,
//             "date": new Date("2023-09-01T02:00:00.000Z"),
//             "timestamp": 1693533600000,
//             "label": "other"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 479,
//             "epochTimestamp": 1693537200,
//             "date": new Date("2023-09-01T03:00:00.000Z"),
//             "timestamp": 1693537200000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "Verizon Wireless",
//             "amount": 114,
//             "epochTimestamp": 1693537200,
//             "date": new Date("2023-09-01T03:00:00.000Z"),
//             "timestamp": 1693537200000,
//             "label": "verizon"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "unknown",
//             "amount": 7,
//             "epochTimestamp": 1693537200,
//             "date": new Date("2023-09-01T03:00:00.000Z"),
//             "timestamp": 1693537200000,
//             "label": "other"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "unknown",
//             "amount": 2,
//             "epochTimestamp": 1693540800,
//             "date": new Date("2023-09-01T04:00:00.000Z"),
//             "timestamp": 1693540800000,
//             "label": "other"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 513,
//             "epochTimestamp": 1693540800,
//             "date": new Date("2023-09-01T04:00:00.000Z"),
//             "timestamp": 1693540800000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "Verizon Wireless",
//             "amount": 97,
//             "epochTimestamp": 1693540800,
//             "date": new Date("2023-09-01T04:00:00.000Z"),
//             "timestamp": 1693540800000,
//             "label": "verizon"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "Verizon Wireless",
//             "amount": 20,
//             "epochTimestamp": 1693544400,
//             "date": new Date("2023-09-01T05:00:00.000Z"),
//             "timestamp": 1693544400000,
//             "label": "verizon"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "AT&T Mobility",
//             "amount": 2,
//             "epochTimestamp": 1693544400,
//             "date": new Date("2023-09-01T05:00:00.000Z"),
//             "timestamp": 1693544400000,
//             "label": "at&t"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 380,
//             "epochTimestamp": 1693544400,
//             "date": new Date("2023-09-01T05:00:00.000Z"),
//             "timestamp": 1693544400000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "unknown",
//             "amount": 2,
//             "epochTimestamp": 1693544400,
//             "date": new Date("2023-09-01T05:00:00.000Z"),
//             "timestamp": 1693544400000,
//             "label": "other"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "Verizon Wireless",
//             "amount": 2,
//             "epochTimestamp": 1693548000,
//             "date": new Date("2023-09-01T06:00:00.000Z"),
//             "timestamp": 1693548000000,
//             "label": "verizon"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "unknown",
//             "amount": 3,
//             "epochTimestamp": 1693548000,
//             "date": new Date("2023-09-01T06:00:00.000Z"),
//             "timestamp": 1693548000000,
//             "label": "other"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 475,
//             "epochTimestamp": 1693548000,
//             "date": new Date("2023-09-01T06:00:00.000Z"),
//             "timestamp": 1693548000000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "unknown",
//             "amount": 2,
//             "epochTimestamp": 1693551600,
//             "date": new Date("2023-09-01T07:00:00.000Z"),
//             "timestamp": 1693551600000,
//             "label": "other"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 436,
//             "epochTimestamp": 1693551600,
//             "date": new Date("2023-09-01T07:00:00.000Z"),
//             "timestamp": 1693551600000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "Verizon Wireless",
//             "amount": 20,
//             "epochTimestamp": 1693551600,
//             "date": new Date("2023-09-01T07:00:00.000Z"),
//             "timestamp": 1693551600000,
//             "label": "verizon"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "AT&T Mobility",
//             "amount": 2,
//             "epochTimestamp": 1693551600,
//             "date": new Date("2023-09-01T07:00:00.000Z"),
//             "timestamp": 1693551600000,
//             "label": "at&t"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 215,
//             "epochTimestamp": 1693555200,
//             "date": new Date("2023-09-01T08:00:00.000Z"),
//             "timestamp": 1693555200000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "unknown",
//             "amount": 1,
//             "epochTimestamp": 1693555200,
//             "date": new Date("2023-09-01T08:00:00.000Z"),
//             "timestamp": 1693555200000,
//             "label": "other"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "Verizon Wireless",
//             "amount": 52,
//             "epochTimestamp": 1693555200,
//             "date": new Date("2023-09-01T08:00:00.000Z"),
//             "timestamp": 1693555200000,
//             "label": "verizon"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 151,
//             "epochTimestamp": 1693558800,
//             "date": new Date("2023-09-01T09:00:00.000Z"),
//             "timestamp": 1693558800000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "unknown",
//             "amount": 4,
//             "epochTimestamp": 1693558800,
//             "date": new Date("2023-09-01T09:00:00.000Z"),
//             "timestamp": 1693558800000,
//             "label": "other"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "Verizon Wireless",
//             "amount": 39,
//             "epochTimestamp": 1693558800,
//             "date": new Date("2023-09-01T09:00:00.000Z"),
//             "timestamp": 1693558800000,
//             "label": "verizon"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "unknown",
//             "amount": 6,
//             "epochTimestamp": 1693562400,
//             "date": new Date("2023-09-01T10:00:00.000Z"),
//             "timestamp": 1693562400000,
//             "label": "other"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 223,
//             "epochTimestamp": 1693562400,
//             "date": new Date("2023-09-01T10:00:00.000Z"),
//             "timestamp": 1693562400000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "Verizon Wireless",
//             "amount": 51,
//             "epochTimestamp": 1693562400,
//             "date": new Date("2023-09-01T10:00:00.000Z"),
//             "timestamp": 1693562400000,
//             "label": "verizon"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 238,
//             "epochTimestamp": 1693566000,
//             "date": new Date("2023-09-01T11:00:00.000Z"),
//             "timestamp": 1693566000000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "unknown",
//             "amount": 4,
//             "epochTimestamp": 1693566000,
//             "date": new Date("2023-09-01T11:00:00.000Z"),
//             "timestamp": 1693566000000,
//             "label": "other"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "Verizon Wireless",
//             "amount": 46,
//             "epochTimestamp": 1693566000,
//             "date": new Date("2023-09-01T11:00:00.000Z"),
//             "timestamp": 1693566000000,
//             "label": "verizon"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 254,
//             "epochTimestamp": 1693569600,
//             "date": new Date("2023-09-01T12:00:00.000Z"),
//             "timestamp": 1693569600000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "unknown",
//             "amount": 13,
//             "epochTimestamp": 1693569600,
//             "date": new Date("2023-09-01T12:00:00.000Z"),
//             "timestamp": 1693569600000,
//             "label": "other"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "Verizon Wireless",
//             "amount": 2,
//             "epochTimestamp": 1693569600,
//             "date": new Date("2023-09-01T12:00:00.000Z"),
//             "timestamp": 1693569600000,
//             "label": "verizon"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 270,
//             "epochTimestamp": 1693573200,
//             "date": new Date("2023-09-01T13:00:00.000Z"),
//             "timestamp": 1693573200000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "Verizon Wireless",
//             "amount": 54,
//             "epochTimestamp": 1693573200,
//             "date": new Date("2023-09-01T13:00:00.000Z"),
//             "timestamp": 1693573200000,
//             "label": "verizon"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "unknown",
//             "amount": 12,
//             "epochTimestamp": 1693573200,
//             "date": new Date("2023-09-01T13:00:00.000Z"),
//             "timestamp": 1693573200000,
//             "label": "other"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "unknown",
//             "amount": 8,
//             "epochTimestamp": 1693576800,
//             "date": new Date("2023-09-01T14:00:00.000Z"),
//             "timestamp": 1693576800000,
//             "label": "other"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 986,
//             "epochTimestamp": 1693576800,
//             "date": new Date("2023-09-01T14:00:00.000Z"),
//             "timestamp": 1693576800000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "Verizon Wireless",
//             "amount": 110,
//             "epochTimestamp": 1693576800,
//             "date": new Date("2023-09-01T14:00:00.000Z"),
//             "timestamp": 1693576800000,
//             "label": "verizon"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "unknown",
//             "amount": 11,
//             "epochTimestamp": 1693580400,
//             "date": new Date("2023-09-01T15:00:00.000Z"),
//             "timestamp": 1693580400000,
//             "label": "other"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "AT&T Mobility",
//             "amount": 10,
//             "epochTimestamp": 1693580400,
//             "date": new Date("2023-09-01T15:00:00.000Z"),
//             "timestamp": 1693580400000,
//             "label": "at&t"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "Verizon Wireless",
//             "amount": 22,
//             "epochTimestamp": 1693580400,
//             "date": new Date("2023-09-01T15:00:00.000Z"),
//             "timestamp": 1693580400000,
//             "label": "verizon"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 891,
//             "epochTimestamp": 1693580400,
//             "date": new Date("2023-09-01T15:00:00.000Z"),
//             "timestamp": 1693580400000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 170,
//             "epochTimestamp": 1693584000,
//             "date": new Date("2023-09-01T16:00:00.000Z"),
//             "timestamp": 1693584000000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "AT&T Mobility",
//             "amount": 171,
//             "epochTimestamp": 1693584000,
//             "date": new Date("2023-09-01T16:00:00.000Z"),
//             "timestamp": 1693584000000,
//             "label": "at&t"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "Verizon Wireless",
//             "amount": 80,
//             "epochTimestamp": 1693584000,
//             "date": new Date("2023-09-01T16:00:00.000Z"),
//             "timestamp": 1693584000000,
//             "label": "verizon"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "unknown",
//             "amount": 3,
//             "epochTimestamp": 1693584000,
//             "date": new Date("2023-09-01T16:00:00.000Z"),
//             "timestamp": 1693584000000,
//             "label": "other"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "T-Mobile USA",
//             "amount": 290,
//             "epochTimestamp": 1693587600,
//             "date": new Date("2023-09-01T17:00:00.000Z"),
//             "timestamp": 1693587600000,
//             "label": "t-mobile"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "Verizon Wireless",
//             "amount": 41,
//             "epochTimestamp": 1693587600,
//             "date": new Date("2023-09-01T17:00:00.000Z"),
//             "timestamp": 1693587600000,
//             "label": "verizon"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "unknown",
//             "amount": 5,
//             "epochTimestamp": 1693587600,
//             "date": new Date("2023-09-01T17:00:00.000Z"),
//             "timestamp": 1693587600000,
//             "label": "other"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "unknown",
//             "amount": 2,
//             "epochTimestamp": 1693591200,
//             "date": new Date("2023-09-01T18:00:00.000Z"),
//             "timestamp": 1693591200000,
//             "label": "other"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "Verizon Wireless",
//             "amount": 40,
//             "epochTimestamp": 1693591200,
//             "date": new Date("2023-09-01T18:00:00.000Z"),
//             "timestamp": 1693591200000,
//             "label": "verizon"
//         },
//         {
//             "type": "cell-scan",
//             "networkProvider": "AT&T Mobility",
//             "amount": 50,
//             "epochTimestamp": 1693591200,
//             "date": new Date("2023-09-01T18:00:00.000Z"),
//             "timestamp": 1693591200000,
//             "label": "at&t"
//         }
//     ],
//     "dailyProviderActivity": [
//         {
//             "items": [
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 160,
//                     "epochTimestamp": 1693594230,
//                     "date": new Date("2023-09-01T18:50:30.000Z"),
//                     "timestamp": 1693594230000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 380,
//                     "epochTimestamp": 1693544400,
//                     "date": new Date("2023-09-01T05:00:00.000Z"),
//                     "timestamp": 1693544400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 475,
//                     "epochTimestamp": 1693548000,
//                     "date": new Date("2023-09-01T06:00:00.000Z"),
//                     "timestamp": 1693548000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 436,
//                     "epochTimestamp": 1693551600,
//                     "date": new Date("2023-09-01T07:00:00.000Z"),
//                     "timestamp": 1693551600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 215,
//                     "epochTimestamp": 1693555200,
//                     "date": new Date("2023-09-01T08:00:00.000Z"),
//                     "timestamp": 1693555200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 151,
//                     "epochTimestamp": 1693558800,
//                     "date": new Date("2023-09-01T09:00:00.000Z"),
//                     "timestamp": 1693558800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 223,
//                     "epochTimestamp": 1693562400,
//                     "date": new Date("2023-09-01T10:00:00.000Z"),
//                     "timestamp": 1693562400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 238,
//                     "epochTimestamp": 1693566000,
//                     "date": new Date("2023-09-01T11:00:00.000Z"),
//                     "timestamp": 1693566000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 254,
//                     "epochTimestamp": 1693569600,
//                     "date": new Date("2023-09-01T12:00:00.000Z"),
//                     "timestamp": 1693569600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 270,
//                     "epochTimestamp": 1693573200,
//                     "date": new Date("2023-09-01T13:00:00.000Z"),
//                     "timestamp": 1693573200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 986,
//                     "epochTimestamp": 1693576800,
//                     "date": new Date("2023-09-01T14:00:00.000Z"),
//                     "timestamp": 1693576800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 891,
//                     "epochTimestamp": 1693580400,
//                     "date": new Date("2023-09-01T15:00:00.000Z"),
//                     "timestamp": 1693580400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 170,
//                     "epochTimestamp": 1693584000,
//                     "date": new Date("2023-09-01T16:00:00.000Z"),
//                     "timestamp": 1693584000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 290,
//                     "epochTimestamp": 1693587600,
//                     "date": new Date("2023-09-01T17:00:00.000Z"),
//                     "timestamp": 1693587600000
//                 }
//             ],
//             "amount": 5139,
//             "startDate": new Date("2023-09-01T05:00:00.000Z"),
//             "label": "t-mobile"
//         },
//         {
//             "items": [
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 181,
//                     "epochTimestamp": 1693112400,
//                     "date": new Date("2023-08-27T05:00:00.000Z"),
//                     "timestamp": 1693112400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 93,
//                     "epochTimestamp": 1693116000,
//                     "date": new Date("2023-08-27T06:00:00.000Z"),
//                     "timestamp": 1693116000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 80,
//                     "epochTimestamp": 1693119600,
//                     "date": new Date("2023-08-27T07:00:00.000Z"),
//                     "timestamp": 1693119600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 65,
//                     "epochTimestamp": 1693123200,
//                     "date": new Date("2023-08-27T08:00:00.000Z"),
//                     "timestamp": 1693123200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 86,
//                     "epochTimestamp": 1693126800,
//                     "date": new Date("2023-08-27T09:00:00.000Z"),
//                     "timestamp": 1693126800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 120,
//                     "epochTimestamp": 1693130400,
//                     "date": new Date("2023-08-27T10:00:00.000Z"),
//                     "timestamp": 1693130400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 132,
//                     "epochTimestamp": 1693134000,
//                     "date": new Date("2023-08-27T11:00:00.000Z"),
//                     "timestamp": 1693134000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 142,
//                     "epochTimestamp": 1693137600,
//                     "date": new Date("2023-08-27T12:00:00.000Z"),
//                     "timestamp": 1693137600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 136,
//                     "epochTimestamp": 1693141200,
//                     "date": new Date("2023-08-27T13:00:00.000Z"),
//                     "timestamp": 1693141200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 262,
//                     "epochTimestamp": 1693144800,
//                     "date": new Date("2023-08-27T14:00:00.000Z"),
//                     "timestamp": 1693144800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 92,
//                     "epochTimestamp": 1693148400,
//                     "date": new Date("2023-08-27T15:00:00.000Z"),
//                     "timestamp": 1693148400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 77,
//                     "epochTimestamp": 1693152000,
//                     "date": new Date("2023-08-27T16:00:00.000Z"),
//                     "timestamp": 1693152000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 240,
//                     "epochTimestamp": 1693155600,
//                     "date": new Date("2023-08-27T17:00:00.000Z"),
//                     "timestamp": 1693155600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 263,
//                     "epochTimestamp": 1693159200,
//                     "date": new Date("2023-08-27T18:00:00.000Z"),
//                     "timestamp": 1693159200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 204,
//                     "epochTimestamp": 1693162800,
//                     "date": new Date("2023-08-27T19:00:00.000Z"),
//                     "timestamp": 1693162800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 215,
//                     "epochTimestamp": 1693166400,
//                     "date": new Date("2023-08-27T20:00:00.000Z"),
//                     "timestamp": 1693166400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 145,
//                     "epochTimestamp": 1693170000,
//                     "date": new Date("2023-08-27T21:00:00.000Z"),
//                     "timestamp": 1693170000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 284,
//                     "epochTimestamp": 1693173600,
//                     "date": new Date("2023-08-27T22:00:00.000Z"),
//                     "timestamp": 1693173600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 203,
//                     "epochTimestamp": 1693177200,
//                     "date": new Date("2023-08-27T23:00:00.000Z"),
//                     "timestamp": 1693177200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 427,
//                     "epochTimestamp": 1693180800,
//                     "date": new Date("2023-08-28T00:00:00.000Z"),
//                     "timestamp": 1693180800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 271,
//                     "epochTimestamp": 1693184400,
//                     "date": new Date("2023-08-28T01:00:00.000Z"),
//                     "timestamp": 1693184400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 87,
//                     "epochTimestamp": 1693188000,
//                     "date": new Date("2023-08-28T02:00:00.000Z"),
//                     "timestamp": 1693188000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 201,
//                     "epochTimestamp": 1693191600,
//                     "date": new Date("2023-08-28T03:00:00.000Z"),
//                     "timestamp": 1693191600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 134,
//                     "epochTimestamp": 1693195200,
//                     "date": new Date("2023-08-28T04:00:00.000Z"),
//                     "timestamp": 1693195200000
//                 }
//             ],
//             "amount": 4140,
//             "startDate": new Date("2023-08-27T05:00:00.000Z"),
//             "label": "t-mobile"
//         },
//         {
//             "items": [
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 1,
//                     "epochTimestamp": 1693112400,
//                     "date": new Date("2023-08-27T05:00:00.000Z"),
//                     "timestamp": 1693112400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 3,
//                     "epochTimestamp": 1693116000,
//                     "date": new Date("2023-08-27T06:00:00.000Z"),
//                     "timestamp": 1693116000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 1,
//                     "epochTimestamp": 1693119600,
//                     "date": new Date("2023-08-27T07:00:00.000Z"),
//                     "timestamp": 1693119600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 2,
//                     "epochTimestamp": 1693123200,
//                     "date": new Date("2023-08-27T08:00:00.000Z"),
//                     "timestamp": 1693123200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 14,
//                     "epochTimestamp": 1693137600,
//                     "date": new Date("2023-08-27T12:00:00.000Z"),
//                     "timestamp": 1693137600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 14,
//                     "epochTimestamp": 1693141200,
//                     "date": new Date("2023-08-27T13:00:00.000Z"),
//                     "timestamp": 1693141200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 6,
//                     "epochTimestamp": 1693144800,
//                     "date": new Date("2023-08-27T14:00:00.000Z"),
//                     "timestamp": 1693144800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 4,
//                     "epochTimestamp": 1693148400,
//                     "date": new Date("2023-08-27T15:00:00.000Z"),
//                     "timestamp": 1693148400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 2,
//                     "epochTimestamp": 1693152000,
//                     "date": new Date("2023-08-27T16:00:00.000Z"),
//                     "timestamp": 1693152000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 3,
//                     "epochTimestamp": 1693159200,
//                     "date": new Date("2023-08-27T18:00:00.000Z"),
//                     "timestamp": 1693159200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 8,
//                     "epochTimestamp": 1693162800,
//                     "date": new Date("2023-08-27T19:00:00.000Z"),
//                     "timestamp": 1693162800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 7,
//                     "epochTimestamp": 1693166400,
//                     "date": new Date("2023-08-27T20:00:00.000Z"),
//                     "timestamp": 1693166400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 8,
//                     "epochTimestamp": 1693170000,
//                     "date": new Date("2023-08-27T21:00:00.000Z"),
//                     "timestamp": 1693170000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 7,
//                     "epochTimestamp": 1693177200,
//                     "date": new Date("2023-08-27T23:00:00.000Z"),
//                     "timestamp": 1693177200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 13,
//                     "epochTimestamp": 1693180800,
//                     "date": new Date("2023-08-28T00:00:00.000Z"),
//                     "timestamp": 1693180800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 39,
//                     "epochTimestamp": 1693184400,
//                     "date": new Date("2023-08-28T01:00:00.000Z"),
//                     "timestamp": 1693184400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 80,
//                     "epochTimestamp": 1693188000,
//                     "date": new Date("2023-08-28T02:00:00.000Z"),
//                     "timestamp": 1693188000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 3,
//                     "epochTimestamp": 1693191600,
//                     "date": new Date("2023-08-28T03:00:00.000Z"),
//                     "timestamp": 1693191600000
//                 }
//             ],
//             "amount": 215,
//             "startDate": new Date("2023-08-27T05:00:00.000Z"),
//             "label": "other"
//         },
//         {
//             "items": [
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 12,
//                     "epochTimestamp": 1693112400,
//                     "date": new Date("2023-08-27T05:00:00.000Z"),
//                     "timestamp": 1693112400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 6,
//                     "epochTimestamp": 1693116000,
//                     "date": new Date("2023-08-27T06:00:00.000Z"),
//                     "timestamp": 1693116000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 4,
//                     "epochTimestamp": 1693119600,
//                     "date": new Date("2023-08-27T07:00:00.000Z"),
//                     "timestamp": 1693119600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 2,
//                     "epochTimestamp": 1693123200,
//                     "date": new Date("2023-08-27T08:00:00.000Z"),
//                     "timestamp": 1693123200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 2,
//                     "epochTimestamp": 1693126800,
//                     "date": new Date("2023-08-27T09:00:00.000Z"),
//                     "timestamp": 1693126800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 4,
//                     "epochTimestamp": 1693141200,
//                     "date": new Date("2023-08-27T13:00:00.000Z"),
//                     "timestamp": 1693141200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 16,
//                     "epochTimestamp": 1693144800,
//                     "date": new Date("2023-08-27T14:00:00.000Z"),
//                     "timestamp": 1693144800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 32,
//                     "epochTimestamp": 1693148400,
//                     "date": new Date("2023-08-27T15:00:00.000Z"),
//                     "timestamp": 1693148400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 8,
//                     "epochTimestamp": 1693152000,
//                     "date": new Date("2023-08-27T16:00:00.000Z"),
//                     "timestamp": 1693152000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 15,
//                     "epochTimestamp": 1693155600,
//                     "date": new Date("2023-08-27T17:00:00.000Z"),
//                     "timestamp": 1693155600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 4,
//                     "epochTimestamp": 1693159200,
//                     "date": new Date("2023-08-27T18:00:00.000Z"),
//                     "timestamp": 1693159200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 47,
//                     "epochTimestamp": 1693162800,
//                     "date": new Date("2023-08-27T19:00:00.000Z"),
//                     "timestamp": 1693162800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 28,
//                     "epochTimestamp": 1693166400,
//                     "date": new Date("2023-08-27T20:00:00.000Z"),
//                     "timestamp": 1693166400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 45,
//                     "epochTimestamp": 1693170000,
//                     "date": new Date("2023-08-27T21:00:00.000Z"),
//                     "timestamp": 1693170000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 158,
//                     "epochTimestamp": 1693173600,
//                     "date": new Date("2023-08-27T22:00:00.000Z"),
//                     "timestamp": 1693173600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 14,
//                     "epochTimestamp": 1693177200,
//                     "date": new Date("2023-08-27T23:00:00.000Z"),
//                     "timestamp": 1693177200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 16,
//                     "epochTimestamp": 1693180800,
//                     "date": new Date("2023-08-28T00:00:00.000Z"),
//                     "timestamp": 1693180800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 6,
//                     "epochTimestamp": 1693184400,
//                     "date": new Date("2023-08-28T01:00:00.000Z"),
//                     "timestamp": 1693184400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 4,
//                     "epochTimestamp": 1693188000,
//                     "date": new Date("2023-08-28T02:00:00.000Z"),
//                     "timestamp": 1693188000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 9,
//                     "epochTimestamp": 1693191600,
//                     "date": new Date("2023-08-28T03:00:00.000Z"),
//                     "timestamp": 1693191600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 26,
//                     "epochTimestamp": 1693195200,
//                     "date": new Date("2023-08-28T04:00:00.000Z"),
//                     "timestamp": 1693195200000
//                 }
//             ],
//             "amount": 458,
//             "startDate": new Date("2023-08-27T05:00:00.000Z"),
//             "label": "verizon"
//         },
//         {
//             "items": [
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "AT&T Mobility",
//                     "amount": 4,
//                     "epochTimestamp": 1693191600,
//                     "date": new Date("2023-08-28T03:00:00.000Z"),
//                     "timestamp": 1693191600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "AT&T Mobility",
//                     "amount": 8,
//                     "epochTimestamp": 1693195200,
//                     "date": new Date("2023-08-28T04:00:00.000Z"),
//                     "timestamp": 1693195200000
//                 }
//             ],
//             "amount": 12,
//             "startDate": new Date("2023-08-27T05:00:00.000Z"),
//             "label": "at&t"
//         },
//         {
//             "items": [
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 2,
//                     "epochTimestamp": 1693198800,
//                     "date": new Date("2023-08-28T05:00:00.000Z"),
//                     "timestamp": 1693198800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 2,
//                     "epochTimestamp": 1693202400,
//                     "date": new Date("2023-08-28T06:00:00.000Z"),
//                     "timestamp": 1693202400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 1,
//                     "epochTimestamp": 1693209600,
//                     "date": new Date("2023-08-28T08:00:00.000Z"),
//                     "timestamp": 1693209600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 1,
//                     "epochTimestamp": 1693213200,
//                     "date": new Date("2023-08-28T09:00:00.000Z"),
//                     "timestamp": 1693213200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 1,
//                     "epochTimestamp": 1693216800,
//                     "date": new Date("2023-08-28T10:00:00.000Z"),
//                     "timestamp": 1693216800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 5,
//                     "epochTimestamp": 1693220400,
//                     "date": new Date("2023-08-28T11:00:00.000Z"),
//                     "timestamp": 1693220400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 8,
//                     "epochTimestamp": 1693224000,
//                     "date": new Date("2023-08-28T12:00:00.000Z"),
//                     "timestamp": 1693224000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 14,
//                     "epochTimestamp": 1693227600,
//                     "date": new Date("2023-08-28T13:00:00.000Z"),
//                     "timestamp": 1693227600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 19,
//                     "epochTimestamp": 1693231200,
//                     "date": new Date("2023-08-28T14:00:00.000Z"),
//                     "timestamp": 1693231200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 13,
//                     "epochTimestamp": 1693234800,
//                     "date": new Date("2023-08-28T15:00:00.000Z"),
//                     "timestamp": 1693234800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 15,
//                     "epochTimestamp": 1693238400,
//                     "date": new Date("2023-08-28T16:00:00.000Z"),
//                     "timestamp": 1693238400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 8,
//                     "epochTimestamp": 1693242000,
//                     "date": new Date("2023-08-28T17:00:00.000Z"),
//                     "timestamp": 1693242000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 34,
//                     "epochTimestamp": 1693245600,
//                     "date": new Date("2023-08-28T18:00:00.000Z"),
//                     "timestamp": 1693245600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 49,
//                     "epochTimestamp": 1693249200,
//                     "date": new Date("2023-08-28T19:00:00.000Z"),
//                     "timestamp": 1693249200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 12,
//                     "epochTimestamp": 1693252800,
//                     "date": new Date("2023-08-28T20:00:00.000Z"),
//                     "timestamp": 1693252800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 4,
//                     "epochTimestamp": 1693256400,
//                     "date": new Date("2023-08-28T21:00:00.000Z"),
//                     "timestamp": 1693256400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 7,
//                     "epochTimestamp": 1693260000,
//                     "date": new Date("2023-08-28T22:00:00.000Z"),
//                     "timestamp": 1693260000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 10,
//                     "epochTimestamp": 1693263600,
//                     "date": new Date("2023-08-28T23:00:00.000Z"),
//                     "timestamp": 1693263600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 3,
//                     "epochTimestamp": 1693267200,
//                     "date": new Date("2023-08-29T00:00:00.000Z"),
//                     "timestamp": 1693267200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 3,
//                     "epochTimestamp": 1693270800,
//                     "date": new Date("2023-08-29T01:00:00.000Z"),
//                     "timestamp": 1693270800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 7,
//                     "epochTimestamp": 1693274400,
//                     "date": new Date("2023-08-29T02:00:00.000Z"),
//                     "timestamp": 1693274400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 14,
//                     "epochTimestamp": 1693278000,
//                     "date": new Date("2023-08-29T03:00:00.000Z"),
//                     "timestamp": 1693278000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 7,
//                     "epochTimestamp": 1693281600,
//                     "date": new Date("2023-08-29T04:00:00.000Z"),
//                     "timestamp": 1693281600000
//                 }
//             ],
//             "amount": 239,
//             "startDate": new Date("2023-08-28T05:00:00.000Z"),
//             "label": "other"
//         },
//         {
//             "items": [
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "AT&T Mobility",
//                     "amount": 9,
//                     "epochTimestamp": 1693198800,
//                     "date": new Date("2023-08-28T05:00:00.000Z"),
//                     "timestamp": 1693198800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "AT&T Mobility",
//                     "amount": 10,
//                     "epochTimestamp": 1693202400,
//                     "date": new Date("2023-08-28T06:00:00.000Z"),
//                     "timestamp": 1693202400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "AT&T Mobility",
//                     "amount": 9,
//                     "epochTimestamp": 1693206000,
//                     "date": new Date("2023-08-28T07:00:00.000Z"),
//                     "timestamp": 1693206000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "AT&T Mobility",
//                     "amount": 10,
//                     "epochTimestamp": 1693209600,
//                     "date": new Date("2023-08-28T08:00:00.000Z"),
//                     "timestamp": 1693209600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "AT&T Mobility",
//                     "amount": 15,
//                     "epochTimestamp": 1693213200,
//                     "date": new Date("2023-08-28T09:00:00.000Z"),
//                     "timestamp": 1693213200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "AT&T Mobility",
//                     "amount": 11,
//                     "epochTimestamp": 1693216800,
//                     "date": new Date("2023-08-28T10:00:00.000Z"),
//                     "timestamp": 1693216800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "AT&T Mobility",
//                     "amount": 22,
//                     "epochTimestamp": 1693220400,
//                     "date": new Date("2023-08-28T11:00:00.000Z"),
//                     "timestamp": 1693220400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "AT&T Mobility",
//                     "amount": 14,
//                     "epochTimestamp": 1693224000,
//                     "date": new Date("2023-08-28T12:00:00.000Z"),
//                     "timestamp": 1693224000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "AT&T Mobility",
//                     "amount": 2,
//                     "epochTimestamp": 1693227600,
//                     "date": new Date("2023-08-28T13:00:00.000Z"),
//                     "timestamp": 1693227600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "AT&T Mobility",
//                     "amount": 10,
//                     "epochTimestamp": 1693242000,
//                     "date": new Date("2023-08-28T17:00:00.000Z"),
//                     "timestamp": 1693242000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "AT&T Mobility",
//                     "amount": 24,
//                     "epochTimestamp": 1693249200,
//                     "date": new Date("2023-08-28T19:00:00.000Z"),
//                     "timestamp": 1693249200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "AT&T Mobility",
//                     "amount": 32,
//                     "epochTimestamp": 1693252800,
//                     "date": new Date("2023-08-28T20:00:00.000Z"),
//                     "timestamp": 1693252800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "AT&T Mobility",
//                     "amount": 32,
//                     "epochTimestamp": 1693256400,
//                     "date": new Date("2023-08-28T21:00:00.000Z"),
//                     "timestamp": 1693256400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "AT&T Mobility",
//                     "amount": 8,
//                     "epochTimestamp": 1693260000,
//                     "date": new Date("2023-08-28T22:00:00.000Z"),
//                     "timestamp": 1693260000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "AT&T Mobility",
//                     "amount": 2,
//                     "epochTimestamp": 1693267200,
//                     "date": new Date("2023-08-29T00:00:00.000Z"),
//                     "timestamp": 1693267200000
//                 }
//             ],
//             "amount": 210,
//             "startDate": new Date("2023-08-28T05:00:00.000Z"),
//             "label": "at&t"
//         },
//         {
//             "items": [
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 182,
//                     "epochTimestamp": 1693198800,
//                     "date": new Date("2023-08-28T05:00:00.000Z"),
//                     "timestamp": 1693198800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 282,
//                     "epochTimestamp": 1693202400,
//                     "date": new Date("2023-08-28T06:00:00.000Z"),
//                     "timestamp": 1693202400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 94,
//                     "epochTimestamp": 1693206000,
//                     "date": new Date("2023-08-28T07:00:00.000Z"),
//                     "timestamp": 1693206000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 72,
//                     "epochTimestamp": 1693209600,
//                     "date": new Date("2023-08-28T08:00:00.000Z"),
//                     "timestamp": 1693209600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 84,
//                     "epochTimestamp": 1693213200,
//                     "date": new Date("2023-08-28T09:00:00.000Z"),
//                     "timestamp": 1693213200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 211,
//                     "epochTimestamp": 1693216800,
//                     "date": new Date("2023-08-28T10:00:00.000Z"),
//                     "timestamp": 1693216800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 216,
//                     "epochTimestamp": 1693220400,
//                     "date": new Date("2023-08-28T11:00:00.000Z"),
//                     "timestamp": 1693220400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 141,
//                     "epochTimestamp": 1693224000,
//                     "date": new Date("2023-08-28T12:00:00.000Z"),
//                     "timestamp": 1693224000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 117,
//                     "epochTimestamp": 1693227600,
//                     "date": new Date("2023-08-28T13:00:00.000Z"),
//                     "timestamp": 1693227600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 645,
//                     "epochTimestamp": 1693231200,
//                     "date": new Date("2023-08-28T14:00:00.000Z"),
//                     "timestamp": 1693231200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 227,
//                     "epochTimestamp": 1693234800,
//                     "date": new Date("2023-08-28T15:00:00.000Z"),
//                     "timestamp": 1693234800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 316,
//                     "epochTimestamp": 1693238400,
//                     "date": new Date("2023-08-28T16:00:00.000Z"),
//                     "timestamp": 1693238400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 65,
//                     "epochTimestamp": 1693242000,
//                     "date": new Date("2023-08-28T17:00:00.000Z"),
//                     "timestamp": 1693242000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 215,
//                     "epochTimestamp": 1693245600,
//                     "date": new Date("2023-08-28T18:00:00.000Z"),
//                     "timestamp": 1693245600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 158,
//                     "epochTimestamp": 1693249200,
//                     "date": new Date("2023-08-28T19:00:00.000Z"),
//                     "timestamp": 1693249200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 226,
//                     "epochTimestamp": 1693252800,
//                     "date": new Date("2023-08-28T20:00:00.000Z"),
//                     "timestamp": 1693252800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 424,
//                     "epochTimestamp": 1693256400,
//                     "date": new Date("2023-08-28T21:00:00.000Z"),
//                     "timestamp": 1693256400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 322,
//                     "epochTimestamp": 1693260000,
//                     "date": new Date("2023-08-28T22:00:00.000Z"),
//                     "timestamp": 1693260000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 326,
//                     "epochTimestamp": 1693263600,
//                     "date": new Date("2023-08-28T23:00:00.000Z"),
//                     "timestamp": 1693263600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 255,
//                     "epochTimestamp": 1693267200,
//                     "date": new Date("2023-08-29T00:00:00.000Z"),
//                     "timestamp": 1693267200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 154,
//                     "epochTimestamp": 1693270800,
//                     "date": new Date("2023-08-29T01:00:00.000Z"),
//                     "timestamp": 1693270800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 137,
//                     "epochTimestamp": 1693274400,
//                     "date": new Date("2023-08-29T02:00:00.000Z"),
//                     "timestamp": 1693274400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 304,
//                     "epochTimestamp": 1693278000,
//                     "date": new Date("2023-08-29T03:00:00.000Z"),
//                     "timestamp": 1693278000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 109,
//                     "epochTimestamp": 1693281600,
//                     "date": new Date("2023-08-29T04:00:00.000Z"),
//                     "timestamp": 1693281600000
//                 }
//             ],
//             "amount": 5282,
//             "startDate": new Date("2023-08-28T05:00:00.000Z"),
//             "label": "t-mobile"
//         },
//         {
//             "items": [
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 23,
//                     "epochTimestamp": 1693198800,
//                     "date": new Date("2023-08-28T05:00:00.000Z"),
//                     "timestamp": 1693198800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 36,
//                     "epochTimestamp": 1693202400,
//                     "date": new Date("2023-08-28T06:00:00.000Z"),
//                     "timestamp": 1693202400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 7,
//                     "epochTimestamp": 1693206000,
//                     "date": new Date("2023-08-28T07:00:00.000Z"),
//                     "timestamp": 1693206000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 13,
//                     "epochTimestamp": 1693209600,
//                     "date": new Date("2023-08-28T08:00:00.000Z"),
//                     "timestamp": 1693209600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 39,
//                     "epochTimestamp": 1693213200,
//                     "date": new Date("2023-08-28T09:00:00.000Z"),
//                     "timestamp": 1693213200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 16,
//                     "epochTimestamp": 1693216800,
//                     "date": new Date("2023-08-28T10:00:00.000Z"),
//                     "timestamp": 1693216800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 32,
//                     "epochTimestamp": 1693220400,
//                     "date": new Date("2023-08-28T11:00:00.000Z"),
//                     "timestamp": 1693220400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 63,
//                     "epochTimestamp": 1693224000,
//                     "date": new Date("2023-08-28T12:00:00.000Z"),
//                     "timestamp": 1693224000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 29,
//                     "epochTimestamp": 1693227600,
//                     "date": new Date("2023-08-28T13:00:00.000Z"),
//                     "timestamp": 1693227600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 95,
//                     "epochTimestamp": 1693231200,
//                     "date": new Date("2023-08-28T14:00:00.000Z"),
//                     "timestamp": 1693231200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 34,
//                     "epochTimestamp": 1693234800,
//                     "date": new Date("2023-08-28T15:00:00.000Z"),
//                     "timestamp": 1693234800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 92,
//                     "epochTimestamp": 1693238400,
//                     "date": new Date("2023-08-28T16:00:00.000Z"),
//                     "timestamp": 1693238400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 82,
//                     "epochTimestamp": 1693242000,
//                     "date": new Date("2023-08-28T17:00:00.000Z"),
//                     "timestamp": 1693242000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 60,
//                     "epochTimestamp": 1693245600,
//                     "date": new Date("2023-08-28T18:00:00.000Z"),
//                     "timestamp": 1693245600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 65,
//                     "epochTimestamp": 1693249200,
//                     "date": new Date("2023-08-28T19:00:00.000Z"),
//                     "timestamp": 1693249200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 80,
//                     "epochTimestamp": 1693252800,
//                     "date": new Date("2023-08-28T20:00:00.000Z"),
//                     "timestamp": 1693252800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 36,
//                     "epochTimestamp": 1693256400,
//                     "date": new Date("2023-08-28T21:00:00.000Z"),
//                     "timestamp": 1693256400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 68,
//                     "epochTimestamp": 1693260000,
//                     "date": new Date("2023-08-28T22:00:00.000Z"),
//                     "timestamp": 1693260000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 111,
//                     "epochTimestamp": 1693263600,
//                     "date": new Date("2023-08-28T23:00:00.000Z"),
//                     "timestamp": 1693263600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 30,
//                     "epochTimestamp": 1693267200,
//                     "date": new Date("2023-08-29T00:00:00.000Z"),
//                     "timestamp": 1693267200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 6,
//                     "epochTimestamp": 1693270800,
//                     "date": new Date("2023-08-29T01:00:00.000Z"),
//                     "timestamp": 1693270800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 8,
//                     "epochTimestamp": 1693274400,
//                     "date": new Date("2023-08-29T02:00:00.000Z"),
//                     "timestamp": 1693274400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 6,
//                     "epochTimestamp": 1693278000,
//                     "date": new Date("2023-08-29T03:00:00.000Z"),
//                     "timestamp": 1693278000000
//                 }
//             ],
//             "amount": 1031,
//             "startDate": new Date("2023-08-28T05:00:00.000Z"),
//             "label": "verizon"
//         },
//         {
//             "items": [
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 226,
//                     "epochTimestamp": 1693285200,
//                     "date": new Date("2023-08-29T05:00:00.000Z"),
//                     "timestamp": 1693285200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 156,
//                     "epochTimestamp": 1693288800,
//                     "date": new Date("2023-08-29T06:00:00.000Z"),
//                     "timestamp": 1693288800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 113,
//                     "epochTimestamp": 1693292400,
//                     "date": new Date("2023-08-29T07:00:00.000Z"),
//                     "timestamp": 1693292400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 113,
//                     "epochTimestamp": 1693296000,
//                     "date": new Date("2023-08-29T08:00:00.000Z"),
//                     "timestamp": 1693296000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 149,
//                     "epochTimestamp": 1693299600,
//                     "date": new Date("2023-08-29T09:00:00.000Z"),
//                     "timestamp": 1693299600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 26,
//                     "epochTimestamp": 1693303200,
//                     "date": new Date("2023-08-29T10:00:00.000Z"),
//                     "timestamp": 1693303200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 11,
//                     "epochTimestamp": 1693306800,
//                     "date": new Date("2023-08-29T11:00:00.000Z"),
//                     "timestamp": 1693306800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 31,
//                     "epochTimestamp": 1693310400,
//                     "date": new Date("2023-08-29T12:00:00.000Z"),
//                     "timestamp": 1693310400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 80,
//                     "epochTimestamp": 1693314000,
//                     "date": new Date("2023-08-29T13:00:00.000Z"),
//                     "timestamp": 1693314000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 134,
//                     "epochTimestamp": 1693317600,
//                     "date": new Date("2023-08-29T14:00:00.000Z"),
//                     "timestamp": 1693317600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 347,
//                     "epochTimestamp": 1693321200,
//                     "date": new Date("2023-08-29T15:00:00.000Z"),
//                     "timestamp": 1693321200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 176,
//                     "epochTimestamp": 1693324800,
//                     "date": new Date("2023-08-29T16:00:00.000Z"),
//                     "timestamp": 1693324800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 151,
//                     "epochTimestamp": 1693328400,
//                     "date": new Date("2023-08-29T17:00:00.000Z"),
//                     "timestamp": 1693328400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 239,
//                     "epochTimestamp": 1693332000,
//                     "date": new Date("2023-08-29T18:00:00.000Z"),
//                     "timestamp": 1693332000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 169,
//                     "epochTimestamp": 1693335600,
//                     "date": new Date("2023-08-29T19:00:00.000Z"),
//                     "timestamp": 1693335600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 766,
//                     "epochTimestamp": 1693339200,
//                     "date": new Date("2023-08-29T20:00:00.000Z"),
//                     "timestamp": 1693339200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 373,
//                     "epochTimestamp": 1693342800,
//                     "date": new Date("2023-08-29T21:00:00.000Z"),
//                     "timestamp": 1693342800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 298,
//                     "epochTimestamp": 1693346400,
//                     "date": new Date("2023-08-29T22:00:00.000Z"),
//                     "timestamp": 1693346400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 280,
//                     "epochTimestamp": 1693350000,
//                     "date": new Date("2023-08-29T23:00:00.000Z"),
//                     "timestamp": 1693350000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 314,
//                     "epochTimestamp": 1693353600,
//                     "date": new Date("2023-08-30T00:00:00.000Z"),
//                     "timestamp": 1693353600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 412,
//                     "epochTimestamp": 1693357200,
//                     "date": new Date("2023-08-30T01:00:00.000Z"),
//                     "timestamp": 1693357200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 186,
//                     "epochTimestamp": 1693360800,
//                     "date": new Date("2023-08-30T02:00:00.000Z"),
//                     "timestamp": 1693360800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 239,
//                     "epochTimestamp": 1693364400,
//                     "date": new Date("2023-08-30T03:00:00.000Z"),
//                     "timestamp": 1693364400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 334,
//                     "epochTimestamp": 1693368000,
//                     "date": new Date("2023-08-30T04:00:00.000Z"),
//                     "timestamp": 1693368000000
//                 }
//             ],
//             "amount": 5323,
//             "startDate": new Date("2023-08-29T05:00:00.000Z"),
//             "label": "t-mobile"
//         },
//         {
//             "items": [
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 16,
//                     "epochTimestamp": 1693285200,
//                     "date": new Date("2023-08-29T05:00:00.000Z"),
//                     "timestamp": 1693285200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 22,
//                     "epochTimestamp": 1693288800,
//                     "date": new Date("2023-08-29T06:00:00.000Z"),
//                     "timestamp": 1693288800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 8,
//                     "epochTimestamp": 1693292400,
//                     "date": new Date("2023-08-29T07:00:00.000Z"),
//                     "timestamp": 1693292400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 18,
//                     "epochTimestamp": 1693296000,
//                     "date": new Date("2023-08-29T08:00:00.000Z"),
//                     "timestamp": 1693296000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 3,
//                     "epochTimestamp": 1693306800,
//                     "date": new Date("2023-08-29T11:00:00.000Z"),
//                     "timestamp": 1693306800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 373,
//                     "epochTimestamp": 1693310400,
//                     "date": new Date("2023-08-29T12:00:00.000Z"),
//                     "timestamp": 1693310400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 66,
//                     "epochTimestamp": 1693314000,
//                     "date": new Date("2023-08-29T13:00:00.000Z"),
//                     "timestamp": 1693314000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 119,
//                     "epochTimestamp": 1693317600,
//                     "date": new Date("2023-08-29T14:00:00.000Z"),
//                     "timestamp": 1693317600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 176,
//                     "epochTimestamp": 1693321200,
//                     "date": new Date("2023-08-29T15:00:00.000Z"),
//                     "timestamp": 1693321200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 4,
//                     "epochTimestamp": 1693332000,
//                     "date": new Date("2023-08-29T18:00:00.000Z"),
//                     "timestamp": 1693332000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 6,
//                     "epochTimestamp": 1693335600,
//                     "date": new Date("2023-08-29T19:00:00.000Z"),
//                     "timestamp": 1693335600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 108,
//                     "epochTimestamp": 1693339200,
//                     "date": new Date("2023-08-29T20:00:00.000Z"),
//                     "timestamp": 1693339200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 14,
//                     "epochTimestamp": 1693342800,
//                     "date": new Date("2023-08-29T21:00:00.000Z"),
//                     "timestamp": 1693342800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 82,
//                     "epochTimestamp": 1693346400,
//                     "date": new Date("2023-08-29T22:00:00.000Z"),
//                     "timestamp": 1693346400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 26,
//                     "epochTimestamp": 1693350000,
//                     "date": new Date("2023-08-29T23:00:00.000Z"),
//                     "timestamp": 1693350000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 10,
//                     "epochTimestamp": 1693353600,
//                     "date": new Date("2023-08-30T00:00:00.000Z"),
//                     "timestamp": 1693353600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 30,
//                     "epochTimestamp": 1693357200,
//                     "date": new Date("2023-08-30T01:00:00.000Z"),
//                     "timestamp": 1693357200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 3,
//                     "epochTimestamp": 1693368000,
//                     "date": new Date("2023-08-30T04:00:00.000Z"),
//                     "timestamp": 1693368000000
//                 }
//             ],
//             "amount": 1084,
//             "startDate": new Date("2023-08-29T05:00:00.000Z"),
//             "label": "verizon"
//         },
//         {
//             "items": [
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 1,
//                     "epochTimestamp": 1693285200,
//                     "date": new Date("2023-08-29T05:00:00.000Z"),
//                     "timestamp": 1693285200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 1,
//                     "epochTimestamp": 1693288800,
//                     "date": new Date("2023-08-29T06:00:00.000Z"),
//                     "timestamp": 1693288800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 1,
//                     "epochTimestamp": 1693303200,
//                     "date": new Date("2023-08-29T10:00:00.000Z"),
//                     "timestamp": 1693303200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 6,
//                     "epochTimestamp": 1693306800,
//                     "date": new Date("2023-08-29T11:00:00.000Z"),
//                     "timestamp": 1693306800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 7,
//                     "epochTimestamp": 1693310400,
//                     "date": new Date("2023-08-29T12:00:00.000Z"),
//                     "timestamp": 1693310400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 3,
//                     "epochTimestamp": 1693314000,
//                     "date": new Date("2023-08-29T13:00:00.000Z"),
//                     "timestamp": 1693314000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 1,
//                     "epochTimestamp": 1693317600,
//                     "date": new Date("2023-08-29T14:00:00.000Z"),
//                     "timestamp": 1693317600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 9,
//                     "epochTimestamp": 1693321200,
//                     "date": new Date("2023-08-29T15:00:00.000Z"),
//                     "timestamp": 1693321200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 9,
//                     "epochTimestamp": 1693324800,
//                     "date": new Date("2023-08-29T16:00:00.000Z"),
//                     "timestamp": 1693324800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 6,
//                     "epochTimestamp": 1693328400,
//                     "date": new Date("2023-08-29T17:00:00.000Z"),
//                     "timestamp": 1693328400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 4,
//                     "epochTimestamp": 1693332000,
//                     "date": new Date("2023-08-29T18:00:00.000Z"),
//                     "timestamp": 1693332000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 4,
//                     "epochTimestamp": 1693335600,
//                     "date": new Date("2023-08-29T19:00:00.000Z"),
//                     "timestamp": 1693335600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 18,
//                     "epochTimestamp": 1693339200,
//                     "date": new Date("2023-08-29T20:00:00.000Z"),
//                     "timestamp": 1693339200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 14,
//                     "epochTimestamp": 1693342800,
//                     "date": new Date("2023-08-29T21:00:00.000Z"),
//                     "timestamp": 1693342800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 3,
//                     "epochTimestamp": 1693346400,
//                     "date": new Date("2023-08-29T22:00:00.000Z"),
//                     "timestamp": 1693346400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 8,
//                     "epochTimestamp": 1693350000,
//                     "date": new Date("2023-08-29T23:00:00.000Z"),
//                     "timestamp": 1693350000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 1,
//                     "epochTimestamp": 1693353600,
//                     "date": new Date("2023-08-30T00:00:00.000Z"),
//                     "timestamp": 1693353600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 12,
//                     "epochTimestamp": 1693357200,
//                     "date": new Date("2023-08-30T01:00:00.000Z"),
//                     "timestamp": 1693357200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 35,
//                     "epochTimestamp": 1693360800,
//                     "date": new Date("2023-08-30T02:00:00.000Z"),
//                     "timestamp": 1693360800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 31,
//                     "epochTimestamp": 1693364400,
//                     "date": new Date("2023-08-30T03:00:00.000Z"),
//                     "timestamp": 1693364400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 3,
//                     "epochTimestamp": 1693368000,
//                     "date": new Date("2023-08-30T04:00:00.000Z"),
//                     "timestamp": 1693368000000
//                 }
//             ],
//             "amount": 177,
//             "startDate": new Date("2023-08-29T05:00:00.000Z"),
//             "label": "other"
//         },
//         {
//             "items": [
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "AT&T Mobility",
//                     "amount": 2,
//                     "epochTimestamp": 1693296000,
//                     "date": new Date("2023-08-29T08:00:00.000Z"),
//                     "timestamp": 1693296000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "AT&T Mobility",
//                     "amount": 4,
//                     "epochTimestamp": 1693310400,
//                     "date": new Date("2023-08-29T12:00:00.000Z"),
//                     "timestamp": 1693310400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "AT&T Mobility",
//                     "amount": 2,
//                     "epochTimestamp": 1693321200,
//                     "date": new Date("2023-08-29T15:00:00.000Z"),
//                     "timestamp": 1693321200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "AT&T Mobility",
//                     "amount": 8,
//                     "epochTimestamp": 1693324800,
//                     "date": new Date("2023-08-29T16:00:00.000Z"),
//                     "timestamp": 1693324800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "AT&T Mobility",
//                     "amount": 77,
//                     "epochTimestamp": 1693328400,
//                     "date": new Date("2023-08-29T17:00:00.000Z"),
//                     "timestamp": 1693328400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "AT&T Mobility",
//                     "amount": 22,
//                     "epochTimestamp": 1693332000,
//                     "date": new Date("2023-08-29T18:00:00.000Z"),
//                     "timestamp": 1693332000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "AT&T Mobility",
//                     "amount": 18,
//                     "epochTimestamp": 1693335600,
//                     "date": new Date("2023-08-29T19:00:00.000Z"),
//                     "timestamp": 1693335600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "AT&T Mobility",
//                     "amount": 4,
//                     "epochTimestamp": 1693339200,
//                     "date": new Date("2023-08-29T20:00:00.000Z"),
//                     "timestamp": 1693339200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "AT&T Mobility",
//                     "amount": 26,
//                     "epochTimestamp": 1693342800,
//                     "date": new Date("2023-08-29T21:00:00.000Z"),
//                     "timestamp": 1693342800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "AT&T Mobility",
//                     "amount": 50,
//                     "epochTimestamp": 1693346400,
//                     "date": new Date("2023-08-29T22:00:00.000Z"),
//                     "timestamp": 1693346400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "AT&T Mobility",
//                     "amount": 12,
//                     "epochTimestamp": 1693350000,
//                     "date": new Date("2023-08-29T23:00:00.000Z"),
//                     "timestamp": 1693350000000
//                 }
//             ],
//             "amount": 225,
//             "startDate": new Date("2023-08-29T05:00:00.000Z"),
//             "label": "at&t"
//         },
//         {
//             "items": [
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 2,
//                     "epochTimestamp": 1693371600,
//                     "date": new Date("2023-08-30T05:00:00.000Z"),
//                     "timestamp": 1693371600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 3,
//                     "epochTimestamp": 1693375200,
//                     "date": new Date("2023-08-30T06:00:00.000Z"),
//                     "timestamp": 1693375200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 3,
//                     "epochTimestamp": 1693382400,
//                     "date": new Date("2023-08-30T08:00:00.000Z"),
//                     "timestamp": 1693382400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 3,
//                     "epochTimestamp": 1693389600,
//                     "date": new Date("2023-08-30T10:00:00.000Z"),
//                     "timestamp": 1693389600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 4,
//                     "epochTimestamp": 1693393200,
//                     "date": new Date("2023-08-30T11:00:00.000Z"),
//                     "timestamp": 1693393200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 7,
//                     "epochTimestamp": 1693396800,
//                     "date": new Date("2023-08-30T12:00:00.000Z"),
//                     "timestamp": 1693396800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 7,
//                     "epochTimestamp": 1693400400,
//                     "date": new Date("2023-08-30T13:00:00.000Z"),
//                     "timestamp": 1693400400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 7,
//                     "epochTimestamp": 1693404000,
//                     "date": new Date("2023-08-30T14:00:00.000Z"),
//                     "timestamp": 1693404000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 2,
//                     "epochTimestamp": 1693407600,
//                     "date": new Date("2023-08-30T15:00:00.000Z"),
//                     "timestamp": 1693407600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 31,
//                     "epochTimestamp": 1693411200,
//                     "date": new Date("2023-08-30T16:00:00.000Z"),
//                     "timestamp": 1693411200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 7,
//                     "epochTimestamp": 1693414800,
//                     "date": new Date("2023-08-30T17:00:00.000Z"),
//                     "timestamp": 1693414800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 3,
//                     "epochTimestamp": 1693418400,
//                     "date": new Date("2023-08-30T18:00:00.000Z"),
//                     "timestamp": 1693418400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 31,
//                     "epochTimestamp": 1693422000,
//                     "date": new Date("2023-08-30T19:00:00.000Z"),
//                     "timestamp": 1693422000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 16,
//                     "epochTimestamp": 1693425600,
//                     "date": new Date("2023-08-30T20:00:00.000Z"),
//                     "timestamp": 1693425600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 21,
//                     "epochTimestamp": 1693429200,
//                     "date": new Date("2023-08-30T21:00:00.000Z"),
//                     "timestamp": 1693429200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 11,
//                     "epochTimestamp": 1693432800,
//                     "date": new Date("2023-08-30T22:00:00.000Z"),
//                     "timestamp": 1693432800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 9,
//                     "epochTimestamp": 1693436400,
//                     "date": new Date("2023-08-30T23:00:00.000Z"),
//                     "timestamp": 1693436400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 4,
//                     "epochTimestamp": 1693440000,
//                     "date": new Date("2023-08-31T00:00:00.000Z"),
//                     "timestamp": 1693440000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 4,
//                     "epochTimestamp": 1693443600,
//                     "date": new Date("2023-08-31T01:00:00.000Z"),
//                     "timestamp": 1693443600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 11,
//                     "epochTimestamp": 1693447200,
//                     "date": new Date("2023-08-31T02:00:00.000Z"),
//                     "timestamp": 1693447200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 35,
//                     "epochTimestamp": 1693450800,
//                     "date": new Date("2023-08-31T03:00:00.000Z"),
//                     "timestamp": 1693450800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 15,
//                     "epochTimestamp": 1693454400,
//                     "date": new Date("2023-08-31T04:00:00.000Z"),
//                     "timestamp": 1693454400000
//                 }
//             ],
//             "amount": 236,
//             "startDate": new Date("2023-08-30T05:00:00.000Z"),
//             "label": "other"
//         },
//         {
//             "items": [
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 347,
//                     "epochTimestamp": 1693371600,
//                     "date": new Date("2023-08-30T05:00:00.000Z"),
//                     "timestamp": 1693371600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 387,
//                     "epochTimestamp": 1693375200,
//                     "date": new Date("2023-08-30T06:00:00.000Z"),
//                     "timestamp": 1693375200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 442,
//                     "epochTimestamp": 1693378800,
//                     "date": new Date("2023-08-30T07:00:00.000Z"),
//                     "timestamp": 1693378800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 409,
//                     "epochTimestamp": 1693382400,
//                     "date": new Date("2023-08-30T08:00:00.000Z"),
//                     "timestamp": 1693382400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 384,
//                     "epochTimestamp": 1693386000,
//                     "date": new Date("2023-08-30T09:00:00.000Z"),
//                     "timestamp": 1693386000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 322,
//                     "epochTimestamp": 1693389600,
//                     "date": new Date("2023-08-30T10:00:00.000Z"),
//                     "timestamp": 1693389600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 297,
//                     "epochTimestamp": 1693393200,
//                     "date": new Date("2023-08-30T11:00:00.000Z"),
//                     "timestamp": 1693393200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 570,
//                     "epochTimestamp": 1693396800,
//                     "date": new Date("2023-08-30T12:00:00.000Z"),
//                     "timestamp": 1693396800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 358,
//                     "epochTimestamp": 1693400400,
//                     "date": new Date("2023-08-30T13:00:00.000Z"),
//                     "timestamp": 1693400400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 487,
//                     "epochTimestamp": 1693404000,
//                     "date": new Date("2023-08-30T14:00:00.000Z"),
//                     "timestamp": 1693404000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 578,
//                     "epochTimestamp": 1693407600,
//                     "date": new Date("2023-08-30T15:00:00.000Z"),
//                     "timestamp": 1693407600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 112,
//                     "epochTimestamp": 1693411200,
//                     "date": new Date("2023-08-30T16:00:00.000Z"),
//                     "timestamp": 1693411200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 228,
//                     "epochTimestamp": 1693414800,
//                     "date": new Date("2023-08-30T17:00:00.000Z"),
//                     "timestamp": 1693414800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 511,
//                     "epochTimestamp": 1693418400,
//                     "date": new Date("2023-08-30T18:00:00.000Z"),
//                     "timestamp": 1693418400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 238,
//                     "epochTimestamp": 1693422000,
//                     "date": new Date("2023-08-30T19:00:00.000Z"),
//                     "timestamp": 1693422000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 524,
//                     "epochTimestamp": 1693425600,
//                     "date": new Date("2023-08-30T20:00:00.000Z"),
//                     "timestamp": 1693425600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 852,
//                     "epochTimestamp": 1693429200,
//                     "date": new Date("2023-08-30T21:00:00.000Z"),
//                     "timestamp": 1693429200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 512,
//                     "epochTimestamp": 1693432800,
//                     "date": new Date("2023-08-30T22:00:00.000Z"),
//                     "timestamp": 1693432800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 328,
//                     "epochTimestamp": 1693436400,
//                     "date": new Date("2023-08-30T23:00:00.000Z"),
//                     "timestamp": 1693436400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 268,
//                     "epochTimestamp": 1693440000,
//                     "date": new Date("2023-08-31T00:00:00.000Z"),
//                     "timestamp": 1693440000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 331,
//                     "epochTimestamp": 1693443600,
//                     "date": new Date("2023-08-31T01:00:00.000Z"),
//                     "timestamp": 1693443600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 411,
//                     "epochTimestamp": 1693447200,
//                     "date": new Date("2023-08-31T02:00:00.000Z"),
//                     "timestamp": 1693447200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 524,
//                     "epochTimestamp": 1693450800,
//                     "date": new Date("2023-08-31T03:00:00.000Z"),
//                     "timestamp": 1693450800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 383,
//                     "epochTimestamp": 1693454400,
//                     "date": new Date("2023-08-31T04:00:00.000Z"),
//                     "timestamp": 1693454400000
//                 }
//             ],
//             "amount": 9803,
//             "startDate": new Date("2023-08-30T05:00:00.000Z"),
//             "label": "t-mobile"
//         },
//         {
//             "items": [
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 2,
//                     "epochTimestamp": 1693378800,
//                     "date": new Date("2023-08-30T07:00:00.000Z"),
//                     "timestamp": 1693378800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 9,
//                     "epochTimestamp": 1693382400,
//                     "date": new Date("2023-08-30T08:00:00.000Z"),
//                     "timestamp": 1693382400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 5,
//                     "epochTimestamp": 1693386000,
//                     "date": new Date("2023-08-30T09:00:00.000Z"),
//                     "timestamp": 1693386000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 2,
//                     "epochTimestamp": 1693389600,
//                     "date": new Date("2023-08-30T10:00:00.000Z"),
//                     "timestamp": 1693389600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 7,
//                     "epochTimestamp": 1693393200,
//                     "date": new Date("2023-08-30T11:00:00.000Z"),
//                     "timestamp": 1693393200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 79,
//                     "epochTimestamp": 1693396800,
//                     "date": new Date("2023-08-30T12:00:00.000Z"),
//                     "timestamp": 1693396800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 42,
//                     "epochTimestamp": 1693400400,
//                     "date": new Date("2023-08-30T13:00:00.000Z"),
//                     "timestamp": 1693400400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 43,
//                     "epochTimestamp": 1693404000,
//                     "date": new Date("2023-08-30T14:00:00.000Z"),
//                     "timestamp": 1693404000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 101,
//                     "epochTimestamp": 1693407600,
//                     "date": new Date("2023-08-30T15:00:00.000Z"),
//                     "timestamp": 1693407600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 9,
//                     "epochTimestamp": 1693411200,
//                     "date": new Date("2023-08-30T16:00:00.000Z"),
//                     "timestamp": 1693411200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 61,
//                     "epochTimestamp": 1693414800,
//                     "date": new Date("2023-08-30T17:00:00.000Z"),
//                     "timestamp": 1693414800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 109,
//                     "epochTimestamp": 1693418400,
//                     "date": new Date("2023-08-30T18:00:00.000Z"),
//                     "timestamp": 1693418400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 4,
//                     "epochTimestamp": 1693422000,
//                     "date": new Date("2023-08-30T19:00:00.000Z"),
//                     "timestamp": 1693422000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 6,
//                     "epochTimestamp": 1693425600,
//                     "date": new Date("2023-08-30T20:00:00.000Z"),
//                     "timestamp": 1693425600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 212,
//                     "epochTimestamp": 1693429200,
//                     "date": new Date("2023-08-30T21:00:00.000Z"),
//                     "timestamp": 1693429200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 187,
//                     "epochTimestamp": 1693432800,
//                     "date": new Date("2023-08-30T22:00:00.000Z"),
//                     "timestamp": 1693432800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 77,
//                     "epochTimestamp": 1693436400,
//                     "date": new Date("2023-08-30T23:00:00.000Z"),
//                     "timestamp": 1693436400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 38,
//                     "epochTimestamp": 1693440000,
//                     "date": new Date("2023-08-31T00:00:00.000Z"),
//                     "timestamp": 1693440000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 43,
//                     "epochTimestamp": 1693443600,
//                     "date": new Date("2023-08-31T01:00:00.000Z"),
//                     "timestamp": 1693443600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 52,
//                     "epochTimestamp": 1693447200,
//                     "date": new Date("2023-08-31T02:00:00.000Z"),
//                     "timestamp": 1693447200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 39,
//                     "epochTimestamp": 1693450800,
//                     "date": new Date("2023-08-31T03:00:00.000Z"),
//                     "timestamp": 1693450800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 41,
//                     "epochTimestamp": 1693454400,
//                     "date": new Date("2023-08-31T04:00:00.000Z"),
//                     "timestamp": 1693454400000
//                 }
//             ],
//             "amount": 1168,
//             "startDate": new Date("2023-08-30T05:00:00.000Z"),
//             "label": "verizon"
//         },
//         {
//             "items": [
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "AT&T Mobility",
//                     "amount": 2,
//                     "epochTimestamp": 1693396800,
//                     "date": new Date("2023-08-30T12:00:00.000Z"),
//                     "timestamp": 1693396800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "AT&T Mobility",
//                     "amount": 1,
//                     "epochTimestamp": 1693400400,
//                     "date": new Date("2023-08-30T13:00:00.000Z"),
//                     "timestamp": 1693400400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "AT&T Mobility",
//                     "amount": 1,
//                     "epochTimestamp": 1693404000,
//                     "date": new Date("2023-08-30T14:00:00.000Z"),
//                     "timestamp": 1693404000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "AT&T Mobility",
//                     "amount": 23,
//                     "epochTimestamp": 1693407600,
//                     "date": new Date("2023-08-30T15:00:00.000Z"),
//                     "timestamp": 1693407600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "AT&T Mobility",
//                     "amount": 122,
//                     "epochTimestamp": 1693411200,
//                     "date": new Date("2023-08-30T16:00:00.000Z"),
//                     "timestamp": 1693411200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "AT&T Mobility",
//                     "amount": 5,
//                     "epochTimestamp": 1693414800,
//                     "date": new Date("2023-08-30T17:00:00.000Z"),
//                     "timestamp": 1693414800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "AT&T Mobility",
//                     "amount": 22,
//                     "epochTimestamp": 1693422000,
//                     "date": new Date("2023-08-30T19:00:00.000Z"),
//                     "timestamp": 1693422000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "AT&T Mobility",
//                     "amount": 26,
//                     "epochTimestamp": 1693425600,
//                     "date": new Date("2023-08-30T20:00:00.000Z"),
//                     "timestamp": 1693425600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "AT&T Mobility",
//                     "amount": 2,
//                     "epochTimestamp": 1693432800,
//                     "date": new Date("2023-08-30T22:00:00.000Z"),
//                     "timestamp": 1693432800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "AT&T Mobility",
//                     "amount": 2,
//                     "epochTimestamp": 1693440000,
//                     "date": new Date("2023-08-31T00:00:00.000Z"),
//                     "timestamp": 1693440000000
//                 }
//             ],
//             "amount": 206,
//             "startDate": new Date("2023-08-30T05:00:00.000Z"),
//             "label": "at&t"
//         },
//         {
//             "items": [
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 4,
//                     "epochTimestamp": 1693458000,
//                     "date": new Date("2023-08-31T05:00:00.000Z"),
//                     "timestamp": 1693458000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 3,
//                     "epochTimestamp": 1693461600,
//                     "date": new Date("2023-08-31T06:00:00.000Z"),
//                     "timestamp": 1693461600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 2,
//                     "epochTimestamp": 1693465200,
//                     "date": new Date("2023-08-31T07:00:00.000Z"),
//                     "timestamp": 1693465200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 5,
//                     "epochTimestamp": 1693468800,
//                     "date": new Date("2023-08-31T08:00:00.000Z"),
//                     "timestamp": 1693468800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 5,
//                     "epochTimestamp": 1693472400,
//                     "date": new Date("2023-08-31T09:00:00.000Z"),
//                     "timestamp": 1693472400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 1,
//                     "epochTimestamp": 1693476000,
//                     "date": new Date("2023-08-31T10:00:00.000Z"),
//                     "timestamp": 1693476000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 6,
//                     "epochTimestamp": 1693483200,
//                     "date": new Date("2023-08-31T12:00:00.000Z"),
//                     "timestamp": 1693483200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 7,
//                     "epochTimestamp": 1693486800,
//                     "date": new Date("2023-08-31T13:00:00.000Z"),
//                     "timestamp": 1693486800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 4,
//                     "epochTimestamp": 1693490400,
//                     "date": new Date("2023-08-31T14:00:00.000Z"),
//                     "timestamp": 1693490400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 9,
//                     "epochTimestamp": 1693494000,
//                     "date": new Date("2023-08-31T15:00:00.000Z"),
//                     "timestamp": 1693494000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 16,
//                     "epochTimestamp": 1693497600,
//                     "date": new Date("2023-08-31T16:00:00.000Z"),
//                     "timestamp": 1693497600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 47,
//                     "epochTimestamp": 1693501200,
//                     "date": new Date("2023-08-31T17:00:00.000Z"),
//                     "timestamp": 1693501200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 40,
//                     "epochTimestamp": 1693504800,
//                     "date": new Date("2023-08-31T18:00:00.000Z"),
//                     "timestamp": 1693504800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 7,
//                     "epochTimestamp": 1693508400,
//                     "date": new Date("2023-08-31T19:00:00.000Z"),
//                     "timestamp": 1693508400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 20,
//                     "epochTimestamp": 1693512000,
//                     "date": new Date("2023-08-31T20:00:00.000Z"),
//                     "timestamp": 1693512000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 9,
//                     "epochTimestamp": 1693515600,
//                     "date": new Date("2023-08-31T21:00:00.000Z"),
//                     "timestamp": 1693515600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 9,
//                     "epochTimestamp": 1693519200,
//                     "date": new Date("2023-08-31T22:00:00.000Z"),
//                     "timestamp": 1693519200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 4,
//                     "epochTimestamp": 1693522800,
//                     "date": new Date("2023-08-31T23:00:00.000Z"),
//                     "timestamp": 1693522800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 6,
//                     "epochTimestamp": 1693526400,
//                     "date": new Date("2023-09-01T00:00:00.000Z"),
//                     "timestamp": 1693526400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 1,
//                     "epochTimestamp": 1693530000,
//                     "date": new Date("2023-09-01T01:00:00.000Z"),
//                     "timestamp": 1693530000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 8,
//                     "epochTimestamp": 1693533600,
//                     "date": new Date("2023-09-01T02:00:00.000Z"),
//                     "timestamp": 1693533600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 7,
//                     "epochTimestamp": 1693537200,
//                     "date": new Date("2023-09-01T03:00:00.000Z"),
//                     "timestamp": 1693537200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 2,
//                     "epochTimestamp": 1693540800,
//                     "date": new Date("2023-09-01T04:00:00.000Z"),
//                     "timestamp": 1693540800000
//                 }
//             ],
//             "amount": 222,
//             "startDate": new Date("2023-08-31T05:00:00.000Z"),
//             "label": "other"
//         },
//         {
//             "items": [
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 551,
//                     "epochTimestamp": 1693458000,
//                     "date": new Date("2023-08-31T05:00:00.000Z"),
//                     "timestamp": 1693458000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 497,
//                     "epochTimestamp": 1693461600,
//                     "date": new Date("2023-08-31T06:00:00.000Z"),
//                     "timestamp": 1693461600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 347,
//                     "epochTimestamp": 1693465200,
//                     "date": new Date("2023-08-31T07:00:00.000Z"),
//                     "timestamp": 1693465200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 347,
//                     "epochTimestamp": 1693468800,
//                     "date": new Date("2023-08-31T08:00:00.000Z"),
//                     "timestamp": 1693468800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 18,
//                     "epochTimestamp": 1693472400,
//                     "date": new Date("2023-08-31T09:00:00.000Z"),
//                     "timestamp": 1693472400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 64,
//                     "epochTimestamp": 1693476000,
//                     "date": new Date("2023-08-31T10:00:00.000Z"),
//                     "timestamp": 1693476000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 188,
//                     "epochTimestamp": 1693479600,
//                     "date": new Date("2023-08-31T11:00:00.000Z"),
//                     "timestamp": 1693479600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 160,
//                     "epochTimestamp": 1693483200,
//                     "date": new Date("2023-08-31T12:00:00.000Z"),
//                     "timestamp": 1693483200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 278,
//                     "epochTimestamp": 1693486800,
//                     "date": new Date("2023-08-31T13:00:00.000Z"),
//                     "timestamp": 1693486800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 464,
//                     "epochTimestamp": 1693490400,
//                     "date": new Date("2023-08-31T14:00:00.000Z"),
//                     "timestamp": 1693490400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 182,
//                     "epochTimestamp": 1693494000,
//                     "date": new Date("2023-08-31T15:00:00.000Z"),
//                     "timestamp": 1693494000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 382,
//                     "epochTimestamp": 1693497600,
//                     "date": new Date("2023-08-31T16:00:00.000Z"),
//                     "timestamp": 1693497600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 341,
//                     "epochTimestamp": 1693501200,
//                     "date": new Date("2023-08-31T17:00:00.000Z"),
//                     "timestamp": 1693501200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 761,
//                     "epochTimestamp": 1693504800,
//                     "date": new Date("2023-08-31T18:00:00.000Z"),
//                     "timestamp": 1693504800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 609,
//                     "epochTimestamp": 1693508400,
//                     "date": new Date("2023-08-31T19:00:00.000Z"),
//                     "timestamp": 1693508400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 315,
//                     "epochTimestamp": 1693512000,
//                     "date": new Date("2023-08-31T20:00:00.000Z"),
//                     "timestamp": 1693512000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 355,
//                     "epochTimestamp": 1693515600,
//                     "date": new Date("2023-08-31T21:00:00.000Z"),
//                     "timestamp": 1693515600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 613,
//                     "epochTimestamp": 1693519200,
//                     "date": new Date("2023-08-31T22:00:00.000Z"),
//                     "timestamp": 1693519200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 487,
//                     "epochTimestamp": 1693522800,
//                     "date": new Date("2023-08-31T23:00:00.000Z"),
//                     "timestamp": 1693522800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 562,
//                     "epochTimestamp": 1693526400,
//                     "date": new Date("2023-09-01T00:00:00.000Z"),
//                     "timestamp": 1693526400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 635,
//                     "epochTimestamp": 1693530000,
//                     "date": new Date("2023-09-01T01:00:00.000Z"),
//                     "timestamp": 1693530000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 674,
//                     "epochTimestamp": 1693533600,
//                     "date": new Date("2023-09-01T02:00:00.000Z"),
//                     "timestamp": 1693533600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 479,
//                     "epochTimestamp": 1693537200,
//                     "date": new Date("2023-09-01T03:00:00.000Z"),
//                     "timestamp": 1693537200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 513,
//                     "epochTimestamp": 1693540800,
//                     "date": new Date("2023-09-01T04:00:00.000Z"),
//                     "timestamp": 1693540800000
//                 }
//             ],
//             "amount": 9822,
//             "startDate": new Date("2023-08-31T05:00:00.000Z"),
//             "label": "t-mobile"
//         },
//         {
//             "items": [
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 21,
//                     "epochTimestamp": 1693458000,
//                     "date": new Date("2023-08-31T05:00:00.000Z"),
//                     "timestamp": 1693458000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 23,
//                     "epochTimestamp": 1693461600,
//                     "date": new Date("2023-08-31T06:00:00.000Z"),
//                     "timestamp": 1693461600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 38,
//                     "epochTimestamp": 1693465200,
//                     "date": new Date("2023-08-31T07:00:00.000Z"),
//                     "timestamp": 1693465200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 38,
//                     "epochTimestamp": 1693468800,
//                     "date": new Date("2023-08-31T08:00:00.000Z"),
//                     "timestamp": 1693468800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 31,
//                     "epochTimestamp": 1693472400,
//                     "date": new Date("2023-08-31T09:00:00.000Z"),
//                     "timestamp": 1693472400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 45,
//                     "epochTimestamp": 1693476000,
//                     "date": new Date("2023-08-31T10:00:00.000Z"),
//                     "timestamp": 1693476000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 46,
//                     "epochTimestamp": 1693479600,
//                     "date": new Date("2023-08-31T11:00:00.000Z"),
//                     "timestamp": 1693479600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 155,
//                     "epochTimestamp": 1693483200,
//                     "date": new Date("2023-08-31T12:00:00.000Z"),
//                     "timestamp": 1693483200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 85,
//                     "epochTimestamp": 1693486800,
//                     "date": new Date("2023-08-31T13:00:00.000Z"),
//                     "timestamp": 1693486800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 127,
//                     "epochTimestamp": 1693490400,
//                     "date": new Date("2023-08-31T14:00:00.000Z"),
//                     "timestamp": 1693490400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 80,
//                     "epochTimestamp": 1693494000,
//                     "date": new Date("2023-08-31T15:00:00.000Z"),
//                     "timestamp": 1693494000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 71,
//                     "epochTimestamp": 1693497600,
//                     "date": new Date("2023-08-31T16:00:00.000Z"),
//                     "timestamp": 1693497600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 49,
//                     "epochTimestamp": 1693501200,
//                     "date": new Date("2023-08-31T17:00:00.000Z"),
//                     "timestamp": 1693501200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 21,
//                     "epochTimestamp": 1693504800,
//                     "date": new Date("2023-08-31T18:00:00.000Z"),
//                     "timestamp": 1693504800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 49,
//                     "epochTimestamp": 1693508400,
//                     "date": new Date("2023-08-31T19:00:00.000Z"),
//                     "timestamp": 1693508400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 109,
//                     "epochTimestamp": 1693512000,
//                     "date": new Date("2023-08-31T20:00:00.000Z"),
//                     "timestamp": 1693512000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 164,
//                     "epochTimestamp": 1693515600,
//                     "date": new Date("2023-08-31T21:00:00.000Z"),
//                     "timestamp": 1693515600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 68,
//                     "epochTimestamp": 1693519200,
//                     "date": new Date("2023-08-31T22:00:00.000Z"),
//                     "timestamp": 1693519200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 47,
//                     "epochTimestamp": 1693522800,
//                     "date": new Date("2023-08-31T23:00:00.000Z"),
//                     "timestamp": 1693522800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 70,
//                     "epochTimestamp": 1693526400,
//                     "date": new Date("2023-09-01T00:00:00.000Z"),
//                     "timestamp": 1693526400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 104,
//                     "epochTimestamp": 1693530000,
//                     "date": new Date("2023-09-01T01:00:00.000Z"),
//                     "timestamp": 1693530000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 58,
//                     "epochTimestamp": 1693533600,
//                     "date": new Date("2023-09-01T02:00:00.000Z"),
//                     "timestamp": 1693533600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 114,
//                     "epochTimestamp": 1693537200,
//                     "date": new Date("2023-09-01T03:00:00.000Z"),
//                     "timestamp": 1693537200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 97,
//                     "epochTimestamp": 1693540800,
//                     "date": new Date("2023-09-01T04:00:00.000Z"),
//                     "timestamp": 1693540800000
//                 }
//             ],
//             "amount": 1710,
//             "startDate": new Date("2023-08-31T05:00:00.000Z"),
//             "label": "verizon"
//         },
//         {
//             "items": [
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 20,
//                     "epochTimestamp": 1693544400,
//                     "date": new Date("2023-09-01T05:00:00.000Z"),
//                     "timestamp": 1693544400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 2,
//                     "epochTimestamp": 1693548000,
//                     "date": new Date("2023-09-01T06:00:00.000Z"),
//                     "timestamp": 1693548000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 20,
//                     "epochTimestamp": 1693551600,
//                     "date": new Date("2023-09-01T07:00:00.000Z"),
//                     "timestamp": 1693551600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 52,
//                     "epochTimestamp": 1693555200,
//                     "date": new Date("2023-09-01T08:00:00.000Z"),
//                     "timestamp": 1693555200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 39,
//                     "epochTimestamp": 1693558800,
//                     "date": new Date("2023-09-01T09:00:00.000Z"),
//                     "timestamp": 1693558800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 51,
//                     "epochTimestamp": 1693562400,
//                     "date": new Date("2023-09-01T10:00:00.000Z"),
//                     "timestamp": 1693562400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 46,
//                     "epochTimestamp": 1693566000,
//                     "date": new Date("2023-09-01T11:00:00.000Z"),
//                     "timestamp": 1693566000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 2,
//                     "epochTimestamp": 1693569600,
//                     "date": new Date("2023-09-01T12:00:00.000Z"),
//                     "timestamp": 1693569600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 54,
//                     "epochTimestamp": 1693573200,
//                     "date": new Date("2023-09-01T13:00:00.000Z"),
//                     "timestamp": 1693573200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 110,
//                     "epochTimestamp": 1693576800,
//                     "date": new Date("2023-09-01T14:00:00.000Z"),
//                     "timestamp": 1693576800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 22,
//                     "epochTimestamp": 1693580400,
//                     "date": new Date("2023-09-01T15:00:00.000Z"),
//                     "timestamp": 1693580400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 80,
//                     "epochTimestamp": 1693584000,
//                     "date": new Date("2023-09-01T16:00:00.000Z"),
//                     "timestamp": 1693584000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 41,
//                     "epochTimestamp": 1693587600,
//                     "date": new Date("2023-09-01T17:00:00.000Z"),
//                     "timestamp": 1693587600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 40,
//                     "epochTimestamp": 1693591200,
//                     "date": new Date("2023-09-01T18:00:00.000Z"),
//                     "timestamp": 1693591200000
//                 }
//             ],
//             "amount": 579,
//             "startDate": new Date("2023-09-01T05:00:00.000Z"),
//             "label": "verizon"
//         },
//         {
//             "items": [
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "AT&T Mobility",
//                     "amount": 2,
//                     "epochTimestamp": 1693544400,
//                     "date": new Date("2023-09-01T05:00:00.000Z"),
//                     "timestamp": 1693544400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "AT&T Mobility",
//                     "amount": 2,
//                     "epochTimestamp": 1693551600,
//                     "date": new Date("2023-09-01T07:00:00.000Z"),
//                     "timestamp": 1693551600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "AT&T Mobility",
//                     "amount": 10,
//                     "epochTimestamp": 1693580400,
//                     "date": new Date("2023-09-01T15:00:00.000Z"),
//                     "timestamp": 1693580400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "AT&T Mobility",
//                     "amount": 171,
//                     "epochTimestamp": 1693584000,
//                     "date": new Date("2023-09-01T16:00:00.000Z"),
//                     "timestamp": 1693584000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "AT&T Mobility",
//                     "amount": 50,
//                     "epochTimestamp": 1693591200,
//                     "date": new Date("2023-09-01T18:00:00.000Z"),
//                     "timestamp": 1693591200000
//                 }
//             ],
//             "amount": 235,
//             "startDate": new Date("2023-09-01T05:00:00.000Z"),
//             "label": "at&t"
//         },
//         {
//             "items": [
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 2,
//                     "epochTimestamp": 1693544400,
//                     "date": new Date("2023-09-01T05:00:00.000Z"),
//                     "timestamp": 1693544400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 3,
//                     "epochTimestamp": 1693548000,
//                     "date": new Date("2023-09-01T06:00:00.000Z"),
//                     "timestamp": 1693548000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 2,
//                     "epochTimestamp": 1693551600,
//                     "date": new Date("2023-09-01T07:00:00.000Z"),
//                     "timestamp": 1693551600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 1,
//                     "epochTimestamp": 1693555200,
//                     "date": new Date("2023-09-01T08:00:00.000Z"),
//                     "timestamp": 1693555200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 4,
//                     "epochTimestamp": 1693558800,
//                     "date": new Date("2023-09-01T09:00:00.000Z"),
//                     "timestamp": 1693558800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 6,
//                     "epochTimestamp": 1693562400,
//                     "date": new Date("2023-09-01T10:00:00.000Z"),
//                     "timestamp": 1693562400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 4,
//                     "epochTimestamp": 1693566000,
//                     "date": new Date("2023-09-01T11:00:00.000Z"),
//                     "timestamp": 1693566000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 13,
//                     "epochTimestamp": 1693569600,
//                     "date": new Date("2023-09-01T12:00:00.000Z"),
//                     "timestamp": 1693569600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 12,
//                     "epochTimestamp": 1693573200,
//                     "date": new Date("2023-09-01T13:00:00.000Z"),
//                     "timestamp": 1693573200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 8,
//                     "epochTimestamp": 1693576800,
//                     "date": new Date("2023-09-01T14:00:00.000Z"),
//                     "timestamp": 1693576800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 11,
//                     "epochTimestamp": 1693580400,
//                     "date": new Date("2023-09-01T15:00:00.000Z"),
//                     "timestamp": 1693580400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 3,
//                     "epochTimestamp": 1693584000,
//                     "date": new Date("2023-09-01T16:00:00.000Z"),
//                     "timestamp": 1693584000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 5,
//                     "epochTimestamp": 1693587600,
//                     "date": new Date("2023-09-01T17:00:00.000Z"),
//                     "timestamp": 1693587600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 2,
//                     "epochTimestamp": 1693591200,
//                     "date": new Date("2023-09-01T18:00:00.000Z"),
//                     "timestamp": 1693591200000
//                 }
//             ],
//             "amount": 76,
//             "startDate": new Date("2023-09-01T05:00:00.000Z"),
//             "label": "other"
//         }
//     ],
//     "loading": false,
//     "enabledProviders": {
//         "at&t": true,
//         "verizon": true,
//         "t-mobile": true,
//         "other": true
//     },
//     "dateMode": "week",
//     "filteredActivityReport": [
//         {
//             "label": "t-mobile",
//             "items": [
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 160,
//                     "epochTimestamp": 1693594230,
//                     "date": new Date("2023-09-01T18:50:30.000Z"),
//                     "timestamp": 1693594230000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 181,
//                     "epochTimestamp": 1693112400,
//                     "date": new Date("2023-08-27T05:00:00.000Z"),
//                     "timestamp": 1693112400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 93,
//                     "epochTimestamp": 1693116000,
//                     "date": new Date("2023-08-27T06:00:00.000Z"),
//                     "timestamp": 1693116000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 80,
//                     "epochTimestamp": 1693119600,
//                     "date": new Date("2023-08-27T07:00:00.000Z"),
//                     "timestamp": 1693119600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 65,
//                     "epochTimestamp": 1693123200,
//                     "date": new Date("2023-08-27T08:00:00.000Z"),
//                     "timestamp": 1693123200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 86,
//                     "epochTimestamp": 1693126800,
//                     "date": new Date("2023-08-27T09:00:00.000Z"),
//                     "timestamp": 1693126800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 120,
//                     "epochTimestamp": 1693130400,
//                     "date": new Date("2023-08-27T10:00:00.000Z"),
//                     "timestamp": 1693130400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 132,
//                     "epochTimestamp": 1693134000,
//                     "date": new Date("2023-08-27T11:00:00.000Z"),
//                     "timestamp": 1693134000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 142,
//                     "epochTimestamp": 1693137600,
//                     "date": new Date("2023-08-27T12:00:00.000Z"),
//                     "timestamp": 1693137600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 136,
//                     "epochTimestamp": 1693141200,
//                     "date": new Date("2023-08-27T13:00:00.000Z"),
//                     "timestamp": 1693141200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 262,
//                     "epochTimestamp": 1693144800,
//                     "date": new Date("2023-08-27T14:00:00.000Z"),
//                     "timestamp": 1693144800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 92,
//                     "epochTimestamp": 1693148400,
//                     "date": new Date("2023-08-27T15:00:00.000Z"),
//                     "timestamp": 1693148400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 77,
//                     "epochTimestamp": 1693152000,
//                     "date": new Date("2023-08-27T16:00:00.000Z"),
//                     "timestamp": 1693152000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 240,
//                     "epochTimestamp": 1693155600,
//                     "date": new Date("2023-08-27T17:00:00.000Z"),
//                     "timestamp": 1693155600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 263,
//                     "epochTimestamp": 1693159200,
//                     "date": new Date("2023-08-27T18:00:00.000Z"),
//                     "timestamp": 1693159200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 204,
//                     "epochTimestamp": 1693162800,
//                     "date": new Date("2023-08-27T19:00:00.000Z"),
//                     "timestamp": 1693162800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 215,
//                     "epochTimestamp": 1693166400,
//                     "date": new Date("2023-08-27T20:00:00.000Z"),
//                     "timestamp": 1693166400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 145,
//                     "epochTimestamp": 1693170000,
//                     "date": new Date("2023-08-27T21:00:00.000Z"),
//                     "timestamp": 1693170000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 284,
//                     "epochTimestamp": 1693173600,
//                     "date": new Date("2023-08-27T22:00:00.000Z"),
//                     "timestamp": 1693173600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 203,
//                     "epochTimestamp": 1693177200,
//                     "date": new Date("2023-08-27T23:00:00.000Z"),
//                     "timestamp": 1693177200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 427,
//                     "epochTimestamp": 1693180800,
//                     "date": new Date("2023-08-28T00:00:00.000Z"),
//                     "timestamp": 1693180800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 271,
//                     "epochTimestamp": 1693184400,
//                     "date": new Date("2023-08-28T01:00:00.000Z"),
//                     "timestamp": 1693184400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 87,
//                     "epochTimestamp": 1693188000,
//                     "date": new Date("2023-08-28T02:00:00.000Z"),
//                     "timestamp": 1693188000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 201,
//                     "epochTimestamp": 1693191600,
//                     "date": new Date("2023-08-28T03:00:00.000Z"),
//                     "timestamp": 1693191600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 134,
//                     "epochTimestamp": 1693195200,
//                     "date": new Date("2023-08-28T04:00:00.000Z"),
//                     "timestamp": 1693195200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 182,
//                     "epochTimestamp": 1693198800,
//                     "date": new Date("2023-08-28T05:00:00.000Z"),
//                     "timestamp": 1693198800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 282,
//                     "epochTimestamp": 1693202400,
//                     "date": new Date("2023-08-28T06:00:00.000Z"),
//                     "timestamp": 1693202400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 94,
//                     "epochTimestamp": 1693206000,
//                     "date": new Date("2023-08-28T07:00:00.000Z"),
//                     "timestamp": 1693206000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 72,
//                     "epochTimestamp": 1693209600,
//                     "date": new Date("2023-08-28T08:00:00.000Z"),
//                     "timestamp": 1693209600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 84,
//                     "epochTimestamp": 1693213200,
//                     "date": new Date("2023-08-28T09:00:00.000Z"),
//                     "timestamp": 1693213200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 211,
//                     "epochTimestamp": 1693216800,
//                     "date": new Date("2023-08-28T10:00:00.000Z"),
//                     "timestamp": 1693216800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 216,
//                     "epochTimestamp": 1693220400,
//                     "date": new Date("2023-08-28T11:00:00.000Z"),
//                     "timestamp": 1693220400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 141,
//                     "epochTimestamp": 1693224000,
//                     "date": new Date("2023-08-28T12:00:00.000Z"),
//                     "timestamp": 1693224000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 117,
//                     "epochTimestamp": 1693227600,
//                     "date": new Date("2023-08-28T13:00:00.000Z"),
//                     "timestamp": 1693227600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 645,
//                     "epochTimestamp": 1693231200,
//                     "date": new Date("2023-08-28T14:00:00.000Z"),
//                     "timestamp": 1693231200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 227,
//                     "epochTimestamp": 1693234800,
//                     "date": new Date("2023-08-28T15:00:00.000Z"),
//                     "timestamp": 1693234800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 316,
//                     "epochTimestamp": 1693238400,
//                     "date": new Date("2023-08-28T16:00:00.000Z"),
//                     "timestamp": 1693238400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 65,
//                     "epochTimestamp": 1693242000,
//                     "date": new Date("2023-08-28T17:00:00.000Z"),
//                     "timestamp": 1693242000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 215,
//                     "epochTimestamp": 1693245600,
//                     "date": new Date("2023-08-28T18:00:00.000Z"),
//                     "timestamp": 1693245600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 158,
//                     "epochTimestamp": 1693249200,
//                     "date": new Date("2023-08-28T19:00:00.000Z"),
//                     "timestamp": 1693249200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 226,
//                     "epochTimestamp": 1693252800,
//                     "date": new Date("2023-08-28T20:00:00.000Z"),
//                     "timestamp": 1693252800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 424,
//                     "epochTimestamp": 1693256400,
//                     "date": new Date("2023-08-28T21:00:00.000Z"),
//                     "timestamp": 1693256400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 322,
//                     "epochTimestamp": 1693260000,
//                     "date": new Date("2023-08-28T22:00:00.000Z"),
//                     "timestamp": 1693260000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 326,
//                     "epochTimestamp": 1693263600,
//                     "date": new Date("2023-08-28T23:00:00.000Z"),
//                     "timestamp": 1693263600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 255,
//                     "epochTimestamp": 1693267200,
//                     "date": new Date("2023-08-29T00:00:00.000Z"),
//                     "timestamp": 1693267200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 154,
//                     "epochTimestamp": 1693270800,
//                     "date": new Date("2023-08-29T01:00:00.000Z"),
//                     "timestamp": 1693270800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 137,
//                     "epochTimestamp": 1693274400,
//                     "date": new Date("2023-08-29T02:00:00.000Z"),
//                     "timestamp": 1693274400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 304,
//                     "epochTimestamp": 1693278000,
//                     "date": new Date("2023-08-29T03:00:00.000Z"),
//                     "timestamp": 1693278000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 109,
//                     "epochTimestamp": 1693281600,
//                     "date": new Date("2023-08-29T04:00:00.000Z"),
//                     "timestamp": 1693281600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 226,
//                     "epochTimestamp": 1693285200,
//                     "date": new Date("2023-08-29T05:00:00.000Z"),
//                     "timestamp": 1693285200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 156,
//                     "epochTimestamp": 1693288800,
//                     "date": new Date("2023-08-29T06:00:00.000Z"),
//                     "timestamp": 1693288800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 113,
//                     "epochTimestamp": 1693292400,
//                     "date": new Date("2023-08-29T07:00:00.000Z"),
//                     "timestamp": 1693292400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 113,
//                     "epochTimestamp": 1693296000,
//                     "date": new Date("2023-08-29T08:00:00.000Z"),
//                     "timestamp": 1693296000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 149,
//                     "epochTimestamp": 1693299600,
//                     "date": new Date("2023-08-29T09:00:00.000Z"),
//                     "timestamp": 1693299600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 26,
//                     "epochTimestamp": 1693303200,
//                     "date": new Date("2023-08-29T10:00:00.000Z"),
//                     "timestamp": 1693303200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 11,
//                     "epochTimestamp": 1693306800,
//                     "date": new Date("2023-08-29T11:00:00.000Z"),
//                     "timestamp": 1693306800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 31,
//                     "epochTimestamp": 1693310400,
//                     "date": new Date("2023-08-29T12:00:00.000Z"),
//                     "timestamp": 1693310400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 80,
//                     "epochTimestamp": 1693314000,
//                     "date": new Date("2023-08-29T13:00:00.000Z"),
//                     "timestamp": 1693314000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 134,
//                     "epochTimestamp": 1693317600,
//                     "date": new Date("2023-08-29T14:00:00.000Z"),
//                     "timestamp": 1693317600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 347,
//                     "epochTimestamp": 1693321200,
//                     "date": new Date("2023-08-29T15:00:00.000Z"),
//                     "timestamp": 1693321200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 176,
//                     "epochTimestamp": 1693324800,
//                     "date": new Date("2023-08-29T16:00:00.000Z"),
//                     "timestamp": 1693324800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 151,
//                     "epochTimestamp": 1693328400,
//                     "date": new Date("2023-08-29T17:00:00.000Z"),
//                     "timestamp": 1693328400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 239,
//                     "epochTimestamp": 1693332000,
//                     "date": new Date("2023-08-29T18:00:00.000Z"),
//                     "timestamp": 1693332000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 169,
//                     "epochTimestamp": 1693335600,
//                     "date": new Date("2023-08-29T19:00:00.000Z"),
//                     "timestamp": 1693335600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 766,
//                     "epochTimestamp": 1693339200,
//                     "date": new Date("2023-08-29T20:00:00.000Z"),
//                     "timestamp": 1693339200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 373,
//                     "epochTimestamp": 1693342800,
//                     "date": new Date("2023-08-29T21:00:00.000Z"),
//                     "timestamp": 1693342800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 298,
//                     "epochTimestamp": 1693346400,
//                     "date": new Date("2023-08-29T22:00:00.000Z"),
//                     "timestamp": 1693346400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 280,
//                     "epochTimestamp": 1693350000,
//                     "date": new Date("2023-08-29T23:00:00.000Z"),
//                     "timestamp": 1693350000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 314,
//                     "epochTimestamp": 1693353600,
//                     "date": new Date("2023-08-30T00:00:00.000Z"),
//                     "timestamp": 1693353600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 412,
//                     "epochTimestamp": 1693357200,
//                     "date": new Date("2023-08-30T01:00:00.000Z"),
//                     "timestamp": 1693357200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 186,
//                     "epochTimestamp": 1693360800,
//                     "date": new Date("2023-08-30T02:00:00.000Z"),
//                     "timestamp": 1693360800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 239,
//                     "epochTimestamp": 1693364400,
//                     "date": new Date("2023-08-30T03:00:00.000Z"),
//                     "timestamp": 1693364400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 334,
//                     "epochTimestamp": 1693368000,
//                     "date": new Date("2023-08-30T04:00:00.000Z"),
//                     "timestamp": 1693368000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 347,
//                     "epochTimestamp": 1693371600,
//                     "date": new Date("2023-08-30T05:00:00.000Z"),
//                     "timestamp": 1693371600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 387,
//                     "epochTimestamp": 1693375200,
//                     "date": new Date("2023-08-30T06:00:00.000Z"),
//                     "timestamp": 1693375200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 442,
//                     "epochTimestamp": 1693378800,
//                     "date": new Date("2023-08-30T07:00:00.000Z"),
//                     "timestamp": 1693378800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 409,
//                     "epochTimestamp": 1693382400,
//                     "date": new Date("2023-08-30T08:00:00.000Z"),
//                     "timestamp": 1693382400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 384,
//                     "epochTimestamp": 1693386000,
//                     "date": new Date("2023-08-30T09:00:00.000Z"),
//                     "timestamp": 1693386000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 322,
//                     "epochTimestamp": 1693389600,
//                     "date": new Date("2023-08-30T10:00:00.000Z"),
//                     "timestamp": 1693389600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 297,
//                     "epochTimestamp": 1693393200,
//                     "date": new Date("2023-08-30T11:00:00.000Z"),
//                     "timestamp": 1693393200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 570,
//                     "epochTimestamp": 1693396800,
//                     "date": new Date("2023-08-30T12:00:00.000Z"),
//                     "timestamp": 1693396800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 358,
//                     "epochTimestamp": 1693400400,
//                     "date": new Date("2023-08-30T13:00:00.000Z"),
//                     "timestamp": 1693400400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 487,
//                     "epochTimestamp": 1693404000,
//                     "date": new Date("2023-08-30T14:00:00.000Z"),
//                     "timestamp": 1693404000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 578,
//                     "epochTimestamp": 1693407600,
//                     "date": new Date("2023-08-30T15:00:00.000Z"),
//                     "timestamp": 1693407600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 112,
//                     "epochTimestamp": 1693411200,
//                     "date": new Date("2023-08-30T16:00:00.000Z"),
//                     "timestamp": 1693411200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 228,
//                     "epochTimestamp": 1693414800,
//                     "date": new Date("2023-08-30T17:00:00.000Z"),
//                     "timestamp": 1693414800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 511,
//                     "epochTimestamp": 1693418400,
//                     "date": new Date("2023-08-30T18:00:00.000Z"),
//                     "timestamp": 1693418400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 238,
//                     "epochTimestamp": 1693422000,
//                     "date": new Date("2023-08-30T19:00:00.000Z"),
//                     "timestamp": 1693422000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 524,
//                     "epochTimestamp": 1693425600,
//                     "date": new Date("2023-08-30T20:00:00.000Z"),
//                     "timestamp": 1693425600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 852,
//                     "epochTimestamp": 1693429200,
//                     "date": new Date("2023-08-30T21:00:00.000Z"),
//                     "timestamp": 1693429200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 512,
//                     "epochTimestamp": 1693432800,
//                     "date": new Date("2023-08-30T22:00:00.000Z"),
//                     "timestamp": 1693432800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 328,
//                     "epochTimestamp": 1693436400,
//                     "date": new Date("2023-08-30T23:00:00.000Z"),
//                     "timestamp": 1693436400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 268,
//                     "epochTimestamp": 1693440000,
//                     "date": new Date("2023-08-31T00:00:00.000Z"),
//                     "timestamp": 1693440000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 331,
//                     "epochTimestamp": 1693443600,
//                     "date": new Date("2023-08-31T01:00:00.000Z"),
//                     "timestamp": 1693443600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 411,
//                     "epochTimestamp": 1693447200,
//                     "date": new Date("2023-08-31T02:00:00.000Z"),
//                     "timestamp": 1693447200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 524,
//                     "epochTimestamp": 1693450800,
//                     "date": new Date("2023-08-31T03:00:00.000Z"),
//                     "timestamp": 1693450800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 383,
//                     "epochTimestamp": 1693454400,
//                     "date": new Date("2023-08-31T04:00:00.000Z"),
//                     "timestamp": 1693454400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 551,
//                     "epochTimestamp": 1693458000,
//                     "date": new Date("2023-08-31T05:00:00.000Z"),
//                     "timestamp": 1693458000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 497,
//                     "epochTimestamp": 1693461600,
//                     "date": new Date("2023-08-31T06:00:00.000Z"),
//                     "timestamp": 1693461600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 347,
//                     "epochTimestamp": 1693465200,
//                     "date": new Date("2023-08-31T07:00:00.000Z"),
//                     "timestamp": 1693465200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 347,
//                     "epochTimestamp": 1693468800,
//                     "date": new Date("2023-08-31T08:00:00.000Z"),
//                     "timestamp": 1693468800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 18,
//                     "epochTimestamp": 1693472400,
//                     "date": new Date("2023-08-31T09:00:00.000Z"),
//                     "timestamp": 1693472400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 64,
//                     "epochTimestamp": 1693476000,
//                     "date": new Date("2023-08-31T10:00:00.000Z"),
//                     "timestamp": 1693476000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 188,
//                     "epochTimestamp": 1693479600,
//                     "date": new Date("2023-08-31T11:00:00.000Z"),
//                     "timestamp": 1693479600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 160,
//                     "epochTimestamp": 1693483200,
//                     "date": new Date("2023-08-31T12:00:00.000Z"),
//                     "timestamp": 1693483200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 278,
//                     "epochTimestamp": 1693486800,
//                     "date": new Date("2023-08-31T13:00:00.000Z"),
//                     "timestamp": 1693486800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 464,
//                     "epochTimestamp": 1693490400,
//                     "date": new Date("2023-08-31T14:00:00.000Z"),
//                     "timestamp": 1693490400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 182,
//                     "epochTimestamp": 1693494000,
//                     "date": new Date("2023-08-31T15:00:00.000Z"),
//                     "timestamp": 1693494000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 382,
//                     "epochTimestamp": 1693497600,
//                     "date": new Date("2023-08-31T16:00:00.000Z"),
//                     "timestamp": 1693497600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 341,
//                     "epochTimestamp": 1693501200,
//                     "date": new Date("2023-08-31T17:00:00.000Z"),
//                     "timestamp": 1693501200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 761,
//                     "epochTimestamp": 1693504800,
//                     "date": new Date("2023-08-31T18:00:00.000Z"),
//                     "timestamp": 1693504800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 609,
//                     "epochTimestamp": 1693508400,
//                     "date": new Date("2023-08-31T19:00:00.000Z"),
//                     "timestamp": 1693508400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 315,
//                     "epochTimestamp": 1693512000,
//                     "date": new Date("2023-08-31T20:00:00.000Z"),
//                     "timestamp": 1693512000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 355,
//                     "epochTimestamp": 1693515600,
//                     "date": new Date("2023-08-31T21:00:00.000Z"),
//                     "timestamp": 1693515600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 613,
//                     "epochTimestamp": 1693519200,
//                     "date": new Date("2023-08-31T22:00:00.000Z"),
//                     "timestamp": 1693519200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 487,
//                     "epochTimestamp": 1693522800,
//                     "date": new Date("2023-08-31T23:00:00.000Z"),
//                     "timestamp": 1693522800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 562,
//                     "epochTimestamp": 1693526400,
//                     "date": new Date("2023-09-01T00:00:00.000Z"),
//                     "timestamp": 1693526400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 635,
//                     "epochTimestamp": 1693530000,
//                     "date": new Date("2023-09-01T01:00:00.000Z"),
//                     "timestamp": 1693530000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 674,
//                     "epochTimestamp": 1693533600,
//                     "date": new Date("2023-09-01T02:00:00.000Z"),
//                     "timestamp": 1693533600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 479,
//                     "epochTimestamp": 1693537200,
//                     "date": new Date("2023-09-01T03:00:00.000Z"),
//                     "timestamp": 1693537200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 513,
//                     "epochTimestamp": 1693540800,
//                     "date": new Date("2023-09-01T04:00:00.000Z"),
//                     "timestamp": 1693540800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 380,
//                     "epochTimestamp": 1693544400,
//                     "date": new Date("2023-09-01T05:00:00.000Z"),
//                     "timestamp": 1693544400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 475,
//                     "epochTimestamp": 1693548000,
//                     "date": new Date("2023-09-01T06:00:00.000Z"),
//                     "timestamp": 1693548000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 436,
//                     "epochTimestamp": 1693551600,
//                     "date": new Date("2023-09-01T07:00:00.000Z"),
//                     "timestamp": 1693551600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 215,
//                     "epochTimestamp": 1693555200,
//                     "date": new Date("2023-09-01T08:00:00.000Z"),
//                     "timestamp": 1693555200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 151,
//                     "epochTimestamp": 1693558800,
//                     "date": new Date("2023-09-01T09:00:00.000Z"),
//                     "timestamp": 1693558800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 223,
//                     "epochTimestamp": 1693562400,
//                     "date": new Date("2023-09-01T10:00:00.000Z"),
//                     "timestamp": 1693562400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 238,
//                     "epochTimestamp": 1693566000,
//                     "date": new Date("2023-09-01T11:00:00.000Z"),
//                     "timestamp": 1693566000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 254,
//                     "epochTimestamp": 1693569600,
//                     "date": new Date("2023-09-01T12:00:00.000Z"),
//                     "timestamp": 1693569600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 270,
//                     "epochTimestamp": 1693573200,
//                     "date": new Date("2023-09-01T13:00:00.000Z"),
//                     "timestamp": 1693573200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 986,
//                     "epochTimestamp": 1693576800,
//                     "date": new Date("2023-09-01T14:00:00.000Z"),
//                     "timestamp": 1693576800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 891,
//                     "epochTimestamp": 1693580400,
//                     "date": new Date("2023-09-01T15:00:00.000Z"),
//                     "timestamp": 1693580400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 170,
//                     "epochTimestamp": 1693584000,
//                     "date": new Date("2023-09-01T16:00:00.000Z"),
//                     "timestamp": 1693584000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "T-Mobile USA",
//                     "amount": 290,
//                     "epochTimestamp": 1693587600,
//                     "date": new Date("2023-09-01T17:00:00.000Z"),
//                     "timestamp": 1693587600000
//                 }
//             ]
//         },
//         {
//             "label": "other",
//             "items": [
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 1,
//                     "epochTimestamp": 1693112400,
//                     "date": new Date("2023-08-27T05:00:00.000Z"),
//                     "timestamp": 1693112400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 3,
//                     "epochTimestamp": 1693116000,
//                     "date": new Date("2023-08-27T06:00:00.000Z"),
//                     "timestamp": 1693116000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 1,
//                     "epochTimestamp": 1693119600,
//                     "date": new Date("2023-08-27T07:00:00.000Z"),
//                     "timestamp": 1693119600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 2,
//                     "epochTimestamp": 1693123200,
//                     "date": new Date("2023-08-27T08:00:00.000Z"),
//                     "timestamp": 1693123200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 14,
//                     "epochTimestamp": 1693137600,
//                     "date": new Date("2023-08-27T12:00:00.000Z"),
//                     "timestamp": 1693137600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 14,
//                     "epochTimestamp": 1693141200,
//                     "date": new Date("2023-08-27T13:00:00.000Z"),
//                     "timestamp": 1693141200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 6,
//                     "epochTimestamp": 1693144800,
//                     "date": new Date("2023-08-27T14:00:00.000Z"),
//                     "timestamp": 1693144800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 4,
//                     "epochTimestamp": 1693148400,
//                     "date": new Date("2023-08-27T15:00:00.000Z"),
//                     "timestamp": 1693148400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 2,
//                     "epochTimestamp": 1693152000,
//                     "date": new Date("2023-08-27T16:00:00.000Z"),
//                     "timestamp": 1693152000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 3,
//                     "epochTimestamp": 1693159200,
//                     "date": new Date("2023-08-27T18:00:00.000Z"),
//                     "timestamp": 1693159200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 8,
//                     "epochTimestamp": 1693162800,
//                     "date": new Date("2023-08-27T19:00:00.000Z"),
//                     "timestamp": 1693162800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 7,
//                     "epochTimestamp": 1693166400,
//                     "date": new Date("2023-08-27T20:00:00.000Z"),
//                     "timestamp": 1693166400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 8,
//                     "epochTimestamp": 1693170000,
//                     "date": new Date("2023-08-27T21:00:00.000Z"),
//                     "timestamp": 1693170000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 7,
//                     "epochTimestamp": 1693177200,
//                     "date": new Date("2023-08-27T23:00:00.000Z"),
//                     "timestamp": 1693177200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 13,
//                     "epochTimestamp": 1693180800,
//                     "date": new Date("2023-08-28T00:00:00.000Z"),
//                     "timestamp": 1693180800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 39,
//                     "epochTimestamp": 1693184400,
//                     "date": new Date("2023-08-28T01:00:00.000Z"),
//                     "timestamp": 1693184400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 80,
//                     "epochTimestamp": 1693188000,
//                     "date": new Date("2023-08-28T02:00:00.000Z"),
//                     "timestamp": 1693188000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 3,
//                     "epochTimestamp": 1693191600,
//                     "date": new Date("2023-08-28T03:00:00.000Z"),
//                     "timestamp": 1693191600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 2,
//                     "epochTimestamp": 1693198800,
//                     "date": new Date("2023-08-28T05:00:00.000Z"),
//                     "timestamp": 1693198800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 2,
//                     "epochTimestamp": 1693202400,
//                     "date": new Date("2023-08-28T06:00:00.000Z"),
//                     "timestamp": 1693202400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 1,
//                     "epochTimestamp": 1693209600,
//                     "date": new Date("2023-08-28T08:00:00.000Z"),
//                     "timestamp": 1693209600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 1,
//                     "epochTimestamp": 1693213200,
//                     "date": new Date("2023-08-28T09:00:00.000Z"),
//                     "timestamp": 1693213200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 1,
//                     "epochTimestamp": 1693216800,
//                     "date": new Date("2023-08-28T10:00:00.000Z"),
//                     "timestamp": 1693216800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 5,
//                     "epochTimestamp": 1693220400,
//                     "date": new Date("2023-08-28T11:00:00.000Z"),
//                     "timestamp": 1693220400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 8,
//                     "epochTimestamp": 1693224000,
//                     "date": new Date("2023-08-28T12:00:00.000Z"),
//                     "timestamp": 1693224000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 14,
//                     "epochTimestamp": 1693227600,
//                     "date": new Date("2023-08-28T13:00:00.000Z"),
//                     "timestamp": 1693227600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 19,
//                     "epochTimestamp": 1693231200,
//                     "date": new Date("2023-08-28T14:00:00.000Z"),
//                     "timestamp": 1693231200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 13,
//                     "epochTimestamp": 1693234800,
//                     "date": new Date("2023-08-28T15:00:00.000Z"),
//                     "timestamp": 1693234800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 15,
//                     "epochTimestamp": 1693238400,
//                     "date": new Date("2023-08-28T16:00:00.000Z"),
//                     "timestamp": 1693238400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 8,
//                     "epochTimestamp": 1693242000,
//                     "date": new Date("2023-08-28T17:00:00.000Z"),
//                     "timestamp": 1693242000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 34,
//                     "epochTimestamp": 1693245600,
//                     "date": new Date("2023-08-28T18:00:00.000Z"),
//                     "timestamp": 1693245600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 49,
//                     "epochTimestamp": 1693249200,
//                     "date": new Date("2023-08-28T19:00:00.000Z"),
//                     "timestamp": 1693249200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 12,
//                     "epochTimestamp": 1693252800,
//                     "date": new Date("2023-08-28T20:00:00.000Z"),
//                     "timestamp": 1693252800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 4,
//                     "epochTimestamp": 1693256400,
//                     "date": new Date("2023-08-28T21:00:00.000Z"),
//                     "timestamp": 1693256400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 7,
//                     "epochTimestamp": 1693260000,
//                     "date": new Date("2023-08-28T22:00:00.000Z"),
//                     "timestamp": 1693260000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 10,
//                     "epochTimestamp": 1693263600,
//                     "date": new Date("2023-08-28T23:00:00.000Z"),
//                     "timestamp": 1693263600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 3,
//                     "epochTimestamp": 1693267200,
//                     "date": new Date("2023-08-29T00:00:00.000Z"),
//                     "timestamp": 1693267200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 3,
//                     "epochTimestamp": 1693270800,
//                     "date": new Date("2023-08-29T01:00:00.000Z"),
//                     "timestamp": 1693270800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 7,
//                     "epochTimestamp": 1693274400,
//                     "date": new Date("2023-08-29T02:00:00.000Z"),
//                     "timestamp": 1693274400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 14,
//                     "epochTimestamp": 1693278000,
//                     "date": new Date("2023-08-29T03:00:00.000Z"),
//                     "timestamp": 1693278000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 7,
//                     "epochTimestamp": 1693281600,
//                     "date": new Date("2023-08-29T04:00:00.000Z"),
//                     "timestamp": 1693281600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 1,
//                     "epochTimestamp": 1693285200,
//                     "date": new Date("2023-08-29T05:00:00.000Z"),
//                     "timestamp": 1693285200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 1,
//                     "epochTimestamp": 1693288800,
//                     "date": new Date("2023-08-29T06:00:00.000Z"),
//                     "timestamp": 1693288800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 1,
//                     "epochTimestamp": 1693303200,
//                     "date": new Date("2023-08-29T10:00:00.000Z"),
//                     "timestamp": 1693303200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 6,
//                     "epochTimestamp": 1693306800,
//                     "date": new Date("2023-08-29T11:00:00.000Z"),
//                     "timestamp": 1693306800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 7,
//                     "epochTimestamp": 1693310400,
//                     "date": new Date("2023-08-29T12:00:00.000Z"),
//                     "timestamp": 1693310400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 3,
//                     "epochTimestamp": 1693314000,
//                     "date": new Date("2023-08-29T13:00:00.000Z"),
//                     "timestamp": 1693314000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 1,
//                     "epochTimestamp": 1693317600,
//                     "date": new Date("2023-08-29T14:00:00.000Z"),
//                     "timestamp": 1693317600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 9,
//                     "epochTimestamp": 1693321200,
//                     "date": new Date("2023-08-29T15:00:00.000Z"),
//                     "timestamp": 1693321200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 9,
//                     "epochTimestamp": 1693324800,
//                     "date": new Date("2023-08-29T16:00:00.000Z"),
//                     "timestamp": 1693324800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 6,
//                     "epochTimestamp": 1693328400,
//                     "date": new Date("2023-08-29T17:00:00.000Z"),
//                     "timestamp": 1693328400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 4,
//                     "epochTimestamp": 1693332000,
//                     "date": new Date("2023-08-29T18:00:00.000Z"),
//                     "timestamp": 1693332000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 4,
//                     "epochTimestamp": 1693335600,
//                     "date": new Date("2023-08-29T19:00:00.000Z"),
//                     "timestamp": 1693335600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 18,
//                     "epochTimestamp": 1693339200,
//                     "date": new Date("2023-08-29T20:00:00.000Z"),
//                     "timestamp": 1693339200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 14,
//                     "epochTimestamp": 1693342800,
//                     "date": new Date("2023-08-29T21:00:00.000Z"),
//                     "timestamp": 1693342800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 3,
//                     "epochTimestamp": 1693346400,
//                     "date": new Date("2023-08-29T22:00:00.000Z"),
//                     "timestamp": 1693346400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 8,
//                     "epochTimestamp": 1693350000,
//                     "date": new Date("2023-08-29T23:00:00.000Z"),
//                     "timestamp": 1693350000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 1,
//                     "epochTimestamp": 1693353600,
//                     "date": new Date("2023-08-30T00:00:00.000Z"),
//                     "timestamp": 1693353600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 12,
//                     "epochTimestamp": 1693357200,
//                     "date": new Date("2023-08-30T01:00:00.000Z"),
//                     "timestamp": 1693357200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 35,
//                     "epochTimestamp": 1693360800,
//                     "date": new Date("2023-08-30T02:00:00.000Z"),
//                     "timestamp": 1693360800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 31,
//                     "epochTimestamp": 1693364400,
//                     "date": new Date("2023-08-30T03:00:00.000Z"),
//                     "timestamp": 1693364400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 3,
//                     "epochTimestamp": 1693368000,
//                     "date": new Date("2023-08-30T04:00:00.000Z"),
//                     "timestamp": 1693368000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 2,
//                     "epochTimestamp": 1693371600,
//                     "date": new Date("2023-08-30T05:00:00.000Z"),
//                     "timestamp": 1693371600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 3,
//                     "epochTimestamp": 1693375200,
//                     "date": new Date("2023-08-30T06:00:00.000Z"),
//                     "timestamp": 1693375200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 3,
//                     "epochTimestamp": 1693382400,
//                     "date": new Date("2023-08-30T08:00:00.000Z"),
//                     "timestamp": 1693382400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 3,
//                     "epochTimestamp": 1693389600,
//                     "date": new Date("2023-08-30T10:00:00.000Z"),
//                     "timestamp": 1693389600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 4,
//                     "epochTimestamp": 1693393200,
//                     "date": new Date("2023-08-30T11:00:00.000Z"),
//                     "timestamp": 1693393200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 7,
//                     "epochTimestamp": 1693396800,
//                     "date": new Date("2023-08-30T12:00:00.000Z"),
//                     "timestamp": 1693396800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 7,
//                     "epochTimestamp": 1693400400,
//                     "date": new Date("2023-08-30T13:00:00.000Z"),
//                     "timestamp": 1693400400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 7,
//                     "epochTimestamp": 1693404000,
//                     "date": new Date("2023-08-30T14:00:00.000Z"),
//                     "timestamp": 1693404000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 2,
//                     "epochTimestamp": 1693407600,
//                     "date": new Date("2023-08-30T15:00:00.000Z"),
//                     "timestamp": 1693407600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 31,
//                     "epochTimestamp": 1693411200,
//                     "date": new Date("2023-08-30T16:00:00.000Z"),
//                     "timestamp": 1693411200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 7,
//                     "epochTimestamp": 1693414800,
//                     "date": new Date("2023-08-30T17:00:00.000Z"),
//                     "timestamp": 1693414800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 3,
//                     "epochTimestamp": 1693418400,
//                     "date": new Date("2023-08-30T18:00:00.000Z"),
//                     "timestamp": 1693418400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 31,
//                     "epochTimestamp": 1693422000,
//                     "date": new Date("2023-08-30T19:00:00.000Z"),
//                     "timestamp": 1693422000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 16,
//                     "epochTimestamp": 1693425600,
//                     "date": new Date("2023-08-30T20:00:00.000Z"),
//                     "timestamp": 1693425600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 21,
//                     "epochTimestamp": 1693429200,
//                     "date": new Date("2023-08-30T21:00:00.000Z"),
//                     "timestamp": 1693429200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 11,
//                     "epochTimestamp": 1693432800,
//                     "date": new Date("2023-08-30T22:00:00.000Z"),
//                     "timestamp": 1693432800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 9,
//                     "epochTimestamp": 1693436400,
//                     "date": new Date("2023-08-30T23:00:00.000Z"),
//                     "timestamp": 1693436400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 4,
//                     "epochTimestamp": 1693440000,
//                     "date": new Date("2023-08-31T00:00:00.000Z"),
//                     "timestamp": 1693440000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 4,
//                     "epochTimestamp": 1693443600,
//                     "date": new Date("2023-08-31T01:00:00.000Z"),
//                     "timestamp": 1693443600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 11,
//                     "epochTimestamp": 1693447200,
//                     "date": new Date("2023-08-31T02:00:00.000Z"),
//                     "timestamp": 1693447200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 35,
//                     "epochTimestamp": 1693450800,
//                     "date": new Date("2023-08-31T03:00:00.000Z"),
//                     "timestamp": 1693450800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 15,
//                     "epochTimestamp": 1693454400,
//                     "date": new Date("2023-08-31T04:00:00.000Z"),
//                     "timestamp": 1693454400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 4,
//                     "epochTimestamp": 1693458000,
//                     "date": new Date("2023-08-31T05:00:00.000Z"),
//                     "timestamp": 1693458000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 3,
//                     "epochTimestamp": 1693461600,
//                     "date": new Date("2023-08-31T06:00:00.000Z"),
//                     "timestamp": 1693461600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 2,
//                     "epochTimestamp": 1693465200,
//                     "date": new Date("2023-08-31T07:00:00.000Z"),
//                     "timestamp": 1693465200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 5,
//                     "epochTimestamp": 1693468800,
//                     "date": new Date("2023-08-31T08:00:00.000Z"),
//                     "timestamp": 1693468800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 5,
//                     "epochTimestamp": 1693472400,
//                     "date": new Date("2023-08-31T09:00:00.000Z"),
//                     "timestamp": 1693472400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 1,
//                     "epochTimestamp": 1693476000,
//                     "date": new Date("2023-08-31T10:00:00.000Z"),
//                     "timestamp": 1693476000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 6,
//                     "epochTimestamp": 1693483200,
//                     "date": new Date("2023-08-31T12:00:00.000Z"),
//                     "timestamp": 1693483200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 7,
//                     "epochTimestamp": 1693486800,
//                     "date": new Date("2023-08-31T13:00:00.000Z"),
//                     "timestamp": 1693486800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 4,
//                     "epochTimestamp": 1693490400,
//                     "date": new Date("2023-08-31T14:00:00.000Z"),
//                     "timestamp": 1693490400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 9,
//                     "epochTimestamp": 1693494000,
//                     "date": new Date("2023-08-31T15:00:00.000Z"),
//                     "timestamp": 1693494000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 16,
//                     "epochTimestamp": 1693497600,
//                     "date": new Date("2023-08-31T16:00:00.000Z"),
//                     "timestamp": 1693497600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 47,
//                     "epochTimestamp": 1693501200,
//                     "date": new Date("2023-08-31T17:00:00.000Z"),
//                     "timestamp": 1693501200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 40,
//                     "epochTimestamp": 1693504800,
//                     "date": new Date("2023-08-31T18:00:00.000Z"),
//                     "timestamp": 1693504800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 7,
//                     "epochTimestamp": 1693508400,
//                     "date": new Date("2023-08-31T19:00:00.000Z"),
//                     "timestamp": 1693508400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 20,
//                     "epochTimestamp": 1693512000,
//                     "date": new Date("2023-08-31T20:00:00.000Z"),
//                     "timestamp": 1693512000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 9,
//                     "epochTimestamp": 1693515600,
//                     "date": new Date("2023-08-31T21:00:00.000Z"),
//                     "timestamp": 1693515600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 9,
//                     "epochTimestamp": 1693519200,
//                     "date": new Date("2023-08-31T22:00:00.000Z"),
//                     "timestamp": 1693519200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 4,
//                     "epochTimestamp": 1693522800,
//                     "date": new Date("2023-08-31T23:00:00.000Z"),
//                     "timestamp": 1693522800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 6,
//                     "epochTimestamp": 1693526400,
//                     "date": new Date("2023-09-01T00:00:00.000Z"),
//                     "timestamp": 1693526400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 1,
//                     "epochTimestamp": 1693530000,
//                     "date": new Date("2023-09-01T01:00:00.000Z"),
//                     "timestamp": 1693530000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 8,
//                     "epochTimestamp": 1693533600,
//                     "date": new Date("2023-09-01T02:00:00.000Z"),
//                     "timestamp": 1693533600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 7,
//                     "epochTimestamp": 1693537200,
//                     "date": new Date("2023-09-01T03:00:00.000Z"),
//                     "timestamp": 1693537200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 2,
//                     "epochTimestamp": 1693540800,
//                     "date": new Date("2023-09-01T04:00:00.000Z"),
//                     "timestamp": 1693540800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 2,
//                     "epochTimestamp": 1693544400,
//                     "date": new Date("2023-09-01T05:00:00.000Z"),
//                     "timestamp": 1693544400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 3,
//                     "epochTimestamp": 1693548000,
//                     "date": new Date("2023-09-01T06:00:00.000Z"),
//                     "timestamp": 1693548000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 2,
//                     "epochTimestamp": 1693551600,
//                     "date": new Date("2023-09-01T07:00:00.000Z"),
//                     "timestamp": 1693551600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 1,
//                     "epochTimestamp": 1693555200,
//                     "date": new Date("2023-09-01T08:00:00.000Z"),
//                     "timestamp": 1693555200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 4,
//                     "epochTimestamp": 1693558800,
//                     "date": new Date("2023-09-01T09:00:00.000Z"),
//                     "timestamp": 1693558800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 6,
//                     "epochTimestamp": 1693562400,
//                     "date": new Date("2023-09-01T10:00:00.000Z"),
//                     "timestamp": 1693562400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 4,
//                     "epochTimestamp": 1693566000,
//                     "date": new Date("2023-09-01T11:00:00.000Z"),
//                     "timestamp": 1693566000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 13,
//                     "epochTimestamp": 1693569600,
//                     "date": new Date("2023-09-01T12:00:00.000Z"),
//                     "timestamp": 1693569600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 12,
//                     "epochTimestamp": 1693573200,
//                     "date": new Date("2023-09-01T13:00:00.000Z"),
//                     "timestamp": 1693573200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 8,
//                     "epochTimestamp": 1693576800,
//                     "date": new Date("2023-09-01T14:00:00.000Z"),
//                     "timestamp": 1693576800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 11,
//                     "epochTimestamp": 1693580400,
//                     "date": new Date("2023-09-01T15:00:00.000Z"),
//                     "timestamp": 1693580400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 3,
//                     "epochTimestamp": 1693584000,
//                     "date": new Date("2023-09-01T16:00:00.000Z"),
//                     "timestamp": 1693584000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 5,
//                     "epochTimestamp": 1693587600,
//                     "date": new Date("2023-09-01T17:00:00.000Z"),
//                     "timestamp": 1693587600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "unknown",
//                     "amount": 2,
//                     "epochTimestamp": 1693591200,
//                     "date": new Date("2023-09-01T18:00:00.000Z"),
//                     "timestamp": 1693591200000
//                 }
//             ]
//         },
//         {
//             "label": "verizon",
//             "items": [
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 12,
//                     "epochTimestamp": 1693112400,
//                     "date": new Date("2023-08-27T05:00:00.000Z"),
//                     "timestamp": 1693112400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 6,
//                     "epochTimestamp": 1693116000,
//                     "date": new Date("2023-08-27T06:00:00.000Z"),
//                     "timestamp": 1693116000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 4,
//                     "epochTimestamp": 1693119600,
//                     "date": new Date("2023-08-27T07:00:00.000Z"),
//                     "timestamp": 1693119600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 2,
//                     "epochTimestamp": 1693123200,
//                     "date": new Date("2023-08-27T08:00:00.000Z"),
//                     "timestamp": 1693123200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 2,
//                     "epochTimestamp": 1693126800,
//                     "date": new Date("2023-08-27T09:00:00.000Z"),
//                     "timestamp": 1693126800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 4,
//                     "epochTimestamp": 1693141200,
//                     "date": new Date("2023-08-27T13:00:00.000Z"),
//                     "timestamp": 1693141200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 16,
//                     "epochTimestamp": 1693144800,
//                     "date": new Date("2023-08-27T14:00:00.000Z"),
//                     "timestamp": 1693144800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 32,
//                     "epochTimestamp": 1693148400,
//                     "date": new Date("2023-08-27T15:00:00.000Z"),
//                     "timestamp": 1693148400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 8,
//                     "epochTimestamp": 1693152000,
//                     "date": new Date("2023-08-27T16:00:00.000Z"),
//                     "timestamp": 1693152000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 15,
//                     "epochTimestamp": 1693155600,
//                     "date": new Date("2023-08-27T17:00:00.000Z"),
//                     "timestamp": 1693155600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 4,
//                     "epochTimestamp": 1693159200,
//                     "date": new Date("2023-08-27T18:00:00.000Z"),
//                     "timestamp": 1693159200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 47,
//                     "epochTimestamp": 1693162800,
//                     "date": new Date("2023-08-27T19:00:00.000Z"),
//                     "timestamp": 1693162800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 28,
//                     "epochTimestamp": 1693166400,
//                     "date": new Date("2023-08-27T20:00:00.000Z"),
//                     "timestamp": 1693166400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 45,
//                     "epochTimestamp": 1693170000,
//                     "date": new Date("2023-08-27T21:00:00.000Z"),
//                     "timestamp": 1693170000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 158,
//                     "epochTimestamp": 1693173600,
//                     "date": new Date("2023-08-27T22:00:00.000Z"),
//                     "timestamp": 1693173600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 14,
//                     "epochTimestamp": 1693177200,
//                     "date": new Date("2023-08-27T23:00:00.000Z"),
//                     "timestamp": 1693177200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 16,
//                     "epochTimestamp": 1693180800,
//                     "date": new Date("2023-08-28T00:00:00.000Z"),
//                     "timestamp": 1693180800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 6,
//                     "epochTimestamp": 1693184400,
//                     "date": new Date("2023-08-28T01:00:00.000Z"),
//                     "timestamp": 1693184400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 4,
//                     "epochTimestamp": 1693188000,
//                     "date": new Date("2023-08-28T02:00:00.000Z"),
//                     "timestamp": 1693188000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 9,
//                     "epochTimestamp": 1693191600,
//                     "date": new Date("2023-08-28T03:00:00.000Z"),
//                     "timestamp": 1693191600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 26,
//                     "epochTimestamp": 1693195200,
//                     "date": new Date("2023-08-28T04:00:00.000Z"),
//                     "timestamp": 1693195200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 23,
//                     "epochTimestamp": 1693198800,
//                     "date": new Date("2023-08-28T05:00:00.000Z"),
//                     "timestamp": 1693198800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 36,
//                     "epochTimestamp": 1693202400,
//                     "date": new Date("2023-08-28T06:00:00.000Z"),
//                     "timestamp": 1693202400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 7,
//                     "epochTimestamp": 1693206000,
//                     "date": new Date("2023-08-28T07:00:00.000Z"),
//                     "timestamp": 1693206000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 13,
//                     "epochTimestamp": 1693209600,
//                     "date": new Date("2023-08-28T08:00:00.000Z"),
//                     "timestamp": 1693209600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 39,
//                     "epochTimestamp": 1693213200,
//                     "date": new Date("2023-08-28T09:00:00.000Z"),
//                     "timestamp": 1693213200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 16,
//                     "epochTimestamp": 1693216800,
//                     "date": new Date("2023-08-28T10:00:00.000Z"),
//                     "timestamp": 1693216800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 32,
//                     "epochTimestamp": 1693220400,
//                     "date": new Date("2023-08-28T11:00:00.000Z"),
//                     "timestamp": 1693220400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 63,
//                     "epochTimestamp": 1693224000,
//                     "date": new Date("2023-08-28T12:00:00.000Z"),
//                     "timestamp": 1693224000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 29,
//                     "epochTimestamp": 1693227600,
//                     "date": new Date("2023-08-28T13:00:00.000Z"),
//                     "timestamp": 1693227600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 95,
//                     "epochTimestamp": 1693231200,
//                     "date": new Date("2023-08-28T14:00:00.000Z"),
//                     "timestamp": 1693231200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 34,
//                     "epochTimestamp": 1693234800,
//                     "date": new Date("2023-08-28T15:00:00.000Z"),
//                     "timestamp": 1693234800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 92,
//                     "epochTimestamp": 1693238400,
//                     "date": new Date("2023-08-28T16:00:00.000Z"),
//                     "timestamp": 1693238400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 82,
//                     "epochTimestamp": 1693242000,
//                     "date": new Date("2023-08-28T17:00:00.000Z"),
//                     "timestamp": 1693242000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 60,
//                     "epochTimestamp": 1693245600,
//                     "date": new Date("2023-08-28T18:00:00.000Z"),
//                     "timestamp": 1693245600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 65,
//                     "epochTimestamp": 1693249200,
//                     "date": new Date("2023-08-28T19:00:00.000Z"),
//                     "timestamp": 1693249200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 80,
//                     "epochTimestamp": 1693252800,
//                     "date": new Date("2023-08-28T20:00:00.000Z"),
//                     "timestamp": 1693252800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 36,
//                     "epochTimestamp": 1693256400,
//                     "date": new Date("2023-08-28T21:00:00.000Z"),
//                     "timestamp": 1693256400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 68,
//                     "epochTimestamp": 1693260000,
//                     "date": new Date("2023-08-28T22:00:00.000Z"),
//                     "timestamp": 1693260000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 111,
//                     "epochTimestamp": 1693263600,
//                     "date": new Date("2023-08-28T23:00:00.000Z"),
//                     "timestamp": 1693263600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 30,
//                     "epochTimestamp": 1693267200,
//                     "date": new Date("2023-08-29T00:00:00.000Z"),
//                     "timestamp": 1693267200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 6,
//                     "epochTimestamp": 1693270800,
//                     "date": new Date("2023-08-29T01:00:00.000Z"),
//                     "timestamp": 1693270800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 8,
//                     "epochTimestamp": 1693274400,
//                     "date": new Date("2023-08-29T02:00:00.000Z"),
//                     "timestamp": 1693274400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 6,
//                     "epochTimestamp": 1693278000,
//                     "date": new Date("2023-08-29T03:00:00.000Z"),
//                     "timestamp": 1693278000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 16,
//                     "epochTimestamp": 1693285200,
//                     "date": new Date("2023-08-29T05:00:00.000Z"),
//                     "timestamp": 1693285200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 22,
//                     "epochTimestamp": 1693288800,
//                     "date": new Date("2023-08-29T06:00:00.000Z"),
//                     "timestamp": 1693288800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 8,
//                     "epochTimestamp": 1693292400,
//                     "date": new Date("2023-08-29T07:00:00.000Z"),
//                     "timestamp": 1693292400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 18,
//                     "epochTimestamp": 1693296000,
//                     "date": new Date("2023-08-29T08:00:00.000Z"),
//                     "timestamp": 1693296000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 3,
//                     "epochTimestamp": 1693306800,
//                     "date": new Date("2023-08-29T11:00:00.000Z"),
//                     "timestamp": 1693306800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 373,
//                     "epochTimestamp": 1693310400,
//                     "date": new Date("2023-08-29T12:00:00.000Z"),
//                     "timestamp": 1693310400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 66,
//                     "epochTimestamp": 1693314000,
//                     "date": new Date("2023-08-29T13:00:00.000Z"),
//                     "timestamp": 1693314000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 119,
//                     "epochTimestamp": 1693317600,
//                     "date": new Date("2023-08-29T14:00:00.000Z"),
//                     "timestamp": 1693317600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 176,
//                     "epochTimestamp": 1693321200,
//                     "date": new Date("2023-08-29T15:00:00.000Z"),
//                     "timestamp": 1693321200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 4,
//                     "epochTimestamp": 1693332000,
//                     "date": new Date("2023-08-29T18:00:00.000Z"),
//                     "timestamp": 1693332000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 6,
//                     "epochTimestamp": 1693335600,
//                     "date": new Date("2023-08-29T19:00:00.000Z"),
//                     "timestamp": 1693335600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 108,
//                     "epochTimestamp": 1693339200,
//                     "date": new Date("2023-08-29T20:00:00.000Z"),
//                     "timestamp": 1693339200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 14,
//                     "epochTimestamp": 1693342800,
//                     "date": new Date("2023-08-29T21:00:00.000Z"),
//                     "timestamp": 1693342800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 82,
//                     "epochTimestamp": 1693346400,
//                     "date": new Date("2023-08-29T22:00:00.000Z"),
//                     "timestamp": 1693346400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 26,
//                     "epochTimestamp": 1693350000,
//                     "date": new Date("2023-08-29T23:00:00.000Z"),
//                     "timestamp": 1693350000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 10,
//                     "epochTimestamp": 1693353600,
//                     "date": new Date("2023-08-30T00:00:00.000Z"),
//                     "timestamp": 1693353600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 30,
//                     "epochTimestamp": 1693357200,
//                     "date": new Date("2023-08-30T01:00:00.000Z"),
//                     "timestamp": 1693357200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 3,
//                     "epochTimestamp": 1693368000,
//                     "date": new Date("2023-08-30T04:00:00.000Z"),
//                     "timestamp": 1693368000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 2,
//                     "epochTimestamp": 1693378800,
//                     "date": new Date("2023-08-30T07:00:00.000Z"),
//                     "timestamp": 1693378800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 9,
//                     "epochTimestamp": 1693382400,
//                     "date": new Date("2023-08-30T08:00:00.000Z"),
//                     "timestamp": 1693382400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 5,
//                     "epochTimestamp": 1693386000,
//                     "date": new Date("2023-08-30T09:00:00.000Z"),
//                     "timestamp": 1693386000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 2,
//                     "epochTimestamp": 1693389600,
//                     "date": new Date("2023-08-30T10:00:00.000Z"),
//                     "timestamp": 1693389600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 7,
//                     "epochTimestamp": 1693393200,
//                     "date": new Date("2023-08-30T11:00:00.000Z"),
//                     "timestamp": 1693393200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 79,
//                     "epochTimestamp": 1693396800,
//                     "date": new Date("2023-08-30T12:00:00.000Z"),
//                     "timestamp": 1693396800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 42,
//                     "epochTimestamp": 1693400400,
//                     "date": new Date("2023-08-30T13:00:00.000Z"),
//                     "timestamp": 1693400400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 43,
//                     "epochTimestamp": 1693404000,
//                     "date": new Date("2023-08-30T14:00:00.000Z"),
//                     "timestamp": 1693404000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 101,
//                     "epochTimestamp": 1693407600,
//                     "date": new Date("2023-08-30T15:00:00.000Z"),
//                     "timestamp": 1693407600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 9,
//                     "epochTimestamp": 1693411200,
//                     "date": new Date("2023-08-30T16:00:00.000Z"),
//                     "timestamp": 1693411200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 61,
//                     "epochTimestamp": 1693414800,
//                     "date": new Date("2023-08-30T17:00:00.000Z"),
//                     "timestamp": 1693414800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 109,
//                     "epochTimestamp": 1693418400,
//                     "date": new Date("2023-08-30T18:00:00.000Z"),
//                     "timestamp": 1693418400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 4,
//                     "epochTimestamp": 1693422000,
//                     "date": new Date("2023-08-30T19:00:00.000Z"),
//                     "timestamp": 1693422000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 6,
//                     "epochTimestamp": 1693425600,
//                     "date": new Date("2023-08-30T20:00:00.000Z"),
//                     "timestamp": 1693425600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 212,
//                     "epochTimestamp": 1693429200,
//                     "date": new Date("2023-08-30T21:00:00.000Z"),
//                     "timestamp": 1693429200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 187,
//                     "epochTimestamp": 1693432800,
//                     "date": new Date("2023-08-30T22:00:00.000Z"),
//                     "timestamp": 1693432800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 77,
//                     "epochTimestamp": 1693436400,
//                     "date": new Date("2023-08-30T23:00:00.000Z"),
//                     "timestamp": 1693436400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 38,
//                     "epochTimestamp": 1693440000,
//                     "date": new Date("2023-08-31T00:00:00.000Z"),
//                     "timestamp": 1693440000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 43,
//                     "epochTimestamp": 1693443600,
//                     "date": new Date("2023-08-31T01:00:00.000Z"),
//                     "timestamp": 1693443600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 52,
//                     "epochTimestamp": 1693447200,
//                     "date": new Date("2023-08-31T02:00:00.000Z"),
//                     "timestamp": 1693447200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 39,
//                     "epochTimestamp": 1693450800,
//                     "date": new Date("2023-08-31T03:00:00.000Z"),
//                     "timestamp": 1693450800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 41,
//                     "epochTimestamp": 1693454400,
//                     "date": new Date("2023-08-31T04:00:00.000Z"),
//                     "timestamp": 1693454400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 21,
//                     "epochTimestamp": 1693458000,
//                     "date": new Date("2023-08-31T05:00:00.000Z"),
//                     "timestamp": 1693458000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 23,
//                     "epochTimestamp": 1693461600,
//                     "date": new Date("2023-08-31T06:00:00.000Z"),
//                     "timestamp": 1693461600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 38,
//                     "epochTimestamp": 1693465200,
//                     "date": new Date("2023-08-31T07:00:00.000Z"),
//                     "timestamp": 1693465200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 38,
//                     "epochTimestamp": 1693468800,
//                     "date": new Date("2023-08-31T08:00:00.000Z"),
//                     "timestamp": 1693468800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 31,
//                     "epochTimestamp": 1693472400,
//                     "date": new Date("2023-08-31T09:00:00.000Z"),
//                     "timestamp": 1693472400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 45,
//                     "epochTimestamp": 1693476000,
//                     "date": new Date("2023-08-31T10:00:00.000Z"),
//                     "timestamp": 1693476000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 46,
//                     "epochTimestamp": 1693479600,
//                     "date": new Date("2023-08-31T11:00:00.000Z"),
//                     "timestamp": 1693479600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 155,
//                     "epochTimestamp": 1693483200,
//                     "date": new Date("2023-08-31T12:00:00.000Z"),
//                     "timestamp": 1693483200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 85,
//                     "epochTimestamp": 1693486800,
//                     "date": new Date("2023-08-31T13:00:00.000Z"),
//                     "timestamp": 1693486800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 127,
//                     "epochTimestamp": 1693490400,
//                     "date": new Date("2023-08-31T14:00:00.000Z"),
//                     "timestamp": 1693490400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 80,
//                     "epochTimestamp": 1693494000,
//                     "date": new Date("2023-08-31T15:00:00.000Z"),
//                     "timestamp": 1693494000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 71,
//                     "epochTimestamp": 1693497600,
//                     "date": new Date("2023-08-31T16:00:00.000Z"),
//                     "timestamp": 1693497600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 49,
//                     "epochTimestamp": 1693501200,
//                     "date": new Date("2023-08-31T17:00:00.000Z"),
//                     "timestamp": 1693501200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 21,
//                     "epochTimestamp": 1693504800,
//                     "date": new Date("2023-08-31T18:00:00.000Z"),
//                     "timestamp": 1693504800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 49,
//                     "epochTimestamp": 1693508400,
//                     "date": new Date("2023-08-31T19:00:00.000Z"),
//                     "timestamp": 1693508400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 109,
//                     "epochTimestamp": 1693512000,
//                     "date": new Date("2023-08-31T20:00:00.000Z"),
//                     "timestamp": 1693512000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 164,
//                     "epochTimestamp": 1693515600,
//                     "date": new Date("2023-08-31T21:00:00.000Z"),
//                     "timestamp": 1693515600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 68,
//                     "epochTimestamp": 1693519200,
//                     "date": new Date("2023-08-31T22:00:00.000Z"),
//                     "timestamp": 1693519200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 47,
//                     "epochTimestamp": 1693522800,
//                     "date": new Date("2023-08-31T23:00:00.000Z"),
//                     "timestamp": 1693522800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 70,
//                     "epochTimestamp": 1693526400,
//                     "date": new Date("2023-09-01T00:00:00.000Z"),
//                     "timestamp": 1693526400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 104,
//                     "epochTimestamp": 1693530000,
//                     "date": new Date("2023-09-01T01:00:00.000Z"),
//                     "timestamp": 1693530000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 58,
//                     "epochTimestamp": 1693533600,
//                     "date": new Date("2023-09-01T02:00:00.000Z"),
//                     "timestamp": 1693533600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 114,
//                     "epochTimestamp": 1693537200,
//                     "date": new Date("2023-09-01T03:00:00.000Z"),
//                     "timestamp": 1693537200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 97,
//                     "epochTimestamp": 1693540800,
//                     "date": new Date("2023-09-01T04:00:00.000Z"),
//                     "timestamp": 1693540800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 20,
//                     "epochTimestamp": 1693544400,
//                     "date": new Date("2023-09-01T05:00:00.000Z"),
//                     "timestamp": 1693544400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 2,
//                     "epochTimestamp": 1693548000,
//                     "date": new Date("2023-09-01T06:00:00.000Z"),
//                     "timestamp": 1693548000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 20,
//                     "epochTimestamp": 1693551600,
//                     "date": new Date("2023-09-01T07:00:00.000Z"),
//                     "timestamp": 1693551600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 52,
//                     "epochTimestamp": 1693555200,
//                     "date": new Date("2023-09-01T08:00:00.000Z"),
//                     "timestamp": 1693555200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 39,
//                     "epochTimestamp": 1693558800,
//                     "date": new Date("2023-09-01T09:00:00.000Z"),
//                     "timestamp": 1693558800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 51,
//                     "epochTimestamp": 1693562400,
//                     "date": new Date("2023-09-01T10:00:00.000Z"),
//                     "timestamp": 1693562400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 46,
//                     "epochTimestamp": 1693566000,
//                     "date": new Date("2023-09-01T11:00:00.000Z"),
//                     "timestamp": 1693566000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 2,
//                     "epochTimestamp": 1693569600,
//                     "date": new Date("2023-09-01T12:00:00.000Z"),
//                     "timestamp": 1693569600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 54,
//                     "epochTimestamp": 1693573200,
//                     "date": new Date("2023-09-01T13:00:00.000Z"),
//                     "timestamp": 1693573200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 110,
//                     "epochTimestamp": 1693576800,
//                     "date": new Date("2023-09-01T14:00:00.000Z"),
//                     "timestamp": 1693576800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 22,
//                     "epochTimestamp": 1693580400,
//                     "date": new Date("2023-09-01T15:00:00.000Z"),
//                     "timestamp": 1693580400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 80,
//                     "epochTimestamp": 1693584000,
//                     "date": new Date("2023-09-01T16:00:00.000Z"),
//                     "timestamp": 1693584000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 41,
//                     "epochTimestamp": 1693587600,
//                     "date": new Date("2023-09-01T17:00:00.000Z"),
//                     "timestamp": 1693587600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "Verizon Wireless",
//                     "amount": 40,
//                     "epochTimestamp": 1693591200,
//                     "date": new Date("2023-09-01T18:00:00.000Z"),
//                     "timestamp": 1693591200000
//                 }
//             ]
//         },
//         {
//             "label": "at&t",
//             "items": [
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "AT&T Mobility",
//                     "amount": 4,
//                     "epochTimestamp": 1693191600,
//                     "date": new Date("2023-08-28T03:00:00.000Z"),
//                     "timestamp": 1693191600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "AT&T Mobility",
//                     "amount": 8,
//                     "epochTimestamp": 1693195200,
//                     "date": new Date("2023-08-28T04:00:00.000Z"),
//                     "timestamp": 1693195200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "AT&T Mobility",
//                     "amount": 9,
//                     "epochTimestamp": 1693198800,
//                     "date": new Date("2023-08-28T05:00:00.000Z"),
//                     "timestamp": 1693198800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "AT&T Mobility",
//                     "amount": 10,
//                     "epochTimestamp": 1693202400,
//                     "date": new Date("2023-08-28T06:00:00.000Z"),
//                     "timestamp": 1693202400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "AT&T Mobility",
//                     "amount": 9,
//                     "epochTimestamp": 1693206000,
//                     "date": new Date("2023-08-28T07:00:00.000Z"),
//                     "timestamp": 1693206000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "AT&T Mobility",
//                     "amount": 10,
//                     "epochTimestamp": 1693209600,
//                     "date": new Date("2023-08-28T08:00:00.000Z"),
//                     "timestamp": 1693209600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "AT&T Mobility",
//                     "amount": 15,
//                     "epochTimestamp": 1693213200,
//                     "date": new Date("2023-08-28T09:00:00.000Z"),
//                     "timestamp": 1693213200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "AT&T Mobility",
//                     "amount": 11,
//                     "epochTimestamp": 1693216800,
//                     "date": new Date("2023-08-28T10:00:00.000Z"),
//                     "timestamp": 1693216800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "AT&T Mobility",
//                     "amount": 22,
//                     "epochTimestamp": 1693220400,
//                     "date": new Date("2023-08-28T11:00:00.000Z"),
//                     "timestamp": 1693220400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "AT&T Mobility",
//                     "amount": 14,
//                     "epochTimestamp": 1693224000,
//                     "date": new Date("2023-08-28T12:00:00.000Z"),
//                     "timestamp": 1693224000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "AT&T Mobility",
//                     "amount": 2,
//                     "epochTimestamp": 1693227600,
//                     "date": new Date("2023-08-28T13:00:00.000Z"),
//                     "timestamp": 1693227600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "AT&T Mobility",
//                     "amount": 10,
//                     "epochTimestamp": 1693242000,
//                     "date": new Date("2023-08-28T17:00:00.000Z"),
//                     "timestamp": 1693242000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "AT&T Mobility",
//                     "amount": 24,
//                     "epochTimestamp": 1693249200,
//                     "date": new Date("2023-08-28T19:00:00.000Z"),
//                     "timestamp": 1693249200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "AT&T Mobility",
//                     "amount": 32,
//                     "epochTimestamp": 1693252800,
//                     "date": new Date("2023-08-28T20:00:00.000Z"),
//                     "timestamp": 1693252800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "AT&T Mobility",
//                     "amount": 32,
//                     "epochTimestamp": 1693256400,
//                     "date": new Date("2023-08-28T21:00:00.000Z"),
//                     "timestamp": 1693256400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "AT&T Mobility",
//                     "amount": 8,
//                     "epochTimestamp": 1693260000,
//                     "date": new Date("2023-08-28T22:00:00.000Z"),
//                     "timestamp": 1693260000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "AT&T Mobility",
//                     "amount": 2,
//                     "epochTimestamp": 1693267200,
//                     "date": new Date("2023-08-29T00:00:00.000Z"),
//                     "timestamp": 1693267200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "AT&T Mobility",
//                     "amount": 2,
//                     "epochTimestamp": 1693296000,
//                     "date": new Date("2023-08-29T08:00:00.000Z"),
//                     "timestamp": 1693296000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "AT&T Mobility",
//                     "amount": 4,
//                     "epochTimestamp": 1693310400,
//                     "date": new Date("2023-08-29T12:00:00.000Z"),
//                     "timestamp": 1693310400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "AT&T Mobility",
//                     "amount": 2,
//                     "epochTimestamp": 1693321200,
//                     "date": new Date("2023-08-29T15:00:00.000Z"),
//                     "timestamp": 1693321200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "AT&T Mobility",
//                     "amount": 8,
//                     "epochTimestamp": 1693324800,
//                     "date": new Date("2023-08-29T16:00:00.000Z"),
//                     "timestamp": 1693324800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "AT&T Mobility",
//                     "amount": 77,
//                     "epochTimestamp": 1693328400,
//                     "date": new Date("2023-08-29T17:00:00.000Z"),
//                     "timestamp": 1693328400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "AT&T Mobility",
//                     "amount": 22,
//                     "epochTimestamp": 1693332000,
//                     "date": new Date("2023-08-29T18:00:00.000Z"),
//                     "timestamp": 1693332000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "AT&T Mobility",
//                     "amount": 18,
//                     "epochTimestamp": 1693335600,
//                     "date": new Date("2023-08-29T19:00:00.000Z"),
//                     "timestamp": 1693335600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "AT&T Mobility",
//                     "amount": 4,
//                     "epochTimestamp": 1693339200,
//                     "date": new Date("2023-08-29T20:00:00.000Z"),
//                     "timestamp": 1693339200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "AT&T Mobility",
//                     "amount": 26,
//                     "epochTimestamp": 1693342800,
//                     "date": new Date("2023-08-29T21:00:00.000Z"),
//                     "timestamp": 1693342800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "AT&T Mobility",
//                     "amount": 50,
//                     "epochTimestamp": 1693346400,
//                     "date": new Date("2023-08-29T22:00:00.000Z"),
//                     "timestamp": 1693346400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "AT&T Mobility",
//                     "amount": 12,
//                     "epochTimestamp": 1693350000,
//                     "date": new Date("2023-08-29T23:00:00.000Z"),
//                     "timestamp": 1693350000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "AT&T Mobility",
//                     "amount": 2,
//                     "epochTimestamp": 1693396800,
//                     "date": new Date("2023-08-30T12:00:00.000Z"),
//                     "timestamp": 1693396800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "AT&T Mobility",
//                     "amount": 1,
//                     "epochTimestamp": 1693400400,
//                     "date": new Date("2023-08-30T13:00:00.000Z"),
//                     "timestamp": 1693400400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "AT&T Mobility",
//                     "amount": 1,
//                     "epochTimestamp": 1693404000,
//                     "date": new Date("2023-08-30T14:00:00.000Z"),
//                     "timestamp": 1693404000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "AT&T Mobility",
//                     "amount": 23,
//                     "epochTimestamp": 1693407600,
//                     "date": new Date("2023-08-30T15:00:00.000Z"),
//                     "timestamp": 1693407600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "AT&T Mobility",
//                     "amount": 122,
//                     "epochTimestamp": 1693411200,
//                     "date": new Date("2023-08-30T16:00:00.000Z"),
//                     "timestamp": 1693411200000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "AT&T Mobility",
//                     "amount": 5,
//                     "epochTimestamp": 1693414800,
//                     "date": new Date("2023-08-30T17:00:00.000Z"),
//                     "timestamp": 1693414800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "AT&T Mobility",
//                     "amount": 22,
//                     "epochTimestamp": 1693422000,
//                     "date": new Date("2023-08-30T19:00:00.000Z"),
//                     "timestamp": 1693422000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "AT&T Mobility",
//                     "amount": 26,
//                     "epochTimestamp": 1693425600,
//                     "date": new Date("2023-08-30T20:00:00.000Z"),
//                     "timestamp": 1693425600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "AT&T Mobility",
//                     "amount": 2,
//                     "epochTimestamp": 1693432800,
//                     "date": new Date("2023-08-30T22:00:00.000Z"),
//                     "timestamp": 1693432800000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "AT&T Mobility",
//                     "amount": 2,
//                     "epochTimestamp": 1693440000,
//                     "date": new Date("2023-08-31T00:00:00.000Z"),
//                     "timestamp": 1693440000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "AT&T Mobility",
//                     "amount": 2,
//                     "epochTimestamp": 1693544400,
//                     "date": new Date("2023-09-01T05:00:00.000Z"),
//                     "timestamp": 1693544400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "AT&T Mobility",
//                     "amount": 2,
//                     "epochTimestamp": 1693551600,
//                     "date": new Date("2023-09-01T07:00:00.000Z"),
//                     "timestamp": 1693551600000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "AT&T Mobility",
//                     "amount": 10,
//                     "epochTimestamp": 1693580400,
//                     "date": new Date("2023-09-01T15:00:00.000Z"),
//                     "timestamp": 1693580400000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "AT&T Mobility",
//                     "amount": 171,
//                     "epochTimestamp": 1693584000,
//                     "date": new Date("2023-09-01T16:00:00.000Z"),
//                     "timestamp": 1693584000000
//                 },
//                 {
//                     "type": "cell-scan",
//                     "networkProvider": "AT&T Mobility",
//                     "amount": 50,
//                     "epochTimestamp": 1693591200,
//                     "date": new Date("2023-09-01T18:00:00.000Z"),
//                     "timestamp": 1693591200000
//                 }
//             ]
//         }
//     ],
//     "startDate": new Date("2023-08-27T05:00:00.000Z"),
//     "endDate": new Date("2023-09-03T04:59:59.999Z"),
//     "cutoffDate": new Date("2023-09-01T18:50:44.991Z")
// };
