import * as colors from './colors';
import * as bleeds from './bleeds';
import * as fonts from './fonts';
import * as shaddows from './shaddows';

export * from './colors';
export * from './bleeds';
export * from './fonts';
export * from './shaddows';

const def = {
  ...colors,
  ...bleeds,
  ...fonts,
  ...shaddows
};
export default def;