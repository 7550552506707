// 4x4 matrix class, which expects 1x3 vector/point
// empty one looks like
// [
//   [0,0,0,0],
//   [0,0,0,0],
//   [0,0,0,0],
//   [0,0,0,0]
// ]
import {Vector} from './Vector.js';

export class M4 {

  static multiplyVector(m4, v) {
    return new Vector(
      m4[0][0] * v[0] + m4[0][1] * v[1] + m4[0][2] * v[2] + m4[0][3] * v[3],
      m4[1][0] * v[0] + m4[1][1] * v[1] + m4[1][2] * v[2] + m4[1][3] * v[3],
      m4[2][0] * v[0] + m4[2][1] * v[1] + m4[2][2] * v[2] + m4[2][3] * v[3],
      m4[3][0] * v[0] + m4[3][1] * v[1] + m4[3][2] * v[2] + m4[3][3] * v[3],
    );
  }

  static multiply(a, b) {
    const m = [
      new Array(4),
      new Array(4),
      new Array(4),
      new Array(4),
    ];

    for (let i = 0; i < 4; i++) {
      for (let j = 0; j < 4; j++) {
        m[i][j] = a[i][0] * b[0][j] +
          a[i][1] * b[1][j] +
          a[i][2] * b[2][j] +
          a[i][3] * b[3][j];
      }
    }

    return m;
  }

  static getPlain(){
    return [
      [1,0,0,0],
      [0,1,0,0],
      [0,0,1,0],
      [0,0,0,1]
    ];
  }

  // Matrixes for standart operations
  static getScale(sx, sy, sz) {
    return [
      [sx, 0, 0, 0],
      [0, sy, 0, 0],
      [0, 0, sz, 0],
      [0, 0, 0, 1],
    ];
  }

  static getTranslation(dx, dy, dz) {
    return [
      [1, 0, 0, dx],
      [0, 1, 0, dy],
      [0, 0, 1, dz],
      [0, 0, 0, 1],
    ];
  }

  static getRotationX(angle) {
    const rad = Math.PI / 180 * angle;

    return [
      [1, 0, 0, 0],
      [0, Math.cos(rad), -Math.sin(rad), 0],
      [0, Math.sin(rad), Math.cos(rad), 0],
      [0, 0, 0, 1],
    ];
  }

  static getRotationY(angle) {
    const rad = Math.PI / 180 * angle;

    return [
      [Math.cos(rad), 0, Math.sin(rad), 0],
      [0, 1, 0, 0],
      [-Math.sin(rad), 0, Math.cos(rad), 0],
      [0, 0, 0, 1],
    ];
  }

  static getRotationZ(angle) {
    const rad = Math.PI / 180 * angle;

    return [
      [Math.cos(rad), -Math.sin(rad), 0, 0],
      [Math.sin(rad), Math.cos(rad), 0, 0],
      [0, 0, 1, 0],
      [0, 0, 0, 1],
    ];
  }

  static getView(eye, target, up) {
    const vz = Vector.substract(eye, target).normalize();
    const vx = Vector.crossProduct(up, vz).normalize();
    const vy = Vector.crossProduct(vz, vx).normalize();

    return M4.multiply(
      M4.getTranslation(-eye[0], -eye[1], -eye[2]),
      [
        [vx[0], vx[1], vx[2], 0],
        [vy[0], vy[1], vy[2], 0],
        [vz[0], vz[1], vz[2], 0],
        [0, 0, 0, 1]
      ]);
  }

  static getPerspectiveProjection(fovy, aspect, n, f) {
    const radians = Math.PI / 180 * fovy;
    const sx = (1 / Math.tan(radians / 2)) / aspect;
    const sy = (1 / Math.tan(radians / 2));
    const sz = (f + n) / (f - n);
    const dz = (-2 * f * n) / (f - n);
    return [
      [sx, 0, 0, 0],
      [0, sy, 0, 0],
      [0, 0, sz, -dz],
      [0, 0, -1, 0],
    ];
  }
}