import {
    RawCellBucketItem,
  RawCellListItem
} from '@ubiety/fe-api';
import {
  NewReportItem
} from 'context';
import {
  binaryToDecimal
} from 'utils';
import {
  providerToLabelName
} from './providers';
export function processRawCellItemToReportItem(item: RawCellListItem): NewReportItem{
  return {
    timestamp: (new Date(item.UNIX_TIMESTAMP)).getTime(),
    provider: item.NETWORKPROVIDER,
    type: item.DATA_TYPE as ('cell'|'cell-scan'),
    power: Number(item.POWER_ESTIMATE),
    establishmentCause: item.ESTABLISHMENT_CAUSE||null,
    tmsi: item.TMSI ? binaryToDecimal(item.TMSI) : null
  }
}
export type ProcessedCellBucketItem = {
  provider: string,
  providerLabel: string,
  timestamp: number,
  count: number
}
export function processRawCellBucketItem(item:RawCellBucketItem): ProcessedCellBucketItem {
  return {
    provider: item.NETWORK_PROVIDER,
    providerLabel: providerToLabelName(item.NETWORK_PROVIDER),
    timestamp: new Date(item.DATETIME).getTime(),
    count: Number(item.NUM_RECORDS)
  }
}
export const establishementCauseToUserFacingValue = (value: NewReportItem['establishmentCause']) => {
  switch(value){
    case 'emergency':
      return '911 Emergency';
    case null:
    case undefined:
      return 'Signal Only';
    default: 
      return 'Data Transfer';
  }
}
export const establishementCauseToUserFacingType = (value: NewReportItem['establishmentCause']) => {
  switch(value){
    case 'emergency':
      return 'emergency';
    case null:
    case undefined:
    case "undefined":
    case "null":
      return 'signal-only';
    default: 
      return 'data-transfer';
  }
}