import React from 'react';
import {IconProps} from './_iconProps';

export const Clipboard: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M21.3333 5.33335H24C24.7072 5.33335 25.3855 5.61431 25.8856 6.1144C26.3857 6.6145 26.6667 7.29278 26.6667 8.00002V26.6667C26.6667 27.3739 26.3857 28.0522 25.8856 28.5523C25.3855 29.0524 24.7072 29.3334 24 29.3334H8C7.29276 29.3334 6.61448 29.0524 6.11438 28.5523C5.61429 28.0522 5.33334 27.3739 5.33334 26.6667V8.00002C5.33334 7.29278 5.61429 6.6145 6.11438 6.1144C6.61448 5.61431 7.29276 5.33335 8 5.33335H10.6667M12 2.66669H20C20.7364 2.66669 21.3333 3.26364 21.3333 4.00002V6.66669C21.3333 7.40307 20.7364 8.00002 20 8.00002H12C11.2636 8.00002 10.6667 7.40307 10.6667 6.66669V4.00002C10.6667 3.26364 11.2636 2.66669 12 2.66669Z" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</svg>
