import React from 'react';
import {IconProps} from './_iconProps';

export const Briefcase: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M21.3333 28V6.66667C21.3333 5.95942 21.0524 5.28115 20.5523 4.78105C20.0522 4.28095 19.3739 4 18.6667 4H13.3333C12.6261 4 11.9478 4.28095 11.4477 4.78105C10.9476 5.28115 10.6667 5.95942 10.6667 6.66667V28M5.33334 9.33333H26.6667C28.1394 9.33333 29.3333 10.5272 29.3333 12V25.3333C29.3333 26.8061 28.1394 28 26.6667 28H5.33334C3.86058 28 2.66667 26.8061 2.66667 25.3333V12C2.66667 10.5272 3.86058 9.33333 5.33334 9.33333Z" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</svg>
