import React from 'react';
import {IconProps} from './_iconProps';

export const Framer: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.66666 21.3333V12H25.3333V2.66667H6.66666L25.3333 21.3333H16M6.66666 21.3333H16M6.66666 21.3333L16 30.6667V21.3333" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</svg>
