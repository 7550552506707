import { LinkedList } from './LinkedList';

export class Queue<T> {
  linkedList: LinkedList<T>;
  length: number;

  constructor() {
    this.linkedList = new LinkedList<T>();
    this.length = this.linkedList.length;
  }

  enqueue(value: T): void {
    this.linkedList.append(value);
  }

  dequeue(): T | undefined {
    if (this.linkedList.isEmpty()) {
      return undefined;
    }
    return this.linkedList.popFront();
  }

  isEmpty(): boolean {
    return this.linkedList.isEmpty();
  }

  peek(): T | undefined {
    if (this.isEmpty()) {
      return undefined;
    }
    return this.linkedList.peekFront();
  }
}
