import { v4 as uuid4 } from "uuid";

export const UID = (mark='_') => {
  return (Date.now() + Math.random() + '').replace('.', mark);
};

export default UID;


const objectsUidsMap = new Map();

export function objectUid(item){
  return objectsUidsMap.get(item) || (function(){
    const uid = uuid4();

    objectsUidsMap.set(item, uid);

    return uid;

  })();
}