import { accountStore } from './AccountStore';
import { homeStore } from './HomeStore';
import { CONTEXT_GLOBAL } from '../globals';

export const getHomeId = () => {
  const homeId = homeStore.getState().home?.uid;

  if (CONTEXT_GLOBAL?.hardCodedHomeId) {
    return CONTEXT_GLOBAL?.hardCodedHomeId;
  }

  return homeId;
};

export const getAccountId = () => {
  const accountId = accountStore.getState().accountId;

  if (CONTEXT_GLOBAL?.hardCodedAccountId) {
    return CONTEXT_GLOBAL?.hardCodedAccountId;
  }

  return accountId;
};

export const getIsHomeLoaded = () => {
  const homeStatus = homeStore.getState().homeDataStatus;

  return homeStatus === 'success';
};