// import { Pagination, apiRequest } from "@ubiety/fe-api";
// import { AxiosResponse } from "axios";

import { FnQueueWithRetries } from '../classes';

type GetAllListDataParam = {
  apiParams?: Record<string, any>
  limit?: number;
  maxRetries?: number;
}

export const getListAllData = async <T extends (...args: any) => Promise<any>, R>(
  {
    fn,
    payload,
    queque
  }: GetAllListDataParam & {
    fn: T;
    payload: Parameters<T>[0],
    queque: FnQueueWithRetries
  }
): Promise<Array<R> | R | undefined> => new Promise((resolve) => {
  const limit = payload.limit || 100;
  let isArray: undefined | boolean = undefined;
  const allResponsesArray: Array<R> = [];
  let allResponsesObject = {} as R;
  let responseCounter = 0;
  let responseTarget: number | undefined = undefined;

  const processResponse = (resp: any) => {
    const respData = resp?.data?.data;
    if (Array.isArray(respData) && isArray === undefined) {
      isArray = true;
    }
    if (isArray && respData) {
      allResponsesArray.push(...respData);
    } else {
      if (respData) {
        allResponsesObject = { ...allResponsesObject, ...respData };
      }
    }
  };

  const getTotal = (resp: any) => {
    return resp?.data?.pagination?.total_records;
  };

  queque.addRequest({
    fn,
    args: [{ ...payload, limit, offset: 0 }],
    onResolve: (resp) => {
      processResponse(resp);
      const total = getTotal(resp);

      responseCounter++;
      responseTarget = Math.ceil(total / limit);

      if (responseTarget < 2) {
        return resolve(isArray ? allResponsesArray : allResponsesObject);
      }
      for (let i = 1; i < responseTarget; i++) {
        queque.addRequest({
          fn,
          args: [{ ...payload, limit, offset: i * limit }],
          onResolve: (resp) => {
            processResponse(resp);
            responseCounter++;
            if (responseCounter === responseTarget) {
              resolve(isArray ? allResponsesArray : allResponsesObject);
            } 
          }
        });
      }
    }
  });
});