import {useEffect} from 'react';

import {
  perSessionStorage
} from 'storage';
import { useFirebaseStore } from 'store';

export const EventsHandler: React.FC = () => {
  const { user: fbUser } = useFirebaseStore(s => s);

  // clear local storage if user empty
  useEffect(function(){
    if(!fbUser){
      perSessionStorage.clear();
    }
  },[fbUser]);

  return null;
};
