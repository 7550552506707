import {
  getUser as getUserData,
  updateUser as updateUserApi,
  deleteUser as deleteUserApi,
  UpdateUserPayload,
  logChangedUserDetails,
  UserResponseData,
} from '@ubiety/fe-api';
import { createStore } from 'zustand/vanilla';

// import { persist } from 'zustand/middleware';
import { ContextError } from '../classes';
import { fnSilentWrapper, withCatcher , processRawUser } from '../helpers';
import { DataStatus, Maybe, PromiseVoid, User } from '../types';

interface IAccountState {
  user?: User;
  accountId?: string;
  fbUserId?: string;
  realoadTimestamp: number;
  userDataStatus: DataStatus;
}

type AccountAction = {
  reset: () => void;
  setFbUserId: (uid: string | undefined) => void;
  getUser: () => Promise<User | undefined>;
  getUserSilently: () => Promise<Maybe<boolean>>;
  deleteUser: () => PromiseVoid;
  deleteUserSilently: () => Promise<Maybe<boolean>>;
  updateUser: (payload: UpdateUserPayload) => Promise<UserResponseData>;
  updateUserSilently: (payload: UpdateUserPayload) => Promise<Maybe<boolean>>;
};

export type AccountState = IAccountState & AccountAction;

const INITIAL_STATE: IAccountState = {
  fbUserId: undefined,
  user: undefined,
  accountId: undefined,
  userDataStatus: undefined,
  realoadTimestamp: Date.now(),
};

export const accountStore = createStore<AccountState>()(
  // persist(
    (set, get) => ({
      ...INITIAL_STATE,
      reset: () => set(INITIAL_STATE),
      setFbUserId: (uid) => set({ fbUserId: uid }),
      getUser: withCatcher('accountStore.getUser', async () => {
        const userId = get().fbUserId;
        if (!userId) {
          throw new ContextError('No account id to get user', {isLocal: true});
        }

        const userDataResponse = await getUserData(userId);
        const userData = processRawUser(userDataResponse?.data);

        set({ user: userData, accountId: userData?.accountId, userDataStatus: 'success' });

        return userData;
      }, (e) => {
        if (e.isLocal) {return;}
        set({ userDataStatus: 'error' });
      }),
      getUserSilently: fnSilentWrapper(() => get().getUser()),
      updateUser: withCatcher('accountStore.updateUser', async (payload: UpdateUserPayload) => {
        const { uid, onboardingSteps } = get().user || {};
        if (!uid) {
          throw new ContextError('No user id to update user', {isLocal: true});
        }

        if (payload?.onboarding_steps) {
          payload.onboarding_steps = { ...onboardingSteps, ...payload.onboarding_steps };
        }

        const resp = await updateUserApi(uid, payload);

        const updatedUser = processRawUser(resp.data);

        set({ user: updatedUser, userDataStatus: 'success' });
        logChangedUserDetails({
          userUid: `${uid}`,
          accountUid: get().accountId,
        });

        return resp.data;
      }, (e) => {
        if (e.isLocal) {return;}
        set({ userDataStatus: 'error' });
      }),
      updateUserSilently: fnSilentWrapper((payload: UpdateUserPayload) => get().updateUser(payload)),
      deleteUser: withCatcher('accountStore.deleteUser', async () => {
        const userId = get().fbUserId;

        if (!userId) {
          throw new ContextError('[AccountStore] Delete user called but there is no loged in user', {isLocal: true});
        }
        await deleteUserApi(userId);
      }),
      deleteUserSilently: fnSilentWrapper(() => get().deleteUser()),
    }),

  //   {
  //     name: 'account-storage',
  //   },
  // ),
);
