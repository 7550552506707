import React from 'react';
import {IconProps} from './_iconProps';

export const MoreHorizontal: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15.4167 16.1667C15.8309 16.1667 16.1667 15.8309 16.1667 15.4167C16.1667 15.0025 15.8309 14.6667 15.4167 14.6667C15.0025 14.6667 14.6667 15.0025 14.6667 15.4167C14.6667 15.8309 15.0025 16.1667 15.4167 16.1667Z" fill="#FEFAE3"/>
<path d="M24.75 16.1667C25.1642 16.1667 25.5 15.8309 25.5 15.4167C25.5 15.0025 25.1642 14.6667 24.75 14.6667C24.3358 14.6667 24 15.0025 24 15.4167C24 15.8309 24.3358 16.1667 24.75 16.1667Z" fill="#FEFAE3"/>
<path d="M6.08334 16.1667C6.49756 16.1667 6.83334 15.8309 6.83334 15.4167C6.83334 15.0025 6.49756 14.6667 6.08334 14.6667C5.66913 14.6667 5.33334 15.0025 5.33334 15.4167C5.33334 15.8309 5.66913 16.1667 6.08334 16.1667Z" fill="#FEFAE3"/>
<path d="M15.4167 16.1667C15.8309 16.1667 16.1667 15.8309 16.1667 15.4167C16.1667 15.0025 15.8309 14.6667 15.4167 14.6667C15.0025 14.6667 14.6667 15.0025 14.6667 15.4167C14.6667 15.8309 15.0025 16.1667 15.4167 16.1667Z" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
<path d="M24.75 16.1667C25.1642 16.1667 25.5 15.8309 25.5 15.4167C25.5 15.0025 25.1642 14.6667 24.75 14.6667C24.3358 14.6667 24 15.0025 24 15.4167C24 15.8309 24.3358 16.1667 24.75 16.1667Z" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
<path d="M6.08334 16.1667C6.49756 16.1667 6.83334 15.8309 6.83334 15.4167C6.83334 15.0025 6.49756 14.6667 6.08334 14.6667C5.66913 14.6667 5.33334 15.0025 5.33334 15.4167C5.33334 15.8309 5.66913 16.1667 6.08334 16.1667Z" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</svg>
