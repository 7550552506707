import axios, { AxiosRequestConfig, AxiosResponse, RawAxiosRequestHeaders } from 'axios';
import axiosRetry, { IAxiosRetryConfig } from 'axios-retry';
import qs from 'qs';

import {ENV_HOLDER, apiListeners} from './env';

export const STATE_INTERCEPTED_HOLDER = {
  current: false
};

/* istanbul ignore next */
const customSerializer = (params: any) =>
  qs.stringify(params, { arrayFormat: 'comma' });

let _total_responses = 0;
let _total_requests = 0;
let _total_errors = 0;

export type API_SERVICE =
  | 'accountManagement'
  | 'accountManagementV3'
  | 'dataAPI'
  | 'eckleburgAPI'
  | 'logsLambda'
  | 'partnersApi'
  | 'pushServiceApiV2'
  | 'homeApi'
  | 'snowflakeConnector'
  | 'feApiGateway';

export type ApiRequestData = AxiosRequestConfig & {
  service?: API_SERVICE;
  path?: string;
  baseUrl?: string;
  withFirebaseToken?: boolean;
  withAuth?: boolean;
  withRetries?: boolean;
  retryConfig?: IAxiosRetryConfig;
  withCredentials?: boolean;
  // this is to handle flask endpoints that are an exception to the standard we're heading towards
  // Gets with params on flask endpoints need to set this to be true
  dontUseCustomSerializer?: boolean;
};

export type ApiResponse<T> = Promise<AxiosResponse<T, any>>;
export const apiRequest = async <T>(apiData: ApiRequestData) => {
  if(STATE_INTERCEPTED_HOLDER.current){
    throw new Error('intercepted')
  }
  const {
    service,
    path,
    headers,
    url,
    withFirebaseToken = false,
    withAuth = true,
    withRetries = true,
    withCredentials = false,
    retryConfig,
    dontUseCustomSerializer,
    ...rest
  } = apiData;
  let targetUrl = '', authConfing = {};
  const requestNumber = ++_total_requests;
  try {
    targetUrl = url ? url : buildApiUrl({ service, path });
    const newConfig: AxiosRequestConfig & {headers: RawAxiosRequestHeaders} = {
      headers: { 
        'Content-Type': 'application/json',
        ...(headers||{})
      },
      // This was added because of the way fastapi parses serialized arrays
      // https://github.com/tiangolo/fastapi/issues/50#issuecomment-760306781
      paramsSerializer: dontUseCustomSerializer ? undefined : customSerializer,
    }
    //retries
    if (withRetries) {
      axiosRetry(axios, {
        retryDelay: axiosRetry.exponentialDelay,
        retries: ENV_HOLDER.settings.defaultRetries||0,
        ...retryConfig,
      });
    } else {
    //clear retry
    }
    // withCreds option
    if (withCredentials||ENV_HOLDER.settings?.withCredentials){
      newConfig.withCredentials = true
    }

    // populate firebase token
    if (withFirebaseToken||withAuth) {

      const authPayload = await ENV_HOLDER.settings.getUserIdAndToken();
      if (withAuth && service){
        authConfing = getServiceAuthConfig(service, authPayload);
      }
      if (withFirebaseToken){
        if (service === 'feApiGateway') {
          newConfig.headers['UBIETY-USER-ID'] = authPayload.uid;
          newConfig.headers['UBIETY-USER-TOKEN'] = authPayload.token;
        } else {
          newConfig.headers.uid = authPayload.uid;
          newConfig.headers.token = authPayload.token;
        }
      }
    }

    // populate custom auth headers
    if(ENV_HOLDER.settings.getAuthHeaderValues){
      const authHeader = await ENV_HOLDER.settings.getAuthHeaderValues?.();
      for (let key in authHeader){
        newConfig.headers[key] = (authHeader as any)[key];
      }
    }

    
    const requestPayload = {
      ...rest,
      ...authConfing,
      ...newConfig,
      headers: {
        ...((authConfing as any).headers||{}),
        ...(newConfig.headers||{}),
      },
      url: targetUrl
    };

    if(ENV_HOLDER.settings.logApiCalls){
      console.log(`[API] new request [${requestNumber}] - ${rest.method} ${targetUrl}:`, {data: rest.data, params: rest.params}, newConfig);
    }

    apiListeners.onRequest.fire(requestPayload as any);
    
    const response = await axios.request<T>(requestPayload);

    apiListeners.onResponse.fire(response as any);

    if(ENV_HOLDER.settings.logApiCalls){
      console.log(`[API] response for [${requestNumber}], ${++_total_responses+_total_errors}/${_total_requests}) - ${targetUrl}:`, response);
    }
    return response;

  } catch (e: any) {
    // handle logging
    const resp = (e as any).response;
    const filteredHeaders = {...((authConfing as any).headers||{})};

    if(filteredHeaders.hasOwnProperty('admin_secret')){
      filteredHeaders['admin_secret'] = '***hidden***'
    }
    if(filteredHeaders.hasOwnProperty('admin-secret')){
      filteredHeaders['admin-secret'] = '***hidden***'
    }
    const errorDetails = {
      targetUrl,
      headers: filteredHeaders,
      payload: rest.data,
      params: rest.params,
      status: resp?.status,
      response: JSON.stringify(resp?.data),
      message: String(e?.message)
    }

    ENV_HOLDER.settings.logError(e, errorDetails);
    if(ENV_HOLDER.settings.logApiCalls){
      console.log(`[API] Error at [${requestNumber}], (${++_total_errors} tot)`, {apiData, e, url:targetUrl, authConfing, errorDetails});
    }
    throw e;
  }
};

type buildApiUrlData = {
  service?: API_SERVICE;
  path?: string;
};
export const buildApiUrl = (d: buildApiUrlData) => {
  const { service, path } = d;
  if(!ENV_HOLDER.current){
    throw new Error('No env found for api. Make sure to initialize ahead of the call');
  }
  switch (service) {
    case 'accountManagement':
      return `${ENV_HOLDER.current.acctMgmtApi}${path}`;
    case 'accountManagementV3':
      return `${ENV_HOLDER.current.acctMgmtApiV3}${path}`;
    case 'dataAPI':
      return `${ENV_HOLDER.current.dataApiUrl}${path}`;
    case 'eckleburgAPI':
      return `${ENV_HOLDER.current.eckleburgApiUrl}${path}`;
    case 'partnersApi':
      return `${ENV_HOLDER.current.partnersApiUrl}${path}`;
    case 'pushServiceApiV2':
      return `${ENV_HOLDER.current.pushServiceApiV2Url}${path}`;
    case 'logsLambda':
      return `${ENV_HOLDER.current.logs_lambda_base_url}${path}`;
    case 'homeApi':
      return `${ENV_HOLDER.current.homeApiUrl}${path}`;
    case 'snowflakeConnector':
      return `${ENV_HOLDER.current.snowflakeConnectorUrl}${path}`;
    case 'feApiGateway':
      return `${ENV_HOLDER.current.frontendApiGateway}${path}`
    default:
      throw new Error('Invalid Service Provided');
  }
};

export const getServiceAuthConfig = (service: API_SERVICE, firbaseAuth: {
  uid: string;
  token: string;
}) => {
  const axiosConfig: AxiosRequestConfig & {headers: RawAxiosRequestHeaders} = {headers:{}};
  const {
    uid,
    token
  } = firbaseAuth;
  if(!ENV_HOLDER.current){
    throw new Error('No api env found. Maki sure api is initiated ahead of making the call');
  }
  switch (service) {
    case 'accountManagement':
    case 'accountManagementV3':
      axiosConfig.headers['X-API-KEY'] = ENV_HOLDER.current.acctMgmtApiKey;
      if(ENV_HOLDER.current.account_management_api_admin_secret){
        axiosConfig.headers['admin_secret'] = ENV_HOLDER.current.account_management_api_admin_secret;
        break;
      }
      axiosConfig.headers.uid = uid;
      axiosConfig.headers.token = token;
      break;
    case 'homeApi':
      if(ENV_HOLDER.current.home_api_admin_secret){
        axiosConfig.headers.admin_secret = ENV_HOLDER.current.home_api_admin_secret;
        break;
      }
      axiosConfig.headers.uid = uid;
      axiosConfig.headers.token = token;
      break;
    case 'partnersApi':
      //newConfig.headers['X-API-KEY'] = '';
      //newConfig.headers['X-API-SECRET'] = '';
      if(ENV_HOLDER.current.partners_api_admin_secret){
        axiosConfig.headers['admin-secret'] = ENV_HOLDER.current.partners_api_admin_secret;
        break;
      }
      axiosConfig.headers['app-read-only-key'] = ENV_HOLDER.current.partners_api_read_token;
      axiosConfig.headers.uid = uid;
      axiosConfig.headers.token = token;
      break;
    case 'dataAPI':
      // this makes node based api calls work
      // if(axiosConfig.data === undefined){
      //   axiosConfig.data = "{}";
      // }
      if(ENV_HOLDER.current.data_api_admin_secret){
        axiosConfig.headers['admin-secret'] = ENV_HOLDER.current.data_api_admin_secret;
        break;
      }
      break;
    case 'pushServiceApiV2':
      if(ENV_HOLDER.current.push_api_admin_secret){
        axiosConfig.headers['admin_secret'] = ENV_HOLDER.current.push_api_admin_secret;
        break;
      }
      break;
    case 'snowflakeConnector':
      if(ENV_HOLDER.current.snowflake_connector_admin_secret){
        axiosConfig.headers['admin_secret'] = ENV_HOLDER.current.snowflake_connector_admin_secret;
        break;
      }
      axiosConfig.headers.uid = uid;
      axiosConfig.headers.token = token;
      break;
    case 'eckleburgAPI':
    case 'logsLambda':
      if(ENV_HOLDER.current.logs_lambda_admin_secret){
        axiosConfig.headers['admin_secret'] = ENV_HOLDER.current.logs_lambda_admin_secret;
        break;
      }
      axiosConfig.headers.uid = uid;
      axiosConfig.headers.token = token;
      break;
    case 'feApiGateway': 
      if (ENV_HOLDER.current.frontend_api_gateway_secret) {
        axiosConfig.headers['admin_secret'] = ENV_HOLDER.current.frontend_api_gateway_secret;
        break;
      }
      axiosConfig.headers['UBIETY-USER-ID'] = uid;
      axiosConfig.headers['UBIETY-USER-TOKEN'] = token;
      break;
    default:
      break;
  }

  return axiosConfig;
}
