export const shaddowStyles = {
  small: `
    /* Small Shadow */
    box-shadow: 0px 0px 8px 0px rgba(20, 20, 20, 0.10), 0px 0px 1px 0px rgba(20, 20, 20, 0.12);
  `,
  medium: `
    /* Medium Shadow */
    box-shadow: 0px 1px 11px 4px rgba(20, 20, 20, 0.10), 0px 0px 1px 0px rgba(20, 20, 20, 0.12);
  `,
  large: `
    /* Large Shadow */
    box-shadow: 0px 1px 20px 7px rgba(20, 20, 20, 0.12), 0px 0px 1px 0px rgba(20, 20, 20, 0.10);
  `
}