import React from 'react';
import {IconProps} from './_iconProps';

export const RotateCcw: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1.33333 5.33335V13.3333M1.33333 13.3333H9.33333M1.33333 13.3333L7.52 7.52001C9.36122 5.68191 11.7499 4.49197 14.3262 4.1295C16.9025 3.76703 19.5269 4.25166 21.8038 5.51037C24.0807 6.76907 25.8869 8.73367 26.9502 11.1081C28.0135 13.4826 28.2763 16.1383 27.699 18.6752C27.1217 21.212 25.7356 23.4925 23.7495 25.173C21.7634 26.8536 19.285 27.8431 16.6876 27.9926C14.0902 28.1421 11.5146 27.4434 9.34888 26.0018C7.18312 24.5603 5.54453 22.4539 4.68 20" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</svg>
