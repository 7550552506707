import React from 'react';
import {IconProps} from './_iconProps';

export const Sliders: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5.33334 28V18.6667M5.33334 13.3333V4M16 28V16M16 10.6667V4M26.6667 28V21.3333M26.6667 16V4M1.33334 18.6667H9.33334M12 10.6667H20M22.6667 21.3333H30.6667" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</svg>
