import React, {useState, useEffect, useMemo} from 'react';

import {
  Button
} from 'components/Button';
import styled from 'styled-components';

export const Pagination:React.FC<{
  totalPages: number,
  currentPage?: number,
  onPageChange?: (p: number) => void,
  displaySize?: number // number of positions to show
  className?: string;
  alwaysShowNext?: boolean;
  onNextClick?: () => void;
  onDotsNextClick?: () => void;
  alwaysShowLeftDots?: boolean;
  showNoLast?: boolean;
}> = ({
  totalPages,
  currentPage: passedCurrentPage=0,
  onPageChange,
  displaySize=6,
  className,
  alwaysShowNext,
  onNextClick,
  alwaysShowLeftDots,
  onDotsNextClick,
  showNoLast
}) => {
  const [currentPage, setCurrentPage] = useState<number>(0);
  
  useEffect(function handlePageChange(){
    setCurrentPage(passedCurrentPage);
  },[passedCurrentPage]);

  const buttonsValues = useMemo(() => {
    const pageNumbers = [...Array(totalPages||0)]?.map((_,i)=>i);
    const values = (totalPages <= displaySize)
    ?
    [...pageNumbers]
    :
    (currentPage < displaySize-2) 
    ? // 12345...
    [...pageNumbers.slice(0, displaySize-1), '...']
    :
    (currentPage >= (totalPages-(displaySize-2)))
    ? // 1...5678
    [0,'...',...pageNumbers.slice((pageNumbers.length-(displaySize-2)), pageNumbers.length)]
    :
    (currentPage >= (displaySize-2)) 
    ? // 1...345...
    [0,'...',...pageNumbers.slice(currentPage-1,currentPage+2),'...']
    :
    [];
    if(alwaysShowNext && values[values.length-1] !== '...'){
      values.push('...')
    }
    if(onDotsNextClick && values[values.length-1] === '...'){
      values.splice(values.length-1, 1, '---');
    }
    return values;
  },[
    displaySize,
    totalPages,
    currentPage,
    alwaysShowNext,
    onDotsNextClick
  ]);

  return <PaginationContainer className={'pagination '+(className||'')}>
    {buttonsValues?.map((v,i) => 
      <Button 
        key={v+'-'+i} 
        variant='plain'
        selected={currentPage === v}
        onClick={() => {
          // handle special type of dots
          if(v === '---'){
            onDotsNextClick?.();
          } else {
            const val = Number(v);
            setCurrentPage(val);
            onPageChange?.(val);
          }
        }} 
        disabled={v==='...'}>{typeof v === 'number' ? v+1 : v==='---' ? '...' : v}</Button>
      )}
    {/* has next / last logic */}
    {
      currentPage+1 < totalPages || alwaysShowNext ?
      <Button variant='secondary' arrowRight onClick={() => {
        const nextPage = currentPage+1;
        if(nextPage<totalPages){
          setCurrentPage(currentPage+1);
          onPageChange?.(nextPage);
        }
        onNextClick?.();
      }} />
      : null
    }
    {
      currentPage+1 < totalPages && totalPages > displaySize && !showNoLast ?
      <Button variant='secondary' arrowRight onClick={() => {
        const lastPage = totalPages-1;
        setCurrentPage(lastPage)
        onPageChange?.(lastPage);
      }}>last</Button>
      : null
    }
  </PaginationContainer>
}

const PaginationContainer = styled.div`${({theme}) => `
  > *:not(:first-child) {
    margin-left: ${theme.boxMargins.base*0.8}px;
  }
`}`;