import React from 'react';
import {IconProps} from './_iconProps';

export const Watch: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M16 12V16L18 18M22.0133 23.1333L21.5467 28.24C21.4866 28.9049 21.1793 29.523 20.6856 29.9723C20.1919 30.4216 19.5476 30.6694 18.88 30.6667H13.1067C12.4391 30.6694 11.7948 30.4216 11.3011 29.9723C10.8073 29.523 10.5001 28.9049 10.44 28.24L9.97333 23.1333M9.98667 8.86667L10.4533 3.76C10.5132 3.09744 10.8186 2.48118 11.3095 2.0322C11.8004 1.58323 12.4414 1.33396 13.1067 1.33333H18.9067C19.5742 1.33063 20.2185 1.5784 20.7123 2.0277C21.206 2.477 21.5133 3.09514 21.5733 3.76L22.04 8.86667M25.3333 16C25.3333 21.1547 21.1547 25.3333 16 25.3333C10.8453 25.3333 6.66667 21.1547 6.66667 16C6.66667 10.8453 10.8453 6.66667 16 6.66667C21.1547 6.66667 25.3333 10.8453 25.3333 16Z" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</svg>
