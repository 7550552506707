import { useCallback, useRef, useState } from 'react';

import { useAlertsStore } from '../hooks';
import { IndexedSensorAlert } from '../types/alerts';

type Data = {
  timeStart: number;
  timeEnd?: number;
};

type State = {
  loaded: boolean,
  alerts?: Array<IndexedSensorAlert>,
  error?: boolean
};

export const useSensorAlerts = (data: Data) => {
  const { timeStart, timeEnd } = data;

  const getSensorOnlineOfflineTimeframeAlerts = useAlertsStore((state) => state.getSensorOnlineOfflineTimeframeAlerts);
  const [sensorOfflinePeriods, setSensorOfflinePeriods] = useState<Array<[number, number]>>([]/*, {moduleName: 'useSensorAlerts', stateName: 'sensorOfflinePeriods'}*/);

  const [sensorTimeframeAlertsState, setSensorTimeframeAlertsState] = useState<State>({ loaded: false }/*, {moduleName: 'useSensorAlerts', stateName: 'sensorTimeframeAlertsState'}*/);

  const instanceUpdatedRef = useRef<number>(0);

  const getSensorTimeframeAlerts = useCallback(async function () {
    try {
      const alerts = await getSensorOnlineOfflineTimeframeAlerts({
        timeStart,
        timeEnd: timeEnd || Date.now()
      });

      if (alerts) {
        const newPeriods = [];
        let curPeriod: [number?, number?] = [];
        // process alerts to a timeline data
        for (let i = alerts.length - 1; i > -1; i--) {
          const a = alerts[i];
          if (a.contributingFactors?.status === 'offline' ||
            (a.contributingFactors?.status === 'online' && curPeriod.length)) {
            curPeriod.push(a.createdAt * 1000);
          }

          if (curPeriod.length === 2) {
            newPeriods.push(curPeriod as [number, number]);
            curPeriod = [];
          }
        }

        // close out the pediod with now, 
        // if no correcponding online event have been found
        if (curPeriod.length) {
          curPeriod.push(Date.now() + 5 * 60 * 1000);
          newPeriods.push(curPeriod as [number, number]);
        }

        console.log('[useSensorAlerts] derived periods', alerts, newPeriods);
        setSensorOfflinePeriods(newPeriods);
        setSensorTimeframeAlertsState({ alerts, loaded: true });
        instanceUpdatedRef.current = Date.now();
      } else {
        setSensorTimeframeAlertsState({ loaded: true });
      }
    }
    catch (e) {
      setSensorTimeframeAlertsState({ loaded: false, error: true });
      console.log('[useSensorAlerts] error loading sensor alerts', e);
    }

  }, [
    setSensorOfflinePeriods,
    setSensorTimeframeAlertsState,
    timeStart,
    timeEnd,
    getSensorOnlineOfflineTimeframeAlerts
  ]);

  const clear = useCallback(function () {
    setSensorTimeframeAlertsState({
      loaded: false
    });
  }, [
    setSensorTimeframeAlertsState
  ]);

  return {
    getSensorTimeframeAlerts,
    sensorTimeframeAlerts: sensorTimeframeAlertsState.alerts,
    sensorTimeframeAlertsLoaded: sensorTimeframeAlertsState.loaded,
    sensorTimeframeAlertsError: sensorTimeframeAlertsState.error,
    sensorOfflinePeriods,
    clear
  };
};