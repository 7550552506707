import React from 'react';

import styled from 'styled-components';

import {UID} from 'utils';

import styles from './Input.module.css';

export type TextProps = {
  type?:string;
  name?:string;
  placeholder?:string;
  defaultValue?:string;
  value?:string;
  id?:string;
  className?:string;
  disabled?: boolean;
  el?: React.MutableRefObject<HTMLInputElement>;

  status?: 'accepted' | 'bad' | 'unfulfilled' | undefined;

  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}
// Input types
export const Text: React.FC<TextProps> = ({
  type='text',
  name='',
  placeholder,
  defaultValue,
  value,
  id=UID(),
  className='',
  disabled=false,
  el=null,
  status,

  onChange,
}) =>

  <StyledInput

    key={id}
    id={id}
    className={className+' '+styles.input}
    ref={el||null}

    disabled={disabled}
    type={type}
    name={name}
    placeholder={placeholder}
    value={value}
    defaultValue={defaultValue}

    status={status}

    onChange={onChange}

    />;


const StyledInput = styled.input.attrs({} as {
  status: string;
})`${({theme, status}) => `
  border: 1px solid ${theme.newColors.MidnightShores};
  padding: ${theme.fontPadding.s}px ${theme.fontPadding.m}px;
  border-radius: ${theme.fontPadding.base*0.25}px;
  ${theme.fontStyles.field}
  color: ${theme.newColors.MidnightShores};
  background-color: ${theme.newColors.SwissCream};

  &::placeholder {
    color: ${theme.colors.ink.lighter};
  }
  &:-ms-input-placeholder{
    color: ${theme.colors.ink.lighter};
  }
  &::-ms-input-placeholder {
    color: ${theme.colors.ink.lighter};
  }

  outline: none;
  outline-offset: -2px;
  outline-width: 2px;

  &:focus {
    box-shadow: 0 0 10px rgba(${
      (!status && theme.colorsRgb.newColors.information.toString())
      || (status === 'accepted' && theme.colorsRgb.newColors.success.toString())
      || (status === 'unfulfilled' && theme.colorsRgb.newColors.warning.toString())
      || (status === 'bad' && theme.colorsRgb.newColors.error.toString()) }, 0.7);
  }

  ${!status ? `
    outline-color: rgba(${theme.colorsRgb.newColors.MidnightShores.toString()}, 0.7);
  ` : ''}
  ${status === 'accepted' ? `
    outline-color: rgba(${theme.colorsRgb.newColors.success.toString()}, 0.7);
    outline-style: solid;
  ` : ''}

  ${status === 'unfulfilled' ? `
    outline-color: rgba(${theme.colorsRgb.newColors.warning.toString()}, 0.7);
    outline-style: solid;
  ` : ''}

  ${status === 'bad' ? `
    outline-color: rgba(${theme.colorsRgb.newColors.error.toString()}, 0.7);
    outline-style: solid;
  `: ''}

  &:focus {
    outline-offset: -2px;
    outline-width: 2px;
    outline-style: solid;

    ${!status ? `
      outline-color: rgba(${theme.colorsRgb.blue.base.toString()}, 0.5);
    `:''}
  }
`}`;
