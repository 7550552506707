import React from 'react';
import {IconProps} from './_iconProps';

export const CloudOff: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_149_272)">
<path d="M30.1467 22.6C30.5762 21.5857 30.7478 20.4808 30.6461 19.384C30.5443 18.2873 30.1724 17.2328 29.5635 16.3149C28.9546 15.397 28.1278 14.6442 27.157 14.1239C26.1861 13.6036 25.1015 13.332 24 13.3333H22.32C21.7757 11.19 20.5788 9.26875 18.8947 7.83552C17.2107 6.40229 15.1228 5.52789 12.92 5.33331M6.66667 6.66665C4.5841 7.81816 2.94641 9.63302 2.01412 11.8225C1.08182 14.012 0.908469 16.4504 1.52163 18.7498C2.1348 21.0491 3.49926 23.0774 5.39798 24.512C7.29669 25.9466 9.62059 26.705 12 26.6666H24C24.773 26.6657 25.54 26.5304 26.2667 26.2666M1.33333 1.33331L30.6667 30.6666" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</g>
<defs>
<clipPath id="clip0_149_272">
<rect width={width} height={height} fill="white"/>
</clipPath>
</defs>
</svg>
