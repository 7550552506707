import { CellSignal } from 'hooks';
import {
  ProcessedReportItem
} from './report';
export const processMixinItemToReportItem = (data: CellSignal): (ProcessedReportItem|null) => {
  if(!data.timestamp||!data.cellProvider||!data.power){
    //this is not our mixin
    return null
  }
  return {
    localTimestamp: data.timestamp,
    timestamp: data.timestamp,
    tmsi: data.identifier,
    type: data.type,
    establishmentCause: data.establishmentCause||null,
    provider: data.cellProvider,
    power: data.power
  }
}