import styled from 'styled-components';

import {UID} from 'utils';

export type RadioProps = {
  disabled?: boolean,
  variant?: string,
  checked?: boolean,
  defaultChecked?: boolean|undefined,

  id?: string,

  el?: React.MutableRefObject<HTMLInputElement>,
  name?: string,
  placeholder?: string,
  className?: string,
  value?: string,

  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void,
}

export const Radio: React.FC<RadioProps> = ({
  disabled=false,
  variant,
  checked,
  defaultChecked=undefined,

  id=UID(),

  el=null,
  name,
  className,

  value,

  onChange,

}) => {
  const radio = <StyledInput
    id={id}
    ref={el}
    key={id}
    type="radio"
    name={name}
    checked={checked}
    defaultChecked={defaultChecked}
    onChange={onChange}
    className={className}
    value={value}
    disabled={disabled}
    />


  return variant === 'custom' ?
    <StyledLabel className="input-holder">{radio}
      <svg className="radio-mark" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="9" cy="9" r="8" stroke="#588157" strokeWidth="2"/>
        <circle cx="9" cy="9" r="5" fill="#588157"/>
      </svg>
    </StyledLabel>
  : radio
};

const StyledLabel = styled.label`${({theme}) => `
  > input {
    display: none;
  }

  width: 1.1em;
  height: 1.1em;
  input:not(:checked)+ .radio-mark circle:last-child{
    display: none;
  }

  font-weight: normal;
`}`;

const StyledInput = styled.input`${({theme}) => `
  width: ${theme.fontSize.xl}px;
  height: ${theme.fontSize.xl}px;
  border: 2px solid ${theme.colors.GreenJuice};
  color: ${theme.colors.GreenJuice};
`}`;
