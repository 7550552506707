import React from 'react';
import {IconProps} from './_iconProps';

export const Loader: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M16 2.66667V8M16 24V29.3333M6.57334 6.57333L10.3467 10.3467M21.6533 21.6533L25.4267 25.4267M2.66667 16H8.00001M24 16H29.3333M6.57334 25.4267L10.3467 21.6533M21.6533 10.3467L25.4267 6.57333" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</svg>
