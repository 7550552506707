import React from 'react';
import {IconProps} from './_iconProps';

export const CreditCard: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1.33333 13.3333H30.6667M3.99999 5.33334H28C29.4728 5.33334 30.6667 6.52725 30.6667 8.00001V24C30.6667 25.4728 29.4728 26.6667 28 26.6667H3.99999C2.52724 26.6667 1.33333 25.4728 1.33333 24V8.00001C1.33333 6.52725 2.52724 5.33334 3.99999 5.33334Z" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</svg>
