import React from 'react';
import {IconProps} from './_iconProps';

export const Feather: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M21.3333 10.6667L2.66667 29.3333M23.3333 20H12M26.9867 16.32C28.4878 14.8189 29.3311 12.7829 29.3311 10.66C29.3311 8.53708 28.4878 6.50112 26.9867 5C25.4855 3.49887 23.4496 2.65555 21.3267 2.65555C19.2038 2.65555 17.1678 3.49887 15.6667 5L6.66667 14V25.3333H18L26.9867 16.32Z" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</svg>
