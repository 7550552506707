import {ReactNode} from 'react'
import styled, {css, keyframes} from 'styled-components';
import {
  Zap,
  Close
} from 'theme/icons';

export type WarningProps = {
  title?: string;
  details?: string;
  onClose?: () => void;
  children?: ReactNode;
};
export const Warning: React.FC<WarningProps> = ({
  title,
  details,
  children, 
  onClose
}) => {
  return <StyledWarning>
    <div className="icon-holder"><Zap /></div>
    <div className="warning-body">
      <div className="close-button" onClick={() => onClose?.()}>Close <Close /></div>
      {title? 
        <h2>{title}</h2>
      :null}
      {details? 
        <p>{details}</p>
      :null}
      {children}
    </div>
  </StyledWarning>
}

const appear = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`

const StyledWarning = styled.div`${({theme})=> css`
  display: flex;
  background-color: ${theme.newColors.warning};
  color: ${theme.newColors.SunsetCrush};
  border-radius: 4px;
  overflow: hidden;

  animation: ${appear} 1000ms;

  .close-button {
    float: right;
    cursor: pointer;
  }
  .icon-holder {
    background-color: ${theme.newColors.SunsetCrush};
    color: ${theme.newColors.warning};
    min-width: 73px;
    min-height: 73px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .icon-holder .icon {
    height: 42px;
    width: 42px;
  }
  .icon-holder .icon path {
    stroke: none;
    fill: ${theme.newColors.warning};
  }
  .warning-body {
    padding: ${theme.boxMargins.base}px ${theme.boxMargins.l}px;
    flex-grow: 1;
  }
  .warning-body p {
    margin: 0;
  }
`}`;