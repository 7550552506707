import React from 'react';
import {IconProps} from './_iconProps';

export const Sunset: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M22.6667 24C22.6667 22.2319 21.9643 20.5362 20.714 19.286C19.4638 18.0357 17.7681 17.3334 16 17.3334C14.2319 17.3334 12.5362 18.0357 11.286 19.286C10.0357 20.5362 9.33333 22.2319 9.33333 24M16 12V2.66669M16 12L21.3333 6.66669M16 12L10.6667 6.66669M5.62667 13.6267L7.52 15.52M1.33333 24H4M28 24H30.6667M24.48 15.52L26.3733 13.6267M30.6667 29.3334H1.33333" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</svg>
