import { Maybe } from '../types';

export interface IGlobalContext {
  logError: (e: unknown, name?: string) => void;
  logStateChange: (moduleName: string, state: any) => void;
  getInstalledAppVersion: () => string;

  userId: Maybe<string>;
  hardCodedHomeId: Maybe<number>;
  hardCodedAccountId: Maybe<string>;
  
  // 
  consoleErrors?: boolean,
}

export const CONTEXT_GLOBAL: IGlobalContext = {
  logError: (e, name) => {
    // error logging of the project choice, for example Sentry
    console.log('[logError] {e, name}: ', {e, name});
  },
  logStateChange: (moduleName, state) => {
    console.log('[logStateChange] {moduleName, state}: ', {moduleName, state});
    // logging of project choice, for example what we use to preserve sessions
  },
  getInstalledAppVersion: () => '',
  userId: null,
  hardCodedHomeId: null,
  hardCodedAccountId: null,
  
  consoleErrors: true,
};

