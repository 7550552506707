import {
  apiRequest
} from '../apiRequest';
import {
  AlertSettingsAlertType,
  AlertSettingsSensitivityType
} from '../types';

export type AlertSettings = {
  notification_type: AlertSettingsAlertType;
  uid: string;
  created_at: number;
  sensitivity: AlertSettingsSensitivityType;
  suppressed: boolean;
}

export type AlertSettingsPayload = Partial<AlertSettings>&{
  notification_types: Array<AlertSettingsAlertType>
};

export const getHomesAlertSettings: (home_id: number | string) => Promise<Array<AlertSettings>|null> = async function(home_id){
  const params = {
    home_id
  };
  const resp = await apiRequest<Array<AlertSettings>>({
    service: 'pushServiceApiV2',
    path: '/preferences',
    params,
    method: 'GET'
  });

  //console.log('[getHomesAlertSettings] response received:', resp);

  if(!resp){ return null; }

  return resp.data;
};

export const putAlertSettings: (home_id: number | string, data_payload: AlertSettingsPayload) => Promise<AlertSettings|null> = async function putAlertSettings(home_id, payload){
  if(!Object.entries(payload).length){ return null; }

  const res = await apiRequest<Array<AlertSettings>>({
    service: 'pushServiceApiV2',
    path: '/preferences?home_id='+home_id,
    data: payload,
    method: 'PUT'
  });

  //console.log(`[putAlertSettings] response for setting payload on ${home_id} received:`, {payload, res});
  if(!res){ return null; }

  return res.data[0];
};
