import { useMemo, useRef, useState, useEffect, useCallback } from 'react';

import {
  eachWeekOfInterval,
  startOfMonth,
  endOfMonth,
  nextDay,
  getWeekOfMonth,
  startOfWeek,
  endOfWeek,
  getDate,
  getMonth,
  getYear,
  Day } from 'date-fns';
import {
  FiChevronLeft,
  FiChevronRight,
  FiCalendar
} from 'react-icons/fi';
import styled from 'styled-components';

import {SelectBox} from 'components';
import {
  usePrevious
} from 'hooks';
import {
  perSessionStorage
} from 'storage';

import {
  makeDateObj
} from './index';

export type DatePickerApi = {
  selectedDate: string|null;
  setSelectedDate: (value: string) => void;
  selectedWeek: number|null;
  setSelectedWeek: (value: number) => void;
  selectedMonth: number;
  setSelectedMonth: (value: number) => void;
  selectedYear: number;
  setSelectedYear: (value: number) => void;
  flipMonth: (plusminusvalue: number) => void;
  flipYear: (plusminusvalue: number) => void;
}
export type DatePickerPaneProps = {
  initDate?: Date,
  mode?: 'day'|'week'|'month' // 'dayrange',
  value?: Date,
  apiRef?: React.MutableRefObject<DatePickerApi|null>,
  onSelect?: (values: [Date, Date?]) => void,
  onHide?: () => void;
  className?: string;
  onClick?: (e:React.MouseEvent<HTMLDivElement>) => void;

  preserveByName?: string;
  // allow multiple (hold command/ctrl to select multiple)
  // allow range
};

export const DatePickerIcon: React.FC<DatePickerPaneProps & {
  hideOnSelect?: boolean,
  hideDelay?: boolean,
  className?: string,
  outsideTriggerClickedAt?: any, // if not empty will trigger diplaying pane
}> = ({
  hideOnSelect=true,
  hideDelay=true,
  onSelect,
  onHide,
  className,
  outsideTriggerClickedAt=false,
  ...props
}) => {
  const [showPane, setShowPane] = useState<boolean>(false);
  const localPropagationBlockedRef = useRef<boolean>(false);

  useEffect(function(){
    const hidePane = function(){
      if(!localPropagationBlockedRef.current){
        setShowPane(false);
        onHide?.();
      } else {
        localPropagationBlockedRef.current = false;
      }
    };
    window.addEventListener('click', hidePane);

    return function(){
      window.removeEventListener('click', hidePane);
    };
  },[setShowPane, onHide]);

  // set show
  useEffect(function(){
    console.log('[DatePickerIcon] triggering display', !!outsideTriggerClickedAt);
    localPropagationBlockedRef.current = true;
    setShowPane(!!outsideTriggerClickedAt);
  }, [outsideTriggerClickedAt]);

  const handleSelect = useCallback(function handleSelect(values){
    if(hideOnSelect && hideDelay){
      setTimeout(() => setShowPane(false), 200);
    }
    else if(hideOnSelect){
      setShowPane(false);
    }
    onSelect?.(values);
  },[hideOnSelect, hideDelay, onSelect]);

  return <StyledDatePickerIconContainer
    onClick={() => {
      localPropagationBlockedRef.current = true;
    }}
    className={(!showPane?'paneHidden':'')+' '+(className||'')}
    >
    <FiCalendar onClick={() => {
      setShowPane(true);
    }}/>

    <DatePickerPane
      onSelect={handleSelect} {...props} />

  </StyledDatePickerIconContainer>;
};

const currentDate = new Date();
export const DatePickerPane: React.FC<DatePickerPaneProps> = ({
  initDate:passedInitDate,
  mode='day',
  value,
  apiRef={current:null},
  onSelect,
  className,
  children,
  preserveByName,
  ...props
}) => {

  //const [dates, setDates] = useState<Date>(initDate);

  const initDate = useMemo(function(){
    if(passedInitDate && preserveByName){
      perSessionStorage.setItem('datePicker_initDate_'+preserveByName, makeDateObj(passedInitDate).str);
    }

    if(!passedInitDate && preserveByName){
      const preservedDateString = perSessionStorage.getItem('datePicker_initDate_'+preserveByName);
      const preservedDate = preservedDateString && new Date(preservedDateString);
      const isValidDate = preservedDate && !isNaN(Number(preservedDate));

      console.log('[DatePicker Test] preserved date is:', {preservedDate, isValidDate, preservedDateString});

      if(preservedDate && isValidDate){ return preservedDate; }
    }

    return passedInitDate||new Date();
  }, [passedInitDate, preserveByName]);

  const [selectedDate, setSelectedDateState] = useState<string|null>(initDate?makeDateObj(initDate).str:null);

  const setSelectedDate = useCallback(function(value){
    if(preserveByName){
      perSessionStorage.setItem('datePicker_initDate_'+preserveByName, value);
    }
    setSelectedDateState(value);
  },[setSelectedDateState, preserveByName]);

  const [selectedWeek, setSelectedWeek] = useState<number|null>(initDate?getWeekOfMonth(initDate)-1:null);
  const [selectedMonth, setSelectedMonth] = useState<number>(getMonth(initDate||currentDate));
  const [selectedYear, setSelectedYear] = useState<number>(getYear(initDate||currentDate));

  const todaysDateOnCalendar = useMemo(() => {
    if(currentDate.getUTCFullYear() === selectedYear && currentDate.getUTCMonth() === selectedMonth){
      return getDate(currentDate);
    }

      return null;

  }, [selectedMonth, selectedYear]);

  const prevMode = usePrevious(mode);

  useEffect(function setValueToState(){
    if(value){
      setSelectedDate(makeDateObj(value).str);
      setSelectedWeek(getWeekOfMonth(value)-1);
      setSelectedMonth(getMonth(value));
      setSelectedYear(getYear(value));
    }
    else if (value !== undefined){
      setSelectedDate(null);
      setSelectedWeek(null);
    }
  },[value, setSelectedDate]);

  const {months, shortQuarters} = useMemo(function(){
    const months: string[] = [];
    const shortQuarters: string[][] = [[],[],[],[]];

    for (let i = 0, q=0; i < 12; i++) {
      const date = (new Date(2022,i, 1, 0,0,1));
      months.push( date.toLocaleString('default', { month: 'long' }) );

      shortQuarters[q].push( date.toLocaleString('default', { month: 'short' }) );

      if( (i+1) % 4 === 0){ q++; }
    }
    return {
      months,
      shortQuarters
    };
  },[]);

  const years = useMemo(function(){

    const yearStart = 2010; // years since 1900; assuming Ubiety wont have any data older then 2010;
    const yearNow = (new Date()).getUTCFullYear();

    const years: number[] = [];
    for (let i = yearNow; i >= yearStart; i--) {
      years.push(i);
    }
    return years;
  },[]);

  type weeksType = {
    day: number,
    month: number,
    year: number,
    str: string
  }[][]
  const weeksRef = useRef<weeksType>();
  const weeks = useMemo<weeksType>(function(){

    const startOfTheMonth = new Date(selectedYear,selectedMonth,1, 0,0,1);
    const beginningOfWeeks = eachWeekOfInterval({
      start: startOfTheMonth,
      end: endOfMonth(startOfTheMonth)
    });

    const weeks = beginningOfWeeks?.map(d => {
      const week = [makeDateObj(d)];
      for(let i=1; i<7; i++){
        const date = nextDay(d, i as Day);
        week.push(makeDateObj(date));
      }

      return week;
    });
    weeksRef.current = weeks;
    return weeks;

  },[selectedMonth, selectedYear]);

  const clearDateWeek = useCallback(function(){
    setSelectedDate(null);
    setSelectedWeek(null);
  }, [setSelectedDate, setSelectedWeek]);

  const flipMonth = useCallback(function flipMonth(n){
    const indexOfSelectedYear = years.indexOf(selectedYear);

    // NOTE: years are listed in reverse
    // if its the end of the years and there is room to flip
    // -> flip month and year
    if( (selectedMonth+n)>11 && indexOfSelectedYear>0){
      setSelectedMonth(0);
      setSelectedYear(years[indexOfSelectedYear-1]);
      clearDateWeek();
    }
    // if it goes in to previous year and there are years to flip
    // -> flip year and month
    else if( (selectedMonth+n)<0 && years[indexOfSelectedYear+1]){
      setSelectedMonth(11);
      setSelectedYear(years[indexOfSelectedYear+1]);
      clearDateWeek();
    } else if(selectedMonth+n>-1&&selectedMonth+n<12){
      setSelectedMonth(selectedMonth+n);
      clearDateWeek();
    }

  },[selectedMonth, selectedYear, years, clearDateWeek]);

  const flipYear = useCallback(function flipYear(n){
    const indexOfSelectedYear = years.indexOf(selectedYear);
    if( years[indexOfSelectedYear+n] ){
      setSelectedYear(years[indexOfSelectedYear+n]);
      clearDateWeek();
    }
  },[selectedYear, years, clearDateWeek]);

  // manage dates selection
  const selectDay = useCallback(function(str: string | Date){

    if(mode==='day' && onSelect){
      const dateDate = new Date(str);
      onSelect([dateDate]);
    }

  },[mode, onSelect]);

  const selectWeek = useCallback(function(week){
    const curWeeks = weeksRef.current||weeks;

    if(mode==='week' && onSelect){
      const dateDate = new Date(curWeeks[week][0].str);
      const start = startOfWeek(dateDate);
      const end = endOfWeek(dateDate);

      onSelect([start, end]);
    }

  },[mode, weeks, onSelect]);

  const selectMonth = useCallback(function(monthN){
    if(mode==='month' && onSelect){
      const dateDate = new Date(selectedYear, monthN, 1, 0,0,1);
      const start = startOfMonth(dateDate);
      const end = endOfMonth(dateDate);

      onSelect([start, end]);
    }

  },[selectedYear, mode, onSelect]);

  // trigger onSelect on mode change
  const prevSelectedDatesRefMap = useRef(new Map()).current;
  useEffect(function onModeChange(){

    // only if mode has changed
    if(mode!==prevMode){
      // requested logic is
      // if we go up the scale (day to month) and so on -> we automate range selection
      // if not -> we use previously selected date or default to today

      // lets preserve the selection
      prevSelectedDatesRefMap.set(prevMode, selectedDate);
      const downgradeDate = prevSelectedDatesRefMap.get(mode)||currentDate;
      console.log('[DatePicker]{onModeChange} preserved selectedDate is', selectedDate);
      console.log('[DatePicker]{onModeChange} retrieved date is', prevSelectedDatesRefMap.get(mode));
      console.log('[DatePicker] prev date mode', prevMode, prevMode === 'month');

      if(mode === 'day'){
        // we alway 'downgrade' when select this ->
        const date = new Date(downgradeDate);

        setSelectedDate(makeDateObj(date).str);
        setSelectedWeek(getWeekOfMonth(date)-1);
        setSelectedMonth(getMonth(date));
        setSelectedYear(getYear(date));

        selectDay(date);
      }
      else if(mode === 'week'){
        const date = prevMode === 'month' ? new Date(downgradeDate) : new Date(selectedDate||initDate||currentDate);
        const week = getWeekOfMonth(date)-1;
        const start = startOfWeek(date);
        const end = endOfWeek(date);

        console.log('{onModeChange} setting date', date);
        setSelectedDate(makeDateObj(date).str);
        setSelectedWeek(week);
        setSelectedMonth(getMonth(date));

        onSelect?.([start, end]);
      }
      else if(mode === 'month'){
        selectMonth(selectedMonth||getMonth(initDate||currentDate));
      }
    }
  },[
    weeks,
    selectDay,
    selectWeek,
    selectMonth,
    initDate,
    prevMode,
    selectedDate,
    selectedMonth,
    selectedWeek,
    selectedYear,
    setSelectedDate,
    prevSelectedDatesRefMap,
    onSelect,
    mode
  ]);

  // hookup api calls if ref specified
  useEffect(function(){
    apiRef.current = {
      selectedDate,
      setSelectedDate,
      selectedWeek,
      setSelectedWeek,
      selectedMonth,
      setSelectedMonth,
      selectedYear,
      setSelectedYear,
      flipMonth,
      flipYear
    };
  },[
    apiRef,
    selectedDate,
    setSelectedDate,
    selectedWeek,
    setSelectedWeek,
    selectedMonth,
    setSelectedMonth,
    selectedYear,
    setSelectedYear,
    flipMonth,
    flipYear
  ]);

  return (
    mode === 'day' || mode === 'week'
      ?
    <StyledCalendarWrapper className={'datePickerPane'+(className?' '+className:'')} {...props}>

      <div className="datepickerHeader">
        <FiChevronLeft onClick={() => {
          flipMonth(-1);
        }}/>

        <div className="pikers">
          <SelectBox
            tabIndex={-1}
            values={months}
            value={months[selectedMonth]}
            onSelect={(v) => {
            const index = months.indexOf(v);
            if(index>-1){
              setSelectedMonth(index);
              clearDateWeek();
            }
          }} />
          <SelectBox
            tabIndex={-1}
            values={years}
            value={selectedYear}
            onSelect={(v) => {
              setSelectedYear(v);
              clearDateWeek();

            }} />
        </div>

        <FiChevronRight onClick={() => {
          flipMonth(+1);
        }}/>
      </div>

      <StyledCalendarBody className={'mode-'+mode}>
          <div className="header"><span>S</span><span>M</span><span>T</span><span>W</span><span>T</span><span>F</span><span>S</span></div>

          {weeks?.map((week, w) =>
            <div
              key={String(w)}
              className={('week')+(w===selectedWeek?' selected':'')}
              onClick={() => {
                setSelectedWeek(w);
                selectWeek(w);
              }}
              >
            {week?.map( ({day, month, str}) =>
              <span
                key={String(str)}
                className={
                  (month!==selectedMonth?' grey':'')+
                  (selectedDate === str?' selected':'')+
                  (((w<2&&day<=14)||(w>0&&day>7&&day<=31))&&(day === todaysDateOnCalendar)?' today':'')||undefined
                }
                onClick={()=> {
                  if((w<2&&day<=14)||(w>0&&day>7)){
                    selectDay(str);
                    setSelectedDate(str);
                  }
                }}>{day}</span>)}
            </div>)}
      </StyledCalendarBody>

    </StyledCalendarWrapper>
      :
    mode === 'month'
      ?
    <StyledCalendarWrapper className="datePickerPane">

      <div className="datepickerHeader">
        <FiChevronLeft onClick={() => {
          flipYear(+1);
        }}/>

        <div className="pikers">
          <SelectBox values={years} value={selectedYear} onSelect={(v) => {
            setSelectedYear(v);
            clearDateWeek();
          }} />
        </div>

        <FiChevronRight onClick={() => {
          flipYear(-1);
        }}/>
      </div>

      <StyledCalendarBody className={'mode-'+mode}>
        {shortQuarters?.map( (quarter,q) => (
          <div key={String(quarter)}>
            {quarter?.map( (month,qi) =>
              <span
                key={String(month)}
                className={(selectedMonth === (quarter.length*q)+qi?'selected':'')}
                onClick={()=>{
                  const monthN = (quarter.length*q)+qi;
                  selectMonth(monthN);
                  setSelectedMonth(monthN);
                  clearDateWeek();
                }}>{month}</span>
            )}
          </div>
          ))}
      </StyledCalendarBody>

    </StyledCalendarWrapper>
      :
      null
  );
};

const StyledDatePickerIconContainer = styled.div`${({theme}) => `
  position: relative;
  display: inline-block;

  .datePickerPane {
    position: absolute;
    top: 100%;
    right: 0;
    margin-top: ${theme.boxMargins.base}px;
  }

  &.paneHidden .datePickerPane {
    display: none;
  }
`}`;
const StyledCalendarWrapper = styled.div`${({theme}) => `
  padding: ${theme.boxPadding.l}px ${theme.fontPadding.s}px;
  border: 2px solid ${theme.newColors.GreenJuice};
  background-color: ${theme.newColors.SwissCream};
  border-radius: 4px;

  display: inline-block;

  text-align: center;

  z-index: 1000;

  .datepickerHeader > svg {
    cursor:pointer;
  }

  .datepickerHeader {
     display: flex;
     justify-content: space-between;
     align-items: center;
  }

  .pikers {
    margin-left: 5%;
  }

  min-width: ${theme.fontPadding.xl5*7+theme.fontMargins.s*6+5}px;
  box-sizing: content-box;

  

  /* overwrite any global values */
  input {
    min-width: 0 !important;
    margin-top: 0 !important;
  }
  input + * {
    margin-top: 0 !important;
  }
`}`;

const StyledCalendarBody = styled.div`${({theme}) => `
  .header span {
    cursor: auto;
    color: ${theme.colors.ink.light};
  }

  span:not(:first-child) {
    margin-left: ${theme.fontMargins.s}px;
  }

  div:not(:first-child) {
    margin-top: ${theme.fontMargins.xxs}px;
  }

  span {
    display: inline-block;

    width: ${theme.fontPadding.xl5}px;
    height: ${theme.fontPadding.xl5}px;
    line-height: ${theme.fontPadding.xl5}px;
    font-size: ${theme.fontSize.m}px;
    font-family: ${theme.defaultFontFamily};
    text-align: center;

    cursor: pointer;

    border-collapse: collapse;
  }

  &.mode-month span{
    width: auto;
    padding: 0 ${theme.fontMargins.xl}px;
    line-height: ${theme.fontPadding.xl5}px;
  }

  .today{
    background-color: rgba(${theme.colorsRgb.newColors.GreenJuice}, 0.2);
    border-radius: ${theme.fontPadding.xl}px;
  }

  &.mode-week .week.selected {
    background-color: rgba(${theme.colorsRgb.newColors.GreenJuice}, 0.5);
    border-radius: ${theme.fontPadding.xl}px;
    color: ${theme.colors.sky.white};
  }

  span.selected {
    background-color: ${theme.newColors.GreenJuice};
    color: ${theme.colors.sky.white};
    border-radius: ${theme.fontPadding.xl}px;
  }

  .grey {
    color: ${theme.colors.sky.lightest};
  }
`}`;
