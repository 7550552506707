import React, {SVGProps, useRef, useLayoutEffect} from 'react';
import {
  newColors
} from 'theme';
import {
  AnimatedEmblem
} from '../AnimatedEmblem';
import gsap from 'gsap';
import styles from './Logo.module.css';

export const Logo: React.FC<SVGProps<SVGSVGElement>> = ({
  width = 186,
  height = 20,
  ...rest
}) => {

  return (
    <svg width={width} height={height} viewBox="0 0 186 20" {...rest}>
      <Emblem x={0} y={0} height={20} width={28} fill={newColors.OrangeDream} />
      <Name x={0} y={37} height={20} width={150} fill={newColors.WhippedCream} />
    </svg>
  )
};

// type LoadingProps = {
//   variant?: 'vertical' | 'inline' | 'icon';
//   width?: number;
//   height?: number;
//   className?: string;

// };
export const AnimatedLogo: React.FC<{
  width?: number,
  height?: number,
  mode?: 'emblem' | 'full',
  loading?: boolean;
} & SVGProps<SVGSVGElement>> = ({
  width = 186,
  height = 20,
  mode = 'full',
  loading,
  ...rest
}) => {
  const timeline = useRef(gsap.timeline()).current;
  const refsHolder = useRef<{
    [key: string]: SVGSVGElement | null
  }>({
    name: null,
    emblem: null
  }).current;
  const initiated = useRef(false);
  
  useLayoutEffect(function animateEmblemToFullTransition(){
    // if(!refsHolder['name']||!refsHolder['emblem']){
    //   return;
    // }
    const centeredOffset = 79; // (width - emblem width) / 2
    const duration = initiated.current ? 0.5 : 0;
    timeline.clear();
    const time = timeline.time();
    switch (mode){
      case 'emblem':
        timeline.to(refsHolder['name'], {
          duration,
          attr: {
            x: 37 + centeredOffset
          },
          opacity: 0
        }, time);
        timeline.to(refsHolder['emblem'], {
          duration,
          attr: {
            x: centeredOffset
          }
        }, time);
        break;
      case 'full':
      default:
        timeline.to(refsHolder['name'], {
          duration,
          attr: {
            x: 37,
          },
          opacity: 1
        }, time);
        timeline.to(refsHolder['emblem'], {
          duration,
          attr: {
            x: 0
          }
        }, time);
        break;
    }
    // set initiated
    initiated.current = true;
  },[
    mode,
    refsHolder,
    timeline
  ]);

  return (
    <svg className={styles.animatedLogo} width={width} height={height} viewBox="0 0 186 20" {...rest}>
      <AnimatedEmblem active={loading} ref={(ref) => {refsHolder['emblem'] = ref;}} x={0} y={0} height={20} width={28} fill={newColors.OrangeDream} />
      <Name ref={(ref) => {refsHolder['name'] = ref;}} x={37} y={0} height={20} width={150} fill={newColors.WhippedCream} />
    </svg>
  )
};

const Emblem: React.FC<{
  fill?: string
} & SVGProps<SVGSVGElement>> = ({
  fill = newColors.OrangeDream,
  x = 0,
  y = 0,
  width = 28,
  height = 20,
  ...rest
}) => (
  <svg width={width} height={height} viewBox="0 0 28 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path d="M2.71745 12.233H0.388208C0.173806 12.233 0 12.4069 0 12.6214V19.6117C0 19.8261 0.173806 20 0.388208 20H2.71745C2.93185 20 3.10566 19.8261 3.10566 19.6117V12.6214C3.10566 12.4069 2.93185 12.233 2.71745 12.233Z" fill={fill}/>
    <path d="M7.53123 0.582524H5.20198C4.98758 0.582524 4.81377 0.756394 4.81377 0.970874V19.6116C4.81377 19.8261 4.98758 20 5.20198 20H7.53123C7.74563 20 7.91944 19.8261 7.91944 19.6116V0.970874C7.91944 0.756394 7.74563 0.582524 7.53123 0.582524Z" fill={fill}/>
    <path d="M12.345 0.582524H10.0158C9.80135 0.582524 9.62755 0.756394 9.62755 0.970874V7.96117C9.62755 8.17564 9.80135 8.34951 10.0158 8.34951H12.345C12.5594 8.34951 12.7332 8.17564 12.7332 7.96117V0.970874C12.7332 0.756394 12.5594 0.582524 12.345 0.582524Z" fill={fill}/>
    <path d="M17.1588 0.582524H14.8295C14.6151 0.582524 14.4413 0.756394 14.4413 0.970874V19.6116C14.4413 19.8261 14.6151 20 14.8295 20H17.1588C17.3732 20 17.547 19.8261 17.547 19.6116V0.970874C17.547 0.756394 17.3732 0.582524 17.1588 0.582524Z" fill={fill}/>
    <path d="M21.9726 12.233H19.6433C19.4289 12.233 19.2551 12.4069 19.2551 12.6214V19.6117C19.2551 19.8261 19.4289 20 19.6433 20H21.9726C22.187 20 22.3608 19.8261 22.3608 19.6117V12.6214C22.3608 12.4069 22.187 12.233 21.9726 12.233Z" fill={fill}/>
    <path d="M26.7863 0.582524H24.4571C24.2427 0.582524 24.0689 0.756394 24.0689 0.970874V19.6116C24.0689 19.8261 24.2427 20 24.4571 20H26.7863C27.0007 20 27.1745 19.8261 27.1745 19.6116V0.970874C27.1745 0.756394 27.0007 0.582524 26.7863 0.582524Z" fill={fill}/>
  </svg>
);

const Name = React.forwardRef<SVGSVGElement, ({
  fill?: string
} & SVGProps<SVGSVGElement>)>(({
  fill = newColors.WhippedCream,
  x = 0,
  y = 0,
  width = 150,
  height = 20,
  ...rest
}, ref) => (
  <svg ref={ref} x={x} y={y} width={width} height={height} viewBox="0 0 150 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path d="M3.5603 18.6913C2.54902 18.2485 1.75514 17.6214 1.17865 16.8097C0.67592 16.1029 0.392529 15.2932 0.326533 14.3806C0.311005 14.1553 0.489581 13.965 0.714741 13.965H3.27691C3.46519 13.965 3.62436 14.1029 3.65736 14.2874C3.7777 14.9553 4.08438 15.5165 4.57547 15.9689C5.14419 16.4932 5.94196 16.7534 6.97071 16.7534C7.99946 16.7534 8.86516 16.4951 9.46106 15.9806C10.057 15.466 10.3539 14.8019 10.3539 13.9903C10.3539 13.3592 10.1695 12.8447 9.7988 12.4466C9.42806 12.0505 8.96804 11.7417 8.41872 11.5262C7.86747 11.3087 7.10464 11.0757 6.13024 10.8214C4.9035 10.4971 3.90581 10.167 3.1391 9.83301C2.37239 9.49903 1.71826 8.98058 1.17671 8.27573C0.635159 7.57087 0.365357 6.63301 0.365357 5.45825C0.365357 4.37476 0.6371 3.42718 1.17671 2.61359C1.71632 1.8 2.47721 1.17864 3.45161 0.745632C4.42601 0.312622 5.55375 0.0951462 6.83678 0.0951462C8.65941 0.0951462 10.154 0.551457 11.3167 1.46214C12.3707 2.28738 12.9976 3.38835 13.2014 4.76893C13.2364 5.00388 13.052 5.21359 12.8152 5.21359H10.1754C9.98902 5.21359 9.83374 5.07961 9.79492 4.89515C9.67651 4.34369 9.36789 3.86214 8.8671 3.45243C8.2712 2.96505 7.48702 2.72039 6.51068 2.72039C5.62557 2.72039 4.9035 2.94563 4.34449 3.39806C3.78547 3.85049 3.50596 4.49903 3.50596 5.34757C3.50596 5.92621 3.68259 6.4 4.03392 6.76893C4.38525 7.13981 4.83168 7.43301 5.37323 7.64854C5.91478 7.86602 6.65432 8.10097 7.59378 8.3534C8.83993 8.69709 9.85509 9.04078 10.6393 9.38252C11.4234 9.72621 12.0912 10.2544 12.6424 10.967C13.1937 11.6796 13.4674 12.633 13.4674 13.8252C13.4674 14.7825 13.2092 15.6854 12.6948 16.534C12.1805 17.3825 11.4312 18.0641 10.4471 18.5786C9.463 19.0932 8.3042 19.3515 6.96877 19.3515C5.70515 19.3515 4.5677 19.1301 3.55642 18.6874L3.5603 18.6913Z" fill={fill}/>
    <path d="M19.5331 0.730097V18.7767C19.5331 18.9922 19.3584 19.165 19.1449 19.165H16.8351C16.6196 19.165 16.4469 18.9903 16.4469 18.7767V0.730097C16.4469 0.514563 16.6216 0.341747 16.8351 0.341747H19.1449C19.3604 0.341747 19.5331 0.516505 19.5331 0.730097Z" fill={fill}/>
    <path d="M58.2413 19.165H55.7509C55.6209 19.165 55.4986 19.099 55.4268 18.9903L46.2554 5.10679V18.7748C46.2554 18.9883 46.0826 19.1631 45.8672 19.1631H43.5573C43.3438 19.1631 43.1691 18.9903 43.1691 18.7748V0.702912C43.1691 0.48932 43.3419 0.314563 43.5573 0.314563H46.0477C46.1777 0.314563 46.3 0.380582 46.3718 0.48932L55.5433 14.3456V0.702912C55.5433 0.48932 55.716 0.314563 55.9315 0.314563H58.2413C58.4548 0.314563 58.6295 0.487378 58.6295 0.702912V18.7767C58.6295 18.9903 58.4568 19.165 58.2413 19.165Z" fill={fill}/>
    <path d="M73.3581 15.3204L69.4333 4.05243L65.4794 15.3204L64.2178 18.9068C64.1634 19.0621 64.0159 19.167 63.8509 19.167H61.4557C61.1878 19.167 60.9995 18.901 61.0907 18.6466L67.5525 0.572815C67.6068 0.417475 67.7543 0.314563 67.9174 0.314563H70.9435C71.1084 0.314563 71.254 0.417475 71.3084 0.572815L77.772 18.6466C77.8633 18.899 77.675 19.167 77.4071 19.167H74.9847C74.8197 19.167 74.6722 19.0621 74.6178 18.9068L73.3562 15.3204H73.3581Z" fill={fill}/>
    <path d="M83.2943 16.2854H90.4334C90.6489 16.2854 90.8216 16.4602 90.8216 16.6738V18.7767C90.8216 18.9922 90.6469 19.165 90.4334 19.165H80.5982C80.3827 19.165 80.21 18.9903 80.21 18.7767V0.730097C80.21 0.514563 80.3847 0.341747 80.5982 0.341747H82.908C83.1235 0.341747 83.2962 0.516505 83.2962 0.730097V16.2854H83.2943Z" fill={fill}/>
    <path d="M115.129 0.840776L109.782 18.8874C109.733 19.0524 109.582 19.165 109.409 19.165H106.501C106.327 19.165 106.173 19.0485 106.127 18.8796L102.252 4.91845L98.1374 18.8893C98.0889 19.0544 97.9394 19.167 97.7686 19.167L94.8862 19.1903C94.7115 19.1903 94.5562 19.0757 94.5096 18.9068L89.3969 0.834951C89.327 0.586408 89.5134 0.339806 89.7696 0.339806H92.2308C92.4074 0.339806 92.5627 0.460194 92.6074 0.633009L96.4312 15.6699L100.575 0.625242C100.622 0.45631 100.775 0.339806 100.95 0.339806H103.819C103.995 0.339806 104.151 0.460194 104.195 0.631068L108.046 15.5883L111.897 0.631068C111.942 0.460194 112.095 0.339806 112.274 0.339806H114.757C115.017 0.339806 115.203 0.590291 115.129 0.838835V0.840776Z" fill={fill}/>
    <path d="M120.426 0.730097V18.7767C120.426 18.9903 120.252 19.165 120.038 19.165H117.728C117.515 19.165 117.34 18.9903 117.34 18.7767V0.730097C117.34 0.516505 117.515 0.341747 117.728 0.341747H120.038C120.252 0.341747 120.426 0.516505 120.426 0.730097Z" fill={fill}/>
    <path d="M126.599 18.6913C125.588 18.2485 124.794 17.6214 124.217 16.8097C123.714 16.1029 123.431 15.2932 123.365 14.3806C123.35 14.1573 123.528 13.965 123.753 13.965H126.315C126.504 13.965 126.663 14.1029 126.696 14.2874C126.816 14.9553 127.123 15.5165 127.614 15.9689C128.183 16.4932 128.98 16.7534 130.009 16.7534C131.038 16.7534 131.904 16.4951 132.5 15.9806C133.096 15.466 133.392 14.8019 133.392 13.9903C133.392 13.3592 133.206 12.8447 132.837 12.4466C132.467 12.0505 132.007 11.7417 131.457 11.5262C130.906 11.3087 130.145 11.0757 129.169 10.8214C127.94 10.4971 126.944 10.167 126.178 9.83301C125.411 9.49903 124.757 8.98058 124.215 8.27573C123.674 7.57087 123.404 6.63301 123.404 5.45825C123.404 4.37476 123.674 3.42718 124.215 2.61359C124.757 1.8 125.516 1.17864 126.49 0.745632C127.465 0.312622 128.592 0.0951462 129.875 0.0951462C131.698 0.0951462 133.193 0.551457 134.355 1.46214C135.409 2.28738 136.036 3.38835 136.24 4.76893C136.275 5.00388 136.091 5.21359 135.854 5.21359H133.214C133.028 5.21359 132.872 5.07961 132.833 4.89515C132.715 4.34369 132.406 3.86214 131.906 3.45243C131.31 2.96505 130.526 2.72039 129.549 2.72039C128.664 2.72039 127.942 2.94563 127.383 3.39806C126.824 3.85049 126.544 4.49903 126.544 5.34757C126.544 5.92621 126.721 6.4 127.072 6.76893C127.424 7.13981 127.872 7.43301 128.412 7.64854C128.953 7.86602 129.693 8.10097 130.632 8.3534C131.878 8.69709 132.894 9.04078 133.678 9.38252C134.464 9.72621 135.13 10.2544 135.681 10.967C136.232 11.6796 136.506 12.633 136.506 13.8252C136.506 14.7825 136.248 15.6854 135.733 16.534C135.219 17.3825 134.47 18.0641 133.486 18.5786C132.502 19.0932 131.343 19.3515 130.007 19.3515C128.744 19.3515 127.606 19.1301 126.595 18.6874L126.599 18.6913Z" fill={fill}/>
    <path d="M30.3835 8.37476C30.17 8.37476 29.9953 8.54757 29.9953 8.76311V10.901C29.9953 11.1146 30.1681 11.2893 30.3835 11.2893H38.136C37.3402 14.4718 34.3335 16.7806 30.8416 16.4796C27.4933 16.1922 24.8088 13.4563 24.5779 10.1029C24.3003 6.07379 27.4836 2.72039 31.4511 2.72039C33.6134 2.72039 35.5428 3.71845 36.8064 5.27767C36.8821 5.37087 36.9927 5.42524 37.1111 5.42524H39.4617C39.7548 5.42524 39.9451 5.1068 39.7995 4.85243C37.6973 1.17282 33.2368 -0.984466 28.4774 0.448544C25.5193 1.33786 23.1842 3.6699 22.2933 6.62718C20.3076 13.2078 25.1776 19.2252 31.4511 19.2252C36.7579 19.2252 41.0592 14.9223 41.0592 9.61359C41.0592 9.30291 41.0437 8.99806 41.0146 8.69514C40.9952 8.4932 40.8282 8.3398 40.6244 8.3398L30.3835 8.3767V8.37476Z" fill={fill}/>
    <path d="M149.612 8.15534H139.421C139.207 8.15534 139.033 8.32921 139.033 8.54369V10.6796C139.033 10.8941 139.207 11.068 139.421 11.068H149.612C149.826 11.068 150 10.8941 150 10.6796V8.54369C150 8.32921 149.826 8.15534 149.612 8.15534Z" fill={fill}/>
    <path d="M149.612 16.3107H139.421C139.207 16.3107 139.033 16.4845 139.033 16.699V18.835C139.033 19.0494 139.207 19.2233 139.421 19.2233H149.612C149.826 19.2233 150 19.0494 150 18.835V16.699C150 16.4845 149.826 16.3107 149.612 16.3107Z" fill={fill}/>
    <path d="M149.612 0.291263H139.421C139.207 0.291263 139.033 0.465133 139.033 0.679612V2.91262C139.033 3.1271 139.207 3.30097 139.421 3.30097H149.612C149.826 3.30097 150 3.1271 150 2.91262V0.679612C150 0.465133 149.826 0.291263 149.612 0.291263Z" fill={fill}/>
  </svg>
));
