import styled, {css} from 'styled-components';

export const FlexContainer = styled.div`
  display: flex !important;
  flex-direction: row;
  flex-wrap: wrap;

  > * {
    display: inline-block;
    box-sizing: border-box;
  }
`;

const common = css`
  @media (max-width: 1150px) {
    width: 100%;
    display: block;
  }
`;

export const Flex1 = styled.div.attrs({
  className: 'flex'
} as {className?: string})`
  flex-grow: 1;
  width: 8.3%;
  box-sizing: border-box;

  ${common}
`;
export const Flex2 = styled.div.attrs({
  className: 'flex'
} as {className?: string})`
  flex-grow: 2;
  width: 16.6%;
  box-sizing: border-box;

  ${common}
`;
export const Flex3 = styled.div.attrs({
  className: 'flex'
} as {className?: string})`
  flex-grow: 3;
  width: 25%;
  box-sizing: border-box;

  ${common}
`;
export const Flex4 = styled.div.attrs({
  className: 'flex'
} as {className?: string})`
  flex-grow: 4;
  width: 33.3%;
  box-sizing: border-box;

  ${common}
`;
export const Flex5 = styled.div.attrs({
  className: 'flex'
} as {className?: string})`
  flex-grow: 5;
  width: 41.6%;
  box-sizing: border-box;

  ${common}
`;
export const Flex6 = styled.div.attrs({
  className: 'flex'
} as {className?: string})`
  flex-grow: 6;
  min-width: 300px;
  width: 50%;
  box-sizing: border-box;

  ${common}
`;
export const Flex7 = styled.div.attrs({
  className: 'flex'
} as {className?: string})`
  flex-grow: 7;
  width: 58.3%;
  box-sizing: border-box;

  ${common}
`;
export const Flex8 = styled.div.attrs({
  className: 'flex'
} as {className?: string})`
  flex-grow: 8;
  width: 66.6%;
  box-sizing: border-box;

  ${common}
`;
export const Flex9 = styled.div.attrs({
  className: 'flex'
} as {className?: string})`
  flex-grow: 9;
  width: 75%;
  box-sizing: border-box;

  ${common}
`;
export const Flex10 = styled.div.attrs({
  className: 'flex'
} as {className?: string})`
  flex-grow: 10;
  width: 83.3%;
  box-sizing: border-box;
`;
export const Flex11 = styled.div.attrs({
  className: 'flex'
} as {className?: string})`
  flex-grow: 11;
  width: 91.6%;
  box-sizing: border-box;

  ${common}
`;
export const Flex12 = styled.div.attrs({
  className: 'flex'
} as {className?: string})`
  flex-grow: 12;
  width: 100%;
  box-sizing: border-box;

  ${common}
`;
