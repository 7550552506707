import React from 'react';

import styled from 'styled-components';

import styles from './Input.module.css';

export type FloatingFormProps = {
  condenced?:boolean;
  refference?:React.RefObject<HTMLFormElement>;
  warnHeader?:React.ReactNode;
  infoHeader?:React.ReactNode;
  successHeader?:React.ReactNode;
}
export const FloatingForm: React.FC<FloatingFormProps & React.HTMLProps<HTMLFormElement>> = ({
  refference,
  condenced=false,
  className='',
  warnHeader,
  infoHeader,
  successHeader,
  onSubmit,
  children
}) =>
  <StyledForm
    ref={refference}
    condenced
    className={(condenced?' '+styles.condenced:'')+' '+className}
    onSubmit={onSubmit} >
    {warnHeader
      ?
      <WarnHeader>{warnHeader}</WarnHeader>
      :
     infoHeader
      ?
      <InfoHeader>{infoHeader}</InfoHeader>
      :
     successHeader
      ?
      <SuccessHeader>{successHeader}</SuccessHeader>
      :
      null
    }

    {children}
  </StyledForm>;

const InfoHeader = styled.div`
  margin-top: -${({theme}) => theme.boxPadding.xl3}px;
  margin-left: -${({theme}) => theme.boxPadding.xl3}px;
  margin-right: -${({theme}) => theme.boxPadding.xl3}px;

  background-color: ${({theme}) => theme.newColors.information};
  padding: ${({theme}) => theme.boxPadding.xl3/2}px ${({theme}) => theme.boxPadding.xl3}px;
  font-weight: bold;
  color: ${({theme}) => theme.colors.sky.white};
`;

const WarnHeader = styled.div`
  margin-top: -${({theme}) => theme.boxPadding.xl3}px;

  margin-left: -${({theme}) => theme.boxPadding.xl3}px;
  margin-right: -${({theme}) => theme.boxPadding.xl3}px;

  background-color: ${({theme}) => theme.newColors.error};
  padding: ${({theme}) => theme.boxPadding.xl3/2}px ${({theme}) => theme.boxPadding.xl3}px;
  font-weight: bold;
  color: ${({theme}) => theme.colors.sky.white};
`;

const SuccessHeader = styled.div`
  margin-top: -${({theme}) => theme.boxPadding.xl3}px;

  margin-left: -${({theme}) => theme.boxPadding.xl3}px;
  margin-right: -${({theme}) => theme.boxPadding.xl3}px;

  background-color: ${({theme}) => theme.colors.green.light};
  padding: ${({theme}) => theme.boxPadding.xl3/2}px ${({theme}) => theme.boxPadding.xl3}px;
  font-weight: bold;
  color: ${({theme}) => theme.colors.sky.white};
`;

const StyledForm = styled.form.attrs({} as FloatingFormProps)`

  display: inline-block;
  overflow: hidden;

  background-color: ${({theme}) => theme.newColors.SwissCream};
  font-family: ${({theme}) => theme.defaultFontFamily};

  padding: ${({theme}) => theme.boxPadding.xl3}px;
  border-radius: ${({theme}) => theme.boxPadding.base}px;

  input:not([type="checkbox"]):not([type="radio"]), select {
    margin-top: ${({theme}) => theme.fontMargins.xxxxs}px;
    min-width: 327px;
    box-sizing: border-box;
  }
  .input-container {
    position: relative;
  }

  b {
    max-width: 261px;
  }

  button {
    min-width:100%;
  }

  input {

  }

  h2 {
    margin-top: 0px;
  }

  > *:not(:first-child) {
    margin-top: ${({theme}) => theme.fontMargins.base}px;
    display: block;
  }

  .input-group[name="password"]{
    margin-bottom: ${({theme}) => theme.boxMargins.xl8}px;
  }

  ${({theme, condenced}) => condenced && `
    input:not([type="checkbox"]):not([type="radio"]) + *,
    input {
      margin-top: ${theme.fontMargins.xxxxs}px;
    }
    .input-group a {
      margin-top: ${theme.fontMargins.xxxxs}px;
    }
  `}

  > a {
    text-align: center;
  }

  a {
    text-decoration: none;
  }
`;
