import React, {useEffect, useState, useRef, useCallback, useMemo} from 'react';

import {
    extent,
    scaleTime,
    scaleLinear,

    axisBottom,
    axisLeft,
    axisRight,
    axisTop,

    path,
    select,

    Path,
    range,

} from 'd3';
import {
    differenceInHours,
    differenceInDays,
    differenceInWeeks,
    differenceInMonths,

    startOfHour,
    endOfHour,
    startOfDay
} from 'date-fns';
import {
    format
} from 'date-fns-tz';

import styled from 'styled-components';
import { useOverlayContext } from 'context';
import { providerToTitleName } from 'helpers';
import { digestedAgent } from 'layout';

type AmountOverDateGraphPayload = {

  dataGroups: {
    label: string;
    items: {amount: number, date:Date}[]
  }[] | undefined;
  pathsCutoff?: Date|number;
  width: number;
  height: number;
  padding?: {
    top: number;
    right: number;
    bottom: number;
    left: number;
  },
  axisSpacing?: {
    top: number,
    right: number,
    bottom: number,
    left: number
  },
  numberOfBins?: number;
  startDate?: Date;
  endDate?: Date;
  labelToColorMap?: Map<string, string>;
  dateTicks?: 'hours'|'days'|'weeks'|'month'|number;
  numberOfVerticalTicks?: number;
  gridCellWidth?:number;
  gridCellHeight?:number;

  itemizationTimeSample?: 'day'|'hour'

  amountOverDateGraphRef?: React.MutableRefObject<SVGSVGElement>,

  label?: string;
  className?: string;
  smothing?: boolean;
  endLabel?: string;

}
type DateAmountBin = {
  // array like object containing
  [key: number]: any,
  date: number;
  amount: number;
  // catmulRom handles
  handleF: {
    date: number,
    amount: number
  }
  handleB: {
    date: number,
      amount: number
    }
  };
  type ValuesByDaysItem = {
    timeRefTimestamp: number,
    values: {
      // label/amount value
      [key: string]: number
    }
  }

  // Version 0.2
  export const AmountOverDateGraph: React.FC<AmountOverDateGraphPayload> = ({
    dataGroups,
    width,
    height,
    padding={
      top: 30,
      right: 30,
      bottom: 50,
      left: 50
    },
    axisSpacing={
      top: 15,
      right: 15,
      bottom: 15,
      left: 15
    },
    numberOfBins=25,
    startDate,
    endDate,
    labelToColorMap= new Map(),
    dateTicks='days',
    numberOfVerticalTicks,
    gridCellWidth=100,
    gridCellHeight=100,

    pathsCutoff, // date to cutoff path at

    itemizationTimeSample='hour',

    label,
    children,
    smothing=true,

    endLabel=false,

    className
  }) => {
    const svgRef = useRef<SVGSVGElement>(null);
    const [binnedData, setBinnedData] = useState<{
      label: string;
      bins: DateAmountBin[]
    }[]>();

    const [allItems, setAllItems] = useState<any[]>();
    // items amount by specified timesample
    const groupValuesByTimesampleRef = useRef<{[key: number]: ValuesByDaysItem}>();

    const amountMaxRef = useRef<number>(0);

    const [svgRefBbBox, setSvgRefBbBox] = useState<DOMRect>();

    const {attachTooltip} = useOverlayContext();

    // BIN INDEXED DATA FOR PLOTTING
    // group indexed data into bins
    useEffect(function binPassedData(){
      if(!dataGroups||!dataGroups.length){
        setBinnedData(undefined);
        setAllItems(undefined);
        amountMaxRef.current = 0;

        return;
      }

      amountMaxRef.current = 0;

      // INDEX GROUPED ITEMS
      const allItems: any[] = [];
      const newGroupValuesByTimesampleIndex: {
        [key: number]: ValuesByDaysItem
      } = {};
      // push to all and create daily index
      dataGroups?.forEach(({items, label}) => {
        items?.forEach(item => {
          allItems.push(item);
          const timeRefTimestamp = itemizationTimeSample === 'hour'
            ?
            // so something happening at 13:30 is within hour 14:00
            Number(endOfHour(item.date)) + 1000 // add a second to tip into the beginning of next hour
            :
            // while something happening on Day 29 is within Day 29
            itemizationTimeSample === 'day'
            ?
            Number(startOfDay(item.date))
            :

            0;

          if(!newGroupValuesByTimesampleIndex[timeRefTimestamp]){
            newGroupValuesByTimesampleIndex[timeRefTimestamp] = {
              timeRefTimestamp,
              values: {}
            };
          }

          const valuesByDayItem = newGroupValuesByTimesampleIndex[timeRefTimestamp];
          valuesByDayItem.values[label] = valuesByDayItem.values[label] ? valuesByDayItem.values[label]+item.amount : item.amount;
        });
      });

      const allItemsExtent = startDate && endDate ? [Number(startDate), Number(endDate)] : extent(allItems, d => Number(d.date));

      //Fri Sep 01 2023 08:00:00 GMT-0500 (Central Daylight Time)
      //endDate: Fri Sep 01 2023 14:00:00 GMT-0500 (Central Daylight Time)
      const startTimestamp = Number(allItemsExtent[0]);
      const endTimestamp = Number(allItemsExtent[1])+1;

      const newBinnedData = dataGroups?.map( ({label, items}) => {

        const binned: any[] = ([...Array(numberOfBins)])?.map(() => []);
        const step: number = (endTimestamp-startTimestamp)/binned.length;

        items?.forEach(item => {
          //allItems.push(item);
          const binNumber = Math.floor((Number(item.date)-startTimestamp)/step);


          if(Array.isArray(binned[binNumber])){
            binned[binNumber].push(item);
          }

        });

        //const filteredBinned = binned?.filter(d => d.length > 0);
        const binMarkStep = (endTimestamp-startTimestamp)/(binned.length-1);
        const mappedBinned = binned?.map((d: any, i) => {
          //d.powerEstimate = d3.mean(d, d=> Number(d.powerEstimate));

          const cummulative = d?.reduce( (cur:number, item: any) =>
            cur + item.amount
          , 0);

          d.amount = cummulative;
          d.date = new Date( startTimestamp + binMarkStep*i);

          // increase max volume if less than current
          if(amountMaxRef.current < d.amount){
            amountMaxRef.current = d.amount;
          }
          return d;
        });

        // calculate camulRom handles for binnned data
        // const tens = 0.7 * 12;
        // for(let i=0; i<mappedBinned.length-1;i++){
        //   const d0 = mappedBinned[ i-1 >= 0 ? i-1 : i ];
        //   const d1 = mappedBinned[ i ];
        //   const d2 = mappedBinned[ i + 1 ];
        //   const d3 = mappedBinned[ i + 2 < mappedBinned.length ? i + 2 : i + 1];

        //   const d1F = {
        //     date: Number(d1.date) + ( Number(d2.date)-Number(d0.date) )/tens,
        //     amount: d1.amount === 0 ? 0 : // prevent rounding below floor values
        //       d1.amount + ( d2.amount-d0.amount )/tens
        //   }

        //   const d2B = {
        //     date: Number(d2.date) - ( Number(d3.date)-Number(d1.date) )/tens,
        //     amount: d1.amount === 0 && d2.amount === 0 ? 0 : //prevent rounding below floor values
        //       d2.amount - ( d3.amount-d1.amount )/tens
        //   };

        //   d1.handleF = {...d1F};
        //   d2.handleB = {...d2B};
        // }
        const handleLength = smothing ? ((endTimestamp-startTimestamp)/numberOfBins)*0.5 : 0;

        for(let i=0; i<mappedBinned.length;i++){
          const d1 = mappedBinned[ i ];

          d1.handleF = {
            date: Number(d1.date) + handleLength,
            amount: d1.amount
          };

          d1.handleB = {
            date: Number(d1.date) - handleLength,
            amount: d1.amount
          };
        }

        return {label, bins: mappedBinned};
      });
      setBinnedData(newBinnedData);
      setAllItems(allItems);
      groupValuesByTimesampleRef.current = newGroupValuesByTimesampleIndex;
      console.log('[AmountOverDateGraph] binned reports', {newBinnedData, allItems, newGroupValuesByTimesampleIndex});
    }, [
      dataGroups,
      endDate,
      itemizationTimeSample,
      numberOfBins,
      startDate,
      smothing,
      axisSpacing.bottom
    ]);

    const dateExtent = useMemo(() => startDate && endDate
        ? [startDate, endDate]
        : allItems
          ? extent(allItems, (d:any) => d.date)
          // adding generic extent to draw empty grid
          : null,
      [startDate, endDate, allItems]);

    const timeScaler = useMemo(() => scaleTime()
          .domain(dateExtent||[0,1])
          .range([0, width-padding.left-padding.right]),

      [dateExtent, width, padding]);

    const amountScaler = useMemo( () => scaleLinear()
        .domain(binnedData?.length ? [amountMaxRef.current, 0]: [1,0])
        .range([0, height-padding.top-padding.bottom]),

    [height, padding, binnedData]);

    useEffect(function drawGrid(){
      const svg = select(svgRef.current);
      if(!svg) {return;}

      console.log('[AmountOverDateGraph:addTmsiAxis] svg is', svg);

      // calculate number of ticks
      const gridVerticalTicks = gridCellWidth ? Math.round((width-padding.left-padding.right)/gridCellWidth) : 0;
      const gridHorizontalTicks = gridCellHeight ? Math.round((height-padding.top-padding.bottom)/gridCellHeight) : 0;

      let numberOfHorizontalTicks;
      if(typeof dateTicks === 'number'){
        numberOfHorizontalTicks = dateTicks;
      }
      else if(dateExtent){
        switch(dateTicks){
          case 'hours':
            numberOfHorizontalTicks = 4;//differenceInHours(dateExtent[1], dateExtent[0]);
            break;
          case 'days':
            numberOfHorizontalTicks = differenceInDays(dateExtent[1], dateExtent[0]);
            break;
          case 'weeks':
            numberOfHorizontalTicks = differenceInWeeks(dateExtent[1], dateExtent[0]);
            break;
          case 'month':
            numberOfHorizontalTicks = differenceInMonths(dateExtent[1], dateExtent[0]);
            break;
          default:
            break;
        }
      }

      // Axis & Grid
      // create axis generator
      const axisX = axisBottom(timeScaler)
        .ticks( numberOfHorizontalTicks );

      let axisYLeft = axisLeft(amountScaler);
      if(numberOfVerticalTicks && amountMaxRef.current){
        axisYLeft = axisYLeft
          .tickValues( range(0, amountMaxRef.current, amountMaxRef.current/(numberOfVerticalTicks-1)) );
      }

      let axisYRight = axisRight(amountScaler);
      if(numberOfVerticalTicks && amountMaxRef.current){
        axisYRight = axisYRight
          .tickValues( range(0, amountMaxRef.current, amountMaxRef.current/(numberOfVerticalTicks-1)) );
      }

      const yAxisGrid = axisTop(timeScaler)
        .tickSize(height-padding.top-padding.bottom) // length
        .tickFormat(() => '')
        .ticks( gridVerticalTicks );

      const xAxisGrid = axisRight(amountScaler)
        .tickSize(width-padding.left-padding.right)
        .tickFormat(() => '')
        .ticks( gridHorizontalTicks );

      // create am axis elements group and attach a call function on it
      svg
        .selectAll('g.axisGroup')
        .data(['one'])
        .join(
          enter =>
            enter
              .append('g')
              .lower()
              .attr('class', 'axisGroup'),
          update => update,
          exit => {
            exit.remove();
          }
        )
        .selectAll('*')
        .remove();


      const axisGroup = svg
        .select('.axisGroup');
      
      if(gridHorizontalTicks){
        axisGroup.append('g')
          .call(xAxisGrid)
          .attr('transform', `translate(${padding.left}, ${padding.top})`)
          .attr('class', 'grid')
      }
      if(gridVerticalTicks){
        axisGroup.append('g')
          .call(yAxisGrid)
          .attr('transform', `translate(${padding.left}, ${height-padding.bottom})`)
          .attr('class', 'grid')
      }
      axisGroup.append('g')
        .call(axisX)
        .attr('class', 'axis bottom')
        .attr('transform', `translate(${padding.left}, ${height - padding.bottom + axisSpacing.bottom})`);

      axisGroup.append('g')
        .call(axisYLeft)
        .attr('class', 'axis left')
        .attr('transform', `translate(${padding.left - axisSpacing.left}, ${padding.top})`);

      axisGroup.append('g')
        .call(axisYRight)
        .attr('class', 'axis right')
        .attr('transform', `translate(${width - padding.right + axisSpacing.right}, ${padding.top})`);
        
    }, [
      dataGroups,
      amountScaler,
      dateExtent,
      timeScaler,
      numberOfVerticalTicks,
      dateTicks,
      gridCellHeight,
      gridCellWidth,
      height,
      padding.bottom,
      padding.left,
      padding.right,
      padding.top,
      axisSpacing,
      width
    ]);

    // create path objects
    const pathMappedGroups = useMemo<{
        path: Path
        label: string
      }[]|undefined>( () => binnedData?.map( (group) => {

      const {bins, label} = group;
      const tp = path();

      let prevBin:DateAmountBin;

      const cutoff = pathsCutoff ? new Date(pathsCutoff).getTime() : null;

      bins?.forEach((d,i) => {
        const datetime = Number(d.date);
        if(cutoff && datetime > cutoff){return;}
        const targetX = timeScaler( datetime );
        const targetY = amountScaler( d.amount );

        if(i===0){
          tp.moveTo(0, targetY);
          tp.bezierCurveTo(
            0, targetY,
            targetX, targetY,
            targetX, targetY);

        } else {

          tp.bezierCurveTo(
            timeScaler(prevBin.handleF.date), amountScaler(prevBin.handleF.amount),
            timeScaler(d.handleB.date), amountScaler(d.handleB.amount),
            targetX, targetY);
        }

        prevBin = d;
      });

      return {
        path: tp,
        label
      };

    }), [timeScaler,amountScaler,binnedData, pathsCutoff]);

    const dotsMappedGroups = useMemo<{
        dots: {
          x: number,
          y: number,
          amount: number,
          label: string
        }[]
        label: string
      }[]|undefined>( () => binnedData?.map( (group) => {

      const {bins, label} = group;
      const dots: {
          x: number,
          y: number,
          amount: number,
          label: string
        }[] = [];

      const cutoff = pathsCutoff ? new Date(pathsCutoff).getTime() : null;

      bins?.forEach((d,i) => {
        const datetime = Number(d.date);
        if(cutoff && datetime > cutoff){return;}
        const targetX = timeScaler( datetime );
        const targetY = amountScaler( d.amount );

        dots.push({
          x: targetX,
          y: targetY,
          amount: d.amount,
          label: label
        });
      });

      return {
        dots,
        label
      };
    }), [timeScaler,amountScaler,binnedData,pathsCutoff]);

    useEffect(function drawPathOntoSvg(){

      const svg = select(svgRef.current);

      svg
        .selectAll('svg.paths')
        .data(['one'])
        .join(
          enter => enter.append('svg').attr('class','paths'),
          update => update,
          exit => exit.remove()
        )
        //.attr('transform', `translate(${padding.left},${padding.top})`)
        .attr('x', padding.left)
        .attr('y', padding.top)
        .attr('width', width-padding.left-padding.right)
        .attr('height', height-padding.top-padding.bottom)
        .attr('style', 'overflow:hidden')

        .selectAll('path')
        .data(pathMappedGroups||[])
          .join(
            enter => enter.append('path'),
            update => update,
            exit => exit.remove()
          )
          .transition()
          .attr('d', d => String(d.path))
          .attr('stroke', d => labelToColorMap.get(d.label) || 'black' )
          .attr('fill', 'none');

    }, [
      binnedData,
      pathMappedGroups,
      padding.left,
      padding.top,
      labelToColorMap,
      height,
      width,
      padding.bottom,
      padding.right
    ]);

    useEffect(function drawDotsOntoSvg(){

      const svg = select(svgRef.current);

      svg
        .selectAll('svg.dots')
        .data(['one'])
        .join(
          enter => enter.append('svg').attr('class','dots'),
          update => update,
          exit => exit.remove()
        )
        //.attr('transform', `translate(${padding.left},${padding.top})`)
        .attr('x', padding.left)
        .attr('y', padding.top)
        .attr('width', width-padding.left-padding.right)
        .attr('height', height-padding.top-padding.bottom)
        .attr('style', 'overflow:visible')

        .selectAll('g')
        .data(dotsMappedGroups||[])
        .join(
          enter => enter.append('g'),
          update => update,
          exit => exit.remove()
        )
        .attr('fill', d => labelToColorMap.get(d.label) || 'black' )
        .selectAll('circle')
        .data(d => d.dots)
        .join(
          enter => enter
            .append('circle')
            .each((data, i, els) => {
              const el = els[i];

              attachTooltip(el as any, <TooltipLabel><b>{providerToTitleName(data.label)}</b><br />{data.amount}</TooltipLabel>, {
                offset: -10,
                showEvent: digestedAgent.phone ? undefined : 'mouseover',
                variant: 'small'
              })
            }),
          update => update
            .each((data, i, els) => {
              const el = els[i];

              attachTooltip(el as any, <TooltipLabel><b>{providerToTitleName(data.label)}</b><br />{data.amount}</TooltipLabel>, {
                offset: -10,
                showEvent: digestedAgent.phone ? undefined : 'mouseover',
                variant: 'small'
              })
            }),
          exit => exit.remove()
        )
        .attr('cx', d => d.x)
        .transition()
        .attr('cy', d => d.y)
        .attr('r', 4)
        .attr('style', 'cursor: pointer')

    },[
      binnedData,
      dotsMappedGroups,
      padding.left,
      padding.top,
      labelToColorMap,
      height,
      width,
      padding.bottom,
      padding.right,
      attachTooltip
    ])

    useEffect(function getSvgBbBoxOnResize(){
      const setBbBoxOnChange = function(){
        const bbBox = svgRef.current?.getBoundingClientRect();
        setSvgRefBbBox(bbBox);
      };

      window.addEventListener('resize', setBbBoxOnChange);
      setBbBoxOnChange();

      return () => {
        window.removeEventListener('resize', setBbBoxOnChange);
      };
    },[setSvgRefBbBox]);


    const numberOfTimeSamples = useMemo(() => {
      if(!dateExtent){ return 0; }

      if(itemizationTimeSample === 'hour'){
        return differenceInHours(dateExtent[1], dateExtent[0]);
      }
      else if(itemizationTimeSample === 'day'){
        return differenceInDays(dateExtent[1], dateExtent[0]);
      }

      return 0;
    }, [
      dateExtent,
      itemizationTimeSample
    ]);

    const getTimesampleSlice = useCallback(function getTimesampleSlice(sliceNum){
      if(!dateExtent||!numberOfTimeSamples) {return null;}

      let dateRef;
      switch(itemizationTimeSample){
        case 'hour':
          dateRef = startOfHour(sliceNum*1000 * 60 * 60 + Number(dateExtent[0]));
          break;
        case 'day':
          dateRef = startOfDay(sliceNum*1000 * 60 * 60 * 24 + Number(dateExtent[0]));
          break;
        default:
          break;
      }
      const dateRefTimestamp = Number(dateRef);
      const ref = groupValuesByTimesampleRef.current?.[dateRefTimestamp]||{timeRefTimestamp: dateRefTimestamp, values:{}};

      //console.log('dateRef', {dateRef, num:Number(dateRef), ref, groupValuesByTimesampleRef});

      return ref;
    },[dateExtent, itemizationTimeSample, numberOfTimeSamples]);

    const placeTimesampleLine = useCallback(function(XP: number, xP: number){

      const ref = getTimesampleSlice(Math.round(numberOfTimeSamples*xP));

      if(!ref){
        return;
      }

      const entries = Object.entries(ref.values);

      const timesampleGroup = select(svgRef.current)
        .selectAll('g.timesampleElContainer')
        .attr('style', null)
        .raise()
        .data([XP])
        .join(
          enter => {
            const g = enter.append('g').attr('class', 'timesampleElContainer');

              //console.log('append g', {g, enter, el: svgRef.current, select, selection});
              g
                .append('line').attr('stroke', 'black')
                .attr('x1', 0)
                .attr('x2', 0)
                .attr('y1', 0)
                .attr('y2', height);

              g
               .append('g')
               .attr('class', 'labelsGroup')
               .append('rect');

            return g;
          },
          update => {

            return update;
          },
          exit => {
            exit.remove();
          }
        )
        .attr('transform', `translate(${XP*width},20)`);

      timesampleGroup
        .selectAll('text.date')
        .data([ref])
        .join(
          enter => enter.append('text').attr('class', 'date'),
          update => update,
          exit => exit.remove()
          )

        .html(() => format(new Date(ref.timeRefTimestamp), itemizationTimeSample==='hour'?'MMM d, p':'MMM d'))
        .attr('text-anchor', xP<0.5?'start':'end')
        .attr('x', xP<0.5?'10':'-10')
        .attr('fill', '#404446');

      const labelsGroup =
        timesampleGroup
          .select('g.labelsGroup')
          .attr('transform', `translate(${xP>0.5?-100-10:10},10)`);
          //.moveToFront();

      labelsGroup
        .select('rect')
          .attr('width', '100')
          .attr('height', entries.length?entries.length*1.2+(0.2)+'em':0)
          .attr('fill', 'white')
          .attr('rx', 5)
          .attr('style', 'filter:url(#shadow)');

      labelsGroup
        .selectAll('rect.mark')
        .data(entries)
        .join(
          enter => {
            const mark = enter
              .append('rect')
              .attr('class', 'mark');
            return mark;
          },
          update => {
            return update;
          },
          exit => {
            exit.remove();
          })

        .attr('x', '0.2em')
        .attr('y', (d, i) => (d && i===0? 0.2: i*1.2+0.2)+'em')
        .attr('width', '1em')
        .attr('height', '1em')
        .attr('fill', d => labelToColorMap.get(d[0])||'black');

      labelsGroup
        .selectAll('text.mark')
        .data(entries)
        .join(
          enter => {
            const mark = enter
              .append('text')
              .attr('class', 'mark');
            return mark;
          },
          update => {
            return update;
          },
          exit => {
            exit.remove();
          })

        .html(d => String(d[1]))
        .attr('x', '1.4em')
        .attr('y', (d, i) => (d && i===0? 1.1: i*1.2+1.1)+'em')

        .attr('fill', d => labelToColorMap.get(d[0])||'black');


    }, [
      height,
      numberOfTimeSamples,
      getTimesampleSlice,
      width,
      itemizationTimeSample,
      labelToColorMap
    ]);

    const hideTimesampleLine = useCallback(function(){
      select(svgRef.current)
        .selectAll('g.timesampleElContainer')
        .attr('style', 'display: none');

    },[]);

    // hookupTimesampleLine
    useEffect(function hookUpLineRoller(){
      const svgEl = svgRef.current;

      const graphAreaBoundaryP = [
        (padding.left)/width,
        (width-padding.right)/width
      ];

      const timesampleSumLine = function displayTimasampleSumLine(e: MouseEvent){
        if(!svgRefBbBox){ return; }

        const x = e.clientX - svgRefBbBox.x;
        const xP = x/svgRefBbBox.width;

        //console.log('svgRefBbBox', {svgRefBbBox, e})

        if(xP>=graphAreaBoundaryP[0] && xP<=graphAreaBoundaryP[1]){
          placeTimesampleLine(xP, (xP-graphAreaBoundaryP[0])/(graphAreaBoundaryP[1]-graphAreaBoundaryP[0]) );
        }
        else {
          hideTimesampleLine();
        }
      };

      svgEl?.addEventListener('mousemove', timesampleSumLine);
      svgEl?.addEventListener('mouseleave', hideTimesampleLine);

      return () => {
        svgEl?.removeEventListener('mousemove', timesampleSumLine);
        svgEl?.removeEventListener('mouseleave', hideTimesampleLine);
      };


    },[
      svgRefBbBox,
      placeTimesampleLine,
      hideTimesampleLine,
      padding.left,
      padding.right,
      width
    ]);

    return <StyledSvg className={"amountOverDateGraph" + className ? ' '+className:''} ref={svgRef} width={width} height="auto" viewBox={`0 0 ${width} ${height}`} preserveAspectRatio="xMidYMin meet" overflow="visible">
      <defs>
        <filter id="shadow">
          <feDropShadow dx="0" dy="0" stdDeviation="4" floodOpacity="0.5" />
        </filter>
      </defs>

      {endLabel?
        <>
          <line 
            className="now-label-line" 
            y1={0} 
            y2={height} 
            x1={timeScaler(dateExtent?.[1])+padding.left}
            x2={timeScaler(dateExtent?.[1])+padding.left} 
            strokeWidth="2" />
          <rect className="now-label" y={height-padding.top-7} x={timeScaler(dateExtent?.[1])+padding.left - 25} width="50px" height="25px" rx="3" ry="3" />
          <text className="now-label-text" y={height-padding.top+25-15} x={timeScaler(dateExtent?.[1])+padding.left} textAnchor="middle">{endLabel}</text>    
        </>
      :null}

      {children}

      {label?
        <text
          className="label"
          y={0}
          x={0}
          textAnchor="left">{label?.split(/\n/g)?.map( (txt, i) => 
            <tspan key={i} x={0} y={(i+1)+"em"}>{txt}</tspan>
          )}</text>
      :null}

      
    </StyledSvg>;
  };

const TooltipLabel = styled.span`${({theme}) => `
  ${theme.fontStyles.captionPlaceholder}
`}`;
const StyledSvg = styled.svg`${({theme}) => `
  .axis path, 
  .axis line {
    display: none;
  }

  text {
    ${theme.fontStyles.graphs}
  }

  .grid line {
    stroke: ${theme.newColors.MidnightFoam};
  }

  .grid path {
    display: none;
  }

  /* removing first value on vertical scale */ 
  .axis.right > path + g , 
  .axis.left > path + g {
    display: none;
  }

  .timesampleElContainer {
    display: none;
  }

  .label {
    font-weight: bold;
  }

  .now-label {
    fill: ${theme.newColors.MidnightShores};
  }
  .now-label-text {
    fill: ${theme.newColors.SwissCream};
    font-weight: bold;
  }
  .now-label-line {
    stroke: ${theme.newColors.MidnightShores};
  }
`}`