import styled from 'styled-components';

export type ProgresslineProps = {
  step?: number,
  of?: number,
  progress?: number,
  showSteps?: boolean
}
export const Progressline: React.FC<ProgresslineProps> = ({
  step,
  of: total,
  progress: passedProggress,
  showSteps= true
}) => {

  const progress = passedProggress  || ((step !== undefined && total !== undefined) ? step/total : 1);

  return <Container><Line progress={progress} />{showSteps&&step&&total?<Label>Step {step} <i>(of {total})</i></Label>:null}</Container>;
};

const Line = styled.div.attrs({} as {
  progress: number
})`${({theme, progress}) => `
  flex-grow: 10;
  height: 4px;
  background-color: ${theme.colors.sky.light};
  position: relative;

  ::after {
    content: '';
    position: absolute;
    width: ${progress*100}%;
    height: 100%;
    left: 0;

    background-color: ${theme.colors.primary.base};
    z-index: 100;
  }
`}`;

const Label = styled.div`${({theme}) => `
  padding-left: ${theme.boxPadding.xl5}px;
  text-align: right;

  margin-top: -${theme.fontSize.base/2 - 4}px;
  line-height: ${theme.fontSize.base}px;
  font-size: ${theme.fontSize.base}px;
  color: ${theme.colors.ink.lighter};

  i {
    font-style: normal;
    color: ${theme.colors.sky.dark};
  }
`}`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
`;
