import React, {
  createContext,
  useContext,
  useRef,
  useCallback,
  useMemo,
  PropsWithChildren
} from 'react';

import {
  WigleSearchPayload,
  WigleItem,
  wigleSearch
} from '@ubiety/fe-api';
import {
  format
} from 'date-fns';


import {
  exampleOne,
  exampleFew,
  exampleMany
} from './Wigle_examples';
import { Home } from '../types';

console.log('- [WigleContext] imported');

type WiglesIndex = {
  [key: string]: {
    one?: Array<WigleItem>,
    few?: Array<WigleItem>,
    many?: Array<WigleItem>
  }
}
type WigleContextType = {
  getOne: (ssid:string, params?:WigleSearchPayload) => Promise<Array<WigleItem>>;
  getFew: (ssid:string, params?:WigleSearchPayload) => Promise<Array<WigleItem>>;
  getMany: (ssid:string, params?:WigleSearchPayload) => Promise<Array<WigleItem>>;
};

const WigleContext = createContext<WigleContextType>(undefined!);

/*
  ssid
  city
  closestLat
  closestLong
  resultsPerPage
  lastupdt
*/

// @ts-ignore
export const WigleProvider: React.FC<PropsWithChildren<any>> = ({
  children
}) => {
  const wiglesIndex = useRef<WiglesIndex>({});

  const getOne = useCallback(async function(ssid: string, params:WigleSearchPayload={}){
    const requestUid = ssid+JSON.stringify(params);
    const savedRes = wiglesIndex.current[requestUid]?.one;
    console.log('[WigleProvider] get one', {savedRes});
    if(!savedRes){
      // unbound, limited to return one, sorted by closest
      const wigleResult = await wigleSearch({
        // closestLat: homeLatLong.latitude,
        // closestLong: homeLatLong.longtitude,
        ...params,
        resultsPerPage: 3,
        ssid
      });

      const filteredResults = wigleResult?.results.filter(r => r.trilat && r.trilong);

      if(!wiglesIndex.current[requestUid]){
        wiglesIndex.current[requestUid] = {};
      }

      if(wigleResult){
        wiglesIndex.current[requestUid].one = filteredResults?.slice(0,1)||[];
      }
    }
    return wiglesIndex.current[requestUid].one||[];
  },[]);

  const getFew = useCallback(async function(ssid: string, params:WigleSearchPayload={}){
    const savedRes = wiglesIndex.current[ssid]?.few;
    console.log('[WigleProvider] get few', {savedRes});
    if(!savedRes){
      // bound by region(state) and country, limited to return 25, sorted by closest
      const wigleResult = await wigleSearch({
        // closestLat: homeLatLong.latitude,
        // closestLong: homeLatLong.longtitude,
        ...params,
        resultsPerPage: 25,
        ssid
      });

      const filteredResults = wigleResult?.results.filter(r => r.trilat && r.trilong);

      if(!wiglesIndex.current[ssid]){
        wiglesIndex.current[ssid] = {};
      }

      if(wigleResult){
        wiglesIndex.current[ssid].few = filteredResults||[];
      }
    }
    return wiglesIndex.current[ssid].few||[];
  },[]);

  const getMany = useCallback(async function(ssid: string, params:WigleSearchPayload={}){
    const savedRes = wiglesIndex.current[ssid]?.many;
    console.log('[WigleProvider] get many', {savedRes});
    if(!savedRes){
      // unbound, unsorted, filtered by 1 year old, limited to return 100
      const wigleResult = await wigleSearch({
        //one year ago, 525960 - year in minutes today
        lastupdt: format(new Date(Date.now()-525960 * 60 * 1000), 'yMMcc'),
        ...params,
        resultsPerPage: 100,
        ssid
      });

      const filteredResults = wigleResult?.results.filter(r => r.trilat && r.trilong);

      if(!wiglesIndex.current[ssid]){
        wiglesIndex.current[ssid] = {};
      }

      if(wigleResult){
        wiglesIndex.current[ssid].many = filteredResults||[];
      }
    }
    return wiglesIndex.current[ssid].many||[];
  },[]);

  const value = useMemo(() => ({
    getOne,
    getFew,
    getMany
  }), [
    getOne,
    getFew,
    getMany
  ]);

  return <WigleContext.Provider value={value}>{children}</WigleContext.Provider>;
};

const IS_LOCAL = false; //process.env.NODE_ENV === 'development';

export const useWigleContext: (home?:Home | undefined, addrs?:{
  state?: string,
  country?: string
}) => WigleContextType = (home, addrs) => {
  const {
    getOne,
    getFew,
    getMany
  } = useContext(WigleContext)||{};

  const placeConstrains = useMemo(() =>
    (addrs?.state?{region:addrs.state}:addrs?.country?{country: addrs.country}:{country:'US'}),
    [addrs]
  );
  // populated fns With home
  const populatedGetOne =  useCallback((ssid: string) =>
    IS_LOCAL ? (async () => exampleOne.results)() :
    getOne(ssid,
      (home?{closestLat:home.lat || undefined,closestLong:home.lng || undefined}:{})
    ),[home, getOne]);
  const populatedGetFew =   useCallback((ssid: string) =>
    IS_LOCAL ? (async () => exampleFew.results)() :
    getFew(ssid,
      (home?{closestLat:home.lat || undefined,closestLong:home.lng || undefined, ...placeConstrains}:{...placeConstrains})
    ),[home, getFew, placeConstrains]);
  const populatedGetMany = useCallback((ssid: string) =>
    IS_LOCAL ? (async () => exampleMany.results)() :
    getMany(ssid),[getMany]);

  return {
    getOne: populatedGetOne,
    getFew: populatedGetFew,
    getMany: populatedGetMany
  };
};
