// global styles
import {createGlobalStyle, css, keyframes} from 'styled-components';


const shimmerBackgroundAnimation = keyframes`
  0% {
    background-position: -50px 0;
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  100% {
    background-position: 200px 0;
    opacity: 0;
  }
`;

export const GlobalStyle = createGlobalStyle`${({theme}: any) => css`
  html {
    font-size: ${theme.fontRem};
  }
  
  /* Body */
  body {
    background-color: ${theme.newColors.BgGreen};
  }

  body, p {
    ${theme.fontStyles.body}
  }

  /* Headers */

  h1 > svg,
  h2 > svg,
  h3 > svg,
  h4 > svg,
  h5 > svg,
  h6 > svg {
    vertical-align: bottom;
    margin-bottom: 2px;
  }

  h1.subheading,
  h2.subheading,
  h3.subheading,
  h4.subheading,
  h5.subheading,
  h6.subheading {
    font-weight: normal;
    color: ${theme.colors.ink.dark};
    margin: 0;
  }

  h1 {
    ${theme.fontStyles.headlineOne}
    margin: 0;
  }

  h2 {
    ${theme.fontStyles.headlineTwo}
    margin: 0;
  }

  h3 {
    ${theme.fontStyles.largeBodySubTitle};
    margin: 0;
  }
  h3 + .subheading {
    margin-top: -${theme.fontMargins.xs}px;
  }

  h5 {
    font-size: ${theme.fontSize.l}px;
    margin: ${theme.fontMargins.xxs}px 0;
  }
  h5 + .subheading {
    margin-top: -${theme.fontMargins.xxs}px;
  }

  h6 {
    font-size: ${theme.fontSize.base}px;
    margin: ${theme.fontMargins.xxxs}px 0;
  }
  h6 + .subheading {
    margin-top: -${theme.fontMargins.xxxs}px;
  }
  
  
  p {
    margin: ${theme.fontMargins.xs}px 0;
  }

  /* Links */
  a {
    cursor: pointer;
    text-decoration: none;
  }

  a {
    ${theme.fontStyles.link}
    color: ${theme.newColors.OrangeDream};
  }

  a:hover {
    ${theme.fontStyles.link}
    color: ${theme.newColors.OrangeDream};
  }

  /* basic layout */
  .flex-section .orange-box {
    width: 660px;
  }
  .flex-section {
    display: flex;
  }
  .flex-section > *:last-child {
    display: flex;
    justify-content: flex-end;
  }
  .flex-section > *:first-child {
    display: flex;
    padding-right: 20px;
  }
  .flex-section > *:last-child {
    display: flex;
    padding-left: 20px;
  }

  .icon {
    width: 1.1em;
    height: 1.1em;
    vertical-align: middle;
    margin-top: -2px;
  }
  a .icon,
  button .icon {
    width: 1.5em;
    height: 1.5em;
  }
  .input-group-icon {
    color: ${theme.newColors.MidnightShores} !important;
  }

  /* global styles */
  

  .emptyShimmer:after {
    content: '';
    position: absolute;
    top:0; left:0; right:0; bottom: 0;
    pointer-events: none;
    
    //background: radial-gradient(circle farthest-side at 0 0, rgba(255,255,255,0) -70%,rgba(255,255,255,.7) 3%,rgba(255,255,255,0) 0%) no-repeat;
    
    background: radial-gradient(circle farthest-side at 0 0, rgba(255,255,255,0) 0%,rgba(255,255,255,.7) 15%,rgba(255,255,255,0) 30%) no-repeat;

    //background-position: 0 0;
    animation: 2s infinite ${shimmerBackgroundAnimation};
    /*change speed to see in slow motion*/
    //transition: background-position 1s;
  }

  .emptyShimmer {
    min-height: 1.5rem;
    background-repeat: no-repeat;
    opacity: 0.5 !important;
    position: relative;
    background: url('data:image/svg+xml,<svg width="100%" height="100%" viewBox="0 0 383 16" fill="none" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none"><rect width="383" height="16" rx="2" fill="url(%23paint0_linear_612_2812)"/><defs><linearGradient id="paint0_linear_612_2812" x1="-31.5" y1="0" x2="383" y2="0" gradientUnits="userSpaceOnUse"><stop stop-color="%23E2E4C7"/><stop offset="1" stop-color="%23C2CAA8" stop-opacity="0"/></linearGradient></defs></svg>');
  }



`}`;