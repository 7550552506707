import React from 'react';
import {IconProps} from './_iconProps';

export const Globe: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M29.3333 16C29.3333 23.3638 23.3638 29.3333 16 29.3333M29.3333 16C29.3333 8.63621 23.3638 2.66667 16 2.66667M29.3333 16H2.66667M16 29.3333C8.6362 29.3333 2.66667 23.3638 2.66667 16M16 29.3333C19.335 25.6822 21.2303 20.944 21.3333 16C21.2303 11.0561 19.335 6.31781 16 2.66667M16 29.3333C12.665 25.6822 10.7697 20.944 10.6667 16C10.7697 11.0561 12.665 6.31781 16 2.66667M2.66667 16C2.66667 8.63621 8.6362 2.66667 16 2.66667" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</svg>
