import React from 'react';
import { WidthContainer } from 'layout/Containers';
import styled from 'styled-components';

//
// import {
//   Logo
// } from 'components';
import UbietyLogo from 'assets/svgs/ubiety-logo/ubiety-mark-color.svg';

export const Footer = () => {
  return (
    <FooterContainer>
      <WidthContainer>
        <div>
          {/*<a href="https://roadmap.homeaware.com">Give Feedback</a>
          <a href="https://ubiety.notion.site/Knowledge-Base-13898f25d260458f878fd9ca3622a8fb">Get Support</a>
          <a href="https://www.homeaware.com/privacy-policies">Privacy Policy</a>*/}
        </div>
        <div>
          <p>Powered by Ubiety</p>
          <StyledUbietyLogo src={UbietyLogo} />
        </div>
      </WidthContainer>
    </FooterContainer>
  );
};

export default Footer;

const StyledUbietyLogo = styled.img.attrs( ({theme}) => ({
  variant: 'icon',
  height: theme.fontSize.xl5,
  width: theme.fontSize.xl5
}) )`
  vertical-align: bottom;
  margin-left: 10px;
  position: relative;
  right:-10px;
`;

const FooterContainer = styled.div`${({theme}) => `
  
  z-index: 100;
  display: flex;
  align-items: center;

  background-color: ${theme.newColors.DarkStar};
  margin-top: 30px;
  padding: ${theme.boxMargins.xl4}px ${theme.boxMargins.xl2}px;

  > div > div {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  > div > div:first-child {
    flex-grow: 1;
  }

  a, p {
    ${theme.fontStyles.body}
    color: ${theme.newColors.ShadowMode};
  }

  a:not(:first-child) {
    margin-left: ${theme.boxMargins.xl3}px;
  }

  a:hover {
    color: ${theme.newColors.OrangeDream};
  }
`}`;
