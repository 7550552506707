import React from 'react';
import {IconProps} from './_iconProps';

export const Chrome: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M16 10.6667C13.0545 10.6667 10.6667 13.0545 10.6667 16C10.6667 18.9455 13.0545 21.3334 16 21.3334C18.9455 21.3334 21.3333 18.9455 21.3333 16C21.3333 13.0545 18.9455 10.6667 16 10.6667ZM16 10.6667H28.2267M5.26667 8.08002L11.3867 18.6667M14.5067 29.2534L20.6133 18.6667M29.3333 16C29.3333 23.3638 23.3638 29.3334 16 29.3334C8.6362 29.3334 2.66667 23.3638 2.66667 16C2.66667 8.63622 8.6362 2.66669 16 2.66669C23.3638 2.66669 29.3333 8.63622 29.3333 16Z" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</svg>
