import React from 'react';
import {IconProps} from './_iconProps';

export const WifiOff: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_149_871)">
<path d="M1.33334 1.33331L30.6667 30.6666M22.2933 14.7466C23.3855 15.2796 24.4067 15.947 25.3333 16.7333M6.66667 16.7333C8.64278 15.0811 11.0212 13.9816 13.56 13.5466M14.28 6.73331C17.1366 6.50314 20.0102 6.85087 22.7294 7.75574C25.4486 8.66061 27.9576 10.1041 30.1067 12M1.89334 12C3.74289 10.3651 5.8636 9.06561 8.16 8.15998M11.3733 21.48C12.7269 20.5183 14.3462 20.0017 16.0067 20.0017C17.6671 20.0017 19.2864 20.5183 20.64 21.48M16 26.6666H16.0133" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</g>
<defs>
<clipPath id="clip0_149_871">
<rect width={width} height={height} fill="white"/>
</clipPath>
</defs>
</svg>
