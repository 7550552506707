import styled from 'styled-components';

export type RadioGroupProps = {
  reverse?: boolean;
  variant?: 'pill' | 'normal';
  onChange?: (e:React.ChangeEvent<HTMLInputElement>) => void
}
export const RadioGroup: React.FC<RadioGroupProps> = ({
  reverse,
  children,
  variant='normal',
  ...args}) =>

  <StyledGroup reverse={reverse||undefined} variant={variant} {...args}>{children}</StyledGroup>;

const StyledGroup = styled.div<{
  variant?: RadioGroupProps['variant']
  reverse?: RadioGroupProps['reverse']
}>`${({theme, variant, reverse}) => `
  
  display: flex;
  flex-direction: row;
  
  > *:not(.input-holder):not(input) {
    flex-grow: 1;
  }

  input, label {
    vertical-align: middle;
    line-height: ${theme.fontSize.fontMargins}px;
    margin-right: 0.5em;
  }

  > label:first-child {
    margin-right: 0.5em;
  }

  cursor: pointer;
  > * {
    cursor: pointer;
  }
`}`;
