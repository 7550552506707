import React from 'react';
import {IconProps} from './_iconProps';

export const Radio: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M21.6533 10.3467C22.3972 11.0897 22.9872 11.972 23.3898 12.9431C23.7924 13.9143 23.9996 14.9553 23.9996 16.0067C23.9996 17.058 23.7924 18.099 23.3898 19.0702C22.9872 20.0414 22.3972 20.9237 21.6533 21.6667M10.3467 21.6533C9.60287 20.9103 9.01279 20.028 8.6102 19.0569C8.20761 18.0857 8.00038 17.0447 8.00038 15.9933C8.00038 14.942 8.20761 13.901 8.6102 12.9298C9.01279 11.9586 9.60287 11.0763 10.3467 10.3333M25.4267 6.57333C27.9263 9.0737 29.3305 12.4645 29.3305 16C29.3305 19.5355 27.9263 22.9263 25.4267 25.4267M6.57334 25.4267C4.07373 22.9263 2.66953 19.5355 2.66953 16C2.66953 12.4645 4.07373 9.0737 6.57334 6.57333M18.6667 16C18.6667 17.4728 17.4728 18.6667 16 18.6667C14.5273 18.6667 13.3333 17.4728 13.3333 16C13.3333 14.5272 14.5273 13.3333 16 13.3333C17.4728 13.3333 18.6667 14.5272 18.6667 16Z" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</svg>
