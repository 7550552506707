import {
  AppVersionData
} from '@ubiety/fe-api';

import {
  snakeToCamelCasePropertyNames
} from '../utils';

export const processRawAppVersionData = (data: AppVersionData) => {
  return snakeToCamelCasePropertyNames(data);
};