import {
  getHomeDevices,
  getHomeDevicesPresence,
  updateDevice as updateDeviceApi,
  RawDeviceData,
  RawPresenceData,
  logDeviceTypeChangeEvent,
  logDeviceNameChangeEvent,
  logDeviceAssignedEvent,
  UpdateDevicePayload,
} from '@ubiety/fe-api';
import { createStore } from 'zustand/vanilla';

// import { persist } from 'zustand/middleware';
import { getHomeId } from './selectors';
import { ContextError, FnQueueWithRetries } from '../classes';
import { fnSilentWrapper, processRawDeviceArray, withCatcher , getListAllData } from '../helpers';
import { DataStatus, Maybe, PromiseVoid } from '../types';
import { Device } from '../types/device';

interface IDevicesState {
  devices: Record<string, Device | undefined>;
  rawDevices: Maybe<Array<RawDeviceData>>;
  rawDevicesPresence: Maybe<RawPresenceData>;
  devicesDataStatus: DataStatus;
  deviceArray: Array<Device>;
  devicesByProfileUid: Record<
    string,
    Record<string, Device | undefined> | undefined
  >;
  deviceArrayByProfileUid: Record<string, Array<Device> | undefined>;
}

type DevicesAction = {
  reset: () => void;

  getDevices: () => PromiseVoid;
  getDevicesSilently: () => Promise<Maybe<boolean>>;
  getDevicesPresence: () => PromiseVoid;
  getDevicesPresenceSilently: () => Promise<Maybe<boolean>>;
  updateDevice: (deviceUid: number, data: UpdateDevicePayload) =>
    Promise<Maybe<RawDeviceData>>;
  updateDeviceSilently: (deviceUid: number, data: UpdateDevicePayload) => 
    Promise<Maybe<boolean>>;
  processRawDevicesIntoTheState: () => void;
};

export type DevicesState = IDevicesState & DevicesAction;

const INITIAL_STATE: IDevicesState = {
  devices: {},
  deviceArray: [],
  rawDevices: null,
  rawDevicesPresence: null,
  devicesByProfileUid: {},
  deviceArrayByProfileUid: {},
  devicesDataStatus: undefined,
};

const devicesQueue = new FnQueueWithRetries();
const devicesPresenceQueue = new FnQueueWithRetries();

export const devicesStore = createStore<DevicesState>()(
  // persist(
    (set, get) => ({
      ...INITIAL_STATE,
      reset: () => set(INITIAL_STATE),
      getDevices: withCatcher('DevicesStore.getDevices', async () => {
        const homeId = getHomeId();
        if (!homeId) {
          throw new ContextError('no home id to get devices from', {isLocal: true});
        }

        const result = await getListAllData<typeof getHomeDevices, RawDeviceData>({ fn: getHomeDevices, payload: {homeId}, queque: devicesQueue });
        if (result) {
          set({ rawDevices: result as Array<RawDeviceData> });
        }
      }, (e) => {
        if (e.isLocal) {return;}
        set({ devicesDataStatus: 'error' });
      }),
      getDevicesSilently: fnSilentWrapper(() => get().getDevices()),
      getDevicesPresence: withCatcher('DevicesStore.getDevicesPresence', async () => {
        const homeId = getHomeId();
        if (!homeId) {
          throw new ContextError('no home id to get presence devices from', {isLocal: true});
        }

        const res = await getListAllData<typeof getHomeDevicesPresence, RawPresenceData>({ fn: getHomeDevicesPresence, payload: {homeId}, queque: devicesPresenceQueue });
        if (res) {
          set({ rawDevicesPresence: res as RawPresenceData });
        } else {
          throw new ContextError('res get presence devices is undefined ', {isLocal: false});
        }
      }, (e) => {
        if (e.isLocal) {return;}
        set({ devicesDataStatus: 'error' });
      }),
      getDevicesPresenceSilently: fnSilentWrapper(() => get().getDevicesPresence()),

      updateDevice: withCatcher('DevicesStore.updateDevice', async (deviceUid, data) => {
        const homeId = getHomeId();
        if (!homeId) {
          throw new ContextError('no home id to update device', {isLocal: true});
        }

        const { rawDevices } = get();
        const currentDeviceData: Partial<RawDeviceData> = rawDevices?.find(d => String(d.uid) === String(deviceUid)) || {};

        const payload = { ...data };

        if (Object.prototype.hasOwnProperty.call(data, 'profile_id') &&
          !Object.prototype.hasOwnProperty.call(data, 'assigned_by_qr')
        ) {
          payload.assigned_by_qr = false;
        }

        const response = await updateDeviceApi(homeId, deviceUid, payload);

        // Log device type change any time they are different and a new device type was passed in
        if (currentDeviceData.profile_id !== payload.profile_id) {
          logDeviceAssignedEvent({
            deviceUid: currentDeviceData.uid ? String(currentDeviceData.uid) : '',
            fromProfileUid: currentDeviceData.profile_id ? String(currentDeviceData.profile_id) : '',
            toProfileUid: payload.profile_id ? String(payload.profile_id) : ''
          });
        }
        if (currentDeviceData.device_type !== payload.device_type) {
          logDeviceTypeChangeEvent({
            fromType: currentDeviceData.device_type,
            toType: payload.device_type,
            deviceUid: `${deviceUid}`,
          });
        }
        if (Object.prototype.hasOwnProperty.call(payload, 'name') && currentDeviceData.name !== payload.name) {
          logDeviceNameChangeEvent({
            fromName: currentDeviceData.name,
            toName: payload.name?.toString(),
            deviceUid: `${deviceUid}`,
          });
        }

        // update device data in raw devices
        const updatedDevice = response.data || {};

        return updatedDevice;
      }, (e) => {
        if (e.isLocal) {return;}
      }),
      updateDeviceSilently: fnSilentWrapper((deviceUid, data) => get().updateDevice(deviceUid, data)),

      processRawDevicesIntoTheState: () => {
        const { rawDevices, rawDevicesPresence } = get();
        if (!rawDevices) {return;}

        const {
          newDevices,
          newDeviceArray,
          newDevicesByProfileUid,
          newDeviceArrayByProfileUid
        } = processRawDeviceArray(rawDevices, rawDevicesPresence || undefined);

        set({
          devicesDataStatus: 'success',
          devices: newDevices,
          deviceArray: newDeviceArray,
          devicesByProfileUid: newDevicesByProfileUid,
          deviceArrayByProfileUid: newDeviceArrayByProfileUid
        });
      }
    }),
  //   {
  //     name: 'devices-storage',
  //   },
  // ),
);
