import React from 'react';
import {IconProps} from './_iconProps';

export const Crop: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_149_332)">
<path d="M8.17334 1.33334L8 21.3333C8 22.0406 8.28095 22.7189 8.78105 23.219C9.28115 23.7191 9.95942 24 10.6667 24H30.6667M1.33334 8.17334L21.3333 8.00001C22.0406 8.00001 22.7189 8.28096 23.219 8.78106C23.719 9.28116 24 9.95943 24 10.6667V30.6667" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</g>
<defs>
<clipPath id="clip0_149_332">
<rect width={width} height={height} fill="white"/>
</clipPath>
</defs>
</svg>
