


import { createStore } from 'zustand/vanilla';

import { ContextError, fnSilentWrapper, Listeners, withCatcher } from '@ubiety/fe-context';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { getAuth, User, signInWithEmailAndPassword, sendPasswordResetEmail, signOut} from 'firebase/auth';
import { getDatabase, ref, update as dbUpdate, set as dbSet} from 'firebase/database'
import { persistedStateType, SurveyTemplatesList } from './types';
import { getApp } from 'firebase/app';

interface IFirebaseState {
  isUserLoaded: boolean;
  user: User | null;
  persistedStateLoaded: boolean;
  persistedState: persistedStateType | undefined;
  surveysTemplates: SurveyTemplatesList | undefined;
}

type FirebaseAction = {
  setFirebaseUser: (user: User | null) => void;
  setUserLoaded: (isLoaded: boolean) => void;
  setPersistedState: (persistedState: persistedStateType | undefined) => void;
  setPersistedStateLoaded: (isLoaded: boolean) => void;
  setSurveysTemplates: (templates: SurveyTemplatesList | undefined) => void;
  singInFirebaseUser: (email: string, password: string) => Promise<void>;
  singInFirebaseUserSilently: (email: string, password: string) => Promise<boolean | null>;
  sendResetPasswordLink: (email: string) => Promise<void>;
  sendResetPasswordLinkSilently: (email: string) => Promise<boolean | null>;
  signOutFirebaseUser: () => Promise<void>;
  signOutFirebaseUserSilently: () => Promise<boolean | null>;
  checkUserEmailVerification: () => Promise<void>;
  checkUserEmailVerificationSilently: () => Promise<boolean | null>;
  persistUserState: (payload: persistedStateType, update?: boolean) => Promise<void>;
  persistUserStateSilently: (payload: persistedStateType, update?: boolean) => Promise<boolean | null>;
}

export type FirebaseState = IFirebaseState & FirebaseAction;

const INITIAL_STATE: IFirebaseState = {
  user: null,
  isUserLoaded: false,
  persistedState: undefined,
  surveysTemplates: undefined,
  persistedStateLoaded: false,
};

export const onUserSignOutListeners = new Listeners();

export const firebaseStore = createStore<FirebaseState>()(
  (set, get) => ({
    ...INITIAL_STATE,
    setFirebaseUser: (user) => set({ user }),
    setUserLoaded: (isLoaded) => set({ isUserLoaded: isLoaded }),
    singInFirebaseUser: withCatcher('FirebaseStore.singInFirebaseUser', async (email, password) => {
      const firebaseApp = getApp();
      const auth = getAuth(firebaseApp);
      await  signInWithEmailAndPassword(auth, email.trim(), password);

      get().setUserLoaded(true);
      const analytics = getAnalytics(firebaseApp);
      logEvent(analytics, 'login', { method: 'email' });
    }),
    sendResetPasswordLink: withCatcher('FirebaseStore.sendResetPasswordLink', async (email) => {
      const firebaseApp = getApp();
      const auth = getAuth(firebaseApp);
      await sendPasswordResetEmail(auth, email);
    }),
    sendResetPasswordLinkSilently: fnSilentWrapper((...args) => get().sendResetPasswordLink(...args)),
    singInFirebaseUserSilently: fnSilentWrapper((...args) => get().singInFirebaseUser(...args)),
    signOutFirebaseUser: withCatcher('FirebaseStore.signOutFirebaseUser', async () => {
      const firebaseApp = getApp();
      await onUserSignOutListeners.fire();
      const auth = getAuth(firebaseApp);
      signOut(auth);
    }),
    signOutFirebaseUserSilently: fnSilentWrapper(() => get().signOutFirebaseUser()),

    // It uses then..catch because firebase auth module has weird bug with async..await
    checkUserEmailVerification: withCatcher('FirebaseStore.checkUserEmailVerification', async () => {
      const firebaseApp = getApp();
      const auth = getAuth(firebaseApp);
      auth?.currentUser?.reload().then(() => {
        if ( auth?.currentUser?.emailVerified) {
          set({ user: auth?.currentUser });
        }
      });
    }),
    checkUserEmailVerificationSilently: fnSilentWrapper(() => get().checkUserEmailVerification()),
    // edgeDaemon setup, It uses then..catch because firebase auth module has weird bug with async..await
    persistUserState: withCatcher('FirebaseStore.persistUserState', async (payload, updateAction = true) => {
      const fbUid = get().user?.uid;

      if (fbUid) {
        const database = getDatabase();
        const _ref = ref(database, '/persistedState/' + fbUid);
        console.log('[FirebaseStore] trying to set payload', { payload, _ref });
        const action = updateAction ? dbUpdate : dbSet;

        action?.(_ref, payload as never)?.then((response) => {
          return response;
        }).catch((error) => {
          throw new ContextError(`[FirebaseStore] couldn't set payload, Error: ${JSON.stringify(error)}`, {isLocal: false})
        });
      }
    }),
    persistUserStateSilently: fnSilentWrapper((payload, updateAction) => get().persistUserState(payload, updateAction)),

    setPersistedState: (state) => set({ persistedState: state }),
    setPersistedStateLoaded: (isLoaded) => set({ persistedStateLoaded: isLoaded }),
    setSurveysTemplates: (templates) => set({ surveysTemplates: templates }),
  }),
);