import styled from 'styled-components';

export const Filler = styled.div`
  flex-grow: 1
`;

export const OrangeBox = styled.div.attrs({
  className: 'orange-box'
})`${({theme}) => `
  background-color: ${theme.newColors.OrangeCream};
  padding: ${theme.boxPadding.l}px ${theme.boxPadding.l}px ${theme.boxPadding.xl}px;
  > * {
    max-width: 100%;
  }
  max-width: 660px;
  box-sizing: border-box;

`}`;
