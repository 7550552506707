import React from 'react';
import {IconProps} from './_iconProps';

export const PhoneCall: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M29.5169 22.8935V26.8935C29.5184 27.2648 29.4424 27.6324 29.2936 27.9726C29.1448 28.3129 28.9267 28.6183 28.653 28.8693C28.3794 29.1204 28.0564 29.3115 27.7046 29.4304C27.3528 29.5494 26.9801 29.5936 26.6103 29.5602C22.5074 29.1143 18.5663 27.7124 15.1036 25.4668C11.882 23.4197 9.15071 20.6884 7.1036 17.4668C4.85023 13.9884 3.44792 10.0282 3.01026 5.90683C2.97694 5.53812 3.02076 5.16651 3.13893 4.81566C3.2571 4.46481 3.44702 4.14241 3.69662 3.86899C3.94621 3.59556 4.25 3.37711 4.58865 3.22752C4.9273 3.07794 5.29338 3.00051 5.6636 3.00016H9.6636C10.3107 2.99379 10.938 3.22293 11.4286 3.64487C11.9192 4.06681 12.2397 4.65276 12.3303 5.29349C12.4991 6.57358 12.8122 7.83046 13.2636 9.04016C13.443 9.51739 13.4818 10.036 13.3755 10.5347C13.2691 11.0333 13.0221 11.491 12.6636 11.8535L10.9703 13.5468C12.8683 16.8849 15.6322 19.6488 18.9703 21.5468L20.6636 19.8535C21.0261 19.495 21.4838 19.248 21.9824 19.1416C22.481 19.0353 22.9997 19.0741 23.4769 19.2535C24.6866 19.7049 25.9435 20.018 27.2236 20.1868C27.8713 20.2782 28.4628 20.6044 28.8856 21.1035C29.3085 21.6025 29.5332 22.2396 29.5169 22.8935Z" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</svg>
