import React from 'react';
import {IconProps} from './_iconProps';

export const Camera: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M30.6666 25.3333C30.6666 26.0406 30.3856 26.7189 29.8855 27.219C29.3854 27.719 28.7072 28 27.9999 28H3.99992C3.29267 28 2.6144 27.719 2.1143 27.219C1.6142 26.7189 1.33325 26.0406 1.33325 25.3333V10.6667C1.33325 9.95942 1.6142 9.28115 2.1143 8.78105C2.6144 8.28095 3.29267 8 3.99992 8H9.33325L11.9999 4H19.9999L22.6666 8H27.9999C28.7072 8 29.3854 8.28095 29.8855 8.78105C30.3856 9.28115 30.6666 9.95942 30.6666 10.6667V25.3333Z" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
<path d="M15.9999 22.6667C18.9454 22.6667 21.3333 20.2789 21.3333 17.3333C21.3333 14.3878 18.9454 12 15.9999 12C13.0544 12 10.6666 14.3878 10.6666 17.3333C10.6666 20.2789 13.0544 22.6667 15.9999 22.6667Z" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</svg>
