import { apiRequest } from '../apiRequest';
import { RawSensor, GetSensorHealthTimelinePayload, SensorHealthTimeline, SensorHealth, CreateSensorPayload, CreateSensorResponse } from './types';

export const getSensor = (homeId: number, sensorId: number) =>
  apiRequest<RawSensor>({
    service: 'feApiGateway',
    path: `homes/${homeId}/sensors/${sensorId}/`
  });

export const getSensors = ({homeId}: {homeId: number}) =>
  apiRequest<RawSensor>({
    service: 'feApiGateway',
    path: `homes/${homeId}/sensors`
  });

export const getSensorHealthTimeline = (payload: GetSensorHealthTimelinePayload) =>
  apiRequest<SensorHealthTimeline>({
    service: 'feApiGateway',
    path: `homes/${payload.home_id}/sensors/${payload.sensor_id}/health-timeline?start_timestamp=${payload.start_timestamp}&bin_size=${payload.bin_size}`,
  });

export const deleteSensor = (homeId: number, sensorId: number) =>
  apiRequest<string>({
    service: 'feApiGateway',
    path: `homes/${homeId}/sensors/${sensorId}`,
    method: 'DELETE'
  });

export const getSensorHealth = (homeId: number, sensorId: number) =>
  apiRequest<SensorHealth>({
    service: 'feApiGateway',
    path: `homes/${homeId}/sensors/${sensorId}/health`,
  });

export const createSensor = (homeId: number, payload: CreateSensorPayload) =>
  apiRequest<CreateSensorResponse>({
    service: 'feApiGateway',
    method: 'POST',
    path: `homes/${homeId}/sensors`,
    data: payload,
  });