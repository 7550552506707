import React from 'react';
import {IconProps} from './_iconProps';

export const MicOff: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_149_577)">
<path d="M1.33334 1.33333L30.6667 30.6667M20 12.4533V5.33333C20.001 4.34137 19.6334 3.38442 18.9685 2.64826C18.3036 1.9121 17.3889 1.44925 16.402 1.34956C15.4151 1.24987 14.4263 1.52045 13.6276 2.10878C12.829 2.69711 12.2774 3.56121 12.08 4.53333M22.6667 22.6C21.3651 23.9286 19.6962 24.8379 17.8742 25.2115C16.0521 25.585 14.1601 25.4056 12.4407 24.6964C10.7213 23.9871 9.25302 22.7804 8.22424 21.2309C7.19545 19.6814 6.65307 17.8599 6.66668 16V13.3333M25.3333 13.3333V16C25.3329 16.5499 25.2838 17.0987 25.1867 17.64M16 25.3333V30.6667M10.6667 30.6667H21.3333M12 12V16C12.0007 16.7906 12.2357 17.5633 12.6753 18.2204C13.1148 18.8776 13.7393 19.3897 14.4698 19.6922C15.2002 19.9947 16.0039 20.0739 16.7794 19.9198C17.5549 19.7658 18.2673 19.3854 18.8267 18.8267L12 12Z" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</g>
<defs>
<clipPath id="clip0_149_577">
<rect width={width} height={height} fill="white"/>
</clipPath>
</defs>
</svg>
