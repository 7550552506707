import React from 'react';
import {IconProps} from './_iconProps';

export const CornerLeftUp: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M18.6667 12L12 5.33331M12 5.33331L5.33334 12M12 5.33331V21.3333C12 22.7478 12.5619 24.1044 13.5621 25.1045C14.5623 26.1047 15.9188 26.6666 17.3333 26.6666H26.6667" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</svg>
