import React from 'react';
import {IconProps} from './_iconProps';

export const Percent: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M25.3333 6.66668L6.66667 25.3333M12 8.66668C12 10.5076 10.5076 12 8.66667 12C6.82572 12 5.33334 10.5076 5.33334 8.66668C5.33334 6.82573 6.82572 5.33334 8.66667 5.33334C10.5076 5.33334 12 6.82573 12 8.66668ZM26.6667 23.3333C26.6667 25.1743 25.1743 26.6667 23.3333 26.6667C21.4924 26.6667 20 25.1743 20 23.3333C20 21.4924 21.4924 20 23.3333 20C25.1743 20 26.6667 21.4924 26.6667 23.3333Z" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</svg>
