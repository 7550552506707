
import { accountStore } from '@ubiety/fe-context/src/store';

import { firebaseStore } from './firebaseStore';
import { getAuth } from 'firebase/auth';
import { getAnalytics, setUserProperties } from 'firebase/analytics';
import { getDatabase, onValue, ref } from 'firebase/database';
import { getApp } from 'firebase/app';

const {
  setFirebaseUser,
  setUserLoaded,
  setPersistedState,
  setPersistedStateLoaded,
  checkUserEmailVerification,
  setSurveysTemplates,
} = firebaseStore.getState();


const { setFbUserId } = accountStore.getState();

export const initAppStores = () => {
  const firebaseApp = getApp();
  console.log('[initAppStores] init called');
  // Firebase 
  const auth = getAuth(firebaseApp);
  const analytics = getAnalytics(firebaseApp);
  const database = getDatabase(firebaseApp);

  const removeSubscribption = auth.onAuthStateChanged((u: any) => {
    console.log('[initAppStores] auth changed, got user:', !!u, u?.uid);
    setFirebaseUser(u);
    setUserLoaded(true);
    setFbUserId(u?.uid);
  });

  let intervalIdCheckUserEmailVerification: ReturnType<typeof setTimeout>;
  const unsubscribeFirebaseStore = firebaseStore.subscribe((state, prevState) => {
    const user = state.user;
    const prevUser = prevState.user;

    if (user?.uid !== prevUser?.uid) {
      if (user?.uid && user?.email) {
        /* Set Analytics */
        setUserProperties(analytics, {
          uid: user.uid,
          email: user.email,
        });

        /* Automatically keep checking if the current user has verified their email */
        if (!user?.emailVerified) {
          checkUserEmailVerification();
          intervalIdCheckUserEmailVerification = setInterval(checkUserEmailVerification, 5 * 1000); // 5s
        } else {
          clearInterval(intervalIdCheckUserEmailVerification);
        }

        /* Hookup Persisted State */
        const reff = ref(database, '/persistedState/' + user.uid);

        onValue(reff, function updateLocalPersistedState(snapshot: any) {
            const value = snapshot.val();
            console.log('[initAppStores] settingPersistedState received data', value);
            setPersistedState(value);
            setPersistedStateLoaded(true);
          });

        // set initial value
        setPersistedState({});
      }

      if (!user?.uid) {
        // Firebase reset 
        setFirebaseUser(null);
        setUserLoaded(true);
  
        // Sentry reset
        // TODO: install sentry logging
        // Sentry.configureScope((scope) => scope.setUser(null));
  
        // Analytics reset 
        setUserProperties(analytics, {
          uid: null,
          account_id: null,
          accountUid: null,
          email: null,
        });
      }
    }
  });

  const reff = ref(database, '/surveyTemplates');

  const unsubscribeUpdateLocalSurveyData = onValue(reff, function updateLocalSurveyData(snapshot: any) {
      const value = snapshot.val();

      console.log('[surveyTemplates] surveyTemplates data', value);

      setSurveysTemplates(value);
    });

  return () => {
    unsubscribeUpdateLocalSurveyData();
    removeSubscribption();
    unsubscribeFirebaseStore();
    if (intervalIdCheckUserEmailVerification) {
      clearInterval(intervalIdCheckUserEmailVerification);
    }
  };
};