import React, {
  createContext,
  useContext,
  useMemo,
  useState,
  useCallback,
  ReactNode,
  CSSProperties,
  useRef
} from 'react';
import {
  PopupProps
} from 'components/OverlayDisplay';
import styled, { keyframes, css } from 'styled-components';

type TooltipOptions = {
  display?: 'top',
  offset?: number,
  containerClassName?: string,
  holderClassName?: string,
  shiftOffset?: number,
  variant?: 'small'|'base'
}
type OverlayProps = {
  loading: boolean,
  popup: PopupProps | undefined,
  setLoading: (t: boolean) => void,
  showPopup: (props: PopupProps) => void
  hidePopup: () => void,
  showTooltip: (el: HTMLElement|SVGSVGElement, content: ReactNode, options?:TooltipOptions) => void,
  hideTooltip: () => void, 
  attachTooltip: (el: HTMLElement|SVGSVGElement|null, content: ReactNode, options?: (TooltipOptions & {
      showEvent?: 'click'|'mouseover'
    })) => void
  deattachTooltip: (el: HTMLElement|SVGSVGElement|null) => void
}
export const OverlayContext = createContext<OverlayProps>(undefined!);

export const useOverlayContext = () => useContext(OverlayContext);

const toolTipsMap = new Map();

export const OverlayProvider: React.FC = ({children}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [popup, setPopup] = useState<PopupProps>();
  const [tooltipProps, setTooltipProps] = useState<{
    holderStyle?: CSSProperties;
    containerStyle?: CSSProperties;
    children?: ReactNode;
    containerClassName?: string;
    holderClassName?:string;
  }>({});
  const preventDismissablePopingOnShow = useRef(false);

  const hidePopup = useCallback(function (e?: Event){
    if(preventDismissablePopingOnShow.current){
      preventDismissablePopingOnShow.current = false;
      return;
    }
    window.removeEventListener('click', hidePopup)
    setPopup(undefined);
  },[]);
  const showPopup = useCallback(function showPopup(props:PopupProps){
    if(props.dismissable){
      preventDismissablePopingOnShow.current = true;
      window.addEventListener('click', hidePopup)
    }
    setPopup({...props});
  },[
    hidePopup
  ]);

  const showTooltip = useCallback(function displayTooltip(el: HTMLElement | SVGSVGElement, content: ReactNode, {
    display='top',
    offset=2,
    containerClassName='tooltip-container',
    holderClassName='',
    shiftOffset=0,
    variant='base'
  }={}){
    const bbRect = el.getBoundingClientRect();
    const holderStyle: CSSProperties = {};
    const bodyBB = document.querySelector('body')?.getBoundingClientRect()

    if(display === 'top' && bodyBB){
      holderStyle.bottom = (document.body.clientHeight-(bbRect.top+window.scrollY)-offset)+'px';
      holderStyle.left = 0;
      const bodyWidth = document.body.clientWidth;
      const newWidth = (bbRect.left-bodyBB.left+(bbRect.width/2))*2;
      if(newWidth > bodyWidth){
        holderStyle.width =  bodyWidth +'px';
        holderStyle.paddingLeft = ((newWidth - bodyWidth))+'px';
      } else {
        holderStyle.width =  newWidth +'px';
        holderStyle.paddingLeft = '0px';
      }
    }

    const containerStyle: CSSProperties = {};
    if(shiftOffset){
      containerStyle.position = 'relative';
      containerStyle.left = shiftOffset;
    }

    function clickHandler(){
      window.removeEventListener('click', clickHandler);
      setTooltipProps({});
    }
    window.addEventListener('click', clickHandler);

    setTooltipProps({
      holderStyle,
      containerStyle,
      children: content,
      containerClassName: (containerClassName||'')+' '+variant,
      holderClassName: (holderClassName||'')+' '+display
    });

  },[]);

  const hideTooltip = useCallback(function hideTooltip(){
    setTooltipProps({});
  },[]);

  const deattachTooltip = useCallback((el) => {
    const killOldEventsFn = toolTipsMap.get(el);
    if(typeof killOldEventsFn === 'function'){
      killOldEventsFn();
    }
  }, []);
  const attachTooltip = useCallback((el, content, {
    showEvent='click',
    ...options
  }={}) => {
    if(!el){ return; } 
    const newEventHandler = (e:Event) => {
      e.stopPropagation();
      showTooltip(el, content, options)
    };
    deattachTooltip(el)
    el.addEventListener(showEvent, newEventHandler);
    toolTipsMap.set(el, () => el.removeEventListener(showEvent, newEventHandler))
  },[
    showTooltip,
    deattachTooltip
  ]);

  const value = useMemo(() => ({
    loading,
    popup,
    setLoading,
    showPopup,
    hidePopup,
    showTooltip,
    hideTooltip,
    attachTooltip,
    deattachTooltip
  }),[
    loading,
    popup,
    setLoading,
    showPopup,
    hidePopup,
    showTooltip,
    hideTooltip,
    attachTooltip,
    deattachTooltip
  ]);

  return <OverlayContext.Provider value={value}>

        {children}
        <TooltipContainerHolderFrame>
          <TooltipContainerHolder style={tooltipProps.holderStyle} className={tooltipProps.holderClassName}>
            <TooltipContainer 
              style={tooltipProps.containerStyle}
              children={tooltipProps.children} 
              className={tooltipProps.containerClassName} />
            <div className="before" />
            <div className="after" />
          </TooltipContainerHolder>
        </TooltipContainerHolderFrame>
    </OverlayContext.Provider>;
};

const appear = keyframes`
  0% {
    transform: translateY(10px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
`
const TooltipContainerHolderFrame = styled.div`${({theme}) => `
  max-width: 100%;
  overflow: hidden;
`}`;
const TooltipContainerHolder = styled.div`${({theme}) => css`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;

  &.top .before {
    background-color: ${theme.newColors.SwissCream};
    border: 1px solid ${theme.newColors.MidnightFoam};

    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    bottom: -5px;
    transform: rotate(45deg);
    z-index: 1;
  }
  &.top .after {
    background-color: ${theme.newColors.SwissCream};
    bottom: 1px;

    content: '';
    position: absolute;
    width: 20px;
    height: 9px;
    border: none;
    z-index: 1;
  }

  &.top {
    animation: ${appear} 200ms;
  }

  pointer-events: none !important;
`}`;
const TooltipContainer = styled.div`${({theme}) => `
  border: 1px solid ${theme.newColors.MidnightFoam};
  background-color: ${theme.newColors.SwissCream};
  border-radius: 3px;

  &.small {
    padding: ${theme.boxPadding.base*0.8}px ${theme.boxPadding.l}px;
  }
  &.base {
    padding: ${theme.boxPadding.xl}px ${theme.boxPadding.xl2}px;
  }
  ${theme.fontStyles.captionPlaceholder}
`}`;