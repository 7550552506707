import React from 'react';
import {IconProps} from './_iconProps';

export const Airplay: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.66666 22.6667H5.33332C4.62608 22.6667 3.9478 22.3857 3.4477 21.8856C2.94761 21.3855 2.66666 20.7072 2.66666 20V6.66667C2.66666 5.95942 2.94761 5.28115 3.4477 4.78105C3.9478 4.28095 4.62608 4 5.33332 4H26.6667C27.3739 4 28.0522 4.28095 28.5523 4.78105C29.0524 5.28115 29.3333 5.95942 29.3333 6.66667V20C29.3333 20.7072 29.0524 21.3855 28.5523 21.8856C28.0522 22.3857 27.3739 22.6667 26.6667 22.6667H25.3333M16 20L22.6667 28H9.33332L16 20Z" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</svg>
