import React from 'react';
import {IconProps} from './_iconProps';

export const Twitter: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M30.6667 3.99998C29.3898 4.90062 27.9761 5.58946 26.48 6.03998C25.677 5.11666 24.6098 4.46224 23.4227 4.16521C22.2356 3.86819 20.986 3.94291 19.8428 4.37925C18.6996 4.81559 17.7179 5.59252 17.0306 6.60494C16.3434 7.61736 15.9836 8.81643 16 10.04V11.3733C13.6568 11.4341 11.335 10.9144 9.24135 9.86057C7.14766 8.80675 5.34709 7.25149 4 5.33331C4 5.33331 -1.33333 17.3333 10.6667 22.6666C7.9207 24.5306 4.64954 25.4652 1.33333 25.3333C13.3333 32 28 25.3333 28 9.99998C27.9988 9.62858 27.9631 9.25811 27.8933 8.89331C29.2541 7.5513 30.2144 5.85693 30.6667 3.99998Z" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</svg>
