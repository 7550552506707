import React from 'react';
import {IconProps} from './_iconProps';

export const Sun: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_149_751)">
<path d="M16 1.33331V3.99998M16 28V30.6666M5.62667 5.62665L7.52 7.51998M24.48 24.48L26.3733 26.3733M1.33334 16H4M28 16H30.6667M5.62667 26.3733L7.52 24.48M24.48 7.51998L26.3733 5.62665M22.6667 16C22.6667 19.6819 19.6819 22.6666 16 22.6666C12.3181 22.6666 9.33334 19.6819 9.33334 16C9.33334 12.3181 12.3181 9.33331 16 9.33331C19.6819 9.33331 22.6667 12.3181 22.6667 16Z" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</g>
<defs>
<clipPath id="clip0_149_751">
<rect width={width} height={height} fill="white"/>
</clipPath>
</defs>
</svg>
