import React, {ChangeEvent, useState, useCallback, useRef, useMemo, useEffect} from 'react';
import {
  DrophoverCheckSelect,
  InputGroup,
  ThemedDataTable,
  Pagination,
  Loading,
  InputGroupType
} from 'components';
import {
  ProcessedReport, 
  processReportItem,
  processRawCellItemToReportItem,
  ProcessedReportItem
} from 'helpers';
import styled from 'styled-components';
import {
  format
} from 'date-fns';
import {
  ChevronDown,
  ChevronUp
} from 'theme/icons';
import {
  startOfWeek,
  startOfDay
} from 'date-fns';
import {
  providerToLabelName,
  processMixinItemToReportItem
} from 'helpers';
import {
  useDynamicRawCellItemsList
} from './useRawCellList';
import { useSensorsStore, useUserDataContext } from 'context';
import {
  establishementCauseToUserFacingType,
  establishementCauseToUserFacingValue
} from 'helpers';

export const CellItemsDataDisplay: React.FC<{
  processedReportItems?: ProcessedReport['items'],
  pageSize: number;
  showDateTime?: boolean;
}> = ({
  processedReportItems: passedProcessedReportItems,
  pageSize,
  showDateTime=false
}) => {
  const [dateSortingDesc, setDateSortingDesc] = useState<boolean>(true);
  const [filters, setFilters] = useState<{
    timestart: number,
    providers: Array<string>,
    type: 'cell'|'cell-scan'|'all',
    causes: Array<'all'|ReturnType<typeof establishementCauseToUserFacingType>>
  }>({
    timestart: -Infinity,
    providers: ['all'],
    type: 'all',
    causes: ['all']
  });
  const now = useRef(Date.now()).current;
  const staticDataSource = useMemo(() => !!passedProcessedReportItems,[passedProcessedReportItems]);
  const [processedReportItems, setProcessedReportItems] = useState(passedProcessedReportItems);
  const [filteredItems, setFilteredItems] = useState(processedReportItems);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const totalPages = useMemo(() => Math.ceil((filteredItems?.length||0)/20),[filteredItems]);
  const [searchString, setSearchString] = useState<string>('');
  const [classesMap, setClassesMap] = useState(new Map());
  const {sensorArray} = useSensorsStore(s => s);
  const sensorId = useMemo(() => sensorArray[0]?.uid || '',[sensorArray]);
  const { getMo, items, loading } = useDynamicRawCellItemsList({
    pageSize, 
    sensorId: String(sensorId),
    cell: true,
    cellScan: true
  });
  const {
    userDataMixins
  } = useUserDataContext();

  // Effects
  useEffect(function processRawItemsAndMixinsAndSetReport(){
    if(staticDataSource){ return; }
    const newClasseMap = new Map();
    const proccessedReportItems = items?.map(it => {
      const pit = processReportItem(processRawCellItemToReportItem(it))
      if(pit.establishmentCause === 'emergency'){
        newClasseMap.set(pit, 'emergency');
      }
      return pit;
    })
    const processedMixins = (userDataMixins||[])?.map(mx => {
      const pmx = processMixinItemToReportItem(mx)
      if(pmx?.establishmentCause === 'emergency'){
        newClasseMap.set(pmx, 'emergency');
      }
      return pmx;
    })?.filter(mx => mx !== null) as ProcessedReportItem[];
    setClassesMap(newClasseMap);
    setProcessedReportItems([...proccessedReportItems,...processedMixins]);
  },[
    staticDataSource,
    userDataMixins,
    items
  ]);
  useEffect(function triggerInitialitemsLoadingIfNotAStaticDataSource(){
    if(staticDataSource){ return; }
    console.log('[CellItemsDataDisplay] initial mo data request');
    getMo();   
  },[
    staticDataSource,
    getMo
  ])
  useEffect(function setProcessedReportOnChange(){
    setProcessedReportItems(passedProcessedReportItems);
  },[passedProcessedReportItems]);

  useEffect(function filterCurrentDataOnFiltersChange(){
    const keyedProviders: any = {};
    filters.providers?.forEach(p => {
      keyedProviders[p] = true;
    });
    const keyedCauses: any = {};
    filters.causes?.forEach(p => {
      keyedCauses[p] = true;
    });
    const searchRegEx = new RegExp('^'+searchString);
    const filteredItems = processedReportItems?.filter(it => {
        const providerLabel = providerToLabelName(it.provider);
        const searchMatched = !searchString ? true : (
          String(it.provider).match(searchRegEx) ||
          String(it.power).match(searchRegEx) ||
          String(it.tmsi).match(searchRegEx)
        );
        return (
          (it.localTimestamp > filters.timestart) &&
          (filters.type && filters.type !== 'all' ? it.type === filters.type : true) &&
          (!keyedProviders['all'] && filters.providers ? keyedProviders[providerLabel] : true) &&
          (!keyedCauses['all'] && filters.causes ? keyedCauses[establishementCauseToUserFacingType(it.establishmentCause)] : true) &&
          searchMatched
        )
      })
      .sort((ita, itb) => 
        dateSortingDesc
        ?
        (ita.localTimestamp < itb.localTimestamp ? 1 : -1)
        :
        (ita.localTimestamp > itb.localTimestamp ? 1 : -1)
      );

    setFilteredItems(filteredItems);
    // only reset page if there are filters applied
    if(
      (filters.type&&filters.type!=='all')||
      (filters.providers?.length&&!keyedProviders['all'])||
      //TODO need to change this
      (filters.timestart&&filters.timestart< 24 * 60 * 60 * 1000 )||// less then 2 weeks
      searchString
    ){
      setCurrentPage(0);
    }
  },[
    processedReportItems,
    searchString,
    dateSortingDesc,
    filters
  ])

  // HELPERS
  

  const handleSortingClick = useCallback((stateSetter: (prev: any) => void) => {
    // reset pages
    setCurrentPage(0);
    // tweak the state
    stateSetter((prev: any) => !prev);
  }, [
    setCurrentPage
  ]);

  const handleFiltersChange = useCallback((filterName, values) => {
    console.log('filters changed', {filterName, values});

    if(filterName === 'timestart'){
      const value = values[0];
      const timestamp = Number(value); 

      setFilters(filters => ({
        ...filters,
        timestart: timestamp
      })); 
    }
    if(filterName === 'provider') {
      setFilters(filters => ({
        ...filters,
        providers: [...values]
      }));
    }
    if(filterName === 'type') {
      setFilters(filters => ({
        ...filters,
        type: values[0]
      }));
    }
    if(filterName === 'causes') {
      setFilters(filters => ({
        ...filters,
        causes: [...values]
      }));
    }
  }, [
    setFilters
  ]);

  const handleDotsNextClick = useCallback(async function getMoData(){
    console.log('dots clicked');
    getMo();
  },[
    getMo
  ]);

  const handleNextClick = useCallback(async function getMoData(){
    if((currentPage+1)===totalPages){
      const nextPage = currentPage+1;
      await getMo();
      setCurrentPage(nextPage)
    }
  },[
    getMo,
    totalPages,
    currentPage,
  ]);

  console.log('items')

  return (
    <CellItemsDataDisplayContainer>
      <div className='filters-row'>
        <b>Filters</b>
        { showDateTime ?
        <span>
          <b>Time/Date:</b>
          <FilterDropHover 
            onChange={(values) => {
              handleFiltersChange('provider', values);
            }}
            data={[
              {
                label:"Past 24 hours",
                value:String(startOfDay(now-(24 * 60 * 60 * 1000)).getTime())
              },
              {
                label:"Past 7 days",
                value:String(startOfDay(now-(7*24 * 60 * 60 * 1000)).getTime())
              },
              {
                label:"Past 2 Weeks",
                value:String(startOfDay(startOfWeek(now-(14*24 * 60 * 60 * 1000))).getTime()),
                props: {
                  defaultChecked: true
                }
              }
              ]}
            type="radio"
            name="timeframe"
            />
        </span>
        : null}
        
        <span>
          <b>Provider:</b>
          <FilterDropHover 
            onChange={(values) => {
              handleFiltersChange('provider', values);
            }}
            data={[
              {
                label:"AT&T",
                value:"att"
              },
              {
                label:"T-Mobile",
                value:"tmobile"
              },
              {
                label:"Verizon Wireless",
                value:"verizon"
              },
              {
                label:"Other",
                value:"other"
              }
            ]}
            type="checkbox"
            name="provider-input"
            showAll
            defaultToAll
            />
        </span>
        <span>
          <b>Type/Source:</b>
          <FilterDropHover 
            onChange={(values) => {
              handleFiltersChange('type', values);
            }}
            data={[
              {
                label:"All",
                value:"all",
                props: {
                  defaultChecked: true
                }
              },
              {
                label:"Cell",
                value:"cell"
              },
              {
                label:"Cell-scan",
                value:"cell-scan"
              }
            ]}
            type="radio"
            name="typesource"
            />
        </span>
        <span>
          <b>Priority Level:</b>
          <FilterDropHover 
            onChange={(values) => {
              handleFiltersChange('causes', values);
            }}
            data={[
              {
                label:"911 Emergency",
                value:"emergency"
              },
              {
                label:"Data Transfer",
                value:"data-transfer"
              },
              {
                label:"Signal only",
                value:"signal-only"
              }
            ]}
            type="checkbox"
            name="causes-input"
            showAll
            defaultToAll
            />
        </span>

        <span className="filler"></span>
        <InputGroup
          type="text"
          icon="Search"
          onChange={(v)=>setSearchString(v.target.value)}
          />
        
      </div>
      { 
      filteredItems?.length ?
        <>
        <ThemedDataTable 
          data={filteredItems.slice(currentPage*pageSize, (currentPage+1)*pageSize)}
          index={['localTimestamp', 'type', 'provider', 'power', 'tmsi', 'establishmentCause']}
          dataFormating={{
            localTimestamp: v => format(v, 'M/d/yy h:mm:ss a'),
            power: v => Math.round(Number(v)*100)/100 + ' dbm',
            tmsi: v => v||'',
            establishmentCause: establishementCauseToUserFacingValue
          }}
          titlesFormatting={{
            localTimestamp: () => <span className="sort-header" onClick={() => handleSortingClick(setDateSortingDesc)}>Date/Time{dateSortingDesc ? <ChevronDown /> : <ChevronUp /> }</span>,
            type: () => 'Type',
            provider: () => 'Provider',
            power: () => 'Signal Strength',
            tmsi: () => 'TMSI',
            establishmentCause: () => 'Priority Level'
          }}
          classesMap={classesMap}
          />

        <Pagination 
          onPageChange={p => setCurrentPage(p)} 
          totalPages={totalPages} 
          currentPage={currentPage} 
          alwaysShowNext={!staticDataSource}
          alwaysShowLeftDots={!staticDataSource}
          showNoLast={!staticDataSource}
          onDotsNextClick={handleDotsNextClick}
          onNextClick={handleNextClick} 
          />
        </>
      : 
      !loading ?
      <p className='nada-items'><span>no items to display. try changing the filtering criteria</span></p>
      :
      null
      }
      { loading
        ?
        <div className="centered">
          <Loading height={10} width={200} strokesSettings={{numberOfStrokes: 40, strokesSpaces: 45}} /> 
        </div>
        : null
      }
    </CellItemsDataDisplayContainer>
  );
}

const FilterDropHover: React.FC<{
  data: Array<{
    label: string,
    value: string,
    props?: Partial<InputGroupType>
  }>,
  name: string,
  type: 'checkbox'|'radio',
  onChange?: (values: string[]) => void,
  showAll?: boolean;
  defaultToAll?: boolean;
}> = ({
  name,
  showAll,
  data,
  type,
  onChange,
  defaultToAll
}) => {
  const uncheckAllInput = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const el = document.querySelector(`input[name=${name}-all]`) as HTMLInputElement;
    // uncheck via click 
    if(e.target?.checked && el?.checked){
      el.click()
    }
  }, [
    name
  ]);

  return (
    <DrophoverCheckSelect 
      onValuesChange={(values) => {
        if(defaultToAll && !values.length){
          (document.querySelector(`input[name=${name}-all]`) as HTMLInputElement).checked = true;
          onChange?.(['all']);
        } else {
          onChange?.(values);
        }
      }}
      placeholder="none selected">
      {showAll && type === 'checkbox' ?
      <InputGroup
        reverse
        variant="custom"
        type="checkbox" 
        label="All"
        value="all"
        name={`${name}-all`}
        defaultChecked
        onChange={(e) => {
          const checked = e.target.checked;
          if(checked){
            ([...document.querySelectorAll(`input[name=${name}`)])?.forEach(el => {
              (el as HTMLInputElement).checked = false;
            })
          }
        }}
        />
      : null}
      {data?.map(dt => (
        <InputGroup
          {...(dt.props||{})}
          reverse
          variant="custom"
          type={type} 
          label={dt.label}
          value={dt.value}
          name={name}
          onChange={uncheckAllInput}
          />
      ))}
    </DrophoverCheckSelect>
  )
}
const CellItemsDataDisplayContainer = styled.div`${({theme}) => `
  .pagination {
    margin-top: ${theme.boxMargins.xl3*1.2}px; 
  }
  .data-table {
    width: 100%;
  }
  .filters-row {
    display: flex;
    align-items: center;
    margin-bottom: ${theme.boxMargins.xl}px;
    padding: ${theme.boxPadding.xs}px ${theme.boxPadding.base}px;
    
  }
  .filters-row > *:not(:first-child) {
    display: inline-block;
    margin-left: ${theme.boxMargins.xl3}px;
  }
  .filters-row .filler {
    flex-grow: 10;
  }

  @media (max-width: 1150px){
    .filters-row > * {
      margin-bottom: ${theme.boxMargins.l}px;
    }
    .filters-row {
      flex-wrap: wrap;
    }
  }
  .sort-header {
    cursor: pointer
  }
  .nada-items {
    text-align: center;
  }
  .nada-items span{
    display: inline-block;
    background-color: rgba(${theme.colorsRgb.newColors.OrangeCream.toString()},0.5);
    padding: ${theme.boxPadding.l}px;
  }
  .centered {
    text-align: center;
  }

  tr.emergency {
    background-color: ${theme.newColors.warning};
    font-weight: bold;
    color: ${theme.newColors.SunsetCrush}
  }
`}`;