import React from 'react';
import {IconProps} from './_iconProps';

export const Triangle: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13.72 5.14663L2.42666 24C2.19382 24.4032 2.07062 24.8604 2.06932 25.326C2.06801 25.7916 2.18865 26.2495 2.41923 26.654C2.64981 27.0585 2.9823 27.3956 3.38361 27.6318C3.78492 27.8679 4.24106 27.9949 4.70666 28H27.2933C27.7589 27.9949 28.2151 27.8679 28.6164 27.6318C29.0177 27.3956 29.3502 27.0585 29.5808 26.654C29.8113 26.2495 29.932 25.7916 29.9307 25.326C29.9294 24.8604 29.8062 24.4032 29.5733 24L18.28 5.14663C18.0423 4.75478 17.7076 4.43079 17.3083 4.20595C16.9089 3.9811 16.4583 3.86298 16 3.86298C15.5417 3.86298 15.0911 3.9811 14.6917 4.20595C14.2924 4.43079 13.9577 4.75478 13.72 5.14663Z" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</svg>
