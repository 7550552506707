import React from 'react';
import {IconProps} from './_iconProps';

export const Shuffle: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M21.3333 4H28M28 4V10.6667M28 4L5.33334 26.6667M28 21.3333V28M28 28H21.3333M28 28L20 20M5.33334 5.33333L12 12" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</svg>
