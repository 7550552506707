import { apiRequest } from '../apiRequest';
import { RawPresenceData, RawPresenceItem, getHomeDevicesPresencePayload, getHomeProfilesPresencePayload } from './types';

export const getHomeProfilesPresence = ({ homeId, limit = 100, offset = 0 }: getHomeProfilesPresencePayload) =>
  apiRequest<RawPresenceData>({
    service: 'feApiGateway',
    path: `homes/${homeId}/profiles/presence?limit=${limit}&offset=${offset}`,
  });

export const getHomeDevicesPresence = ({ homeId, limit = 100, offset = 0 }: getHomeDevicesPresencePayload) =>
  apiRequest<RawPresenceData>({
    service: 'feApiGateway',
    path: `homes/${homeId}/devices/presence?limit=${limit}&offset=${offset}`,
  });

export const getProfilePresence = (homeId: number, profileId: number) =>
  apiRequest<RawPresenceItem>({
    service: 'feApiGateway',
    path: `homes/${homeId}/profiles/${profileId}/presence`,
  });

export const getDevicePresence = (homeId: number, deviceId: number) =>
  apiRequest<RawPresenceItem>({
    service: 'feApiGateway',
    path: `homes/${homeId}/devices/${deviceId}/presence`,
  });

// TODO: 
export const getLastoneOut: (params: {
  timestamp: number;
  homeId: number;
}) => Promise<{
  profileId: string,
  timestamp: number
}> = async ({
  timestamp,
  homeId,
}) => {
    // TODO: 
    //   const response = await getHomeProfilesPresence(homeId, {timestamp});
    let latestOutTimestamp = 0;
    let latestOutProfileId = '';

    //   console.log('[getLastoneOut] last one out response:', {response});

    //   if(response.status === 200){
    //     Object.entries(response.data).forEach(([profileId, value]) => {
    //       if(
    //         value.last_status_change &&                   // not null
    //         value.current_status === 'absent' &&          // is absent
    //         latestOutTimestamp < value.last_status_change // closest to submitted timestamp
    //       ){
    //         latestOutTimestamp = value.last_status_change;
    //         latestOutProfileId = profileId;
    //       }
    //     });
    //   }

    return {
      profileId: latestOutProfileId,
      timestamp: latestOutTimestamp
    };
  };
