import * as Sentry from '@sentry/react';
import { CaptureContext } from '@sentry/types';

export type LogErrorDetails = {
    tags: {
      service: string,
      path: string,
    },
    contexts: {
      message: string,
      response: string
    },
  }

export const logError = (error: unknown, details: CaptureContext) => 
  Sentry.captureException(error, details);
