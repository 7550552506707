import {
  getDaemons,
  getDaemonHealth,
  EdgeDaemon,
  EdgeDaemonHealth,
} from '@ubiety/fe-api';
// import { persist } from 'zustand/middleware';
import { createStore } from 'zustand/vanilla';

import { getHomeId, getIsHomeLoaded } from './selectors';
import { ContextError, ErrorCodes } from '../classes';
import { fnSilentWrapper, withCatcher } from '../helpers';
import { DataStatus, Maybe } from '../types';

interface IEdgeDaemonState {
  daemons: Array<EdgeDaemon & EdgeDaemonHealth>;
  activeDaemons: Array<EdgeDaemon & EdgeDaemonHealth>;
  edgeDaemonDataStatus: DataStatus;
}

type EdgeDaemonAction = {
  reset: () => void;
  getHomeDaemons: () => Promise<Maybe<Array<EdgeDaemon & EdgeDaemonHealth>>>;
  getHomeDaemonsSilently: () => Promise<Maybe<boolean>>;
};

export type EdgeDaemonState = IEdgeDaemonState & EdgeDaemonAction;

const INITIAL_STATE: IEdgeDaemonState = {
  edgeDaemonDataStatus: undefined,
  daemons: [],
  activeDaemons: [],
};

export const edgeDaemonStore = createStore<EdgeDaemonState>()(
  // persist(
  (set, get) => ({
    ...INITIAL_STATE,
    reset: () => set(INITIAL_STATE),

    getHomeDaemons: withCatcher('edgeDaemonStore.getHomeDaemons', async () => {
      const homeId = getHomeId();
      console.log('[EdgeDaemonStore] getting daemons for home(maybe?):', { uid: homeId });
      const isHomeLoaded = getIsHomeLoaded();

      if (!isHomeLoaded) {
        throw new ContextError('home is not yet loaded', {
          isLocal: true,
          errorCode: ErrorCodes.inconclusive // wait until home is loaded and dont set error or success
        });
      }

      if (!homeId) {
        throw new ContextError('there is no home to get daemons with', { isLocal: true });
      }

      const daemonsResponse = await getDaemons(homeId);
      const daemons = daemonsResponse.data.data;
      const daemonsData = new Array<EdgeDaemon & EdgeDaemonHealth>();

      await Promise.all(daemons.map(async (d) => {
        const daemonHealthResponse = await getDaemonHealth(homeId, d.uid);
        const daemonHealthData = daemonHealthResponse.data;

        daemonsData.push({ ...d, ...daemonHealthData });
      }));

      set({
        edgeDaemonDataStatus: 'success',
        daemons: daemonsData,
        activeDaemons: daemonsData.filter(d => d.active)
      });

      console.log('[EdgeDaemonStore] got daemons', { daemonsData });
      return daemonsData;
    }, (e) => {
      // errorCode 1 -> wait until home is loaded
      if (e.errorCode !== ErrorCodes.inconclusive) {
        set({ edgeDaemonDataStatus: e.isLocal ? 'success' : 'error' });
      }
    }),
    getHomeDaemonsSilently: fnSilentWrapper(() => get().getHomeDaemons()),
  }),
);