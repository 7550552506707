import React from 'react';
import {IconProps} from './_iconProps';

export const Folder: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M29.3333 25.3333C29.3333 26.0406 29.0524 26.7189 28.5523 27.219C28.0522 27.719 27.3739 28 26.6667 28H5.33333C4.62609 28 3.94781 27.719 3.44771 27.219C2.94762 26.7189 2.66667 26.0406 2.66667 25.3333V6.66667C2.66667 5.95942 2.94762 5.28115 3.44771 4.78105C3.94781 4.28095 4.62609 4 5.33333 4H12L14.6667 8H26.6667C27.3739 8 28.0522 8.28095 28.5523 8.78105C29.0524 9.28115 29.3333 9.95942 29.3333 10.6667V25.3333Z" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</svg>
