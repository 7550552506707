import React from 'react';
import {IconProps} from './_iconProps';

export const BatteryCharging: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M20 8H22.6667C23.3739 8 24.0522 8.28095 24.5523 8.78105C25.0524 9.28115 25.3333 9.95942 25.3333 10.6667V21.3333C25.3333 22.0406 25.0524 22.7189 24.5523 23.219C24.0522 23.719 23.3739 24 22.6667 24H20M30.6667 17.3333V14.6667M16 8L10.6667 16H18.6667L13.3333 24M6.66666 24H3.99999C3.29275 24 2.61447 23.719 2.11438 23.219C1.61428 22.7189 1.33333 22.0406 1.33333 21.3333V10.6667C1.33333 9.95942 1.61428 9.28115 2.11438 8.78105C2.61447 8.28095 3.29275 8 3.99999 8H6.66666V24Z" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</svg>
