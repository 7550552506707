import React from 'react';
import {IconProps} from './_iconProps';

export const Star: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M16 2.66669L20.12 11.0134L29.3333 12.36L22.6667 18.8534L24.24 28.0267L16 23.6934L7.75999 28.0267L9.33332 18.8534L2.66666 12.36L11.88 11.0134L16 2.66669Z" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</svg>
