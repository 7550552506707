import React from 'react';
import {IconProps} from './_iconProps';

export const Book: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5.33333 26C5.33333 25.116 5.68452 24.2681 6.30964 23.643C6.93477 23.0179 7.78261 22.6667 8.66667 22.6667H26.6667M5.33333 26C5.33333 26.8841 5.68452 27.7319 6.30964 28.357C6.93477 28.9822 7.78261 29.3334 8.66667 29.3334H26.6667V2.66669H8.66667C7.78261 2.66669 6.93477 3.01788 6.30964 3.643C5.68452 4.26812 5.33333 5.11597 5.33333 6.00002V26Z" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</svg>
