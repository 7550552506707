import React from 'react';
import {IconProps} from './_iconProps';

export const CornerUpRight: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M20 18.6667L26.6667 12M26.6667 12L20 5.33334M26.6667 12H10.6667C9.25219 12 7.89563 12.5619 6.89544 13.5621C5.89525 14.5623 5.33334 15.9189 5.33334 17.3333V26.6667" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</svg>
