import React from 'react';
import {IconProps} from './_iconProps';

export const MoreVertical: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M16.5001 15.9167C16.5001 15.5025 16.1643 15.1667 15.7501 15.1667C15.3359 15.1667 15.0001 15.5025 15.0001 15.9167C15.0001 16.3309 15.3359 16.6667 15.7501 16.6667C16.1643 16.6667 16.5001 16.3309 16.5001 15.9167Z" fill="#FEFAE3"/>
<path d="M16.5001 6.58333C16.5001 6.16912 16.1643 5.83333 15.7501 5.83333C15.3359 5.83333 15.0001 6.16912 15.0001 6.58333C15.0001 6.99755 15.3359 7.33333 15.7501 7.33333C16.1643 7.33333 16.5001 6.99755 16.5001 6.58333Z" fill="#FEFAE3"/>
<path d="M16.5001 25.25C16.5001 24.8358 16.1643 24.5 15.7501 24.5C15.3359 24.5 15.0001 24.8358 15.0001 25.25C15.0001 25.6642 15.3359 26 15.7501 26C16.1643 26 16.5001 25.6642 16.5001 25.25Z" fill="#FEFAE3"/>
<path d="M16.5001 15.9167C16.5001 15.5025 16.1643 15.1667 15.7501 15.1667C15.3359 15.1667 15.0001 15.5025 15.0001 15.9167C15.0001 16.3309 15.3359 16.6667 15.7501 16.6667C16.1643 16.6667 16.5001 16.3309 16.5001 15.9167Z" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
<path d="M16.5001 6.58333C16.5001 6.16912 16.1643 5.83333 15.7501 5.83333C15.3359 5.83333 15.0001 6.16912 15.0001 6.58333C15.0001 6.99755 15.3359 7.33333 15.7501 7.33333C16.1643 7.33333 16.5001 6.99755 16.5001 6.58333Z" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
<path d="M16.5001 25.25C16.5001 24.8358 16.1643 24.5 15.7501 24.5C15.3359 24.5 15.0001 24.8358 15.0001 25.25C15.0001 25.6642 15.3359 26 15.7501 26C16.1643 26 16.5001 25.6642 16.5001 25.25Z" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</svg>
