import { apiRequest } from '../apiRequest';
import { ENV_HOLDER } from '../env';
import { AutoCompletePrediction, PlaceResponseData } from '../types';

type PredictionResponseData = {
  predictions: Array<AutoCompletePrediction>;
};

export const getPlaceDetails = (placeId: string) =>
  apiRequest<PlaceResponseData>({
    url: `${ENV_HOLDER.current?.googlePlacesApiUrl}/details/json?placeid=${placeId}&key=${ENV_HOLDER.current?.googlePlacesApiKey}`,
    method: 'POST',
    withAuth: false
  });
export const getAutoCompletePrediction = (input: string) =>
  apiRequest<PredictionResponseData>({
    url: `${ENV_HOLDER.current?.googlePlacesApiUrl}/autocomplete/json?input=${input}&key=${ENV_HOLDER.current?.googlePlacesApiKey}`,
    method: 'POST',
    withAuth: false
  });
