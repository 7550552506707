import {useEffect, useCallback, useRef, useState, useMemo} from 'react';

import Delaunator from 'delaunator';

import {
  hexToRgb,
  rgbToHex
} from 'utils';

import {M4, Vector} from './classes';

const defaultSettings = {
  D: 0.25,
  K: 0.23,
  M: 1,
  accentDimStep: 0.01,
  bgColor: '#182e4d',
  cameraX: 0,
  cameraY: 115,
  cameraZ: 140,
  circlesNumber: 15,
  distributionFormula: 'fibonachiSpiral',
  dotSize: 2,
  dotsAccentColorHex: '#e100af',
  dotsColorHex: '#292e51',
  framePerSecond: 15,
  height: 370,
  kickDiviation: 0.05,
  linesColorHex: '#303d7e',
  linesWidth: 2,
  logcalctime: false,
  logdrawtime: false,
  numberOfDots: 1000,
  scaleAdjustment: 150,
  speed: 0.8,
  viewBoxOffsetX: 0,
  viewBoxOffsetY: 75,
  waveDimStep: 0.01,
  width: 800
};

export type fibonachiPowerPlotSettings = Partial<typeof defaultSettings>;

export const useFibonachiPowerPlot = (
  svgRef: React.MutableRefObject<SVGSVGElement|null>,
  passedSettings?: fibonachiPowerPlotSettings) => {

  const [vertices, setVertices] = useState<any>([]);
  const polymeshesRef = useRef<any[]>([]);
  const circlesRef = useRef<any[]>();

  const cameraRef = useRef<any>(new Vector(0,0,0));
  const targetColorRgbRef = useRef<any>();
  const [settings, setSettings] = useState<fibonachiPowerPlotSettings>();

  const offsetXRef = useRef<number>(0);
  const offsetYRef = useRef<number>(0);

  const intervalRef = useRef<any>();

  const wavesFramesRef = useRef([]);

  // Init Settings
  // set up vartices and polymesh map
  const clearScene = useCallback(function clearScene(){
   // clear svg
   if(svgRef.current){
     svgRef.current.innerHTML = '';
   }
  }, [svgRef]);
  const resetScene = useCallback(function init(){
    clearScene();

    const settings = {
      ...defaultSettings,
      ...(passedSettings||{})
    };

    // DEFINE DIVIATIONS

    // camera
    const camera = cameraRef.current;

    function setCamera(){
      camera[0] = settings.cameraX;
      camera[1] = settings.cameraY;
      camera[2] = settings.cameraZ;
    }

    // Generate DOTS
    const vertices: any[] = [];

    // ADD POINTS IN FIBONACHI SEQUENCE
    const goldenRatio = (1 + Math.pow(5,0.5) )/2;

    // fibonachi 3d
    // function setFibonachi3dPane(){
    //   const n = settings.numberOfDots*1;

    //   // clean out vertices
    //   vertices.splice(0,vertices.length);

    //   // add in points of fibonachi sequence in 3d
    //   for(let i=0; i<n; i++){

    //     const theta = 2 * Math.PI * i / goldenRatio;
    //     const phi = Math.acos(1 - 2*(i+0.5)/n);

    //     const vertice = new Vector(
    //       Math.cos(theta) * Math.sin(phi),
    //       Math.sin(theta) * Math.sin(phi),
    //       Math.cos(phi)
    //     );

    //     vertices.push(vertice);
    //   }
    // }

    // fibonachi 2d
    // same formula as above, but flat
    function setFibonachi2dPane(){
      const n = settings.numberOfDots*1;

      // clean out vertices
      vertices.splice(0,vertices.length);

      // add in points of fibonachi sequence in 2d
      for(let i=0; i<n; i++){

        const theta = 2 * Math.PI * i / goldenRatio;
        const phi = Math.acos(1 - 2*(i+0.5)/n);

        const x = Math.cos(theta) * Math.sin(phi);
        const y = Math.sin(theta) * Math.sin(phi);

        const vertice = new Vector(
          x,
          y, //Math.cos(phi)
          0,
        );

        vertices.push(vertice);
      }
    }

    // fibonachi spiral
    function setFibonachiSpiralPane(){
      const n = settings.numberOfDots*1;

      // clean out vertices
      vertices.splice(0,vertices.length);

      // add in points of fibonachi sequence in 2d

      for(let i=0;i<n;i++){
        const x = i / goldenRatio;
        const y = i / n;

        const theta = 2 * Math.PI * x;
        const r = Math.pow( y, 0.5 );

        const xP = r * Math.cos( theta );
        const yP = r * Math.sin( theta );

        const vertice = new Vector(
          xP,
          yP, //Math.cos(phi)
          0,
        );

        vertices.push(vertice);
      }
    }

    //
    // create circles
    const circles: any[] = [];
    let radiusIncrement = 0;

    function createCircles(){
      // clean out circles
      circles.splice(0,circles.length);

      // settings
      const numberOfCircles = settings.circlesNumber;

      // vars
      const paneWidth = 2; //(rows-1)*colWidth*2;
      radiusIncrement = (paneWidth/2)/numberOfCircles;

      for(let i=0; i < numberOfCircles; i++){
        circles.push({radius: (i+1)*radiusIncrement});
      }
    }

    // group dots in circles
    // map vetices to circles
    function mapVerticesToCircles(){
      const center = new Vector(0,0,0);

      for(let i=0;i<vertices.length;i++){
        const vertice = vertices[i];
        const distanceToCenter = vertice.distanceTo(center);
        // find out which circle it belongs to best
        const circleN = Math.round(distanceToCenter/radiusIncrement);

        vertice.circleN = circleN;
      }
    }

    const polymeshes: number[][] = [];
    const polymeshesCopy: number[][] = [];

    function mapPolylines(){
      const {triangles} = Delaunator.from(vertices);

      polymeshes.splice(0,polymeshes.length);
      polymeshesCopy.splice(0,polymeshesCopy.length);

      for(let i=0; i < triangles.length-2; i+=3){
        polymeshes.push([triangles[i], triangles[i+1], triangles[i+2]]);
        polymeshesCopy.push([triangles[i], triangles[i+1], triangles[i+2]]);
      }

      //polymesh.pop(1);
      // console.log('triangles is', triangles);
      // console.log('polymeshes are', polymeshes);

      // OPTIMIZATION reduce number of polygons

      // iterate over polymeshes to adjoin them n times
      // set to be 4 times per 1000 points but less then a 100 times
      for(let r=0; r<(polymeshesCopy.length/1000)*4 && r<100;r++){

        // search to polygons that start with what this one ends by
        for(let i=0; i<polymeshesCopy.length; i++){
          const polymesh = polymeshesCopy[i];
          const endsWith = polymesh[polymesh.length-1];

          const next = polymeshesCopy.findIndex( p => p[0] === endsWith );

          if(next > -1){
            // merge element
            polymeshesCopy[next].splice(0,1,...polymesh);
            // remove element
            polymeshesCopy.splice(i,1);
          }
        }
      }

      for(let r=0; r<(polymeshesCopy.length/1000)*4 && r<100;r++){
        // search for polygons that end with the same point
        for(let i=0; i<polymeshesCopy.length; i++){
          const polymesh = polymeshesCopy[i];
          const endsWith = polymesh[polymesh.length-1];
          const sameEnding = polymeshesCopy.findIndex( p => p[p.length-1] === endsWith );

          if(sameEnding > -1 && sameEnding !== i){
            // merge element
            polymeshesCopy[sameEnding].reverse().splice(0,1,...polymesh);
            // remove element
            polymeshesCopy.splice(i,1);
          }
        }
      }

      //console.log('mergedPolymeshes are', polymeshesCopy);

      polymeshes.splice(0,polymeshes.length,...polymeshesCopy);
      //console.log('updated polymeshes are', polymeshes);
    }

    //setFibonachi3dPane()
    if(settings.distributionFormula === 'fibonachiSpiral'){
      setFibonachiSpiralPane();
    }
    else {
      setFibonachi2dPane();
    }

    // update target color
    targetColorRgbRef.current = hexToRgb(settings.dotsColorHex);
    //
    createCircles();
    mapVerticesToCircles();
    mapPolylines();

    // push a zero point ref
    vertices.push(new Vector(0,0,0));

    // APPLY SETTINGS
    setCamera();
    setSettings(settings);
    // set vertices state
    setVertices(vertices);
    polymeshesRef.current = polymeshes;
    circlesRef.current = circles;

  }, [setSettings, setVertices, clearScene, passedSettings]);
  useEffect(resetScene, [resetScene]);

  useEffect(function clearIntervalOnUnmount(){
    return function(){
      clearInterval(intervalRef.current);
    };
  },[]);

  // Frame getters
  const transformationMatrix = useMemo(function getTransformationMatrix(){

    let matrix = M4.getPlain();

    if(!settings){ return matrix; }

    const camera = cameraRef.current;

    const centerShift = M4.getTranslation(0, 0, 0);
    const scale = M4.getScale(settings.scaleAdjustment,settings.scaleAdjustment,settings.scaleAdjustment);
    const view = M4.getView(
      new Vector(...camera),
      new Vector(0,0,0),
      new Vector(0,1,0)
    );

    // matrix = M4.multiply(
    //   M4.getRotationX(20),
    //   matrix
    // );

    // matrix = M4.multiply(
    //   M4.getRotationY(20),
    //   matrix
    // );

    // matrix = M4.multiply(
    //   M4.getRotationZ(20),
    //   matrix
    // );

    matrix = M4.multiply(
      scale,
      matrix
    );

    matrix = M4.multiply(
      centerShift,
      matrix
    );

    matrix = M4.multiply(
      view,
      matrix
    );

    matrix = M4.multiply(
      M4.getPerspectiveProjection(
        90, 500/500,
        -1, -200),
      matrix,
    );

    return matrix;
  }, [settings]);

  const getSceneVertices = useCallback( function getSceneVertices(){
    if(settings?.logcalctime) {console.time('verticesCalc time');}
    const matrix = transformationMatrix;
    const sceneVertices = [];
    for(let i = 0 ; i < vertices.length ; i++) {
      const vertex: any = M4.multiplyVector(
        matrix,
        vertices[i]
      );

      vertex[0] = Math.round( (vertex[0] / vertex[3] * 250)*100 )/100;
      vertex[1] = Math.round( (vertex[1] / vertex[3] * 250)*100 )/100;

      sceneVertices.push(vertex); //vertex);
    }
    if(settings?.logcalctime) {console.time('verticesCalc time');}

    return sceneVertices;
  }, [vertices, transformationMatrix, settings?.logcalctime]);

  // Drawing interface
  type Dot = {
    el: SVGCircleElement,
    prevHash?: string
  }
  const drawNewDot = useCallback(function drawNewDot(x,y, color=settings?.dotsColorHex): Dot|null {
    if(!settings || settings.width === undefined || settings.height === undefined || !svgRef.current || settings.dotSize === undefined){
      return null;
    }
    const offsetX = offsetXRef.current;
    const offsetY = offsetYRef.current;

    const svgEl = svgRef.current;

    const el = document.createElementNS('http://www.w3.org/2000/svg', 'circle');
    el.setAttribute('cx', String(x + settings.width/2-offsetX));
    el.setAttribute('cy', String(y + settings.height/2-offsetY));
    el.setAttribute('r', String(settings.dotSize));
    el.setAttribute('fill', color);

    svgEl.appendChild(el);

    return {el};
  },[settings, svgRef]);

  const redrawDot = useCallback(function redrawDot(dot: Dot, x,y, color=settings?.dotsColorHex){
    if(!settings || settings.width === undefined || settings.height === undefined || !svgRef.current || !settings.dotSize === undefined){
      return null;
    }
    const offsetX = offsetXRef.current;
    const offsetY = offsetYRef.current;

    const hash = ''+x+y+color;
    // only redraw point if anything changed
    if(dot.prevHash === hash) {return;}
    dot.prevHash = hash;

    const {el} = dot;

    el.setAttribute('cx', String(x + settings.width/2-offsetX));
    el.setAttribute('cy', String(y + settings.height/2-offsetY));
    el.setAttribute('fill', color);

    return dot;
  },[settings, svgRef]);

  type Polyline = {
    el: SVGPolylineElement,
    prevHash?: string
  }
  const drawNewPolyline = useCallback(function drawNewPolyline(points, color=settings?.linesColorHex): Polyline|null{
    if(!svgRef.current){
      return null;
    }
    const offsetX = offsetXRef.current;
    const offsetY = offsetYRef.current;

    const svgEl = svgRef.current;

    let isX = true;

    const mappedPoints = points?.map( (point:number) => {
      if(!settings || !settings.width || !settings.height){ return null; }

      isX = !isX;

      return !isX
        ?
        point + settings.width/2-offsetX
        :
        point + settings.height/2-offsetY;
    });

    const linesWidth = settings?.linesWidth||1;

    const el = document.createElementNS('http://www.w3.org/2000/svg', 'polyline');
    el.setAttribute('points', mappedPoints.join(','));
    el.setAttribute('fill', 'none');
    el.setAttribute('stroke', color);
    el.setAttribute('stroke-width', String(linesWidth));

    svgEl.appendChild(el);

    return {el};
    // return snap.polyline(mappedPoints).attr({stroke: settings.linesColorHex, fill:'none'});
  },[settings, svgRef]);
  const redrawPolyline = useCallback(function redrawPolyline(polyline, points, color=settings?.linesColorHex): Polyline|null{
    if(!svgRef.current){
      return null;
    }

    const offsetX = offsetXRef.current;
    const offsetY = offsetYRef.current;

    let isX = true;

    const hash = ''+points.join('.');
    // only redraw point if anything changed
    if(polyline.prevHash === hash){ return null; }
    polyline.prevHash = hash;

    const mappedPoints = points?.map( (point:number) => {
      if(!settings || !settings.width || !settings.height){ return null; }

      isX = !isX;

      return !isX
        ?
        point + settings.width/2-offsetX
        :
        point + settings.height/2-offsetY;
    });

    const { el } = polyline;
    el.setAttribute('points', mappedPoints.join(','));
    if(color){
      el.setAttribute('color', color);
    }

    return polyline;
    // polyline
    //   .attr('points', mappedPoints)
    //   .attr('stroke', settings.linesColorHex);
  },[settings, svgRef]);

  // - dots color diming
  const dimTempColor = useCallback(function(vertice){
    const dimStep = Number(settings?.accentDimStep);
    const startColorRgb = hexToRgb(vertice.tempColor);
    const targetColorRgb = targetColorRgbRef.current;

    if(!startColorRgb || !targetColorRgb || !dimStep) { return; }

    vertice.tempColorDim += dimStep;

    if(vertice.tempColorDim >= 1){
      vertice.tempColor = undefined;
      vertice.color = undefined;
      vertice.primeColor = undefined;
    }
    else {

      const newColorRgb: [number, number, number] = [
        Math.round( (targetColorRgb[0]-startColorRgb[0])*vertice.tempColorDim+startColorRgb[0] ),
        Math.round( (targetColorRgb[1]-startColorRgb[1])*vertice.tempColorDim+startColorRgb[1] ),
        Math.round( (targetColorRgb[2]-startColorRgb[2])*vertice.tempColorDim+startColorRgb[2] )
        ];
      const newColor = rgbToHex(...newColorRgb);

      vertice.color = newColor;
    }

  }, [settings]);

  // - drawing views
  const drawDotsView = useCallback(function drawDotsView(){
    const sceneVertices = getSceneVertices();
    if(!sceneVertices.length) {return;}
    const zeroRef = sceneVertices.pop();

    // set offset to zero point refferrence
    offsetXRef.current = zeroRef[0];
    offsetYRef.current = zeroRef[1];

    if(settings?.logdrawtime) {console.time('drawDotsView');}
    for (let v = 0; v < sceneVertices.length; v++) {
      const vertice = vertices[v];
      const tempColor = vertice.tempColor || undefined;
      const color = vertice.color || undefined;
      const snapDot = vertice.snapDot;
      // reset color

      // dim temp color on each draw
      if(tempColor){ dimTempColor(vertice); }

      //vertice.tempColor = undefined;
      //console.log('color', color);

      if(snapDot){
        redrawDot(
          snapDot,
          sceneVertices[v][0],
          sceneVertices[v][1],
          color
          );

      } else {
        const newDot = drawNewDot(
          sceneVertices[v][0],
          sceneVertices[v][1],
          color
        );

        vertice.snapDot = newDot;
      }
    }
    if(settings?.logdrawtime) {console.timeEnd('drawDotsView');}
  },[getSceneVertices, dimTempColor, drawNewDot, redrawDot, vertices, settings?.logdrawtime]);

  const drawPolymeshesView = useCallback(function drawPolymeshesView(){
    const polymeshes = polymeshesRef.current;

    const sceneVertices = getSceneVertices();
    if(!sceneVertices.length) {return;}
    const zeroRef = sceneVertices.pop();

    // set offset to zero point refferrence
    offsetXRef.current = zeroRef[0];
    offsetYRef.current = zeroRef[1];

    if(settings?.logdrawtime){ console.time('drawPolymeshView'); }
    for(let p=0; p<polymeshes.length;p++){
      const points = [];
      const polymesh = polymeshes[p];
      const snapPolyline = polymesh.snapPolyline;

      for(let i=0;i<polymesh.length;i++){
        const vn = polymesh[i];
        points.push(sceneVertices[vn][0]);
        points.push(sceneVertices[vn][1]);
      }

      if(snapPolyline){

        redrawPolyline(snapPolyline, points);

      } else {
        const newSnapPolyline = drawNewPolyline(points);

        polymesh.snapPolyline = newSnapPolyline;
      }
    }
    if(settings?.logdrawtime){ console.timeEnd('drawPolymeshView'); }
  }, [getSceneVertices, drawNewPolyline, redrawPolyline, settings?.logdrawtime]);

  const drawStage = useCallback(function drawStage(){
    drawPolymeshesView();
    drawDotsView();
  },[drawDotsView,drawPolymeshesView]);

  // Spring
  // this is animation of Y poisition
  const applySpring = useCallback(function(vertice: any){
    if(!settings || settings.K === undefined || settings.M === undefined || settings.D === undefined || settings.speed=== undefined ){ return null; }
    // const settings = {
    //   K: 0.23, // sprint konstant
    //   D: 0.25, // dumpening factor
    //   M: 1, // mass
    // };

    // current velocity
    const velocity = vertice.velocity || 0;

    // this is to use with center and target point, where
    // vertice is trying to setle at a specific point,
    // while moving back and force along the line passing target point and center
    //const {targetPoint, targetHeight} = point;
    //const currentHeight = pointsDistance(point, this.center);
    //const dd = targetHeight - currentHeight;

    // lets work towards value 1;
    // current delta
    const dd = vertice[2];

    // new acceleration and velocity
    const acceleration = -(settings.K/settings.M)*dd - settings.D*velocity;
    vertice.velocity = velocity + acceleration*Number(settings.speed);

    vertice.acceleration = acceleration;

    // update point
    //const acceleratedPoint = pointAtSegmentDistance(targetPoint, this.center, dd+velocity);

    //if(acceleration) console.log('acceleration is', acceleration);

    vertice[2] = dd+velocity;

    //console.log('vertice', vertice);

  }, [settings]);

  const applyAllSprings = useCallback(function applyAllSprings(){
    if(settings?.logcalctime){ console.time('apply all springs'); }
    for(let i=0; i<vertices.length; i++){
      const vertice = vertices[i];

      applySpring(vertice);
    }
    if(settings?.logcalctime){ console.timeEnd('apply all springs'); }
  }, [vertices, applySpring, settings?.logcalctime]);

  // Kick in energy
  const kickInSomeMotion = useCallback(function kickInSomeMotion(circleN, accentColor=settings?.dotsAccentColorHex){
    if(!settings){ return; }

    //add y values to points in circle
    for (let v = 0; v < vertices.length; v++) {
      const vertice = vertices[v];
      const step = settings.kickDiviation;

      if(vertice.circleN === circleN && step){
        vertice[2] += step*(vertice[2]<0?-1:1);
        vertice.tempColor = accentColor;
        vertice.tempColorDim = 0;
      }
    }
  }, [settings, vertices]);

  const kickInMotion = useCallback(function kickInMotion(circleN, value, color=settings?.dotsAccentColorHex, colorDim=0, primeColor?){
    if(!settings){ return; }
    //add y values to points in circle
    for (let v = 0; v < vertices.length; v++) {
      const vertice = vertices[v];
      const step = settings.kickDiviation;

      if(vertice.circleN === circleN){
        vertice[2] = Number(step)*value; //*(vertice[2]<0?-1:1); //*(vertice[2]<0?-1:1);
        vertice.velocity = 0;

        // if prime color passed -> primeUp -> assign it
        if(primeColor){
          vertice.tempColor = primeColor;
          vertice.tempColorDim = 0;
        }
        // or assign secondary colors if else
        else if(!vertice.primeColor){

          vertice.tempColor = color;
          vertice.tempColorDim = colorDim;
        }

        if(primeColor){
          vertice.primeColor = primeColor;
        }
      }
    }
  },[settings, vertices]);

  // waves is a queque of circles that should be applied energy of

  const addWave = useCallback(function addWave(circleN, color=settings?.dotsAccentColorHex){

    const wavesFrames = wavesFramesRef.current as any[];
    const circles = circlesRef.current;

    if(!settings || !wavesFramesRef.current || !settings.waveDimStep || !circles){ return; }
    // total number of frames to create
    const frames = Math.min(circles.length, circles.length - circleN);

    const waveDimStep = settings.waveDimStep;

    // add in current frame
    if(!wavesFrames[0]){
      wavesFrames[0] = {};
    }
    const startFrame = wavesFrames[0];
    startFrame[circleN] = (startFrame[circleN]||0) + 1;
    startFrame[circleN+'-primeColor'] = color;

    // add in spread kicks
    for(let i=1; i<frames;i++){
      // create frame if does not exist
      if(!wavesFrames[i]){
        wavesFrames[i] = {};
      }
      const frame = wavesFrames[i];
      const circleOut = circleN + i;
      //const circleIn = circleN - i;

      // how much we want to kick in
      const kickValue = 1 * (1-waveDimStep * i);

      if(circleOut<=circles.length && kickValue > 0){
        frame[circleOut] = (frame[circleOut]||0) + kickValue;

        frame[circleOut+'-color'] = color;
        frame[circleOut+'-colorDim'] = 1-kickValue*0.35;

      }
    }

    return wavesFrames;
  },[settings]);
  const kickInWawesFrame = useCallback(function kickInWawesFrame(){
    const wavesFrames = wavesFramesRef.current as any[];
    const circles = circlesRef.current;
    const frame = wavesFrames.splice(0,1)[0];

    if(!frame||!circles) {return;}

    for(let i=0;i<=circles.length;i++){
      const circleN = i;
      const kickInValue = frame[i];
      const kickInColor = frame[i+'-color'];
      const kickInColorDim = frame[i+'-colorDim'];
      const kickInPrimeColor = frame[i+'-primeColor'];

      if(kickInValue){
        kickInMotion(circleN, kickInValue, kickInColor, kickInColorDim, kickInPrimeColor);
      }
    }
  },[kickInMotion]);

  // Frame
  const onFrame = useCallback(function onFrame(){
    const logtime = false;
    if(logtime){ console.time('onFrame'); }
    kickInWawesFrame();
    applyAllSprings();
    drawStage();
    if(logtime){ console.timeEnd('onFrame'); }
  },[applyAllSprings, drawStage, kickInWawesFrame]);


  const play = useCallback(function Play(){
    clearInterval(intervalRef.current);
    intervalRef.current = setInterval(onFrame, 1000/Number(settings?.framePerSecond));

  }, [onFrame, settings?.framePerSecond]);
  const stop = useCallback(function Stop(){
    clearInterval(intervalRef.current);
  }, []);

  return {
    clearScene,
    resetScene,
    drawNewDot,
    redrawDot,
    drawNewPolyline,
    redrawPolyline,
    drawDotsView,
    drawPolymeshesView,
    applyAllSprings,
    kickInSomeMotion,
    addWave,
    kickInWawesFrame,
    play,
    stop,
    getSceneVertices
  };
};

// const _defaultSettings = {
//   //starting position of camera
//   //startCamera: new Vector(0, 0, 200),//10, -130, 360), //, 1),//0,0,200),
//   cameraX: 0,
//   cameraY: 0,
//   cameraZ: 200,

//   scaleAdjustment: 150,
//   logdrawtime: false,
//   logcalctime: false,

//   // line color
//   linesColorHex: '#008000',

//   // canvas settings
//   dotSize: 2,
//   dotsColorHex: '#4eb504',
//   dotsAccentColorHex: '#2d4bf7',

//   // how fast should highlighted points dim
//   // assuming a precentage value per each drawing
//   accentDimStep: 0.01,
//   // divide pane by n number of circles
//   circlesNumber: 15,

//   // what plot to use
//   distributionFormula: 'fibonachi2d',

//   // use this many dots for fibonachi formulas
//   numberOfDots: 1,

//   // spring settings,
//   K: 0.23, // sprint konstant
//   D: 0.25, // dumpening factor
//   M: 1, // mass

//   // amount of diviation to apply to dots on energy kick
//   // number value for axis it is being iterated against
//   // in the scale to the figure itself
//   // fibonachi figues are of size 2 before scale (+-1 from axis in each direction)
//   kickDiviation: 0.1,
//   framePerSecond: 5,
//   speed: 0.01,

//   width: 500,
//   height: 500,

//   bgColor: '#333333',

// }
