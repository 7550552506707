import React, {useRef, useState, useMemo, FormEvent, useCallback} from 'react';
import {
  InputGroup,
  Button,
  WarningMessage,
  PlainForm,
  Loading
} from 'components';
import {
  useValidator,
  validations,
  restrictors
} from 'utils';
import {
  BiErrorCircle
} from 'react-icons/bi';
import {
  useAccountStore,
  useNewReportsContext,
  useSensorsStore
} from 'context';
import styles from './ForensicReportCreationForm.module.css'; 
import styled from 'styled-components';
import {
  format
} from 'date-fns';
import { NewReportItem, NewSaveReportPayload, useOverlayContext } from 'context';
import {
  getRawCellListItems
} from '../CellItemsDataDisplay/useRawCellList';
import {
  processRawCellItemToReportItem
} from 'helpers';

export const ForensicReportCreationForm: React.FC = () => {
  const [formError, setFormError] = useState<string|null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const { hidePopup } = useOverlayContext();
  const { user } = useAccountStore(s => s);
  const { sensorArray } = useSensorsStore(s => s);
  const sensorId = useMemo(() => sensorArray[0]?.uid,[sensorArray]);
  const { saveReport } = useNewReportsContext();

  const formRef = useRef({});
  const formElRef = useRef<HTMLFormElement>(null);
  // list of fields to display in the form
  const timeFields = useMemo(() => ([
      {
        label: 'Hour',
        name: 'hour',
        type: 'select-box',
        defaultValue: (new Date().getHours() % 12)||12,
        values: [1,2,3,4,5,6,7,8,9,10,11,12]
      },
      {
        label: 'Minute',
        name: 'minute',
        type: 'select-box',
        defaultValue: new Date().getMinutes(),
        values: [...new Array(60)]?.map((_,i) => (i<10?'0'+i:i))
      },
      {
        label: '',
        name: 'ampm',
        type: 'select',
        defaultValue: new Date().getHours() >= 12 ? 'pm' : 'am',
        values: {
          am: 'AM',
          pm: 'PM'
        }
      },
    ]), []);

  const beforeAfterSelectValues = useRef({
    [30*60*1000]: '30 minutes',
    [45*60*1000]: '45 minutes',
    [60*60*1000]: '1 hour',
    [3*60*60*1000]: '3 hours',
  }).current;

  // this validates field on blur and adds results to validationsObj under fields name
  const [validationsObj, validatorApi] = useValidator(formRef,
    {
      email: validations.email,
      password: restrictors.required(new Error('Password field is required'))
    });

  const handleSubmitForm = useCallback(async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      if(!formElRef.current){
        throw new Error('application state error'); 
      }
      if(!sensorId){
        throw new Error('missing sensor');
      }
      setLoading(true);
      const formData = new FormData(formElRef.current);
      const serializedValues: any = {};
      for (var key of formData.keys()) {
        serializedValues[key] = formData.get(key);
      }
      const reportTime = new Date(serializedValues.date+' '+serializedValues.hour+':'+serializedValues.minute+' '+serializedValues.ampm).getTime();
      if(reportTime > Date.now()){
        throw new Error('Cannot select time in the future')
      }
      const startTimestamp = reportTime-serializedValues.beforeTime*1;
      const endTimestamp = Math.min((reportTime+serializedValues.afterTime*1),Date.now());
      const newReport: NewSaveReportPayload = {
        reportTime: reportTime,
        startTime: startTimestamp,
        endTime: endTimestamp,
        createdBy: user?.firstName+' '+user?.lastName,
        items: [],
        sensorId: Number(sensorId),
        length: 0
      }

      const items = await Promise.all([
        getRawCellListItems({
          source: 'cell',
          startTimestamp,
          endTimestamp,
          sensorId
        }),
        getRawCellListItems({
          source: 'cell-scan',
          startTimestamp,
          endTimestamp,
          sensorId
        })
      ]);

      const processedItems: Array<NewReportItem> = [];

      items[0]?.forEach(rawItem => {
        processedItems.push(processRawCellItemToReportItem(rawItem))
      });

      items[1]?.forEach(rawItem => {
        processedItems.push(processRawCellItemToReportItem(rawItem))
      });

      newReport.items = processedItems;
      newReport.length = processedItems.length;

      console.log('[ForensicReportCreationForm] got items', items, processedItems);

      // save report
      await saveReport(newReport);
      
      console.log('[ForensicReportCreationForm] report saved', items, processedItems);
      hidePopup();
    }
    catch (e) {
      console.error('Error creating the report', e);
      setFormError((e as Error).message);
    }
    finally {
      setLoading(false); 
    }

  },[
    user,
    sensorId,
    hidePopup,
    setLoading,
    saveReport,
    setFormError
  ]);

  return (
    <ForensicReportCreationFormContainer>
      {loading
      ?
      <Loading height={10} width={200} strokesSettings={{numberOfStrokes: 40, strokesSpaces: 45}} />
      :
      <PlainForm
        refference={formElRef}
        className={styles.loginForm+(formError===null?' '+styles.loginAppear:'')+(formError?' '+styles.hasError:'')}
        warnHeader={formError&&<><BiErrorCircle className={styles.headerIcon} />Error creating the report</>}
        onSubmit={handleSubmitForm}
        condenced
      >
      {formError ?
        <WarningMessage large>{formError}</WarningMessage> : null}
        <h2>Create a Forensic Report</h2>
        <p>Reports provide insights at the defined event time and the reporting window selected.</p>
        <InputGroup 
          form={formRef}
            type='date'
            name='date'
            label='Date'
            placeholder={format(Date.now(), 'm/d/yy')}
            status={validationsObj['date']}
            onChange={() => validationsObj['date'] && validatorApi.validateField('date')}
            />
        <h2>Time of Event</h2>
        <div className="flex-row">
          {timeFields?.map(({name,type,label,values,defaultValue}) => (
            <InputGroup
              defaultValue={defaultValue ? String(defaultValue) : undefined}

              key={name}
              form={formRef}
              type={type}
              name={name}
              label={label}
              values={values}
              onChange={() => validationsObj[name] && validatorApi.validateField(name)} />
          ))}
        </div>
        <div>
          {timeFields?.map(({name}) => (
            <div key={name}>
              {validatorApi.messages[name]?.length > 0 ? validatorApi.messages[name]?.map( (message) => {
                  return <WarningMessage key={message}>{message}</WarningMessage>;
              }) : null}
            </div>
          ))} 
        </div>
        <h2>Reporting Window</h2>       
        <div className="flex-row">
          <InputGroup
            form={formRef}
            type="select"
            name="beforeTime"
            label="Minutes Before Event"
            values={beforeAfterSelectValues}
            onChange={() => validationsObj['beforeTime'] && validatorApi.validateField('beforeTime')} />
          <InputGroup
            form={formRef}
            type="select"
            name="afterTime"
            label="Minutes After Event"
            values={beforeAfterSelectValues}
            onChange={() => validationsObj['afterTime'] && validatorApi.validateField('afterTime')} />
        </div>
        <div className="flex-row">
          <Button
            type="submit"
            className='submit-button'
            disabled={loading}
            loading={loading}
            variant="primary" >Create Report</Button>
          <Button
            className='cancel-button'
            disabled={loading}
            loading={loading}
            onClick={() => hidePopup()}
            variant="secondary">cancel</Button>
        </div> 
      </PlainForm>
      }
    </ForensicReportCreationFormContainer>
  );
}

const ForensicReportCreationFormContainer = styled.div`${({theme}) => `
  max-width: 330px;

  .flex-row {
    display: flex;

  }
  .flex-row > * {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: flex-end;
  }
  .flex-row > *:not(:first-child){
    margin-left: 0.5rem;
  }
  .flex-row input, 
  .flex-row select,
  .flex-row button {
    min-width: 0 !important;
  }
  .flex-row input {
    width: 100%
  }
  .submit-button {
    flex-grow: 3;
  }
  .cancel-button {
    flex-grow: 1
  }

  .form-header {
    margin-left: -${theme.boxPadding.xl3}px;
    margin-right: -${theme.boxPadding.xl3}px;
    margin-top: -${theme.boxPadding.xl3}px;
  }

  .form-header svg {
    margin-right: ${theme.boxPadding.l}px;
    vertical-align: middle;
  }
`}`;