import { useCallback } from 'react';

import { devicesStore, homeStore, profilesStore, sensorsStore } from '../store';

const getHome = homeStore.getState().getHome;
const { getProfiles, getProfilesPresence } = profilesStore.getState();
const { getDevices } = devicesStore.getState();
const getSensors = sensorsStore.getState().getSensors;

export const useGetAllData = () => {
  const getAllData = useCallback(async function getAllData() {
    const responses = (await Promise.all([
      getHome(),
      getProfiles(),
      getProfilesPresence(),
      getDevices(),
      getSensors(),
    ])).filter(r => !!r);

    return responses.length ? true : null;
  }, []);

  return { getAllData };
};