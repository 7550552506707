import React from 'react';
import {IconProps} from './_iconProps';

export const PhoneOff: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_149_637)">
<path d="M6.91999 17.08C4.67447 13.6173 3.27247 9.67623 2.82666 5.57334C2.79334 5.20463 2.83716 4.83302 2.95533 4.48218C3.0735 4.13133 3.26342 3.80893 3.51302 3.5355C3.76261 3.26208 4.0664 3.04362 4.40505 2.89404C4.7437 2.74446 5.10978 2.66703 5.47999 2.66668H9.47999C10.1271 2.66031 10.7544 2.88945 11.245 3.31139C11.7356 3.73333 12.0561 4.31927 12.1467 4.96001C12.3155 6.2401 12.6286 7.49698 13.08 8.70668C13.2594 9.18391 13.2982 9.70256 13.1919 10.2012C13.0855 10.6998 12.8385 11.1575 12.48 11.52L10.7867 13.2133M30.6667 1.33334L1.33333 30.6667M14.24 17.7467C15.5931 19.101 17.1224 20.267 18.7867 21.2133L20.48 19.52C20.8425 19.1615 21.3002 18.9145 21.7988 18.8081C22.2974 18.7018 22.8161 18.7406 23.2933 18.92C24.503 19.3714 25.7599 19.6845 27.04 19.8533C27.6807 19.9439 28.2667 20.2644 28.6886 20.755C29.1106 21.2456 29.3397 21.8729 29.3333 22.52V26.52C29.3348 26.8913 29.2588 27.2589 29.11 27.5991C28.9612 27.9394 28.7431 28.2448 28.4694 28.4958C28.1958 28.7469 27.8728 28.938 27.521 29.0569C27.1692 29.1759 26.7965 29.2201 26.4267 29.1867C22.3238 28.7409 18.3827 27.3389 14.92 25.0933C13.3137 24.0734 11.8247 22.8795 10.48 21.5333L14.24 17.7467Z" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</g>
<defs>
<clipPath id="clip0_149_637">
<rect width={width} height={height} fill="white"/>
</clipPath>
</defs>
</svg>
