import React from 'react';
import {IconProps} from './_iconProps';

export const Smartphone: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M16 24H16.0133M9.33334 2.66669H22.6667C24.1394 2.66669 25.3333 3.86059 25.3333 5.33335V26.6667C25.3333 28.1394 24.1394 29.3334 22.6667 29.3334H9.33334C7.86058 29.3334 6.66667 28.1394 6.66667 26.6667V5.33335C6.66667 3.86059 7.86058 2.66669 9.33334 2.66669Z" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</svg>
