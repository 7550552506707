import { apiRequest } from '../apiRequest';
import { 
  TmsiRespose,
  ActivityRespose, 
  CreateTmsiReportPayload, 
  CreateActivityReportPayload, 
} from './types';

export const getRawTmsiReport = async (payload: CreateTmsiReportPayload) => {
  const res = await apiRequest<TmsiRespose>({
    service: 'dataAPI',
    path: '/api/tmsi_report',
    method: 'POST',
    data: {
      sensor_id:payload.sensorId,
      start_epoch:payload.epochStart,
      end_epoch:payload.epochEnd,
      providers: payload.providers
    }  
  });

  if(res.data?.['Items Returned']){
    const items = res.data['Items Returned'];
    return items;
  } else if(res.data?.['Status Code'] !== 200){
    console.error('[getTmsiReport] status is not 200:', res.data?.['Status Code'], res.data?.Reason);
    throw new Error( String(res.data?.['Status Code'])+' '+String(res.data?.Reason) );
  } else {
    return null;
  }
};

export const getRawActivityReport = async (payload: CreateActivityReportPayload) => {
  const res = await apiRequest<ActivityRespose>({
    service: 'dataAPI',
    path: '/api/activity_report',
    method: 'POST',
    data: {
      sensor_id:payload.sensorId,
      start_epoch:payload.epochStart,
      end_epoch:payload.epochEnd,
      time_bin:payload.time_bin
    }  
  });

  if(res.data?.['Items Returned']){
    const items = res.data['Items Returned'];
    return items;
  } else if(res.data?.['Status Code'] !== 200){
    console.error('[getTmsiReport] status is not 200:', res.data?.['Status Code'], res.data?.Reason);
    throw new Error(String(res.data?.['Status Code'])+' '+String(res.data?.Reason));
  } else {
    return null;
  }
};