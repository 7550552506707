import React from 'react';
import {IconProps} from './_iconProps';

export const Pocket: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10.6667 13.3333L16 18.6667L21.3333 13.3333M5.33334 4H26.6667C27.3739 4 28.0522 4.28095 28.5523 4.78105C29.0524 5.28115 29.3333 5.95942 29.3333 6.66667V14.6667C29.3333 18.2029 27.9286 21.5943 25.4281 24.0948C22.9276 26.5952 19.5362 28 16 28C14.249 28 12.5152 27.6551 10.8976 26.9851C9.27988 26.315 7.81003 25.3329 6.57191 24.0948C4.07143 21.5943 2.66667 18.2029 2.66667 14.6667V6.66667C2.66667 5.95942 2.94762 5.28115 3.44772 4.78105C3.94782 4.28095 4.62609 4 5.33334 4Z" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</svg>
