import React from 'react';
import {IconProps} from './_iconProps';

export const UploadCloud: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M21.3333 21.3334L16 16M16 16L10.6666 21.3334M16 16V28M27.1866 24.52C28.4871 23.811 29.5144 22.6892 30.1065 21.3315C30.6985 19.9738 30.8216 18.4577 30.4563 17.0223C30.0909 15.5869 29.258 14.314 28.0889 13.4046C26.9198 12.4952 25.4811 12.001 24 12H22.32C21.9164 10.439 21.1642 8.9898 20.1199 7.76134C19.0756 6.53289 17.7664 5.55715 16.2908 4.9075C14.8151 4.25784 13.2114 3.95116 11.6001 4.01053C9.98889 4.06989 8.41207 4.49375 6.98821 5.25024C5.56436 6.00673 4.33053 7.07616 3.37947 8.37813C2.42842 9.6801 1.7849 11.1807 1.49728 12.7672C1.20967 14.3537 1.28545 15.9847 1.71893 17.5377C2.15241 19.0907 2.9323 20.5252 3.99998 21.7334" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</svg>
