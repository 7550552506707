import React from 'react';
import {IconProps} from './_iconProps';

export const Smile: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10.6667 18.6667C10.6667 18.6667 12.6667 21.3334 16 21.3334C19.3333 21.3334 21.3333 18.6667 21.3333 18.6667M12 12H12.0133M20 12H20.0133M29.3333 16C29.3333 23.3638 23.3638 29.3334 16 29.3334C8.63621 29.3334 2.66667 23.3638 2.66667 16C2.66667 8.63622 8.63621 2.66669 16 2.66669C23.3638 2.66669 29.3333 8.63622 29.3333 16Z" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</svg>
