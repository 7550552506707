/*
`dev/sensor/12290/data/raw/cell/scan`
`dev/sensor/12290/data/raw/cell`
`dev/sensor/12290/data/raw/wifi/probe-requests`
`dev/sensor/12290/data/filtered/bluetooth/changes`
`dev/sensor/12290/data/filtered/bluetooth/unique-mac-list`
`dev/sensor/12290/data/raw/bluetooth/adv-packets`
`dev/sensor/12290/data/filtered/bluetooth/scanned`
*/
import {useEffect, useState} from 'react';

import { PubSub } from 'aws-amplify';

//import { ENV } from 'config';

import {Listeners} from 'classes';
import {binaryToDecimal} from 'utils';
import { useEdgeDaemonStore, useSensorsStore } from 'context';

export type CapturedSignal = {
  type: 'cell'|'cell-scan'|'wifi'|'bluetooth';
  identifier: string;
  power: number;
  timestamp: number;
  mac?: string;
}

export type CellSignal = CapturedSignal & {
  type: 'cell'|'cell-scan',
  cellProvider: string;
  establishmentCause?: string;
}

export type WifiSignal = CapturedSignal & {
  type: 'wifi',
  ssid: string
}

export type BluetoothSignal = CapturedSignal & {
  type: 'bluetooth'
}

export type CapturedSignalType = CellSignal|WifiSignal|BluetoothSignal;

class CapturedQueue {
  timeStart: number=Date.now();
  timeEnd: number|null=null;
  queue: Array<CellSignal|WifiSignal|BluetoothSignal>=[];
  batchPointers: Array<{
    startPosition: number;
    endPosition: number;
    timestamp: number;
  }>=[];
}

class quequeStore<T>{
  all: Array<CapturedQueue>=[];
  current: CapturedQueue | undefined;

  startQ(){
    const q = new CapturedQueue();
    this.all.push(q);
    this.current = q;
  }
  stopQ(){
    if(!this.current){return;}
    this.current.timeEnd = Date.now();
    this.current = undefined;
  }
  pushBatch(batch: Array<T>){
    if(!this.current){return;}
    const startPosition = this.current?.queue.length;
    const endPosition = startPosition + batch.length;
    const timestamp = Date.now();
    Array.prototype.push.apply(this.current.queue,batch);
    this.current.batchPointers.push({
      startPosition,
      endPosition,
      timestamp
    });
  }
}

function payloadFromData(data: any){
  const dataType = data.value.data_type?.[0]||(function(){
    const symbol = Object.getOwnPropertySymbols(data.value)[0];
    return String(data.value[symbol]).replace(/^.*?([^/]*)$/g,'$1');
  }());
  const value = data.value;
  const payloads:Array<CellSignal|WifiSignal|BluetoothSignal> =[];
  console.log('--- adding value', value);
  switch (dataType){
    case 'bt-adv-packet':
    case 'passive-bt':
        value['bt-adv-packet']?.forEach( (advp:any) => {
          payloads.push({
            type: 'bluetooth',
            identifier: advp.manufacturerName&&advp.manufacturerName!=='-'?advp.manufacturerName:advp.derivedManufacturerName,
            power: advp.rssi,
            timestamp: advp.timestamp,
            mac: advp.mac
          });
        }); 
        break;
    case 'cell':
        payloads.push({
          type: 'cell',
          identifier: String(binaryToDecimal(value.cell?.rach_message?.['s-TMSI']?.['m-TMSI'])),
          power: value.cell?.rf_power_estimate,
          cellProvider: value.cell.downlink_info.networkProvider,
          timestamp: value.timestamp,
          establishmentCause: Object.keys(value.cell?.rach_message_xml?.children?.c1.children?.rrcConnectionRequest?.children?.criticalExtensions?.children?.["rrcConnectionRequest-r8"]?.children?.establishmentCause?.children||{})[0]
        });
        break;
    case 'cell-scan':
        payloads.push({
          type: 'cell-scan',
          identifier: '',
          power: value['cell-scan']?.rf_power_estimate,
          cellProvider: value['cell-scan']?.network_provider,
          timestamp: value.timestamp
        });
        break;
    case 'wifi-probe-request':
    case 'passive-wifi':
        value['wifi-probe-request']?.forEach( (preq: any) => {
          payloads.push({
            type: 'wifi',
            identifier: preq.manufacturer,
            ssid: preq.ssid,
            power: preq.rfPowerEstimate,
            timestamp: preq.timestamp,
            mac: preq.srcMac
          });
        });
        break;
    case 'lte-survey':
    case 'bt-active':
    case 'decode-failures':
    case 'wifi-networked':
    default:
      break;
  }

  return payloads;
}

const onData = new Listeners<Array<CellSignal|WifiSignal|BluetoothSignal>>();
const onError = new Listeners();
const onClose = new Listeners();
let subscribtion: any, 
    connected=false;
// init qstore 
const qstore = new quequeStore();
onData.addListener((signals) => {
  qstore.pushBatch(signals);
});

function connectToTopics(sensorId: string, daemonIds?: Array<string>){
  try {
    console.log('[connectToTopics] connecting observer to mqtt topics');
    subscribtion?.unsubscribe?.();
    const topics = [
        //`${ENV.environment}/sensor/${sensorId}/data/raw/cell/scan`,
        //`${ENV.environment}/sensor/${sensorId}/data/raw/cell`,
        `rf_vision/sensor/${sensorId}/cell-scan`,
        `rf_vision/sensor/${sensorId}/cell`,
        // `${ENV.environment}/sensor/${sensorId}/data/raw/wifi/probe-requests`,
        // `${ENV.environment}/sensor/${sensorId}/data/raw/bluetooth/adv-packets`,

        `rf_vision/sensor/${sensorId}/passive-bt`,
        //`rf_vision/sensor/${sensorId}/cell`,
        //`rf_vision/sensor/${sensorId}/cell-scan`,
        `rf_vision/sensor/${sensorId}/passive-wifi`,
        

        // `rf_vision/sensor/13011/passive-bt`,
        // `rf_vision/sensor/13011/cell`,
        // `rf_vision/sensor/13011/cell-scan`,
        // `rf_vision/sensor/13011/passive-wifi`,

      
        // `dev/sensor/${sensorId}/data/filtered/bluetooth/changes`,
        // `dev/sensor/${sensorId}/data/filtered/bluetooth/unique-mac-list`,
        // `dev/sensor/${sensorId}/data/filtered/bluetooth/scanned`,
        
      ];

    // add in daemons
    daemonIds?.forEach(id => {
      topics.push(`rf_vision/daemon/${id}/passive-bt`);
      topics.push(`rf_vision/daemon/${id}/passive-wifi`);
    });

    console.log('[connectToTopics] topics:', topics);

    subscribtion = PubSub.subscribe(topics)
      .subscribe({
        next: (data: any) => {
          const payload = payloadFromData(data);
          // console.log('[connectToTopics] --- got next', {
          //   data,
          //   payload,
          // });

          onData.fire(payload);
        },
        error: (error: any) => {
          console.log('[connectToTopics] mqtt observer connection error', error);
        },
        complete: () => {
          connected=false;
          console.log('[connectToTopics] mqtt observer connection closed');
        }
      });

    connected=true;
  }
  catch (error){
    console.log('[connectToTopics] error connecting to mqtt', error);
    connected=false;
  }
}

let members = 0;

export const dataObserverListeners = onData;

export const useDataFeedObserver = ({
  onData: passedOnData,
  onError: passedOnError,
  onClose: passedOnClose,
  captureOnBackground
}:{
  onData?: (signal: Array<CellSignal|WifiSignal|BluetoothSignal>) => void,
  onError?: () => void,
  onClose?: () => void,
  captureOnBackground?: boolean
}={}) => {

  const [newestSignals, setNewestSignals] = useState<Array<CellSignal|WifiSignal|BluetoothSignal>>([]);
  const { activeSensor } = useSensorsStore(s => s);
  const { daemons } = useEdgeDaemonStore(s => s);

  useEffect(function connectIfNotYetConnected(){
    if(!activeSensor&&!daemons?.filter(d => d.active).length){ return; }
    members++;
    if(!connected){
      qstore.stopQ();
      qstore.startQ();
      connectToTopics(`${activeSensor?.uid}`||'', daemons?.map(d => `${d?.uid}`));
    }
    return () => {
      if(!captureOnBackground && members-1 <= 0){
        members--;
        subscribtion?.unsubscribe?.();
        qstore.stopQ();
        connected = false;
      }
    };
  },[
    activeSensor,
    captureOnBackground,
    daemons
  ]);

  useEffect(function localSignalsListenerOnAllSignalsOnInitIfNoActiveInstancesYet(){
    const remove = onData.addListener(function(signals){
      if(!signals){return;}
      setNewestSignals(signals);
    });

    return () => remove();
  },[]);

  useEffect(function hookUpListener(){
    if(!passedOnData){return;}
    const remove = onData.addListener(passedOnData);
    
    return () => remove();
  },[passedOnData]);

  useEffect(function hookUpListener(){
    if(!passedOnError){return;}
    const remove = onError.addListener(passedOnError);
    return () => remove();
  },[passedOnError]);

  useEffect(function hookUpListener(){
    if(!passedOnClose){return;}
    const remove = onClose.addListener(passedOnClose);
    return () => remove();
  },[passedOnClose]);
  
  return {
    connectToTopics,
    connected,
    queues: qstore.all,
    currentQueue: qstore.current,
    newestSignals,
    qstore
  };
};