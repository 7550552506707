import React from 'react';
import {IconProps} from './_iconProps';

export const Trash: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4 8.00002H6.66667M6.66667 8.00002H28M6.66667 8.00002V26.6667C6.66667 27.3739 6.94762 28.0522 7.44772 28.5523C7.94781 29.0524 8.62609 29.3334 9.33333 29.3334H22.6667C23.3739 29.3334 24.0522 29.0524 24.5523 28.5523C25.0524 28.0522 25.3333 27.3739 25.3333 26.6667V8.00002H6.66667ZM10.6667 8.00002V5.33335C10.6667 4.62611 10.9476 3.94783 11.4477 3.44774C11.9478 2.94764 12.6261 2.66669 13.3333 2.66669H18.6667C19.3739 2.66669 20.0522 2.94764 20.5523 3.44774C21.0524 3.94783 21.3333 4.62611 21.3333 5.33335V8.00002" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</svg>
