import axios from 'axios';

import {ENV_HOLDER} from '../env';

export type WigleSearchPayload = {
  onlymine?: string;
  notmine?: string;
  latrange1?: number;
  latrange2?: number;
  longrange1?: number;
  longrange2?: number;

  closestLat?: number;
  closestLong?: number;
  lastupdt?: string;

  startTransID?: string; //'yyyyMMdd-00000';
  endTransID?: string; //'yyyyMMdd-00000';
  encryption?: 'None'|'WEP'|'WPA'|'WPA2'|'WPA3'|'Unknown';
  freenet?: boolean;
  paynet?: boolean;
  netid?: string;

  ssid?: string;
  ssidlike?: string // wildcards '%' (any string) and '_' (any character).;

  minQoS?: number //integer($int32);
  variance?: number;

  houseNumber?: string;
  road?: string;
  city?: string;
  region?: string;
  postalCode?: string;
  country?: string;
  resultsPerPage?: number; //integer($int64); Bounded at 1000 for COMMAPI, 100 for site.;
  searchAfter?: string;
}

export type WigleSearchResponse = {
    'success': boolean,
    'totalResults': number,
    'first': number,
    'last': number,
    'resultCount': number,
    'results': Array<WigleItem>,
    'searchAfter': string,
    'search_after': number
}

export type WigleItem = {
    'trilat': number, //42.05528641,
    'trilong': number, //-87.74407196,
    'ssid': string|null, //"RushPub",
    'qos': number, //2,
    'transid': string|null, //"20190104-00000",
    'firsttime': string|null, //"2019-01-04T10:00:00.000Z",
    'lasttime': string|null, //"2022-09-13T16:00:00.000Z",
    'lastupdt': string|null, //"2022-09-13T20:00:00.000Z",
    'netid': string|null, //"B4:E9:B0:B4:2A:70",
    'name': string|null,
    'type': string|null, //"infra",
    'comment': string|null,
    'wep': string, //"?",
    'bcninterval': number, //0,
    'freenet': string|null, //"?",
    'dhcp': string|null, //"?",
    'paynet': string|null, //"?",
    'userfound': boolean, //false,
    'channel': number, //6,
    'encryption': string|null, //"unknown",
    'country': string|null, //"US",
    'region': string|null, //"IL",
    'city': string|null,
    'housenumber': string|null, //"4711",
    'road': string|null, //"Golf Road",
    'postalcode': string|null //"60076"
}
/*
  ssid
  city
  closestLat
  closestLong
  resultsPerPage
  lastupdt
*/

export const wigleSearch: (payload: WigleSearchPayload&{ssid: string}) => Promise<WigleSearchResponse|null> = async (payload) => {
  const wigleAuthHeader = ENV_HOLDER.current?.wigle_auth_header || ''; // "Basic QUlEYTNkM2ZhNTI1OWEwZGVjZDBjYmVkMGQ4ODcwNzc2MWI6NjUzZGRkYzc5ZDU3MTAxNDAzZmQ3YmJkZTM5YjMyMmM=";
  const wigleSearchUrl = ENV_HOLDER.current?.wigle_search_url || ''; //"https://api.wigle.net/api/v2/network/search";

  try {
    console.log('[wigleSearch] request', payload);

    const res = await axios.get<WigleSearchResponse>(wigleSearchUrl, {
      params: payload,
      headers: {
        'Authorization': wigleAuthHeader
      }
    });

    console.log('[wigleSearch] response is', {payload, res});

    return res.data;
  }
  catch (e) {
    console.error('[wigleSearch] error getting wigle search response', e);
    return null;
  }
};
