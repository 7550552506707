import React from 'react';

import styled from 'styled-components';

import {UID} from 'utils';

import styles from './Input.module.css';

import {SelectBox, SelectBoxProps} from './SelectBox';

export type SelectBoxInputProps = SelectBoxProps & {
  status?: 'accepted' | 'bad' | 'unfulfilled' | undefined,
  disabled?: boolean,
  id?: string;
  className?: string;
}

// Select Box Input Type
export const SelectBoxInput: React.FC<SelectBoxInputProps> = ({
  title,
  defaultValue,
  value,
  values,
  mapper= value => String(value),
  onSelect,
  search,
  tabIndex,

  name='',
  el,
  
  id=UID(),
  className='',
  disabled=false,
  status
}) =>
  
  <StyledSelectBoxContainer
    id={id}
    className={className+' '+styles.input}
    disabled={disabled}
    status={status}>
    <SelectBox
      title={title}
      key={id}
      el={el}
      name={name}
      value={value}
      defaultValue={defaultValue}
      onSelect={onSelect}
      values={values}
      mapper={mapper}
      tabIndex={tabIndex}
      search={search}
      />
  </StyledSelectBoxContainer>;


const StyledSelectBoxContainer = styled.div.attrs({} as {
  status: string;
  disabled: boolean;
})`${({theme, status}) => `
  

  ${!status ? `
    outline-color: rgba(${theme.colorsRgb.newColors.MidnightShores.toString()}, 0.7);
  ` : ''}
  ${status === 'accepted' ? `
    outline-color: rgba(${theme.colorsRgb.newColors.success.toString()}, 0.7);
    outline-style: solid;
  ` : ''}

  ${status === 'unfulfilled' ? `
    outline-color: rgba(${theme.colorsRgb.newColors.warning.toString()}, 0.7);
    outline-style: solid;
  ` : ''}

  ${status === 'bad' ? `
    outline-color: rgba(${theme.colorsRgb.newColors.error.toString()}, 0.7);
    outline-style: solid;
  `: ''}

  .selectBox {
    width: 100%;
  }

  .selectBox input {
    

    margin: 0px !important;
    ${theme.fontStyles.field}
    border: 1px solid ${theme.newColors.MidnightShores};
    padding: ${theme.fontPadding.s}px ${theme.fontPadding.m}px;
    border-radius: ${theme.fontPadding.base*0.25}px;
    
    color: ${theme.newColors.MidnightShores};
    background-color: ${theme.newColors.SwissCream};

    &::placeholder {
      color: rgba(${theme.colorsRgb.newColors.MidnightShores.toString()},0.7);
    }
    &:-ms-input-placeholder{
      color: rgba(${theme.colorsRgb.newColors.MidnightShores.toString()};
    }
    &::-ms-input-placeholder {
      color: rgba(${theme.colorsRgb.newColors.MidnightShores.toString()},0.7);
    }

    outline: none;
    outline-offset: -2px;
    outline-width: 2px;

    &:focus {
      box-shadow: 0 0 10px rgba(${
        (!status && theme.colorsRgb.newColors.information.toString())
        || (status === 'accepted' && theme.colorsRgb.newColors.success.toString())
        || (status === 'unfulfilled' && theme.colorsRgb.newColors.warning.toString())
        || (status === 'bad' && theme.colorsRgb.newColors.error.toString()) }, 0.7);
    }
    
    &:focus {
      outline-offset: -2px;
      outline-width: 2px;
      outline-style: solid;

      ${!status ? `
        outline-color: rgba(${theme.colorsRgb.blue.base.toString()}, 0.5);
      `:''}
    }
  }
  .valueBox {
    display: flex;
    align-items: center;

  }
  .valueBox > span {

    flex-grow: 10;
  }
`}`;
