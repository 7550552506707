import React from 'react';
import {IconProps} from './_iconProps';

export const Send: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M29.3333 2.66666L14.6667 17.3333M29.3333 2.66666L20 29.3333L14.6667 17.3333M29.3333 2.66666L2.66666 12L14.6667 17.3333" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</svg>
