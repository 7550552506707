// generic error type
export enum ErrorCodes {
  default = 0,
  inconclusive = 1,
}

export class ContextError extends Error {
  isCustomError = true;
  isLocal?: boolean = true;
  errorCode?: ErrorCodes = ErrorCodes.default;

  constructor(message: string, options?: Partial<Pick<ContextError, 'isLocal'|'errorCode'>>){
    super(message);
    for(const key in options){
      (this as any)[key] = (options as any)[key];
    }
  }
}