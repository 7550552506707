import { RawAlert, Alert } from '@ubiety/fe-api';
import { getDayOfYear, getYear } from 'date-fns';

import { AlertMap, DailyIndexedAlerts, GroupAlert, IndexedSensorAlertMap, IndexedSensorAlert } from '../types/alerts';
import { objectKeysSnakeToCamelCase } from '../utils';

export const ACCEPTED_ALERTS_LIST = [
  'UNIT_ONLINE_OFFLINE',
  'UNKNOWN_DEVICE',
  'UNASSIGNED_DEVICE',
  'PROFILE_ENTER',
  'PROFILE_EXIT'
] as const;

export const acceptedAlerts = (ACCEPTED_ALERTS_LIST as any) as Array<(typeof ACCEPTED_ALERTS_LIST)[number]>;

export const processRawAlertEntry = (entry: RawAlert) => {
  const tempEntry = {...entry, alert_type: entry.type, push_notification: entry.push_notification, contributing_factors: entry.contributing_factors};
  return objectKeysSnakeToCamelCase(tempEntry) as Alert;
};

export const processRawAlertEntryArray = (rawAlerts: Array<RawAlert>) => {
  const processedAlertsIndex: AlertMap = {};
  const processedAlertsArray: Array<Alert> = [];

  rawAlerts.forEach(a => {
    const processedAlerts = processRawAlertEntry(a);
    processedAlertsIndex[processedAlerts.uid] = processedAlerts;
    processedAlertsArray.push(processedAlerts);
  });

  return {
    alertArray: processedAlertsArray,
    alerts: processedAlertsIndex
  };
};

export const getAlertTimestamp = (alert: Alert | GroupAlert) => (((
  alert?.alertType === 'PROFILE_EXIT' &&
  alert?.contributingFactors?.last_seen_ts
)
  ? alert.contributingFactors?.last_seen_ts
  : alert?.createdAt) || 0) * 1000;

// idea is we will index diggesting alerts daily and use such before additional requests
// when paginated alerts days are marked as complete one step behind
export const digestRawAlerts = (data: Array<RawAlert>, paginated = false, dailyIndexedAlerts: DailyIndexedAlerts, dailyIndexedAlertsIndex: AlertMap) => {
  let prevDate: number;
  const now = Date.now();
  const today = getYear(now) * 1000 + getDayOfYear(now);
  const digestedData: Array<Alert> = [];
  const indexedData: AlertMap = {};

  data?.forEach((a) => {
    const alert = a ? processRawAlertEntry(a) : null;
    if (!alert) { return; }
    const dateMarker = alert ? getYear(alert.createdAt * 1000) * 1000 + getDayOfYear(alert.createdAt * 1000) : null;
    if (!dateMarker) { return; }
    if (!dailyIndexedAlerts[dateMarker]) {
      dailyIndexedAlerts[dateMarker] = {
        markerDate: dateMarker,
        complete: !paginated && dateMarker !== today ? true : false, // this counts on all non paginated requests being "rouded up" daily 
        alerts: []
      };
    }

    if (!dailyIndexedAlertsIndex[alert.uid]) {
      dailyIndexedAlerts[dateMarker].alerts.push(alert);
      dailyIndexedAlertsIndex[alert.uid] = alert;
    }

    indexedData[alert.uid] = alert;
    digestedData.push(alert);

    if ((paginated && prevDate && prevDate !== dateMarker) || (!paginated && dailyIndexedAlerts[prevDate])) {
      dailyIndexedAlerts[prevDate].complete = prevDate !== today ? true : false;
    }
    prevDate = dateMarker;
  });

  return { digestedData, indexedData };
};

// digesting sensor alerts is diferent in a sense that introduces 
// the fact we need one beofre the period and one after the period
// and that there may be days without alerts
// so we shall keep next and prev on the digested object
// we should revisit unread alerts logic and along with this
export const digestSensorsResponse = (data: {
  rawAlerts: Array<RawAlert>;
  startMarker: number;
  endMarker: number;
}, sensorAlertsIndex: IndexedSensorAlertMap,) => {
  const { rawAlerts, startMarker, endMarker } = data;

  const alerts: Array<IndexedSensorAlert> = [];
  const now = Date.now();
  const today = getYear(now) * 1000 + getDayOfYear(now);
  // markCoveredPeriod
  for (let m = startMarker; m <= endMarker && today !== m; m++) {
    sensorAlertsIndex[m] = [];
  }
  rawAlerts.forEach(rawAlert => {
    const marker = rawAlert.created_at ? getYear(rawAlert.created_at * 1000) * 1000 + getDayOfYear(rawAlert.created_at * 1000) : 0;
    const alert = rawAlert ? processRawAlertEntry(rawAlert) : null;
    if (!alert) { return; }
    const sensorAlert = alert;

    if (sensorAlertsIndex[marker]) {
      sensorAlertsIndex[marker].push(sensorAlert);
    }

    alerts.push(sensorAlert);
  });
  console.log('[AlertsStore] preserved alerts', { sensorAlertsIndex, alerts });

  return alerts;
};

export const eachForGetTimeframeAlerts = function (dm: number, alerts: DailyIndexedAlerts, collectionToPush: Array<Alert>, ends: Array<number>, options: { startsAtLesserEnd: boolean, forceUpdate?: boolean }) {
  const { startsAtLesserEnd, forceUpdate } = options;
  if(forceUpdate){
    return true;
  }
  const dayIndex = alerts[dm];
  if(dayIndex?.complete){
    collectionToPush.push(...dayIndex.alerts);
  } else {
    // new end marker
    const newEndNumber = Number(!startsAtLesserEnd);
    ends[newEndNumber] = dm;
    return true;
  }
};

export const eachForGetSensorOnlineOfflineTimeframeAlerts = function (dm: number, alerts: IndexedSensorAlertMap, collectionToPush: Array<Alert>, ends: Array<number>, options: { startsAtLesserEnd: boolean, forceUpdate?: boolean }) {
  const { startsAtLesserEnd, forceUpdate } = options;
  if(forceUpdate){
    return true;
  }
  const dayIndex = alerts[dm];
  if(dayIndex){
    collectionToPush.push(...dayIndex);
  } else {
    // new end marker
    const newEndNumber = Number(!startsAtLesserEnd);
    ends[newEndNumber] = dm;
    return true;
  }
};

export const getDatesDataForTimeframe = (timeframe: { timeStart: number; timeEnd: number }) => {
  const { timeStart, timeEnd } = timeframe;

  const epochStart = Math.round(timeStart / 1000);
  const epochEnd = Math.round(timeEnd / 1000);

  // lesser date
  const startMarker = getYear(timeStart) * 1000 + getDayOfYear(timeStart); // date in format 2023001; 2023365
  // greater date
  const endMarker = getYear(timeEnd) * 1000 + getDayOfYear(timeEnd);

  const ends = [startMarker, endMarker].sort((m1, m2) => m1 - m2); // lesser first

  return {
    epochStart,
    epochEnd,
    startMarker,
    endMarker,
    ends,
  };
};
