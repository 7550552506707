import React from 'react';
import {IconProps} from './_iconProps';

export const Volume1: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M20.72 11.28C21.9698 12.5302 22.6719 14.2256 22.6719 15.9934C22.6719 17.7611 21.9698 19.4565 20.72 20.7067M14.6667 6.66669L7.99999 12H2.66666V20H7.99999L14.6667 25.3334V6.66669Z" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</svg>
