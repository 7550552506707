import {hexToRgb} from 'utils';

export const newColors = {
  GreenJuice: "#588157",
  SunsetCrush: "#753C1B",

  GreenMachine: "#344E41",
  BgGreen: '#3A4E41',
  OrangeDream: "#F47B37",

  SwissCream: "#FFFEF8",
  WhippedCream: "#FEFAE3",
  OrangeCream: "#FCEDCF",

  DarkStar: "#3B3C37",
  DarkGreen: '#28362D',
  MidnightShores: "#5D6456",
  MidnightFoam: "#C2CAA8",
  ShadowMode: "#E0DBD4",

  MildRed: "#E74927",

  'success': "#588157",
  'information': "#08A7CA",
  'warning': "#FFC804",
  'error': "#E74927",

  'att': "#469DD6",
  'att-secondary': "#ACD3EC",
  'verizon': "#D60000",
  'verizon-secondary': "#FF9999",
  'tmobile': "#FC118A",
  'tmobile-secondary': "#FE9ACD",
  'other': "#770FFF",
  'other-secondary': "#C599FF"
}

// Gray
const gray = {
  black: '#1b1b1b',
  darkest: '#262626',
  darker: '#404040',
  dark: '#525252',
  base: '#737373',
  light: '#A3A3A3',
  lighter: '#D4D4D4',
  lightest: '#E5E5E5',
  white: '#F5F5F5'
};

// Blue
const blue = {
  darkest: '#182E4D',
  dark: '#094d96',
  base: '#0568D1',
  light: '#00A1D5',
};

// Orange
const orange = {
  base: '#EF9A26',
  light: '#FAB250'
};

// Red
const red = {
  dark: '#5c0205',
  base: '#C1272D',
  light: '#DE3A40'
};

// Green
const green = {
  base: '#3ca102',
  light: '#73bd48'
};

const ink = {
  black: '#000000',
  darkest: '#090A0A',
  darker: '#202325',
  dark: '#303437',
  base: '#404446',
  light: '#6C7072',
  lighter: '#72777A',
};

const sky = {
  dark: '#979C9E',
  base: '#CDCFD0',
  light: '#E3E5E5',
  lighter: '#F2F4F5',
  lightest: '#F7F9FA',
  white: '#ffffff',
};

const insightBlue = {
  base: '#00E5D9'
};

const waveGreen = {
  base: '#4ED300'
};

const tertiary = {
  lighter: '#A9E5FE'
};

export const colors: ColorsNamedList = {
  gray,
  blue,
  orange,
  red,
  green,

  ink,
  sky,

  insightBlue,
  waveGreen,
  tertiary,

  primary: {
    dark: blue.dark,
    base: blue.base,
    light: '#3A90F2',
    lightest: '#E6F3FE',
  },

  secondary: {
    base: blue.darkest
  },

};

type ColorIdentifier = {
  black?: string;
  darkest?: string;
  darker?: string;
  dark?: string;
  base?: string;
  light?: string;
  lighter?: string;
  lightest?: string;
  white?: string;
}

interface ColorsNamedList {
  [key: string]: ColorIdentifier
}

export const colorsRgb: {
  [key: string]: {
    [key: string]: string
  }
} = (function copyColorsToRgbValues(){
  const copy: {
    [key: string]: {
      [key: string]: string
    }
  } = {};

  Object.entries({...colors, newColors: newColors})?.forEach(([name, shadesHexValues]) => {
    copy[name] = Object.fromEntries( 
      Object
        .entries(shadesHexValues)
        ?.map(([name, hexValue]) => {
          return hexValue ? [name, hexToRgb(hexValue)] : [];
        })
    );
  });

  return copy;

}());
