import {
  getDate,
  getMonth,
  getYear,
} from 'date-fns';

type DateObject = {
  day: number,
  month: number,
  year: number,
  str: string
}
export const makeDateObj = function(d:Date): DateObject{
  const dateObj = {
    day: getDate(d),
    month: getMonth(d),
    year: getYear(d),
    str: ''
  };
  dateObj.str = dateObj.year+'/'+(dateObj.month<9?0:'')+(dateObj.month+1)+'/'+(dateObj.day<10?0:'')+dateObj.day;
  return dateObj;
};
