import React from 'react';
import {IconProps} from './_iconProps';

export const ThumbsUp: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.33334 29.3334H5.33334C4.62609 29.3334 3.94782 29.0524 3.44772 28.5523C2.94762 28.0522 2.66667 27.3739 2.66667 26.6667V17.3334C2.66667 16.6261 2.94762 15.9478 3.44772 15.4477C3.94782 14.9476 4.62609 14.6667 5.33334 14.6667H9.33334M18.6667 12V6.66669C18.6667 5.60582 18.2452 4.58841 17.4951 3.83826C16.745 3.08811 15.7275 2.66669 14.6667 2.66669L9.33334 14.6667V29.3334H24.3733C25.0164 29.3406 25.6405 29.1152 26.1305 28.6987C26.6206 28.2822 26.9436 27.7026 27.04 27.0667L28.88 15.0667C28.938 14.6845 28.9122 14.2943 28.8045 13.923C28.6967 13.5518 28.5095 13.2084 28.2558 12.9167C28.0022 12.625 27.6881 12.3919 27.3354 12.2336C26.9828 12.0754 26.5999 11.9956 26.2133 12H18.6667Z" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</svg>
