import React from 'react';
import {IconProps} from './_iconProps';

export const Volume2: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M25.4267 6.57336C27.9263 9.07373 29.3305 12.4645 29.3305 16C29.3305 19.5356 27.9263 22.9263 25.4267 25.4267M20.72 11.28C21.9698 12.5302 22.6719 14.2256 22.6719 15.9934C22.6719 17.7611 21.9698 19.4565 20.72 20.7067M14.6667 6.6667L8.00001 12H2.66667V20H8.00001L14.6667 25.3334V6.6667Z" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</svg>
