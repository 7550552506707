import React from 'react';
import {IconProps} from './_iconProps';

export const Link2: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12 22.6667H8C7.12452 22.6667 6.25762 22.4942 5.44878 22.1592C4.63994 21.8242 3.90501 21.3331 3.28596 20.714C2.03571 19.4638 1.33334 17.7681 1.33334 16C1.33334 14.2319 2.03571 12.5362 3.28596 11.286C4.5362 10.0357 6.23189 9.33333 8 9.33333H12M10.6667 16H21.3333M20 9.33333H24C24.8755 9.33333 25.7424 9.50577 26.5512 9.8408C27.3601 10.1758 28.095 10.6669 28.714 11.286C29.3331 11.905 29.8242 12.6399 30.1592 13.4488C30.4942 14.2576 30.6667 15.1245 30.6667 16C30.6667 16.8755 30.4942 17.7424 30.1592 18.5512C29.8242 19.3601 29.3331 20.095 28.714 20.714C28.095 21.3331 27.3601 21.8242 26.5512 22.1592C25.7424 22.4942 24.8755 22.6667 24 22.6667H20V9.33333Z" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</svg>
