import React, {useEffect, useState, useRef, useMemo, ReactNode} from 'react';

import {
  scaleLinear,
  axisLeft,
  axisRight,
  select,
  range,
} from 'd3';

import styled from 'styled-components';
import { useOverlayContext } from 'context';
import { digestedAgent } from 'layout';

export type GroupsGraphDataGroup = {
  label: string;
  items: {
    color: string,
    amount: number, 
    index: number, // group index within group
    tooltip?: ReactNode,
    label?: string,
    type?: string
  }[]
}

export type GroupsGraphParams = {

  dataGroups: GroupsGraphDataGroup[];
  pathsCutoff?: Date|number;
  width: number;
  height: number;
  padding?: {
    top: number;
    right: number;
    bottom: number;
    left: number;
  },
  axisSpacing?: {
    top: number,
    right: number,
    bottom: number,
    left: number
  },
  numberOfBins?: number;
  startDate?: Date;
  endDate?: Date;
  labelToColorMap?: Map<string, string>;
  dateTicks?: 'hours'|'days'|'weeks'|'month'|number;
  numberOfVerticalTicks?: number;
  gridCellWidth?:number;
  gridCellHeight?:number;

  itemizationTimeSample?: 'day'|'hour'

  amountOverDateGraphRef?: React.MutableRefObject<SVGSVGElement>,

  label?: string;
  className?: string;
  smothing?: boolean;

  groupWidth?: number;
  itemWidth?: number;
  groupSize?: number;
}


// Version 0.2
export const GroupsGraph: React.FC<GroupsGraphParams> = ({
  dataGroups=[],
  width,
  height,
  padding={
    top: 30,
    right: 30,
    bottom: 50,
    left: 50
  },
  axisSpacing={
    top: 15,
    right: 15,
    bottom: 15,
    left: 15
  },
  groupWidth=65,
  itemWidth=8,
 
  numberOfVerticalTicks=8,
  gridCellHeight=0,
  groupSize=4,

  label,
  children,

  className
}) => {
  const svgRef = useRef<SVGSVGElement>(null);
  const amountMaxRef = useRef<number>(0);
  const [indexedGroups, setIndexedGroups] = useState<{
      size: number,
      value: typeof dataGroups[0]
    }[]>([]);
  const {attachTooltip, deattachTooltip} = useOverlayContext();
  
  const amountScaler = useMemo( () => scaleLinear()
      .domain(indexedGroups?.length ? [amountMaxRef.current, 0]: [1,0])
      .range([-(height-padding.top-padding.bottom), 0]),

  [height, padding, indexedGroups]);

  useEffect(function indexPassedGroups(){
    if(!dataGroups?.length){
      return;
    }
    const indexedGroups: {
      size: number,
      value: typeof dataGroups[0]
    }[] = [];
    // null out max amount ref
    amountMaxRef.current = 0;
    // index passed groups
    // capturing largest index as group size
    dataGroups?.forEach((gr,gi) => {
      gr.items?.forEach(it => {
        if(!indexedGroups[gi]){
          indexedGroups[gi] = {
            size: 0,
            value: gr
          };
        }
        if(indexedGroups[gi].size < it.index){
          indexedGroups[gi].size = it.index
        }
        if(amountMaxRef.current < it.amount){
          amountMaxRef.current = it.amount;
        }
      })
    });
    setIndexedGroups(indexedGroups);
  }, [
    dataGroups
  ]);

  useEffect(function drawGrid(){
    const svg = select(svgRef.current);
    if(!svg) {return;}

    console.log('[AmountOverDateGraph:addTmsiAxis] svg is', svg);

    // calculate number of ticks
    const gridHorizontalTicks = gridCellHeight ? Math.round((height-padding.top-padding.bottom)/gridCellHeight) : 0;

    // Axis & Grid
    // create axis generator
    
    let axisYLeft = axisLeft(amountScaler);
    if(numberOfVerticalTicks && amountMaxRef.current){
      axisYLeft = axisYLeft
        .tickValues( range(0, amountMaxRef.current, amountMaxRef.current/(numberOfVerticalTicks-1)) );
    }

    let axisYRight = axisRight(amountScaler);
    if(numberOfVerticalTicks && amountMaxRef.current){
      axisYRight = axisYRight
        .tickValues( range(0, amountMaxRef.current, amountMaxRef.current/(numberOfVerticalTicks-1)) );
    }

    const xAxisGrid = axisRight(amountScaler)
      .tickSize(width-padding.left-padding.right)
      .tickFormat(() => '')
      .ticks( gridHorizontalTicks );

    // create am axis elements group and attach a call function on it
    svg
      .selectAll('g.axisGroup')
      .data(['one'])
      .join(
        enter =>
          enter
            .append('g')
            .lower()
            .attr('class', 'axisGroup'),
        update => update,
        exit => {
          exit.remove();
        }
      )
      .attr('style', `transform:translateY(${height - padding.bottom}px)`)
      .selectAll('*')
      .remove();


    const axisGroup = svg
      .select('.axisGroup');
    
    if(gridHorizontalTicks){
      axisGroup.append('g')
        .call(xAxisGrid)
        .attr('transform', `translate(${padding.left}, ${padding.top})`)
        .attr('class', 'grid')
    }
    
    axisGroup.append('g')
      .call(axisYLeft)
      .attr('class', 'axis left')
      .attr('transform', `translate(${padding.left - axisSpacing.left}, ${padding.top})`);

    axisGroup.append('g')
      .call(axisYRight)
      .attr('class', 'axis right')
      .attr('transform', `translate(${width - padding.right + axisSpacing.right}, ${padding.top})`);
      
  }, [
    dataGroups,
    amountScaler,
    numberOfVerticalTicks,
    gridCellHeight,
    height,
    padding.bottom,
    padding.left,
    padding.right,
    padding.top,
    width,
    axisSpacing.left,
    axisSpacing.right
  ]);

  useEffect(function drawGroupsOntoSvg(){
    
    const svg = select(svgRef.current);
    const canvasWidth = width - padding.left - padding.right;
    const canvasHeight = height - padding.top - padding.bottom;
    const groupSpacing = (canvasWidth-dataGroups.length*groupWidth)/(dataGroups.length+1)
    const groupBarSpacing = (groupWidth-itemWidth*groupSize)/(groupSize-1);

    const group = svg
      .selectAll('svg.dataGroups')
      .data(['one'])
      .join(
        enter => enter.append('svg').attr('class','dataGroups'),
        update => update,
        exit => exit.remove()
      )
      .attr('x', padding.left)
      .attr('y', padding.top + canvasHeight)
      .attr('width', canvasWidth)
      .attr('height', canvasHeight)
      .attr('style', `overflow:visible;`)
      .selectAll('g')
      .data(indexedGroups)
      .join(
        enter => enter.append('g'),
        update => update,
        exit => exit.remove()
      )
      .attr('style', (_,i) => `transform:translateX(${groupSpacing + (groupSpacing + groupWidth)*i}px)`);
    
    group
      .selectAll('line')
      .data(gri => gri.value.items.sort( (ita, itb) => (ita.amount > itb.amount ? -1 : 1) ))
      .join(
        enter => enter
          .append('line')
          // adding tooltip if present
          .each((it,i,els) => {
            const {tooltip} = it;
            const el = els[i];
            if(tooltip){
              el.setAttribute('style', 'cursor:pointer')
              attachTooltip(el as any, tooltip, {
                offset: -10,
                variant: 'small',
                showEvent: digestedAgent.phone ? undefined : 'mouseover'
              })
            } else {
              (el as any)?.setAttribute('style', 'pointer-events:none')
            }
          }),
        update => update
          .each((it,i,els) => {
            const {tooltip} = it;
            const el = els[i];
            if(tooltip){
              (el as any)?.setAttribute('style', 'cursor:pointer')
              attachTooltip(el as any, tooltip, {
                offset: -10,
                variant: 'small',
                showEvent: digestedAgent.phone ? undefined : 'mouseover'
              })
            } else {
              (el as any)?.setAttribute('style', 'pointer-events:none')
              deattachTooltip(el as any)
            }
          }),
        exit => exit.remove()
      )
      .attr('stroke-width', itemWidth)
      .attr('stroke', it => it.color)
      .attr('x1', it => (it.index ? (itemWidth*it.index+it.index*groupBarSpacing) : 0)+(itemWidth/2))
      .attr('x2', it => (it.index ? (itemWidth*it.index+it.index*groupBarSpacing) : 0)+(itemWidth/2))
      .attr('y1', 0)
      .transition()
      .attr('y2', it => amountScaler(it.amount))
      
    group
      .selectAll('text')
      .data(gri => [gri.value.label])
      .join(
        enter => enter.append('text'),
        update => update,
        exit => exit.remove()
      )
      .attr('class','bottom-label')
      .attr('fill', 'currentColor')
      //.attr('y', '1rem')
      // add tspans elements
      .selectAll('tspan')
      .data(v => v.split(/\n/g))
      .join(
        enter => enter.append('tspan'),
        update => update,
        exit => exit.remove()
      )
      .attr('x', groupWidth/2)
      .attr('y', (_,i) => (1+i)+'em')
      .attr('text-anchor', 'middle')
      .html(v => v);

    // svg
    //   .selectAll('svg.dots')
    //   .data(['one'])
    //   .join(
    //     enter => enter.append('svg').attr('class','dots'),
    //     update => update,
    //     exit => exit.remove()
    //   )
    //   //.attr('transform', `translate(${padding.left},${padding.top})`)
    //   .attr('x', padding.left)
    //   .attr('y', padding.top)
    //   .attr('width', width-padding.left-padding.right)
    //   .attr('height', height-padding.top-padding.bottom)
    //   .attr('style', 'overflow:visible')

    //   .selectAll('g')
    //   .data([])
    //   .join(
    //     enter => enter.append('g'),
    //     update => update,
    //     exit => exit.remove()
    //   )
    //   .attr('fill', d => '' || 'black' )
    //   .selectAll('circle')
    //   .data(d => {
    //     return d
    //   })
    //   .join(
    //     enter => enter.append('circle'),
    //     update => update,
    //     exit => exit.remove()
    //   )
    //   .transition()
    //   .attr('cx', d => 3)
    //   .attr('cy', d => 3)
    //   .attr('r', 4)
    
  },[
    padding.left,
    padding.top,
    height,
    width,
    padding.bottom,
    padding.right,
    indexedGroups,
    amountScaler,
    dataGroups.length,
    groupSize,
    groupWidth,
    itemWidth,
    attachTooltip,
    deattachTooltip
  ]);

  return <StyledSvg className={"amountOverDateGraph" + className ? ' '+className:''} ref={svgRef} width={width} height="auto" viewBox={`0 0 ${width} ${height}`} preserveAspectRatio="xMidYMin meet" overflow="visible">
    <defs>
      <filter id="shadow">
        <feDropShadow dx="0" dy="0" stdDeviation="4" floodOpacity="0.5" />
      </filter>
    </defs>

    {label?
      <text
        className="label"
        y={0}
        x={0}
        textAnchor="left">{label?.split(/\n/g)?.map( (txt, i) => 
          <tspan key={i} x={0} y={(i+1)+"em"}>{txt}</tspan>
        )}</text>
    :null}

    {children}
  </StyledSvg>;
};

const StyledSvg = styled.svg`${({theme}) => `
  .axis path, 
  .axis line {
    display: none;
  }

  text {
    ${theme.fontStyles.graphs}
  }

  .grid line {
    stroke: ${theme.newColors.MidnightFoam};
  }

  .grid path {
    display: none;
  }

  /* removing first value on vertical scale */ 
  .axis.right > path + g , 
  .axis.left > path + g {
    display: none;
  }

  .timesampleElContainer {
    display: none;
  }

  text.bottom-label {
    transform: translateY(10px);
  }

  .label {
    font-weight: bold;
  }
`}`