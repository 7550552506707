import React from 'react';
import {IconProps} from './_iconProps';

export const CornerRightUp: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13.3333 12L20 5.33331M20 5.33331L26.6667 12M20 5.33331V21.3333C20 22.7478 19.4381 24.1044 18.4379 25.1045C17.4377 26.1047 16.0811 26.6666 14.6667 26.6666H5.33333" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</svg>
