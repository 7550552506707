export const snakeToCamelCasePropertyNames = <T extends Record<string|number, any>>(obj: Record<string|number, any>):Record<string|number, any> => {
  const newObj: {
    [key: string]: any
  } = {};
  for(const key in obj){
    if(Object.prototype.hasOwnProperty.call(obj, key)){
      const camelCaseKey = String(key).replace(/_([a-zA-Z])/g, (z, letter) => letter.toUpperCase());
      newObj[camelCaseKey] = obj[key];
    }
  }
  return newObj as T;
};