import {
  apiRequest
} from '../apiRequest';
export type RawCellListItem = {
  DATA_TYPE: string; //"cell-scan"
  NETWORKPROVIDER: string; //"T-Mobile USA"
  POWER_ESTIMATE: string; //"-87.73664093017578"
  UNIX_TIMESTAMP: string; //Sat Sep 02 2023 1
  ESTABLISHMENT_CAUSE?: string; //'mo-Data'|'mt-Access'
  TMSI?: string;
}
export type RawCellBucketItem = {
  DATETIME: string
  NETWORK_PROVIDER: string
  NUM_RECORDS: string
}
export type RawCellResponse<T> = {
  limit: number;
  nextOffset: number;
  items: Array<T>
}
export type RawCellBucketQueryPayload = {
  sensorId: number|string,
  startTimestamp: number,
  endTimestamp: number,
  binSize?: 'minute'|'hour'|'day'|'week'|'month'|'year',
  limit?: number,
  offset?: number,
  timezoneOffsetEpoch?: number
};
export type RawCellListQueryPayload = {
  sensorId: number|string,
  startTimestamp: number,
  endTimestamp: number,
  limit?: number,
  offset?: number
}
export const getRawCellItemsList = (params: RawCellListQueryPayload) => 
  apiRequest<RawCellResponse<RawCellListItem>>({
    service: 'snowflakeConnector',
    path: '/list-cell',
    params
  });
export const getRawCellScanItemsList = (params: RawCellListQueryPayload) => 
  apiRequest<RawCellResponse<RawCellListItem>>({
    service: 'snowflakeConnector',
    path: '/list-cell-scan',
    params
  });
export const getRawCellItemsBucket = (params: RawCellBucketQueryPayload) => 
  apiRequest<RawCellResponse<RawCellBucketItem>>({
    service: 'snowflakeConnector',
    path: '/bucket-cell',
    params: {
      timezoneOffsetEpoch: (new Date().getTimezoneOffset() * 60), // minutes to seconds
      ...params
    }
  });
export const getRawCellScanItemsBucket = (params: RawCellBucketQueryPayload) => 
  apiRequest<RawCellResponse<RawCellBucketItem>>({
    service: 'snowflakeConnector',
    path: '/bucket-cell-scan',
    params: {
      timezoneOffsetEpoch: (new Date().getTimezoneOffset() * 60), // minutes to seconds
      ...params
    }
  });