import { HTMLProps, useRef } from 'react';

import styled from 'styled-components';

import {Loading} from '../Loading';
import {
  ArrowRightCircle,
  ArrowLeftCircle
} from 'theme/icons';

export type ButtonProps = {
  variant?: 'primary' | 'secondary' | 'faint' | 'plain';
  sizeVariant?: 'normal' | 'small';
  loading?: boolean;
  arrowLeft?: boolean;
  arrowRight?: boolean;
  // iconLeft?: React.ReactNode;
  // iconRight?: React.ReactNode;
} & HTMLProps<HTMLButtonElement>;

// Button
export const Button: React.FC<ButtonProps> = ({
  arrowLeft,
  arrowRight,
  variant='secondary',
  sizeVariant='normal',
  loading=false,
  selected,
  children,
  onClick,
  ...args
}) => {
  const ref = useRef<HTMLButtonElement>(null);
  const iconLeft = arrowLeft ? <ArrowLeftCircle strokeWidth='3' /> : null;
  const iconRight = arrowRight ? <ArrowRightCircle strokeWidth='3' /> : null;

  return variant === 'primary'
    ?
    <PrimeButton sizeVariant={sizeVariant} className={(selected?'selected':'')}
      ref={ref}
      onClick={e => {ref.current?.blur(); onClick?.(e)}}
      {...args}>
      {iconLeft}{' '}<StyledContent $loading={loading}>{children}</StyledContent>{' '}{iconRight}
      {loading ? <StyledLoading strokesSettings={{numberOfStrokes: 10}} /> : null}
    </PrimeButton>
    :
    variant === 'faint'
    ?
    <FaintButton sizeVariant={sizeVariant} className={(selected?'selected':'')}
      ref={ref}
      onClick={e => {ref.current?.blur(); onClick?.(e)}}
      {...args}>
      {iconLeft}{' '}<StyledContent $loading={loading}>{children}</StyledContent>{' '}{iconRight}
      {loading ? <StyledLoading strokesSettings={{numberOfStrokes: 10}} /> : null}
    </FaintButton>
    :
    variant === 'plain'
    ?
    <PlainButton sizeVariant={sizeVariant} className={(selected?'selected':'')}
      ref={ref}
      onClick={e => {ref.current?.blur(); onClick?.(e)}}
      {...args}>
      {iconLeft}{' '}<StyledContent $loading={loading}>{children}</StyledContent>{' '}{iconRight}
      {loading ? <StyledLoading strokesSettings={{numberOfStrokes: 10}} /> : null}
    </PlainButton>
    :
    <SecondaryButton sizeVariant={sizeVariant} className={(selected?'selected':'')}
      ref={ref}
      onClick={e => {ref.current?.blur(); onClick?.(e)}}
      {...args}>
      {iconLeft}{' '}<StyledContent $loading={loading}>{children}</StyledContent>{' '}{iconRight}
      {loading ? <StyledLoading strokesSettings={{numberOfStrokes: 10}} /> : null}
    </SecondaryButton>;
};

const StyledLoading = styled(Loading).attrs(({theme}) => ({
  size: theme.fontPadding.xl6/1.5
}))`${() => `
  margin: auto !important;

  position: absolute;
  top:0;
  right:0;
  bottom:0;
  left:0;
  height: 10px;
  width: 50px;
`}`;

const StyledContent = styled('span').attrs({} as {
  $loading: boolean,
  someVar: string
})`${({theme, $loading:loading}) => `
  
  > * {
    vertical-align: middle;
  }
  
  ${loading ? `
    opacity: 0;
  ` : `
    opacity: 1;
  `}

`}`;

type StyledButtonType = ButtonProps & {
  sizeVariant: string
}

const PrimeButton = styled('button').attrs({} as {
  sizeVariant: string
})`${({theme, sizeVariant}) => `
  position: relative;
  vertical-align: top;
  white-space: nowrap;
  align-items: center;
  text-align: center;
  justify-content: space-around;
  
  ${sizeVariant === 'small' ? theme.fontStyles.buttonSmall : theme.fontStyles.button};
  text-align: center;

  padding: ${theme.fontPadding.s}px ${theme.fontPadding.xl}px;
  border-radius: ${theme.fontPadding.xxxs}px;
  
  border: none;
  cursor: pointer;

  background-color: ${theme.newColors.OrangeDream};
  color: ${theme.newColors.SwissCream};

  &:hover,
  &.selected{
    background-color: ${theme.newColors.GreenMachine};
  }
  &:focus {
    color: ${theme.newColors.SunsetCrush};
    background-color: ${theme.newColors.OrangeDream};
    outline: 2px solid ${theme.newColors.SunsetCrush};
  }
  &:active {
    color: ${theme.newColors.SwissCream};
    background-color: ${theme.newColors.GreenJuice};
  }
  &[disabled],
  &:disabled {
    pointer-events: none;
    background-color: ${theme.newColors.MidnightShores};
    color: ${theme.newColors.MidnightFoam};
    outline: none;
  }

  > svg {
    vertical-align: middle;
  }

`}` as React.FC<StyledButtonType>;

const SecondaryButton = styled.button.attrs({} as {
  sizeVariant: 'normal'| 'small'
})`${({theme, sizeVariant}) => `
  position: relative;
  white-space: nowrap;
  align-items: center;
  text-align: center;
  justify-content: space-around;
  
  ${sizeVariant === 'small' ? theme.fontStyles.buttonSmall : theme.fontStyles.button};
  text-align: center;

  padding: ${theme.fontPadding.s-2}px ${theme.fontPadding.xl-2}px;
  border-radius: ${theme.fontPadding.xxxs}px;
  
  box-sizing: border-box;
  cursor: pointer;

  outline-offset: -1px;

  border: 2px solid ${theme.newColors.OrangeDream};
  background: none;
  color: ${theme.newColors.OrangeDream};
  box-sizing: border-box;

  &:hover,
  &.selected {
    color: ${theme.newColors.GreenMachine};
    border-color: ${theme.newColors.GreenMachine};
  }
  &:focus {
    color: ${theme.newColors.SunsetCrush};
    /*background-color: ${theme.newColors.OrangeCream};*/
    border-color: ${theme.newColors.SunsetCrush};
  }
  &:active {
    color: ${theme.newColors.GreenJuice};
    border-color: ${theme.newColors.GreenJuice};
    /*background-color: ${theme.newColors.WhippedCream};*/
    outline: none;
  }
  &[disabled],
  &:disabled {
    pointer-events: none;
    color: ${theme.newColors.MidnightShores};
    border-color: ${theme.newColors.MidnightShores};
    /*background-color: ${theme.newColors.WhippedCream};*/
    outline: none;
  }

  > svg {
    vertical-align: middle;
  }

`}` as React.FC<StyledButtonType>;

const FaintButton = styled.button.attrs({} as {
  sizeVariant: 'normal'| 'small',
})`${({theme, sizeVariant}) => `
  position: relative;
  white-space: nowrap;
  align-items: center;
  text-align: center;
  justify-content: space-around;

  background-color: ${theme.colors.primary.lightest};
  
  color: ${theme.colors.primary.base};
  ${sizeVariant === 'small' ? theme.fontStyles.buttonSmall : theme.fontStyles.button};
  text-align: center;

  padding: ${theme.fontPadding.s}px ${theme.fontPadding.xl}px;
  border-radius: ${theme.fontPadding.xxxs}px;
  
  border: none;
  cursor: pointer;

  &:hover,
  &.selected {
    opacity: 0.80
  }
  &[disabled] {
    pointer-events: none;
    background-color: ${theme.colors.sky.light};
    color: ${theme.colors.sky.dark};
  }
  
  > svg {
    vertical-align: middle;
  }

`}` as React.FC<StyledButtonType>;

const PlainButton = styled.button.attrs({
} as {
  sizeVariant: 'normal'| 'small'
})`${({theme, sizeVariant}) => `
  position: relative;
  white-space: nowrap;
  align-items: center;
  text-align: center;
  justify-content: space-around;
  
  text-align: center;
  background: none;
  
  color: ${theme.newColors.OrangeDream};
  ${sizeVariant === 'small' ? theme.fontStyles.buttonSmall : theme.fontStyles.button};
  text-align: center;

  padding: ${theme.fontPadding.s}px ${theme.fontPadding.xl}px;
  border-radius: ${theme.fontPadding.xxxs}px;
  
  border: none;
  cursor: pointer;

  &:hover,
  &.selected {
    color: ${theme.newColors.GreenMachine};
    background-color: ${theme.newColors.MidnightFoam};
  }
  &[disabled] {
    pointer-events: none;
    color: ${theme.colors.sky.dark};
  }

  > svg {
    vertical-align: middle;
  }
`}` as React.FC<StyledButtonType>;