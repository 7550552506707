import React from 'react';
import {IconProps} from './_iconProps';

export const DownloadCloud: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10.6667 22.6667L16 28M16 28L21.3334 22.6667M16 28V16M27.84 24.12C28.9992 23.3049 29.8685 22.1415 30.3217 20.7989C30.775 19.4562 30.7886 18.004 30.3605 16.6531C29.9325 15.3022 29.0852 14.1228 27.9414 13.2861C26.7977 12.4494 25.4171 11.9989 24 12H22.32C21.919 10.4372 21.1687 8.98567 20.1255 7.75478C19.0824 6.52389 17.7735 5.54568 16.2976 4.89381C14.8217 4.24193 13.2171 3.93337 11.6047 3.99136C9.9923 4.04934 8.41403 4.47236 6.98876 5.22857C5.56348 5.98478 4.32832 7.05447 3.37627 8.35711C2.42422 9.65975 1.78009 11.1614 1.49237 12.749C1.20464 14.3366 1.28083 15.9688 1.71519 17.5227C2.14955 19.0766 2.93076 20.5117 4.00002 21.72" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</svg>
