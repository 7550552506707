import React, {useEffect, useRef, useMemo, useState} from 'react';
import styled from 'styled-components';
import {
  WeeklyActivityBucketsGraph,
  TMSITracking
} from 'elements';
import {
  FlexContainer,
  Flex8,
  Flex4,
} from 'layout';
import { OrangeBox } from 'components';
import {
  startOfDay,
  endOfDay
} from 'date-fns';
import {
  subDays,
  format
} from 'date-fns';
import {
  useRawCellBucket
} from 'hooks';
import { RawCellBucketItem } from '@ubiety/fe-api';
import {
  LabelsMap
} from 'components';
import { useSensorsStore } from 'context';

export const Trends: React.FC = () => {

  return <Container>
    <h1>Property Trends</h1>
    <h3>All your cellular traffic and events</h3>

    <FlexContainer className='section'>
      <Flex8>
        <OrangeBox>
          <LabelsMap />

          <h2>Weekly Cellular Carrier Volume</h2>
          <h3>Cellular traffic totals by week</h3>

          <WeeklyActivityBucketsGraph />
        </OrangeBox>
      </Flex8>
      <Flex4>
        <SevenDaysTrends />
      </Flex4>
    </FlexContainer>

    <FlexContainer className='section'>
      <Flex8>
        <OrangeBox>
          <LabelsMap />

          <h2>TMSI Tracking</h2>
          <h3>Temporary Mobile Subscriber Identity numbers over time.</h3>

          <TMSITracking
            startTimestamp={startOfDay(Date.now()-7*60*60*1000).getTime()}
            endTimestamp={endOfDay(Date.now()-7*60*60*1000).getTime()}
            variant='scroll'
            axisLabel='Today'
            />
        </OrangeBox>

      </Flex8>
      <Flex4>
        
      </Flex4>
    </FlexContainer>
  </Container>
}



const SevenDaysTrends = () => {

  const {sensorArray} = useSensorsStore(s => s);
  const sensor = useMemo(() => sensorArray[0], [sensorArray]);
  const endTimestamp = useRef(endOfDay(subDays(Date.now(),1)).getTime()).current;
  const startTimestamp = useRef(startOfDay(subDays(endTimestamp,7)).getTime()).current;

  const [mostActiveItem, setMostActiveItem] = useState<RawCellBucketItem>();

  // useRawCellBucket to get cell scan data
  const {
    rawItems 
  } = useRawCellBucket({
    source: 'cell-scan',
    sensorId: sensor?.uid || '',
    startTimestamp,
    endTimestamp,
    binSize: 'day'
  });

  useEffect(function processDataIntoValues(){
    if(!rawItems||!rawItems.length){ return; }
    let mostActivityItem: RawCellBucketItem = rawItems[0],
        currentHighestActivity = 0;

    rawItems?.forEach(it => {
      const numRecords = Number(it.NUM_RECORDS);
      if(currentHighestActivity < numRecords){
        currentHighestActivity = numRecords;
        mostActivityItem = it;
      }
    });
    setMostActiveItem(mostActivityItem)

    console.log('[Seven days items] got data, determined highest:', {rawItems, mostActivityItem});
  },[
    rawItems
  ])

  return <TrendsContainer>
    <h1>Trends from the past 7 days</h1>
    <h3>Trends from {format(startTimestamp, 'M/d')} through {format(endTimestamp, 'M/d')}</h3>

    <div className="trend-row">
      <div>
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M20.665 14.7977C21.2788 14.1672 21.7657 13.4184 22.0979 12.5942C22.4301 11.77 22.6011 10.8865 22.6011 9.99433C22.6011 9.10211 22.4301 8.21865 22.0979 7.39445C21.7657 6.57025 21.2788 5.82147 20.665 5.19093M11.335 5.20225C10.7212 5.83279 10.2343 6.58156 9.9021 7.40577C9.56989 8.22997 9.3989 9.11343 9.3989 10.0056C9.3989 10.8979 9.56989 11.7813 9.9021 12.6055C10.2343 13.4297 10.7212 14.1785 11.335 14.809M23.7787 18C25.8413 15.878 27 13.0004 27 9.99999C27 6.99955 25.8413 4.12194 23.7787 1.99999M8.22134 1.99999C6.15872 4.12194 5 6.99955 5 9.99999C5 13.0004 6.15872 15.878 8.22134 18M20.8893 24L19 18L11 24M20.8893 24L22.7787 30L11 24M20.8893 24L13 18L11 24M20.8893 24L9 30L11 24M18.2005 9.99999C18.2005 8.75012 17.2153 7.7369 16 7.7369C14.7847 7.7369 13.7995 8.75012 13.7995 9.99999C13.7995 11.2499 14.7847 12.2631 16 12.2631C17.2153 12.2631 18.2005 11.2499 18.2005 9.99999Z" stroke="#588157" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>
      <div>
        <h4>Carrier with most activity</h4>
        <p className={!mostActiveItem ? 'emptyShimmer' :''}>{mostActiveItem?.NETWORK_PROVIDER}</p>
        {/*<p className="subtext">Verizon is typically been the most active carrier for your property./p>*/}
      </div>
    </div>

    <div className="trend-row">
      <div>
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M21.3333 2.66675V8.00008M10.6667 2.66675V8.00008M4 13.3334H28M6.66667 5.00008H25.3333C26.8061 5.00008 28 6.19399 28 7.66675V26.3334C28 27.8062 26.8061 29.0001 25.3333 29.0001H6.66667C5.19391 29.0001 4 27.8062 4 26.3334V7.66675C4 6.19399 5.19391 5.00008 6.66667 5.00008Z" stroke="#588157" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <circle cx="21" cy="20" r="2" fill="#588157"/>
        </svg>
      </div>
      <div>
        <h4>Day with the most activity</h4>
        <p className={!mostActiveItem ? 'emptyShimmer' :''}>{mostActiveItem ? format(new Date(mostActiveItem?.DATETIME), 'EEEE') : ''}</p>
        {/*<p className="subtext"></p>*/}
      </div>
    </div>

    {/*<div>
      <div>
        <CellTower />
      </div>
      <div>
        <h2>Hour with the most activity</h2>
        <p>4:00 PM</p>
        <p className="subtext"></p>
      </div>
    </div>*/}
  </TrendsContainer>
}

const TrendsContainer = styled.div`${({theme}) => `
  padding: 10px;
  padding-left: 50px;


  .trend-row {
    display: flex;
    flex-direction: row;
    margin-bottom: 25px;
  }

  .trend-row > div:first-child {
    padding: 0 10px;
  }

  h4 {
    margin: 0;
    padding: 0;
    ${theme.fontStyles.label}
  }

  @media (max-width: 1150px){
    margin-top: 50px;
  }

`}`;
const Container = styled.div`${({theme}) => `
  > h3 {
    margin-bottom: ${theme.boxMargins.xl4}px;
  }

  h3 {
    margin-bottom: ${theme.boxMargins.xl3}px;
  }

  .orange-box {
    max-width: none !important;
  }

  .section {
    margin-bottom: ${theme.boxMargins.xl5}px;
  }

  .labels-map {
    float: right;
  }
`}`;