import React from 'react';
import {IconProps} from './_iconProps';

export const Umbrella: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M24 25.3334C24 26.3942 23.5786 27.4116 22.8284 28.1618C22.0783 28.9119 21.0609 29.3334 20 29.3334C18.9391 29.3334 17.9217 28.9119 17.1716 28.1618C16.4214 27.4116 16 26.3942 16 25.3334V16M30.6667 16C30.3184 12.3515 28.6227 8.96369 25.9107 6.49824C23.1987 4.03279 19.6651 2.66669 16 2.66669C12.3348 2.66669 8.80128 4.03279 6.08928 6.49824C3.37729 8.96369 1.6816 12.3515 1.33333 16H30.6667Z" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</svg>
