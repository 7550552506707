export class Vector {

  constructor(x, y, z, w = 1){
    const vector = 
      [  x, 
         y, 
         z, 
         w
      ];

    vector.normalize = this.normalize;
    vector.getLength = this.getLength;
    vector.multiplyByScalar = this.multiplyByScalar;
    vector.distanceTo = this.distanceTo;

    return vector;
  }

  normalize() {
    const length = this.getLength();
    
    this[0] /= length;
    this[1] /= length;
    this[2] /= length;
    
    return this;
  }

  getLength() {
    return Math.sqrt(
        this[0] * this[0] + this[1] * this[1] + this[2] * this[2]
    );
  }

  multiplyByScalar(s) {
    this[0] *= s;
    this[1] *= s;
    this[2] *= s;
    
    return this;
  }

  distanceTo(v) {
    // d = ((x2 - x1)^2 + (y2 - y1)^2 + (z2 - z1)^2)^1/2
    return Math.sqrt(
        Math.pow(v[0]-this[0],2) +
        Math.pow(v[1]-this[1],2) +
        + Math.pow(v[2]-this[2],2)
        );   
  }

  static add(v1, v2){
    return new Vector(
      v1[0]+v2[0],
      v1[1]+v2[1],
      v1[2]+v2[2],
      v1[3]+v2[3]
    );
  }

  static substract(v1, v2){
    return new Vector(
      v1[0]-v2[0],
      v1[1]-v2[1],
      v1[2]-v2[2],
      v1[3]-v2[3]
    );
  }

  static crossProduct(v1, v2) {
    return new Vector(
      v1[1] * v2[2] - v1[2] * v2[1],
      v1[2] * v2[0] - v1[0] * v2[2],
      v1[0] * v2[1] - v1[1] * v2[0],
      // v1.y * v2.z - v1.z * v2.y,
      // v1.z * v2.x - v1.x * v2.z,
      // v1.x * v2.y - v1.y * v2.x,
    );
  }

}