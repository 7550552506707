import {
  useState
} from 'react';

import {
  FiLogOut
} from 'react-icons/fi';
import {
  NavLink
} from 'react-router-dom';
import styled, {useTheme} from 'styled-components';

import {
  Avatar
,
  Logo,
  Popover
} from 'components';
import {
  WidthContainer
} from 'layout/Containers';
import { useFirebaseStore } from 'store';

export const Navbar: React.FC<{
  logo?: React.ReactNode,
  mode?: 'logo'|'header'
}> = ({
  mode = 'header',
  logo = null
}) => {
  const [userPopoverActive, setUserPopoverActive] = useState<boolean>(false);
  const { user: fbUser } = useFirebaseStore(s => s);
  const theme = useTheme() as any;

  return (
    <Container mode={mode}>
      <WidthContainer>
        <NavLink className={({isActive}) => (String(isActive ? 'active logo' : 'logo'))} to="/" >
          {logo || <Logo />} 
        </NavLink>

        <div className={"menu center-menu flex"}>
          
          <NavLink className={({isActive}) => (isActive ? 'active' : '')} to="/">Home</NavLink>
          <NavLink className={({isActive}) => (isActive ? 'active' : '')} to="forensic-reports">Forensic Reports</NavLink>
          <NavLink className={({isActive}) => (isActive ? 'active' : '')} to="activity">Activity</NavLink>
          <NavLink className={({isActive}) => (isActive ? 'active' : '')} to="trends">Trends</NavLink>

          {/*<NavLink className={({isActive}) => (isActive ? 'active' : '')} to="tmsi-report">_Tmsi Report</NavLink>
          <NavLink className={({isActive}) => (isActive ? 'active' : '')} to="activity-report">_Activity Report</NavLink>
          <NavLink className={({isActive}) => (isActive ? 'active' : '')} to="tmsi-dashboard">_Tmsi Dashboard</NavLink>*/}

        </div>

        <div className="userAccount right-menu flex">

          <Avatar
            username={fbUser?.displayName}
            photoURL={fbUser?.photoURL}
            size={theme.fontSize.xl4}
            variant="iconplaceholder"
            onClick={() => setUserPopoverActive((prev) => !prev)}
            />

          <Popover
            align="right"
            active={userPopoverActive}>
            <RowList>
              <span>
                <b>Account</b><br />
                {fbUser?.email}
              </span>

              <a href="#signout">Sign Out <FiLogOut /></a>
            </RowList>
          </Popover>
        </div>
      </WidthContainer>

    </Container>
  );
};

export default Navbar;

const Container = styled.div.attrs({} as {
  mode: 'header'|'logo'
})`${({theme, mode}) => `

  display: flex;
  padding: 0;
  margin-top: ${theme.boxMargins.base}px;

  justify-content: space-between;
  align-items: stretch;

  > div > * {
    transition: 
      max-width 1s ease-in-out,
      flex-grow 1s,
      opacity 1s; 

    max-width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    opacity: 1;
  }

  .menu a {
    ${theme.fontStyles.button}
    color: ${theme.newColors.WhippedCream};
    position: relative;
    margin-bottom: 9px;
    padding: ${theme.fontPadding.l}px ${theme.fontPadding.xl2}px;
  }
  .menu a:hover:not(.active):after {
    opacity: 1;
  }
  .menu a:not(.active):after {
    background-color: ${theme.newColors.GreenJuice};
    top: 0;
    opacity: 0;
  }
  .menu a.active:after {
    top: 100%;
    background-color: ${theme.newColors.OrangeDream};
  } 
  .menu a:after {
    z-index: -1;
    content: '';
    position: absolute;
    left: 0;
    height: 9px;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    transition: 
      background-color 0.1s,
      opacity 0.4s,
      top 0.4s;
  }
  .menu {
    overflow: hidden;
    flex-grow: 1000;
    justify-content: center;
  }
  .logo {
    flex-grow: 1;
    justify-content: center;
    margin-top: -9px;
  }

  .userAccount {
    display: flex;
    justify-content: flex-end;
    user-select: none;
  }
  .userAccount .popoverMarker {
    align-self: flex-end;
    margin-bottom: -5px;
  }
  .userAccount > svg {
    cursor: pointer;
  }

  /*position: sticky;*/
  top: 0;
  z-index: 1000;

  ${mode === 'logo' ? `
    > div > *:not(.logo) {
      max-width: 0px;
      overflow: hidden;
      opacity: 0;
    }

    .menu a:after {
      opacity: 0;
    }
  ` : ''}

`}`;

const RowList = styled.div`${({theme}) => `

  background-color: ${theme.colors.sky.white};

  > * {
    display: block;
    white-space: nowrap;
    padding: ${theme.boxPadding.l}px ${theme.boxPadding.xl}px;
  }

  > *:not(:first-child) {
    border-top: 1px solid ${theme.colors.sky.light}
  }

  a {
    font-size: ${theme.fontSize.base}px !important;
    font-family: ${theme.defaultFontFamily};
    line-height: ${theme.fontSize.xl}px;
    vertical-align: middle;
    text-align: left !important;
  }

  a > svg {
    font-size: ${theme.fontSize.base}px !important;
  }

`}`;
