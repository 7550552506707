import React from 'react';
import {IconProps} from './_iconProps';

export const Lock: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.33333 14.6667V9.33333C9.33333 7.56522 10.0357 5.86953 11.286 4.61929C12.5362 3.36904 14.2319 2.66667 16 2.66667C17.7681 2.66667 19.4638 3.36904 20.714 4.61929C21.9643 5.86953 22.6667 7.56522 22.6667 9.33333V14.6667M6.66667 14.6667H25.3333C26.8061 14.6667 28 15.8606 28 17.3333V26.6667C28 28.1394 26.8061 29.3333 25.3333 29.3333H6.66667C5.19391 29.3333 4 28.1394 4 26.6667V17.3333C4 15.8606 5.19391 14.6667 6.66667 14.6667Z" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</svg>
