import React, { useState, useMemo, useRef, useEffect } from 'react';
import {
  GroupsGraph,
  GroupsGraphDataGroup,
  Loading
} from 'components';
import {
  newColors
} from 'theme';
import {
  useRawCellBucket
} from 'hooks';

import {
  providerToLabelName,
  providersLabelNames,
  providerToTitleName
} from 'helpers';
import { RawCellBucketItem } from '@ubiety/fe-api';
import {
  format,
  startOfWeek,
  endOfWeek,
  subWeeks
} from 'date-fns';
import styled from 'styled-components';
import { useSensorsStore } from 'context';

const emptyState = [{
  label: '',
  items: [{
    color: 'none',
    amount: 10,
    index: 0
  }]
}];

export const WeeklyActivityBucketsGraph: React.FC = () => {
  const [data, setData] = useState<Array<GroupsGraphDataGroup>>([]);
  
  const {sensorArray} = useSensorsStore(s => s);
  const sensor = useMemo(() => sensorArray[0], [sensorArray]);
  const endTimestamp = useRef(endOfWeek(Date.now()).getTime()).current;
  const startTimestamp = useRef(startOfWeek(subWeeks(Date.now(),4)).getTime()).current;

  // useRawCellBucket to get cell data
  const {
    loading: cellLoading,
    rawItems: cellRawItems
  } = useRawCellBucket({
    source: 'cell',
    sensorId: sensor?.uid || '',
    startTimestamp,
    endTimestamp,
    binSize: 'week'
  });
  // useRawCellBucket to get cell scan data
  const {
    loading: cellScanLoading,
    rawItems: cellScanRawItems
  } = useRawCellBucket({
    source: 'cell-scan',
    sensorId: sensor?.uid || '',
    startTimestamp,
    endTimestamp,
    binSize: 'week'
  });

  const loading = useMemo(() => {
    return cellLoading && cellScanLoading;
  },[
    cellLoading,
    cellScanLoading
  ]);

  useEffect(function digestItemsToData(){
    const timestampIndexed: {
      [key: string]: GroupsGraphDataGroup
    } = {};
    const providersIndexMap: {
      [key: string]: number;
    } = {/*att: 1, tmobile: 2 etc.*/};
    providersLabelNames?.forEach((l,i)=>{
      providersIndexMap[l] = i;
    });

    function eachRawItem(item: RawCellBucketItem, type:'cell'|'cell-scan'){
      const bucketTimestamp = new Date(item.DATETIME).getTime();
      const provider = providerToLabelName(item.NETWORK_PROVIDER);
      if(!timestampIndexed[bucketTimestamp]){
        timestampIndexed[bucketTimestamp] = {
          label: 'Week ' + format(bucketTimestamp, 'II') +'\n'+ format(bucketTimestamp, 'M/d')+' - '+format(endOfWeek(bucketTimestamp), 'M/d'),
          items: []
        }
      }
      const group = timestampIndexed[bucketTimestamp];

      group.items.push({
        // tmsi's are expected to be of smaller amounts, therefore we use more white~ish color on them
        color: type === 'cell' ? (newColors as any)[provider+'-secondary'] : newColors[provider],
        amount: Number(item.NUM_RECORDS),
        index: Object.prototype.hasOwnProperty.call(providersIndexMap, provider) ? providersIndexMap[provider] : -1,
        label: provider,
        type
      });
    }
    console.log('[RecentActivityBucketsGraph] processing', {cellRawItems, cellScanRawItems});
    cellRawItems?.forEach(it => eachRawItem(it, 'cell'));
    cellScanRawItems?.forEach(it => eachRawItem(it, 'cell-scan'));

    console.log('[RecentActivityBucketsGraph] digestedData:', timestampIndexed);
    setData(Object.values(timestampIndexed))
  },[
    cellScanRawItems,
    cellRawItems
  ]);

  // postprocess data to add tooltips
  const processedData: Array<GroupsGraphDataGroup> = useMemo(function addTooltipToGreatesByIndex(){
    const perIndexCollector: {
      [key: string]: {
        index: string|number;
        label: string;
        amountTypePerColor: {
          [key: string]: {
            amount: number,
            type: string
          }
        };
        greatestObject: any
      }
    } = {};
    data?.forEach((gr, gi) => {
      gr.items?.forEach(it => {
        const id = gi+'-'+it.index;
        if(!perIndexCollector[id]){
          perIndexCollector[id] = {
            index: it.index,
            label: providerToTitleName(it.label||''),
            amountTypePerColor: {},
            greatestObject: null
          }
        }
        perIndexCollector[id].amountTypePerColor[it.color] = {
          amount: it.amount,
          type: it.type||''
        }
        if(!perIndexCollector[id].greatestObject || perIndexCollector[id].greatestObject.amount < it.amount ){
          perIndexCollector[id].greatestObject = it;
        }
      })
    });// now lets add tooltips
    Object.values(perIndexCollector)?.forEach(ind => {
      ind.greatestObject.tooltip = <Tooltip>
        <b>{ind.label}</b>
        {Object.entries(ind.amountTypePerColor).sort((entA, entB) => entA[1].amount < entB[1].amount ? 1 : -1)?.map(([color, vals]) => 
          <div key={color}><span style={{
            backgroundColor: color
          }}/>{String(vals.amount).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} {vals.type === 'cell' ? 'with TMSI' : 'Scans'}</div>
        )}
      </Tooltip>
    });
    
    return [...data];
  },[
    data
  ]);

  return <StyledGroupsGraph
    dataGroups={loading ? emptyState : processedData}
    width={861}
    height={300}
    label={"Number of \nScans"}
    padding={{
      top: 5,
      right: 45,
      left: 45,
      bottom: 40
    }}
    axisSpacing={{
      top: 0,
      right: 0,
      left: 0,
      bottom: 6
    }}

  >
    { (loading) ?
      <Loading x="370" y="92" /> : null
    }
  </StyledGroupsGraph>
}

const StyledGroupsGraph = styled(GroupsGraph)`
  tspan:first-child {
    font-weight: 600
  }
`;

const Tooltip = styled.div`${({theme}) => `
  span {
    display: inline-block;
    height: 12px;
    width: 6px;
    margin-right: 5px;
  }

  ${theme.fontStyles.captionPlaceholder}
`}`;

// const sampleData = [{
//   label: 'Friday 8/4',
//   items: [
//     {
//       color: newColors.att,
//       amount: 170,
//       index: 0
//     },
//     {
//       color: newColors['att-secondary'],
//       amount: 70,
//       index: 0
//     },
//     {
//       color: newColors.verizon,
//       amount: 240,
//       index: 1
//     },
//     {
//       color: newColors['verizon-secondary'],
//       amount: 100,
//       index: 1
//     },
//     {
//       color: newColors.tmobile,
//       amount: 120,
//       index: 2
//     },
//     {
//       color: newColors['tmobile-secondary'],
//       amount: 60,
//       index: 2
//     },
//     {
//       color: newColors.other,
//       amount: 100,
//       index: 3
//     },
//     {
//       color: newColors['other-secondary'],
//       amount: 30,
//       index: 3
//     }
//   ]
// },
// {
//   label: 'Saturday 8/5',
//   items: [
//     {
//       color: newColors.att,
//       amount: 150,
//       index: 0
//     },
//     {
//       color: newColors['att-secondary'],
//       amount: 70,
//       index: 0
//     },
//     {
//       color: newColors.verizon,
//       amount: 240,
//       index: 1
//     },
//     {
//       color: newColors['verizon-secondary'],
//       amount: 100,
//       index: 1
//     },
//     {
//       color: newColors.tmobile,
//       amount: 120,
//       index: 2
//     },
//     {
//       color: newColors['tmobile-secondary'],
//       amount: 60,
//       index: 2
//     },
//     {
//       color: newColors.other,
//       amount: 100,
//       index: 3
//     },
//     {
//       color: newColors['other-secondary'],
//       amount: 30,
//       index: 3
//     }
//   ]
// },
// {
//   label: 'Sunday 8/5',
//   items: [
//     {
//       color: newColors.att,
//       amount: 150,
//       index: 0
//     },
//     {
//       color: newColors['att-secondary'],
//       amount: 70,
//       index: 0
//     },
//     {
//       color: newColors.verizon,
//       amount: 240,
//       index: 1
//     },
//     {
//       color: newColors['verizon-secondary'],
//       amount: 100,
//       index: 1
//     },
//     {
//       color: newColors.tmobile,
//       amount: 120,
//       index: 2
//     },
//     {
//       color: newColors['tmobile-secondary'],
//       amount: 60,
//       index: 2
//     },
//     {
//       color: newColors.other,
//       amount: 100,
//       index: 3
//     },
//     {
//       color: newColors['other-secondary'],
//       amount: 30,
//       index: 3
//     }
//   ]
// },
// {
//   label: 'Moday 8/7',
//   items: [
//     {
//       color: newColors.att,
//       amount: 150,
//       index: 0
//     },
//     {
//       color: newColors['att-secondary'],
//       amount: 70,
//       index: 0
//     },
//     {
//       color: newColors.verizon,
//       amount: 240,
//       index: 1
//     },
//     {
//       color: newColors['verizon-secondary'],
//       amount: 100,
//       index: 1
//     },
//     {
//       color: newColors.tmobile,
//       amount: 120,
//       index: 2
//     },
//     {
//       color: newColors['tmobile-secondary'],
//       amount: 60,
//       index: 2
//     },
//     {
//       color: newColors.other,
//       amount: 100,
//       index: 3
//     },
//     {
//       color: newColors['other-secondary'],
//       amount: 30,
//       index: 3
//     }
//   ]
// },
// {
//   label: 'Yesterday 8/8',
//   items: [
//     {
//       color: newColors.att,
//       amount: 150,
//       index: 0
//     },
//     {
//       color: newColors['att-secondary'],
//       amount: 70,
//       index: 0
//     },
//     {
//       color: newColors.verizon,
//       amount: 240,
//       index: 1
//     },
//     {
//       color: newColors['verizon-secondary'],
//       amount: 100,
//       index: 1
//     },
//     {
//       color: newColors.tmobile,
//       amount: 120,
//       index: 2
//     },
//     {
//       color: newColors['tmobile-secondary'],
//       amount: 60,
//       index: 2
//     },
//     {
//       color: newColors.other,
//       amount: 100,
//       index: 3
//     },
//     {
//       color: newColors['other-secondary'],
//       amount: 30,
//       index: 3
//     }
//   ]
// }
// ];