export type FlaskResponse<T> = {
  num_results: number,
  objects: Array<T>,
  page: number,
  total_pages: number
}

// Pass an object of {name: value},
// where value is string, number, null, array
// -or- value is an object specifying flask params
// to get a fomatted filer object
export const getFlaskFilter: ( filters: {
  [key: string]: string|number|null|Array<string>|{
    name: string;
    val: any;
    // flask restless api, ref here
    // https://flask-restless.readthedocs.io/en/stable/searchformat.html#searchformat
    // narrowing to one of each
    op: '==' | //'eq' | 'equals' | 'equals_to' |
        '!=' | //'neq' | 'does_not_equal' | 'not_equal_to' |
        '>' | //'gt' | '<' | 'lt' |
        '>=' | //'ge' | 'gte' | 'geq' |
        '<=' | // 'le' | 'lte' | 'leq' |
        'in' |
        'not_in' |
        'is_null' |
        'is_not_null' |
        'like' |
        'has' |
        'any'
  }
}, options?: any) => string|'' =

(filters, options={}) => JSON.stringify({filters:
  Object.entries(filters).map(([name, val]) => {
    // pay attention: array is an object, lets catch it first
    return Array.isArray(val)
    ?
    {
      name,
      op: 'in',
      val
    }
    :
    (val && typeof val === 'object' && Object.prototype.hasOwnProperty.call(val, 'op'))
    ?
    {
      name,
      op: val.op,
      val: val.val
    }
    :
    {
      name,
      op: 'eq',
      val
    };
  }),
...options});
