import { Queue } from './Queue';

export type FnQueueWithRetriesItem = { fn: (...args: any) => Promise<any>, args: Array<any>, retries?: number, onRequest?: () => void, onResolve?: (response: any) => void };

export class FnQueueWithRetries {
  queue = new Queue<FnQueueWithRetriesItem>();
  maxRetries = 5;
  rateLimit = 10;
  limitInterval = 1005;

  constructor(payload?: Partial<FnQueueWithRetries>) {
    if (payload) {
      Object.entries(payload).forEach(([key, value]) => {
        (this as any)[key] = value;
      });
    }
  }

  async resolveRequestWithRetries(item: FnQueueWithRetriesItem) {
    if (item.onRequest) {
      item.onRequest();
    }
    console.log('[processQueue] API Request');

    try {
      const response = await item.fn(...item.args);
      if (item.onResolve) {
        item.onResolve(response);
      }
    } catch (error) {
      item.retries = (item.retries || 0) + 1;
      if (item.retries <= this.maxRetries) {
        console.error('[FnQueueWithRetries] Request failed, but going to try again. Error: ', error);
        this.queue.enqueue(item);
      } else {
        console.error(`[FnQueueWithRetries] Request failed after ${this.maxRetries} retries`, error);
        console.error(`[FnQueueWithRetries] Request failed with the following args ${JSON.stringify(item.args)}`);
      }
    }
  }

  addRequest(item: FnQueueWithRetriesItem) {
    this.queue.enqueue(item);
    this.processQueue();
  }

  lastBatchStartTimestamp: number | undefined = undefined;
  currentBatchLoad = 0;
  timeout: ReturnType<typeof setTimeout> | null = null;

  processQueue() {
    if (this.queue.isEmpty()) { return; }

    const kickItem = () => {
      const item = this.queue.dequeue();

      if (item) {
        this.currentBatchLoad++;
        console.log('[processQueue] item adding item:', { r: this.currentBatchLoad, ql: this.queue.length });
        
        this.resolveRequestWithRetries(item).finally(() => {
          if (!this.queue.isEmpty() && this.currentBatchLoad < this.rateLimit) {
            kickItem();
          }
        });
      }
    };

    if (this.lastBatchStartTimestamp && (Date.now() - this.lastBatchStartTimestamp) < this.limitInterval) {
      if (this.currentBatchLoad < this.rateLimit) {
        // can execute
        kickItem();
      } else {
        return;
      }
    } else {
      // @ts-ignore
      clearTimeout(this.timeout || 0);
      this.currentBatchLoad = 0;
      this.lastBatchStartTimestamp = Date.now();
      console.log('[processQueue] item started new batch', this.lastBatchStartTimestamp);
      this.timeout = setTimeout(() => {
        console.log('[processQueue] item timeout', Date.now());
        this.processQueue();
      }, this.limitInterval);
      kickItem();
    }
  }
}