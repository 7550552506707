import React from 'react';
import {IconProps} from './_iconProps';

export const Award: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10.9467 18.52L9.33333 30.6666L16 26.6666L22.6667 30.6666L21.0533 18.5066M25.3333 10.6666C25.3333 15.8213 21.1547 20 16 20C10.8453 20 6.66667 15.8213 6.66667 10.6666C6.66667 5.51199 10.8453 1.33331 16 1.33331C21.1547 1.33331 25.3333 5.51199 25.3333 10.6666Z" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</svg>
