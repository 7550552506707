// import { persist } from 'zustand/middleware';
import {
  AlertSettings,
  AlertSettingsAlertType,
  AlertSettingsSensitivityType,
  getHomesAlertSettings,
  putAlertSettings,
} from '@ubiety/fe-api';
import { createStore } from 'zustand/vanilla';

import { getHomeId } from './selectors';
import { ContextError } from '../classes';
import { fnSilentWrapper, withCatcher } from '../helpers';
import { DataStatus, Maybe } from '../types';

export type IndexedAlertsSettings = {
  [Property in AlertSettingsAlertType]: AlertSettings;
};

interface IAlertSettingsState {
  alertsSettings: Array<AlertSettings>;
  alertsSettingsIndexed: Partial<IndexedAlertsSettings>;
  alertSettingsStatus: DataStatus;
}

type AlertSettingsAction = {
  setAlertSettings: (type: AlertSettingsAlertType, payload: {
    sensitivity?: AlertSettingsSensitivityType;
    suppressed?: boolean;
  }) => Promise<Maybe<AlertSettings> | void>;
  setAlertSettingsSilently: (type: AlertSettingsAlertType, payload: {
    sensitivity?: AlertSettingsSensitivityType;
    suppressed?: boolean;
  }) => Promise<Maybe<boolean>>;
  getAllAlertSettings: () => Promise<Maybe<Array<AlertSettings>> | void>;
  getAllAlertSettingsSilently: () => Promise<Maybe<boolean>>;
  setAlertSettingsStatus: (status: DataStatus) => void;
  reset: () => void;
};

export type AlertSettingsState = IAlertSettingsState & AlertSettingsAction;

const INITIAL_STATE: IAlertSettingsState = {
  alertsSettings: [],
  alertsSettingsIndexed: {},
  alertSettingsStatus: undefined,
};

export const alertSettingsStore = createStore<AlertSettingsState>()(
  // persist(
    (set, get) => ({
      ...INITIAL_STATE,
      reset: () => set(INITIAL_STATE),
      setAlertSettings: withCatcher('alertSettingsStore.setAlertSettings', async (type, payload) => {
        const homeId = getHomeId();
        const alertSettingsStatus = get().alertSettingsStatus;

        if (!homeId || alertSettingsStatus !== 'success') {
          throw new ContextError('Home id is empty or alertSettings is not loaded', {isLocal: true});
        }

        const { alertsSettings, alertsSettingsIndexed } = get();

        const settingsItem = alertsSettingsIndexed[type];
        const settingsUid = settingsItem?.uid;

        if (settingsUid && !Object.entries(payload).length) {
          throw new ContextError('settingsUid is passed but payload is empty', {isLocal: true});
        }

        if (!settingsUid) {
          throw new ContextError('Cant find alert sensitivity settings item to update', {isLocal: true});
        }

        const newSettingsItem = await putAlertSettings(homeId, {
          notification_types: [type],
          ...payload
        });

        if (!newSettingsItem) {
          throw new ContextError('Error getting settings', {isLocal: true});
        }

        const indexNumber = alertsSettings.indexOf(settingsItem);

        if (indexNumber < 0) {
          alertsSettings.push(newSettingsItem);
        }
        else {
          alertsSettings.splice(indexNumber, 1, newSettingsItem);
        }

        alertsSettingsIndexed[newSettingsItem.notification_type] = newSettingsItem;

        set({
          alertsSettings: [...alertsSettings],
          alertsSettingsIndexed: { ...alertsSettingsIndexed }
        });

        return newSettingsItem;
      }),
      setAlertSettingsSilently: fnSilentWrapper((type, payload) => get().setAlertSettings(type, payload)),
      getAllAlertSettings: withCatcher('alertSettingsStore.getAllAlertSettings', async () => {
        const homeId = getHomeId();
        if (!homeId) { 
          throw new ContextError('No home id to get alerts from', {isLocal: true});
        }

        const settings = await getHomesAlertSettings(homeId);
        if (!settings) {
          throw new ContextError('Error getting settings', {isLocal: false});
        }
        //console.log('[AlertsSettingsStore] got new settings', { settings, homeId });

        set({
          alertSettingsStatus: 'success',
          alertsSettings: settings,
          alertsSettingsIndexed: settings.reduce((reducer: any, entry: any) => {
            reducer[entry.notification_type] = entry;
            return reducer;
          }, {})
        });

        return settings;
      }, (e) => {
        if (e.isLocal) {return;}
        set({ alertSettingsStatus: 'error' });
      }),
      getAllAlertSettingsSilently: fnSilentWrapper(() => get().getAllAlertSettings()),
      setAlertSettingsStatus: (status: DataStatus) => set({alertSettingsStatus: status}),
    }),

  //   {
  //     name: 'alert-settings-storage',
  //   },
  // ),
);