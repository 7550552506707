import { RawProfile, RawPresenceData } from '@ubiety/fe-api';

import { Profile } from '../types/profile';
import { snakeToCamelCasePropertyNames } from '../utils';

export const profileSort = (a: Profile, b: Profile) => {
  const aPresent = a.state === 'present';
  const bPresent = b.state === 'present';
  // By Present
  if (aPresent && bPresent) {
    // Sort Alphabetically
    return (a?.name ?? '').toLowerCase() < (b?.name ?? '').toLowerCase()
      ? -1
      : (a?.name ?? '').toLowerCase() > (b?.name ?? '').toLowerCase()
        ? 1
        : 0;
  }
  if (aPresent) {
    return -1;
  }
  if (bPresent) {
    return 1;
  }

  // By Last Seen
  return (b.lastSeen ?? 0) - (a.lastSeen ?? 0);
};

export const processRawProfileArray = (
  rawProfileArray: Array<RawProfile>,
  rawProfilePresenceDate?: RawPresenceData
) => {
  const newProfileArray: Array<Profile> = [];
  const newProfiles: Record<string, Profile> = {};

  rawProfileArray.forEach((p) => {
    const processedProfile = snakeToCamelCasePropertyNames(p) as Profile;
    const uid = p.uid;
    const state = rawProfilePresenceDate?.[p.uid]?.current_status;
    const lastStateChange = rawProfilePresenceDate?.[p.uid]?.last_status_change;
    processedProfile.uid = p.uid;
    processedProfile.state = state;
    processedProfile.lastSeen = state === 'present' ? Infinity : lastStateChange;
    processedProfile.lastStateChange = lastStateChange;

    newProfileArray.push(processedProfile);
    newProfiles[uid] = processedProfile;
  });

  return {
    newProfileArray,
    newProfiles
  };
};
