import { apiRequest } from '../apiRequest';
import { EdgeDaemon, EdgeDaemonHealth, EdgeDaemonsGetResponse } from './types';

export const getDaemons = (homeId: number) =>
  apiRequest<EdgeDaemonsGetResponse>({
    service: 'feApiGateway',
    path: `homes/${homeId}/daemons`,
    method: 'GET'
  });

export const getDaemonById = (homeId: number, daemonId: number) =>
  apiRequest<EdgeDaemon>({
    service: 'feApiGateway',
    path: `homes/${homeId}/daemons/${daemonId}`,
    method: 'GET'
  });

export const getDaemonHealth = (homeId: number, daemonId: number) =>
  apiRequest<EdgeDaemonHealth>({
    service: 'feApiGateway',
    path: `homes/${homeId}/daemons/${daemonId}/health`,
    method: 'GET'
  });
