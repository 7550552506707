import React from 'react';
import {IconProps} from './_iconProps';

export const ToggleLeft: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M21.3333 6.66669H10.6667C5.51201 6.66669 1.33334 10.8454 1.33334 16C1.33334 21.1547 5.51201 25.3334 10.6667 25.3334H21.3333C26.488 25.3334 30.6667 21.1547 30.6667 16C30.6667 10.8454 26.488 6.66669 21.3333 6.66669Z" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
<path d="M10.6667 20C12.8758 20 14.6667 18.2092 14.6667 16C14.6667 13.7909 12.8758 12 10.6667 12C8.45753 12 6.66667 13.7909 6.66667 16C6.66667 18.2092 8.45753 20 10.6667 20Z" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</svg>
