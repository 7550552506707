import React from 'react';
import {IconProps} from './_iconProps';

export const Inbox: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M29.3333 16H21.3333L18.6667 20H13.3333L10.6667 16H2.66667M29.3333 16V24C29.3333 24.7072 29.0524 25.3855 28.5523 25.8856C28.0522 26.3857 27.3739 26.6667 26.6667 26.6667H5.33333C4.62609 26.6667 3.94781 26.3857 3.44771 25.8856C2.94762 25.3855 2.66667 24.7072 2.66667 24V16M29.3333 16L24.7333 6.81334C24.5126 6.36905 24.1722 5.99516 23.7506 5.73371C23.329 5.47225 22.8428 5.3336 22.3467 5.33334H9.65333C9.15722 5.3336 8.67102 5.47225 8.24939 5.73371C7.82777 5.99516 7.48744 6.36905 7.26667 6.81334L2.66667 16" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</svg>
