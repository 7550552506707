import React from 'react';
import {IconProps} from './_iconProps';

export const AtSign: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M21.3333 10.6667V17.3333C21.3333 18.3942 21.7548 19.4116 22.5049 20.1617C23.2551 20.9119 24.2725 21.3333 25.3333 21.3333C26.3942 21.3333 27.4116 20.9119 28.1618 20.1617C28.9119 19.4116 29.3333 18.3942 29.3333 17.3333V16C29.3331 12.9907 28.315 10.07 26.4444 7.71269C24.5738 5.35543 21.9607 3.70029 19.0302 3.01638C16.0996 2.33248 13.0239 2.66004 10.3031 3.94581C7.58235 5.23158 5.37653 7.39993 4.04435 10.0983C2.71216 12.7966 2.33196 15.8663 2.96556 18.8081C3.59916 21.75 5.2093 24.391 7.53418 26.3017C9.85905 28.2124 12.7619 29.2805 15.7708 29.3322C18.7796 29.3839 21.7175 28.4163 24.1067 26.5867M21.3333 16C21.3333 18.9455 18.9455 21.3333 16 21.3333C13.0545 21.3333 10.6667 18.9455 10.6667 16C10.6667 13.0545 13.0545 10.6667 16 10.6667C18.9455 10.6667 21.3333 13.0545 21.3333 16Z" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</svg>
