import React from 'react';
import {IconProps} from './_iconProps';

export const Power: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M24.48 8.85332C26.1578 10.5317 27.3003 12.6699 27.763 14.9976C28.2258 17.3252 27.9879 19.7378 27.0795 21.9303C26.1712 24.1228 24.6331 25.9967 22.6598 27.3151C20.6865 28.6335 18.3665 29.3372 15.9933 29.3372C13.6201 29.3372 11.3002 28.6335 9.3269 27.3151C7.3536 25.9967 5.81552 24.1228 4.90716 21.9303C3.99879 19.7378 3.76092 17.3252 4.22363 14.9976C4.68633 12.6699 5.82883 10.5317 7.50667 8.85332M16 2.66666V16" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</svg>
