import React from 'react';
import {IconProps} from './_iconProps';

export const Phone: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M29.3333 22.56V26.56C29.3348 26.9313 29.2588 27.2989 29.11 27.6391C28.9612 27.9794 28.743 28.2848 28.4694 28.5358C28.1958 28.7869 27.8727 28.978 27.521 29.0969C27.1692 29.2159 26.7965 29.2601 26.4266 29.2267C22.3238 28.7809 18.3826 27.3789 14.92 25.1333C11.6984 23.0862 8.9671 20.3549 6.91998 17.1333C4.66662 13.6549 3.26431 9.69466 2.82665 5.57333C2.79333 5.20462 2.83715 4.83301 2.95532 4.48216C3.07348 4.13132 3.26341 3.80892 3.513 3.53549C3.7626 3.26207 4.06639 3.04361 4.40504 2.89403C4.74368 2.74444 5.10977 2.66701 5.47998 2.66666H9.47998C10.1271 2.6603 10.7544 2.88944 11.245 3.31137C11.7356 3.73331 12.0561 4.31926 12.1466 4.96C12.3155 6.24009 12.6286 7.49697 13.08 8.70666C13.2594 9.1839 13.2982 9.70255 13.1919 10.2012C13.0855 10.6998 12.8385 11.1575 12.48 11.52L10.7866 13.2133C12.6847 16.5514 15.4486 19.3153 18.7866 21.2133L20.48 19.52C20.8425 19.1615 21.3002 18.9145 21.7988 18.8081C22.2974 18.7018 22.8161 18.7406 23.2933 18.92C24.503 19.3714 25.7599 19.6845 27.04 19.8533C27.6877 19.9447 28.2792 20.2709 28.702 20.77C29.1249 21.269 29.3495 21.9061 29.3333 22.56Z" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</svg>
