import React from 'react';
import {IconProps} from './_iconProps';

export const CornerDownLeft: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12 13.3333L5.33334 20M5.33334 20L12 26.6666M5.33334 20H21.3333C22.7478 20 24.1044 19.4381 25.1046 18.4379C26.1048 17.4377 26.6667 16.0811 26.6667 14.6666V5.33331" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</svg>
