import React from 'react';
import {IconProps} from './_iconProps';

export const ShieldOff: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_149_709)">
<path d="M26.2533 18.6667C26.5214 17.8029 26.6607 16.9044 26.6667 16V6.66668L16 2.66668L11.7867 4.24001M6.30667 6.30668L5.33334 6.66668V16C5.33334 24 16 29.3333 16 29.3333C18.8227 27.8439 21.3596 25.8668 23.4933 23.4933M1.33334 1.33334L30.6667 30.6667" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</g>
<defs>
<clipPath id="clip0_149_709">
<rect width={width} height={height} fill="white"/>
</clipPath>
</defs>
</svg>
