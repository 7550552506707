import React from 'react';
import {IconProps} from './_iconProps';

export const CornerUpLeft: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12 18.6666L5.33334 12M5.33334 12L12 5.33331M5.33334 12H21.3333C22.7478 12 24.1044 12.5619 25.1046 13.5621C26.1048 14.5623 26.6667 15.9188 26.6667 17.3333V26.6666" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</svg>
