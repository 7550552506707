import React from 'react';
import {IconProps} from './_iconProps';

export const Figma: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M16 2.66666H11.3333C10.0957 2.66666 8.90867 3.15832 8.0335 4.03349C7.15833 4.90866 6.66667 6.09565 6.66667 7.33332C6.66667 8.571 7.15833 9.75799 8.0335 10.6332C8.90867 11.5083 10.0957 12 11.3333 12M16 2.66666V12M16 2.66666H20.6667C21.2795 2.66666 21.8863 2.78736 22.4525 3.02189C23.0187 3.25641 23.5332 3.60015 23.9665 4.03349C24.3998 4.46683 24.7436 4.98128 24.9781 5.54747C25.2126 6.11365 25.3333 6.72049 25.3333 7.33332C25.3333 7.94616 25.2126 8.55299 24.9781 9.11918C24.7436 9.68537 24.3998 10.1998 23.9665 10.6332C23.5332 11.0665 23.0187 11.4102 22.4525 11.6448C21.8863 11.8793 21.2795 12 20.6667 12M16 12H11.3333M16 12H20.6667M16 12V21.3333M11.3333 12C10.0957 12 8.90867 12.4917 8.0335 13.3668C7.15833 14.242 6.66667 15.429 6.66667 16.6667C6.66667 17.9043 7.15833 19.0913 8.0335 19.9665C8.90867 20.8417 10.0957 21.3333 11.3333 21.3333M20.6667 12C20.0538 12 19.447 12.1207 18.8808 12.3552C18.3146 12.5897 17.8002 12.9335 17.3668 13.3668C16.9335 13.8002 16.5898 14.3146 16.3552 14.8808C16.1207 15.447 16 16.0538 16 16.6667C16 17.2795 16.1207 17.8863 16.3552 18.4525C16.5898 19.0187 16.9335 19.5331 17.3668 19.9665C17.8002 20.3998 18.3146 20.7436 18.8808 20.9781C19.447 21.2126 20.0538 21.3333 20.6667 21.3333C21.2795 21.3333 21.8863 21.2126 22.4525 20.9781C23.0187 20.7436 23.5332 20.3998 23.9665 19.9665C24.3998 19.5331 24.7436 19.0187 24.9781 18.4525C25.2126 17.8863 25.3333 17.2795 25.3333 16.6667C25.3333 16.0538 25.2126 15.447 24.9781 14.8808C24.7436 14.3146 24.3998 13.8002 23.9665 13.3668C23.5332 12.9335 23.0187 12.5897 22.4525 12.3552C21.8863 12.1207 21.2795 12 20.6667 12ZM11.3333 21.3333C10.0957 21.3333 8.90867 21.825 8.0335 22.7002C7.15833 23.5753 6.66667 24.7623 6.66667 26C6.66667 27.2377 7.15833 28.4247 8.0335 29.2998C8.90867 30.175 10.0957 30.6667 11.3333 30.6667C12.571 30.6667 13.758 30.175 14.6332 29.2998C15.5083 28.4247 16 27.2377 16 26V21.3333M11.3333 21.3333H16" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</svg>
