import React from 'react';
import {IconProps} from './_iconProps';

export const CloudDrizzle: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_149_266)">
<path d="M10.6667 25.3334V28M10.6667 17.3334V20M21.3334 25.3334V28M21.3334 17.3334V20M16 28V30.6667M16 20V22.6667M26.6667 22.1067C28.0683 21.4928 29.2162 20.4154 29.9175 19.0554C30.6188 17.6954 30.8309 16.1355 30.5181 14.6376C30.2054 13.1397 29.3869 11.795 28.2001 10.8291C27.0132 9.8633 25.5302 9.33516 24 9.33336H22.32C21.8974 7.69672 21.0918 6.18396 19.9698 4.91976C18.8477 3.65556 17.4413 2.67613 15.8664 2.06219C14.2915 1.44825 12.5932 1.21738 10.9116 1.38862C9.22997 1.55985 7.6131 2.12829 6.19428 3.04707C4.77546 3.96585 3.59531 5.20867 2.7511 6.67309C1.90688 8.1375 1.42279 9.78159 1.33871 11.4698C1.25463 13.1581 1.57297 14.8421 2.2675 16.3832C2.96202 17.9242 4.01285 19.2781 5.33336 20.3334" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</g>
<defs>
<clipPath id="clip0_149_266">
<rect width={width} height={height} fill="white"/>
</clipPath>
</defs>
</svg>
