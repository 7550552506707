import React from 'react';
import {IconProps} from './_iconProps';

export const CloudSnow: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M26.6667 23.44C28.0683 22.8261 29.2161 21.7488 29.9175 20.3887C30.6188 19.0287 30.8308 17.4688 30.5181 15.9709C30.2054 14.473 29.3869 13.1283 28.2001 12.1625C27.0132 11.1966 25.5302 10.6685 24 10.6667H22.32C21.8974 9.03003 21.0918 7.51727 19.9698 6.25307C18.8477 4.98887 17.4413 4.00944 15.8664 3.3955C14.2915 2.78156 12.5932 2.55069 10.9116 2.72193C9.22995 2.89317 7.61309 3.4616 6.19427 4.38038C4.77545 5.29916 3.59529 6.54198 2.75108 8.0064C1.90687 9.47082 1.42277 11.1149 1.33869 12.8031C1.25461 14.4914 1.57295 16.1754 2.26748 17.7165C2.96201 19.2575 4.01283 20.6115 5.33334 21.6667M10.6667 21.3333H10.68M10.6667 26.6667H10.68M16 24H16.0133M16 29.3333H16.0133M21.3333 21.3333H21.3467M21.3333 26.6667H21.3467" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</svg>
