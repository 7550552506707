import {
  FaRegUserCircle
} from 'react-icons/fa';
import styled from 'styled-components';


export type AvatarProps = {
  username?: string | null;
  photoURL?: string | null;
  variant?: 'regular' | 'iconplaceholder'
  size?: number;
}
export const Avatar: React.FC<AvatarProps & React.SVGAttributes<SVGSVGElement>> = ({
  username,
  photoURL,
  variant,
  size=100,
  children,
  ...rest
}) => {
  const initials = username?.replace(/^(\w).*\s(\w)/g,'$1$2');

  return (
    <StyledAvatar width={size} height={size} viewBox="0 0 100 100" borderRadius={size/2} {...rest}>
    { photoURL
      ?
      <image width="100" height="100" href={photoURL||undefined} />
      :
      variant === 'iconplaceholder'
      ?
      <FaRegUserCircle fontSize="90" x={5} y={5} />
      :
      <StyledText x="50%" y="53%">{initials}</StyledText>
      }
    </StyledAvatar>
  );
};

const StyledAvatar = styled.svg.attrs({} as {
  borderRadius: number;
})`${({theme, borderRadius}) => `
  border-radius: ${borderRadius}px;
  padding: 0 !important;
  color: ${theme.newColors.WhippedCream};
`}`;

const StyledText = styled.text`${({theme}) => `

  text-anchor: middle;
  dominant-baseline: middle;
  font-family: ${theme.defaultFontFamily};
  font-weight: bold;
  font-size: 70px;

  fill: ${theme.colors.gray.lighter};

`}`;
