import { SensorWifiSignalStrength } from '@ubiety/fe-api';

import { RawSensorWithHealth, Sensor, isPresent } from '../types';

export const processRawSensorArray = (rawSensorArray: Array<RawSensorWithHealth>) => {
  const newSensorArray: Array<Sensor> = rawSensorArray?.map((sensor) => {
    if (!sensor) {
      return null;
    }

    const { sensor_health: sensorHealth, ...rest } = sensor;

    let wifiSignalStrengthString: SensorWifiSignalStrength = 'waiting...';

    if (sensorHealth?.wifi.rssi) {
      if (sensorHealth.wifi.rssi <= -80) {
        wifiSignalStrengthString = 'WEAK';
      } else if (
        sensorHealth.wifi.rssi > -80 &&
        sensorHealth.wifi.rssi <= -60
      ) {
        wifiSignalStrengthString = 'OKAY';

      } else if (sensorHealth.wifi.rssi >= -50) {
        wifiSignalStrengthString = 'GOOD';
      }
    }

    return {
      sensorType: sensor.hardware_type,
      hardwareId: sensor.hardware_id,
      wifiIp: sensorHealth?.wifi.ip,
      wifiMac: sensorHealth?.wifi.mac,
      wifiCanPingInternet: sensorHealth?.wifi.can_ping_internet,
      wifiFrequency: sensorHealth?.wifi.frequency,
      wifiSignalStrengthString,
      wifiSsid: sensorHealth?.wifi.ssid||null,
      ...rest,
    };
  })
    .filter(isPresent);

  // Active sensor is the most recently setup and active
  const newActiveSensor: Sensor | undefined = newSensorArray
    .sort((a, b) => (b.setup_timestamp ?? 0) - (a.setup_timestamp ?? 0))
    .find((sensor) => sensor.active && sensor.setup_timestamp);

  const newSensors: Record<string, Sensor> = {};
  newSensorArray.forEach((s) => {
    newSensors[s.uid] = s;
  });

  return {
    newActiveSensor,
    newSensorArray,
    newSensors,
  };
};