import styled from 'styled-components';

const StyledLabel = styled.label`${({theme}) => `
  ${theme.fontStyles.label}
`}`;

export const SubLabel = styled.span`${({theme}) => `
  display: block;
  font-size: ${theme.fontSize.m}px;
  color: ${theme.colors.ink.lighter};
`}`;

export const Label = StyledLabel;

export default Label;