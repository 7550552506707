import React from 'react';
import {IconProps} from './_iconProps';

export const Minimize2: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5.33333 18.6667H13.3333M13.3333 18.6667V26.6667M13.3333 18.6667L4 28M26.6667 13.3333H18.6667M18.6667 13.3333V5.33333M18.6667 13.3333L28 4" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</svg>
