import {useState, useEffect, useMemo, useRef, useCallback} from 'react';

import {
  format
} from 'date-fns-tz';
import {
  BsFullscreen,
  BsFullscreenExit
} from 'react-icons/bs';
import styled, {css, keyframes} from 'styled-components';

import {
  useFibonachiPowerPlot,
  Button
} from 'components';
import {
  useDevicesStore,
} from 'context';
import {
  useDataFeedObserver,
  BluetoothSignal,
  WifiSignal,
  CellSignal
} from 'hooks';
import {
  FlexContainer,
  Flex6,
  digestedAgent
} from 'layout';
import {
  newColors
} from 'theme';
import {
  Globe,
  Zap
} from 'theme/icons';
import {
  providerToLabelName,
} from 'helpers';
import { NavLink } from 'react-router-dom';

export type CellMonitorProps = {
  variant?: 'inline'|'normal';
  wavesKickInterval?: number;
}

export type PassiveMonitorProps = {
  wavesKickInterval?: number;
};

//// CELL MONITOR

export const CellMonitor: React.FC<CellMonitorProps & React.HTMLAttributes<HTMLDivElement>> = ({
  children,
  wavesKickInterval = 250,
  ...rest
}) => {
  const svgRef = useRef<SVGSVGElement>(null);

  const [allCellSignals, setAllCellSignals] = useState<CellSignal[]>([]);
  const [isFullScreen, setIsFullScreen] = useState<boolean>(false);
  const wavesQ = useRef<Array<{power: number, provider: string}>>([]).current;
  const { newestSignals, queues } = useDataFeedObserver({
    captureOnBackground: true
  });
  const fibonachiSettings = useMemo(() => ({
    cameraX:0,
    cameraY:145,
    cameraZ:200,
    scaleAdjustment:200,
    linesColorHex:'#8a9380',
    dotsColorHex:'#3b3c37',
    dotsAccentColorHex:'#fc118a',
    circlesNumber:15,
    distributionFormula:'fibonachiSpiral',
    numberOfDots: digestedAgent.phone ? 500 : 1000,
    dotSize:2.5,
    accentDimStep:.005,
    kickDiviation:0.02,
    framePerSecond:24,
    speed:1,
    K:0.25,
    D:0.35,
    M:1,
    linesWidth: 1,
    // view box settings
    width:620,
    height:295,
    viewBoxOffsetX: 0,
    viewBoxOffsetY: 0,
    waveDimStep: 0.01,
    bgColor:'#fefae3'
  }),[]);

  // instantiate fibonachi power plot
  const {
    addWave,
    play,
    stop
  } = useFibonachiPowerPlot(svgRef, fibonachiSettings);
  
  // helpers
  const getProviderColor = useCallback((provider) =>
    newColors[providerToLabelName(provider)]
  ,[]);

  useEffect(function intakeLatestQueIntoStateSilentlyOnElementInit(){
    const latestQ = queues[queues.length-1];
    console.log('[CellMonitor] processing initial q', {queues, latestQ});
    const newCellSignals: CellSignal[] = [];
    latestQ?.queue?.forEach(signal => {
      // add them to state as appropriate
      if(signal.type === 'cell'||signal.type === 'cell-scan'){
        newCellSignals.push(signal);
      } 
    });
    setAllCellSignals([...newCellSignals]);
  },[
    queues
  ]);
  useEffect(function intakeNewIdentifiers(){
    const newCellSignals: CellSignal[] = [];
    newestSignals?.forEach(signal => {
      // add them to state as appropriate
      if(signal.type === 'cell'||signal.type === 'cell-scan'){
        newCellSignals.push(signal);
        wavesQ.push({
          power: signal.power,
          provider: signal.cellProvider
        });
      } 
    });
    setAllCellSignals(prev => [...prev, ...newCellSignals]);
  },[
    newestSignals,
    getProviderColor,
    addWave,
    wavesQ
  ]);
  // waves runner
  useEffect(function setupWavesKickRunner(){
    let timeout: any;
    timeout = setTimeout(function kickinNewWave(){
      if(wavesQ.length){
        const {
          power,
          provider
        } = wavesQ.splice(0,1)[0];
        const color = getProviderColor(provider);
        const circleN = Math.min(15, Math.round( 15*((power*-1)-40)/80));

        //console.log('- adding active Wave', circleN, color);
        addWave(circleN, color);
      }
      timeout = setTimeout(kickinNewWave, wavesKickInterval)
    }, wavesKickInterval);
    return () => clearTimeout(timeout);
  },[
    wavesKickInterval,
    addWave,
    fibonachiSettings,
    wavesQ,
    getProviderColor
  ]);

  // set interval for plot
  useEffect(function(){
    play();
    return stop;
  },[play, stop]);

  // kick in wave on appear
  useEffect(function KickRandomWaweOnAppear(){
    const dbm = -90;
    addWave( 
      Math.min(15, Math.round( 15*((dbm*-1)-40)/80)), // we are interested in diviation between 40 and 120 dbm, our plot has 15 circles
      '#000000');
  }, [addWave]);

  const containerRef = useRef<HTMLDivElement>(null);
  
  // this fixes extremely weird behavior obseverved on iphone horiontal mode,
  // where that container's background goes strangely color pixelated
  // useEffect(function applyScreenWierdnessFix(){
  //   //console.log('[TMSI monitor] digested agent', {digestedAgent});
  //   if(digestedAgent.apple.device){
  //     let val = 1;
  //     const interval: ReturnType<typeof setInterval> = setInterval( () => {
  //       // window.scrollTo(window.scrollX, window.scrollY+val);
  //       val *= -1;
  //       // console.log('scrolling works!?');
  //       containerRef.current?.setAttribute('style', `background-color: rgba(${colorsRgb.newColors.WhippedCream.toString()}, 0.99`+(1-val)+');');
  //     }, 100);

  //     return () => clearInterval(interval);
  //   }
  // }, []);

  // slice out current identifiers list
  // on identifiers change
  const currentIdentifiers = useMemo(function sliceOutCurrentIdentifiersListOnChange(){
    const newCurrentIdentifiers: Array<null|CellSignal> = [];
    const length = allCellSignals.length;
    const displayLength = isFullScreen && !digestedAgent.phone? 12 : 4;
    for(let i=0;i<displayLength;i++){
      newCurrentIdentifiers.push(allCellSignals[length-i-1]||null)
    }
    return newCurrentIdentifiers;
  },[
    allCellSignals,
    isFullScreen
  ]);

  return <>
    <CellMonitorContainer ref={containerRef} id="tmsiMonitor" {...rest} fullScreen={isFullScreen}>
      <TmsiMonitorCellContainer className="tmsi-monitor-cell">
        <PlotSvg ref={svgRef} width="759" height="auto" viewBox="0 83 620 295" />
      </TmsiMonitorCellContainer>
      
      <SideBoxContainer className="side-box-container">
        <SideBox className="recent-cell-activity-list-box">
          <h2>Cellular Activity</h2>
          <RecentCellActivityList>
            {currentIdentifiers?.map( (ci, i) => ci ? <li key={(() => `timestamp-${ci.timestamp}-${ci.type}`)()}>
                <CellProviderColorBox provider={ci.cellProvider} />{ci.establishmentCause === 'emergency' ? <span className="warn-label"><Zap />911</span> : null} {ci.cellProvider + ' ' + String(ci.type).replace('-', ' ') + ' ' + format(ci.timestamp, 'h:mm:ss a') + ' at ' + Math.round(ci.power) + ' dBm' + (ci.identifier ? ' with TMSI '+ci.identifier : '')}
              </li> : <li key={`notani-${i}`} className={allCellSignals.length ? '' : "emptyShimmer"}></li>)
            }
          </RecentCellActivityList>
          <div className="buttons-row">
            <NavLink to="activity"><Button variant='primary' sizeVariant="small" arrowRight>Full Activity Report</Button></NavLink>
            {children}
          </div>
        </SideBox>
      </SideBoxContainer>

      {/* Full screen button */}
      <FullScreenButton
        onClick={() => setIsFullScreen(prev => !prev)}
        >
        {!isFullScreen ?
          <BsFullscreen />
          :
          <BsFullscreenExit />
        }
      </FullScreenButton>
    </CellMonitorContainer>
  </>;
};

//// PASSIVE MONITOR

const SIGNAL_POWER_RANGE = [20,120];
const PassiveColorMap = new Map();
PassiveColorMap.set('bluetooth','#236ceb');
PassiveColorMap.set('wifi','#ebc623');

export const PassiveMonitor: React.FC<PassiveMonitorProps & React.HTMLAttributes<HTMLDivElement>> = ({
  wavesKickInterval=(digestedAgent.phone ? 500 : 250),
  children,
  ...rest
}) => {
  const svgRef = useRef<SVGSVGElement>(null);
  const [isFullScreen, setIsFullScreen] = useState<boolean>(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const {deviceArray} = useDevicesStore(s => s);
  const presentDevices = useMemo(() => deviceArray?.filter(d => d.state === 'present' ),[deviceArray]);
  const fibonachiSettings = useMemo(() => ({
    cameraX:0,
    cameraY:145,
    cameraZ:200,
    scaleAdjustment:200,
    linesColorHex:'#8a9380',
    dotsColorHex:'#3b3c37',
    dotsAccentColorHex:'#fc118a',
    circlesNumber:15,
    distributionFormula:'fibonachiSpiral',
    numberOfDots: digestedAgent.phone ? 500 : 1000,
    dotSize:2.5,
    accentDimStep:.025,
    kickDiviation:0.02,
    framePerSecond: digestedAgent.phone ? 17 : 24,
    speed: digestedAgent.phone ? 1.3 : 1,
    K:0.25,
    D:0.35,
    M:1,
    linesWidth: 1,
    // view box settings
    width:620,
    height:295,
    viewBoxOffsetX: 0,
    viewBoxOffsetY: 0,
    waveDimStep: 0.01,
    bgColor:'#fefae3'
  }),[]);
  // instantiate fibonachi power plot
  const {
    addWave,
    play,
    stop
  } = useFibonachiPowerPlot(svgRef, fibonachiSettings);
  // use data observer
  const { newestSignals, queues } = useDataFeedObserver({
    captureOnBackground: true
  });
  // wavesQ, sinse bluetooth and wifi signals come at once
  const wavesQ = useRef<Array<{power: number, type: 'bluetooth'|'wifi'}>>([]).current;
  const [wifiIdentifiers, setWifiIdentifiers] = useState<WifiSignal[]>([]);
  const [bluetoothIdentifiers, setBluetoothIdentifiers] = useState<BluetoothSignal[]>([]);
    
  const [wifiNetworkIds, setWifiNetworkIds] = useState<Array<string>>([]);
  const [bluetoothManufacturers, setBluetoothManufacturers] = useState<Array<string>>([]);

  // signals intake
  useEffect(function intakeLatestQueIntoStateSilentlyOnElementInit(){
    const latestQ = queues[queues.length-1];
    const newWifiSignals: WifiSignal[] = [];
    const newBluetoothSignals: BluetoothSignal[] = [];
    latestQ?.queue?.forEach(signal => {
      // add them to state as appropriate
      if(signal.type === 'wifi'){
        newWifiSignals.push(signal);
      } else if(signal.type === 'bluetooth'){
        newBluetoothSignals.push(signal)
      } else {
        return;
      }
    });
    setWifiIdentifiers(prev => [...prev, ...newWifiSignals]);
    setBluetoothIdentifiers(prev => [...prev, ...newBluetoothSignals]);
  },[
    queues
  ]);
  useEffect(function intakeNewIdentifiers(){
    const newWifiSignals: WifiSignal[] = [];
    const newBluetoothSignals: BluetoothSignal[] = [];
    newestSignals?.forEach(signal => {
      // add them to state as appropriate
      if(signal.type === 'wifi'){
        newWifiSignals.push(signal);
        wavesQ.push({type: 'wifi', power: signal.power})
      } else if(signal.type === 'bluetooth'){
        newBluetoothSignals.push(signal)
        wavesQ.push({type: 'bluetooth', power: signal.power})
      } else {
        return;
      }
    });
    setWifiIdentifiers(prev => [...prev, ...newWifiSignals]);
    setBluetoothIdentifiers(prev => [...prev, ...newBluetoothSignals]);
  },[
    newestSignals,
    wavesQ
  ]);
  // waves runner
  useEffect(function setupWavesKickRunner(){
    let timeout: any;
    timeout = setTimeout(function kickinNewWave(){
      if(wavesQ.length){
        const {
          power,
          type
        } = wavesQ.splice(0,1)[0];
        const circleN = Math.round(fibonachiSettings.circlesNumber*(Math.abs(power)-SIGNAL_POWER_RANGE[0])/(SIGNAL_POWER_RANGE[1]-SIGNAL_POWER_RANGE[0]));
        const color = PassiveColorMap.get(type);
        addWave(circleN, color);
        //console.log('- adding passive Wave', circleN, color);
      }
      timeout = setTimeout(kickinNewWave, wavesKickInterval)
    }, wavesKickInterval);
    return () => clearTimeout(timeout);
  },[
    wavesKickInterval,
    addWave,
    fibonachiSettings,
    wavesQ
  ]);
  // press play on jelly fish
  // set interval for plot
  useEffect(function(){
    play();
    return stop;
  },[play, stop]);

  // kick in wave on appear
  useEffect(function KickRandomWaweOnAppear(){
    const dbm = -90;
    addWave( Math.min(15, Math.round( 15*((dbm*-1)-40)/80 // we are interested in diviation between 40 and 120 dbm, our plot has 15 circles
    )), '#000000');
  }, [addWave]);
  
  // this fixes extremely weird behavio obseverved on iphone horiontal mode,
  // where that container's background goes strangely color pixelated
  // useEffect(function iphoneScreenRefreshFix(){
  //   //console.log('[TMSI monitor] digested agent', {digestedAgent});
  //   if(digestedAgent.apple.device){
  //     let val = 1;
  //     const interval: ReturnType<typeof setInterval> = setInterval( () => {
  //       // window.scrollTo(window.scrollX, window.scrollY+val);
  //       val *= -1;
  //       // console.log('scrolling works!?');
  //       containerRef.current?.setAttribute('style', `background-color: rgba(${colorsRgb.newColors.WhippedCream.toString()}, 0.99'+(1-val)+');`);
  //     }, 100);

  //     return () => clearInterval(interval);
  //   }
  // }, []);

  // derive wifi and bluetooth things
  useEffect(function resolveWifiNetworkNames(){
    const newnetworkNames: {
      [key: string]: boolean
    } = {};

    wifiIdentifiers?.forEach(id => {
      const name = id.ssid;
      newnetworkNames[name] = true;
    });

    delete newnetworkNames[''];
    
    setWifiNetworkIds(Object.keys(newnetworkNames));
  },[
    wifiIdentifiers
  ]);

  useEffect(function resolvebluetoothManufacturerNames(){
    const newBluetoothManufacturers: {
      [key: string]: boolean
    } = {};

    bluetoothIdentifiers?.forEach(id => {
      const name = id.identifier;
      newBluetoothManufacturers[name] = true;
    });

    delete newBluetoothManufacturers[''];

    setBluetoothManufacturers(Object.keys(newBluetoothManufacturers));
  },[
    bluetoothIdentifiers
  ]);

  return <>
    <CellMonitorContainer ref={containerRef} id="tmsiMonitor" {...rest} fullScreen={isFullScreen}>
      
      <TmsiMonitorCellContainer className="tmsi-monitor-cell">
        <PlotSvg ref={svgRef} width="759" height="auto" viewBox="0 83 620 295" />
      </TmsiMonitorCellContainer>
      
      <SideBoxContainer className="side-box-container">
        <SideBox>
          <h2>WiFi Devices</h2>

          <NumberBox>
            <div><Globe /></div>
            <div>
              Current Number of<br />On Network Devices
              <div className="number">{presentDevices.length}</div>
            </div>
          </NumberBox>
          
        </SideBox>
        {children}
      </SideBoxContainer>

      {/* Full screen button */}
      <FullScreenButton
        onClick={() => setIsFullScreen(prev => !prev)}
        >
        {!isFullScreen ?
          <BsFullscreen />
          :
          <BsFullscreenExit />
        }
      </FullScreenButton>
    </CellMonitorContainer>
    <InfoFlexContainer>
      <Flex6>
        <h2>WiFi Network ID’s</h2>
        <p className={!wifiNetworkIds.length ? 'emptyShimmer' : ''}>{wifiNetworkIds.join(', ')}</p>
      </Flex6>
      <Flex6>
        <h2>BlueTooth Manufactures</h2>
        <p className={!bluetoothManufacturers.length ? 'emptyShimmer' : ''}>{bluetoothManufacturers.join(', ')}</p>
      </Flex6>
    </InfoFlexContainer>
  </>;
};

const CellMonitorContainer = styled(FlexContainer).attrs({} as Pick<CellMonitorProps, 'variant'> & {fullScreen?: boolean})`${({
  theme,
  variant='normal',
  fullScreen=false
}) => `
  will-change: transform;
  min-height: 200px;

  position: relative;
  margin-bottom: 0.8rem;
  
  .tmsi-monitor-cell {
    align-items: flex-end;
  }

  > * {
    align-items: center;
  }

  .tmsi-monitor-cell {
    margin-right: -100px;
  }
  .side-box-container {
    z-index: 1;
  }

  @media (max-width: 1150px) {
    .tmsi-monitor-cell {
      margin-right: 0;
      justify-content: flex-start;
      align-items: flex-start;
    }
    .side-box-container {
      margin-top: -100px;
      align-items: flex-end;
      justify-content: flex-end;
    }
  }


  ${variant === 'inline' ? `
    .tmsi-monitor-cell {
      display: flex-block;
      justify-content: center;
      align-items: center !important;
      min-width: 100%;
    }
  `
  : ''}

  .full-screen-button {
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: 100;
  }

  ${fullScreen && !digestedAgent.phone ? `
    position: fixed;
    top: -1px;
    bottom: -1px;
    left: -1px;
    right: -1px;
    z-index: 2000;
    margin: 0 !important;
    border-radius: 0;
    background-color: ${theme.newColors.WhippedCream};
    justify-content: flex-end;

    .tmsi-monitor-cell {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center !important;
      margin: 0 !important;
    }
      
    .tmsi-monitor-cell > svg {
      width: 85%;
      height: 85%;
    }

    .side-box-container {
      position: absolute;
      right: 50px;
      height: 100%;
      margin: 0;
      width: auto;
    }

    .recent-cell-activity-list-box {
      min-height: 529px;
      position: absolute;
      right: 0;
      top: 50%;
      margin: 0;
      margin-top: -200px;
      align-self: flex-start;
    }

    .full-screen-button {
      position: absolute;
      left: 20px;
      top: 20px;
      z-index: 100;
    }

    @media (max-width: 1150px) {
      .tmsi-monitor-cell {
        height: 80%;
        align-items: center;
      }
      .side-box-container {
        flex-grow: 1 !important;
        marging-right: 10px;
        right: 0px;
        bottom: 0px;
        padding: 10px;
        padding-bottom: 10px;
        height: 100%;
        box-sizing: border-box;
      }
      .side-box-container > *:first-child {
        max-width: 90%;
        margin: 1%;
      }
    }

    `:''}

    ${fullScreen && digestedAgent.phone ? `
      position: fixed;
      top: -1px;
      bottom: -1px;
      left: -1px;
      right: -1px;
      z-index: 2000;
      margin: 0 !important;
      border-radius: 0;
      background-color: ${theme.newColors.WhippedCream};
      justify-content: flex-end;

      svg {
        width: 95%;
        margin: auto auto;
      }

      .side-box-container {
        position: absolute;
        bottom: 10px;
      }

      .side-box-container > *:first-child {
        width: 620px;
        margin-right: 10px;
      }

      .full-screen-button {
        position: absolute;
        left: 20px;
        top: 20px;
        z-index: 100;
      }
    `:``}

`}`;

const PlotSvg = styled.svg`
  max-width: 100%;
  user-select: none !important;
  pointer-events: none !important;
`;

const FullScreenButton = styled.div.attrs({
  className: 'full-screen-button'
})`${({theme}) =>  `
  cursor: pointer;
  color: ${theme.newColors.DarkStar};
`}`;

const TmsiMonitorCellContainer = styled(Flex6).attrs({} as {
  fullScreen?: boolean
})`${({theme, fullScreen=false}) => `
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: space-around;
`}`;

const SideBoxContainer = styled(Flex6)`${() => `
  align-items: center;
  display: flex;
`}`;

const SideBox = styled.div`${({theme}) => `
  box-sizing: border-box;
  width: 635px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: ${theme.newColors.SwissCream};

  border: 1px solid ${theme.newColors.ShadowMode};
  border-radius: 4px;

  h2 {
    margin: 0px;
  }

  h2 + * {
    margin-top: 0.8rem
  }

  padding: ${theme.boxPadding.xl}px ${theme.boxPadding.xl3}px;
  background-color: rgba(${theme.colorsRgb.newColors.MidnightShores.toString()},0.90);

  > button {
    margin-top: 0.75rem;
  }
`}`;

const CellProviderColorBox = styled.span.attrs({} as {
  provider: string
})`${({theme, provider}) => `
  display: inline-block;
  margin-right: 0.625rem;
  border-radius: 2px;
  width: 1.125rem;
  height: 1.125rem;
  border: 1px solid ${theme.newColors.ShadowMode};
  background-color: ${newColors[providerToLabelName(provider)]};
`}`

const toZeroMargin = keyframes`
  0% {
    margin-top: -2.125rem;
    opacity: 0;
  }
  100% {
    margin-top: 0px;
    opacity: 100%;
  }
`;
const appear = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 100%;
  }
`
const RecentCellActivityList = styled.ul`${({theme}) => css`
  min-width: 100%;
  padding:0;
  list-style: none;
  margin: 0px;
  margin-top: 0.8rem;
  flex-grow: 1;
  min-height: 8.125rem;
  li {
    display: flex;
    align-items: center;
    padding: 0;
    text-wrap: nowrap !important;
    -webkit-text-size-adjust: 100%; /* Prevent font scaling in landscape while allowing user zoom */
  }
  li:not(:first-child) {
    margin-top: 0.625rem;
  }
  li {
    animation-name: ${appear};
    animation-duration: 1s;
  }
  li:first-child {
    animation-name: ${toZeroMargin};
    animation-duration: 1s;
  }
  .warn-label {
    background-color: ${theme.newColors.warning};
    color: ${theme.newColors.SunsetCrush};
    padding: 2px 5px;
    margin-top: -2px;
    margin-right: 0.5em;
    border-radius: 2px;
    font-weight: bold;
  }
  .warn-label path{
    stroke: none!important;
    fill: ${theme.newColors.SunsetCrush};
  }
  margin-bottom: ${theme.boxMargins.l}px;
  white-space: none;
`}`;

const NumberBox = styled.div`${({theme}) => `
  display: flex;
  flex-direction: row;
  color: ${theme.newColors.SwissCream};
  > div {
    margin-right: 8px;
  }
  .number {
    ${theme.fontStyles.largeTextDisplay}
  }

  
`}`;

const InfoFlexContainer = styled(FlexContainer)`${()=>`
  > * {
    padding-right: 5%;
  }
`}`;
