import React, {
  useRef,
  useState,
  useEffect,
  useCallback,
  useMemo,

  MouseEvent
} from 'react';

import {
  getAuth,

  signInWithPopup,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  sendEmailVerification,
  signOut,

  GoogleAuthProvider
} from 'firebase/auth';
import {
  BiErrorCircle
} from 'react-icons/bi';
import {
  FiCheckCircle
} from 'react-icons/fi';
import {
  MdOutlineKeyboardBackspace,
  MdOutlineEmail,
  MdOutlineMarkEmailUnread
} from 'react-icons/md';
import {
  VscUnverified
} from 'react-icons/vsc';
import {
  useLocation
} from 'react-router-dom';
import styled from 'styled-components';
import {
  
} from 'elements';

import {
  InputGroup,
  Button,
  Loading,
  FloatingForm,
  IconWrapper,
  SuccessMessage,
  InfoMessage,
  WarningMessage
} from 'components';
import {
  useAccountStore,
  useDevicesStore,
  useGlobalsContext, 
  useProfilesStore,
  useSensorsStore
} from 'context';
import {
  FlexContainer,
} from 'layout';
import {
  toCapitalCase,
  restrictors,
  validations,
  useValidator
} from 'utils/formValidator.hook';

// icons

import styles from './Auth.module.css';
import { useFirebaseStore } from 'store';

type Forms = 'login'|'forgot'; //|'email-verify'|'loading';
type LoginFormProps = {
  error?: string; // error to display in the header
}

export const AuthWrapper: React.FC = ({
  children
}) => {

  const [formMode, setFormMode] = useState<Forms|undefined>('login'); //'email-verify');

  const {user: fbUser, isUserLoaded: fbUserLoaded} = useFirebaseStore(s => s);
  const fbUserEmailVerified = fbUser?.emailVerified;

  const { userDataStatus } = useAccountStore(s => s)
  const allAccountDataLoaded = userDataStatus === 'success';
  const userLoadingDataError = userDataStatus === 'error';

  const {profilesDataStatus} = useProfilesStore(s => s)
  const profilesLoaded = profilesDataStatus === 'success';

  const {devicesDataStatus} = useDevicesStore(s => s)
  const devicesLoaded = devicesDataStatus === 'success';

  const {sensorsDataStatus} = useSensorsStore(s => s);
  const sensorsLoaded = sensorsDataStatus === 'success';
  const activeSensorLoaded = sensorsDataStatus === 'success';

  const {setGlobalLoaded} = useGlobalsContext();

  const location = useLocation();

  // select the things we rely on before loading page
  const usersDataLoaded = useMemo(() => 
    fbUserLoaded &&
    fbUser &&
    allAccountDataLoaded &&
    profilesLoaded &&
    devicesLoaded &&
    sensorsLoaded &&
    activeSensorLoaded,
  [
    fbUserLoaded,
    fbUser,
    allAccountDataLoaded,
    profilesLoaded,
    devicesLoaded,
    sensorsLoaded,
    activeSensorLoaded
  ]);

  useEffect(function updateGlobalUserDataLoadedVar(){
    setGlobalLoaded(!!usersDataLoaded);
  },[usersDataLoaded, setGlobalLoaded]);

  // logging
  useEffect(function checkIfUserDataLoaded(){
    if(allAccountDataLoaded){
      console.log('[Login] account data is loaded');
    }
    else if(userLoadingDataError){
      console.log('[Login] user loading error occured');
    }
  },[allAccountDataLoaded, userLoadingDataError]);

  useEffect(function(){
    console.log('[Login] fbUser changed, context:', {fbUser, fbUserLoaded, fbUserEmailVerified} );
  }, [fbUser, fbUserEmailVerified, fbUserLoaded]);

  useEffect(function(){
    if(profilesLoaded && devicesLoaded && sensorsLoaded && activeSensorLoaded){
      console.log('[Login] data is loaded');
    }
  }, [profilesLoaded, devicesLoaded, sensorsLoaded, activeSensorLoaded]);
  // Handle User Loading error
  useEffect(function kickOutOnError(){
    if(fbUser && userLoadingDataError){
      const auth = getAuth();
      signOut(auth);
    }
  },[userLoadingDataError, fbUser]);

  // Handle hash change
  useEffect(function navigateToForm(){
    const hash = (location.hash+'').replace('#', '');

    if(hash === 'signout'){
      // clear hash
      window.location.hash = '';
      const auth = getAuth();
      signOut(auth);
      return;
    }

    switch(hash){
      case 'forgot':
        setFormMode('forgot');
        break;
      case 'login':
        setFormMode('login');
        break;
      default:
        break;
    }

  },[location]);

  return (
    (usersDataLoaded)
    ?
    <>{children}</>
    :
    <FlexContainer className={styles.container}>
      {
      (fbUserLoaded && fbUser && !fbUserEmailVerified)
      ?
      <EmailConfirmation />
      :
      !fbUserLoaded || (fbUserLoaded && fbUser && !usersDataLoaded)
      ? 
      <div className={styles.loadingContainer}><Loading centered height={10} width={200} strokesSettings={{numberOfStrokes: 40, strokesSpaces: 45}} /></div>
      :
      formMode === 'forgot'
      ?
      <PasswordForm />
      :
      <LoginForm error={userLoadingDataError?"Error loading user's account, please sign in again":undefined} />
      }
    </FlexContainer>
  );
};


export const LoginForm: React.FC<LoginFormProps> = ({
  error
}) => {

  const [formError, setFormError] = useState<string|null>(error||null);
  const [loading, setLoading] = useState<boolean>(false);

  const formRef = useRef({});
  const formElRef = useRef<HTMLFormElement>(null);
  // list of fields to display in the form
  const fields = [
    {
      name: 'email',
      type: 'text'
    },
    {
      name: 'password',
      type: 'password',
      children: <a href="#forgot">Forgot your password?</a>
    },
  ];
  // this validates field on blur and adds results to validationsObj under fields name
  const [validationsObj, validatorApi] = useValidator(formRef,
    {
      email: validations.email,
      password: restrictors.required(new Error('Password field is required'))
    });

  // -- HANDLERS
  const handleSSO = useCallback( (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();

    const googleSSOProvider = new GoogleAuthProvider();
    //googleSSOProvider.addScope('https://www.googleapis.com/auth/contacts.readonly');
    const auth = getAuth();

    signInWithPopup(auth, googleSSOProvider).then((result) => {
      // This gives you a Google Access Token. You can use it to access the Google API.
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const token = credential?.accessToken;
      // The signed-in user info.
      const user = result.user;

      console.log('[login] sso success', credential, token, user);
      // ...
    }).catch((error) => {
      // Handle Errors here.
      const errorCode = error.code;
      const errorMessage = error.message;
      // The email of the user's account used.
      const email = error.customData.email;
      // The AuthCredential type that was used.
      const credential = GoogleAuthProvider.credentialFromError(error);
      // ...

      console.log('[login] sso error', errorCode, errorMessage, email, credential);

      if(error.code === 'auth/popup-closed-by-user'){
        // do nothing
      }
      else {
        setFormError(errorMessage);
      }
    });
  }, []);

  const handleSubmitForm = useCallback( (e: React.FormEvent) => {
    e.preventDefault();

    const isValid = validatorApi.validate();
    if(!isValid) {return;}

    setLoading(true);
    setFormError('');

    const auth = getAuth();
    const data = new FormData(formElRef.current||undefined);

    signInWithEmailAndPassword(auth, data.get('email') as string, data.get('password') as string)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        // ...
        console.log('[Login] email sign in success, user', user);

        setLoading(false);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.error('error', errorCode, errorMessage);

        // 'auth/user-not-found'
        // 'auth/invalid-email'
        // 'auth/wrong-password'

        setFormError('Username or password is incorrect');
        setLoading(false);
      });

    console.log('payload is', data, auth, data.get('email') as string, data.get('password') as string);

  }, [validatorApi]);

  return (
    <FloatingForm
      refference={formElRef}
      className={styles.loginForm+(formError===null?' '+styles.loginAppear:'')+(formError?' '+styles.hasError:'')}
      warnHeader={formError&&<><BiErrorCircle className={styles.headerIcon} /> Sign in error</>}
      onSubmit={handleSubmitForm}
      condenced
    >
      {formError ?
        <WarningMessage large>{formError}</WarningMessage> : null}
      <h2>Welcome to Signalwise</h2>
      {fields?.map(({name,type,children}) => (
        <InputGroup
          key={name}
          form={formRef}
          type={type}
          name={name}
          label={toCapitalCase(name)}
          placeholder={'Enter your '+name}
          status={validationsObj[name]}
          onChange={() => validationsObj[name] && validatorApi.validateField(name)}>

          {validatorApi.messages[name]?.length > 0 ? validatorApi.messages[name]?.map( (message) => {
              return <WarningMessage key={message}>{message}</WarningMessage>;
          }) : null}

          {children}
        </InputGroup>
      ))}

      <Button
        disabled={loading}
        loading={loading}
        variant="primary">Sign In</Button>

      <a href="#sso" onClick={handleSSO}>Sign in with SSO</a>
    </FloatingForm>

    );
};

export const PasswordForm: React.FC = () => {

  const [formError, setFormError] = useState<string|null>(null);
  const [formSuccess, setFormSuccess] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const formRef = useRef({});
  const formElRef = useRef<HTMLFormElement>(null);
  // list of fields to display in the form
  const fields = [
    {
      name: 'email',
      type: 'text',
      children: null
    }
  ];
  // this validates field on blur and adds results to validationsObj under fields name
  const [validationsObj, validatorApi] = useValidator(formRef,
    {
      email: validations.email
    });


  // -- HANDLERS
  const handleSSO = useCallback( (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    const googleSSOProvider = new GoogleAuthProvider();
    googleSSOProvider.addScope('https://www.googleapis.com/auth/contacts.readonly');
    const auth = getAuth();

    signInWithPopup(auth, googleSSOProvider).then((result) => {
      // This gives you a Google Access Token. You can use it to access the Google API.
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const token = credential?.accessToken;
      // The signed-in user info.
      const user = result.user;

      console.log('[login] sso success', credential, token, user);
      // ...
    }).catch((error) => {
      // Handle Errors here.
      const errorCode = error.code;
      const errorMessage = error.message;
      // The email of the user's account used.
      const email = error.customData.email;
      // The AuthCredential type that was used.
      const credential = GoogleAuthProvider.credentialFromError(error);
      // ...

      console.log('[login] sso error', errorCode, errorMessage, email, credential);
      setFormError(errorMessage);
    });
  }, []);

  const handleSubmitForm = useCallback( (e: React.FormEvent) => {
    e.preventDefault();

    const isValid = validatorApi.validate();
    if(!isValid) {return;}

    setLoading(true);
    setFormError('');

    const auth = getAuth();
    const data = new FormData(formElRef.current||undefined);

    sendPasswordResetEmail(auth, data.get('email') as string)
      .then(() => {
        // Password reset email sent!
        // ..
        console.log('[PasswordForm] Password reset email sent: ',data.get('email'));
        setFormSuccess(true);
        setLoading(false);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        // ..
        console.error('[PasswordForm] error sending email: ',errorCode, errorMessage);

        setFormSuccess(false);
        if(error.code === 'auth/network-request-failed'){
          setFormError('Error sending an email. Check your internet connection and try again');
        }
        else {
          // we are not voluntearing error codes
          setFormSuccess(true);
        }

        setLoading(false);
      });

  }, [validatorApi]);

  return (
    <FloatingForm
      refference={formElRef}
      className={styles.loginForm+(formError===null?' '+styles.loginAppear:'')+(formError?' '+styles.hasError:'')}
      warnHeader={formError&&<><BiErrorCircle className={styles.headerIcon} /> Error</>}
      successHeader={formSuccess&&<><MdOutlineMarkEmailUnread className={styles.headerIcon} /> Success</>}
      onSubmit={handleSubmitForm}
      condenced
    >
      {formError ?
        <WarningMessage large>{formError}</WarningMessage> : null}

      {!formSuccess
        ?
        <>
          {fields?.map(({name,type,children}) => (
            <InputGroup
              key={name}
              form={formRef}
              type={type}
              name={name}
              label={toCapitalCase(name)}
              placeholder={'Enter your '+name}
              status={validationsObj[name]}
              onChange={() => validationsObj[name] && validatorApi.validateField(name)}>

              {validatorApi.messages[name]?.length > 0 ? validatorApi.messages[name]?.map( (message) => {
                  return <WarningMessage key={message}>{message}</WarningMessage>;
              }) : null}

              {children}
            </InputGroup>
          ))}

          <Button
            disabled={loading}
            loading={loading}
            variant="primary">Reset Password</Button>

          <Button
            onClick={handleSSO}

            >Sign in with SSO</Button>

          <a href="#login">Back to login?</a>
        </>
        :
        <>
          <Centered>
            <IconWrapper type='success'>
              <FiCheckCircle />
            </IconWrapper>
          </Centered>

          <SuccessMessage>Email was sent if user exists</SuccessMessage>

          <Button
            variant="primary"
            onClick={() => {window.location.hash='#login';}}
            ><MdOutlineKeyboardBackspace className={styles.reactIcon}/> Back to Login</Button>

        </>
      }
    </FloatingForm>
  );
};

export const EmailConfirmation: React.FC = () => {

  const [formError, setFormError] = useState<string|null>(null);
  const [formSuccess, setFormSuccess] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmitForm = useCallback((e:React.FormEvent) => {
    e.preventDefault();

    const auth = getAuth();
    const currentUser = auth.currentUser;

    if(currentUser){
      setLoading(true);
      sendEmailVerification(currentUser)
        .then(() => {
          // Email verification sent!
          // ...
          console.log('[Login: EmailConfirmation] email verification sent');
          setLoading(false);
          setFormSuccess(true);
        });
    } else {
      setLoading(false);
      setFormError('Error sending an email. Try again or relogin.');
    }

  }, []);

  return (
    <FloatingForm
      className={styles.loginForm+(formError===null?' '+styles.loginAppear:'')+(formError?' '+styles.hasError:'')}
      warnHeader={formError&&<><BiErrorCircle className={styles.headerIcon} /> Error</>}
      infoHeader={<><VscUnverified className={styles.headerIcon} /> Email Verification</>}
      onSubmit={handleSubmitForm}
      condenced
      >

      {formError ?
        <WarningMessage large>{formError}</WarningMessage> : null}

      <Centered>
        <IconWrapper type={formSuccess ? 'success' : 'info'}>
          <MdOutlineEmail />
        </IconWrapper>
      </Centered>

      {!formSuccess
        ?
        <>
          <InfoMessage>Email verification required. Please check your email for a verification link.</InfoMessage>
          <Button
            disabled={loading}
            loading={loading}
            variant="primary">Resend Email</Button>
        </>
        :

        <InfoMessage>Verification email has been successfully sent just now. Please follow the link in the email and this page will update shortly.</InfoMessage>

      }

      <a href="#signout">Signout?</a>
    </FloatingForm>
  );
};

const Centered = styled.div`
  text-align: center;
`;

