import React from 'react';
import {IconProps} from './_iconProps';

export const Crosshair: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M29.3333 16C29.3333 23.3638 23.3638 29.3333 16 29.3333M29.3333 16C29.3333 8.63619 23.3638 2.66666 16 2.66666M29.3333 16H24M16 29.3333C8.6362 29.3333 2.66667 23.3638 2.66667 16M16 29.3333V24M2.66667 16C2.66667 8.63619 8.6362 2.66666 16 2.66666M2.66667 16H8M16 2.66666V7.99999" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</svg>
