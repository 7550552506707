import React from 'react';
import {IconProps} from './_iconProps';

export const Move: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.66666 12L2.66666 16M2.66666 16L6.66666 20M2.66666 16H29.3333M12 6.66667L16 2.66667M16 2.66667L20 6.66667M16 2.66667V29.3333M20 25.3333L16 29.3333M16 29.3333L12 25.3333M25.3333 12L29.3333 16M29.3333 16L25.3333 20" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</svg>
