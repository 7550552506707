import React from 'react';
import {IconProps} from './_iconProps';

export const File: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17.3333 2.66666H8.00001C7.29277 2.66666 6.61449 2.94761 6.11439 3.4477C5.61429 3.9478 5.33334 4.62608 5.33334 5.33332V26.6667C5.33334 27.3739 5.61429 28.0522 6.11439 28.5523C6.61449 29.0524 7.29277 29.3333 8.00001 29.3333H24C24.7073 29.3333 25.3855 29.0524 25.8856 28.5523C26.3857 28.0522 26.6667 27.3739 26.6667 26.6667V12M17.3333 2.66666L26.6667 12M17.3333 2.66666V12H26.6667" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</svg>
