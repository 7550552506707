import React from 'react';
import {IconProps} from './_iconProps';

export const Dribbble: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.4133 3.66666C17.24 11.7067 19.44 16.2267 22.12 27.2933M25.5067 6.78666C20.5467 12.5867 13.5867 14.3333 3 14.5867M29 17.12C24.3333 15.88 20.16 16.0267 17.08 17.12C13.64 18.3467 10.4 20.9333 7.16 25.5467M29.3333 16C29.3333 23.3638 23.3638 29.3333 16 29.3333C8.6362 29.3333 2.66667 23.3638 2.66667 16C2.66667 8.63619 8.6362 2.66666 16 2.66666C23.3638 2.66666 29.3333 8.63619 29.3333 16Z" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</svg>
