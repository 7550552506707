import React from 'react';
import {IconProps} from './_iconProps';

export const Truck: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M21.3333 21.3333V4H1.33333V21.3333H21.3333ZM21.3333 21.3333H30.6667V14.6667L26.6667 10.6667H21.3333V21.3333ZM10.6667 24.6667C10.6667 26.5076 9.17428 28 7.33333 28C5.49238 28 3.99999 26.5076 3.99999 24.6667C3.99999 22.8257 5.49238 21.3333 7.33333 21.3333C9.17428 21.3333 10.6667 22.8257 10.6667 24.6667ZM28 24.6667C28 26.5076 26.5076 28 24.6667 28C22.8257 28 21.3333 26.5076 21.3333 24.6667C21.3333 22.8257 22.8257 21.3333 24.6667 21.3333C26.5076 21.3333 28 22.8257 28 24.6667Z" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</svg>
