import React from 'react';
import {IconProps} from './_iconProps';

export const HelpCircle: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.12 12C12.4335 11.1089 13.0522 10.3575 13.8666 9.87884C14.681 9.40021 15.6385 9.22525 16.5696 9.38495C17.5006 9.54465 18.3451 10.0287 18.9534 10.7514C19.5618 11.474 19.8947 12.3887 19.8933 13.3333C19.8933 16 15.8933 17.3333 15.8933 17.3333M16 22.6667H16.0133M29.3333 16C29.3333 23.3638 23.3638 29.3333 16 29.3333C8.63619 29.3333 2.66666 23.3638 2.66666 16C2.66666 8.6362 8.63619 2.66666 16 2.66666C23.3638 2.66666 29.3333 8.6362 29.3333 16Z" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</svg>
