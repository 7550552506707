import { useStore } from 'zustand';

import {
    appVersionStore,
    AppVersionState,

    alertsStore,
    AlertsState,

    alertSettingsStore,
    AlertSettingsState,

    accountStore,
    AccountState,

    homeStore,
    HomeState,

    profilesStore,
    ProfilesState,

    devicesStore,
    DevicesState,
    
    sensorsStore,
    SensorsState,

    tellMeWhenStore,
    TellMeWhenState,
    
    edgeDaemonStore,
    EdgeDaemonState,
    
    WebSocketState,
    webSocketStore
} from '../store';

export const useAppVersionStore = <T>(selector?: (state: AppVersionState) => T) => {
    return useStore(appVersionStore, selector!);
};

export const useAlertsStore = <T>(selector?: (state: AlertsState) => T) => {
    return useStore(alertsStore, selector!);
};

export const useAlertSettingsStore = <T>(selector?: (state: AlertSettingsState) => T) => {
    return useStore(alertSettingsStore, selector!);
};

export const useAccountStore = <T>(selector?: (state: AccountState) => T) => {
    return useStore(accountStore, selector!);
};

export const useHomeStore = <T>(selector?: (state: HomeState) => T) => {
    return useStore(homeStore, selector!);
};

export const useProfilesStore = <T>(selector?: (state: ProfilesState) => T) => {
    return useStore(profilesStore, selector!);
};

export const useDevicesStore = <T>(selector?: (state: DevicesState) => T) => {
    return useStore(devicesStore, selector!);
};

export const useSensorsStore = <T>(selector?: (state: SensorsState) => T) => {
    return useStore(sensorsStore, selector!);
};

export const useTellMeWhenStore = <T>(selector?: (state: TellMeWhenState) => T) => {
    return useStore(tellMeWhenStore, selector!);
};

export const useEdgeDaemonStore = <T>(selector?: (state: EdgeDaemonState) => T) => {
    return useStore(edgeDaemonStore, selector!);
};

// export const useTimelineStore = <T>(selector?: (state: TimelineState) => T) => {
//     return useStore(timelineStore, selector!);
// };

export const useWebSocketStore = <T>(selector?: (state: WebSocketState) => T) => {
    return useStore(webSocketStore, selector!);
};