import {useState, useEffect, useRef} from 'react';
import {
  RawCellBucketItem,
  RawCellBucketQueryPayload,
  getRawCellItemsBucket,
  getRawCellScanItemsBucket
} from '@ubiety/fe-api'

export type useRawCellBucketProps = {
  source: 'cell'|'cell-scan',
  endTimestamp?: number
} & Omit<RawCellBucketQueryPayload,'endTimestamp'>;

export const useRawCellBucket = ({
  source='cell',
  sensorId,
  startTimestamp,
  endTimestamp,
  binSize='hour',
  limit=0,
  offset=0
}: useRawCellBucketProps) => {
  const instanceTimestamp = useRef(Date.now()).current;
  const [rawItems, setRawItems] = useState<Array<RawCellBucketItem>>([]);
  const [loading, setLoading] = useState(false);

  useEffect(function querryItems(){(async () => {
    const endT = endTimestamp||instanceTimestamp;
    const payload = {
      sensorId,
      startTimestamp,
      endTimestamp: endT,
      binSize,
      limit,
      offset
    };
    try {
      setLoading(true);
      switch(source){
        case 'cell':
          const cellResp = await getRawCellItemsBucket(payload);
          console.log('[useRawCellBucket] got cellResp', cellResp);
          setRawItems(cellResp.data.items)
          break;
        case 'cell-scan':
          const cellScanResp = await getRawCellScanItemsBucket(payload);
          console.log('[useRawCellBucket] got cellScanResp', cellScanResp);
          setRawItems(cellScanResp.data.items)
          break;
      }
    } catch (e){
      console.error('Error getting bucketed data', e);
    } finally {
      setLoading(false);
    }
  })()},[
    sensorId,
    startTimestamp,
    endTimestamp,
    binSize,
    limit,
    offset,
    instanceTimestamp,
    source
  ])

  return {
    loading,
    rawItems
  }
}