import { apiRequest } from "../apiRequest";
import { SubscribeNotificationPayload, SubscribeNotificationResponse } from "./types";

export const createNotificationSubscription = (homeId: number, payload: SubscribeNotificationPayload) =>
  apiRequest<SubscribeNotificationResponse>({
    service: 'feApiGateway',
    path: `homes/${homeId}/alerts/notification-subscriptions/push`,
    method: 'POST',
    data: payload
  });

export const deleteNotificationSubscription = (homeId: number, phoneId: string) =>
  apiRequest<string>({
    service: 'feApiGateway',
    path: `homes/${homeId}/alerts/notification-subscriptions/push/${phoneId}`,
    method: 'DELETE',
  });

export const getNotificationSubscription = (homeId: number, phoneId: string) =>
  apiRequest<SubscribeNotificationResponse>({
    service: 'feApiGateway',
    path: `homes/${homeId}/alerts/notification-subscriptions/push/${phoneId}`,
  });

export const getNotificationSubscriptions = (homeId: number) =>
  apiRequest<SubscribeNotificationResponse>({
    service: 'feApiGateway',
    path: `homes/${homeId}/alerts/notification-subscriptions/push`,
  });
