import {
  apiRequest
} from '../apiRequest';

export type ServerTimestampData = {
  clock_milliseconds: number;
  clock_seconds: number;
};
export const getServerTimestamp = () =>
  apiRequest<ServerTimestampData>({
    service: 'dataAPI',
    path: '/api/clock',
    method: 'GET',
  });

// export type AppVersionData = {
//   uid?: string;
//   current_version?: string;
//   minimum_version?: string;
// };
// export const getAppVersion = () =>
//   apiRequest<AppVersionData>({
//     service: 'dataAPI',
//     path: '/api/version/1',
//     method: 'GET',
//     withAuth: false 
//   });

  export type AppVersionData = {
    current_version?: string;
    minimum_version?: string;
  };
  
  export const getAppVersion = () =>
    apiRequest<AppVersionData>({
      service: 'feApiGateway',
      path: 'app/version',
      method: 'GET',
      withAuth: false 
    });