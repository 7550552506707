import React from 'react';
import {IconProps} from './_iconProps';

export const Voicemail: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.33334 21.3333C10.6471 21.3333 13.3333 18.647 13.3333 15.3333C13.3333 12.0196 10.6471 9.33331 7.33334 9.33331C4.01963 9.33331 1.33334 12.0196 1.33334 15.3333C1.33334 18.647 4.01963 21.3333 7.33334 21.3333ZM7.33334 21.3333H24.6667M24.6667 21.3333C27.9804 21.3333 30.6667 18.647 30.6667 15.3333C30.6667 12.0196 27.9804 9.33331 24.6667 9.33331C21.353 9.33331 18.6667 12.0196 18.6667 15.3333C18.6667 18.647 21.353 21.3333 24.6667 21.3333Z" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</svg>
