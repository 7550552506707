import {useEffect, useState, useRef, useMemo} from 'react';

import {
  scaleTime,
  axisBottom,
  select,
} from 'd3';
import styled from 'styled-components';

// NOTE: ALL items are mutable since processed from raw server reply!
export type ItemsInTimeGraphProps = {
  scroll?: boolean;
  className?: string;
  data: {
    items: {date: Date, color: string}[];
    value: string;
    label: string;
  }[];
  startDate: Date,
  endDate: Date,
  width?: number;
  minHeight?: number;
  timeaxisHeight?: number;
  labelColWidth?: number;
  padding?: {
    top: number,
    right: number,
    bottom: number,
    left: number
  };

  numberOfHorizontalTicks?: number;

  gridCellWidth?: number;
  //gridCellHeight?: number;
  marksR?: number;

  lineHeigth?: number;

  label?: string;

  axisLabels?: Array<{
    label: string,
    position?: {
      x: number
    }
    value?: number
  }>
  showNow?: boolean;
}
export const ItemsInTimeGraph: React.FC<ItemsInTimeGraphProps> = ({
  scroll,
  className,
  data: passedData,
  startDate:passedStartDate = new Date(),
  endDate: passedEndDate,
  width = 700,
  minHeight = 300,
  timeaxisHeight = 10,
  labelColWidth = 120,
  padding = {
    top: 10,
    right: 10,
    bottom: 0,
    left: 0
  },
  //gridCellHeight=50,
  marksR=3,

  lineHeigth=20,

  numberOfHorizontalTicks=3,
  children,
  label,
  axisLabels=[],
  showNow
}) => {
  const svgRef = useRef<SVGSVGElement>(null);
  const overlaySvgRef = useRef<SVGSVGElement>(null);
  const graphWidth = useMemo(() => width-padding.left-padding.right-labelColWidth, [width, padding, labelColWidth]);

  const dateExtent = useMemo(() => {
    return [passedStartDate, passedEndDate];
  },[passedStartDate, passedEndDate]);
  const dateScaler = useMemo(() => scaleTime()
    .domain(dateExtent)
    .range([0, graphWidth])
  ,[dateExtent, graphWidth]);

  const [scale, setScale] = useState<number>(1);

  useEffect(function bindScaleUpdate(){
    let timeout: any;

    function updateScale(){
      clearTimeout(timeout);
      timeout = setTimeout(function(){
        if(!svgRef.current){ return; }
        const newScale = svgRef.current.getBoundingClientRect().width/width;
        setScale(newScale);
      },500);
    }
    window.addEventListener('resize', updateScale);
    updateScale();

    return function(){
      clearTimeout(timeout);
      window.removeEventListener('resize', updateScale);
    };
  },[setScale, width]);

  const height = useMemo(() => {
    return Math.max(Number((passedData.length+0.5)*lineHeigth), minHeight);
  },[
    passedData,
    lineHeigth,
    minHeight
  ]);

  useEffect(function drawDataOnPlot(){
    const graphWidth = width-padding.left-labelColWidth-padding.right;

    const group = select(svgRef.current)
      .selectAll('g.dataPlot')
      .data(['one'])
      .join(
        enter => enter.append('g').attr('class', 'dataPlot'),
        update => update,
        exit => exit.remove()
      )
      .attr('style', `transform:translate(${padding.left+labelColWidth}px,${padding.top}px)`);

    const row = group
      .selectAll('g.row')
      .data(passedData) // remove data on loading
      .join(
        enter => {
          const g = enter
            .append('g')
            .attr('class', 'row');

          g
            .append('text')
            .attr('class', 'tmsi');

          g
            .append('text')
            .attr('class', 'dbm');

          g
            .append('line');

          g
            .append('rect')
            .attr('height', '1em')
            .attr('y', '-0.5em')
            .attr('width', graphWidth)
            .attr('opacity', '0');

          return g;
        },
        update => update,
        exit => exit.remove()
      )
      // this is verticallly align to middle
      .attr('style', (_, i) => `transform:translate(0px,${((i+1)-0.5/*vertically align to middle*/)*lineHeigth}px);`);

    // update label value
    row
      .select('text')
      .attr('dominant-baseline', 'middle')
      .attr('fill', 'currentColor');

    row
      .select('text.tmsi')
      .attr('x', -labelColWidth)
      .attr('text-anchor', 'start')
      .html(d => d.value);

    row
      .selectAll('circle')
      .data(d => d.items)
      .join(
        enter => enter.append('circle'),
        update => update,
        exit => exit.remove()
      )
      .attr('r', marksR)
      .attr('fill', 'currentColor')
      .attr('cy', '0')
      .attr('cx', d => dateScaler(d.date))
      .attr('color', d => d.color);

    row
      .select('line')
      .attr('x1', '-0.5em')
      .attr('x2', graphWidth)
      .attr('stroke', 'currentColor')
      .attr('stroke-width', '2')
      .attr('opacity', 0.2);


  },[
    width,
    passedData,
    dateScaler,
    graphWidth,
    marksR,
    lineHeigth,
    labelColWidth,
    padding
  ]);

  useEffect(function drawAxis(){
    if(!svgRef.current){ return; }

    //const height
    const axisX = axisBottom(dateScaler)
      .ticks( numberOfHorizontalTicks );


    if(!overlaySvgRef.current){ return; }

    select(overlaySvgRef.current)
      .selectAll('g.axisX')
      .data(['one'])
      .join(
        enter => enter.append('g').attr('class','axisX'),
        update => update,
        exit => {
          exit.remove();
        }
      )
      .attr('style',`transform: translate(${labelColWidth+padding.left}px, ${padding.top}px)`);

    if(axisLabels?.length){
      select(overlaySvgRef.current)
        .select('g.axisX')
        .selectAll('text')
        .data(axisLabels)
        .join(
          enter => enter.append('text'),
          update => update,
          exit => {
            exit.remove();
          }
        )
        .attr('text-anchor', d => (!isNaN(d?.position?.x as any)||d?.position?.x) ? 'left' : 'middle')
        .attr('alignment-baseline', 'top')
        .attr('x', d => !isNaN(d?.position?.x as any) ? (d?.position?.x as any) : dateScaler(d.value||0))
        .attr('y', '1em')
        .text(d => d?.label||'');

    } else {
      select(overlaySvgRef.current)
        .select('g.axisX')
        .selectAll()
        .remove();

      select(overlaySvgRef.current)
        .select('g.axisX')
        .append('g')
        .call(axisX) 
    }

  },[
    height,
    width,
    padding,
    dateScaler,
    dateExtent,
    scale,
    labelColWidth,
    numberOfHorizontalTicks,
    passedData,
    axisLabels
  ]);

  return <StyledItemInTimeGraph className={'itemInTimeGraph '+(className||'')+(scroll ? ' with-scroll' : '')} height={`${minHeight}px`}>
    <div className="scrollField" style={{bottom: `${timeaxisHeight}px`}}>
      <svg className="canvas" ref={svgRef} width="100%" height={height*(scale<1?scale:1)} viewBox={`0 0 ${width} ${height}`} preserveAspectRatio="xMidYMin meet">
        {children}
      </svg>
    </div>
    <svg className="fixedOverlay" ref={overlaySvgRef} width="100%" height={timeaxisHeight} viewBox={`0 0 ${width} ${timeaxisHeight}`} preserveAspectRatio="xMidYMin meet" overflow="visible">
      {showNow ?
      <>
        <line 
          className="now-label-line" 
          y1={0} 
          y2={-minHeight*2+timeaxisHeight} 
          x1={dateScaler(new Date(Date.now()))+padding.left+labelColWidth}
          x2={dateScaler(new Date(Date.now()))+padding.left+labelColWidth} 
          strokeWidth="2" />
        <rect className="now-label" y="-5px" x={dateScaler(new Date(Date.now()))+padding.left+labelColWidth - 25} width="50px" height="30px" rx="3" ry="3" />
        <text textAnchor="middle" className="now-label-text" y="1em" x={dateScaler(new Date(Date.now()))+padding.left+labelColWidth}>NOW</text>    
      </>
      : null}
      </svg>
  </StyledItemInTimeGraph>;
};

const StyledItemInTimeGraph = styled.div.attrs({} as {
  height: string;
})`${({theme, height}) => `
  height: ${height};
  position: relative;
  overflow: hidden;

  .scrollField{
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    padding: 0;
    margin: auto;
  }

  &.with-scroll .scrollField {
    overflow-x: scroll !important;

    ::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 7px;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: rgba(0, 0, 0, .5);
      box-shadow: 0 0 1px rgba(255, 255, 255, .5);
    }
    ::-webkit-scrollbar-corner,
    ::-webkit-scrollbar-track-piece {
      background-color: none;
    }
  }

  .fixedOverlay {
    position: absolute;
    bottom: 0;
    left:0;
  }

  .axis {
    display: none;
  }
  .sub {
    opacity: 0.3
  }

  text {
    color: ${theme.newColors.DarkStar};
    ${theme.fontStyles.field}
  }

  svg.canvas {
    min-height: 100%;
  }

  .label {
    position: absolute;
    transform: rotate(-90deg);
  }

  svg .row:hover .dbm {
    opacity: 1;
  }

  svg .row:hover line {
    opacity: 0.5;
  }

  line {
    color: ${theme.newColors.MidnightFoam};
    opacity: 1 !important;
  }

  .axisX path,
  .axisX line {
    display: none;
  }

  .now-label {
    fill: ${theme.newColors.MidnightShores};
  }
  .now-label-text {
    fill: ${theme.newColors.SwissCream};
    font-weight: bold;
  }
  .now-label-line {
    stroke: ${theme.newColors.MidnightShores};
  }

`}`;
