export type Maybe<T> = T | null;
export type ValueOf<T> = T[keyof T];
export type PromiseVoid = Promise<void>;
// Context:
// - https://github.com/microsoft/TypeScript/issues/16069
// - https://github.com/robertmassaioli/ts-is-present
//
export function isPresent<T>(t: T | undefined | null | void): t is T {
  return t !== undefined && t !== null;
}

export type DataStatus = 'success' | 'error' | undefined;

export * from './alerts';
export * from './device';
export * from './home';
export * from './profile';
export * from './sensor';
export * from './tell_me_when';
export * from './user';