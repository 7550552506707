import React from 'react';
import {IconProps} from './_iconProps';

export const UserMinus: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M21.3333 28V25.3333C21.3333 23.9188 20.7714 22.5623 19.7712 21.5621C18.771 20.5619 17.4145 20 16 20H6.66667C5.25218 20 3.89563 20.5619 2.89543 21.5621C1.89524 22.5623 1.33334 23.9188 1.33334 25.3333V28M30.6667 14.6667H22.6667M16.6667 9.33333C16.6667 12.2789 14.2789 14.6667 11.3333 14.6667C8.38782 14.6667 6 12.2789 6 9.33333C6 6.38781 8.38782 4 11.3333 4C14.2789 4 16.6667 6.38781 16.6667 9.33333Z" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</svg>
