import React from 'react';
import {IconProps} from './_iconProps';

export const CornerLeftDown: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M18.6667 20L12 26.6666M12 26.6666L5.33333 20M12 26.6666V10.6666C12 9.25216 12.5619 7.8956 13.5621 6.89541C14.5623 5.89522 15.9188 5.33331 17.3333 5.33331H26.6667" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</svg>
