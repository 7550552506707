import { apiRequest } from '../apiRequest';

export type logIndexItem = {
  homeId: number,
  userEmail: string
  timeStart: number, // in millisecond
  timeEnd:  number, // in milliseconds
  files: Array<string>,
  expirationTime: number, // in milliseconds
  createdAt: number, // in milliseconds
  uid: string,
  deviceUid?: string,
  manufacturer?: string
}

export type getLogsIndexQueryParams = {
  homeId: string // optional
  userEmail?: string // optional
  timeStart?: number // optional
  timeEnd?: number // optional
  count?: number // optional
  offset?: number // optional
}
export type getLogsIndexQueryResponse = {
  nextKey: string|null,
  logEntries: Array<logIndexItem>
}

type logsUploadLinksResponse = {
  [key: string]: {
    filename: string,
    get: string,
    put: string
  }
}

export const getLogsIndexQuery = (params: getLogsIndexQueryParams) => 
  apiRequest<getLogsIndexQueryResponse>({
    service: 'logsLambda',
    path: '/app-logs',
    method: 'GET',
    params
  });

export const getLogsUploadLinks = (params: {
  filenames: Array<string>;
}) => 
  apiRequest<logsUploadLinksResponse>({
    service: 'logsLambda',
    path: '/upload-link/app-logs',
    method: 'GET',
    params
  });

type saveLogsIndexPayload = Pick<logIndexItem, 'userEmail'|'homeId'|'uid'|'timeStart'|'timeEnd'|'files'> & Partial<logIndexItem>;
export const saveLogsIndex = (payload:saveLogsIndexPayload) => 
  apiRequest<logIndexItem>({
    service: 'logsLambda',
    path: '/app-logs',
    method: 'POST',
    data: payload
  });