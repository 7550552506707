import styled from 'styled-components';

export const WidthContainer = styled.div.attrs({
  className: 'width-container'
})`${({theme}) => `
  display: flex;
  margin: 0 auto;
  flex: 1;
  max-width: 1400px;
  padding: 0 ${theme.boxPadding.xl2}px;
  box-sizing: border-box;
`}`;