import styled from 'styled-components';
import {
  DataTable
} from 'components/DataTable';
import {css, keyframes} from 'styled-components';
import {colorsRgb} from 'theme';

const fadeHightlight = keyframes`
  0% {
    background-color: rgba(${colorsRgb.newColors.GreenJuice.toString()},0);
  }
  5% {
    background-color: rgba(${colorsRgb.newColors.GreenJuice.toString()},0.3);
  }
  100% {
    background-color: rgba(${colorsRgb.newColors.GreenJuice.toString()},0);
  }
`
export const ThemedDataTable = styled(DataTable)`${({theme}) => css`
  th {
    padding: ${theme.boxPadding.base*0.8}px ${theme.boxPadding.base}px;
    text-align: left;
    border-bottom: 2px solid ${theme.newColors.MidnightFoam};
    background-color: ${theme.newColors.OrangeCream};
  }

  border-spacing: 0;
  td {
    padding: ${theme.boxPadding.base*0.8}px;
    border-bottom: 1px solid ${theme.newColors.MidnightFoam};
    text-align: left;
  }

  .new-item {
    animation: 5s ${fadeHightlight};
  }
`}`;