import React, {useEffect, useState, useRef, useCallback} from 'react';
import {
  Button, ThemedDataTable
} from 'components';
import {
  ForensicReportCreationForm
} from 'elements';
import {
  useNewReportsContext,
  useOverlayContext
} from 'context';
import styled from 'styled-components';
import {
  format
} from 'date-fns';
import {
  NavLink
} from 'react-router-dom';
import {
  offsetHomeSpecificTimestamp
} from 'utils';
import {
  HelpCircle,
  Download
} from 'theme/icons';

export const ForensicReportList: React.FC = () => {
  const { reportsIndex } = useNewReportsContext();
  const { showPopup } = useOverlayContext();

  const indexedItemsMap = useRef<any>(null);
  const [classeMap, setClassesMap] = useState(new Map());

  useEffect(function createNewClassesMap(){
    const indexed = indexedItemsMap.current||new Map();
    const newClassesMap = new Map();
    reportsIndex?.forEach(ind => {
      if(!indexed.get(ind.uid)){
        newClassesMap.set(ind, 'new-item');
      }
      indexed.set(ind.uid, true);
    });
    // first run -> do nothin
    if(!indexedItemsMap.current && reportsIndex.length){
      indexedItemsMap.current = indexed;
    } else {
      setClassesMap(newClassesMap);
    }
    
  },[
    reportsIndex
  ]);

  const handleCreateReportClick = useCallback(() => showPopup({
    children: <ForensicReportCreationForm />
  }),[showPopup])

  return <Container>
    <h1>Forensic Report Archive</h1>
    <h3>Forensic reports  previously requested and created</h3>

    <Button
      variant='primary'
      className="new-report-button"
      onClick={handleCreateReportClick}
      >Create New Forensic Report</Button>

    { !reportsIndex.length 
      ?
      <div className="empty-state">
        <h1>You haven’t created any reports yet</h1>
        <h3>Once you have created a report, you’ll be able to see and access the report from here.</h3>
        <Button variant='secondary' onClick={handleCreateReportClick}>Create Your First Forensic Report</Button>
      </div>
      :
      <>
        <div className="quicklinks">
          <a onClick={() => showPopup({
            dismissable: true,
            children: <ForesnicReportFAQ />
          })} href="#forensic-report-faq">Forensic Report FAQ’s <HelpCircle /></a>
          <a href="/downloads/tmsi_law_enforcement_guide.pdf" target="_blank" download="" rel="noreferrer">Law Enforcement  guide <Download /></a>
        </div>

        <ThemedDataTable 
          data={reportsIndex.sort((a,b) =>a.createdAt < b.createdAt ? 1 : -1)}
          index={['createdAt', 'createdBy', 'reportTime']}
          mixinIndex={['actions']}
          dataFormating={{
            createdAt: v => v && format(v, 'MMMM do h:mm a'),
            createdBy: v => v,
            reportTime: v => v && format(offsetHomeSpecificTimestamp(v), 'MMMM do h:mm a'),
            actions: (_,it) => <NavLink to={it.uid}>View Report</NavLink>
          }}
          titlesFormatting={{
            createdAt: () => 'Date Created',
            createdBy: () => 'Created By',
            reportTime: () => 'Event Time',
            actions: () => '',
          }}
          classesMap={classeMap}
          />
      </>
    }
    
  </Container>
}

const ForesnicReportFAQ = styled(({className}:{className?:string}) => (
  <div className={className}>
    <h2>Forensic Report FAQ</h2>
    <h2>What is a TMSI?</h2>
    <p>TMSI stands for Temporary Mobile Subscriber Identity. It allows your cellular device to access a cellular network.</p>
    <p>Similar to how WiFi networks utilize IP addresses, cellular network providers use TMSIs to manage traffic coming from many devices at once.</p>
    <h2>What is a  Forensic Report for?</h2>
    <p>You can hand this report to law enforcement officials in the event of a burglary. This can potentially aid in an investigation because it can lead to a real person.</p> 
    <h2>What will happen after it goes to law enforcement?</h2>
    <p>Law enforcement can subpoena wireless carriers to obtain subscriber information (i.e. the phone’s owner) associated with a particular TMSI. Through law enforcement process, a TMSI therefore can be associated with a specific person.</p>
  </div>
))`
  max-width: 330px;
`;

const Container = styled.div`${({theme}) => `
  position: relative;

  h3 {
    margin-bottom: ${theme.boxMargins.l*1.07}px;
  }

  .quicklinks {
    margin-bottom: ${theme.boxMargins.l*1.2}px;
  }

  .new-report-button {
    position: absolute;
    right:0;
    top: 0;
  }

  .data-table {
    width: 100%
  }

  .quicklinks a:not(:first-child){
    margin-left: ${theme.boxMargins.xl3}px;
  }

  .empty-state {
    text-align: center;
    background-color: ${theme.newColors.OrangeCream};
    padding: ${theme.boxPadding.xl5}px ${theme.boxPadding.xl7}px ${theme.boxPadding.xl6}px;
    max-width: 900px;
    margin: ${theme.boxMargins.xl10}px auto 0;
    border-radius: 3px;
  }
  .empty-state button {
    background-color: ${theme.newColors.WhippedCream};
    margin-top: ${theme.boxMargins.base}px;
  }
`}`;