import { RawSensor, getSensorHealth, getSensors, deleteSensor } from '@ubiety/fe-api';
import { createStore } from 'zustand/vanilla';

// import { persist } from 'zustand/middleware';
import { getHomeId, getIsHomeLoaded } from './selectors';
import { ContextError, ErrorCodes, FnQueueWithRetries } from '../classes';
import { fnSilentWrapper, processRawSensorArray, withCatcher , getListAllData } from '../helpers';
import { DataStatus, Maybe, PromiseVoid, RawSensorWithHealth, Sensor } from '../types';

interface ISensorsState {
  sensors: Record<string, Sensor | undefined>;
  sensorArray: Array<Sensor>;
  activeSensors: Array<Sensor>;
  activeSensor: Sensor | undefined;
  sensorsDataStatus: DataStatus;
}

type SensorsAction = {
  reset: () => void;
  getSensors: () => PromiseVoid;
  getSensorsSilently: () => Promise<Maybe<boolean>>;
  deleteSensor: (homeId: number, sensorId: number) => PromiseVoid;
};

export type SensorsState = ISensorsState & SensorsAction;

const INITIAL_STATE: ISensorsState = {
  sensors: {},
  sensorArray: [],
  activeSensors: [],
  activeSensor: undefined,
  sensorsDataStatus: undefined,
};

const sensorsQueue = new FnQueueWithRetries();

export const sensorsStore = createStore<SensorsState>()(
  // persist(
    (set, get) => ({
      ...INITIAL_STATE,
      reset: () => set(INITIAL_STATE),
      getSensors: withCatcher('SensorsStore.getSensors', async () => {
        const homeId = getHomeId();

        const isHomeLoaded = getIsHomeLoaded();
        if (!isHomeLoaded) {
          throw new ContextError('home is not yet loaded', {
            isLocal: true,
            errorCode: ErrorCodes.inconclusive // wait until home is loaded and dont set error or success
          });
        }

        if (!homeId) {
          throw new ContextError('there is no homeId to get sensors', {isLocal: true});
        }

        const sensorsResponse = await getListAllData<typeof getSensors, RawSensor>({ fn: getSensors, payload: {homeId: homeId}, queque: sensorsQueue }) as Array<RawSensor>;
        const rawSensorsData = new Array<RawSensorWithHealth>();
        await Promise.all((Array.isArray(sensorsResponse) ? sensorsResponse : []).map(async (s) => {
          if(s.active){
            // TODO rate limit sensor health for all active sensors
            const sensorHealthResponse = await getSensorHealth(homeId, s.uid);
            const sensorHealthData = sensorHealthResponse.data;
            rawSensorsData.push({ ...s, sensor_health: sensorHealthData });
          } else {
            rawSensorsData.push({ ...s });
          }
        }));

        if (rawSensorsData?.length) {
          const {
            newActiveSensor,
            newSensorArray,
            newSensors
          } = processRawSensorArray(rawSensorsData);

          set({
            sensors: newSensors,
            activeSensor: newActiveSensor,
            activeSensors: newSensorArray.filter(s => s.active),
            sensorArray: newSensorArray
          });

          console.log('[SensorsStore] sensors loaded and processed');
        } else {
          console.log('[SensorsStore] rawSensorArray is empty: no sensors');
        }

        set({ sensorsDataStatus: 'success' });
      }, (e) => {
          // errorCode 1 -> wait until home is loaded
          if (e.errorCode !== ErrorCodes.inconclusive) {
            set({ sensorsDataStatus: e.isLocal ? 'success' : 'error' });
          }
      }),
      getSensorsSilently: fnSilentWrapper(() => get().getSensors()),
      deleteSensor: withCatcher('SensorStore.deleteSensor', async (homeId, sensorId) => {
        await deleteSensor(homeId, sensorId);
        const {getSensors} = get(); 
        await getSensors();
      }),
    }),
  //   {
  //     name: 'sensors-storage',
  //   },
  // ),
);

