import React, {useState} from 'react';

export const GlobalsContext = React.createContext({
  globalLoaded: false,
  setGlobalLoaded: (v: boolean) => {void ''},
  showNetworkActivity: false,
  setShowNetworkActivity: (v: boolean) => {void ''}
});
export const useGlobalsContext = () => React.useContext(GlobalsContext);

export const GlobalsContextProvider: React.FC = ({
  children
}) => {
  const [globalLoaded, setGlobalLoaded] = useState(false);
  const [showNetworkActivity, setShowNetworkActivity] = useState(false);
  
  return <GlobalsContext.Provider value={{
    globalLoaded,
    setGlobalLoaded,
    showNetworkActivity,
    setShowNetworkActivity
  }}>{children}</GlobalsContext.Provider>
}