import {useEffect} from 'react';

import {
    Listeners,
  Relay
} from '../classes';
import { 
  useAccountStore, 
  useAlertSettingsStore, 
  useAlertsStore, 
  useDevicesStore, 
  useEdgeDaemonStore, 
  useHomeStore, 
  useProfilesStore, 
  useSensorsStore, 
  useTellMeWhenStore,
  useWebSocketStore
} from '../hooks/allStores';

const relay = new Relay();

export const contextUpdatesControllerUpdateAllListeners = new Listeners();
export const useContextUpdatesController = () => {
  const { getHome } = useHomeStore((s) => s);
  const { getProfiles, profileArray, getProfilesPresence } = useProfilesStore((s) => s);
  const { getDevices, deviceArray, getDevicesPresence } = useDevicesStore((s) => s);
  const { getSensors } = useSensorsStore((s) => s);
  const { getUser } = useAccountStore((s) => s);

  const { getAllTellMeWhenSuspicious } = useTellMeWhenStore((s) => s);
  const { getHomeDaemons } = useEdgeDaemonStore((s) => s);
  const { getAllAlertSettings } = useAlertSettingsStore((s) => s);
  const { onMessage } = useWebSocketStore(s => s);

  const {
    getUnreadAlerts,
    getNewerPaginatedAlerts
  } = useAlertsStore((s) => s);
  
  // dispatch update calls
  useEffect(function subscribeMainSwitchEvent(){
    const remove = onMessage.addListener(async function(data){
      const {
        //resourceId,
        resource
      } = data;

      console.log('[useContextUpdatesController] whoa, something changed:', {data});
      // TODO: it feels like we should not be throwing anythng beyond context,
      // so that these can be freely used in components and screens
      // right now it causes type conflict, so we need to try catch again
      // my sugestion would be to try catch in context and return a successs boolean instead
      try {
        switch(resource){
          case 'account':
            relay.handle('getUser', getUser);
            break;
          case 'home':
            relay.handle('getHome', getHome);
            break;
          case 'profile':
            relay.handle('getProfiles', getProfiles);
            relay.handle('getProfilesPresence', getProfilesPresence);
            break;
          case 'sensor':
            relay.handle('getSensors', getSensors);
            break;
          case 'alert':
            relay.handle('alert', () => {
              console.log('[useContextUpdatesController] handling alerts update call');
              relay.handle('getUnreadAlerts', getUnreadAlerts);
              relay.handle('getNewerPaginatedAlerts', getNewerPaginatedAlerts);
            });
            break;
          case 'device':
          case 'device_enter_exit':
            relay.handle('getDevices', getDevices);
            // to do, this should probably only change on a separate call
            relay.handle('getProfilesPresence', getProfilesPresence);
            relay.handle('getDevicesPresence', getDevicesPresence);
            break;
          case 'notification_preferences':
            relay.handle('getAllAlertSettings', getAllAlertSettings);
            break;
          default:
            // do nothin'
            break;
        }
      } catch (e) {
        console.log('[useContextUpdatesController] Error trying to update:', {e});
      }
    });

    return remove;
  },[
    getAllTellMeWhenSuspicious,
    getUser,
    getUnreadAlerts,
    getHome,
    getProfiles,
    getProfilesPresence,
    getDevices,
    getDevicesPresence,
    getSensors,
    getAllAlertSettings,
    profileArray,
    // getAllTellMeWhenSchedule,
    getNewerPaginatedAlerts,
    getHomeDaemons,
    deviceArray,
    onMessage
  ]);

  // subsribe to update all callout
  useEffect(() => contextUpdatesControllerUpdateAllListeners.addListener(() => {
    relay.handle('getUsersAccount', getUser);
    relay.handle('getHome', getHome);
    relay.handle('getProfiles', getProfiles);
    relay.handle('getSensors', getSensors);
    relay.handle('alert', () => {
      console.log('[useContextUpdatesController] handling alerts update call');
      relay.handle('getUnreadAlerts', getUnreadAlerts);
      relay.handle('getNewerPaginatedAlerts', getNewerPaginatedAlerts);
    });
    relay.handle('getDevices', getDevices);
    relay.handle('getProfilesPresence', getProfilesPresence);
    relay.handle('getProfilesPresence', getProfilesPresence);
    relay.handle('getDevicesPresence', getDevicesPresence);
    relay.handle('getAllAlertSettings', getAllAlertSettings);
    relay.handle('getHomeDaemons', getHomeDaemons);
  }), [
    getAllAlertSettings,
    getDevices,
    getDevicesPresence,
    getHome,
    getHomeDaemons,
    getNewerPaginatedAlerts,
    getProfiles,
    getProfilesPresence,
    getSensors,
    getUnreadAlerts,
    getUser
  ]);
};