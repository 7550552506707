import React from 'react';
import {IconProps} from './_iconProps';

export const BellOff: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_149_185)">
<path d="M18.3067 28C18.0723 28.4041 17.7358 28.7395 17.331 28.9727C16.9262 29.2059 16.4672 29.3286 16 29.3286C15.5328 29.3286 15.0739 29.2059 14.669 28.9727C14.2642 28.7395 13.9278 28.4041 13.6933 28M24.84 17.3333C24.2468 15.1619 23.964 12.9174 24 10.6666C24.0022 9.2174 23.6106 7.79479 22.8671 6.55077C22.1237 5.30675 21.0562 4.28806 19.7788 3.6035C18.5015 2.91894 17.0621 2.59424 15.6145 2.66407C14.167 2.7339 12.7656 3.19564 11.56 3.99998M8.34668 8.34665C8.11503 9.09813 7.99816 9.88027 8.00001 10.6666C8.00001 20 4.00001 22.6666 4.00001 22.6666H22.6667M1.33334 1.33331L30.6667 30.6666" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</g>
<defs>
<clipPath id="clip0_149_185">
<rect width={width} height={height} fill="white"/>
</clipPath>
</defs>
</svg>
