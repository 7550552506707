import styled from 'styled-components';

import {UID} from 'utils';

export type CheckboxProps = {
  disabled?: boolean,
  checked?: boolean,
  defaultChecked?: boolean|undefined,
  id?: string,
  el?: React.MutableRefObject<HTMLInputElement>,
  name?: string,
  placeholder?: string,
  className?: string,
  value?: string,
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void,
  variant?:'custom'|'normal'
}

export const Checkbox: React.FC<CheckboxProps> = ({
  disabled=false,
  checked,
  defaultChecked=undefined,
  id=UID(),
  el=null,
  name,
  className,
  value,
  onChange,
  variant='normal'
}) => {

  const checkbox = <StyledInput
      id={id}
      ref={el}
      key={id}
      type="checkbox"
      name={name}
      checked={checked}
      defaultChecked={defaultChecked}
      onChange={onChange}
      className={className}
      value={value}
      disabled={disabled}
      />;

  return variant === 'custom'
    ?
    <StyledLabel className="input-holder">{checkbox}<svg className="checkmark" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10 3L4.5 8.5L2 6" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
    </StyledLabel>

    :
    checkbox;
};

const StyledInput = styled.input`${({theme}) => `
  width: ${theme.fontSize.xl2}px;
  height: ${theme.fontSize.xl2}px;
`}`;

const StyledLabel = styled.label`${({theme}) => `
  input {
    display: none;
  }

  width: 1em;
  height: 1em;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  .checkmark{
    display: inline-block;

    color: ${theme.newColors.GreenJuice};
  }

  input:not(:checked) + .checkmark path{
    fill: none !important;
    stroke: none !important;
  }

  border: 2px solid ${theme.newColors.GreenJuice};
  border-radius: 3px;

`}`;
