import React from 'react';
import {IconProps} from './_iconProps';

export const Grid2: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13.3333 8.66667C13.3333 6.08934 11.244 4 8.66667 4V4C6.08934 4 4 6.08934 4 8.66667V8.66667C4 11.244 6.08934 13.3333 8.66667 13.3333V13.3333C11.244 13.3333 13.3333 11.244 13.3333 8.66667V8.66667Z" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
<path d="M28 8.66667C28 6.08934 25.9107 4 23.3333 4V4C20.756 4 18.6667 6.08934 18.6667 8.66667V8.66667C18.6667 11.244 20.756 13.3333 23.3333 13.3333V13.3333C25.9107 13.3333 28 11.244 28 8.66667V8.66667Z" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
<path d="M28 23.3333C28 20.756 25.9107 18.6667 23.3333 18.6667V18.6667C20.756 18.6667 18.6667 20.756 18.6667 23.3333V23.3333C18.6667 25.9107 20.756 28 23.3333 28V28C25.9107 28 28 25.9107 28 23.3333V23.3333Z" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
<path d="M13.3333 23.3333C13.3333 20.756 11.244 18.6667 8.66667 18.6667V18.6667C6.08934 18.6667 4 20.756 4 23.3333V23.3333C4 25.9107 6.08934 28 8.66667 28V28C11.244 28 13.3333 25.9107 13.3333 23.3333V23.3333Z" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</svg>
