export function componentToHex(
  c:number
) {
  const hex = c.toString(16);
  return hex.length === 1 ? '0' + hex : hex;
}

export function rgbToHex(
  r: number, 
  g: number, 
  b: number) {

  return '#' + componentToHex(r) + componentToHex(g) + componentToHex(b);
}

export function hexToRgb(
  hex: string
) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? [
    parseInt(result[1], 16),
    parseInt(result[2], 16),
    parseInt(result[3], 16)
  ] : null;
}

export const mixColors = (color1: string, color2: string, precent: number) => {
  const rgbOne = hexToRgb(color1);
  const rgbTwo = hexToRgb(color2);
  if(!rgbOne||!rgbTwo){ return '' }
  
  const composure = rgbToHex(...([
    Math.round(rgbOne[0] - ((rgbOne[0]-rgbTwo[0])*precent)),
    Math.round(rgbOne[1] - ((rgbOne[1]-rgbTwo[1])*precent)),
    Math.round(rgbOne[2] - ((rgbOne[2]-rgbTwo[2])*precent)), 
  ] as [number, number, number]));
  return composure;
}