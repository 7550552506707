import React from 'react';
import {IconProps} from './_iconProps';

export const Heart: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M27.7867 6.14667C27.1057 5.46534 26.2971 4.92486 25.4072 4.55611C24.5172 4.18736 23.5633 3.99757 22.6 3.99757C21.6367 3.99757 20.6828 4.18736 19.7929 4.55611C18.9029 4.92486 18.0944 5.46534 17.4134 6.14667L16 7.56L14.5867 6.14667C13.2111 4.77108 11.3454 3.99828 9.40003 3.99828C7.45465 3.99828 5.58895 4.77108 4.21336 6.14667C2.83777 7.52226 2.06497 9.38796 2.06497 11.3333C2.06497 13.2787 2.83777 15.1444 4.21336 16.52L5.62669 17.9333L16 28.3067L26.3734 17.9333L27.7867 16.52C28.468 15.839 29.0085 15.0304 29.3772 14.1405C29.746 13.2505 29.9358 12.2966 29.9358 11.3333C29.9358 10.37 29.746 9.41614 29.3772 8.52619C29.0085 7.63625 28.468 6.82767 27.7867 6.14667V6.14667Z" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</svg>
