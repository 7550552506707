import {
  useEffect,
  useState,
  useMemo,
  useRef
} from 'react';
// items in time graph
import {
  ProcessedReport,
  providerToLabelName,
  processRawCellItemToReportItem
} from 'helpers';
import {
  ItemsInTimeGraph,
  ItemsInTimeGraphProps,
  Loading,
  Pagination
} from 'components';
import {
  newColors
} from 'theme';
import {
  signalsRange
} from 'config';
import {
  useRawCellList
} from '../CellItemsDataDisplay'
import {
  startOfDay,
  endOfDay
} from 'date-fns';
import {
  offsetHomeSpecificTimestamp
} from 'utils';
import styled from 'styled-components';
import {
  mixColors
} from 'utils';
import { useDataFeedObserver } from 'hooks';
import { format } from 'date-fns';
import { useSensorsStore } from 'context';

export const TMSITracking: React.FC<{
  variant?: 'scroll'|'pagination',
  endTimestamp?: number,
  startTimestamp: number,
  pageSize?: number,
  width?: number,
  minHeight?: number,
  live?: boolean,
  markEachNMilliseconds?: number,
  marksFramePaddingMillisecondsLeft?: number,
  marksFramePaddingMillisecondsRight?: number,
  axisLabel?: string,
  showNow?: boolean
}> = ({
  width=861,
  minHeight=330,
  variant='pagination',
  pageSize=10,
  startTimestamp=startOfDay(Date.now()).getTime(),
  endTimestamp,//=endOfDay(Date.now()).getTime(),
  live,
  markEachNMilliseconds = 6 * 60 * 60 * 1000,
  marksFramePaddingMillisecondsLeft = 0,
  marksFramePaddingMillisecondsRight = 0,
  axisLabel,
  showNow = false
}) => {
  const [itemsInTimegraphItems, setItemsInTimeGraphItems] = useState<Array<(ItemsInTimeGraphProps['data'][0])&{latestTimestamp: number}>>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [allItems, setAllItems] = useState<ProcessedReport['items']>([]);
  const {sensorArray} = useSensorsStore(s => s);
  const sensorId = useMemo(() => sensorArray[0]?.uid,[sensorArray])
  const {
    rawItems,
    loading
  } = useRawCellList({
    source: 'cell',
    sensorId,
    endTimestamp, 
    startTimestamp 
  });
  const {newestSignals} = useDataFeedObserver();
  const initTime = useRef(Date.now()).current;
  const initiated = useRef(false);
  const [newestTimeMark, setNewestTimemark] = useState<number>(Date.now());

  // Effects
  useEffect(function filterAndProcessedReportItems(){
    // make sure it has TMSI
    // make sure its within the range
    // create the payload
    const tmsiKeyedIndex: {
      [key: string]: typeof itemsInTimegraphItems[0]
    } = {};
    allItems?.forEach(it => {
      if(it.localTimestamp >= startTimestamp && it.tmsi && (!endTimestamp || (it.localTimestamp <= endTimestamp))){
        const tmsi = it.tmsi;
        if(!tmsiKeyedIndex[tmsi]){
          tmsiKeyedIndex[tmsi] = {
            items: [],
            label: providerToLabelName(it.provider),
            value: String(tmsi),
            latestTimestamp: it.localTimestamp
          }
        }
        const target = tmsiKeyedIndex[tmsi];
        const providerLabelColor = newColors[providerToLabelName(it.provider)];
        target.items.push({
          date: new Date(it.localTimestamp),
          color: mixColors(providerLabelColor, '#FFFFFF', (Math.max((Math.abs(it.power)-signalsRange.min),0)/(signalsRange.max-signalsRange.min))*signalsRange.outOf)
        });
        if(target.latestTimestamp < it.localTimestamp){
          target.latestTimestamp = it.localTimestamp
        }
      }
    });
    const direction = variant === 'scroll' ? -1 : 1;
    setItemsInTimeGraphItems(Object
      .values(tmsiKeyedIndex)
      .sort((a,b) => a.latestTimestamp > b.latestTimestamp ? 1*direction : -1*direction));
  },[
    allItems,
    startTimestamp,
    endTimestamp,
    variant
  ]);

  useEffect(function processRawItems(){
    setAllItems(prev => {
      const newItems = rawItems?.map(rawIt => {
        const newItem = processRawCellItemToReportItem(rawIt);
        return {
          ...newItem,
          localTimestamp: offsetHomeSpecificTimestamp(newItem.timestamp)
        }
      });
      // if query just came through -> there is a chance we have already caught some new items
      if(!initiated.current && live){
        initiated.current = true;
        newItems.push(...prev);
      }
      return newItems;
    });
  },[
    rawItems,
    live
  ]);
  useEffect(function processNewestSignalsToItems(){
    if(!live){ return; }
    const newItems: ProcessedReport['items'] = [];
    let newLatestTimemark = 0;
    newestSignals?.forEach(signal => {
      // map new cell signals to items
      if(signal.type === 'cell'){
        newItems.push({
          localTimestamp: signal.timestamp,
          timestamp: signal.timestamp,
          provider: signal.cellProvider,
          type: signal.type,
          power: signal.power,
          tmsi: signal.identifier,
          establishmentCause: null
        });
        if(newLatestTimemark < signal.timestamp){
          newLatestTimemark = signal.timestamp;
        }
      }
    });
    if(newItems.length){
      setAllItems(prev => ([...prev, ...newItems]));
      setNewestTimemark(newLatestTimemark);
    }
  },[
    newestSignals,
    live
  ]);

  useEffect(function setNewestTimemarkAutoTickIfLive(){
    if(!live){ return; }
    const int = setInterval(() => {
      setNewestTimemark(Date.now())
    },1000); // lets tick every minute
    return () => clearInterval(int);
  },[
    live
  ]);

  const displayedFrameTimestamps: {
    start: number,
    end: number
  } = useMemo(() => {
    return live ? 
      {
        start: startTimestamp+(initTime-newestTimeMark),
        end: newestTimeMark
      }
      :
      {
        start: startTimestamp,
        end: endTimestamp||initTime
      }
  },[
    startTimestamp,
    live,
    newestTimeMark,
    endTimestamp,
    initTime
  ]);

  const timeAxisLabels: Array<{
    value: number,
    label: string
  }> = useMemo(() => {
    const startOfTheDayOfTheEarliestMark = startOfDay(displayedFrameTimestamps.start).getTime();
    const endOfDayOfLatestTimeMark = endOfDay(displayedFrameTimestamps.end).getTime();
    const numberOfIntervals = (endOfDayOfLatestTimeMark - startOfTheDayOfTheEarliestMark) / markEachNMilliseconds;
    const labels = [];
    const minShownTimestamp = displayedFrameTimestamps.start + marksFramePaddingMillisecondsLeft;
    const maxShownTimestamp = displayedFrameTimestamps.end - marksFramePaddingMillisecondsRight;
    for(let i=1;i<numberOfIntervals;i++){
      const timestamp = startOfTheDayOfTheEarliestMark + markEachNMilliseconds*i
      if(timestamp > minShownTimestamp && timestamp < maxShownTimestamp){
        labels.push({
          label: format(timestamp, 'h:mm aa'),
          value: timestamp
        });
      }
    }
    return labels;
  },[
    displayedFrameTimestamps,
    markEachNMilliseconds,
    marksFramePaddingMillisecondsLeft,
    marksFramePaddingMillisecondsRight
  ]);

  return <Container>
    <b className="label">TMSI Numbers</b>
    <ItemsInTimeGraph
      showNow={showNow}
      scroll={variant==='scroll'}
      className="items-in-time-graph"
      width={width}
      minHeight={minHeight}
      data={variant==='pagination' 
        ?
        itemsInTimegraphItems.slice(currentPage*pageSize,(currentPage+1)*pageSize)
        :
        itemsInTimegraphItems
      }
      gridCellWidth={0}
      labelColWidth={100}
      startDate={new Date(displayedFrameTimestamps.start)}
      endDate={new Date((displayedFrameTimestamps.end)+0.4*60*60*1000 )}
      lineHeigth={28}
      padding={{
        top: 0,
        bottom: 0,
        left: 0,
        right: 10
      }}
      marksR={3.5}
      timeaxisHeight={40}
      axisLabels={[
        {
          label: axisLabel||'',
          position: {
            x: 0
          }
        },
        ...timeAxisLabels
      ]}
      >
      {loading ?
        <Loading x={width/2-60} y={minHeight/2-65} />
        : null
      }
      </ItemsInTimeGraph>
    { variant === 'pagination' ?
      <Pagination onPageChange={(p) => setCurrentPage(p)} totalPages={Math.ceil(itemsInTimegraphItems.length/pageSize)} />
      : null
    }   
  </Container>
}

const Container = styled.div`${({theme}) => `
  .items-in-time-graph:not(.with-scroll) {
    margin-bottom: ${theme.boxMargins.xl4}px;
  }
`}`;