import React from 'react';
import {IconProps} from './_iconProps';

export const VolumeX: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M30.6667 12L22.6667 20M22.6667 12L30.6667 20M14.6667 6.66669L8 12H2.66666V20H8L14.6667 25.3334V6.66669Z" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</svg>
