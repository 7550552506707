import React from 'react';
import {IconProps} from './_iconProps';

export const Share2: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.4533 18.0133L20.56 23.32M20.5467 8.67999L11.4533 13.9867M28 6.66666C28 8.8758 26.2091 10.6667 24 10.6667C21.7909 10.6667 20 8.8758 20 6.66666C20 4.45752 21.7909 2.66666 24 2.66666C26.2091 2.66666 28 4.45752 28 6.66666ZM12 16C12 18.2091 10.2091 20 8 20C5.79086 20 4 18.2091 4 16C4 13.7909 5.79086 12 8 12C10.2091 12 12 13.7909 12 16ZM28 25.3333C28 27.5425 26.2091 29.3333 24 29.3333C21.7909 29.3333 20 27.5425 20 25.3333C20 23.1242 21.7909 21.3333 24 21.3333C26.2091 21.3333 28 23.1242 28 25.3333Z" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</svg>
