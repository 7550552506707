import {
  appVersionStore,
  alertsStore,
  homeStore,
  alertSettingsStore,
  accountStore,
  profilesStore,
  devicesStore,
  sensorsStore,
  tellMeWhenStore,
  edgeDaemonStore,
  // timelineStore,
} from './store';

// reload app version
const { loadAppVersion } = appVersionStore.getState();
const { getUnreadAlerts, reset: resetAlertsStore } = alertsStore.getState();
const { getUser, reset: resetAccountStore } = accountStore.getState();
const { getHome, reset: resetHomeStore } = homeStore.getState();
const {
  getProfiles,
  getProfilesPresence,
  processRawProfilesIntoTheState,
  reset: resetProfilesStore,
} = profilesStore.getState();

const {
  // alertSettingsStatus,
  // getAllAlertSettings,
  reset: resetAlertSettingStore
} = alertSettingsStore.getState();

const { 
  // getAllTellMeWhenSuspicious, 
  reset: resetTellMeWhenStore 
} = tellMeWhenStore.getState();


const { 
  getDevices, 
  getDevicesPresence, 
  processRawDevicesIntoTheState,
  reset: resetDevicesStore,
} = devicesStore.getState();

const { getSensors, reset: resetSensorsStore } = sensorsStore.getState();

const { getHomeDaemons, reset: resetEdgeDaemonsStore } = edgeDaemonStore.getState();

export const initDependencies = () => {
  loadAppVersion();
  // App Verstion 
  const appVersionUpdateInterval = setInterval(() => {
    loadAppVersion();
  }, 60 * 60 * 1000);

  const homeDaemonsUpdateInterval = setInterval(function () {
    getHomeDaemons();
  }, 1 * 60 * 1000);


  // Subscription to homeId. Make any calls depend on homeId changes
  const unsubscribeHome = homeStore.subscribe((state, prevState) => {
    const isHomeLoaded = state.homeDataStatus === 'success';
    const isPrevHomeLoaded = prevState.homeDataStatus === 'success';

    if (isHomeLoaded && isHomeLoaded !== isPrevHomeLoaded) {
      getHomeDaemons();
      getSensors();
    }
    
    const homeId = state.home?.uid;
    const prevHomeId = prevState.home?.uid;
    if (homeId !== prevHomeId) { // homeId has changed
      /* homeId exists */
      if (homeId) {
        // Alerts
        getUnreadAlerts();

        // AlertsSettings
        // if (!alertSettingsStatus) {
        //   getAllAlertSettings();
        // }

        // Profiles
        getProfiles();
        getProfilesPresence();

        // Devices
        getDevices();
        getDevicesPresence();
        // // TellMeWhen
        // getAllTellMeWhenSuspicious();

        // EdgeDaemons
        getHomeDaemons();
        // Sensors

        getSensors();
        return;
      }

      /** homeId doesn't exist */
      resetAlertSettingStore();
      resetProfilesStore();
      resetDevicesStore();
      resetTellMeWhenStore();
      resetEdgeDaemonsStore();
      resetSensorsStore();
      resetAlertsStore(); 
      // resetTimelineStore();
    }
  });

  const unsubscribeAccount = accountStore.subscribe((state, prevState) => {
    const fbUserId = state.fbUserId;
    const prevUserId = prevState.fbUserId;

    if (fbUserId !== prevUserId) {
      if (fbUserId) {
        getUser();
      } else {
        resetAccountStore();
      }
    }

    const accountId = state.accountId;
    const prevAccountId = prevState.accountId;



    if (accountId !== prevAccountId) {
      if (accountId) {
        getHome();
      } else {
        resetHomeStore();
      }
    }
  });
  /* TODO: add listener for 'notification_preferences' for addModelsChangedListener function
         when ModelsChangedContext will be added
  */

  const unsubscribeProfiles = profilesStore.subscribe((state, prevState) => {
    const rawProfiles = state.rawProfiles;
    const prevRawProfiles = prevState.rawProfiles;
    const isRawProfilesChanged = rawProfiles && rawProfiles !== prevRawProfiles;

    const rawProfilePresence = state.rawProfilePresence;
    const prevProfilePresence = prevState.rawProfilePresence;
    const isRawProfilesPresenceChanged = rawProfilePresence && rawProfilePresence !== prevProfilePresence;

    if (isRawProfilesChanged || isRawProfilesPresenceChanged) {
      processRawProfilesIntoTheState();
    }
  });

  const unsubscribeDevices = devicesStore.subscribe((state, prevState) => {
    const rawDevices = state.rawDevices;
    const prevRawDevices = prevState.rawDevices;
    const isRawDevicesChanged = rawDevices && rawDevices !== prevRawDevices;

    const rawDevicesPresence = state.rawDevicesPresence;
    const prevRawDevicesPresence = prevState.rawDevicesPresence;
    const isRawDevicesPresenceChanged = rawDevicesPresence && rawDevicesPresence !== prevRawDevicesPresence;

    if (isRawDevicesChanged || isRawDevicesPresenceChanged) {
      processRawDevicesIntoTheState();
    }
  });

  return () => {
    console.log('[LOG] --- clearInterval(appVersionUpdateInterval) RUN');
    clearInterval(appVersionUpdateInterval);
    clearInterval(homeDaemonsUpdateInterval);
    unsubscribeHome();
    unsubscribeAccount();
    unsubscribeProfiles();
    unsubscribeDevices();
  };
};