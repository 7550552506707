import React from 'react';
import {IconProps} from './_iconProps';

export const Linkedin: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M21.3333 10.6667C23.4551 10.6667 25.4899 11.5095 26.9902 13.0098C28.4905 14.5101 29.3333 16.5449 29.3333 18.6667V28H24V18.6667C24 17.9594 23.719 17.2811 23.219 16.781C22.7189 16.281 22.0406 16 21.3333 16C20.6261 16 19.9478 16.281 19.4477 16.781C18.9476 17.2811 18.6667 17.9594 18.6667 18.6667V28H13.3333V18.6667C13.3333 16.5449 14.1762 14.5101 15.6765 13.0098C17.1768 11.5095 19.2116 10.6667 21.3333 10.6667Z" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
<path d="M8 12H2.66667V28H8V12Z" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
<path d="M5.33333 8C6.80609 8 8 6.80609 8 5.33333C8 3.86057 6.80609 2.66667 5.33333 2.66667C3.86057 2.66667 2.66667 3.86057 2.66667 5.33333C2.66667 6.80609 3.86057 8 5.33333 8Z" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</svg>
