import React from 'react';
import {IconProps} from './_iconProps';

export const AlertOctagon: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M16 10.6667V16M16 21.3334H16.0133M10.48 2.66669H21.52L29.3333 10.48V21.52L21.52 29.3334H10.48L2.66666 21.52V10.48L10.48 2.66669Z" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</svg>
