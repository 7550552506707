import { apiRequest } from "../apiRequest";
import { GetPushNotificationResponse, UpdatePushNotificationPayload } from "./types";

export const getPushNotification = (homeId: number, alertId: string, pushNotificationId: string) =>
  apiRequest<GetPushNotificationResponse>({
    service: 'feApiGateway',
    path: `homes/${homeId}/alerts/${alertId}/push-notifications/${pushNotificationId}`
  })

export const getPushNotifications = (homeId: number, alertId: string) =>
  apiRequest<GetPushNotificationResponse>({
    service: 'feApiGateway',
    path: `homes/${homeId}/alerts/${alertId}/push-notifications`
  })

export const updatePushNotification = (payload: UpdatePushNotificationPayload) =>
  apiRequest<GetPushNotificationResponse>({
    service: 'feApiGateway',
    path: `homes/${payload.homeId}/alerts/${payload.alertId}/push-notifications/${payload.pushNotificationId}`,
    method: 'PATCH',
    data: {
      opened_method: payload.opened_method,
      opened_timestamp: payload.opened_timestamp,
    },
  })
