import { apiRequest } from "../apiRequest";
import { RawDeviceData, UpdateDevicePayload, getHomeDevicesPayload } from "./types";

export const getHomeDevices = ({ homeId, limit = 100, offset = 0 }: getHomeDevicesPayload) =>
  apiRequest<RawDeviceData>({
    service: 'feApiGateway',
    path: `homes/${homeId}/devices?limit=${limit}&offset=${offset}`,
  });

export const updateDevice = (homeId: number, deviceId: number, payload: UpdateDevicePayload) =>
  apiRequest<RawDeviceData>({
    service: 'feApiGateway',
    path: `homes/${homeId}/devices/${deviceId}`,
    method: 'PATCH',
    data: payload,
  });

export const getDevice = (homeId: number, deviceId: number) => 
  apiRequest<RawDeviceData>({
    service: 'feApiGateway',
    path: `homes/${homeId}/devices/${deviceId}`,
  });

