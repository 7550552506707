import React, {useState, useRef, useCallback, useEffect, useMemo} from 'react';
import styled, {keyframes, css} from 'styled-components';
import {
  ChevronDown
} from 'theme/icons';

// this expects label-checkbox or label select as children
// and will display checked/selected options in the inline field
// on click, display hover with the list of select/checkboxes
export const DrophoverCheckSelect: React.FC<{
  className?: string;
  placeholder?: string;
  onValuesChange?: (values: Array<string>) => void
  onValuesLabelsChange?: (labelText: Array<string>) => void
  defaultValuesOnEmpty?: Array<string>
  defaultLabelsOnEmpty?: Array<string>
}> = ({
  className,
  placeholder,
  onValuesChange,
  onValuesLabelsChange,
  children
}) => {
  const hoverRef = useRef<HTMLDivElement>(null);
  const [checkedInputValues, setCheckedInputValues] = useState<Array<string>>([]);
  const [checkedInputLabels, setCheckedInputLabels] = useState<Array<string>>([]);
  const [isOn, setIsOn] = useState<boolean>(false);
  const valuesRef = useRef({
    selectedInputValues: [],
    selectedInputLabelValues: []
  }).current;

  // search checked input and fill in labels text values to stat
  const scanAndSetChecked = useCallback(function scanAndSetChecked(){
    //hoverRef.current.
    const box = hoverRef.current;
    if(!box){
      return;
    }
    const selectedInputValues = [] as any;
    const selectedInputLabelValues = [] as any;

    ([...box.getElementsByTagName('input')])
      ?.filter(el => el.checked)
      ?.forEach(el => {
        const value = el.value;
        const labelText = box.querySelector(`label[for=${el.id}]`)?.textContent
        selectedInputValues.push(value);
        selectedInputLabelValues.push(labelText);
      })
    const hasChanged = (
      valuesRef.selectedInputValues?.join('--') !== selectedInputValues.join('--') ||
      valuesRef.selectedInputLabelValues?.join('--') !== selectedInputLabelValues.join('--')
    ) 
    if(hasChanged){
      setCheckedInputValues(selectedInputValues);
      setCheckedInputLabels(selectedInputLabelValues);
      onValuesChange?.(selectedInputValues);
      onValuesLabelsChange?.(selectedInputLabelValues);
    }

    valuesRef.selectedInputValues = selectedInputValues;
    valuesRef.selectedInputLabelValues = selectedInputLabelValues;
  },[
    setCheckedInputValues,
    setCheckedInputLabels,
    onValuesChange,
    onValuesLabelsChange,
    valuesRef
  ]);

  // initial values check
  useEffect(function setPreselctedOnWindowIn(){
    scanAndSetChecked();
  },[
    scanAndSetChecked
  ]);

  // dismiss on outside click
  useEffect(function setUpDismissHandlerOnWindow(){
    function handleHidePopup(){
      setIsOn(false);
    }
    if(isOn){
      window.addEventListener('click', handleHidePopup)
    }
    return () => window.removeEventListener('click', handleHidePopup);
  },[
    isOn,
    setIsOn
  ]);

  //
  const values = useMemo(() => checkedInputLabels.length ? checkedInputLabels : checkedInputValues, [
    checkedInputLabels,
    checkedInputValues
  ]);

  return <DrophoverContainer className={isOn ? 'active' : 'drophover '+(className||'')} onClick={((e) => {
    // first lets cancel out other calls 
    //simulateClick(window);
    // not set on
    if(!isOn){
      setIsOn(true)
    }
    // trigger scans on every inside click
    scanAndSetChecked();
    // and make sure to cut those call here to prevent setting on to off
    e.stopPropagation();
  })}>
    <span className={!values.length?'placeholder':''}>{values.length ? values.join(', ') : placeholder}</span>
    <div ref={hoverRef} className={'hover-box' + (isOn ? ' active' : '')} onClick={() => scanAndSetChecked}>
      {children}
    </div>
    <ChevronDown strokeWidth='3' />
  </DrophoverContainer>
} 

const appear = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-20px)
  }
  40% {
    opacity: 1;
  } 
  100% {
    opacity: 1;
    transform: translateY(0px)
  }
`;

const DrophoverContainer = styled.div`${({theme}) => css`
  position: relative;
  display: inline-block;
  align-items: center;

  margin: 0 0.5em;
  border-bottom: 2px solid ${theme.newColors.MidnightShores};
  color: ${theme.newColors.MidnightShores};
  padding-left: ${theme.boxPadding.s}px;
  font-weight: 600;

  span.placeholder {
    color: rgba(${theme.colorsRgb.newColors.MidnightShores.toString()}, 0.5);
  }

  &.active {
    z-index: 100;
  }

  min-width: 125px;
  display: inline-flex;
  flex-direction: row;
  > *:first-child {
    flex-grow: 10
  }

  cursor: pointer;
  input {

  }

  .hover-box {
    position: absolute;
    top: 0;
    left: 0;
    white-space: nowrap;
    min-width: 100%;
    box-sizing: border-box;
    ${theme.shaddowStyles.large}
  }

  .hover-box input[type=checkbox],
  .hover-box input[type=radio] {
    margin-right: ${theme.boxMargins.base}px;
  }

  .hover-box > * {
    display: flex;
    align-items: center;
    width: 100%;
    border
  }

  .hover-box {
    background-color: ${theme.newColors.WhippedCream};
    border-radius: ${theme.boxPadding.s}px;
    padding: ${theme.boxPadding.base*0.8}px ${theme.boxPadding.base*1.2}px ${theme.boxPadding.base*1.4}px;
  }

  .hover-box:not(.active) {
    display: none;
  }

  .active {
    animation: ${appear} 0.5s;
  }
`}`;

