import React from 'react';
import {IconProps} from './_iconProps';

export const FastForward: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17.3333 25.3333L29.3333 16L17.3333 6.66666V25.3333Z" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
<path d="M2.66666 25.3333L14.6667 16L2.66666 6.66666V25.3333Z" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</svg>
