import React from 'react';
import {IconProps} from './_iconProps';

export const ToggleRight: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M21.3333 6.66669H10.6667C5.51201 6.66669 1.33333 10.8454 1.33333 16C1.33333 21.1547 5.51201 25.3334 10.6667 25.3334H21.3333C26.488 25.3334 30.6667 21.1547 30.6667 16C30.6667 10.8454 26.488 6.66669 21.3333 6.66669Z" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
<path d="M21.3333 20C23.5425 20 25.3333 18.2092 25.3333 16C25.3333 13.7909 23.5425 12 21.3333 12C19.1242 12 17.3333 13.7909 17.3333 16C17.3333 18.2092 19.1242 20 21.3333 20Z" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</svg>
