import React from 'react';
import {IconProps} from './_iconProps';

export const Battery: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M30.6667 17.3333V14.6667M4.00001 8H22.6667C24.1394 8 25.3333 9.19391 25.3333 10.6667V21.3333C25.3333 22.8061 24.1394 24 22.6667 24H4.00001C2.52725 24 1.33334 22.8061 1.33334 21.3333V10.6667C1.33334 9.19391 2.52725 8 4.00001 8Z" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</svg>
