import React from 'react';
import {IconProps} from './_iconProps';

export const PhoneIncoming: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M21.3334 2.66668V10.6667M21.3334 10.6667H29.3334M21.3334 10.6667L30.6667 1.33334M29.3334 22.56V26.56C29.3349 26.9313 29.2588 27.2989 29.11 27.6391C28.9613 27.9794 28.7431 28.2848 28.4695 28.5358C28.1958 28.7869 27.8728 28.978 27.521 29.097C27.1693 29.2159 26.7965 29.2601 26.4267 29.2267C22.3238 28.7809 18.3827 27.3789 14.92 25.1333C11.6985 23.0862 8.96714 20.3549 6.92002 17.1333C4.66666 13.6549 3.26434 9.69467 2.82669 5.57334C2.79337 5.20463 2.83719 4.83302 2.95535 4.48218C3.07352 4.13133 3.26345 3.80893 3.51304 3.5355C3.76264 3.26208 4.06643 3.04362 4.40507 2.89404C4.74372 2.74446 5.10981 2.66703 5.48002 2.66668H9.48002C10.1271 2.66031 10.7544 2.88945 11.245 3.31139C11.7357 3.73333 12.0561 4.31927 12.1467 4.96001C12.3155 6.2401 12.6286 7.49698 13.08 8.70668C13.2594 9.18391 13.2982 9.70256 13.1919 10.2012C13.0856 10.6998 12.8385 11.1575 12.48 11.52L10.7867 13.2133C12.6848 16.5514 15.4486 19.3153 18.7867 21.2133L20.48 19.52C20.8425 19.1615 21.3002 18.9145 21.7988 18.8081C22.2975 18.7018 22.8161 18.7406 23.2934 18.92C24.5031 19.3714 25.7599 19.6845 27.04 19.8533C27.6877 19.9447 28.2792 20.271 28.7021 20.77C29.1249 21.2691 29.3496 21.9061 29.3334 22.56Z" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</svg>
