import {
  apiRequest
} from '../apiRequest';
import {
  FlaskResponse,
  getFlaskFilter
} from '../helpers';
import { TellMeWhenApiItem, TellMeWhenApiNewItemPayload, TellMeWhenApiUpdateItemPayload } from './types';

export const saveTellMeWhenSuspicious = async function(p: TellMeWhenApiNewItemPayload){
  const response = await apiRequest<TellMeWhenApiItem>({
    service: 'dataAPI',
    path: '/api/tell_me_when_security_alert',
    method: 'POST',
    data: p
  });

  console.log('[saveTellMeWhen] received response', response);

  return response.data;
};

export const updateTellMeWhenSuspicious = async function(p: TellMeWhenApiUpdateItemPayload){
  const {uid} = p;

  const response = await apiRequest<TellMeWhenApiItem>({
    service: 'dataAPI',
    path: '/api/tell_me_when_security_alert/'+uid,
    method: 'PUT',
    data: p
  });

  console.log('[updateTellMeWhen] update tell me when response is:', {response});

  return response.data;
};

export const getAllTellMeWhenSuspicious = async function(params: {
  homeId: number | string;
}){
  const { homeId } = params;
  const q = getFlaskFilter({
    home_id: homeId,
  });

  const response = await apiRequest<FlaskResponse<TellMeWhenApiItem>>({
    service: 'dataAPI',
    path: '/api/tell_me_when_security_alert?'+(q?'q='+q:''),
    method: 'GET'
  });

  //console.log('[getAllTellMeWhen] all tell me whens are:', {response, q});

  return response.data?.objects ;
};

export const deleteTellMeWhenSuspicious = (uid: string|number) =>
  apiRequest<FlaskResponse<TellMeWhenApiItem>>({
    service: 'dataAPI',
    path: '/api/tell_me_when_security_alert/'+uid,
    method: 'DELETE'
  });
