import { Listeners } from "@ubiety/fe-context/src/classes";
import { AxiosRequestConfig, AxiosResponse } from "axios";

export type ENV_TAGS = 'dev'|'staging'|'prod'|'white_label';
export type EnvironmentData = {
  environment: ENV_TAGS;
  appName: string;
  acctMgmtApi: string;
  acctMgmtApiV3: string;
  dataApiUrl: string;
  eckleburgApiUrl: string;
  acctMgmtApiKey: string;
  geocodeApiKey: string;
  betaRequestURL: string;
  apiDebugging: boolean;
  googlePlacesApiKey: string;
  googlePlacesApiUrl: string;
  qrCodeProxyUrl: string;
  tmw_endpoint_base: string;
  partnersApiUrl: string;
  pushServiceApiV2Url: string;
  logs_lambda_base_url: string;
  homeApiUrl: string;
  frontendApiGateway: string;
  snowflakeConnectorUrl: string;
  home_api_admin_secret?: string;
  partners_api_read_token: string;
  wigle_search_url: string;
  wigle_auth_header: string;
  websocket_base: string;
  sensorAccountId: string;
  sensorIotEndpoint: string;

  // may pass admin secrets
  account_management_api_admin_secret?: string;
  partners_api_admin_secret?: string;
  data_api_admin_secret?: string;
  push_api_admin_secret?: string;
  snowflake_connector_admin_secret?: string;
  logs_lambda_admin_secret?: string;
  frontend_api_gateway_secret?: string;
};

export const ENV_HOLDER: {
  current?: EnvironmentData,
  settings: {
    getAuthHeaderValues?: () => Promise<Record<string, string>>,
    onResponse?: () => void,
    onRequest?: () => void
    logApiCalls: boolean,
    logAnalyticsEvents: boolean,
    defaultRetries: number,
    withCredentials?: boolean,
    
    logAnalyticsEvent: (name: string, params?: {
      [key: string]: any;
    } | undefined) => Promise<void>;
    logError: (
      error: unknown, 
      details: {
        targetUrl?: string,
        headers?: Record<string, string>,
        payload?: Record<string, string>,
        params?: Record<string, string>,
        status?: string|number,
        response?: string,
        message?: string
      }) => void,
    getUserIdAndToken: () => Promise<{ uid: string; token: string; }>,
  }
} = {
  current: undefined,
  settings: {
    logApiCalls: false,
    logAnalyticsEvents: false,
    defaultRetries: 0,
    onResponse: () => {},
    onRequest: () => {},
    logError: () => {},
    logAnalyticsEvent: (name, params) => {
      console.log('[logFBCustomEvent] {name, params} ', {name, params});
      return new Promise((resolve) => resolve());
    },
    getUserIdAndToken: async () => { 
      return new Promise(resolve => resolve({uid: '', token: ''}))
    },
  }
};

export const apiListeners = {
  onResponse: new Listeners<[AxiosResponse<any>]>(),
  onRequest: new Listeners<[AxiosRequestConfig]>()
}

export const ENV: Partial<EnvironmentData> = {};

export const apiInit = (env: EnvironmentData, settings: Partial<typeof ENV_HOLDER.settings>) => {
  ENV_HOLDER.current = env;
  ENV_HOLDER.settings = {
    ...ENV_HOLDER.settings,
    ...settings
  };

  if(settings.onRequest){
    apiListeners.onRequest.addListener(settings.onRequest)
  }

  if(settings.onResponse){
    apiListeners.onResponse.addListener(settings.onResponse)
  }

  Object.entries(env).forEach(([key, value]) => {
    (ENV as any)[key] = value;
  })
};
