import {
  useState,
  useCallback,
  useMemo,
  useEffect,
} from 'react';

import {
  STATE_INTERCEPTED_HOLDER
} from '@ubiety/fe-api';
import {
  compress, 
  decompress,
} from 'compress-json';
import UUID from 'uuid-js';

import {
  Listeners
} from '../classes';
import { trimUndefinedAndClassesObjectRecursively } from '../utils';


console.log('---decompress is', decompress);

export type SessionJson = {
  timeStart: number;
  timeEnd: number;
  compressedLogs: ReturnType<typeof compress>;
  sessionUser: string;
}
export type StateKnotLog = {
  timestamp: number,
  entry: any,
  moduleName: string,
  stateName: string,
  groupId: string
};
export class StatesKnot {
  logs: Array<StateKnotLog>=[];
  timestampIndex:{
    [key: string]: Array<number>
  }={};
  logsIndex:{
    [key: string]:  StateKnotLog // namespace--timestamp: log
  }={};
  indexNamesRecorded:{
    [key: string]: number
  }={};
  historyStartTimestamp: number=Date.now();
  historyEndTimeStamp: number=Date.now();
  listeners=new Listeners();
  uid=UUID.create().toString();
  sessionPreserved=false;

  addEntry(entry: any, moduleName:string, stateName: string, timestamp:number=Date.now()){
    const log = {
      timestamp,
      entry,
      moduleName,
      stateName,
      // group by module name
      groupId: moduleName
    };
    const indexName = moduleName+'--'+stateName;
    const logIndexId = indexName+'--'+timestamp;
    if(!this.timestampIndex[indexName]){
      this.timestampIndex[indexName] = [];
    }
    this.timestampIndex[indexName].push(timestamp);
    this.indexNamesRecorded[indexName] = timestamp;
    this.logsIndex[logIndexId] = log;

    this.logs.push(log);
    this.historyEndTimeStamp = Date.now();
    this.listeners.fire(log);
  }

  getStateAtTimeForIndexName(timestamp: number, indexName: string){
    const {
      timestampIndex
    } = this;
    let matchedTimestamp = null;
    for( let i=0; 
      i < timestampIndex[indexName].length && 
      timestamp > timestampIndex[indexName][i]; 
      i++) {
      matchedTimestamp = timestampIndex[indexName][i];
    }
    return this.logsIndex[indexName+'--'+matchedTimestamp];
  }

  getStateAtTime(timestamp: number){
    const flatState: {
      [key:string]: any | null
    } = {};

    Object.keys(this.indexNamesRecorded).forEach(indexName => {
      const state = this.getStateAtTimeForIndexName(timestamp, indexName);
      flatState[indexName] = state;
    });

    return flatState;
  }

  getStateTreeAtTime(timestamp: number){
    const timeState: {
      [key: string]: {
        [key: string]: any | null
      }
    } = {};
    
    Object.keys(this.indexNamesRecorded).forEach(indexName => {
      const state = this.getStateAtTimeForIndexName(timestamp, indexName);
      const [moduleName, stateName] = indexName.split('--');
      if(!timeState[moduleName]){
       timeState[moduleName] = {}; 
      }
      timeState[moduleName][stateName] = state;
    });

    return timeState;
  }

  // compress current session
  getSessionExportObject<T>(mixin:T=({} as T)){
    try {
      const logs = this.logs;
      // clear undefined
      console.log('[StatesKnot][getSessionExportObject] trimming undefined');
      trimUndefinedAndClassesObjectRecursively(logs, true);
      console.log('[StatesKnot][getSessionExportObject] trimmed undefined', logs, compress);
      const compressedLogs = compress(logs);
      console.log('[StatesKnot][getSessionExportObject] compressed logs', compressedLogs);
      return {
        uid: this.uid,
        timeStart: this.historyStartTimestamp,
        timeEnd: this.historyEndTimeStamp,
        compressedLogs,
        ...mixin
      } as {
        uid: string;
        timeStart: number;
        timeEnd: number;
        compressedLogs: ReturnType<typeof compress>
      } & T & typeof mixin;
    } catch (e){
      console.log('[StatesKnot] error compressing logs', e);
      return null;
    }
  }
}
let knot = new StatesKnot();
export const mountedModuleSetters: {
  [key: string]: ((value: any) => void) | null
} = {};
export const STATE_INTERCEPTED = {
  current: false,
  listeners: new Listeners(),
  set: function(s: boolean){
    this.current = s;
    this.listeners.fire(s);
  }
};

STATE_INTERCEPTED.listeners.addListener((val) => {
  STATE_INTERCEPTED_HOLDER.current = val as any;
});

const originalConsoleError = console.error;
export const sessionRef = {
  current: knot
};
export function startNewSession(){
  knot = new StatesKnot();
  sessionRef.current = knot;
}

export const useStateIntercepted =  () => {
  const [position, setPosition] = useState<boolean>(STATE_INTERCEPTED.current);

  useEffect(function(){
    return STATE_INTERCEPTED.listeners.addListener(function(p){
      console.log('--- state intercepted called, value', p);
      setPosition(p as boolean);
    });
  },[
    setPosition
  ]);

  const toogleStateInterception = useCallback(function(newPoisition?: boolean){
    if( (newPoisition !== undefined ? newPoisition : !STATE_INTERCEPTED.current) ){
      STATE_INTERCEPTED.set(true);
      console.log('intercepting to true',newPoisition);
      console.error = (...args) => console.log('[--INTERCEPTED ON--][ERROR]',...args);
    } else {
      STATE_INTERCEPTED.set(false);
      console.error = originalConsoleError;
    }
  },[]);

  return [
    position,
    toogleStateInterception
  ] as const;
};

console.log('[usePreserveState] knot created', knot);
//(global as any).getKnot = () => knot;

export const usePreserveStateKnot = () => {
  const [logs, setLogs] = useState<Array<StateKnotLog>>([...knot.logs]);

  useEffect(function(){
    const removeListener = knot.listeners.addListener(() => {
      setLogs([...knot.logs]);
    });

    return () => removeListener();
  },[]);

  return {
    logs, 
    knot
  };
};

export type usePreserveStateSettings = {
  moduleName?: string;
  stateName?: string;
}
export function usePreserveState<S>(
  value: S | (() => S), 
  settings?: usePreserveStateSettings
) {
  const [state, setState] = useState<S>(value);
  
  const {
    moduleName, 
    stateName
  } = useMemo(() => settings||{},[settings]);

  useMemo(() => {
    if(!moduleName || !stateName){
      console.error('[usePreserveState] missing state values', {moduleName, stateName});
    }
  }, [
    moduleName,
    stateName
  ]);
  
  const preserveState = useCallback((value: S) => {
    if(!moduleName || !stateName){ return; }
    new Promise(function preserveStateOutsideOfMainThread(){
      const entry = Array.isArray(value)
        ? [...value]
        : typeof value === 'object' 
        ? {...value} 
        : value === undefined
        ? 'undefined'
        : value;

      knot.addEntry(
        entry,
        moduleName,
        stateName
      );
    });
  },[
    moduleName,
    stateName
  ]);

  const setStateProxy = useCallback(function(v: any){
    // only set if not intercepted
    if(!STATE_INTERCEPTED.current){ 
      setState(v);
      preserveState(v);
    }
  },[
    setState,
    preserveState
  ]);

  useEffect(() => {
    mountedModuleSetters[moduleName+'--'+stateName] = setState;
    return () => {
      mountedModuleSetters[moduleName+'--'+stateName] = null;
      //console.log(`--- unmounted the ${moduleName} - ${stateName}`);
    };
  },[
    moduleName,
    stateName,
    setStateProxy
  ]);

  return [state, setStateProxy] as [typeof state, typeof setState];
}

