import styles from './Input.module.css';

type SubmitProps = {
  disabled?: boolean;
  id?: string;
  el?: React.MutableRefObject<HTMLInputElement>;
  value?: string,
  className?: string,
  onClick?: (e: React.MouseEvent<HTMLInputElement>)=> void,
  preventDefault?: boolean,
}
export const Submit: React.FC<SubmitProps> = ({
  disabled,
  id,
  className,
  el,
  value,
  onClick,
  preventDefault=false,
}) =>

  <input
    disabled={disabled}

    key={id}

    ref={el}
    type="submit"
    className={className+' '+styles.submit}
    value={value}
    onClick={e=>{
      if(preventDefault) {e.preventDefault();}
      onClick?.(e);
    }} />;
