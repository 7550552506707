import React from 'react';
import {IconProps} from './_iconProps';

export const VideoOff: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_149_847)">
<path d="M14.2133 6.66665H18.6667C19.3739 6.66665 20.0522 6.9476 20.5523 7.44769C21.0524 7.94779 21.3333 8.62607 21.3333 9.33331V13.7866L22.6667 15.12L30.6667 9.33331V22.6666M1.33333 1.33331L30.6667 30.6666M21.3333 21.3333V22.6666C21.3333 23.3739 21.0524 24.0522 20.5523 24.5523C20.0522 25.0524 19.3739 25.3333 18.6667 25.3333H3.99999C3.29275 25.3333 2.61447 25.0524 2.11438 24.5523C1.61428 24.0522 1.33333 23.3739 1.33333 22.6666V9.33331C1.33333 8.62607 1.61428 7.94779 2.11438 7.44769C2.61447 6.9476 3.29275 6.66665 3.99999 6.66665H6.66666L21.3333 21.3333Z" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</g>
<defs>
<clipPath id="clip0_149_847">
<rect width={width} height={height} fill="white"/>
</clipPath>
</defs>
</svg>
