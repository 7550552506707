import React from 'react';
import {IconProps} from './_iconProps';

export const CornerRightDown: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13.3333 20L20 26.6666M20 26.6666L26.6667 20M20 26.6666V10.6666C20 9.25216 19.4381 7.8956 18.4379 6.89541C17.4377 5.89522 16.0811 5.33331 14.6667 5.33331H5.33333" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</svg>
