import React from 'react';
import {IconProps} from './_iconProps';

export const Cast: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M2.66667 21.4666C3.94786 21.7279 5.12393 22.3602 6.04851 23.2848C6.9731 24.2094 7.60538 25.3855 7.86667 26.6666M2.66667 16.0666C5.37439 16.3678 7.89904 17.5814 9.82549 19.5078C11.7519 21.4343 12.9655 23.9589 13.2667 26.6666M2.66667 10.6666V7.99998C2.66667 7.29274 2.94762 6.61446 3.44772 6.11436C3.94782 5.61426 4.62609 5.33331 5.33334 5.33331H26.6667C27.3739 5.33331 28.0522 5.61426 28.5523 6.11436C29.0524 6.61446 29.3333 7.29274 29.3333 7.99998V24C29.3333 24.7072 29.0524 25.3855 28.5523 25.8856C28.0522 26.3857 27.3739 26.6666 26.6667 26.6666H18.6667M2.66667 26.6666H2.68001" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</svg>
