import React from 'react';
import {IconProps} from './_iconProps';

export const Tv: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M22.6667 2.66669L16 9.33335L9.33333 2.66669M5.33333 9.33335H26.6667C28.1394 9.33335 29.3333 10.5273 29.3333 12V26.6667C29.3333 28.1394 28.1394 29.3334 26.6667 29.3334H5.33333C3.86057 29.3334 2.66666 28.1394 2.66666 26.6667V12C2.66666 10.5273 3.86057 9.33335 5.33333 9.33335Z" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</svg>
