import React from 'react';
import {IconProps} from './_iconProps';

export const Hexagon: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M28 21.3333V10.6667C27.9995 10.199 27.8761 9.73974 27.6421 9.33488C27.408 8.93001 27.0717 8.59381 26.6667 8.35999L17.3333 3.02666C16.9279 2.79261 16.4681 2.6694 16 2.6694C15.5319 2.6694 15.0721 2.79261 14.6667 3.02666L5.33333 8.35999C4.92835 8.59381 4.59197 8.93001 4.35795 9.33488C4.12392 9.73974 4.00048 10.199 4 10.6667V21.3333C4.00048 21.801 4.12392 22.2602 4.35795 22.6651C4.59197 23.07 4.92835 23.4062 5.33333 23.64L14.6667 28.9733C15.0721 29.2074 15.5319 29.3306 16 29.3306C16.4681 29.3306 16.9279 29.2074 17.3333 28.9733L26.6667 23.64C27.0717 23.4062 27.408 23.07 27.6421 22.6651C27.8761 22.2602 27.9995 21.801 28 21.3333Z" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</svg>
