import {useState, useEffect, useRef, useCallback} from 'react';
import {
  RawCellListItem,
  RawCellListQueryPayload,
  getRawCellItemsList,
  getRawCellScanItemsList
} from '@ubiety/fe-api';
import {
  endOfDay
} from 'date-fns';

export type useRawCellListProps = {
  source: 'cell'|'cell-scan',
  endTimestamp?: number
} & Omit<RawCellListQueryPayload,'endTimestamp'>;

export const useRawCellList = ({
  source='cell',
  sensorId,
  startTimestamp,
  endTimestamp,
  limit=0,
  offset=0
}: useRawCellListProps) => {
  const instanceTimestamp = useRef(Date.now()).current;
  const [rawItems, setRawItems] = useState<Array<RawCellListItem>>([]);
  const [loading, setLoading] = useState(false);

  useEffect(function querryItems(){(async () => {
    const endT = endTimestamp||instanceTimestamp;
    const payload = {
      sensorId,
      startTimestamp,
      endTimestamp: endT,
      limit,
      offset
    };
    try {
      setLoading(true);
      switch(source){
        case 'cell':
          const cellResp = await getRawCellItemsList(payload);
          console.log('[useRawCellList] got cellResp', cellResp);
          setRawItems(cellResp.data.items)
          break;
        case 'cell-scan':
          const cellScanResp = await getRawCellScanItemsList(payload);
          console.log('[useRawCellList] got cellScanResp', cellScanResp);
          setRawItems(cellScanResp.data.items)
          break;
      }
    } catch (e){
      console.error('Error getting list data', e);
    } finally {
      setLoading(false);
    }
  })()},[
    sensorId,
    startTimestamp,
    endTimestamp,
    limit,
    offset,
    instanceTimestamp,
    source
  ]);

  return {
    loading,
    rawItems
  }
}

export const useDynamicRawCellItemsList = ({
  pageSize=20,
  cell=true,
  cellScan=true,
  sensorId
}: {
  pageSize?: number,
  cell?: boolean,
  cellScan?: boolean,
  sensorId: number|string
}) => {
  const now = useRef(Date.now()).current;
  const [items, setItems] = useState<Array<RawCellListItem>>([]);
  const [loading, setLoading] = useState(false);
  const state = useRef({
    currentPage: 0
  }).current;

  const getMo = useCallback(async function getItemsAndAddthemToState(){
    if(!sensorId){ return }
    try{
      setLoading(true);
      const endOfToday = endOfDay(now).getTime(); 
      const promises = [];
      const payload = {
        sensorId, 
        // limit to 14 days
        startTimestamp: endOfToday - 14 * 24 * 60 * 60 * 1000,
        endTimestamp: endOfToday,
        limit: pageSize,
        offset: pageSize * state.currentPage
      }
      if(cell){
        promises.push(getRawCellItemsList(payload));
      }
      if(cellScan){
        promises.push(getRawCellScanItemsList(payload));
      }
      const resp = await Promise.all(promises);
      const newItems: Array<RawCellListItem> = [];
      resp?.forEach(r=>newItems.push(...r.data.items));
      setItems(items => ([...items, ...newItems]));
      state.currentPage += 1;
    }
    catch (e) {
      console.error('Error getting raw cell items', e);
    }
    finally {
      setLoading(false);
    }
  },[
    pageSize,
    cell,
    cellScan,
    state,
    setItems,
    setLoading,
    now, 
    sensorId
  ]);

  useEffect(function resetonPageSizeChange(){
    setItems([]);
    state.currentPage = 0;
  },[
    setItems,
    pageSize,
    state
  ]);

  return {
    items,
    getMo,
    loading
  }
}

export const getRawCellListItems = async ({
  source='cell',
  sensorId,
  startTimestamp,
  endTimestamp,
  limit=0,
  offset=0
}: RawCellListQueryPayload & {
  source: 'cell'|'cell-scan'
}) => {
  const payload = {
    sensorId,
    startTimestamp,
    endTimestamp,
    limit,
    offset
  };

  switch(source){
    case 'cell':
      const cellResp = await getRawCellItemsList(payload);
      console.log('[useRawCellList] got cellResp', cellResp);
      return cellResp.data.items;
    case 'cell-scan':
      const cellScanResp = await getRawCellScanItemsList(payload);
      console.log('[useRawCellList] got cellScanResp', cellScanResp);
      return cellScanResp.data.items;
  }
}