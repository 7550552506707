import React from 'react';
import {IconProps} from './_iconProps';

export const Printer: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.99999 12V2.66666H24V12M7.99999 24H5.33332C4.62608 24 3.9478 23.719 3.4477 23.2189C2.94761 22.7188 2.66666 22.0406 2.66666 21.3333V14.6667C2.66666 13.9594 2.94761 13.2811 3.4477 12.781C3.9478 12.2809 4.62608 12 5.33332 12H26.6667C27.3739 12 28.0522 12.2809 28.5523 12.781C29.0524 13.2811 29.3333 13.9594 29.3333 14.6667V21.3333C29.3333 22.0406 29.0524 22.7188 28.5523 23.2189C28.0522 23.719 27.3739 24 26.6667 24H24M7.99999 18.6667H24V29.3333H7.99999V18.6667Z" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</svg>
