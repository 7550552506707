import React from 'react';
import {IconProps} from './_iconProps';

export const Youtube: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M30.0533 8.55998C29.8949 7.92719 29.5724 7.34741 29.1182 6.87919C28.664 6.41097 28.0943 6.07089 27.4667 5.89331C25.1733 5.33331 16 5.33331 16 5.33331C16 5.33331 6.82666 5.33331 4.53333 5.94665C3.90566 6.12423 3.33596 6.4643 2.88179 6.93252C2.42762 7.40074 2.10505 7.98053 1.94666 8.61331C1.52695 10.9407 1.32164 13.3017 1.33333 15.6666C1.31837 18.0494 1.52368 20.4284 1.94666 22.7733C2.12127 23.3864 2.45107 23.9442 2.90419 24.3926C3.3573 24.8411 3.91842 25.1651 4.53333 25.3333C6.82666 25.9466 16 25.9466 16 25.9466C16 25.9466 25.1733 25.9466 27.4667 25.3333C28.0943 25.1557 28.664 24.8157 29.1182 24.3474C29.5724 23.8792 29.8949 23.2994 30.0533 22.6666C30.4698 20.3568 30.6751 18.0138 30.6667 15.6666C30.6816 13.2839 30.4763 10.9049 30.0533 8.55998Z" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
<path d="M13 20.0266L20.6667 15.6666L13 11.3066V20.0266Z" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</svg>
