import React from 'react';
import {IconProps} from './_iconProps';

export const RotateCw: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M30.6667 5.33334V13.3333M30.6667 13.3333H22.6667M30.6667 13.3333L24.4933 7.52001C22.6541 5.67966 20.2666 4.48692 17.6906 4.12153C15.1146 3.75614 12.4896 4.23789 10.2111 5.49419C7.93271 6.75049 6.12432 8.71328 5.05848 11.0868C3.99263 13.4603 3.72707 16.1159 4.30182 18.6534C4.87656 21.191 6.26047 23.473 8.245 25.1556C10.2295 26.8382 12.7072 27.8302 15.3046 27.9821C17.9019 28.1341 20.4783 27.4377 22.6455 25.998C24.8127 24.5583 26.4533 22.4532 27.32 20" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</svg>
