// mimiking localStorageSyntax since
// it was implemented initially
// to preserve values semi-pemanently per browser
// instead of redux
class objectStorage extends Map {
  setItem(...values: [any, any]){
    return this.set(...values);
  }

  getItem(...values: [any]){
    return this.get(...values);
  }
}

export const perSessionStorage = new objectStorage();
