import React, { Suspense, useState, useEffect} from 'react';

import { Route, Routes, Outlet } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import { 
  AnimatedLogo, 
  Loading,
  OverlayDisplay,
  Filler,
  Warning
} from 'components';
import {
  apiInit
} from '@ubiety/fe-api';
import {
  ENV
} from 'config';
import {
  OverlayProvider,
  ConfigProvider,
  useGlobalsContext,
  GlobalsContextProvider,
  NewReportsProvider,
  WarningsContextProvider,
  UserDataContextProvider,
  useWarningContext,
  initUbietyContext,
} from 'context';
import { 
  ContentWrapper, 
  PageWrapper,
  BodySectionWrapper 
} from 'layout';
import {
  AuthWrapper,
  Landing,
  ForensicReportItem,
  ForensicReportList,
  Activity,
  Trends
} from 'pages';
import theme, {fontFamilies} from 'theme';
import {
  GlobalStyle
} from 'theme/styles';
import {
  ScrollToTop
} from 'utils';
import WebFont from 'webfontloader';
import styled from 'styled-components';

import {
  EventsHandler
} from './EventsHandler';

import { Footer } from 'layout/Footer';
import { Navbar } from 'layout/Navbar';
import { firebaseGetUserIdAndToken, getSentryAccountContext, logError, logFBCustomEvent } from 'helpers';
import { initAppStores } from 'store/initAppStores';

import { captureException } from '@sentry/react';
import { getApp } from 'firebase/app';
import axios from 'axios';

export const App: React.FC = () => {
  return (
    /** @ts-ignore */
    <ThemeProvider theme={theme}>
      <GlobalsContextProvider>
        {/** @ts-ignore */}
        <GlobalStyle />

                      <UserDataContextProvider>
                        <NewReportsProvider>

                            <OverlayProvider>
                              <ConfigProvider>

                                  <WarningsContextProvider>
                                    <AppRoutes />
                                  </WarningsContextProvider>

                              </ConfigProvider>
                            </OverlayProvider>

                        </NewReportsProvider>
                      </UserDataContextProvider>
      </GlobalsContextProvider>
    </ThemeProvider>
  );
};

const AppRoutes: React.FC = () => (<>
  <ScrollToTop />
  <EventsHandler />
  <Routes>
    <Route path='*' element={<Wrapper />}>
      <Route index element={<Landing />} />
      <Route path="forensic-reports" element={<ForensicReportList />} />
      <Route path="forensic-reports/:id" element={<ForensicReportItem />} />
      <Route path="activity" element={<Activity />} />
      <Route path="trends" element={<Trends />} />
    </Route>
  </Routes>
  </>
);

// custom app preloading functions
export const Wrapper: React.FC = () => {
  const [loaded, setLoaded] = useState(false);
  const [initiated, setInitiated] = useState(false);
  const [isNarrow, setIsNarrow] = useState(false);
  const {
    globalLoaded
  } = useGlobalsContext();
  const {warningNodes} = useWarningContext();

  useEffect(function preloadThingsBeforeDirectionUserToTheSite(){
    WebFont.load({
      custom: {
        families: fontFamilies
      },
      loading: function() {
        console.log('[Preload][Webfont] loading');
      },
      active: function() {
        setTimeout(() => setLoaded(true), 400);
        setTimeout(() => setInitiated(true), 1000);
        console.log('[Preload][Webfont] active');
      },
      inactive: function() {
        console.log('[Preload][Webfont] inactive');
      }
    });
  },[]);

  useEffect(function bindScaleUpdate(){
    let timeout: any;

    function updateScale(){
      clearTimeout(timeout);
      timeout = setTimeout(function(){
        setIsNarrow(window.innerWidth < 1150);
      }, 500);
    }
    window.addEventListener('resize', updateScale);
    updateScale();

    return function(){
      clearTimeout(timeout);
      window.removeEventListener('resize', updateScale);
    };
  },[setIsNarrow]);

  useEffect(() => {
    const firebaseApp = getApp();
    if (firebaseApp) {
      console.log('[INIT]')
      apiInit(ENV, {
        logApiCalls: true,
      
        logError: (error, details) => {
          logError(error, {
            tags: {
              'source': 'package @ubiety/fe-api'
            },
            contexts: {
              ...getSentryAccountContext(),
              'Api Error Details': details
            }
          })
        },
        logAnalyticsEvent: (name, params) => logFBCustomEvent(name, params),
        getUserIdAndToken: firebaseGetUserIdAndToken,
      });
      
        // init Context
        initUbietyContext({
          logError: (e) => {
            console.log('[initApp] got context error', String(e));
            console.error('[initApp]', e);
            // omit local errors
            // and axios errors
            if(!(e as any).isLocal && !(axios.isAxiosError(e))){
              captureException(e, {
                tags: {
                  'source': 'package @ubiety/fe-context'
                },
                contexts: {
                  ...getSentryAccountContext()
                }
              });
            }
          },
          logStateChange: (name) => {
            // TODO: consider hooking it up to preserve state connector
            console.log('[initApp] ' + name + ' context state changed');
          },
        }, true);
      
        initAppStores();
    }
  }, []);

  return <ContentWrapper>
    <Filler />
    <Navbar
      mode={globalLoaded ? 'header' : 'logo'}
      logo={<AnimatedLogo 
        loading={!loaded} 
        width={!loaded ? 400 : !globalLoaded ? 250 : isNarrow ? 140 : undefined } 
        height={!loaded ? 40 : !globalLoaded ? 27 : isNarrow ? 20  : undefined }
        mode={!loaded ? 'emblem' : 'full'} 
        />} 
      />
    
    { initiated ?
      <AuthWrapper>
        <BodySectionWrapper>
          <PageWrapper>
            <Suspense fallback={<Loading centered height={10} width={200} strokesSettings={{numberOfStrokes: 40, strokesSpaces: 45}} />}>
              <StyledWarningWrapper>
                {warningNodes?.map(wr => (
                  <Warning
                    key={wr.uid}
                    title={wr.title}
                    details={wr.details}
                    children={wr.children}
                    onClose={wr.onClose} />
                ))}
              </StyledWarningWrapper>
              <Outlet />
              <OverlayDisplay />
            </Suspense>
          </PageWrapper>
          <Footer />
        </BodySectionWrapper>
      </AuthWrapper>
      : null
    }

    <Filler />
  </ContentWrapper>
}

const StyledWarningWrapper = styled.div`${({theme}) => `
  margin-top: -${theme.boxMargins.l}px;
  margin-bottom: ${theme.boxMargins.xl2}px;
  z-index: 10;
  transition: height 300ms;
  > *:not(:first-child) {
    margin-top: 20px; 
  }
`}`;

// routes that we want to explicitly name
// see https://github.com/icd2k3/use-react-router-breadcrumbs#usage for details
export const NamedRoutes = [
  {path: '/tmsi-report', breadcrumb: 'TMSI Report'},
  {path: '/tmsi-report/new-tmsi-export', breadcrumb: 'New TMSI Export'}
];


