import {useRef, useState, useEffect, useCallback, useMemo} from 'react';

import {objectUid} from '../../utils/UID';
import styles from './DataTable.module.css';


// This assumes an input of array of objects 
// and displays it in a form of table
// building index if not passed
// and running formatting function on each cell with key matching dataFormating map's key name
// 
/*
  SAMPLE PAYLOAD:
  const someItem = {name: 'myName', number: "numberValueToFormat", date: "this is a date", notNeededValue: "nada"};

  {
    data: [
      someItem, {...someItem}, {...someItem}
    ],
    classesMap: new Map(someItem, 'bgColorBlueClassName'),
    index: ["name", "number", "date"],
    mixinIndex: ["actionButtons"],
    dataFormatting: {
      number: v => Number(v),
      date: v => (new Date(v)).getLocaleString(),
      actionButtons: (v,item) => <button onClick={() => console.log('Item's action button clicked:', {v,item})}>click me</button>
    }
  }
*/ 

// TODO add header index naming Map

export type DataTableProps = {
  data: {
    [key: string]: any
  }[] | undefined,
  classesMap?: Map<any, string>;
  index?: string[];
  mixinIndex?: string[];
  /*
  * Rows we want to add as a mixins 
  * object with an index number
  */
  mixinRows?:{
    [key: number]: React.ReactNode;
  }
  dataFormating?: {
    [key: string]: (value: any, dataItem: any) => any
  }
  titlesFormatting?: {
    [key: string]: (value: any) => React.ReactNode
  },
  className?: string
}
export const DataTable: React.FC<DataTableProps> = ({
  data:dataInp=[],
  classesMap=(new Map()), // add in classes based on item object
  index:passedIndex, // = [];
  mixinIndex, //=["mixin"], // index to mixin with main one, like action button creators or something
  mixinRows={},
  dataFormating={ // data formating functions by key names
    createdAt: v => (new Date(v)).toLocaleString(),
    updatedAt: v => (new Date(v)).toLocaleString(),
    description: v => ((v+'').length > 25) ? (v+'').slice(0,25)+'...' : v
  },
  titlesFormatting={},
  className='data-table'
}) => {

  const data = useMemo<DataTableProps['data']>(()=> dataInp || [], [dataInp]);

  const getCellValue = useCallback(function getCellValue(item, key){
    const formatter = dataFormating[key];
    const value = item[key];
    return typeof formatter == 'function' ? formatter(value, item) : String(value);
  }, [dataFormating]);

  const getTitleValue = useCallback(function getTitleValue(key){
    const formatter = titlesFormatting[key];
    return typeof formatter == 'function' ? formatter(key) : String(key);
  }, [titlesFormatting]);

  // ? Create index if not passed

  const [indexNames, setIndexNames] = useState<string[]>([]);
  const indexMap = useRef<Map<string, number>>();

  // Index
  useEffect(function assignPassedIndexOrCreateNew(){
    const names: string[] = [];
    const namesMap = new Map<string, number>();

    const mixins = Array.isArray(mixinIndex) ? [...mixinIndex] : [];
    const mixinsMap = new Map();

    // set mixins map to true for now
    mixins?.forEach(name => {
      mixinsMap.set(name, true);
    });

    // build index if not passed
    if(!passedIndex){
      
      // index each item's keys into names and namesMap
      // excluding ones in the mixin index
      data?.forEach((item:  any) => {
        Object.keys(item)?.forEach(key => {
          if(typeof namesMap.get(key) === 'undefined' && !mixinsMap.get(key)){
            const newPosition = names.length;
            namesMap.set(key, newPosition);
            names.push(key);
          }
        });
      });
    }
    else {
      Array.prototype.push.apply(names, passedIndex);
      
      names?.forEach( (name,i) => {
        namesMap.set(name, i);
      });
    }

    // add mixins into main map
    mixins?.forEach(key => {
      const newPosition = names.length;
      namesMap.set(key, newPosition);
      names.push(key);
    });

    // set main map to ref and trigger the update
    indexMap.current = namesMap;
    setIndexNames(names);

    console.log('[DataTable:setIndexNames]', names);

  }, [ data, passedIndex, mixinIndex]);
  

  const rows: React.ReactNode[] = useMemo(function(){
    const rows: React.ReactNode[] = [];
    // loop through data:
    data?.forEach((item) => {

      const rowChildren: React.ReactNode[] = [];
      const itemKey = objectUid(item);
      const classes = classesMap.get(item);

      // read index or item keys AND mixin index into current row
      (indexNames)?.forEach(key => {
        
        const cellPosition = indexMap.current?.get(key);

        // create row
        if(cellPosition!==undefined){
          rowChildren[cellPosition] = <Cell key={key}>{getCellValue(item, key)}</Cell>;
        }
      
      });

      rows.push(<Row className={classes} key={itemKey}>{rowChildren}</Row>);

    });

    // create header
    const headerRow = <Row key="header">{indexNames?.map( (dataName) => <HeaderCell key={dataName}>{getTitleValue(dataName)}</HeaderCell> )}</Row>;

    // add it before content rows
    rows.splice(0,0, headerRow); 

    // add in rows mixins
    Object.entries(mixinRows)
      // sort in revert order so new addons dont interfear with indexing
      .sort((ma, mb) => Number(ma[0]) > Number(mb[0]) ? -1 : 1)
      ?.forEach(([key, value]) => {
        rows.splice(Number(key), 0, <tr key={objectUid(value)} className="mixin">{value}</tr>);
      });

    return rows;
  }, [getCellValue, getTitleValue, indexNames, classesMap, data, mixinRows]);

  return <Table className={className}>{rows}</Table>;
};


// 'Styled'
// TODO: change to styled as we go
export const HeaderCell: React.FC = ({
  children
}) => {
  return <th>{children}</th>;
};

export const Cell: React.FC = ({
  children,
  ...args
}) => {
  return <td {...args}>{children}</td>;
};

export const Row: React.FC<{
  className?: string;
}> = ({
  className,
  children
}) => {
  return <tr className={className}>{children}</tr>;
};

export const Table: React.FC<{
  className?:string
}> = ({
  className='',
  children
}) => {
  return <table className={styles.table+' '+className+' data-table'}><tbody>{children}</tbody></table>;
};