import React from 'react';
import {IconProps} from './_iconProps';

export const Instagram: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M23.3333 8.66666H23.3467M9.33334 2.66666H22.6667C26.3486 2.66666 29.3333 5.65143 29.3333 9.33333V22.6667C29.3333 26.3486 26.3486 29.3333 22.6667 29.3333H9.33334C5.65144 29.3333 2.66667 26.3486 2.66667 22.6667V9.33333C2.66667 5.65143 5.65144 2.66666 9.33334 2.66666ZM21.3333 15.16C21.4979 16.2697 21.3083 17.403 20.7917 18.3987C20.275 19.3944 19.4575 20.2019 18.4555 20.7062C17.4535 21.2106 16.3179 21.3861 15.2104 21.2079C14.1028 21.0297 13.0797 20.5068 12.2865 19.7135C11.4932 18.9203 10.9703 17.8972 10.7921 16.7896C10.6139 15.6821 10.7894 14.5465 11.2938 13.5445C11.7981 12.5425 12.6056 11.725 13.6013 11.2083C14.597 10.6917 15.7303 10.5021 16.84 10.6667C17.9719 10.8345 19.0198 11.3619 19.8289 12.1711C20.6381 12.9802 21.1655 14.0281 21.3333 15.16Z" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</svg>
