import React from 'react';
import {IconProps} from './_iconProps';

export const Speaker: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M16 8.00002H16.0133M8.00001 2.66669H24C25.4728 2.66669 26.6667 3.86059 26.6667 5.33335V26.6667C26.6667 28.1394 25.4728 29.3334 24 29.3334H8.00001C6.52725 29.3334 5.33334 28.1394 5.33334 26.6667V5.33335C5.33334 3.86059 6.52725 2.66669 8.00001 2.66669ZM21.3333 18.6667C21.3333 21.6122 18.9455 24 16 24C13.0545 24 10.6667 21.6122 10.6667 18.6667C10.6667 15.7212 13.0545 13.3334 16 13.3334C18.9455 13.3334 21.3333 15.7212 21.3333 18.6667Z" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</svg>
