import React from 'react';
import {IconProps} from './_iconProps';

export const Coffee: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M24 10.6666H25.3333C26.7478 10.6666 28.1044 11.2285 29.1046 12.2287C30.1048 13.2289 30.6667 14.5855 30.6667 16C30.6667 17.4145 30.1048 18.771 29.1046 19.7712C28.1044 20.7714 26.7478 21.3333 25.3333 21.3333H24M24 10.6666H2.66667V22.6666C2.66667 24.0811 3.22858 25.4377 4.22877 26.4379C5.22896 27.4381 6.58552 28 8.00001 28H18.6667C20.0812 28 21.4377 27.4381 22.4379 26.4379C23.4381 25.4377 24 24.0811 24 22.6666V10.6666ZM8.00001 1.33331V5.33331M13.3333 1.33331V5.33331M18.6667 1.33331V5.33331" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</svg>
