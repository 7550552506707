import { apiRequest } from '../apiRequest';
import { CreateProfilePayload, GetHomeProfilesPayload, RawProfile, UpdateProfilePayload } from './types';

export const createProfile = (homeId: number, data: CreateProfilePayload) =>
  apiRequest<RawProfile>({
    service: 'feApiGateway',
    path: `homes/${homeId}/profiles`,
    method: 'POST',
    data,
  });

export const updateProfile = (homeId: number, profileUid: number, data: UpdateProfilePayload) =>
  apiRequest<RawProfile>({
    service: 'feApiGateway',
    path: `homes/${homeId}/profiles/${profileUid}`,
    method: 'PATCH',
    data,
  });

export const deleteProfile = (homeId: number, profileUid: number) =>
  apiRequest({
    service: 'feApiGateway',
    path: `homes/${homeId}/profiles/${profileUid}`,
    method: 'DELETE',
  });

export const getHomeProfiles = ({ homeId, offset = 0, limit = 100 }: GetHomeProfilesPayload) =>
  apiRequest<RawProfile>({
    service: 'feApiGateway',
    path: `homes/${homeId}/profiles?limit=${limit}&offset=${offset}`,
    method: 'GET',
  });

export const getProfileById = (homeId: number, profileUid: number) => 
  apiRequest<RawProfile>({
    service: 'feApiGateway',
    path: `homes/${homeId}/profiles/${profileUid}`,
    method: 'GET',
  });
