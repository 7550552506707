import React from 'react';
import {IconProps} from './_iconProps';

export const ShoppingCart: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1.33334 1.33334H6.66668L10.24 19.1867C10.3619 19.8005 10.6959 20.352 11.1834 20.7444C11.6709 21.1369 12.2809 21.3453 12.9067 21.3333H25.8667C26.4924 21.3453 27.1024 21.1369 27.5899 20.7444C28.0775 20.352 28.4114 19.8005 28.5333 19.1867L30.6667 8.00001H8.00001M13.3333 28C13.3333 28.7364 12.7364 29.3333 12 29.3333C11.2636 29.3333 10.6667 28.7364 10.6667 28C10.6667 27.2636 11.2636 26.6667 12 26.6667C12.7364 26.6667 13.3333 27.2636 13.3333 28ZM28 28C28 28.7364 27.4031 29.3333 26.6667 29.3333C25.9303 29.3333 25.3333 28.7364 25.3333 28C25.3333 27.2636 25.9303 26.6667 26.6667 26.6667C27.4031 26.6667 28 27.2636 28 28Z" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</svg>
