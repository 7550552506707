export type RelayItem = {
  timeout: ReturnType<typeof setTimeout> | null
  callout: () => void
}
export class Relay{
  relays: {
    [key: string]: RelayItem
  }={};
  delay=500;

  constructor(settings?: Partial<Pick<Relay, 'delay'>>){
    for(const key in settings){
      if(Object.prototype.hasOwnProperty.call(settings, key)){
        (this as any)[key] = (settings as any)[key];
      }
    }
  }
  static makeRelay(fn: () => void){
    return {
      timeout: null,
      callout: fn
    };
  }
  addRelay(name: string, r: RelayItem): RelayItem{
    this.relays[name] = r;
    return r;
  }
  removeRelay(name: string): Relay{
    delete this.relays[name];
    return this;
  }
  handle(identifier: string, fn: () => void, customDelay?: number){
    if(!fn){
      return;
    }
    // create relay
    if(!this.relays[identifier]){
      this.addRelay(identifier, Relay.makeRelay(fn));
    // or update callout function
    } else {
      this.relays[identifier].callout = fn;
    }
    const relay = this.relays[identifier];
    clearTimeout(relay.timeout||(0 as any));
    relay.timeout = setTimeout(() => relay.callout(), (customDelay !== undefined)?customDelay:this.delay);
  }
}