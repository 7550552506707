// TODO: consider multiple app versions scenario 
// if context is used for different projects and 
// ether provide prefix for app version, aka: `{core-app: {minimum, current}` 
// or potentially move this context and api hook for it into individual projects
import { getAppVersion } from '@ubiety/fe-api';
import { createStore } from 'zustand/vanilla';

// import { persist } from 'zustand/middleware';
import { CONTEXT_GLOBAL } from '../globals';
import { fnSilentWrapper, withCatcher, processRawAppVersionData } from '../helpers';
import { DataStatus, Maybe, PromiseVoid } from '../types';
import { compareVersions } from '../utils';

interface IAppVersionState {
  appVersionData?: ReturnType<typeof processRawAppVersionData>;
  appVersionDataStatus: DataStatus;
  behindMinimum: boolean;
  behindCurrent: boolean;
  localVersion: string|undefined;
  minimumVersion: string|undefined;
  currentVersion: string|undefined;
}

type AppVersionAction = {
  reset: () => void;
  loadAppVersion: () => PromiseVoid;
  loadAppVersionSilently: () => Promise<Maybe<boolean>>;
}

export type AppVersionState = IAppVersionState & AppVersionAction;

const INITIAL_STATE: IAppVersionState = {
  appVersionData: undefined,
  appVersionDataStatus: undefined,
  behindMinimum: false,
  behindCurrent: false,
  minimumVersion: undefined,
  currentVersion: undefined,
  localVersion: undefined,
}; 

export const appVersionStore = createStore<AppVersionState>()(
  // persist(
    (set, get) => ({
      ...INITIAL_STATE,
      reset: () => set(INITIAL_STATE),
      loadAppVersion: withCatcher('AppVersionStore.loadAppVersion', async () => {
        const response = await getAppVersion();

        set((state) => {
          const localVersion = CONTEXT_GLOBAL.getInstalledAppVersion();

          if (!response.data||!localVersion) { return state; }

          return {
            appVersionData: processRawAppVersionData(response.data),
            appVersionDataStatus: 'success',
            behindMinimum: !!response.data.minimum_version &&
              compareVersions(localVersion, response.data.minimum_version)
                ?.comparison === 'behind',
            behindCurrent: !!response.data.current_version &&
              compareVersions(localVersion, response.data.current_version)
                ?.comparison === 'behind'
                ,
            minimumVersion: response.data.minimum_version,
            currentVersion: response.data.minimum_version,
            localVersion
          };
        });
      }, () => {
        set({ appVersionDataStatus: 'error' });
      }),
      loadAppVersionSilently: fnSilentWrapper(() => get().loadAppVersion()),
    }),
  //   {
  //     name: 'app-version-storage',
  //   },
  // ),
);

