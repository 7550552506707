import { createStore } from 'zustand/vanilla';

import { Listeners } from '../classes';
import { CONTEXT_GLOBAL } from '../globals';
import { withCatcher } from '../helpers';

type RawUpdatePayload = {
  type: 'resource_update',
  details: {
    home_id: number,
    resource: string,
    resource_id?: string
  }
}

export type UpdatePayload = {
  homeId: number,
  resource: 'profile'|'device'|'device_enter_exit'|'home'|'alert'|'sensor'|'account'|'daemon'|'notification_preferences';
  resourceId?: string
}

interface IWebSocketState {
  currentSocket?: WebSocket,
}

type WebSocketAction = {
  reset: () => void;
  openSocket: (address: string) => Promise<WebSocket | null>;
  onError: Listeners<unknown>,
  onClose: Listeners<unknown>,
  onOpen: Listeners<Event>,
  onMessage: Listeners<UpdatePayload>,
}

export type WebSocketState = IWebSocketState & WebSocketAction;

const INITIAL_STATE: IWebSocketState = {
  currentSocket: undefined
};

export const webSocketStore = createStore<WebSocketState>()(
  (set, get) => ({
    ...INITIAL_STATE,
    onError: new Listeners(),
    onMessage: new Listeners(),
    onClose: new Listeners(),
    onOpen: new Listeners(),
    openSocket: withCatcher('updatesStore.openSocket', async (address) => {
      const {onClose, onError, onMessage, onOpen} = get();
      const socket = new WebSocket(address);

      socket.onerror = (e: unknown) => {
        set({currentSocket: undefined});
        onError.fire(e);
      };
      socket.onclose = () => {
        set({currentSocket: undefined});
        onClose.fire();
      };
      socket.onopen = () => {
        onOpen.fire();
      };
      socket.onmessage = (evt: any & {data?: string}) => {
        try {
          const data = JSON.parse(evt.data);
          console.log('[updatesStore][openSocket] got update:', data);
          const {type, details: {home_id, resource, resource_id}} = data as RawUpdatePayload||{};
          if(type === 'resource_update'){
            // optionally check if received payload is valid
            onMessage.fire({
              homeId: home_id,
              resource: resource as UpdatePayload['resource'],
              resourceId: resource_id
            });
          }
        }
        catch (error) {
          CONTEXT_GLOBAL.logError(error, 'updatesStore.currentSocket.onmessage');
        }
      };
      set({currentSocket: socket});
      return socket;
    }),
    reset: () => {
      get().currentSocket?.close();
    }
  })
);

