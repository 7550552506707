import React from 'react';
import {IconProps} from './_iconProps';

export const Slack: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M19.3333 13.3334C18.2267 13.3334 17.3333 12.44 17.3333 11.3334V4.66669C17.3333 3.56002 18.2267 2.66669 19.3333 2.66669C20.44 2.66669 21.3333 3.56002 21.3333 4.66669V11.3334C21.3333 12.44 20.44 13.3334 19.3333 13.3334Z" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
<path d="M27.3333 13.3334H25.3333V11.3334C25.3333 10.2267 26.2267 9.33335 27.3333 9.33335C28.44 9.33335 29.3333 10.2267 29.3333 11.3334C29.3333 12.44 28.44 13.3334 27.3333 13.3334Z" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
<path d="M12.6667 18.6667C13.7733 18.6667 14.6667 19.56 14.6667 20.6667V27.3334C14.6667 28.44 13.7733 29.3334 12.6667 29.3334C11.56 29.3334 10.6667 28.44 10.6667 27.3334V20.6667C10.6667 19.56 11.56 18.6667 12.6667 18.6667Z" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
<path d="M4.66667 18.6667H6.66667V20.6667C6.66667 21.7734 5.77333 22.6667 4.66667 22.6667C3.56 22.6667 2.66667 21.7734 2.66667 20.6667C2.66667 19.56 3.56 18.6667 4.66667 18.6667Z" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
<path d="M18.6667 19.3334C18.6667 18.2267 19.56 17.3334 20.6667 17.3334H27.3333C28.44 17.3334 29.3333 18.2267 29.3333 19.3334C29.3333 20.44 28.44 21.3334 27.3333 21.3334H20.6667C19.56 21.3334 18.6667 20.44 18.6667 19.3334Z" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
<path d="M20.6667 25.3334H18.6667V27.3334C18.6667 28.44 19.56 29.3334 20.6667 29.3334C21.7733 29.3334 22.6667 28.44 22.6667 27.3334C22.6667 26.2267 21.7733 25.3334 20.6667 25.3334Z" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
<path d="M13.3333 12.6667C13.3333 11.56 12.44 10.6667 11.3333 10.6667H4.66667C3.56 10.6667 2.66667 11.56 2.66667 12.6667C2.66667 13.7734 3.56 14.6667 4.66667 14.6667H11.3333C12.44 14.6667 13.3333 13.7734 13.3333 12.6667Z" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
<path d="M11.3333 6.66669H13.3333V4.66669C13.3333 3.56002 12.44 2.66669 11.3333 2.66669C10.2267 2.66669 9.33333 3.56002 9.33333 4.66669C9.33333 5.77335 10.2267 6.66669 11.3333 6.66669Z" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</svg>
