import React from 'react';
import {IconProps} from './_iconProps';

export const Users: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M22.6667 28V25.3333C22.6667 23.9188 22.1048 22.5623 21.1046 21.5621C20.1044 20.5619 18.7478 20 17.3333 20H6.66666C5.25217 20 3.89562 20.5619 2.89543 21.5621C1.89523 22.5623 1.33333 23.9188 1.33333 25.3333V28M30.6667 28V25.3333C30.6658 24.1516 30.2725 23.0037 29.5485 22.0698C28.8245 21.1358 27.8108 20.4688 26.6667 20.1733M21.3333 4.17333C22.4805 4.46707 23.4974 5.13427 24.2235 6.06975C24.9496 7.00523 25.3438 8.15577 25.3438 9.34C25.3438 10.5242 24.9496 11.6748 24.2235 12.6103C23.4974 13.5457 22.4805 14.2129 21.3333 14.5067M17.3333 9.33333C17.3333 12.2789 14.9455 14.6667 12 14.6667C9.05448 14.6667 6.66666 12.2789 6.66666 9.33333C6.66666 6.38781 9.05448 4 12 4C14.9455 4 17.3333 6.38781 17.3333 9.33333Z" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</svg>
