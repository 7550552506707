import styled, {keyframes, css} from 'styled-components';

const appear = keyframes`
  0% {
    max-height: 5px;
  }
  100% {
    max-height: 10000px;
  }
`;

export const BodySectionWrapper = styled.div`${({theme}) => css`
  flex-grow: 1000;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  transition: max-height 2s; 
  
  animation: 4s ${appear};
  
`}`;