import React from 'react';
import {IconProps} from './_iconProps';

export const CheckCircle: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M29.3333 14.7734V16C29.3317 18.8753 28.4007 21.6729 26.6791 23.9758C24.9576 26.2787 22.5377 27.9633 19.7805 28.7786C17.0232 29.5938 14.0763 29.4959 11.3793 28.4995C8.68224 27.503 6.37954 25.6615 4.81461 23.2494C3.24969 20.8374 2.50639 17.9841 2.69557 15.1151C2.88475 12.2461 3.99627 9.51512 5.86437 7.32945C7.73247 5.14378 10.257 3.62053 13.0616 2.98688C15.8661 2.35324 18.8004 2.64314 21.4267 3.81336M29.3333 5.33336L16 18.68L12 14.68" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</svg>
