import React from 'react';
import {IconProps} from './_iconProps';

export const PenTool: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M2.66667 2.66666L22 7.33332L24 17.3333L17.3333 24L7.33334 22L2.66667 2.66666ZM2.66667 2.66666L12.7813 12.7813M16 25.3333L25.3333 16L29.3333 20L20 29.3333L16 25.3333ZM17.3333 14.6667C17.3333 16.1394 16.1394 17.3333 14.6667 17.3333C13.1939 17.3333 12 16.1394 12 14.6667C12 13.1939 13.1939 12 14.6667 12C16.1394 12 17.3333 13.1939 17.3333 14.6667Z" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</svg>
