import { apiRequest } from '../apiRequest';
import { APIResponse } from '../types';
import { 
  CreateUserPayload,
  DeleteUserResponse,
  UpdateUserPayload, 
  UserResponseData,
} from './types';

export const createUser = (data: CreateUserPayload) =>
  apiRequest<APIResponse<UserResponseData>>({
    service: 'feApiGateway',
    path: 'users',
    method: 'POST',
    data,
    withAuth: false,
  });

export const updateUser = (uid: string, data: UpdateUserPayload) =>
  apiRequest<UserResponseData>({
    service: 'feApiGateway',
    path: `users/${uid}`,
    method: 'PATCH',
    data,
  });

export const getUser = (uid: string) =>
  apiRequest<UserResponseData>({
    service: 'feApiGateway',
    path: `users/${uid}`,
    method: 'GET',
  });

export const deleteUser = (uid: string) =>
  apiRequest<DeleteUserResponse>({
    service: 'feApiGateway',
    path: `users/${uid}`,
    method: 'DELETE',
  });

export const updateUserOnboardingSteps = (
  uid: string,
  onboardingSteps: Record<string, boolean | undefined>,
) => updateUser(uid, { onboarding_steps: onboardingSteps });