import {
  TellMeWhenApiNewItemPayload,
  saveTellMeWhenSuspicious as saveTellMeWhenSuspiciousApi,
  updateTellMeWhenSuspicious as updateTellMeWhenSuspiciousApi,
  getAllTellMeWhenSuspicious as getAllTellMeWhenSuspiciousApi,
  deleteTellMeWhenSuspicious as deleteTellMeWhenSuspiciousApi,
} from '@ubiety/fe-api';
import { createStore } from 'zustand/vanilla';

// import { persist } from 'zustand/middleware';
import { getHomeId } from './selectors';
import { ContextError } from '../classes';
import {
  withCatcher,
  fnSilentWrapper,
  apiItemToTellMeWhenSuspiciousItem,
  tellMeWhenSuspiciousItemToApiItem,
} from '../helpers';
import { DataStatus, Maybe, PromiseVoid } from '../types';
import { TellMeWhenSuspiciousItem } from '../types/tell_me_when';

interface ITellMeWhenState {
  tellMeWhenSuspiciousItems: Array<TellMeWhenSuspiciousItem>;
  tellMeWhenDataStatus: DataStatus;
}

type TellMeWhenAction = {
  reset: () => void;
  getAllTellMeWhenSuspicious: () => PromiseVoid;
  getAllTellMeWhenSuspiciousSilently: () => Promise<Maybe<boolean>>;
  deleteTellMeWhenSuspicious: (uid: string | number) => Promise<void>;
  deleteTellMeWhenSuspiciousSilently: (uid: string | number) => Promise<Maybe<boolean>>;
  addTellMeWhenSuspicious: (item: TellMeWhenSuspiciousItem) => Promise<Maybe<TellMeWhenSuspiciousItem>>;
  addTellMeWhenSuspiciousSilently: (item: TellMeWhenSuspiciousItem) => Promise<Maybe<boolean>>;
  updateTellMeWhenSuspicious: (item: Partial<TellMeWhenSuspiciousItem> & Pick<TellMeWhenSuspiciousItem, 'uid'>) => Promise<Maybe<TellMeWhenSuspiciousItem>>;
  updateTellMeWhenSuspiciousSilently: (item: Partial<TellMeWhenSuspiciousItem> & Pick<TellMeWhenSuspiciousItem, 'uid'>) => Promise<Maybe<boolean>>;
};

export type TellMeWhenState = ITellMeWhenState & TellMeWhenAction;

const INITIAL_STATE: ITellMeWhenState = {
  tellMeWhenSuspiciousItems: [],
  tellMeWhenDataStatus: undefined,
};

export const tellMeWhenStore = createStore<TellMeWhenState>()(
  // persist(
    (set, get) => ({
      ...INITIAL_STATE,
      reset: () => set(INITIAL_STATE),
      getAllTellMeWhenSuspicious: withCatcher('tellMeWhenStore.getAllTellMeWhenSuspicious', async () => {
        const homeId = getHomeId();
        if (!homeId) { 
          throw new ContextError('no home id to get tell me whens by', {isLocal: true });
        }

        const apiItems = await getAllTellMeWhenSuspiciousApi({ homeId });
        const processedItems = apiItemToTellMeWhenSuspiciousItem(apiItems);

        console.log('[TellMeWhenStore] received suspicious response is', processedItems);
        set({ tellMeWhenSuspiciousItems: processedItems, tellMeWhenDataStatus: 'success' });
      }, (e) => {
        if(e.isLocal) {return;}
          set({ tellMeWhenDataStatus: 'error' });
      }),
      getAllTellMeWhenSuspiciousSilently: fnSilentWrapper(() => get().getAllTellMeWhenSuspicious()),
      deleteTellMeWhenSuspicious: withCatcher('tellMeWhenStore.deleteTellMeWhenSuspicious', async (uid) => {
        await deleteTellMeWhenSuspiciousApi(uid);
        // if success -> remove locally
        const { tellMeWhenSuspiciousItems } = get();
        const updatedTellMeWhens = [...tellMeWhenSuspiciousItems];
        const indexOfDeleteditem = tellMeWhenSuspiciousItems.findIndex(item => item.uid === uid);
        if (indexOfDeleteditem > -1) {
          updatedTellMeWhens.splice(indexOfDeleteditem, 1);
        }

        set({ tellMeWhenSuspiciousItems: updatedTellMeWhens });
      }),
      deleteTellMeWhenSuspiciousSilently: fnSilentWrapper((uid) => get().deleteTellMeWhenSuspicious(uid)),
      addTellMeWhenSuspicious: withCatcher('tellMeWhenStore.addTellMeWhenSuspicious', async (item) => {
        const homeId = getHomeId();
        if (!homeId) { 
          throw new ContextError('there is no home id to add tmw suspicious', {isLocal: true});
        }

        const processedItem = tellMeWhenSuspiciousItemToApiItem(homeId, item);
        const savedItem = await saveTellMeWhenSuspiciousApi(processedItem as TellMeWhenApiNewItemPayload);
        const processedSavedItem = apiItemToTellMeWhenSuspiciousItem(savedItem)[0];

        console.log('[TellMeWhenStore] adding tell me when', { savedItem, processedSavedItem });
        const { tellMeWhenSuspiciousItems } = get();

        set({ tellMeWhenSuspiciousItems: [...tellMeWhenSuspiciousItems, processedSavedItem] });

        return processedSavedItem;
      }),
      addTellMeWhenSuspiciousSilently: fnSilentWrapper((item) => get().addTellMeWhenSuspicious(item)),
      updateTellMeWhenSuspicious: withCatcher('tellMeWhenStore.updateTellMeWhenSuspicious', async (item) => {
        const homeId = getHomeId();
        if (!homeId) { 
          throw new ContextError('there is no home id to update tmw suspicious', {isLocal: true});
        }

        const processedItem = tellMeWhenSuspiciousItemToApiItem(homeId, item);
        const updatedItem = await updateTellMeWhenSuspiciousApi(processedItem);
        const processedUpdatedItem = apiItemToTellMeWhenSuspiciousItem(updatedItem)[0];

        console.log('[TellMeWhenStore] update item', { processedItem, updatedItem, processedUpdatedItem });
        const { tellMeWhenSuspiciousItems } = get();
        const itemToUpdate = tellMeWhenSuspiciousItems.findIndex(i => i.uid === item.uid);
        const newData = [...tellMeWhenSuspiciousItems];
        newData.splice(itemToUpdate, 1, processedUpdatedItem);
        set({ tellMeWhenSuspiciousItems: newData });

        return processedUpdatedItem;
      }),
      updateTellMeWhenSuspiciousSilently: fnSilentWrapper((item) => get().updateTellMeWhenSuspicious(item)),
    }),
  //   {
  //     name: 'tell-me-when-storage',
  //   },
  // ),
);