import React from 'react';

import styled from 'styled-components';

import styles from './Input.module.css';

export type PlainFormProps = {
  condenced?:boolean;
  refference?:React.RefObject<HTMLFormElement>;
  warnHeader?:React.ReactNode;
  infoHeader?:React.ReactNode;
  successHeader?:React.ReactNode;
}
export const PlainForm: React.FC<PlainFormProps & React.HTMLProps<HTMLFormElement>> = ({
  refference,
  condenced=false,
  className='',
  warnHeader,
  infoHeader,
  successHeader,
  onSubmit,
  children
}) =>
  <StyledForm
    ref={refference||null}
    condenced
    className={(condenced?' '+styles.condenced:'')+' '+className}
    onSubmit={onSubmit} >
    {warnHeader
      ?
      <WarnHeader className='warn-header form-header'>{warnHeader}</WarnHeader>
      :
     infoHeader
      ?
      <InfoHeader className='info-header form-header'>{infoHeader}</InfoHeader>
      :
     successHeader
      ?
      <SuccessHeader className='success-header form-header'>{successHeader}</SuccessHeader>
      :
      null
    }

    {children}
  </StyledForm>;

const InfoHeader = styled.div`
  background-color: ${({theme}) => theme.colors.blue.light};
  padding: ${({theme}) => theme.boxPadding.xl3/2}px ${({theme}) => theme.boxPadding.xl3}px;
  font-weight: bold;
  color: ${({theme}) => theme.colors.sky.white};
`;

const WarnHeader = styled.div`
  background-color: ${({theme}) => theme.colors.red.light};
  padding: ${({theme}) => theme.boxPadding.xl3/2}px ${({theme}) => theme.boxPadding.xl3}px;
  font-weight: bold;
  color: ${({theme}) => theme.colors.sky.white};
`;

const SuccessHeader = styled.div`
  background-color: ${({theme}) => theme.colors.green.light};
  padding: ${({theme}) => theme.boxPadding.xl3/2}px ${({theme}) => theme.boxPadding.xl3}px;
  font-weight: bold;
  color: ${({theme}) => theme.colors.sky.white};
`;

const StyledForm = styled.form.attrs({} as PlainFormProps)`${({theme, condenced}) => `

  display: inline-block;

  font-family: ${theme.defaultFontFamily};

  input:not([type="checkbox"]):not([type="radio"]), select {
    margin-top: ${theme.fontMargins.xxxxs}px;
    min-width: 327px;
    box-sizing: border-box;
  }
  .input-container {
    position: relative;
  }

  b {
    max-width: 261px;
  }

  button {
    min-width:100%;
  }

  input {

  }

  h2 {
    margin-top: 0px;
  }

  > *:not(:first-child) {
    margin-top: ${theme.fontMargins.base}px;
    display: block;
  }

  .input-group[name="password"]{
    margin-bottom: ${theme.boxMargins.xl8}px;
  }

  ${condenced && `
    input:not([type="checkbox"]):not([type="radio"]) + *,
    input {
      margin-top: ${theme.fontMargins.xxxxs}px;
    }
    .input-group a {
      margin-top: ${theme.fontMargins.xxxxs}px;
    }
  `}

  > a {
    text-align: center;
  }

  a {
    text-decoration: none;
  }

`}`;
