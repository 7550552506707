import { apiRequest } from '../apiRequest';
import { AlertGetResponse, AlertUpdateResponse, AlertsGetQueryPayload, AlertsGetResponse, AlertsMarkAllAsReadResponse } from './types';

export const getAlert = (homeId: number, alertUid: string) =>
  apiRequest<AlertGetResponse>({
    service: 'feApiGateway',
    path: `homes/${homeId}/alerts/${alertUid}`,
    method: 'GET',
  });

export const getAlerts = (homeId: number, queryPayload: AlertsGetQueryPayload) => {
  let queryString = '?';
  

  for (const key in queryPayload) {
    if (Object.prototype.hasOwnProperty.call(queryPayload, key) && key !== 'type') {
      queryString += key + '=' + (queryPayload as any)[key] + '&';
    }
    else if (key === 'type') {
      const types_arr: Array<string> = (queryPayload as any)[key];

      types_arr.forEach(alertType => {
        queryString += 'type=' + alertType + '&';
      });
    }
  }

  return apiRequest<AlertsGetResponse>({
    service: 'feApiGateway',
    path: `homes/${homeId}/alerts/${queryString}`,
    method: 'GET'
  });
};

export const markAlertAsRead = (homeId: number, alertUid: string) =>
  apiRequest<AlertUpdateResponse>({
    service: 'feApiGateway',
    path: `homes/${homeId}/alerts/${alertUid}/`,
    method: 'PATCH',
    data: {
      acknowledged_timestamp: Math.round(new Date().getTime()),
    },
  });

export const markAllAlertsAsReadApi = (homeId: number) =>
  apiRequest<AlertsMarkAllAsReadResponse>({
    service: 'feApiGateway',
    path: `homes/${homeId}/alerts/acknowledge`,
    method: 'POST',
  });