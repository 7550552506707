import React from 'react';
import {IconProps} from './_iconProps';

export const Underline: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.99999 4V13.3333C7.99999 15.4551 8.84285 17.4899 10.3431 18.9902C11.8434 20.4905 13.8783 21.3333 16 21.3333C18.1217 21.3333 20.1566 20.4905 21.6568 18.9902C23.1571 17.4899 24 15.4551 24 13.3333V4M5.33333 28H26.6667" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</svg>
