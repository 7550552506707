import { useEffect, useMemo, useState } from 'react';

import {
  BluetoothAdvPacketPayload,
  ActiveBluetoothPayload,
  CellPayload,
  WifiNetworkedPayload,
  WifiProbeRequestPayload,
  HealthPayload,
} from '@ha_schemas/ha_schemas';
import { PubSub } from 'aws-amplify';
import { ENV } from 'config/settings';

export interface MQTTHookConfig<T> {
  onNext?: (data: T) => void;
  onError?: (error: Error) => void;
  onClose?: () => void;
  onEffect?: () => void;
  onEffectCleanup?: () => void;
  shouldBeSubscribed?: boolean;
}
// TODO: Add "collect" argument to collect all data
// TODO: Add "autoreconnect" arguement to automatically manage reconnection attempts
export const useMQTT = <T>(
  route: string | string[],
  config?: MQTTHookConfig<T>,
) => {
  const {
    onNext,
    onError,
    onClose,
    onEffect,
    onEffectCleanup,
    shouldBeSubscribed = true,
  } = config || {};
  const [closed, setClosed] = useState(false);

  useEffect(() => {
    console.log('[useMQTT] useEffect run');

    let sub: any | undefined;
    if (shouldBeSubscribed) {
      console.log('[useMQTT] subscribing to topic: ', route);
      sub = PubSub.subscribe(route).subscribe({
        next: (data) => {
          console.log('[mqtt] on next called', {
            route,
            data
          });
          onNext?.(data.value as T);
        },
        error: (e: Error) => {
          // console.log((new Date()).toLocaleTimeString()+' [mqtt] error', {
          //   route,
          //   error: e
          // });
          console.log(e);
          onError?.(e);
        },
        complete: () => {
          // console.log((new Date()).toLocaleTimeString()+' [mqtt] route closed', {
          //   route
          // });
          console.log('[mqtt] mqtt connection closed')
          setClosed(true);
          onClose?.();
        },
      });
      onEffect?.();
    }

    return () => {
      if (sub) {
        sub.unsubscribe();
      }
      setClosed(false);
      onEffectCleanup?.();
    };
  }, [
    route,
    onNext,
    onError,
    onClose,
    onEffect,
    onEffectCleanup,
    shouldBeSubscribed,
  ]);

  return { closed };
};

export const useMQTTHealth = (
  sensorID: string | number | (string | number)[],
  config?: MQTTHookConfig<HealthPayload>,
) => {
  const url = useMemo(() => {
    if (Array.isArray(sensorID)) {
      return sensorID?.map(
        (id) => `${ENV.environment}/sensor/${id}/data/health`,
      );
    }
    return `${ENV.environment}/sensor/${sensorID}/data/health`;
  }, [sensorID]);
  return useMQTT<HealthPayload>(url, config);
};

export const useMQTTCellRaw = (
  sensorID: string | number,
  config?: MQTTHookConfig<CellPayload>,
) => {
  return useMQTT<CellPayload>(
    `${ENV.environment}/sensor/${sensorID}/data/raw/cell`,
    config,
  );
};

export interface CellScanMQTTData {
  timestamp?: number;
  sensor_id?: string;
  data_type?: ['cell-scan'];
  'cell-scan'?: {
    earfcn?: number;
    network_provider?: string;
    global_cell_id?: number;
    rf_power_estimate?: number;
  };
}
export const useMQTTCellScan = (
  sensorID: string | number,
  config?: MQTTHookConfig<CellScanMQTTData>,
) => {
  return useMQTT<CellScanMQTTData>(
    `${ENV.environment}/sensor/${sensorID}/data/raw/cell/scan`,
    config,
  );
};

export type CellConfigLTESurveyLTESurveyEARFCNData = {
  earfcn?: number;
  uplink_earfcn?: number;
  rf_center_freq?: number;
  uplink_rf_center_freq?: number;
  bandwidth_mhz?: string;
  rf_power_estimate?: number[];
  downlink_info?: {
    survey_timestamp?: number;
    completeInfo?: boolean;
    physicalCellId?: number;
    cyclicPrefixType?: boolean;
    numberOfTxAntennas?: number;
    lteBandwidthDL?: number;
    phichDuration?: boolean;
    phichResource?: number;
    frequencyError?: number;
    mcc?: number;
    mnc?: number;
    networkProvider?: string;
    globalCellId?: number;
    sib2Periodicity?: number;
    sysInfoWindowLength?: number;
    prachConfigIndex?: number;
    prachFrequencyOffset?: number;
    zcRootSequenceIndex?: number;
    highSpeedFlag?: boolean;
    zcZoneConfig?: number;
    rachResponseWindowSize?: number;
    groupAssignmentPusch?: number;
    groupHoppingEnabled?: boolean;
    sequenceHoppingEnabled?: boolean;
    cyclicShift?: number;
    nSB?: number;
    hoppingMode?: number;
    puschHoppingOffset?: number;
    srsEnabled?: boolean;
    srsSubframeConfig?: number;
  }[];
};
export type CellConfigLTESurveyLTESurveyData = {
  band?: number;
  earfcns?: CellConfigLTESurveyLTESurveyEARFCNData[];
};
export interface CellConfigLTESurveyMQTTData {
  timestamp?: number;
  sensor_id?: string;
  data_type?: 'lte-survey';
  'lte-survey'?: CellConfigLTESurveyLTESurveyData[];
}
export interface CellConfigLTEScanMQTTData {
  timestamp?: number;
  sensor_id?: string;
  data_type?: 'lte-scan';
  'lte-scan'?: {
    band?: number;
    earfcn?: number;
    recent_results?: number[];
    ff_info?: {
      ff_index?: number;
      radio_index?: number;
      compatibility_info?: {
        prachFrequencyOffset?: number;
        lteBandwidthDL?: number;
        numberOfTxAntennas?: number;
        prachConfigIndex?: number;
      };
    }[];
  }[];
}
export type CellConfigMQTTData =
  | CellConfigLTESurveyMQTTData
  | CellConfigLTEScanMQTTData;
export const useMQTTCellConfig = (
  sensorID: string | number,
  config?: MQTTHookConfig<CellConfigMQTTData>,
) => {
  return useMQTT<CellConfigMQTTData>(
    `${ENV.environment}/sensor/${sensorID}/config/cell`,
    config,
  );
};

export const useMQTTWiFiNetworked = (
  sensorID: string | number,
  config?: MQTTHookConfig<WifiNetworkedPayload>,
) => {
  return useMQTT<WifiNetworkedPayload>(
    `${ENV.environment}/sensor/${sensorID}/data/wifi/networked`,
    config,
  );
};

export const useMQTTWiFiProbeRequests = (
  sensorID: string | number,
  config?: MQTTHookConfig<WifiProbeRequestPayload>,
) => {
  return useMQTT<WifiProbeRequestPayload>(
    `${ENV.environment}/sensor/${sensorID}/data/raw/wifi/probe-requests`,
    config,
  );
};

export interface BluetoothChangesMQTTData {
  timestamp?: number;
  sensor_id?: string;
  data_type?: ['bt'];
  bt?: {
    arrivals?: {
      length?: number;
      data?: {
        timestamp?: number;
        scanID?: number;
        sensorID?: string;
        mac?: string;
        reservedLAP?: boolean;
        btClassicChannel?: string;
        detectType?: string;
        btClassicHeader?: string;
        deviceName?: string;
        manufacturerName?: string;
        rssi?: number;
        raw_bytes?: string;
        packet_body?: {
          address_type?: string;
          flags?: string;
          eir_bytes?: string;
          eir_data?: {
            data_type_value?: string;
            data_type_name?: string;
            contents?: string;
            manufacturer_cic?: string;
            manufacturer_data?: string;
          }[];
        };
        derivedManufacturerName?: string;
      }[];
    };
    departures?: {
      length?: number;
      data?: {
        timestamp?: number;
        scanID?: number;
        sensorID?: string;
        mac?: string;
        reservedLAP?: boolean;
        btClassicChannel?: string;
        detectType?: string;
        btClassicHeader?: string;
        deviceName?: string;
        manufacturerName?: string;
        rssi?: number;
        raw_bytes?: string;
        packet_body?: {
          address_type?: string;
          flags?: string;
          eir_bytes?: string;
          eir_data?: {
            data_type_value?: string;
            data_type_name?: string;
            contents?: string;
            manufacturer_cic?: string;
            manufacturer_data?: string;
          }[];
        };
        derivedManufacturerName?: string;
      }[];
    };
  };
}
export const useMQTTBluetoothChanges = (
  sensorID: string | number,
  config?: MQTTHookConfig<BluetoothChangesMQTTData>,
) => {
  return useMQTT<BluetoothChangesMQTTData>(
    `${ENV.environment}/sensor/${sensorID}/data/filtered/bluetooth/changes`,
    config,
  );
};

export interface BluetoothUniqueMacListMQTTData {
  timestamp?: number;
  sensor_id?: string;
  data_type?: ['bt-unique-mac-list'];
  'bt-unique-mac-list'?: {
    length?: number;
    data?: { mac: string }[];
  };
}
export const useMQTTBluetoothUniqueMacList = (
  sensorID: string | number,
  config?: MQTTHookConfig<BluetoothUniqueMacListMQTTData>,
) => {
  return useMQTT<BluetoothUniqueMacListMQTTData>(
    `${ENV.environment}/sensor/${sensorID}/data/filtered/bluetooth/unique-mac-list`,
    config,
  );
};

export const useMQTTBluetoothAdvPackets = (
  sensorID: string | number,
  config?: MQTTHookConfig<BluetoothAdvPacketPayload>,
) => {
  return useMQTT<BluetoothAdvPacketPayload>(
    `${ENV.environment}/sensor/${sensorID}/data/raw/bluetooth/adv-packets`,
    config,
  );
};

export const useMQTTBluetoothScanned = (
  sensorID: string | number,
  config?: MQTTHookConfig<ActiveBluetoothPayload>,
) => {
  return useMQTT<ActiveBluetoothPayload>(
    `${ENV.environment}/sensor/${sensorID}/data/filtered/bluetooth/scanned`,
    config,
  );
};

export type ModelsChangedPayload = {
  // database table name the change was made to
  // currently
  'change_type': 'account'|'home'|'profile'|'device'|'notifications'|'notification_preferences'|'ensnaresensor'|'device_enter_exit'|'alert'|'tell_me_when_schedule_duration'|'tell_me_when_schedule_duration_association'|'tell_me_when_schedule_interval'|'tell_me_when_schedule_interval_association';
  // uid value of what has changed
  'uid': number;
}

export const useMQTTModelsChanged = (
  homeID: string,
  config?: MQTTHookConfig<ModelsChangedPayload>
) => {

  const shouldBeSubscribed = !!homeID;

  //console.log('[useMQTTModelsChanged], shouldBeSubscribed:', {shouldBeSubscribed, homeID});

  return useMQTT<ModelsChangedPayload>(
    `${ENV.environment}/data_api/models_changed/home_${homeID}`,
    { ...config,
      shouldBeSubscribed }
  );
};
