import { apiRequest } from "../apiRequest";
import { FeebackAttachmentsResponse, FeedbackRequestPayload, FeedbackRequestResponse } from "./types";

export const uploadFeebackAttachments = (payload: FormData) =>
  apiRequest<FeebackAttachmentsResponse>({
    service: 'feApiGateway',
    path: 'feedback/attach',
    method: 'POST',
    data: payload,
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  });

export const createFeedbackRequest = (payload: FeedbackRequestPayload) =>
  apiRequest<FeedbackRequestResponse>({
    service: 'feApiGateway',
    path: 'feedback',
    method: 'POST',
    data: payload,
  })