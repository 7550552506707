import React, {} from 'react';
import {
  CellItemsDataDisplay
} from 'elements';
import styled from 'styled-components';

export const Activity: React.FC = function(){

  return <Container>
    <h1>Cellular Activity</h1>
    <h3>All your cellular traffic and events</h3>
    <CellItemsDataDisplay
      showDateTime
      pageSize={20} />
  </Container>
}

const Container = styled.div`${({theme}) => `
  h3 {
    margin-bottom: ${theme.boxMargins.xl3}px;
  }
`}`