import {
  apiRequest
} from '../apiRequest';
import {
  getFlaskFilter,
  FlaskResponse
} from '../helpers';
import { SsidDataItem, SsidDataItemPayload } from './types';

export const getSsidData: (homeId: number, ssid?: string|Array<string>) => Promise<Array<SsidDataItem>|null> = async (homeId, ssid) => {

  const filtersString = ssid ? getFlaskFilter({
    name: ssid,
    home_id: homeId
  }) : null;

  const res = await apiRequest<FlaskResponse<SsidDataItem>>({
    service: 'dataAPI',
    path: '/api/ssid_categorization?'+(filtersString?'q='+filtersString:''),
    method: 'GET',
    // data: {
    //   q: filtersString
    // }
  });

  if(res?.data?.num_results && res?.data?.num_results > 0){
    return res?.data?.objects || null;
  }

    return null;

};

export const getOneSsidData: (homeId: number, ssid: string) => Promise<SsidDataItem|null> = async (homeId, ssid) => {
  const ssidsRes = await getSsidData(homeId, [ssid]);

  return ssidsRes ? ssidsRes[0] : null;
};

export const saveSsidItem = async (homeId: number, data: SsidDataItemPayload={}) =>{
  const res = await apiRequest<SsidDataItem>({
    service: 'dataAPI',
    path: '/api/ssid_categorization',
    method: 'POST',
    data: {
      home_id: homeId,
      ...data
    }
  });

  return res ? res.data : null;
};

export const updateSsidItem = async (uid: number, data: SsidDataItemPayload={}) =>{
  const res = await apiRequest<SsidDataItem>({
    service: 'dataAPI',
    path: '/api/ssid_categorization/'+uid,
    method: 'PUT',
    data
  });

  return res ? res.data : null;
};
