import React from 'react';

import styles from './Loading.module.css';

import {
  AnimatedEmblem,
  AnimatedEmblemProps
} from 'components'

export const Loading: React.FC<{
  className?: string,
  centered?: boolean,
} & AnimatedEmblemProps> = ({
  className = '',
  centered,
  ...rest
}) => 
  <AnimatedEmblem active className={styles.loader + ' ' + className + ' loading' + (centered ? ' '+styles.centered : '')} {...rest} />