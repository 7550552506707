import React from 'react';
import {IconProps} from './_iconProps';

export const RefreshCcw: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1.33334 5.33335V13.3334M1.33334 13.3334H9.33334M1.33334 13.3334L7.52001 7.52002C8.95301 6.0863 10.7258 5.03896 12.6731 4.47572C14.6203 3.91247 16.6785 3.85169 18.6556 4.29903C20.6327 4.74637 22.4643 5.68726 23.9794 7.03391C25.4945 8.38056 26.6438 10.0891 27.32 12M30.6667 26.6667V18.6667M30.6667 18.6667H22.6667M30.6667 18.6667L24.48 24.48C23.047 25.9137 21.2742 26.9611 19.3269 27.5243C17.3797 28.0876 15.3215 28.1483 13.3444 27.701C11.3673 27.2537 9.53574 26.3128 8.02063 24.9661C6.50552 23.6195 5.35623 21.911 4.68001 20" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</svg>
