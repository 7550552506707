import React from 'react';
import {IconProps} from './_iconProps';

export const Edit2: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M22.6667 3.99999C23.0169 3.64979 23.4326 3.37201 23.8901 3.18248C24.3477 2.99296 24.8381 2.89542 25.3333 2.89542C25.8286 2.89542 26.319 2.99296 26.7765 3.18248C27.2341 3.37201 27.6498 3.64979 28 3.99999C28.3502 4.35018 28.628 4.76592 28.8175 5.22346C29.007 5.68101 29.1046 6.17141 29.1046 6.66665C29.1046 7.1619 29.007 7.65229 28.8175 8.10984C28.628 8.56739 28.3502 8.98313 28 9.33332L10 27.3333L2.66666 29.3333L4.66666 22L22.6667 3.99999Z" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</svg>
