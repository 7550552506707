import React from 'react';
import {IconProps} from './_iconProps';

export const Music: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12 24V6.66667L28 4V21.3333M12 24C12 26.2091 10.2091 28 8 28C5.79086 28 4 26.2091 4 24C4 21.7909 5.79086 20 8 20C10.2091 20 12 21.7909 12 24ZM28 21.3333C28 23.5425 26.2091 25.3333 24 25.3333C21.7909 25.3333 20 23.5425 20 21.3333C20 19.1242 21.7909 17.3333 24 17.3333C26.2091 17.3333 28 19.1242 28 21.3333Z" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</svg>
