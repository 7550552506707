import React from 'react';
import {IconProps} from './_iconProps';

export const Codepen: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M16 2.66669L29.3333 11.3334M16 2.66669L2.66666 11.3334M16 2.66669V11.3334M29.3333 11.3334V20.6667M29.3333 11.3334L16 20.6667M29.3333 20.6667L16 29.3334M29.3333 20.6667L16 11.3334M16 29.3334L2.66666 20.6667M16 29.3334V20.6667M2.66666 20.6667V11.3334M2.66666 20.6667L16 11.3334M2.66666 11.3334L16 20.6667" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</svg>
