export const ALERTS_SENSITIVITY_VALUES: Array<'low'|'normal'|'high'> = ['low','normal','high'];

export type AlertSettingsAlertType = 'security_alert'
  | 'use_individual_alerts'
  | 'all_alerts'
  | 'counter_anomaly'
  | 'unassigned_device'
  | 'unknown_device'
  | 'unit_online_offline'
  | 'ssid_alert'
  | 'mobile_phone_type_alert'
  | 'bt_manufacturer_alert';

export type AlertSettingsSensitivityType = typeof ALERTS_SENSITIVITY_VALUES[0];