import React from 'react';
import {IconProps} from './_iconProps';

export const Droplet: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M16 3.58667L23.5467 11.1333C25.0391 12.6248 26.0557 14.5254 26.4679 16.5947C26.88 18.6641 26.6692 20.8091 25.8621 22.7586C25.0549 24.7081 23.6878 26.3744 21.9335 27.5468C20.1792 28.7192 18.1166 29.3449 16.0067 29.3449C13.8967 29.3449 11.8341 28.7192 10.0799 27.5468C8.32558 26.3744 6.95842 24.7081 6.15129 22.7586C5.34416 20.8091 5.13333 18.6641 5.54547 16.5947C5.95761 14.5254 6.97421 12.6248 8.46667 11.1333L16 3.58667Z" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</svg>
