import {
  createContext,
  useContext,
  useEffect,
  useState,
  useMemo
} from 'react';
import { getAuth } from 'firebase/auth';
import { getDatabase, ref, onValue, off, child, update } from 'firebase/database';

import { NewReportItem } from 'context';

import { CellSignal } from 'hooks';
import { useFirebaseStore } from 'store';
import { getApp } from 'firebase/app';

export type UserData = {
  cellDataMixins: any
}

export const updateUserData = (payload: UserData) => {
  const firebaseApp = getApp();
  const db = getDatabase(firebaseApp);
  const user = (getAuth()).currentUser;
  const uid = user?.uid;

  if(!uid){ return; }

  const userDataRef = child(ref(db), 'SignalwiseUserData/'+uid);

  console.log('[updateUserData]', {db, user, payload});

  return update(userDataRef, payload);
};

export const updateUserDataMixins = (payload: {[key: string]: NewReportItem}) => {
  const firebaseApp = getApp();
  const db = getDatabase(firebaseApp);
  const user = (getAuth()).currentUser;
  const uid = user?.uid;

  if(!uid){ return; }

  const dataRef = child(ref(db), 'SignalwiseUserData/'+uid+'/Mixins');

  console.log('[updateUserData]', {db, user, payload});

  return update(dataRef, payload);
};

export type UserDataContextType = {
  userDataMixins: Array<CellSignal>;
  userDataLoaded: boolean;
  updateUserData: (userData: UserData) => Promise<void>|undefined;
  updateUserDataMixins: (payload: {[key: string]: NewReportItem}) => Promise<void>|undefined;
}
export const UserDataContext = createContext<UserDataContextType>(undefined!);

export const useUserDataContext = () => useContext(UserDataContext);

export const UserDataContextProvider: React.FC = ({
  children
}) => {
  const { user: fbUser } = useFirebaseStore(s => s);
  //const [userData, setUserData] = useState<UserDataContextType['userData']>();
  const [userDataMixins, setUserDataMixins] = useState<UserDataContextType['userDataMixins']>([]);
  const [userDataLoaded, setUserDataLoaded] = useState<boolean>(false);

  useEffect(function Mixins(){
    function processSnapsot(snapshot: any){
      if(snapshot.exists()){
        const val = snapshot.val();
        console.log('[UserDataProvider] got value', val)
        setUserDataMixins(Object.values(val));
      }
    }

    if(fbUser?.uid){
      const userUid = fbUser?.uid;
      const db = getDatabase();
      const savedUserDataMixinsRef = ref(db, 'SignalwiseUserData/'+userUid+'/Mixins');

      console.log('[UserDataProvider]', {savedUserDataMixinsRef});

      onValue(savedUserDataMixinsRef, snapshot => {
        console.log('[UserDataProvider] on user report value', {userUid, snapshot});
        processSnapsot(snapshot);
        setUserDataLoaded(true);
      });

      return () => off(savedUserDataMixinsRef);
    }
  }, [fbUser, setUserDataMixins, setUserDataLoaded]);

  return <UserDataContext.Provider value={useMemo(() => ({
    userDataMixins,
    updateUserData,
    updateUserDataMixins,
    userDataLoaded
  }),
    [
      userDataMixins,
      userDataLoaded
    ])}>{children}</UserDataContext.Provider>;
};
