import React from 'react';
import {IconProps} from './_iconProps';

export const Eye: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1.33334 16.0002C1.33334 16.0002 6.66668 5.3335 16 5.3335C25.3333 5.3335 30.6667 16.0002 30.6667 16.0002C30.6667 16.0002 25.3333 26.6668 16 26.6668C6.66668 26.6668 1.33334 16.0002 1.33334 16.0002Z" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
<path d="M16 20C18.2091 20 20 18.2091 20 16C20 13.7909 18.2091 12 16 12C13.7909 12 12 13.7909 12 16C12 18.2091 13.7909 20 16 20Z" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</svg>
