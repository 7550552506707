import {useEffect, useMemo, useState, useRef, useCallback} from 'react';

import styled from 'styled-components';

export const Switchgroup: React.FC = ({
  children
}) => {
  
  const count = useMemo(() => Array.isArray(children) ?
    children.length : 0, [children]);

  const [selected, setSelected] = useState<number|null>(null);

  const refference = useRef<HTMLDivElement>(null);

  const getAndSetSelected = useCallback(function(){
    const els = refference.current?.getElementsByTagName('input');

    if(!els||!els.length){ return; }

    for(let i=0;i<els.length;i++){
      if(els[i].checked){
        return setSelected(i);
      }
    }

  },[]);

  useEffect(function setSelected(){
    getAndSetSelected();
  },[getAndSetSelected]);

  return <StyledSwitchgroup ref={refference} sizeCount={count} selected={selected} onClick={getAndSetSelected}>{children}</StyledSwitchgroup>;
};

const StyledSwitchgroup = styled.div.attrs({} as {
  sizeCount: number,
  selected: number|null
})`${({theme, sizeCount, selected}) => `
  display: flex;
  width: 100%;
  background-color: ${theme.colors.sky.lighter};
  border-radius: ${theme.fontPadding.xxs}px;
  position: relative;
  box-sizing: border-box;
  overflow: hidden;

  > div {
    flex-grow: 1;
    text-align: center;
    overflow: hidden;
  }

  > div input {
    display: none;
  }

  > div label {
    font-size: ${theme.fontSize.s}px;
    display: block;
    padding: ${theme.fontPadding.s}px 0;
    cursor: pointer;
  }

  ${selected !== null ? `&:before {
    content: '';
    width: ${100/sizeCount-2}%;
    left: ${ (100/sizeCount) * selected + 1}%;
    
    transition: left 500ms ease-in-out;
    top: 5px;
    bottom: 5px;
    box-shadow: 0px 0px 5px rgba(${theme.colorsRgb.ink.dark.toString()}, 0.2);
    border-radius: ${theme.fontPadding.xxs}px;
    position: absolute;
    box-sizing: border-box;

    background-color: ${theme.colors.sky.white};
  }` : ''}

  &:before { 
    z-index: 1;
  }

  > div {
    z-index: 2
  }
`}`;