import React, {useEffect} from 'react';

import styled from 'styled-components';

export const ContentWrapper: React.FC = ({
  children
}) => {
  
  // scroll to hash on load
  useEffect(function(){
    const hashId = String(window.location.hash).replace('#','');
    const el = window.document.getElementById(hashId);
    if(el){
      el.scrollIntoView();
    }
  },[]);

  console.log('children', children);

  return (
    <MainPanel>
      {children}
    </MainPanel>
  );
};

export default ContentWrapper;

const MainPanel = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;
