import React from 'react';
import {IconProps} from './_iconProps';

export const CloudLightning: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_149_269)">
<path d="M25.3333 22.5333C26.9535 22.2043 28.3937 21.2851 29.3742 19.954C30.3548 18.6229 30.8057 16.9749 30.6396 15.33C30.4734 13.6851 29.702 12.1606 28.4751 11.0525C27.2482 9.94437 25.6533 9.33167 24 9.33333H22.32C21.8782 7.62308 21.0187 6.04931 19.8185 4.75331C18.6183 3.4573 17.1151 2.47963 15.4437 1.90808C13.7724 1.33652 11.9853 1.18897 10.2428 1.47867C8.50038 1.76837 6.85714 2.48626 5.46066 3.56787C4.06417 4.64949 2.95815 6.06097 2.24189 7.67561C1.52564 9.29024 1.22157 11.0575 1.35699 12.8186C1.4924 14.5798 2.06307 16.2798 3.01775 17.7659C3.97242 19.2521 5.28123 20.4779 6.82665 21.3333M17.3333 14.6667L12 22.6667H20L14.6667 30.6667" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</g>
<defs>
<clipPath id="clip0_149_269">
<rect width={width} height={height} fill="white"/>
</clipPath>
</defs>
</svg>
