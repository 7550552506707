import React from 'react';
import {IconProps} from './_iconProps';

export const Bold: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8 16H18.6667C20.0812 16 21.4377 15.4381 22.4379 14.4379C23.4381 13.4377 24 12.0811 24 10.6666C24 9.25216 23.4381 7.8956 22.4379 6.89541C21.4377 5.89522 20.0812 5.33331 18.6667 5.33331H8V16ZM8 16H20C21.4145 16 22.771 16.5619 23.7712 17.5621C24.7714 18.5623 25.3333 19.9188 25.3333 21.3333C25.3333 22.7478 24.7714 24.1044 23.7712 25.1045C22.771 26.1047 21.4145 26.6666 20 26.6666H8V16Z" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</svg>
