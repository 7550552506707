import { HTMLProps } from 'react';
import styled, {keyframes, css} from 'styled-components';
import {
  Close
} from 'theme/icons';

export type PopupProps = {
  dismissable?: boolean,
  header?: React.ReactNode,
  title?: React.ReactNode,
  children?: React.ReactNode,
  footer?: React.ReactNode,

  onDissmiss?: () => void
}

export const Popup: React.FC<PopupProps> = ({
  dismissable,
  header,
  title,
  children,
  footer,
  onDissmiss
}) => (
  <StyledPopup onClick={e => {
    if(dismissable){
      e.stopPropagation();
    }
  }}>
    {dismissable ?
      <StyledClose onClick={() => onDissmiss?.()}/>: null}

    {header ?
      <header>{header}</header>: null}
    
    {title ?
      <h3>{title}</h3>: null}

    {children ?
      <main>{children}</main>: null}
    
    {footer ?
      <footer>{footer}</footer>: null}

  </StyledPopup>
  )

const slideIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-30px);
  }
  100% {
    opacity: 100%; 
    transform: translateY(0);
  }
`;

export const StyledPopup = styled('div')`${({theme}) => css`
  position: relative;
  display: inline-block;

  background-color: ${theme.newColors.SwissCream};
  padding: ${theme.boxPadding.xl3}px;
  border-radius: ${theme.boxPadding.m}px;

  margin: auto;
  
  > header {
    margin-bottom: ${theme.boxPadding.xl}px;
  }

  > main {
    
  }

  > footer {
    margin-top: ${theme.boxPadding.xl3}px
    margin-left: -${theme.boxPadding.l}px;
    margin-right: -${theme.boxPadding.l}px;
  }
  > footer > * {
    margin: ${theme.boxMargins.m}px 0;
  }

  button, input[type="text"] {
    box-sizing: border-box;
    min-width: 100%;
  }

  animation-name: ${slideIn};
  animation-duration: 300ms;
`}`;


const StyledClose = styled(
  ({className, ...rest}: {className?:string} & HTMLProps<HTMLAnchorElement>) => (
  <a className={className} {...rest}>close <Close /></a>
  ))`${({theme}) => `
  cursor: pointer;
  float: right;
`}`;