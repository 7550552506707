import React, {useLayoutEffect, useRef, SVGProps, useMemo, useCallback} from 'react';
import { newColors } from 'theme';
import gsap, {TimelineMax} from 'gsap';

export type AnimatedEmblemProps = {
  size?: number;
  className?: string;

  centered?: boolean;

  x?: string|number;
  y?: string|number;

  width?: number;
  height?: number;

  variant?: 'piano'|'updown',
  active?: boolean,

  strokesSettings?: {
    strokesWidth?: number;
    strokesHeight?: number;
    strokesSpaces?: number;
    numberOfStrokes?: number;
  },

  fill?: string;
};

// helpers
// emblem strokes map
const dLines = {
  straight: 'M15 0H1C0.447715 0 0 0.447714 0 0.999999V99C0 99.5523 0.447715 100 1 100H15C15.5523 100 16 99.5523 16 99V1C16 0.447715 15.5523 0 15 0Z',
  up: 'M15 0H1C0.447715 0 0 0.447714 0 0.999999V39C0 39.5523 0.447715 40 1 40H15C15.5523 40 16 39.5523 16 39V1C16 0.447715 15.5523 0 15 0Z',
  down: 'M15 60H1C0.447715 60 0 60.4477 0 61V99C0 99.5523 0.447715 100 1 100H15C15.5523 100 16 99.5523 16 99V61C16 60.4477 15.5523 60 15 60Z'
};
// getting stroke function
function getStrokeD(position: 'straight'|'up'|'down'){
  return dLines[position] || '';
};

const toLogo = (timeline: TimelineMax, pathHolder: Array<SVGPathElement|null>, {
  duration = 0.4,
  tweenOptions = {},
  delay = 0,
}: {
  duration?: number,
  tweenOptions?: gsap.TweenVars,
  delay?: number
} = ({} as any)) => {
  const time = delay+timeline.time();
  let up = false, full = false;

  for(let i=0;i<pathHolder.length;i++){
    timeline.to(pathHolder[i], duration, {
      attr:{d: getStrokeD(full ? 'straight' : (up ? 'up' : 'down'))},
    }, time);
    // switch direction on every even i
    up = ((i+1) % 2) ? !up : up;
    // switch straight param every i
    full = !full;
  }
}

const toDown = (timeline: TimelineMax, pathHolder: Array<SVGPathElement|null>, {
  duration = 0.4,
  tweenOptions = {},
  delay = 0,
}: {
  duration?: number,
  tweenOptions?: gsap.TweenVars,
  delay?: number
} = ({} as any)) => {
  const time = delay + timeline.time();

  for(let i=0;i<pathHolder.length;i++){
    timeline.to(pathHolder[i], duration, {
      ...tweenOptions,
      attr:{d: getStrokeD('down')},
    }, time);
  }
}

const toUp = (timeline: TimelineMax, pathHolder: Array<SVGPathElement|null>, {
  duration = 0.4,
  tweenOptions = {},
  delay = 0
}: {
  duration?: number,
  tweenOptions?: gsap.TweenVars,
  delay?: number
} = ({} as any)) => {
  const time = delay+timeline.time();

  for(let i=0;i<pathHolder.length;i++){
    timeline.to(pathHolder[i], duration, {
      ...tweenOptions,
      attr:{d: getStrokeD('up')},
    }, time);
  }
}
(function(){return toUp})();// its used, see 

const pianoSequence = (timeline: TimelineMax, pathHolder: Array<SVGPathElement|null>, options?: {
  duration?: number,
  loop?: boolean,
  tweenOptions?: gsap.TweenVars,
  delay?: number
}) => {
  const {
    duration = 3,
    loop = false,
    tweenOptions = {},
    delay = 0
  } = options||{};
  const step = duration / (pathHolder.length/2+0.5);
  const time = delay + timeline.time();
  let loopKilled = false;
  let killedCallback: (() => void) | null = null;

  for(let i=0;i<pathHolder.length;i++){
    const el = pathHolder[i];
    const sp = i * 0.5

    timeline.to(el, step, {
      ...tweenOptions,
      attr:{d: getStrokeD('straight')},
      onComplete: (i===(pathHolder.length-1)) ? () => {
        if(loop && !loopKilled){
          pianoSequence(timeline, pathHolder, options);
        } else if(killedCallback) {
          killedCallback()
        }
      } : undefined
    }, time+step *sp);

    timeline.to(el, step, {
      ...tweenOptions,
      attr:{d: getStrokeD('down')}
    }, time+step *(sp+1));
  }

  return function killLoop(callback?: () => void){ loopKilled = true; killedCallback = callback||null; }
};

const upDownSequence = (timeline: TimelineMax, pathHolder: Array<SVGPathElement|null>, options?: {
  duration?: number,
  loop?: boolean,
  tweenOptions?: gsap.TweenVars,
  delay?: number
}) => {
  const {
    duration = 3,
    loop = false,
    tweenOptions = {},
    delay = 0
  } = options||{};
  const step = duration / (pathHolder.length * 2);
  const time = delay + timeline.time();
  let loopKilled = false;
  let killedCallback: (() => void) | null = null;

  for(let i=0; i<pathHolder.length;i++){
    const el = pathHolder[i];
    const sp = i*2; // set two steps into each sequence
    timeline.to(el, step, {
      ...tweenOptions,
      attr:{d: getStrokeD('straight')},
    }, time+step*(sp+0));

    timeline.to(el, step, {
      ...tweenOptions,
      attr:{d: getStrokeD('up')},
    }, time+step*(sp+1));
    
    timeline.to(el, step, {
      ...tweenOptions,
      attr:{d: getStrokeD('straight')},
    }, time+step*(sp+2));

    timeline.to(el, step, {
      ...tweenOptions,
      attr:{d: getStrokeD('down')},
      // set up loop trigger if looped
      onComplete: (i===(pathHolder.length-1)) ? () => {
        if(loop && !loopKilled){
          upDownSequence(timeline, pathHolder, options);
        } else if(killedCallback) {
          killedCallback()
        }
      } : undefined
    }, time+step*(sp+3));
  }

  return function killLoop(callback?: () => void){ loopKilled = true; killedCallback = callback||null; }
}

export const AnimatedEmblem = React.forwardRef<SVGSVGElement, AnimatedEmblemProps>(({
  className='',
  width = 136,
  height = 100,
  variant = 'updown',
  active = false,

  fill = newColors.OrangeDream,
  
  x,
  y,

  // relative settings, this will be stretched onto the canvas
  strokesSettings: {
    strokesWidth= 16,
    strokesHeight= 200,
    strokesSpaces= 8,
    numberOfStrokes= 6
  } = {}
  
}, ref) => {

  const pathHolder = useRef<Array<SVGPathElement|null>>([]).current;
  const timeline = useRef(new TimelineMax()).current;

  const {
    derivedStrokesWidth,
    derivedStrokesHeight,
    derivedStrokesSpaces,
    derivedNumberOfStrokes
  } = useMemo(() => {
    const strokesTotalWidth = (numberOfStrokes-1)*strokesSpaces + numberOfStrokes*strokesWidth
    const fraction = width/strokesTotalWidth;
    return {
      derivedStrokesWidth: strokesWidth * fraction,
      derivedStrokesSpaces: strokesSpaces * fraction,
      derivedStrokesHeight: height,
      derivedNumberOfStrokes: numberOfStrokes
    }
  },[
    strokesWidth,
    strokesSpaces,
    numberOfStrokes,
    width, 
    height
  ]);

  useLayoutEffect(function (){
    // if not active -> go to logotype
    if(!active){
      // cleanup function is not being fired right away, so yes, sleep 
      // ** worse case scenario, it will just be set to a logo instead of being animated
      setTimeout(() => toLogo(timeline, pathHolder, {duration: 0.5}), 50);
      return;
    }
    // clean up path holder
    pathHolder.splice(derivedNumberOfStrokes+1);
    // initiate animation
    switch(variant){
      case 'piano':
        toDown(timeline, pathHolder, {duration: 0.5});
        pianoSequence(timeline, pathHolder, {
          duration: 3, loop: true, tweenOptions: {
          
        }});
        break;
      case 'updown':
      default:
        //toLogo(timeline, pathHolder, {duration: 4});
        toDown(timeline, pathHolder, {duration: 0.5});
        upDownSequence(timeline, pathHolder, {
          duration: 3, loop: true, tweenOptions: {
          
        }});
        //timeline.play();
        console.log('made timeline animation', timeline, {...pathHolder});
        break;
    }

    return () => {
      timeline.clear();
    };
  },[
    active,
    derivedNumberOfStrokes,
    pathHolder,
    timeline,
    variant
  ]);

  const handleRef = useCallback(function(id, ref: SVGSVGElement|null){
    pathHolder[id] = ref?.children[0] as SVGPathElement||null
  },[
    pathHolder
  ])

  return (
    <svg ref={ref} x={x} y={y} className={className} width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox={`0 0 ${width} ${height}`} preserveAspectRatio="none">
      {([...new Array(derivedNumberOfStrokes)])?.map((_,i) => 
        <Stroke key={(() => 'notani-'+i)()} ref={r => handleRef(i,r)} x={(derivedStrokesSpaces+derivedStrokesWidth)*i} width={derivedStrokesWidth} height={derivedStrokesHeight} fill={fill} />
      )}
    </svg>
    );
});

const Stroke = React.forwardRef<SVGSVGElement,{
  
} & SVGProps<SVGSVGElement>>(({
  fill = newColors.OrangeDream,
  x = 0,
  y = 0,
  width = 16,
  height = 100,
  ...rest
}, ref) => 
  <svg {...rest} ref={ref} x={x} y={y} width={width} height={height} viewBox="0 0 16 100" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none">
    <path d={getStrokeD('straight')} fill={fill}/>
  </svg>
);