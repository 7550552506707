import React from 'react';
import {IconProps} from './_iconProps';

export const CornerDownRight: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M20 13.3333L26.6667 20M26.6667 20L20 26.6666M26.6667 20H10.6667C9.25218 20 7.89562 19.4381 6.89543 18.4379C5.89523 17.4377 5.33333 16.0811 5.33333 14.6666V5.33331" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</svg>
