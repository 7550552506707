import React, { createContext, useContext, useState, useCallback, useEffect, useMemo } from "react"
import { getDatabase, ref, child, set, onValue, DataSnapshot } from 'firebase/database';
import { v4 as uuidv4 } from 'uuid';
import { useAccountStore } from 'context';
import { useFirebaseStore } from "store";
import { getApp } from "firebase/app";

export type NewReportIndexItem = {
  createdAt: number;
  createdBy: string;
  reportTime: number;
  startTime: number;
  endTime: number;
  userUid: string;
  sensorId: number;
  uid: string;
  length: number;
}
export type NewReportItem = {
  timestamp: number;
  provider: string;
  type: 'cell'|'cell-scan';
  power: number;
  establishmentCause: string|null;
  tmsi: string|number|null;
}
export type NewSavedReportValue = NewReportIndexItem & {
  items: Array<NewReportItem>
};
export type NewSaveReportPayload = Omit<NewReportIndexItem, 'uid'|'createdAt'|'userUid'> & Partial<NewReportIndexItem> & {
  items: Array<NewReportItem>
}
export type NewReportswContextType = {
  reportsIndex: Array<NewReportIndexItem>,
  reports: {
    [key: string]: NewSavedReportValue
  },
  getReportsIndex?: () => void,
  getReport?: () => void, 
  saveReport?: (payload:NewSaveReportPayload) => void, 

  _getReportsIndex?: (accountId: string) => void;
  _getReport?: (reportUid: string) => void;
  _saveReport?: (accountId: string, payload:NewSaveReportPayload) => Promise<(NewReportIndexItem & {items: Array<NewReportItem>})|null>
  _onReportIndexSnapshot?: (snapshot: DataSnapshot) => void;
}



export const NewReportsContext = createContext<NewReportswContextType>({
  reportsIndex: [],
  reports: {}
});
export const useNewReportsContext = () => {
  const {
    _onReportIndexSnapshot, 
    _saveReport, 
    reportsIndex,
    reports,
    _getReportsIndex,
    _getReport
  } = useContext(NewReportsContext);
  const {accountId} = useAccountStore(s => s);
  const { user: fbUser } = useFirebaseStore(s => s);

  const getReportsIndex = useCallback(() => accountId && _getReport?.(accountId),[accountId, _getReport]);
  const getReport = useCallback((reportId:string) => _getReport?.(reportId),[_getReport]);
  const saveReport = useCallback((payload:NewSaveReportPayload) => {
    if(!fbUser){ throw new Error('no user id') }
    accountId && _saveReport?.(accountId, {
      ...payload, 
      userUid: fbUser.uid
    });
  },[accountId, _saveReport, fbUser]);
  
  useEffect(function reportsindexListenerSetup(){
    if(!accountId||!_onReportIndexSnapshot){ return }
    const firebaseApp = getApp();

    const db = getDatabase(firebaseApp);
    const reportsIndexRef = child(ref(db), 'CellReportsIndex/'+accountId);
    console.log('[useNewReportsContext] getting to listen on index', accountId);
    const killit = onValue(reportsIndexRef,_onReportIndexSnapshot);
    console.log('[useNewReportsContext] listening on index', accountId);

    return killit;
  },[
    accountId,
    _onReportIndexSnapshot
  ]);

  return {
    reportsIndex,
    reports,
    _getReportsIndex,
    _getReport,
    _saveReport,
    _onReportIndexSnapshot,
    getReportsIndex,
    getReport,
    saveReport
  };
};
export const NewReportsProvider: React.FC = ({
  children
}) => {
  const [reportsIndex, setReportsIndex] = useState<NewReportswContextType['reportsIndex']>([]);
  const [reports, setReports] = useState<NewReportswContextType['reports']>({});

  // private functions, expect accountId id as param
  const _onReportIndexSnapshot = useCallback(function (snapshot){
    const value = snapshot.val() as NewReportswContextType['reportsIndex'];
    const newIndexValue = value ? Object.values(value) : [];
    setReportsIndex(newIndexValue);
  },[]);
  const _getReportsIndex = useCallback(async function getReportindex(accountId){
    const firebaseApp = getApp();
    const db = getDatabase(firebaseApp);
    const reportsIndexRef = child(ref(db), 'CellReportsIndex/'+accountId);
    try {
      const indexSnapshot = await new Promise(resolve => onValue(reportsIndexRef, res => {
        resolve(res);
      }, {
        onlyOnce: true
      }))

      _onReportIndexSnapshot(indexSnapshot);
    }
    catch (e){
      console.error('[newReportsContextProvider] Error getting reports index', e);
    }
  }, [
    _onReportIndexSnapshot
  ]);
  const _getReport = useCallback(async function getReport(reportId){
    const firebaseApp = getApp();
    const db = getDatabase(firebaseApp);
    const reportValueRef = child(ref(db), 'CellReportsValues/'+reportId);

    console.log('[newReportsContextProvider] getting report')
    
    try {
      const reportSnapshot: DataSnapshot = await new Promise(resolve => onValue(reportValueRef, res => {
        resolve(res);
      }, {
        onlyOnce: true
      }));
      const value = reportSnapshot.val();

      setReports(reports => ({...reports, [reportId]: value}));
      console.log('[newReportsContextProvider] received reportSnapshot', {reportSnapshot, value});
    }
    catch (e) {
      console.error('[newReportsContextProvider] Error getting report', e);
    }
  }, []);
  const _saveReport = useCallback(async function saveReport(accountId, payload){
    const uid = payload.uid || uuidv4();
    const createdAt = payload.createdAt || Date.now();
    const firebaseApp = getApp();
    const db = getDatabase(firebaseApp);
    const reportValueRef = child(ref(db), 'CellReportsValues/'+uid);
    const reportsIndexRef = child(ref(db), 'CellReportsIndex/'+accountId+'/'+uid);
    const derivedPayload = {
      ...payload,
      createdAt,
      uid
    }
    const indexValue = {...derivedPayload, items: null};
    try {
      // save index
      await set(reportsIndexRef, {
        ...indexValue
      });
      // save report
      await set(reportValueRef, derivedPayload);
    } catch (e) {
      console.error('[newReportsContextProvider] Error saving report', e);
      return null;
    }    
    
    return derivedPayload;
  },[]);
  
  return <NewReportsContext.Provider value={useMemo(() => ({
    reportsIndex,
    reports,
    _getReportsIndex,
    _getReport,
    _saveReport,
    _onReportIndexSnapshot
  }),[
    reportsIndex,
    reports,
    _getReportsIndex,
    _getReport,
    _saveReport,
    _onReportIndexSnapshot
  ])}>{children}</NewReportsContext.Provider>
}