import { accountStore, homeStore } from '@ubiety/fe-context';
import { firebaseStore } from 'store';

export const getSentryAccountContext = () => {
  const firebaseUser = firebaseStore.getState().user;
  const accountId = accountStore.getState().accountId;
  const homeId = homeStore.getState().home?.uid;

  return {
    'account details': {
      'firebase_user_uid': firebaseUser?.uid,
      'firebase_user_email': firebaseUser?.email,
      'account_id': accountId,
      'home_id': homeId
    }
  };
};