import {
  providersTitleNames,
  providerToLabelName
} from 'helpers';
import {
  newColors
} from 'theme';
import styled from 'styled-components';

export const LabelsMap:React.FC<{
  className?: string
}> = ({
  className
}) => {
  return <StyledLabelsMapContainer className={"labels-map "+(className||'')}>
    {providersTitleNames?.map(title => {
      return <li key={title}><span className="color-square" style={{
        backgroundColor: newColors[providerToLabelName(title)]
      }}></span> {title}</li>
    })}
  </StyledLabelsMapContainer>
}
const StyledLabelsMapContainer = styled.ul`${({theme}) => `
  list-style-type: none;
  padding: 0;
  margin: 0;

  display: flex;
  flex-direction: row;
  align-items: center;

  li:not(:first-child) {
    margin-left: 1em;
  }

  li {
    align-items: center;
  }

  .color-square {
    display: inline-block;
    width: 1em;
    height: 1em;
    margin-right;
    border-radius: 2px;
    vertical-align: middle;
  }
`}`;