import React from 'react';
import {IconProps} from './_iconProps';

export const Tablet: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M16 24H16.0133M8 2.66669H24C25.4728 2.66669 26.6667 3.86059 26.6667 5.33335V26.6667C26.6667 28.1394 25.4728 29.3334 24 29.3334H8C6.52724 29.3334 5.33334 28.1394 5.33334 26.6667V5.33335C5.33334 3.86059 6.52724 2.66669 8 2.66669Z" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</svg>
