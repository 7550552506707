import { apiRequest } from '../apiRequest';
import {RawHomeData, CreateHomePayloadType, UpdateHomePayload, LinkHomePayload, LinkHomeResponse, GetHomesResponse} from './types'

export const createHome = (payload: Partial<CreateHomePayloadType>) => 
  apiRequest<RawHomeData>({
    service: 'feApiGateway',
    path: 'homes',
    method: 'POST',
    data: payload
  });
export const updateHome = (homeId: number, payload: UpdateHomePayload) =>
  apiRequest<RawHomeData>({
    service: 'feApiGateway',
    path: `homes/${homeId}`,
    method: 'PATCH',
    data: payload
  });

export const getHomeById = (homeId: number) => 
  apiRequest<RawHomeData>({
    service: 'feApiGateway',
    path:  `homes/${homeId}`,
    method: 'GET'
  });

export const getHomes = () => 
  apiRequest<GetHomesResponse>({
    service: 'feApiGateway',
    path:  `homes`,
    method: 'GET'
  });

export const linkHome = (payload: LinkHomePayload) => 
  apiRequest<LinkHomeResponse>({
    service: 'feApiGateway',
    path:  `homes/link`,
    method: 'POST',
    data: payload
  });