export const makeEvent = function(name, bubbles=false, cancelable=false){
  
  if(typeof Event === 'function') {return new Event(name, {bubbles, cancelable});}
  const e = document.createEvent(name);
  e.initEvent(bubbles, cancelable);

  return e;
};

export const clearInput = function(el){
  el.value = '';

  if(el.type === 'file'){
    const changeEvent = makeEvent('change', true, true);
    el.dispatchEvent(changeEvent);
  }

  return el;
};

export const observeDOM = (function(){
  var MutationObserver = window.MutationObserver || window.WebKitMutationObserver;

  return function( obj, callback ){
    if( !obj || obj.nodeType !== 1 ) {return;} 

    if( MutationObserver ){
      // define a new observer
      var mutationObserver = new MutationObserver(callback);

      // have the observer observe foo for changes in children
      mutationObserver.observe( obj, { childList:true, subtree:true });
      return function(){ mutationObserver.disconnect(); };
    }
    
    // browser support fallback
    else if( window.addEventListener ){
      obj.addEventListener('DOMNodeInserted', callback, false);
      obj.addEventListener('DOMNodeRemoved', callback, false);
    }

  };
})();

export const copyTextToClipboard = function(text, success=()=>{/**/}, failure=()=>{/**/}) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text, success, failure);
    return;
  }
  navigator.clipboard.writeText(text).then(function() {
    success();
  }, function(err) {
    console.warn(err);
    failure();
  });
};

function fallbackCopyTextToClipboard(text, success=()=>{/**/}, failure=()=>{/**/}) {
  var textArea = document.createElement('textarea');
  textArea.value = text;
  
  // Avoid scrolling to bottom
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    var successful = document.execCommand('copy');
    
    if(successful){
      success();
    } else {
      failure();
    }

  } catch (err) {
    failure();
  }

  document.body.removeChild(textArea);
}

export const saveFile = function(filename, content){
  
  var encodedUri = encodeURI(content);
  var link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  link.setAttribute('download', filename);
  document.body.appendChild(link); // Required for FF

  link.click();

  link.remove();
};

