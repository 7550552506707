


import { getApp, initializeApp } from 'firebase/app';
import {
  getAnalytics,
  logEvent,
} from 'firebase/analytics';
import { getStorage, ref, uploadBytes, deleteObject, getDownloadURL } from 'firebase/storage';
import { getAuth, signOut } from 'firebase/auth';
import { v4 as UUID } from 'uuid';
import { ENV_HOLDER } from '@ubiety/fe-api';
import { logError } from './log_error';

export const initializeFirebaseApp = ((...args) => {
  const fbapp = initializeApp.apply(this, [...args] as any)

  return fbapp;
}) as typeof initializeApp;

export const signOutFirebaseUser = async () => {
  try {
    await signOut(getAuth());
  } catch (e) {
    console.log('[signOutFirebaseUser] Error signing out firebase user', e);
    throw e;
  }
};

export const uploadImage = async (uri: any) => {
  const storage = getStorage();

  try {
    const imgId = UUID();
    const imageRef = ref(storage, `images/${imgId}.jpg`);
    const snapshot = await uploadBytes(imageRef, uri);
    const url = await getDownloadURL(snapshot.ref);
    return url;
  } catch (e) {
    console.error(e);
    throw e;
  }

};

export const deleteImage = (url: string) => {
  // storage().refFromURL(url).delete();
  const storage = getStorage();
  const imageRef = ref(storage, url);

  deleteObject(imageRef);
};

//
// Analytics
//
// Utility functions for all custom analytic events
//
// These are places here to ensure every time an event is called
//  it is called the same way and we don't have to hunt around
//  the app to ensure all events of the same type are updated
//
export const logFBCustomEvent = async (
  name: string,
  params?: {
    [key: string]: any;
  },
) => {
  const { logAnalyticsEvents } = ENV_HOLDER.settings;
  const paramsAndVersion = {
    ...params,
  };
  try {
    const firebaseApp = getApp();

    if (!firebaseApp) {
      throw new Error('Attempting to invoke before initialization');
    }
    /* istanbul ignore next */
    if (logAnalyticsEvents) {
      console.log('[logFBCustomEvent] Firebase Analytics Event Logged', name, paramsAndVersion);
    }
    const analytics = getAnalytics(firebaseApp);

    logEvent(analytics, name, paramsAndVersion);
    /* istanbul ignore next */
    if (logAnalyticsEvents) {
      console.log(
        '[logFBCustomEvent] Firebase Analytics Event Logged Success',
        name,
        paramsAndVersion,
      );
    }
  } catch (e) {
    console.error(e);
    logError(e, {
      tags: {
        service: 'firebase analytics',
      },
      contexts: {
        message: { value: 'Firebase Analytics Error' },
        params: params ?? {},
        name: { value: name },
      },
    });
    throw e;
  }
};

export const logFBPageView = async ({
  screenName,
  screenClass
}: {
  screenName: string
  screenClass?: string
}) =>
  logFBCustomEvent('screen_view', {
    screen_name: screenName,
    screen_class: screenClass || screenName,
  });


export const firebaseGetUserIdAndToken = async () => {
  const defaultValue = { uid: '', token: '' }
  try {
    const firebaseApp = getApp();
    
    if (!firebaseApp) {
      return defaultValue;
    }

    const auth = getAuth(firebaseApp);
    const currentUser = auth.currentUser;

    if (!currentUser) {
      throw new Error('[getUserIdAndToken] No current user to get UID or token from');
    }

    return {
      uid: currentUser.uid,
      token: await currentUser.getIdToken()
    };
  } catch (error) {
    console.error('[firebaseGetUserIdAndToken] Error: ', error);
    return defaultValue;
  }
};