import React from 'react';
import {IconProps} from './_iconProps';

export const Twitch: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14.6667 14.6667V9.33335M21.3333 14.6667V9.33335M28 2.66669H4V24H10.6667V29.3334L16 24H22.6667L28 18.6667V2.66669Z" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</svg>
