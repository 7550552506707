import React from 'react';
import {IconProps} from './_iconProps';

export const Edit: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14.6667 5.33334H5.33334C4.62609 5.33334 3.94782 5.61429 3.44772 6.11439C2.94762 6.61449 2.66667 7.29276 2.66667 8.00001V26.6667C2.66667 27.3739 2.94762 28.0522 3.44772 28.5523C3.94782 29.0524 4.62609 29.3333 5.33334 29.3333H24C24.7072 29.3333 25.3855 29.0524 25.8856 28.5523C26.3857 28.0522 26.6667 27.3739 26.6667 26.6667V17.3333M24.6667 3.33334C25.1971 2.80291 25.9165 2.50491 26.6667 2.50491C27.4168 2.50491 28.1362 2.80291 28.6667 3.33334C29.1971 3.86377 29.4951 4.58319 29.4951 5.33334C29.4951 6.08349 29.1971 6.80291 28.6667 7.33334L16 20L10.6667 21.3333L12 16L24.6667 3.33334Z" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</svg>
