import React from 'react';

import { AWSIoTProvider } from '@aws-amplify/pubsub/lib/Providers';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { Amplify } from 'aws-amplify';
import {format} from 'date-fns';
import { initializeFirebaseApp } from 'helpers';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import * as api from '@ubiety/fe-api';
import { App } from 'app';
import { ENV } from 'config';

// let overwrite console.log functions for production mode but preserve logs to session records
if(ENV.environment === 'prod' && process.env.NODE_ENV){
//if(ENV.environment === 'dev'){
  const sessionLogs: any[] = [];
  const originalConsoleLog = console.log;
  const originalConsoleWarn = console.warn;
  const originalConsoleError = console.warn;

  console.log = (...args: any[]) => {
    sessionLogs.push(['log', new Date(), args]);
  };
  console.warn = (...args: any[]) => {
    sessionLogs.push(['warn', new Date(), args]);
  };
  console.error = (...args: any[]) => {
    sessionLogs.push(['error', new Date(), args]);
    originalConsoleError(...args);
  };
  (global as {vociferous?: any}).vociferous = function(){
    sessionLogs?.forEach(([logType, date, args]) => {
      if(logType ==='log'){
        originalConsoleLog(format(date, 'MM/dd - kk:mm:ss:SSS'), ...args);
      } else {
        originalConsoleWarn(format(date, 'MM/dd - kk:mm:ss:SSS'), ...args);
      }
    });

    console.log = originalConsoleLog;
    console.warn = originalConsoleWarn;
    console.error = originalConsoleError;
  };

}

console.log('[index] env is', {ENV, env: process.env});

if(process.env.NODE_ENV){
  // log api for local deployments
  (global as {api?: any}).api = api;
  console.log('[index] api is', {...api});
}

// Setup Sentry
Sentry.init({
  dsn: 'https://924b94610b5e47ef8ee82deeb1085035@o368646.ingest.sentry.io/5709855',
  environment: ENV.environment,
  release: `web-app@${ENV.VERSION}`,
  // Disable while running locally.
  enabled: process.env.NODE_ENV !== 'development',
  integrations: [new Integrations.BrowserTracing()],
});

const {
  FIREBASE_API_KEY,
  FIREBASE_AUTH_DOMAIN,
  FIREBASE_DATABASE_URL,
  FIREBASE_PROJECT_ID,
  FIREBASE_STORAGE_BUCKET,
  FIREBASE_APP_ID,
  FIREBASE_MESSAGING_SENDER_ID,

  AWS_IDENTITY_POOL_ID,
  AWS_REGION,
  AWS_PUBSUB_ENDPOINT,
} = ENV;

//Amplify
Amplify.configure({
  Auth: {
    identityPoolId: AWS_IDENTITY_POOL_ID,
    region: AWS_REGION,
    // userPoolId: AWS_USER_POOL_ID,
    // userPoolWebClientId: AWS_USER_POOL_WEB_CLIENT_ID,
  },
});

Amplify.addPluggable(
  new AWSIoTProvider({
    aws_pubsub_region: AWS_REGION,
    aws_pubsub_endpoint: AWS_PUBSUB_ENDPOINT,
  }),
);

initializeFirebaseApp({
  apiKey: FIREBASE_API_KEY,
  authDomain: FIREBASE_AUTH_DOMAIN,
  databaseURL: FIREBASE_DATABASE_URL,
  projectId: FIREBASE_PROJECT_ID,
  storageBucket: FIREBASE_STORAGE_BUCKET,
  appId: FIREBASE_APP_ID,
  messagingSenderId: FIREBASE_MESSAGING_SENDER_ID,
});

const rootEl = document.getElementById('root');

if(rootEl){
  const root = ReactDOM.createRoot(rootEl);
  root.render(

    <BrowserRouter>
      <App />
    </BrowserRouter>

  );
}
