import {
  RawDeviceType,
  RawDeviceData,
  RawPresenceData,
  ENV
} from '@ubiety/fe-api';
import { formatDistance } from 'date-fns';

import { Device, DeviceDerivedDisplayData, DeviceType, LabelValueData } from '../types/device';
import { getUniqueStringArray, snakeToCamelCasePropertyNames } from '../utils';

export const defaultDeviceLabelValue = (device?: Device) => {
  const labelValueArray: Array<LabelValueData> = [];
  if (device?.derivedDisplayData) {
    const { derivedDisplayData } = device;
    const { deviceType, suggestedName, suggestedDeviceType } = derivedDisplayData;

    if (suggestedName && device.name !== suggestedName.value) {
      labelValueArray.push(suggestedName);
    }

    if (suggestedDeviceType && deviceType?.value !== suggestedDeviceType?.value) {
      labelValueArray.push(suggestedDeviceType);
    }

    for (const [key, value] of Object.entries(derivedDisplayData)) {
      if (key === 'suggestedName' || key === 'suggestedDeviceType') {
        return;
      } 
        if (!value) { return; }
        labelValueArray.push(value as LabelValueData);
      
    }
  }

  return labelValueArray;
};

export const deviceSort = (a: Device, b: Device) => {
  const aIsPresent = a?.state === 'present';
  const bIsPresent = b?.state === 'present';
  if (aIsPresent && bIsPresent) {
    const aNameIsMacAddress =
      a?.name === a?.derivedDisplayData?.wifiMacAddress?.value;
    const bNameIsMacAddress =
      b?.name === b?.derivedDisplayData?.wifiMacAddress?.value;
    // Push Mac Address to bottom
    if (aNameIsMacAddress && bNameIsMacAddress) {
      return 0;
    }
    if (aNameIsMacAddress) {
      return 1;
    }
    if (bNameIsMacAddress) {
      return -1;
    }

    // Sort Alphabetically
    return (a?.name ?? '')?.toLowerCase() < (b?.name ?? '')?.toLowerCase()
      ? -1
      : (a?.name ?? '')?.toLowerCase() > (b?.name ?? '')?.toLowerCase()
        ? 1
        : 0;
  }
  if (aIsPresent) {
    return -1;
  }
  if (bIsPresent) {
    return 1;
  }

  return (b.lastSeen ?? b.createdAt ?? 0) - (a.lastSeen ?? a.createdAt ?? 0);
};

export const rawDeviceTypeToDeviceType = (
  rawDeviceType?: RawDeviceType,
): DeviceType => {
  const rawDeviceTypeMap: Record<Exclude<RawDeviceType, null>, DeviceType> = {
    'mobile phone': 'Mobile Phone',
    'computer': 'Computer',
    'tablet': 'Tablet',
    'watch': 'Watch',
    'smart tv': 'Smart TV',
    'gaming console': 'Gaming Console',
    'printer': 'Printer',
    'thermostat': 'Thermostat',
    'light bulb': 'Light Bulb',
    'router': 'Router',
    'other IoT': 'Other IoT',
    'home security': 'Home Security',
    'homeaware sensor': 'Homeaware Sensor',
  };

  if (rawDeviceType && rawDeviceTypeMap[rawDeviceType]) {
    return rawDeviceTypeMap[rawDeviceType];
  }

  return 'Unknown';
};

export const deviceTypeToRawDeviceType = (
  deviceType?: DeviceType,
): RawDeviceType => {

  const deviceTypeMap: Record<DeviceType, RawDeviceType> = {
    'Mobile Phone': 'mobile phone',
    'Computer': 'computer',
    'Tablet': 'tablet',
    'Watch': 'watch',
    'Smart TV': 'smart tv',
    'Gaming Console': 'gaming console',
    'Printer': 'printer',
    'Thermostat': 'thermostat',
    'Light Bulb': 'light bulb',
    'Router': 'router',
    'Other IoT': 'other IoT',
    'Home Security': 'home security',
    'Homeaware Sensor': 'homeaware sensor',
    'Unknown': null,
  };

  if (deviceType && deviceTypeMap[deviceType]) {
    return deviceTypeMap[deviceType];
  }

  return null;
};

// TODO: Optimize and refactor the function processRawDevice
export const processRawDevice = (rawDevice: RawDeviceData, presence?: RawPresenceData[0]) => {
  const {
    device_type: deviceType,
    suggested_device_type: suggestedDeviceType
  } = rawDevice;
  // TODO: replace this one mac_type is propagated from BE
  const isPrivateMacLocalWorkaround = !!(rawDevice.wifiid?.mac||'').match(/^.[26ae]/);
  if(rawDevice.wifiid && isPrivateMacLocalWorkaround){
    rawDevice.wifiid.mac_type = 'random';
  }
  const convertedDevice = snakeToCamelCasePropertyNames(rawDevice) as Device;
  convertedDevice.wifiid = snakeToCamelCasePropertyNames(convertedDevice.wifiid||{}) as any;
  const {
    createdAt,
    suggestedName,
  } = convertedDevice;
  const derivedDisplayData: DeviceDerivedDisplayData = {};

  if (presence?.current_status || presence?.last_status_change) {
    const lastSeenMili = presence?.last_status_change || 0;

    derivedDisplayData.lastSeen = {
      label: 'Last Seen',
      value:
        presence?.current_status === 'present'
          ? 'Present'
          : !lastSeenMili
            ? 'Unknown'
            : `Left ${formatDistance(new Date(), lastSeenMili)} ago`,
      key: 'lastSeen',
      explanation: 'The last time our sensor detected this device',
    };
  }
  if (createdAt) {
    derivedDisplayData.createdAt = {
      label: 'First Seen',
      value: `${formatDistance(new Date(), createdAt)} ago`,
      key: 'createdAt',
      explanation: 'The first time our sensor detected this device',
    };
  }

  if (rawDevice.wifiid?.netbios_name?.length) {
    const nameArray = rawDevice.wifiid?.netbios_name;
    const isNetBiosName = !(nameArray.length === 1 && nameArray[0] === '-') && nameArray.length;

    if (isNetBiosName) {
      derivedDisplayData.netBiosName = {
        label: nameArray.length > 1 ? 'Net Bios Names' : 'Net Bios Name',
        value: nameArray.length > 1 ? nameArray : nameArray[0],
        key: 'netBiosName',
        explanation:
          'This is a potential name your sensor detected from this device. This name may be useful in determining who owns this device',
      };
    }
  }
    
  if (rawDevice.wifiid?.hostname?.length) {
    const hostNames = rawDevice.wifiid?.hostname;
    derivedDisplayData.hostName = {
      label: 'Host Name',
      value: hostNames,
      key: 'hostName',
      explanation:
        'This is a potential name your sensor detected from this device. This name may be useful in determining who owns this device',
    };
  }

  const wifiManufacturerArray = getUniqueStringArray([
    rawDevice.wifiid?.manufacturer
  ]);
  if (wifiManufacturerArray.length === 1) {
    derivedDisplayData.wifiManufacturer = {
      label: 'WiFi Manufacturer',
      value: wifiManufacturerArray[0],
      key: 'wifiManufacturer',
      explanation: 'The company that built this device',
    };
  } else if (wifiManufacturerArray.length > 1) {
    derivedDisplayData.wifiManufacturer = {
      label: 'WiFi Manufacturers',
      value: wifiManufacturerArray.sort((a, b) =>
        a.toLowerCase() < b.toLowerCase()
          ? -1
          : a.toLowerCase() > b.toLowerCase()
            ? 1
            : 0,
      ),
      key: 'wifiManufacturer',
      explanation: 'The company that built this device',
    };
  }

  const wifiMacAddressArray = getUniqueStringArray([
    rawDevice.wifiid?.mac
  ]);
  
  if (wifiMacAddressArray.length === 1) {
    derivedDisplayData.wifiMacAddress = {
      label: (rawDevice.wifiid?.mac_type === 'random')
          ? 'Private WiFi Mac Address'
          : 'WiFi Mac Address',
      value: wifiMacAddressArray[0],
      key: 'wifiMacAddress',
      explanation: "The unique identifier of this device's wireless interfaces",
    };
  } else if (wifiMacAddressArray.length > 1) {
    derivedDisplayData.wifiMacAddress = {
      label: 'WiFi Mac Address',
      value: wifiMacAddressArray.sort((a, b) =>
        a.toLowerCase() < b.toLowerCase()
          ? -1
          : a.toLowerCase() > b.toLowerCase()
            ? 1
            : 0,
      ),
      key: 'wifiMacAddress',
      explanation: "The unique identifier of this device's wireless interfaces",
    };
  }
  if (rawDevice.wifiid?.ip) {
    derivedDisplayData.wifiNetworkIPAddress = {
      label: 'Local IP Address',
      value: rawDevice.wifiid.ip,
      key: 'wifiNetworkIPAddress',
      explanation:
        'The IP address of this device on your local WiFi Network. It is common that this IP address changes based on what other devices are on the same network and in what order they were connected.',
    };
  }
  if (rawDevice?.name) {
    derivedDisplayData.discoveredDeviceName = {
      label: 'Discovered Device Name',
      value: rawDevice.name,
      key: 'discoveredDeviceName',
      explanation:
        'Names that your sensor has discovered about this device. These names may be useful in determining who owns this device',
    };
  }  

  derivedDisplayData.deviceType = {
    label: 'Device Type',
    value: rawDeviceTypeToDeviceType(deviceType),
    key: 'deviceType',
    explanation: 'The category of the device',
  };

  if (suggestedName) {
    derivedDisplayData.suggestedName = {
      label: 'Suggested Name',
      value: suggestedName,
      key: 'suggestedName',
      explanation:
        `This is ${ENV.appName}'s suggestion based on data about this device.`,
    };
  }

  if (suggestedDeviceType) {
    derivedDisplayData.suggestedDeviceType = {
      label: 'Suggested Device Type',
      value: rawDeviceTypeToDeviceType(suggestedDeviceType),
      key: 'suggestedDeviceType',
      explanation:
        `This is ${ENV.appName}'s suggestion based on data about this device.`,
    };
  }

  return {
    ...convertedDevice,
    state: presence?.current_status,
    lastSeen: presence?.current_status === 'present' ? Infinity : presence?.last_status_change,
    lastStatusChange: presence?.last_status_change,
    deviceType: rawDeviceTypeToDeviceType(deviceType),
    suggestedDeviceType: rawDeviceTypeToDeviceType(suggestedDeviceType),
    derivedDisplayData,
  };
};

export const processRawDeviceArray = (
  rawDeviceArray: Array<RawDeviceData>,
  presenceData?: RawPresenceData,
) => {
  const newDeviceArray: Array<Device> = [];
  const newDevices: Record<string, Device> = {};
  const newDevicesByProfileUid: Record<string, Record<string, Device>> = {};
  const newDeviceArrayByProfileUid: Record<string, Array<Device>> = {};

  rawDeviceArray.forEach?.((rawDevice) => {
    const presence = presenceData?.[rawDevice.uid];
    const processedDevice = processRawDevice(rawDevice, presence);
    const {
      profileId,
      uid
    } = processedDevice;
    newDeviceArray.push(processedDevice);
    newDevices[processedDevice.uid] = processedDevice;
    if (profileId) {
      if (!newDevicesByProfileUid[profileId]) {
        newDevicesByProfileUid[profileId] = {};
      }
      newDevicesByProfileUid[profileId][uid] = processedDevice;

      if (!newDeviceArrayByProfileUid[profileId]) {
        newDeviceArrayByProfileUid[profileId] = [];
      }
      newDeviceArrayByProfileUid[profileId].push(processedDevice);
    }
  });

  return {
    newDeviceArray,
    newDevices,
    newDevicesByProfileUid,
    newDeviceArrayByProfileUid,
  };
};

export const isPhone = (deviceType: DeviceType) => deviceType === 'Mobile Phone';

export const isPersonalDevice = (deviceType: DeviceType) => {
  const personalDevicesMap: Partial<Record<DeviceType, DeviceType>> = {
    Computer: 'Computer',
    Tablet: 'Tablet',
    Watch: 'Watch'
  };

  return !!personalDevicesMap?.[deviceType];
};

export const isOtherDevice = (deviceType: DeviceType) => {
  const otherDevicesMap: Partial<Record<DeviceType, DeviceType>> = {
    'Gaming Console': 'Gaming Console',
    'Home Security': 'Home Security',
    'Light Bulb': 'Light Bulb',
    'Other IoT': 'Other IoT',
    'Printer': 'Printer',
    'Router': 'Router',
    'Thermostat': 'Thermostat',
    'Unknown': 'Unknown',
    'Smart TV': 'Smart TV'
  };

  return !!otherDevicesMap?.[deviceType];
};

export const isAssignedDevice = (device: Device) => {
  if (!device.profileId) {
    return false;
  }
  return true;
};