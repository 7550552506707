import styled from 'styled-components';

export const ScrollableDataTableContainer = styled.div`${({theme}) => `
  max-height: 200px;
  overflow-x: scroll;
  border-radius: 16px;
  border: 1px solid ${theme.colors.sky.base};

  table {
    border-spacing: 0px;
    border-collapse: collapse;
  }

  th {
    background-color: ${theme.colors.sky.lighter};
    font-weight: normal;
    text-align: left;
  }

  th, td {
    border: 1px solid ${theme.colors.sky.base};
    padding: ${theme.fontPadding.xxs}px ${theme.fontPadding.m}px;
    font-size: ${theme.fontSize.base}px;
  }

  table {
    width:101%;
    margin-left:-1px;
    margin-top:-1px;
    margin-bottom:-1px;
    margin-right:-1px;
  }

  table {
    position: relative;
  }

  th {
    position: sticky;
    top: -1px;
  }

`}`;
