import {
  RawProfile,
  RawPresenceData,
  getHomeProfiles,
  getHomeProfilesPresence,
  UpdateProfilePayload,
  createProfile as createProfileApi,
  updateProfile as updateProfileApi,
  deleteProfile as deleteProfileApi,
  CreateProfilePayload,
  logCreateProfileEvent,
  logProfileNameChangeEvent,
  logProfileNotificationsChangeEvent,
  logDeleteProfileEvent,
} from '@ubiety/fe-api';
import { createStore } from 'zustand/vanilla';

// import { persist } from 'zustand/middleware';
import { getHomeId } from './selectors';
import { ContextError, FnQueueWithRetries } from '../classes';
import { fnSilentWrapper, processRawProfileArray, withCatcher , getListAllData } from '../helpers';
import { DataStatus, Maybe, PromiseVoid } from '../types';
import { Profile } from '../types/profile';

interface IProfilesState {
  profileArray: Array<Profile>;
  profilesDataStatus: DataStatus;
  profiles: Record<string, Profile | undefined>;

  rawProfiles: Maybe<Array<RawProfile>>;
  rawProfilePresence: Maybe<RawPresenceData>;

  profilePresenceFetchedAt: Maybe<number>;
}

type ProfilesAction = {
  reset: () => void;
  getProfiles: () => PromiseVoid;
  getProfilesSilently: () => Promise<Maybe<boolean>>;
  getProfilesPresence: () => PromiseVoid;
  getProfilesPresenceSilently: () => Promise<Maybe<boolean>>;
  processRawProfilesIntoTheState: () => void;

  createProfile: (payload: CreateProfilePayload) =>
    Promise<Awaited<ReturnType<typeof createProfileApi>>['data'] | null>;
  createProfileSilently: (payload: CreateProfilePayload) => Promise<Maybe<boolean>>;
  updateProfile: (
    profileUid: number,
    data: Omit<UpdateProfilePayload, 'uid'>,
  ) =>
    Promise<Awaited<ReturnType<typeof updateProfileApi>>['data'] | null>;
  updateProfileSilently: (
    profileUid: number,
    data: Omit<UpdateProfilePayload, 'uid'>,
  ) => Promise<Maybe<boolean>>;
  deleteProfile: (profileUid: number) =>
    Promise<Awaited<ReturnType<typeof deleteProfileApi>>['data'] | null>;
  deleteProfileSilently: (profileUid: number) => Promise<Maybe<boolean>>;
};

export type ProfilesState = IProfilesState & ProfilesAction;

const INITIAL_STATE: IProfilesState = {
  profiles: {},
  profileArray: [],
  profilesDataStatus: undefined,

  rawProfiles: null,
  rawProfilePresence: null,

  profilePresenceFetchedAt: null
};

const profilesQueue = new FnQueueWithRetries();
const profilesPresenceQueue = new FnQueueWithRetries();

export const profilesStore = createStore<ProfilesState>()(
  // persist(
    (set, get) => ({
      ...INITIAL_STATE,
      reset: () => set(INITIAL_STATE),
      getProfiles: withCatcher('profilesStore.getProfiles', async () => {
        const homeId = getHomeId();
        if (!homeId) { 
          throw new ContextError('there is no home to get profiles', {isLocal: true});
        }

        const response = await getListAllData<typeof getHomeProfiles, RawProfile>({ fn: getHomeProfiles, payload: {homeId}, queque: profilesQueue });
        if (response) {
          set({ rawProfiles: response as Array<RawProfile> });
        }
      }, (e) => {
        if (e.isLocal) {return;}
        set({ profilesDataStatus: 'error' });
      }),
      getProfilesSilently: fnSilentWrapper(async () => {
        return get().getProfiles();
      }),
      getProfilesPresence: withCatcher('profilesStore.getProfilesPresence', async () => {
        const homeId = getHomeId();
        if (!homeId) { 
          throw new ContextError('there is no home to get profiles presence', {isLocal: true});
        }
        const res = await getListAllData<typeof getHomeProfilesPresence, RawPresenceData>({ fn: getHomeProfilesPresence, payload: {homeId}, queque: profilesPresenceQueue });
        if (res) {
          set({ rawProfilePresence: res as RawPresenceData, profilePresenceFetchedAt: Date.now() });
        }
      }, (e) => {
        if (e.isLocal) {return;}
        set({ profilesDataStatus: 'error' });
      }),
      getProfilesPresenceSilently: fnSilentWrapper(async () => {
        return get().getProfilesPresence();
      }),
      processRawProfilesIntoTheState: () => {
        const { rawProfiles, rawProfilePresence } = get();
        if (!rawProfiles) { return; }

        const {
          newProfiles,
          newProfileArray
        } = processRawProfileArray(rawProfiles, rawProfilePresence || undefined);

        set({ profiles: newProfiles, profileArray: newProfileArray, profilesDataStatus: 'success' });
      },
      createProfile: withCatcher('profilesStore.createProfile', async (data) => {
        const homeId = getHomeId();
        if (!homeId) { 
          throw new ContextError('there is no home to create profile', {isLocal: true});
        }
        const response = await createProfileApi(homeId, data);
        // TODO: Determine if this can be removed or not.
        logCreateProfileEvent({
          profileUid: String(response.data.uid),
        });
        return response.data;
      }, (e) => {
        if (e.isLocal) {return;}
        throw new ContextError('Error creating profile', {isLocal: false});
      }),
      createProfileSilently: fnSilentWrapper(async (data) => {
        return get().createProfile(data);
      }),
      updateProfile: withCatcher('profilesStore.updateProfile', async (profileUid, data) => {
        const homeId = getHomeId();
        if (!homeId) { 
          throw new ContextError('there is no home to create profile', {isLocal: true});
        }

        const response = await updateProfileApi(homeId, profileUid, data);
        
        const previousProfile = get().profiles[profileUid];

        // Log Changes
        if (
          data.name &&
          previousProfile?.name &&
          previousProfile.name !== data.name
        ) {
          logProfileNameChangeEvent({
            fromName: previousProfile.name,
            toName: data.name,
            profileUid: `${profileUid}`,
          });
        }

        if (data.alert_on_enter &&
          previousProfile &&
          previousProfile.alertOnEnter &&
          previousProfile.alertOnEnter !== data.alert_on_enter) {

          logProfileNotificationsChangeEvent({
            profileUid: `${profileUid}`,
            notificationsType: 'enter',
            notificationsValue: !!data.alert_on_enter
          });
        }

        if (data.alert_on_leave &&
          previousProfile &&
          previousProfile.alertOnLeave &&
          previousProfile.alertOnLeave !== data.alert_on_leave) {

          logProfileNotificationsChangeEvent({
            profileUid: `${profileUid}`,
            notificationsType: 'exit',
            notificationsValue: !!data.alert_on_leave
          });
        }

        // replace current profile value with response
        const {rawProfiles, processRawProfilesIntoTheState} = get();
        const rawProfile = response.data;
        const newRawProfiles = [...(rawProfiles||[])];
        const rawProfileIndex = newRawProfiles.findIndex(p => p.uid === profileUid);
        newRawProfiles.splice(rawProfileIndex, 1);
        newRawProfiles.push(rawProfile);
        set({
          rawProfiles: newRawProfiles
        });
        processRawProfilesIntoTheState();
        return response.data;
      }, (e) => {
        if (e.isLocal) {return;}
        throw new ContextError('Error updating profile', {isLocal: false});
      }),
      updateProfileSilently: fnSilentWrapper(async (profileUid, data) => {
        return get().updateProfile(profileUid, data);
      }),
      deleteProfile: withCatcher('profilesStore', async (profileUid) => {
        const homeId = getHomeId();
        if (!homeId) { 
          throw new ContextError('there is no home to create profile', {isLocal: true});
        }

        const response = await deleteProfileApi(homeId, profileUid);

        logDeleteProfileEvent({
          profileUid: `${profileUid}`,
        });
        return response.status === 200;
      }, (e) => {
        if (e.isLocal) {return;}
        throw new ContextError('Error deleting profile', {isLocal: false});
      }),
      deleteProfileSilently: fnSilentWrapper(async (profileUid) => {
        return get().deleteProfile(profileUid);
      }),
    }),
  //   {
  //     name: 'profiles-storage',
  //   },
  // ),
);
