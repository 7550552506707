import React from 'react';
import {IconProps} from './_iconProps';

export const Cloud: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M24 13.3333H22.32C21.8212 11.4013 20.7904 9.64796 19.3449 8.27251C17.8993 6.89705 16.0969 5.95463 14.1426 5.55235C12.1882 5.15006 10.1601 5.30403 8.28887 5.99677C6.41761 6.6895 4.77818 7.89323 3.55687 9.47116C2.33555 11.0491 1.58132 12.9379 1.37987 14.9231C1.17843 16.9083 1.53785 18.9102 2.4173 20.7013C3.29675 22.4924 4.66095 24.0009 6.35491 25.0554C8.04886 26.1099 10.0046 26.6681 12 26.6666H24C25.7681 26.6666 27.4638 25.9643 28.714 24.714C29.9643 23.4638 30.6667 21.7681 30.6667 20C30.6667 18.2319 29.9643 16.5362 28.714 15.2859C27.4638 14.0357 25.7681 13.3333 24 13.3333Z" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</svg>
