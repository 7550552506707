import React from 'react';
import {IconProps} from './_iconProps';

export const Monitor: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10.6667 28H21.3333M16 22.6667V28M5.33334 4H26.6667C28.1394 4 29.3333 5.19391 29.3333 6.66667V20C29.3333 21.4728 28.1394 22.6667 26.6667 22.6667H5.33334C3.86058 22.6667 2.66667 21.4728 2.66667 20V6.66667C2.66667 5.19391 3.86058 4 5.33334 4Z" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</svg>
