import {
  TellMeWhenApiItem,
  TellMeWhenApiNewItemPayload,
  TellMeWhenApiUpdateItemPayload,
} from '@ubiety/fe-api';

import { TellMeWhenSuspiciousItem } from '../types/tell_me_when';

export const TELL_ME_WHEN_SUSPICIOUS_OPTIONS_MAP = {
  'counter_anomaly': 'There are more phones than expected.',
  'ssid_alert': 'A new network name shows up.',
  'mobile_phone_type_alert': 'A new phone brand shows up.',
  'bt_manufacturer_alert': 'A new bluetooth signal shows up.'
};

export const TELL_ME_WHEN_SUSPICIOUS_OPTIONS = Object.keys(TELL_ME_WHEN_SUSPICIOUS_OPTIONS_MAP);

export const apiItemToTellMeWhenSuspiciousItem = (p: Array<TellMeWhenApiItem> | TellMeWhenApiItem): Array<TellMeWhenSuspiciousItem> => {
  const items = Array.isArray(p) ? p : [p];

  return items.filter(item => item.profile_ids?.length && item.notification_types?.length).map(item => ({
    itemType: 'tmwSuspicious',
    enabled: item.enabled,
    active: item.active,
    profiles: item.profile_ids,
    options: item.notification_types,
    uid: String(item.uid),
    createdAt: item.created_at,
  } as TellMeWhenSuspiciousItem));
};

export const tellMeWhenSuspiciousItemToApiItem = (homeId: number | string, p: Partial<TellMeWhenSuspiciousItem>): TellMeWhenApiItem | TellMeWhenApiNewItemPayload | TellMeWhenApiUpdateItemPayload => {
  const item: any = {
    home_id: homeId ? Number(homeId) : undefined,
    enabled: p.enabled,
    active: p.active,
    profile_ids: p.profiles,
    notification_types: p.options,
    uid: p.uid,
    created_at: p.createdAt
  };

  Object.entries(item).forEach(([key, value]) => {
    if (value === undefined) {
      delete item[key];
    }
  });

  return item;
};