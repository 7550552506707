import { CONTEXT_GLOBAL } from './globals';
import { initDependencies } from './initDependencies';
import { initDependenciesLite } from './initDependenciesLite';
import { appVersionStore } from './store';

export const initUbietyContext = (options: Partial<typeof CONTEXT_GLOBAL>, isLite?: boolean) => {
  Object.entries(options).forEach(([key, value]) => {
    (CONTEXT_GLOBAL as any)[key] = value;
  });
  appVersionStore.setState({localVersion: CONTEXT_GLOBAL.getInstalledAppVersion()});
  
  const initDeps = isLite ? initDependenciesLite : initDependencies;

  initDeps();
};

export const contextReset = () => {
  (CONTEXT_GLOBAL as any).logFBCustomEvent = () => {
    return new Promise((resolve) => resolve);
  };
  // TODO: prettify these
  // eslint-disable-next-line
  (CONTEXT_GLOBAL as any).userId = null,
  // eslint-disable-next-line
  (CONTEXT_GLOBAL as any).hardCodedHomeId = null;
  // eslint-disable-next-line
  (CONTEXT_GLOBAL as any).hardCodedAccountId = null;

  return true;
};
