export const objectKeysSnakeToCamelCase = function(obj: Record<string, any>){
  const converted: {
    [key: string]: any
  } = {};
  for(const key in obj){
    if(Object.prototype.hasOwnProperty.call(obj, key)){
      const convertedKey = key.replace(/_(.)/g, (_, g) => g.toUpperCase() );
      converted[convertedKey] = obj[key];
    }
  }
  return converted;
};