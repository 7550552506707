import React from 'react';
import {IconProps} from './_iconProps';

export const RefreshCw: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M30.6667 5.33335V13.3334M30.6667 13.3334H22.6667M30.6667 13.3334L24.48 7.52002C23.047 6.0863 21.2742 5.03896 19.3269 4.47572C17.3797 3.91247 15.3215 3.85169 13.3444 4.29903C11.3673 4.74637 9.53573 5.68726 8.02062 7.03391C6.50551 8.38056 5.35622 10.0891 4.67999 12M1.33333 26.6667V18.6667M1.33333 18.6667H9.33333M1.33333 18.6667L7.51999 24.48C8.95299 25.9137 10.7258 26.9611 12.6731 27.5243C14.6203 28.0876 16.6785 28.1483 18.6556 27.701C20.6327 27.2537 22.4643 26.3128 23.9794 24.9661C25.4945 23.6195 26.6438 21.911 27.32 20" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</svg>
