import React from 'react';
import {IconProps} from './_iconProps';

export const Facebook: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M24 2.66666H20C18.2319 2.66666 16.5362 3.36904 15.2859 4.61928C14.0357 5.86952 13.3333 7.56521 13.3333 9.33332V13.3333H9.33333V18.6667H13.3333V29.3333H18.6667V18.6667H22.6667L24 13.3333H18.6667V9.33332C18.6667 8.9797 18.8071 8.64056 19.0572 8.39051C19.3072 8.14047 19.6464 7.99999 20 7.99999H24V2.66666Z" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</svg>
