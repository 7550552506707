import React, {useEffect, useMemo, useState, useCallback} from 'react';
import {
  useParams
} from 'react-router';
import {
  NavLink
} from 'react-router-dom';
import {
  DataTableProps,
  Loading,
  OrangeBox,
  ThemedDataTable,
  ItemsInTimeGraph,
  ItemsInTimeGraphProps
} from 'components';
import {
  useNewReportsContext,
  NewSavedReportValue,
} from 'context';
import {
  CellItemsDataDisplay
} from 'elements';
import {
  ProcessedReport,
  processReport,
  providerToLabelName
} from 'helpers';
import {
  format
} from 'date-fns';
import { Flex6, FlexContainer } from 'layout';
import styled from 'styled-components';
import {
  Bell,
} from 'theme/icons';
import {newColors} from 'theme';
import {
  saveFile,
  arrayOfObjectToCsv,
  mixColors
} from 'utils';
import {
  Download
} from 'theme/icons';
import {
  signalsRange
} from 'config';

export const ForensicReportItem: React.FC = () => {
  const {id} = useParams();
  const {reports, getReport} = useNewReportsContext();
  const report = useMemo<NewSavedReportValue|null>(() => id && reports ? reports[id]: null, [id, reports]);
  const [loaded, setLoaded] = useState<boolean>(report?true:false);

  // EFFECTS
  // load report
  useEffect(function getReportIfNotYetLoadedExist(){(async () => {
    if(!id){ return }
    if(!report && !loaded){
      setLoaded(false);
      await getReport(id);
      setLoaded(true);
    }
  })()},[
    id,
    reports,
    loaded,
    getReport,
    report
  ]);

  // MEMOS
  // !important
  // process retrieved report to generate local timestamp values
  const processedReport = useMemo<ProcessedReport|null>(() => report ? processReport(report) : null,[report]);

  // get sample values for the table
  const eventsSample = useMemo(function findImmediateTimeSurroundedSample(){
    if(!processedReport){ return [] };
    const targetTimestamp = processedReport?.reportTime;
    const items = processedReport.items.sort((a,b) => a.timestamp > b.timestamp ? 1 : -1);
    let index = 0;
    for(let i=0;i<items.length;i++){
      if(items[i].timestamp>=targetTimestamp){
        index = i;
        break;
      }
    }
    if(!index){
      index = items.length
    }
    return items.slice(index-4,index+4);
  },[
    processedReport
  ]);

  // CALLBACKS
  const handleCVSSave = useCallback(function(e){
    e.preventDefault();
    e.stopPropagation();
    const items = processedReport?.items || [];
    
    saveFile('report-file.csv', arrayOfObjectToCsv(items, [
      'timestamp',
      'type',
      'provider',
      'power',
      'tmsi'
    ]));
  },[
    processedReport?.items
  ]);

  const breadcrumbs = useMemo(() => <StyledBreadcrumbs>
    <NavLink to="/forensic-reports">Foresic Reports</NavLink>{' > '}<span>Report {processedReport ? 'For '+format(Number(processedReport.localReportTime), 'MMM do'):''}</span>
  </StyledBreadcrumbs>, [
    processedReport
  ]);

  return <>
    
    {!loaded 
    ? 
    <Loading centered height={10} width={200} strokesSettings={{numberOfStrokes: 40, strokesSpaces: 45}} />
    :
    !processedReport
    ?
    <>report not found</>
    :
    <StyledPage>
      {breadcrumbs}
      <h1>Forensic Report for {format(Number(processedReport.localReportTime), 'MMM do')} at {format(Number(processedReport.localReportTime), 'h:mm a')}</h1>
      <h3>Includes sensor activity window from {format(Number(processedReport.localStartTime), 'h:mm a')} to {format(Number(processedReport.localEndTime), 'h:mm a')}</h3>
    
      <FlexContainer className="flex-section section">
        <Flex6>
          <OrangeBox>
            <h1>Event Summary Data</h1>
            <h3>Top activity around the time of the event</h3>
            
              <EventsSampleTable 
                data={eventsSample}
                index={['localTimestamp', 'type', 'provider', 'power', 'tmsi']}
                mixinRows={{
                  5: <td colSpan={5}><ReportTimeLabel className="rtl-horizontal"><span className="rtl-event-label event-label"><Bell /> Event at {format(processedReport.localReportTime,'h:mm:ss a')}</span><span className='rtl-horizontal-line'></span></ReportTimeLabel></td>
                }}
                dataFormating={{
                  localTimestamp: v => format(v, 'h:mm:ss a'),
                  power: v => Math.round(Number(v)*100)/100 + ' dbm',
                  tmsi: v => v||''
                }}
                titlesFormatting={{
                  localTimestamp: () => 'Time',
                  type: () => 'Type',
                  provider: () => 'Provider',
                  power: () => 'Signal Strength',
                  tmsi: () => 'TMSI'
                }}
                />
            
          </OrangeBox>
        </Flex6>
        <Flex6>
          <OrangeBox>
            <h1>TMSI Tracking</h1>
            <h3>TMSI seen in event window </h3>

            <EventinTimeGraphBox>
              <ReportTimeLabel className="rtl-vertical"><span className="rtl-event-label event-label"><Bell /> Event at {format(processedReport.localReportTime,'h:mm:ss a')}</span><span className='rtl-vertical-line'></span></ReportTimeLabel>
              <ReportItemsInTimeDisplay 
                allItems={processedReport.items}
                startTimestamp={processedReport.localStartTime}
                endTimestamp={processedReport.localEndTime}
                />
            </EventinTimeGraphBox>
          </OrangeBox>
        </Flex6>
      </FlexContainer>

      <div className="section">
        <a className="right-action-button" href="#nada" onClick={handleCVSSave}>Download CSV <Download /></a>
        <h1>Complete Cellular Activity for the Hour</h1>
        <h3>Cellular pings and activity between {format(Number(processedReport.localStartTime), 'h:mm a')} and {format(Number(processedReport.localEndTime), 'h:mm a')}</h3>
        
        <CellItemsDataDisplay 
          processedReportItems={processedReport.items}
          pageSize={20} />
      </div>

    </StyledPage>
    }
    </>
}

const StyledBreadcrumbs = styled.div`${({theme}) => `
  height: 22px;
  margin-top: -22px;
  margin-bottom: ${theme.boxMargins.l*1.2}px;
`}`;

const ReportItemsInTimeDisplay: React.FC<{
  allItems: ProcessedReport['items'],
  startTimestamp: number,
  endTimestamp: number,
  pagination?: boolean,
  pageSize?: number
}> = ({
  allItems=[],
  startTimestamp,
  endTimestamp
}) => {
  const [itemsInTimegraphItems, setItemsInTimeGraphItems] = useState<Array<(ItemsInTimeGraphProps['data'][0])&{latestTimestamp: number}>>([]);

  useEffect(function filterAndProcessedReportItems(){
    // make sure it has TMSI
    // make sure its within the range
    // create the payload
    const tmsiKeyedIndex: {
      [key: string]: typeof itemsInTimegraphItems[0]
    } = {};
    allItems?.forEach(it => {
      if(it.localTimestamp >= startTimestamp && it.localTimestamp <= endTimestamp && it.tmsi){
        const tmsi = it.tmsi;
        if(!tmsiKeyedIndex[tmsi]){
          tmsiKeyedIndex[tmsi] = {
            items: [],
            label: providerToLabelName(it.provider),
            value: String(tmsi),
            latestTimestamp: it.localTimestamp
          }
        }
        const target = tmsiKeyedIndex[tmsi];
        const providerLabelColor = newColors[providerToLabelName(it.provider)];
        target.items.push({
          date: new Date(it.localTimestamp),
          color: mixColors(providerLabelColor, '#FFFFFF', (Math.max((Math.abs(it.power)-signalsRange.min),0)/(signalsRange.max-signalsRange.min))*signalsRange.outOf)
        });
        if(target.latestTimestamp < it.localTimestamp){
          target.latestTimestamp = it.localTimestamp
        }
      }
    });
    
    setItemsInTimeGraphItems(Object.values(tmsiKeyedIndex).sort((a,b) => a.latestTimestamp > b.latestTimestamp ? 1 : -1));
  },[
    allItems,
    startTimestamp,
    endTimestamp
  ]);

  const axisLabels = useMemo(() => {
    const step = (endTimestamp - startTimestamp)/6;
    const half = (endTimestamp - startTimestamp)/2;
    const labels = [
      {
        value: startTimestamp+step,
        label: format(startTimestamp+step, 'hh:mm aa')
      },
      {
        value: startTimestamp+half,
        label: format(startTimestamp+half, 'hh:mm aa')
      },
      {
        value: endTimestamp-step,
        label: format(endTimestamp-step, 'hh:mm aa')
      },
    ];
    return labels;
  },[
    startTimestamp,
    endTimestamp
  ]);

  return <ItemsInTimeGraph
            className="items-in-time-graph"
            width={625}
            minHeight={298}
            data={itemsInTimegraphItems.slice(itemsInTimegraphItems.length/2-5,itemsInTimegraphItems.length/2+5)}
            gridCellWidth={0}
            labelColWidth={100}
            startDate={new Date(startTimestamp)}
            endDate={new Date(endTimestamp)}
            axisLabels={axisLabels}
            timeaxisHeight={20}
            lineHeigth={28}
            padding={{
              top: 0,
              bottom: 0,
              left: 0,
              right: 0
            }}
            marksR={3.5}
            />
}

const StyledPage = styled.div`${({theme}) => `
  .section:not(:first-child) {
    margin-top: ${theme.boxMargins.xl4}px;
  }
  h3 {
    margin-bottom: ${theme.boxMargins.xl2}px;
  }
  .right-action-button {
    float: right;
    margin-top: 2rem;
  }

  @media (max-width: 1150px){
    .flex {
      padding: 0px !important;
    }
    .flex, 
    .flex .orange-box{
      width: 100%;
      max-width: none !important;

    }
    .flex .orange-box svg {
      float: right;
    }
    .flex + .flex {
      margin-top: 50px;
      margin-bottom: 20px;
    }
  }

  @media (min-width: 860px) and (max-width: 1150px){
    .nirto {
      margin-right: 60px;
      float: right;
      width: 630px;
    }
  }
`}`;

const ReportTimeLabel = styled.div`${({theme}) => `
  display: flex;
  align-items: center;

  > * {
    display: flex;
  }

  color: ${theme.newColors.SwissCream};

  &.rtl-vertical {
    position: absolute;
    height: 100%;
    flex-direction: column;
    left: 100px;
    right: 0;
  }

  pointer-events: none;

  .rtl-event-label {
    flex-grow: 0;
    background-color ${theme.newColors.MildRed};
    padding: ${theme.boxPadding.s}px;
    border-radius: ${theme.boxPadding.s}px;
  }
  .rtl-event-label .icon{
    height: 1.3rem;
    width: 1.3rem;
    margin-right: 0.75rem;
    margin-left: 0.5rem;
  }
  
  .rtl-event-label .icon path{
    stroke-width: 2px;
  }

  .rtl-event-label {
    padding-right: ${theme.boxPadding.xl}px;
    align-items: center;
  }
  .rtl-horizontal-line {
    flex-grow: 10;
    border: 2px solid ${theme.newColors.MildRed};
  }
  .rtl-vertical-line {

    flex-grow: 10;
    border: 2px solid ${theme.newColors.MildRed};
  }

  z-index: 100;

`}`;

const EventinTimeGraphBox = styled.div`${() => `
  position: relative;
  padding-top: 1em;
  height: 382px;
  display: flex;
  align-items: center;
  .items-in-time-graph {
    width: 100%;
  }
`}`;

const EventsSampleTable = styled(({...props}: DataTableProps) => <ThemedDataTable {...props}/>)`${()=>`
  tr.mixin td {
    border-bottom: 0;
    padding: 0;
  }
  tr:nth-child(5) td {
    border-bottom: 0;
  }
  width: 100%;
`}`;
