import React, {useMemo} from 'react';
import styled, {keyframes, css} from 'styled-components';

import { Loading } from '../Loading';
import {
  useOverlayContext
} from 'context';
import {
  Popup
} from './Popup';

// this has to remain separate because hot reload breaks 
// if it is kept in the same file as context
// also sort of make sense

export const OverlayDisplay = () => {
  const {
    loading, popup,
    hidePopup
  } = useOverlayContext();

  const hasOverlay = useMemo(() => loading || popup, [
    loading, popup
  ]);

  return hasOverlay ?
    <StyledHover>
      {loading ?
        <Loading />: null}

      {popup ?
        <Popup {...popup} onDissmiss={() => {
          popup.onDissmiss?.();
          hidePopup();
        }}/>: null}
      
    </StyledHover>
    : null;
};

const appear = keyframes`
  from {
    opacity: 0
  }

  to {
    opacity: 1
  }
`;


const StyledHover = styled('div')`${({theme}) => css`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;

  background-color: rgba(${theme.colorsRgb.ink.black.toString()},0.5);

  z-index: 1000;

  > svg {
    position: absolute;
    margin: auto;

    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    opacity: 0.7
  }

  animation-name: ${appear};
  animation-duration: 500ms;
`}`;
