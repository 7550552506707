import React from 'react';
import {IconProps} from './_iconProps';

export const Flag: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5.33333 20C5.33333 20 6.66666 18.6667 10.6667 18.6667C14.6667 18.6667 17.3333 21.3333 21.3333 21.3333C25.3333 21.3333 26.6667 20 26.6667 20V4.00001C26.6667 4.00001 25.3333 5.33334 21.3333 5.33334C17.3333 5.33334 14.6667 2.66667 10.6667 2.66667C6.66666 2.66667 5.33333 4.00001 5.33333 4.00001V20ZM5.33333 20V29.3333" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</svg>
