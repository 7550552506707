import React from 'react';
import {IconProps} from './_iconProps';

export const Server: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.00001 7.99999H8.01334M8.00001 24H8.01334M5.33334 2.66666H26.6667C28.1394 2.66666 29.3333 3.86056 29.3333 5.33332V10.6667C29.3333 12.1394 28.1394 13.3333 26.6667 13.3333H5.33334C3.86058 13.3333 2.66667 12.1394 2.66667 10.6667V5.33332C2.66667 3.86056 3.86058 2.66666 5.33334 2.66666ZM5.33334 18.6667H26.6667C28.1394 18.6667 29.3333 19.8606 29.3333 21.3333V26.6667C29.3333 28.1394 28.1394 29.3333 26.6667 29.3333H5.33334C3.86058 29.3333 2.66667 28.1394 2.66667 26.6667V21.3333C2.66667 19.8606 3.86058 18.6667 5.33334 18.6667Z" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</svg>
