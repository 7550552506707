import styles from './Input.module.css';

type TextAreaProps = {
  disabled?: boolean,
  value?: string,
  defaultValue?: string,
  id?: string,
  el?: React.MutableRefObject<HTMLTextAreaElement>,
  name?: string,
  placeholder?: string,
  className?: string,
  onChange?: (e:React.ChangeEvent<HTMLTextAreaElement>) => void
}

export const TextArea: React.FC<TextAreaProps> = ({
  disabled,
  value=undefined,
  id,
  el,
  name,
  placeholder,
  className,
  onChange,
  defaultValue
}) =>

  <textarea

    key={id}

    value={value}
    disabled={disabled}
    defaultValue={defaultValue}
    ref={el}
    rows={6}
    name={name}
    placeholder={placeholder}
    className={className+' '+styles.textarea}
    onChange={onChange} />;
