import React from 'react';
import {IconProps} from './_iconProps';

export const Mail: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M29.3333 8C29.3333 6.53333 28.1333 5.33333 26.6667 5.33333H5.33332C3.86666 5.33333 2.66666 6.53333 2.66666 8M29.3333 8V24C29.3333 25.4667 28.1333 26.6667 26.6667 26.6667H5.33332C3.86666 26.6667 2.66666 25.4667 2.66666 24V8M29.3333 8L16 17.3333L2.66666 8" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</svg>
