import {
  homeStore,
  accountStore,
  profilesStore,
  devicesStore,
  sensorsStore,
} from './store';

// reload app version
const { getUser, reset: resetAccountStore } = accountStore.getState();
const { getHome, reset: resetHomeStore } = homeStore.getState();
const {
  getProfiles,
  getProfilesPresence,
  processRawProfilesIntoTheState,
  reset: resetProfilesStore,
} = profilesStore.getState();



const { 
  getDevices, 
  getDevicesPresence, 
  processRawDevicesIntoTheState,
  reset: resetDevicesStore,
} = devicesStore.getState();

const { getSensors, reset: resetSensorsStore } = sensorsStore.getState();

export const initDependenciesLite = () => {

  // Subscription to homeId. Make any calls depend on homeId changes
  const unsubscribeHome = homeStore.subscribe((state, prevState) => {
    const isHomeLoaded = state.homeDataStatus === 'success';
    const isPrevHomeLoaded = prevState.homeDataStatus === 'success';

    if (isHomeLoaded && isHomeLoaded !== isPrevHomeLoaded) {
      getSensors();
    }
    
    const homeId = state.home?.uid;
    const prevHomeId = prevState.home?.uid;
    if (homeId !== prevHomeId) { // homeId has changed
      /* homeId exists */
      if (homeId) {
        // Alerts

        // AlertsSettings
        // if (!alertSettingsStatus) {
        //   getAllAlertSettings();
        // }

        // Profiles
        getProfiles();
        getProfilesPresence();

        // Devices
        getDevices();
        getDevicesPresence();

        getSensors();
        return;
      }

      /** homeId doesn't exist */
      resetProfilesStore();
      resetDevicesStore();
      resetSensorsStore();
    }
  });

  const unsubscribeAccount = accountStore.subscribe((state, prevState) => {
    const fbUserId = state.fbUserId;
    const prevUserId = prevState.fbUserId;

    if (fbUserId !== prevUserId) {
      if (fbUserId) {
        getUser();
      } else {
        resetAccountStore();
      }
    }

    const accountId = state.accountId;
    const prevAccountId = prevState.accountId;



    if (accountId !== prevAccountId) {
      if (accountId) {
        getHome();
      } else {
        resetHomeStore();
      }
    }
  });
  /* TODO: add listener for 'notification_preferences' for addModelsChangedListener function
         when ModelsChangedContext will be added
  */

  const unsubscribeProfiles = profilesStore.subscribe((state, prevState) => {
    const rawProfiles = state.rawProfiles;
    const prevRawProfiles = prevState.rawProfiles;
    const isRawProfilesChanged = rawProfiles && rawProfiles !== prevRawProfiles;

    const rawProfilePresence = state.rawProfilePresence;
    const prevProfilePresence = prevState.rawProfilePresence;
    const isRawProfilesPresenceChanged = rawProfilePresence && rawProfilePresence !== prevProfilePresence;

    if (isRawProfilesChanged || isRawProfilesPresenceChanged) {
      processRawProfilesIntoTheState();
    }
  });

  const unsubscribeDevices = devicesStore.subscribe((state, prevState) => {
    const rawDevices = state.rawDevices;
    const prevRawDevices = prevState.rawDevices;
    const isRawDevicesChanged = rawDevices && rawDevices !== prevRawDevices;

    const rawDevicesPresence = state.rawDevicesPresence;
    const prevRawDevicesPresence = prevState.rawDevicesPresence;
    const isRawDevicesPresenceChanged = rawDevicesPresence && rawDevicesPresence !== prevRawDevicesPresence;

    if (isRawDevicesChanged || isRawDevicesPresenceChanged) {
      processRawDevicesIntoTheState();
    }
  });

  return () => {
    console.log('[LOG] --- clearInterval(appVersionUpdateInterval) RUN');
    unsubscribeHome();
    unsubscribeAccount();
    unsubscribeProfiles();
    unsubscribeDevices();
  };
};