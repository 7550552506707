import { ContextError } from '../classes';
import { CONTEXT_GLOBAL } from '../globals';

export const withCatcher = <T extends Array<any>, R>(
  identifier: string,
  fn: (...args: T) => Promise<R>,
  onError?: (error: ContextError, identifier?: string, args?: T) => void,
): (...args: T) => Promise<R> => {
  const prefix = String(identifier).split('.').map(n => '['+n+']');
  return async (...args: T) => {
    try {
      return await fn(...args);
    } catch (error) {
      const err = error as ContextError;
      if(CONTEXT_GLOBAL.consoleErrors){
        const logFn = err?.isLocal ? console.log : console.error;
        logFn(prefix+' catcher proxied error: ', error);
      }
      onError?.(err, identifier, args);
      CONTEXT_GLOBAL.logError(error, identifier);
      throw error;
    }
  };
};

export const fnSilentWrapper = <T extends Array<any>>(
  fn: (...args: T) => Promise<any>
) => async (...args: T) => {
  try {
    await fn(...args);
    return true;
  } catch (error) {
    return null;
  }
};