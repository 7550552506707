import isMobile from 'ismobilejs';

export const digestedAgent = isMobile(window.navigator);
const portrait = window.matchMedia('(orientation: portrait)');

function setMeta(isportrait: boolean){
  if(isportrait && digestedAgent.phone){
    meta.setAttribute('content', 'width=640');
  }
  else if(!isportrait && digestedAgent.tablet){
    meta.setAttribute('content', 'width=1400');
  }
  else {
    meta.setAttribute('content', 'width=1149, initialscale=1');
  }
}

portrait.addEventListener('change', function(e) {
    console.log('[DOMSetters] changed orientation:', (e.matches ? 'portrait' : 'landscape'));
    setMeta(!!e.matches /*its portrait mode if matches*/);
});

export const meta = window.document.createElement('meta');
meta.setAttribute('name','viewport');

setMeta(true);

window.onload = function(){
  const docHeader = window.document.getElementsByTagName('head')[0];
  console.log('[DOMSetters] setting window content size', {digestedAgent, docHeader, meta, portrait});
  docHeader.append(meta);
};
