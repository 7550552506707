export function providerToLabelName(providerName: string=''){
  return (providerName.match(/at&?t/gi))
    ? 'att'
    : (providerName.match(/t-mobile/gi))
    ? 'tmobile'
    : (providerName.match(/verizon/gi))
    ? 'verizon'
    : 'other';
}

export function providerToTitleName(providerName: string=''){
  return (providerName.match(/at&?t/gi))
    ? 'At&t'
    : (providerName.match(/t-?mobile/gi))
    ? 'T-Mobile'
    : (providerName.match(/verizon/gi))
    ? 'Verizon'
    : 'Other';
}

export type ProvidersLabelName = 'att'|'tmobile'|'verizon'|'other';
export const providersLabelNames = ['att','tmobile','verizon','other'];
export const providersTitleNames = ['At&t','T-Mobile','Verizon','Other'];
