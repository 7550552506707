import React from 'react';
import {IconProps} from './_iconProps';

export const Wind: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M23.64 10.3067C24.0278 9.91986 24.505 9.63457 25.0292 9.47603C25.5535 9.3175 26.1088 9.2906 26.646 9.39773C27.1831 9.50486 27.6856 9.74271 28.109 10.0902C28.5324 10.4378 28.8636 10.8843 29.0733 11.3903C29.283 11.8963 29.3649 12.4461 29.3115 12.9913C29.2582 13.5364 29.0713 14.06 28.7675 14.5157C28.4637 14.9715 28.0522 15.3453 27.5695 15.6042C27.0868 15.8631 26.5477 15.999 26 16H2.66667M12.7867 6.12002C13.0964 5.80844 13.4783 5.57821 13.8985 5.44981C14.3187 5.3214 14.764 5.2988 15.195 5.38401C15.626 5.46921 16.0293 5.65959 16.369 5.93821C16.7087 6.21683 16.9743 6.57507 17.1422 6.98107C17.3101 7.38708 17.375 7.82827 17.3313 8.26544C17.2876 8.7026 17.1365 9.12218 16.8915 9.4869C16.6466 9.85161 16.3153 10.1501 15.9271 10.356C15.539 10.5618 15.106 10.6685 14.6667 10.6667H2.66667L12.7867 6.12002ZM16.7867 25.88C17.0964 26.1916 17.4783 26.4218 17.8985 26.5502C18.3187 26.6786 18.764 26.7012 19.195 26.616C19.626 26.5308 20.0293 26.3404 20.369 26.0618C20.7087 25.7832 20.9743 25.425 21.1422 25.019C21.3101 24.613 21.375 24.1718 21.3313 23.7346C21.2876 23.2974 21.1365 22.8778 20.8915 22.5131C20.6466 22.1484 20.3153 21.8499 19.9271 21.6441C19.539 21.4382 19.106 21.3315 18.6667 21.3334H2.66667L16.7867 25.88Z" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</svg>
