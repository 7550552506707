import {createContext, useContext, useState, useCallback, useMemo} from 'react';
import {
  WarningProps
} from 'components';
import {
  useDataFeedObserver
} from 'hooks';
import {v4 as uuid} from 'uuid';
import {
  format
} from 'date-fns';

type WarningsContextPropsType = {
  warningNodes: Array<{uid: string} & WarningProps>,
  createWarning: (props: WarningProps) => void
}
const WarningsContext = createContext<WarningsContextPropsType>(undefined!);
export const useWarningContext = () => useContext(WarningsContext);
export const WarningsContextProvider: React.FC = ({
  children
}) => {
  const [warningNodes, setWarningNodes] = useState<WarningsContextPropsType['warningNodes']>([]);

  const createWarning = useCallback(function({
    title, details, children
  }){
    const uid = uuid();
    const newWarning = {
      uid,
      title,
      details,
      children,
      onClose: () => {
        setWarningNodes(prev => (
          prev?.filter(w => w.uid!==uid)
        ));
      }
    };
    setWarningNodes(prev => [newWarning,...prev]);
  },[]);

  // create warning on emergency signals
  useDataFeedObserver({
    onData: (signals) => {
      signals?.forEach(signal => {
        if(signal.type === 'cell' && signal.establishmentCause === 'emergency'){
          createWarning({
            title: 'A 911 Call was detected on your property',
            details: signal.cellProvider + ' ' + String(signal.type).replace('-', ' ') + ' ' + format(signal.timestamp, 'h:mm:ss a') + ' at ' + Math.round(signal.power) + ' dBm' + (signal.identifier ? ' with TMSI '+signal.identifier : '')
          })
        }
      })
    }
  });

  const value = useMemo(()=> ({
    warningNodes,
    createWarning
  }),[
    warningNodes,
    createWarning
  ]);

  return <WarningsContext.Provider value={value}>
    {children}
  </WarningsContext.Provider> 
}

