export function trimUndefined<T extends object>(object: T) {
  for (const key in object) {
    if (object[key] === undefined) {
      delete object[key];
    }
  }
}

export function trimUndefinedRecursively<T extends object>(object: T, stringifyNonDeletable=false) {
  trimUndefinedRecursivelyLoop(object, new Set(), stringifyNonDeletable);
}

function trimUndefinedRecursivelyLoop<T extends object>(
  object: T,
  tracks: Set<object>,
  stringifyNonDeletable=false
) {
  tracks.add(object);
  for (const key in object) {
    if (object[key] === undefined) {
      try{
        delete object[key];
      }
      catch (e) {
        if(stringifyNonDeletable){
          (object[key] as any) = 'undefined';
        } else {
          // thor it back at ya
          throw e;
        }
      }
    } else {
      const value: object = object[key] as any;
      if (value && typeof value === 'object' && !tracks.has(value)) {
        trimUndefinedRecursivelyLoop(value, tracks);
      }
    }
  }
}

export function trimUndefinedAndClassesObjectRecursively<T extends object>(object: T, stringifyNonDeletable=false, nullOutNonDeletableClasses=true){
  trimUndefinedAndClassesObjectRecursivelyLoop(object, new Set(), stringifyNonDeletable, nullOutNonDeletableClasses);
}

function trimUndefinedAndClassesObjectRecursivelyLoop<T extends object>(
  object: T,
  tracks: Set<object>,
  stringifyNonDeletable=false,
  nullOutNonDeletableClasses=true
) {
  tracks.add(object);
  for (const key in object) {
    if (object[key] === undefined) {
      try{
        delete object[key];
      }
      catch (e) {
        if(stringifyNonDeletable){
          (object[key] as any) = 'undefined';
        } else {
          // thor it back at ya
          throw e;
        }
      }
    } 
    // check to match json friendly contructors
    else if( !(object[key] as any)?.constructor.name.match(/Object|Array|Number|Boolean|String/) ) {
      try{
        delete object[key];
      }
      catch (e) {
        if(nullOutNonDeletableClasses){
          (object[key] as any) = null;
        } else {
          // thor it back at ya
          throw e;
        }
      }
    } 
    else if ( typeof object[key] === 'number' && ( (object[key] as any) === Infinity || isNaN(object[key] as any)) ){
      (object[key] as any) = String();
    }
    else {
      const value: object = object[key] as any;
      if (value && typeof value === 'object' && !tracks.has(value)) {
        trimUndefinedAndClassesObjectRecursivelyLoop(value, tracks);
      }
    }
  }
}