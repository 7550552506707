import { UserResponseData } from '@ubiety/fe-api';

import { User } from '../types';

export const processRawUser = (user?: UserResponseData): User | undefined => {
  if (!user) {
    return undefined;
  }
  const {
    uid,
    created_at,
    account_id,
    first_name,
    last_name,
    phone_number,
    email,
    onboarding_steps,
  } = user;

  return {
    uid,
    createdAt: created_at,
    accountId: account_id,
    firstName: first_name,
    lastName: last_name,
    email,
    phone: phone_number,
    onboardingSteps: onboarding_steps,
  };
};
