type CompareVersionsReturnValue = {
  difference: Array<number>;
  comparison: 'ahead' | 'equal' | 'behind';
} | null;
/**
 * Function compares two strings assumed to be in the format of
 * #.#.#.#. of any length and comparethem and return the difference
 * between the two version and a statement of whether version1 is
 * ahead, equal, or behind version2
 *
 * @param version1 string
 * @param version2 string
 * @returns {
 * 	difference: number[]
 * 	comparison: 'ahead' | 'equal' | 'behind'
 * } | null
 */
export const compareVersions = (
  version1: string,
  version2: string,
): CompareVersionsReturnValue => {
  if (!version1 || !version2) {
    console.warn('[compareVersions] two versions were not provided to be compared');
    return null;
  }

  // Break into parts
  const version1Parts = version1.split('.').map((s) => parseInt(s, 10));
  const version2Parts = version2.split('.').map((s) => parseInt(s, 10));

  // Make parts even in length
  const differenceInParts = version1Parts.length - version2Parts.length;
  for (let i = 0; i < Math.abs(differenceInParts); i++) {
    if (differenceInParts < 0) {
      version1Parts.push(0);
    } else {
      version2Parts.push(0);
    }
  }
  const difference = version1Parts.map((n, i) => n - (version2Parts[i] ?? 0));
  const firstNoneZeroVersionPart = difference.find((n) => n !== 0);
  const comparison = !firstNoneZeroVersionPart
    ? 'equal'
    : firstNoneZeroVersionPart > 0
    ? 'ahead'
    : 'behind';
  return { difference, comparison };
};
