export type NetworkProviderLabel = 'att'|'verizon'|'tmobile'|'other';
export const PROVIDERS_ARRAY = [ 'att', 'tmobile', 'verizon', 'other' ];


export const ProvidersLabelsMap = new Map<NetworkProviderLabel, string>();
ProvidersLabelsMap.set('att', 'AT&T');
ProvidersLabelsMap.set('tmobile', 'T-mobile');
ProvidersLabelsMap.set('verizon', 'Verizon');
ProvidersLabelsMap.set('other', 'Other');

export const ProvidersColorsMap = new Map<NetworkProviderLabel, string>();
ProvidersColorsMap.set('att', '#0FB5DA');
ProvidersColorsMap.set('verizon', '#F00202');
ProvidersColorsMap.set('tmobile', '#E100AF');
ProvidersColorsMap.set('other', '#EF9A26');

export const signalsRange = {
  min: 70,
  max: 130, 
  outOf: 0.6
};
