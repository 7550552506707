import React from 'react';
import {IconProps} from './_iconProps';

export const Share: React.FC<IconProps> = ({
  width=32,
  height=32,
  stroke="currentColor",
  strokeWidth="1.5",
  className
}) =>  <svg className={'icon '+(className||'')} style={{color:stroke}} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5.33333 16V26.6667C5.33333 27.3739 5.61429 28.0522 6.11438 28.5523C6.61448 29.0524 7.29276 29.3333 8 29.3333H24C24.7072 29.3333 25.3855 29.0524 25.8856 28.5523C26.3857 28.0522 26.6667 27.3739 26.6667 26.6667V16M21.3333 7.99999L16 2.66666M16 2.66666L10.6667 7.99999M16 2.66666V20" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
</svg>
