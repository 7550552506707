// import font definitions
import './font-files';
import { newColors } from './colors';

// define root rem size
export const fontRem = '16px';

// declare font families list for preload
export const fontFamilies = ['Poppins', 'DM Sans'];

export const defaultFontFamily = 'Poppins';

export const fontStyles = {
  pageTitle: `
    font-family: Poppins, sans-serif;
    font-size: 3.052rem;
    line-height: 4.578rem;
  `,
  pageSubtitle: `
    font-family: Poppins, sans-serif;
    font-size: 1.563rem;
    line-height: 2.3445rem;
  `,
  headlineOne: `
    font-family: Poppins, sans-serif;
    font-size: 1.563rem;
    line-height: 2.3445rem;
    font-weight: 600;
  `,
  headlineTwo: `
    font-family: Poppins, sans-serif;
    font-size: 1.25rem;
    line-height: 1.875rem;
    font-weight: 600;
  `,
  largeBodySubTitle: `
    font-family: DM Sans, sans-serif;
    color: ${newColors.MidnightShores};
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: normal;
  `,
  body: `
    font-family: DM Sans, sans-serif;
    color: ${newColors.DarkGreen};
    font-size: 1rem;
    line-height: 1.5rem;
  `,
  captionPlaceholder: `
    font-family: DM Sans, sans-serif;
    color: ${newColors.MidnightShores};
    font-size: 0.8rem;
    line-height: 1.2rem;
  `,
  label: `
    font-family: Poppins, sans-serif;
    font-size: 0.875rem;
    line-height: 1.3125rem;
    font-weight: 600;
  `,
  field: `
    font-family: Poppins;
    color: ${newColors.DarkStar};
    font-size: 0.875rem;
    line-height: 1.3125rem;
  `,
  fieldPlaceholder: `
    font-family: Poppins;
    font-size: 0.875rem;
    line-height: 1.3125rem;
    color: ${newColors.MidnightShores};
  `,
  button: `
    font-family: Poppins, sans-serif;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 600; 
    text-transform: uppercase;
  `,
  buttonSmall: `
    font-family: Poppins, sans-serif;
    font-size: 0.66rem;
    line-height: 1rem;
    font-weight: 600; 
    text-transform: uppercase;
  `,
  link: `
    font-family: DM Sans;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: normal;
    color: ${newColors.OrangeDream};
  `,
  graphs: `
    font-family: DM Sans, sans serif;
    font-size: 0.75rem;
    color: ${newColors.DarkStar};
  `,
  largeTextDisplay: `
    font-family: Poppins, sans serif;
    font-size: 3rem;
    line-height: 4.5rem;
    font-weight: bold;
  `
}

/*
Page Title: 48.83px
 
Subtitle: 31.25px
Headline 1: 25.00px
Headline 2: 20.00px 
Large Body/Sub-tile: 20.00px
Body: 16.00px
Caption/Placeholder: 12.80px
Label: 14px
Field: 14px
Button: 16.00px
*/


export const fontMargins = {
  xxxxs: 2,
  xxxs: 5,
  xxs: 8,
  xs: 10,
  s: 12,
  m: 14,
  base: 16,
  l: 18,
  xl: 20,
  xl2: 24,
  xl3: 28,
  xl4: 34,
  xl5: 40,
  xl6: 48,
  xl7: 52,
  xl8: 60,
  xl9: 70,
};

export const fontPadding = {
  xxxxs: 3,
  xxxs: 4,
  xxs: 5,
  xs: 6,
  s: 8,
  m: 10,
  base: 12,
  l: 14,
  xl: 16,
  xl2: 20,
  xl3: 22,
  xl4: 24,
  xl5: 34,
  xl6: 40,
  xl7: 48,
  xl8: 60,
  xl9: 70,
};

export const fontSize = {
  xxxxs: 3,
  xxxs: 5,
  xxs: 8,
  xs: 10,
  s: 12,
  m: 14,
  base: 16,
  l: 18,
  xl: 22,
  xl2: 24,
  xl3: 28,
  xl4: 32,
  xl5: 40,
  xl6: 46,
  xl7: 52,
  xl8: 60,
  xl9: 70,
};