import { apiRequest } from '../apiRequest';
import {
  TimelineDataPayload,
  TimelinePresenceResponse,
  RawTimelinePresenceData,
  ProfilePayload,
  DevicePaylaod,
} from './types';

export const getProfileTimelineData = (data: TimelineDataPayload & ProfilePayload) =>
  apiRequest<RawTimelinePresenceData>({
    service: 'feApiGateway',
    path: `homes/${data.home_id}/profiles/${data.profile_id}/timeline`,
    method: 'GET',
    params: data.params,
  });

export const getHomeProfilesTimelineData = ({home_id, limit, offset, params}: TimelineDataPayload) => 
  apiRequest<TimelinePresenceResponse>({
    service: 'feApiGateway',
    path: `homes/${home_id}/profiles/timeline/?limit=${limit}&offset=${offset}`,
    method: 'GET',
    params,
  });

export const getDeviceTimelineData = (data: TimelineDataPayload & DevicePaylaod) =>
  apiRequest<RawTimelinePresenceData>({
    service: 'feApiGateway',
    path: `homes/${data.home_id}/devices/${data.device_id}/timeline`,
    method: 'GET',
    params: data.params,
  });
